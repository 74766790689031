import React, { useRef, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import EditQuoteModal from '../../EditQuoteModal';
import ChangeQuoteStageModal from '../../../pages/OpportunityDetails/components/ChangeQuoteStageModal';
import QuoteModal from '../../../pages/OpportunityDetails/components/QuoteModal';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { CardDiv } from '../styles';
import bin from '../../../assets/logo/bin.svg';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';

const QuoteDetailsCardMobile = ({
  details,
  name,
  onDelete,
  setShowDeleteModal,
}: {
  details: any;
  name: string;
  setShowDeleteModal?: Function;
  onDelete?: Function;
}) => {
  const navigate = useNavigate();
  const [showEditQuote, setShowEditQuote] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');

  const uuid = details.uuid;

  const deleteFun = (uuid: string, name: string) => {
    if (onDelete) {
      onDelete(uuid, name);
    }
  };

  const [currentStage, setCurrentStage] = useState('');

  const [showChangeStageModal, setShowChangeStageModal] = useState('closed');
  const [UUID, setUUID] = useState('');

  return (
    <CardDiv>
      {' '}
      <div className='opportunity'>
        {name === 'Account' || name === 'Contact' ? (
          <>
            <div className='topContent d-flex justify-content-between'>
              <div className='topDetails d-flex align-items-center'>
                <>
                  <span className='oppName'>{details?.name}</span>
                </>
              </div>
            </div>
            <div className='lowContent'>
              <div className='oppDetaisl'>
                <div className='oppDetaislfields  '>
                  <>
                    {name === 'Contact' ? (
                      <>
                        <div className='d-flex contact-div'>
                          <div className='d-flex account-head'>
                            {details?.Account.name}
                          </div>
                          <div className='d-flex  premium-div'>
                            <span className='valHead '>| Best premium</span>
                            <b>$</b>
                            {details?.premium
                              ? details?.premium.toLocaleString('en-US')
                              : 0}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='d-flex  detailsQuotes'>
                        <span className='valHead '>Best premium</span>
                        <span className='valueField'>
                          $
                          {details?.premium
                            ? details?.premium.toLocaleString('en-US')
                            : 0}
                        </span>
                      </div>
                    )}
                  </>
                  <div className='d-flex detailsQuotes w-100 justify-content-between'>
                    <span className='valueField borderDiv quotesCount'>
                      {details?.QuoteRequests?.length || 0} quotes
                      <br />
                      available
                    </span>
                    <div className='d-flex '>
                      <button
                        className='tertiaryButton quotingbtn '
                        onClick={() =>
                          navigate(`/opportunities/${details.uuid}/quotes`)
                        }
                      >
                        View quotes{' '}
                        <img className='arrowIcon' src={arrow} alt='' />
                      </button>
                      {/* <div className='actionMenu d-flex align-items-center justify-content-center'>
                        <img src={vector} alt='vector' />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='Opportunity'>
            <div className='topContent d-flex justify-content-between'>
              <div className='topDetails d-flex align-items-center'>
                <>
                  <span className='carrierName'>{details?.Carrier?.name}</span>
                  <div
                    className={`quote-stage-pill ${
                      details.QuoteStage?.name === 'NEW'
                        ? 'yellow-bg'
                        : details.QuoteStage?.name === 'CANCELED' ||
                          details.QuoteStage?.name === 'EXPIRED' ||
                          details.QuoteStage?.name === 'DECLINED'
                        ? 'danger-bg'
                        : details.QuoteStage?.name === 'ACCEPTED'
                        ? 'green-bg'
                        : details.QuoteStage?.name === 'VIEWED'
                        ? 'blue-bg'
                        : ''
                    }`}
                  >
                    {details.QuoteStage?.name.toLowerCase()}
                  </div>
                </>
              </div>
            </div>
            <div className='lowContent'>
              <div className='oppDetaisl'>
                <div className='oppDetaislfields'>
                  <div className='d-flex  detailsQuotes'>
                    <span className='valueSpan '>
                      {details?.QuoteType?.name
                        .toLowerCase()
                        .split('-')
                        .map(
                          (word: string) =>
                            word[0].toUpperCase() + word.substring(1)
                        )
                        .join(' ')}{' '}
                      <span className='divider'>|</span>
                    </span>

                    <span className='valueSpan'>
                      <b>$</b>
                      {details?.premium?.toLocaleString('en-US')}
                    </span>
                  </div>
                  <div>
                    <div className='d-flex '>
                      <span className='valHead '>Effective date</span>
                      <span className='valueField valHead'>
                        <Moment format='MM/DD/YYYY'>
                          {details?.effectiveDate}
                        </Moment>
                      </span>
                    </div>
                  </div>

                  <div className='d-flex detailsQuotes w-100 justify-content-between'>
                    <div className='d-flex editbutton-div'>
                      {details.QuoteStage.name !== 'CANCELED' &&
                      details.QuoteStage.name !== 'EXPIRED' &&
                      details.QuoteStage.name !== 'DECLINED' ? (
                        <button
                          className='tertiaryButton editquotingbtn '
                          onClick={() => {
                            setShowEditQuote('open');
                          }}
                        >
                          Edit Quote
                        </button>
                      ) : (
                        <button
                          className='tertiaryButton editquotingbtn '
                          onClick={() => {
                            setShowQuoteModal('open');
                          }}
                        >
                          View Quote
                        </button>
                      )}
                      <ActionsDropdown
                        uuid={details.uuid}
                        setShowDeleteModal={setShowDeleteModal}
                        name={details?.Carrier?.name}
                        details={details}
                        deleteFun={deleteFun}
                        setShowChangeStageModal={setShowChangeStageModal}
                        setUUID={setUUID}
                        currentStage={details.QuoteStage?.uuid}
                        setCurrentStage={setCurrentStage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showEditQuote !== 'closed' && (
        <EditQuoteModal
          show={showEditQuote}
          closeModal={setShowEditQuote}
          type='Opportunity'
          UUID={uuid}
        />
      )}
      {showChangeStageModal !== 'closed' && (
        <ChangeQuoteStageModal
          showModal={showEditQuote}
          closeModal={setShowChangeStageModal}
          uuid={UUID}
          currentStage={currentStage}
        />
      )}
      {showQuoteModal !== 'closed' && (
        <QuoteModal
          uuid={uuid}
          showModal={showQuoteModal}
          setShowModal={setShowQuoteModal}
        />
      )}
    </CardDiv>
  );
};

export default QuoteDetailsCardMobile;

const ActionsDropdown = ({
  uuid,
  setShowDeleteModal,
  name,
  deleteFun,
  setShowChangeStageModal,
  setUUID,
  currentStage,
  setCurrentStage,
  details,
}: {
  uuid: string;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
  setShowChangeStageModal: Function;
  setUUID: Function;
  currentStage: string;
  setCurrentStage: Function;
  details: any;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      ref={dropRef}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          {setShowDeleteModal && (
            <>
              {details.QuoteStage.name !== 'CANCELED' &&
                details.QuoteStage.name !== 'EXPIRED' &&
                details.QuoteStage.name !== 'DECLINED' && (
                  <div
                    className='quotediv'
                    onClick={() => {
                      setShowChangeStageModal('open');
                      setShowActionsDropdown(false);
                      setUUID(uuid);
                      setCurrentStage(currentStage);
                    }}
                    tabIndex={0}
                  >
                    Change status <img src={arrow} alt='bin' />
                  </div>
                )}
              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setShowActionsDropdown(false);
                  deleteFun(uuid, name);
                }}
                tabIndex={1}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </>
          )}
        </div>
      )}
    </button>
  );
};

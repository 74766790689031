import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../store';
import {
  PipelinesSliceState,
  updatePipeline,
  getPipelineTemplate,
} from '../../store/pipelines/pipelineSlice';
import { fetchPipelineTemplate } from '../../api/pipeline';
import Modal from '../../components/Modal';
import InputField from '../InputField';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import DeleteWarningModal from '../../pages/Admin/PipelineMange/components/DeleteWarmimgModal';
import DeletePipelineModal from '../../pages/Admin/PipelineMange/components/DeletePipelineModal';
import { IPipelineTemplate, ITemplateStage } from '../../interfaces/Pipeline';
import { IPipeline, IPipelineStage } from '../../interfaces/Opportunity';
import { SuccessAlign } from '../../styles/SuccessAlign';
import { StyledPipelineModal } from '../AddPipelineModal/styles';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import edit from '../../assets/logo/greyEdit.svg';
import warning from '../../assets/logo/warning.svg';
import bin from '../../assets/logo/bin.svg';
import { fetchProducts } from '../../api/product';
import { IProduct } from '../../interfaces/Product';
import 'rc-slider/assets/index.css';
import Skeleton from 'react-loading-skeleton';

const PipelineSchema = Yup.object().shape({
  pipelineName: Yup.string()
    .max(20, 'Pipeline Name cannot exceed 20 characters')
    .required('Please enter Pipeline Name '),
});

const EditPipelineDrawer = ({
  show,
  closeModal,
  pipeline,
  setDeleteSuccess,
  deleteSuccess,
}: {
  show: string;
  closeModal: Function;
  pipeline?: IPipeline;
  setDeleteSuccess?: Function;
  deleteSuccess?: boolean;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const [product, setProduct] = useState<IProduct[]>([]);
  const [template, setTemplate] = useState<IPipelineTemplate>();
  const [stages, setStages] = useState<(ITemplateStage | IPipelineStage)[]>([]);
  const [numStage, setNumStage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pipelineDeleteWarning, setPipelineDeleteWarning] = useState(false);
  const [onDelete, setDelete] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(PipelineSchema),
  });

  const watchName = watch('pipelineName');

  useEffect(() => {
    dispatch(
      getPipelineTemplate({
        successCB: (data: IPipelineTemplate) => {
          setTemplate(data);
          setStages(data?.defaultStages);
          setNumStage(data?.minStage);
        },
      })
    );
    fetchPipelineTemplate((data: IPipelineTemplate) => {
      if (pipeline) {
        const temp = pipeline.PipelineStages.reduce((t: any, o) => {
          if (o.nextStageId) {
            t[o.nextStageId] = o;
          } else {
            t['last'] = o;
          }
          return t;
        }, {});

        let result: IPipelineStage[] = [];

        let p: any = 'last';

        while (temp[p]) {
          result.unshift(temp[p]);
          p = temp[p].id;
        }

        setStages(result);
        setNumStage(pipeline?.PipelineStages.length);
        setValue('pipelineName', pipeline.name);
        let products: any = [];
        if (pipeline.Products) {
          products = products.concat(
            pipeline.Products.map((item) => {
              return { value: item.uuid, label: item.name };
            })
          );
        }
        if (pipeline.CustomProducts) {
          products = products.concat(
            pipeline.CustomProducts.map((item) => {
              return { value: item.uuid, label: item.name };
            })
          );
        }
        setValue('opportunityTypes', products);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPipelineTemplateLoading = useSelector(
    (state: { pipelines: PipelinesSliceState }) =>
      state.pipelines.pipeLineTemplateStatus
  );

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  useEffect(() => {
    fetchProducts(
      (data: IProduct[]) => {
        setProduct(data);
      },
      'name',
      'asc',
      '',
      uuid,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMarks = (min: number, max: number) => {
    if (pipeline)
      return Array.from(Array(max - min + 1).keys()).reduce((t: any, o) => {
        t[o + min] = {
          label: (
            <span
              className={
                o + min < pipeline?.PipelineStages.length ? 'disabled-mark' : ''
              }
            >
              {o + min}
            </span>
          ),
        };
        return t;
      }, {});
  };
  const loading = useSelector(
    (state: { pipelines: PipelinesSliceState }) => state.pipelines.isLoading
  );

  const onEditStage = (type: string, value: string, index: number) => {
    const updatedStages = [...stages];
    const updatedStage = { ...updatedStages[index] };

    if (type === 'probability') {
      const percent = parseInt(value);
      if (percent >= 0 && percent <= 100) {
        updatedStage.stageProbability = percent;
      } else if (!percent) {
        updatedStage.stageProbability = 0;
      }
    } else {
      updatedStage.name = value;
    }
    updatedStages[index] = updatedStage;
    setStages(updatedStages);
  };

  const onChangeStage = (values: number | number[]) => {
    let val;
    if (typeof values !== 'number') {
      val = values[2];
    } else {
      val = values;
    }

    if (pipeline && template) {
      if (val >= pipeline?.PipelineStages.length) {
        const updatedStages = [...stages];
        if (val > numStage) {
          let numAdd = val - numStage;

          for (
            let i = numStage - template.minStage;
            i < numAdd + (numStage - template.minStage);
            i++
          ) {
            updatedStages.splice(i + 2, 0, template.additionalStages[i]);
          }
        } else {
          let numRemove = numStage - val;
          for (let i = 0; i < numRemove; i++) {
            updatedStages.splice(stages.length - 4 - i, 1);
          }
        }
        setStages(updatedStages);
        setNumStage(val);
      }
    }
  };

  const onSubmit = (data: any) => {
    data.pipelineStages = stages;
    data.productUUID = data.opportunityTypes
      ? data.opportunityTypes
          .filter((item: any) => item.type === 'PRODUCT')
          .map((item: { label: string; value: string }) => item.value)
      : [];
    data.customProductUUID = data.opportunityTypes
      ? data.opportunityTypes
          .filter((item: any) => item.type === 'CUSTOM_PRODUCT')
          .map((item: { label: string; value: string }) => item.value)
      : [];
    data.agencyUUID = uuid;
    dispatch(
      updatePipeline({
        data,
        uuid: pipeline ? pipeline.uuid : '',
        successCB: (val: IPipeline) => {
          closeModal('closing', val);
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          setTimeout(() => {
            // if (!uuid) {
            //   window.location.reload();
            // }

            setTimeout(() => {
              setShowSuccess(false);
              closeModal('closed');
            }, 300);
          }, 2000);
        },
      })
    );
  };

  return (
    <>
      <StyledPipelineModal>
        {showSuccess ? (
          <SuccessAlign>
            <div
              className={`success-container ${showSuccess && 'open-drawer'} `}
            >
              <SuccessCard
                title={'Success editing Pipeline'}
                message={`Pipeline <b>${watchName}</b> has been updated.<b>`}
              />
              <button
                onClick={() => {
                  setShowSuccess(false);
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
            </div>
          </SuccessAlign>
        ) : (
          <Modal open={show} align='right'>
            <div className='drawer-content'>
              <div className='top-div d-flex justify-content-between align-items-center'>
                <h2>Edit Pipeline</h2>
                <div className='d-none d-sm-block'>
                  <button
                    className='delete-button'
                    onClick={() => {
                      if (pipeline && pipeline?.opportunityCount > 0)
                        setPipelineDeleteWarning(true);
                      else setDelete(true);
                    }}
                  >
                    Delete Pipeline
                  </button>
                  <button className='cross-btn'>
                    <img
                      className='cross'
                      src={cross}
                      alt=''
                      onClick={() => {
                        onCloseModal();
                      }}
                    />
                  </button>
                </div>
                <div className='d-flex align-items-center d-sm-none'>
                  <button
                    className='delete-buttonMob'
                    onClick={() => {
                      if (pipeline && pipeline?.opportunityCount > 0)
                        setPipelineDeleteWarning(true);
                      else setDelete(true);
                    }}
                  >
                    <img src={bin} alt='bin' />
                  </button>
                  <button className='cross-btnMob'>
                    <img
                      className='cross'
                      src={cross}
                      alt=''
                      onClick={() => {
                        onCloseModal();
                      }}
                    />
                  </button>
                </div>
              </div>

              <form className='pipeline-form' onSubmit={handleSubmit(onSubmit)}>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <InputField
                      name='pipelineName'
                      register={register}
                      type='text'
                      placeholder='Pipeline name'
                      required
                      className={`${errors?.pipelineName && 'error-field'} ${
                        watchName && 'filled'
                      }`}
                    />{' '}
                    {errors.pipelineName ? (
                      <div className='error-message'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.pipelineName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields'>
                    <SelectField
                      name='opportunityTypes'
                      control={control}
                      placeholder='Opportunity types'
                      options={product.map((item) => {
                        return {
                          value: item.uuid,
                          label: item.name,
                          type: item.type,
                        };
                      })}
                      isMulti={true}
                      isDisabled
                    />
                  </div>
                </div>
                <div className='stages-slider'>
                  <div className='d-flex align-items-center'>
                    <h6>Select number of stages</h6>
                  </div>

                  <div className='stage-info'>
                    No. of stages can&apos;t be decreased while editing
                  </div>
                </div>

                <div className='pipeline-slider'>
                  {template && pipeline && (
                    <Slider
                      min={template?.minStage}
                      max={template?.maxStage}
                      value={[
                        template.minStage,
                        pipeline?.PipelineStages.length,
                        numStage,
                      ]}
                      trackStyle={[
                        { backgroundColor: '#262758' },
                        { backgroundColor: 'transparent' },
                      ]}
                      handleStyle={[
                        { backgroundColor: 'transparent', boxShadow: 'none' },
                        { backgroundColor: 'transparent', boxShadow: 'none' },
                        { backgroundColor: '#fff', cursor: 'pointer' },
                      ]}
                      marks={getMarks(template?.minStage, template?.maxStage)}
                      onChange={onChangeStage}
                      range
                      count={2}
                    />
                  )}
                </div>

                <div className='stage-list'>
                  <div className='d-flex header-div'>
                    <div className='col'>Stage Name</div>
                    <div className='col'>Stage Probability</div>
                  </div>
                  <div className='stage-options'>
                    {getPipelineTemplateLoading === 'loading'
                      ? [1, 2, 3].map((item) => (
                          <div key={item}>
                            {' '}
                            <Skeleton height={84} key={item} />
                          </div>
                        ))
                      : stages.map((item, index) => (
                          <StageCard
                            item={item}
                            index={index}
                            key={index}
                            onEditStage={onEditStage}
                          />
                        ))}
                  </div>
                </div>

                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    disabled={loading}
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        )}
        {deleteSuccess && onCloseModal()}
        {pipelineDeleteWarning && (
          <DeleteWarningModal
            showModal={pipelineDeleteWarning}
            onCloseModal={setPipelineDeleteWarning}
            UUID={pipeline?.uuid}
            onDeletePipeline={setDelete}
            name={pipeline?.name ? pipeline?.name : ''}
          />
        )}
        {onDelete && setDeleteSuccess && (
          <DeletePipelineModal
            showModal={onDelete}
            onCloseModal={setDelete}
            UUID={pipeline?.uuid}
            onDelete={setDelete}
            setDeleteSuccess={setDeleteSuccess}
          />
        )}
      </StyledPipelineModal>
    </>
  );
};

export default EditPipelineDrawer;

const StageCard = ({
  item,
  index,
  onEditStage,
}: {
  item: ITemplateStage | IPipelineStage;
  index: number;
  onEditStage: Function;
}) => {
  const [isEditProbability, setIsEditProbability] = useState(false);
  const [isEditName, setIsEditName] = useState(false);

  return (
    <div key={index} className='stage-card d-flex'>
      <div className='col'>
        {isEditName ? (
          <input
            value={item.name}
            type='text'
            onChange={(e) => onEditStage('name', e.target.value, index)}
            onBlur={() => setIsEditName(false)}
            autoFocus={true}
            maxLength={20}
          />
        ) : (
          <div onClick={() => setIsEditName(true && item.editable)}>
            {item.name} {item.editable && <img src={edit} alt='' />}
          </div>
        )}
      </div>
      <div className='col'>
        <div>
          {isEditProbability ? (
            <div className='percent-container d-flex'>
              <input
                value={item.stageProbability}
                type='number'
                onChange={(e) =>
                  onEditStage('probability', e.target.value, index)
                }
                onBlur={() => setIsEditProbability(false)}
                autoFocus={true}
                style={{ width: 30 }}
              />
            </div>
          ) : (
            <div onClick={() => setIsEditProbability(true)}>
              {item.stageProbability}%{' '}
              <img src={edit} alt='' className='editingLogo' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

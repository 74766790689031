import axios from './axios';

const fetchAgents = (
  successCB: Function,
  sortBy: string,
  orderBy: string,
  searchBy: string,
  minimal?: string,
  limit?: number,
  offset?: number,
  agencyUUID?: string,
  userRoleUUID?: string,
  agentStatusUUID?: string,
  includeInActive?: boolean
) => {
  return axios
    .get(`/agents`, {
      params: {
        sortBy,
        searchBy,
        orderBy,
        minimal,
        limit,
        offset,
        agencyUUID,
        userRoleUUID,
        agentStatusUUID,
        includeInActive,
      },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const fetchStatus = (successCB: Function) => {
  return axios.get(`/agents/agentStatus`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const fetchAgentDetails = (uuid: string, successCB: Function) => {
  return axios.get(`/agents/${uuid}`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const updateAgent = (
  uuid: string,
  data: { action: string; status: string }
) => {
  return axios.patch(`/agents/${uuid}`, data).then((res) => {
    return res;
  });
};

const fetchUserRoles = (successCB: Function) => {
  return axios.get(`/user/userRoles`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const deleteAgent = (
  uuid: String,
  assigneeUUID: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.delete(`/agents/${uuid}`, { data: { assigneeUUID } }).then(
    (res) => {
      successCB();
      return res;
    },
    (error) => {
      errorCB(error?.response?.data.error);
      return error.response;
    }
  );
};

export {
  fetchAgents,
  fetchAgentDetails,
  updateAgent,
  fetchStatus,
  fetchUserRoles,
  deleteAgent,
};

import styled from '@emotion/styled';

export const SuccesDiv = styled.div`
  width: 100%;
  position: relative;
  background: #d4fad4;
  border-radius: 8px;
  padding-right: 30px;

  .name-text-overflow,
  b {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
  }

  .border {
    position: absolute;
    color: #58c070;
    width: 10px;
    background: #58c070;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  .message {
    padding: 18px 16px;
    padding-left: 28px;
    font-size: 14px;
    color: #262758;
  }
  .success-tick {
    margin-right: 12px;
  }
  .success-text {
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-overflow: ellipsis;
    color: #3d8a68;
  }
`;

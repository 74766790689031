import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/user/userSlice';
import Link, { LinkImage } from '../Link';
import Modal from '../Modal';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { StyledMobileTabSidebar } from './styles';
import cross from '../../assets/logo/blackcross.svg';
import logo from '../../assets/logo/logo.svg';
import vector from '../../assets/logo/dropdown.svg';
import {
  accounts,
  accountsActive,
  activities,
  activitiesActive,
  contacts,
  contactsActive,
  dashboard,
  dashboardActive,
  manage,
  manageActive,
  mybook,
  mybookActive,
  policies,
  policiesActive,
  settings,
  settingsActive,
  team,
  teamActive,
} from '../../assets/nav-icons';

function MobileTabSidebarDrawer({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = useSelector(selectUser);

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, setShowDropdown);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  return (
    <StyledMobileTabSidebar>
      <Modal open={show} align='left'>
        <div className='tab-div'>
          <div>
            <button
              className='cross-button'
              onClick={() => {
                onCloseModal();
              }}
            >
              <img src={cross} alt='cross' />
            </button>
          </div>
          <div className='logo-div'>
            <img src={logo} alt='' />
          </div>

          {user?.UserRole.name === 'ADMIN' ? (
            <>
              <Link to='/admin'>
                <div className='content-link-div'>
                  <LinkImage
                    className='img'
                    name='admin'
                    activeIcon={manageActive}
                    inactiveIcon={manage}
                  />
                  Management
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/dashboard'>
                <div className='content-link-div'>
                  <LinkImage
                    className='img'
                    name='dashboard'
                    activeIcon={dashboardActive}
                    inactiveIcon={dashboard}
                  />
                  Dashboard
                </div>
              </Link>
              <Link to='/activities'>
                <div className='content-link-div'>
                  <LinkImage
                    className='img'
                    name='activities/'
                    activeIcon={activitiesActive}
                    inactiveIcon={activities}
                  />
                  Tasks
                </div>
              </Link>
              <Link to='/pipeline'>
                <div className='content-link-div pipeline'>
                  <LinkImage
                    className='img'
                    name='pipeline'
                    activeIcon={manageActive}
                    inactiveIcon={manage}
                  />
                  Sales pipeline
                </div>
              </Link>
              <div className='mybook-div'>
                <Link to='/mybook'>
                  <div
                    className='vector-nav'
                    onClick={(e) => {
                      setShowDropdown(!showDropdown);
                      e.preventDefault();
                    }}
                    ref={dropDownRef}
                  >
                    <LinkImage
                      className='img'
                      name='mybook'
                      activeIcon={mybookActive}
                      inactiveIcon={mybook}
                    />
                    My book
                    <img className='vector-img' src={vector} alt='vector' />
                    {showDropdown && (
                      <div className='drop-Div'>
                        <div className='contacts'>
                          <Link to='/mybook/contacts'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <LinkImage
                                className='mybook-img'
                                name='mybook/contacts'
                                activeIcon={contactsActive}
                                inactiveIcon={contacts}
                              />
                              Contacts
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/accounts'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <LinkImage
                                className='mybook-img'
                                name='mybook/accounts'
                                activeIcon={accountsActive}
                                inactiveIcon={accounts}
                              />
                              Accounts
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/policies'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <LinkImage
                                className='mybook-img'
                                name='mybook/policies'
                                activeIcon={policiesActive}
                                inactiveIcon={policies}
                              />
                              Policies
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/leads'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={contacts}
                                alt='logo'
                                className='mybook-img'
                              />
                              Leads
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/opportunities'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <LinkImage
                                className='mybook-img'
                                name='mybook/opportunities'
                                activeIcon={policiesActive}
                                inactiveIcon={policies}
                              />
                              Opportunities
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
              {user?.UserRole.name === 'PRINCIPAL_AGENT' ? (
                <div>
                  <Link to='/myteam'>
                    <div className='content-link-div pipeline'>
                      <LinkImage
                        className='img'
                        name='myteam'
                        activeIcon={teamActive}
                        inactiveIcon={team}
                      />
                      My Team
                    </div>
                  </Link>
                  <Link to='/settings'>
                    <div className='content-link-div pipeline'>
                      <LinkImage
                        className='img'
                        name='settings'
                        activeIcon={settingsActive}
                        inactiveIcon={settings}
                      />
                      Settings
                    </div>
                  </Link>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Modal>
    </StyledMobileTabSidebar>
  );
}

export default MobileTabSidebarDrawer;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import SelectField from '../../../../components/SelectField';
import { StyledDeleteModal } from '../../../Admin/elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import { fetchProducts } from '../../../../api/product';
import { IProduct } from '../../../../interfaces/Product';
import {
  onDeleteProduct,
  selectAllProductCategories,
} from '../../../../store/productCategories/productCategoriesSlice';

const DeletePolicyType = ({
  showModal,
  setShowModal,
  type,
  category,
  setSuccessMessage,
}: {
  showModal: boolean;
  setShowModal: Function;
  type: any;
  category: any;
  setSuccessMessage: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();

  const [product, setProduct] = useState<IProduct[]>([]);

  const customProductCategories = useSelector(selectAllProductCategories);
  const { handleSubmit, control, setValue, watch } = useForm();
  const watchDestination = watch('destination');

  useEffect(() => {
    setValue('from', { label: type.name, value: type.uuid });
  }, []);

  useEffect(() => {
    fetchProducts(
      (data: IProduct[]) => setProduct(data),
      '',
      '',
      '',
      uuid,
      true,
      type.LineOfBusiness.name
    );
  }, []);

  const onDelete = (data: any) => {
    data.action = 'deleteCustom';
    data.agencyUUID = uuid;
    if (data.destination) {
      data.destinationUUID = data.destination.value;
      data.destinationType =
        data.destination.type === 'CUSTOM_PRODUCT'
          ? 'CUSTOM_PRODUCT'
          : 'PRODUCT';
    }

    dispatch(
      onDeleteProduct({
        uuid: type.uuid,
        data,
        successCB: () => {
          setShowModal(false);
          setSuccessMessage(`${type.name} has been deleted.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal(false)}
      />
      <h2>Delete policy type?</h2>
      <div className='sub-heading'>
        There would be opportunities and policies associated to this policy
        type. You can either move these policy types to a different category or
        keep it as is.
      </div>
      <form onSubmit={handleSubmit(onDelete)}>
        <div className='mb-3'>
          <SelectField
            name='from'
            placeholder='From Policy type'
            options={customProductCategories
              .map((item) => item)
              .flat(1)
              .map((item) => {
                return {
                  label: item.name,
                  value: item.uuid,
                };
              })}
            control={control}
            isDisabled={true}
          />
        </div>
        <SelectField
          name='destination'
          placeholder='To Policy type'
          options={product
            .map((item) => item)
            .flat(1)
            .filter((item) => item.uuid !== type.uuid)
            .map((item) => {
              return {
                label: item.name,
                value: item.uuid,
                type: item.type,
              };
            })}
          control={control}
        />
        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowModal();
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>

          <button
            className='WarningButton d-flex align-items-center justify-content-between'
            type='submit'
          >
            {watchDestination ? 'Move and Delete' : 'Delete'}
            <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledDeleteModal>
  );
};

export default DeletePolicyType;

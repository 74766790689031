import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  fetchXDateReasons,
  reasonsSliceState,
  selectXDateReasons,
} from '../../../store/reasons/reasonsSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import SuccessCard from '../../../components/SuccessCard';
import AddXDateReasonModal from './AddXDateReasonModal';
import EditXDateReasonModal from './EditXDateReasonModal';
import DeleteXDateReasonModal from './DeletXDateReasonModal';
import { IXDateReason } from '../../../interfaces/Lead';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import plus from '../../../assets/logo/plus.svg';
import close from '../../../assets/logo/cross.svg';
import drop from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import search from '../../../assets/logo/search.svg';

const XDateReasons = () => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const xDateReasons = useSelector(selectXDateReasons);

  const searchRef = useRef(null);

  const [showAddXDateReason, setShowAddXDateReason] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');

  useOutsideClick(searchRef, setIsSearchClicked);

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    dispatch(fetchXDateReasons({ uuid, searchBy })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const loadingStatus = useSelector(
    (state: { reasons: reasonsSliceState }) => state.reasons.status
  );

  return (
    <div className='settings-div'>
      <SuccessAlign>
        <div
          className={`success-container ${
            successMessage.length > 0 && 'open-drawer'
          } `}
        >
          <SuccessCard title={`<b>Success!</b>`} message={successMessage} />
          <button
            onClick={() => {
              setSuccessMessage('');
            }}
            className='crossbutton'
          >
            <img src={close} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <div className='d-flex justify-content-between'>
        <button
          className='primaryButton addButton xDateButton d-none d-sm-flex align-items-center justify-content-between'
          onClick={() => setShowAddXDateReason(true)}
        >
          Add X-date reason <img alt='' src={plus} />
        </button>
        <button
          className='primaryButton addButton d-sm-none align-items-center justify-content-between'
          onClick={() => setShowAddXDateReason(true)}
        >
          <img alt='' src={plus} />
        </button>
        <div className='book-filter d-flex' ref={searchRef}>
          {isSearchClicked || searchBy || window.innerWidth < 576 ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}
        </div>
      </div>
      <div className='d-flex flex-wrap cards-container'>
        {loadingStatus === 'loading' ? (
          <>
            <div className='col-12 p-1'>
              <Skeleton height={70} />
            </div>
            <div className='col-12 p-1'>
              <Skeleton height={70} />
            </div>{' '}
            <div className='col-12 p-1'>
              <Skeleton height={70} />
            </div>
          </>
        ) : xDateReasons.length === 0 ? (
          <div
            className='reason-card w-100 d-flex justify-content-center align-items-center'
            style={{ height: 200 }}
          >
            <h6 className='text-center'>No matching reasons found.</h6>
          </div>
        ) : (
          xDateReasons.map((item) => (
            <ReasonCard
              item={item}
              setSuccessMessage={setSuccessMessage}
              key={item.uuid}
            />
          ))
        )}
      </div>
      <AddXDateReasonModal
        setShowAddXDateReason={setShowAddXDateReason}
        showAddXDateReason={showAddXDateReason}
        setSuccessMessage={setSuccessMessage}
      />
    </div>
  );
};

export default XDateReasons;

const ReasonCard = ({
  item,
  setSuccessMessage,
}: {
  item: IXDateReason;
  setSuccessMessage: Function;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, () => setShowDropdown(false));

  return (
    <div className='reason-card w-100 d-flex justify-content-between align-items-center'>
      <h6>{item.name}</h6>
      {!item.isDefault && (
        <>
          <div
            className='d-flex align-items-center justify-content-center d-sm-none drop-box'
            onClick={() => setShowDropdown(true)}
            ref={dropRef}
          >
            <img src={drop} alt='' />
            {showDropdown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div d-flex align-items-center justify-content-between'
                  onClick={() => {
                    setShowEditModal(true);
                    setShowDropdown(false);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='delete-div d-flex align-items-center justify-content-between'
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </div>
          <div className='d-none d-sm-flex'>
            <button
              className='tertiaryButton'
              onClick={() => setShowEditModal(true)}
            >
              Edit
            </button>

            <button
              className='a_deleteButton'
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </button>
          </div>
        </>
      )}
      <EditXDateReasonModal
        xDateReason={item}
        setShowEditXDateReason={setShowEditModal}
        showEditXDateReason={showEditModal}
        setSuccessMessage={setSuccessMessage}
      />
      <DeleteXDateReasonModal
        xDateReason={item}
        setShowModal={setShowDeleteModal}
        setSuccessMessage={setSuccessMessage}
        showModal={showDeleteModal}
      />
    </div>
  );
};

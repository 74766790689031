import axios from './axios';
import { ILocation } from '../interfaces/Location';

const getLocations = (
  sortBy?: string,
  orderBy?: string,
  searchBy?: string,
  limit?: number,
  offset?: number,
  minimal?: string,
  agencyUUID?: string
) => {
  return axios
    .get('/location', {
      params: { agencyUUID, sortBy, orderBy, searchBy, limit, offset, minimal },
    })
    .then((res) => {
      return res;
    });
};

const getPrimaryLocations = (agencyUUID?: string) => {
  return axios
    .get('/location/primaryLocation', { params: { agencyUUID } })
    .then((res) => {
      return res.data;
    });
};

const addLocation = (
  data: ILocation,
  successCB: Function,
  errorCB: Function
) => {
  return axios.post('/location', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response);
      return error.res;
    }
  );
};

const fetchLocation = (uuid: string, agencyUUID?: string) => {
  return axios
    .get(`/location/${uuid}`, { params: { agencyUUID } })
    .then((res) => {
      return res;
    });
};

const UpdateLocation = (
  data: ILocation,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`location/${uuid}`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response);
      return error.res;
    }
  );
};

const deleteLocation = (
  uuid: string,
  locationUUID: string,
  agencyUUID?: string,
  agencyUpdate?: boolean
) => {
  return axios
    .delete(`location/${uuid}`, {
      data: { locationUUID, agencyUUID, agencyUpdate },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
};

export {
  getLocations,
  addLocation,
  fetchLocation,
  UpdateLocation,
  deleteLocation,
  getPrimaryLocations,
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { clearUser } from '../../../store/user/userSlice';
import { logOut } from '../../../api/user';
import LocalStorageService from '../../../utils/LocalStorageService';
import { StyledDeleteModal } from '../../Admin/elements/styles';
import cross from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';

const LogoutModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { handleSubmit, register } = useForm({});
  const [loading, setLoading] = useState(false);

  const onDelete = (data: any) => {
    if (data.logout === 'logout') {
      logOut(
        () => {
          dispatch(clearUser());
          LocalStorageService.clearToken();
          navigate('/');
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setShowModal(false);
    }
  };

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal(false)}
      />
      <h2>Password updated</h2>
      <div className='sub-heading'>
        To make sure your account is secure, we can log you out of all the
        devices that you are currently logged in. You can log back in with your
        new password.
      </div>
      <form onSubmit={handleSubmit(onDelete)}>
        <div className='mb-3'>
          <label className='radio-container'>
            <input
              type='radio'
              {...register('logout')}
              value={'logout'}
              className='form-check-input'
            />
            Log me out of all devices
          </label>
        </div>
        <div className='mb-3'>
          <label className='radio-container'>
            <input
              type='radio'
              {...register('logout')}
              defaultChecked
              name='logout'
              value={'stay'}
              className='form-check-input'
            />
            Keep me logged in
          </label>
        </div>

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            className='WarningButton d-flex align-items-center justify-content-between'
            type='submit'
            disabled={loading}
          >
            Continue
            <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledDeleteModal>
  );
};

export default LogoutModal;

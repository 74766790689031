import styled from '@emotion/styled';

export const PickerDiv = styled.div`
  .CallenderImg {
    position: absolute;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
  }
  display: flex;
  height: 32px;
  align-items: center;
  background: #ffffff;
  padding-right: 10px;
  padding-left: 0px;
  border: none;
  border-radius: 8px;
  position: relative;
  label {
    position: absolute;
    color: #262758;
    pointer-events: none;
    transform: translate(0, 2px);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 14px;
    left: 16px;
    font-weight: 600;
  }
  &.filled-picker {
    border: 1px solid #262758;
  }
  .time-input {
    padding: 70px;
  }
  &:hover {
    border: none;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  }
  .date-input-placeholder {
    display: none;
  }
  .input-container {
    &:hover {
      border: none;
    }
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker {
    width: 280px;
    border-radius: 8px;
    right: 0px;
    border: none;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
  }
  .react-datepicker__month-container {
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    background: #fff;
  }
  .react-datepicker__header {
    background: #fff;
    border: none;
  }
  .react-datepicker__current-month {
    color: #262758;
    font-size: 20px;
    text-align: center;
    line-height: 17.5px;
    button {
      border: 0;
      background: #fff;
      width: 20px;
      height: 21px;
      padding: 0;

      :disabled {
        opacity: 0.2;
      }
    }

    .date-info {
      padding: 0 5px;
    }
  }
  .react-datepicker__month {
    text-align: unset;
    width: 100%;
    margin: 0;
  }

  .react-datepicker__day-names {
    margin: 0;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: #3c498a;
    font-size: 14px;
  }
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__navigation {
    top: 33px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--today {
    position: relative;
    :before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #f04d46;
      display: block;
      position: absolute;
      top: 22px;
      left: 10px;
    }
    font-weight: 600;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day--outside-month {
    color: #cad9f3;
  }
  .react-datepicker__day--selected {
    background: #c1dbeb;
    border-radius: 50%;
    font-weight: 700;
    color: #262758;
  }
  .react-datepicker__day--keyboard-selected {
    background: #c1dbeb;
    border-radius: 50%;
    color: #262758;
  }
  .react-datepicker__time-box {
    text-align: start;
  }
  .react-datepicker__time-container {
    text-align: start;
  }
  .date-input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 4px 2px;
    outline: none;
    border-radius: 8px;
    border: 0;
    font-size: 12px;
    background: #fff !important;
    font-weight: 600;
    &:hover {
      border: none;
      background: #f5f7fc !important;
    }
    &:focus-within {
      background: #f5f7fc !important;
    }
  }
  .filled {
    label {
      display: none;
      font-size: 12px;
      transform: translate(0, -10px);
      font-weight: 400;
    }
  }
  :hover {
    background: #f5f7fc !important;
    .date-input {
      background: #f5f7fc !important;
    }
  }
  :focus-within {
    label {
      display: none;
    }
    border: none;

    .input-container {
      label {
        display: none;
        font-size: 12px;
        transform: translate(0, -10px);
        font-weight: 400;
      }
    }
  }
  @media screen and (min-width: 567px) {
    .react-datepicker {
      width: 300px;
      right: 12px;
    }
  }

  @media screen and (min-width: 1900px) {
    height: 32px;
    .react-datepicker {
      width: 300px;
      right: 20px;
    }
  }
`;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAudit } from '../../api/audit';
import { IAudit } from '../../interfaces/Audit';

export type auditsSliceState = {
  status: string;
  isLoading: boolean;
  audits: IAudit[];
};
interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  resource?: any;
  resourceUUID?: any;
  successCB: Function;
  offset: number;
}

const initialState: auditsSliceState = {
  status: 'idle',
  isLoading: false,
  audits: [],
};

export const getAudits = createAsyncThunk(
  'audits/getAudits',
  async ({
    sortBy,
    orderBy,
    searchBy,
    resource,
    resourceUUID,
    successCB,
    offset,
  }: IProps) => {
    const response = await fetchAudit(
      sortBy,
      orderBy,
      searchBy,
      resource,
      resourceUUID,
      offset
    );
    if (successCB) {
      successCB(response.data);
    }
    return response.data;
  }
);

const auditsSlice = createSlice({
  name: 'audits',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearHistory: (state) => {
      state.audits = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAudits.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAudits.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.audits = [...state.audits, ...action.payload];
      })
      .addCase(getAudits.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, clearHistory } = auditsSlice.actions;

export default auditsSlice.reducer;

export const selectAllAudits = (state: { audits: auditsSliceState }) =>
  state.audits?.audits;

export const scrollToError = (errorMap: Map<string, string>) => {
  let firstError: string = '';

  // @ts-ignore
  for (let [key, value] of errorMap.entries()) {
    if (value !== '') {
      firstError = key;
      break;
    }
  }
  if (firstError) {
    const arrayOfElements = Array.from(document.getElementsByName(firstError));

    if (arrayOfElements?.[0]) {
      let elementToScrollTo: HTMLElement | null = arrayOfElements[0];

      if (elementToScrollTo?.getAttribute('type') === 'hidden') {
        elementToScrollTo = document.getElementById(firstError);
      }

      elementToScrollTo?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
};

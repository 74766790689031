import moment from 'moment';
import { IContact } from '../../../interfaces/Contact';
import edit from '../../../assets/logo/edit.svg';
import { contacts } from '../../../assets/nav-icons';

const ContactDetailsDrawer = ({
  contactDetails,
  setShowEditModal,
}: {
  contactDetails?: IContact;
  setShowEditModal: Function;
}) => {
  //   const navigate = useNavigate();

  return (
    <div className='info-div'>
      <div className='details-div'>
        <div className='d-flex align-items-center'>
          <h5 className='section-title '>Details</h5>
          <img
            className='editIcon'
            src={edit}
            alt='edit'
            onClick={() => setShowEditModal('open')}
          />
        </div>

        <div className='sub-section'>
          <div className='subsection-title'>Created At</div>
          <div>{moment(contactDetails?.createdAt).format('MM/DD/YYYY')}</div>
          <div className='subsection-title'>Email</div>
          <div>
            {
              contactDetails?.ContactDetails?.find(
                (item) => item.type === 'EMAIL'
              )?.value
            }
          </div>

          <div className='subsection-title'>Phone Number</div>
          <div>
            {
              contactDetails?.ContactDetails?.find(
                (item) => item.type === 'PHONENUMBER'
              )?.value
            }
          </div>
          <div className='subsection-title'>License State</div>
          <div>
            {contactDetails?.Jurisdiction?.name
              ? contactDetails?.Jurisdiction?.name
              : '---'}
          </div>
          <div className='subsection-title'>License Number</div>
          <div>
            {contactDetails?.driverlicenseNumber
              ? contactDetails?.driverlicenseNumber
              : '---'}
          </div>
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title'>Team</h5>
        <div className='sub-section'>
          <div>
            <img src={contacts} alt='' className='contact-img' />
            Producer: {contactDetails?.Agent?.User?.firstName}{' '}
            {contactDetails?.Agent?.User?.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsDrawer;

import styled from '@emotion/styled';
export const CardDiv = styled.div`
  .opportunity {
    margin-top: 5px;
    width: 100%;
    box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
    border-radius: 16px;
    padding: 18px 8px 16px 18px;
    .documentDate {
      max-width: 110px;
    }
    .documentAmmountDetails {
      width: 50%;
    }
    .vector {
      background: #f5f7fc;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      border: 0;
      flex-shrink: 0;
      :hover,
      :focus {
        background: #e6e9f0;
      }
    }

    .quote-stage-pill {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #191919;
      background: #e6e9f0;
      border-radius: 50px;
      margin-left: 12px;
      padding: 4px 16px;
      height: 24px;
      text-transform: capitalize;
    }

    .green-bg {
      color: #3d8a68;
      background: #d4fad4;
    }
    .danger-bg {
      color: #9a0a0d;
      background: #f9e4d2;
    }
    .blue-bg {
      color: #191919;
      background: #ecf4f9;
    }
    .yellow-bg {
      color: #914123;
      background: #fcf2cf;
    }

    .topContent {
      .topDetails {
        .oppstatus {
          text-transform: capitalize;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 600;
          padding: 0px 4px;
          height: 20px;
        }
        .greenC {
          background: #d4fad4;
          color: #3d8a68;
        }
        .yellowC {
          background: #fcf2cf;
          color: #914123;
        }
        .oppName {
          font-weight: 600;
          font-size: 18px;
        }
        .carrierName {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
    .quotesCount {
      width: 80px;
    }

    .borderDiv {
      border-right: 1px solid #d1d6e5;
      margin-right: 4px;
    }
    .mobileProfile {
      width: 32px;
      height: 32px;
      font-size: 12px;
      padding-left: 6px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .mobileProfileAccount {
      width: 32px;
      height: 32px;
      font-size: 12px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .imageCard {
      width: 40px;
      height: 40px;
      background: #f5f7fc;
      border-radius: 8px;
    }
    .oppDetaislfields {
      width: 100%;
    }
    .oppDetaislfieldsQuotes {
      width: 100%;
      padding: 17px 24px 18px;
    }
    .valueField {
      font-size: 12px;
      padding: 0 10px 0 0px;
      max-width: 300px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addedBY {
      padding: 4px 0px 0px 0px;
      width: 200px;
    }
    .valueSpan {
      font-weight: 600;
      font-size: 12px;
    }

    .valHead {
      color: #262758;
      font-size: 12px;
      margin-right: 10px;
      max-width: 300px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .DocumentHead {
      padding: 0 10px 0 0;
      margin: 0;
    }
    .detailsQuotes {
      margin-top: 12px;
    }
    .documentField {
      padding: 4px 1 0px;
    }
    .quotingbtn {
      height: 32px;
      padding: 4px 39px;
      margin-right: 5px;
      width: 100%;
      font-size: 14px;
    }
    .editquotingbtn {
      height: 32px;
      padding: 4px 44px;
      margin-right: 10px;
      width: 100%;
    }
    .editquoting-btn {
      height: 32px;
      padding: 4px 20px;
      margin-right: 10px;
      width: 232px;
    }
    .viewbtn {
      height: 32px;
      padding: 4px 10px;
      margin-right: 10px;
    }
    .account-premium {
    }
    .actionMenu {
      width: 32px;
      height: 32px;
      position: relative;
      padding-left: 2px;
      border-radius: 8px;
      background: #f5f7fc;
    }
    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 0;
      top: 35px;
      font-size: 14px;
      z-index: 4;
      width: 170px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .delete-div {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }

      .quotediv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          filter: invert(1);
          width: 19px;
        }
      }
      .edit-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
    }
    .button-div {
      margin-left: 62px;
    }

    .addIcon {
      margin: 0;
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
    .arrowIcon {
      margin-left: 6px;
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
    .quotesName {
      margin-left: 20px;
    }
    .PinCard {
      padding: 14px 24px 14px 0px;
    }
    .Card {
      padding-left: 0px;
      border-right: 1px solid #d1d6e5;
    }
    .PremiumDiv {
      padding: 14px 35px 14px 0px;
      margin-right: 32px;
    }
    .QuoteHead {
      font-weight: 600;
      font-size: 20px;
    }
    .number {
      background: #e6e9f0;
      font-size: 12px;
      padding: 4px 4px;
      margin-right: 10px;
      margin-left: 4px;
      border-radius: 8px;
    }
    .dateEffective {
      padding: 0;
    }
    .quotesCount {
      padding-right: 20px;
    }
    .addbutton-mob {
      position: fixed;
      bottom: 80px;
      border: none;
      width: 48px;
      height: 48px;
      right: 20px;
      background: #262758;
      border-radius: 50%;
      z-index: 1;
      .dropUp {
        position: absolute;
        width: 144px;
        height: 126px;
        top: -120px;
        z-index: 5;

        padding: 10px 12px 5px 5px;
        left: -110px;
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
        border-radius: 8px;
        .tasks {
          padding: 0px 0px 2px 6px;
          border-bottom: 1px solid #e6e9f0;
          border-radius: 8px;
        }
        .tasks:hover {
          background: #ecf4f9;
        }
      }
    }
  }
  .account-head {
    font-weight: 600;
    font-size: 12px;
  }
  .premium-div {
    font-weight: 400;
    font-size: 12px;
    color: #5a5f70;
    margin-left: 4px;
  }
  .contact-div {
    margin-top: 12px;
  }
  .divider {
    color: #e6e9f0;
    padding: 5px;
  }
  .vectorIMage {
    width: 15px;
  }
  @media screen and (min-width: 576px) {
    .opportunity {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 0px 24px;
      .topContent {
        padding: 24px 0px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 45%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 20px;
            padding: 0px 4px;
            height: 20px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            font-weight: 600;
            font-size: 24px;
            max-width: 50%;
            margin-left: 20px;
          }
          .carrierName {
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
      }
      .oppDetaislfields {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .accountdetailsQuotes {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .valueField {
        font-weight: 600;
      }
      .accountText {
        font-size: 14px;
        color: #000000;
      }
      .valueText {
        font-weight: 400;
        color: #262758;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        height: 32px;
        padding: 4px 20px;
      }
      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }

      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 14px 24px 14px 0px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 14px 0px;
        margin-right: 32px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 16px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        padding: 4px 4px;
        margin-right: 10px;
        margin-left: 4px;
        border-radius: 8px;
      }
      .oppHead {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .opportunity {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 0px 24px;
      .topContent {
        padding: 24px 0px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 35%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 20px;
            padding: 0px 4px;
            height: 20px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            font-weight: 600;
            font-size: 24px;
            max-width: 50%;
            margin-left: 20px;
          }
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
      }
      .oppDetaislfields {
        width: 55%;
        padding: 17px 24px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .valueField {
        font-weight: 600;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        padding: 4px 20px;
        width: 100%;
      }

      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }
      .addIcon {
        filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
          brightness(0%) contrast(103%);
      }
      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 14px 24px 14px 0px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 14px 0px;
        margin-right: 32px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 16px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        padding: 4px 4px;
        margin-right: 10px;
        margin-left: 4px;
        border-radius: 8px;
      }
    }
  }
`;

import styled from '@emotion/styled';
export const CardMobileDiv = styled.div`
  .vector .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    top: 35px;
    font-size: 14px;
    z-index: 4;
    width: 170px;
    cursor: pointer;

    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .quotediv {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  .vector {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    border-radius: 8px;
    background: #f5f7fc;
    cursor: pointer;
    position: relative;
    border: none;
  }
  .opportunity {
    margin-top: 10px;
    width: 100%;
    box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 16px 16px 16px 20px;
    .topContent {
      .topDetails {
        width: 100%;
        .oppstatus {
          text-transform: capitalize;
          border-radius: 8px;
          font-size: 12px;
          padding: 4px 8px;
          height: 30px;
          h6 {
            font-weight: 600;
          }
        }
        .greenC {
          background: #d4fad4;
          color: #3d8a68;
        }
        .yellowC {
          background: #fcf2cf;
          color: #914123;
        }
        .oppName {
          font-weight: 600;
          font-size: 18px;
          max-width: 172px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .borderDiv {
      border-right: 1px solid #d1d6e5;
    }
    .mobileProfile {
      width: 32px;
      height: 32px;
      font-size: 12px;
      padding-left: 6px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .mobileProfileAccount {
      width: 32px;
      height: 32px;
      font-size: 12px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .imageCard {
      width: 40px;
      height: 40px;
      background: #f5f7fc;
      border-radius: 8px;
    }
    .oppDetaislfields {
      width: 100%;
    }
    .AccountHead {
      width: 100%;
    }
    .oppDetaislfieldsQuotes {
      width: 100%;
      padding: 17px 24px 18px;
    }
    .valueField {
      font-size: 12px;
      padding: 0 10px 0 8px;
    }
    .valHead {
      color: #262758;
      font-size: 12px;
      margin-top: 13px;
      margin-right: 10px;
    }
    .ammountDetails {
      margin-top: 5px;
    }

    .quotingbtn {
      font-size: 14px;
      height: 32px;
      padding: 4px 15px;
    }
    .actionMenu {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      border-radius: 8px;
      background: #f5f7fc;
    }
    .addIcon {
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
    .quotesName {
      margin-left: 20px;
    }
    .PinCard {
      padding: 14px 24px 14px 0px;
    }
    .Card {
      padding-left: 0px;
      border-right: 1px solid #d1d6e5;
    }
    .PremiumDiv {
      padding: 14px 35px 14px 0px;
      margin-right: 32px;
    }
    .QuoteHead {
      font-weight: 600;
      font-size: 20px;
    }
    .number {
      background: #e6e9f0;
      font-size: 12px;
      padding: 4px 4px;
      margin-right: 10px;
      margin-left: 4px;
      border-radius: 8px;
    }
    .dateEffective {
      padding: 0;
    }
    .DocumentHead {
      color: #262758;
    }
    .policyStatus {
      margin-top: -1px;
    }

    .policy-stage-pill {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      background: #e6e9f0;
      border-radius: 50px;
      margin-left: 12px;
      padding: 4px 16px;
      height: 24px;
      text-transform: capitalize;
      margin-top: 8px;
    }
    .green-bg {
      color: #3d8a68;
      background: #d4fad4;
    }
    .danger-bg {
      color: #9a0a0d;
      background: #f9e4d2;
    }
    .grey-bg {
      background: #e6e9f0;
      color: #191919;
    }
  }
`;

export const CardDiv = styled.div`
  .vector .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    top: 35px;
    font-size: 14px;
    z-index: 4;
    width: 170px;
    cursor: pointer;

    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .quotediv {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .vector {
    width: 42px;
    height: 42px;
    margin-left: 20px;
    border-radius: 8px;
    background: #f5f7fc;
    cursor: pointer;
    position: relative;
    border: none;
  }
  .buttnsContainer {
    margin-top: 10px;
    padding: 27px 24px 18px;
  }

  .valHeaddetails {
    padding: 5px 0px;
  }
  .detailsStatusText {
    padding: 8px 4px;
  }
  .policyNumdiv {
    font-size: 14px;
    font-weight: 600;
  }
  .policyStatus {
    margin-top: -20px;
  }

  .policy-stage-pill {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: #e6e9f0;
    border-radius: 50px;
    margin-left: 12px;
    padding: 4px 16px;
    height: 24px;
    text-transform: capitalize;
    margin-top: 8px;
  }
  .green-bg {
    color: #3d8a68;
    background: #d4fad4;
  }
  .danger-bg {
    color: #9a0a0d;
    background: #f9e4d2;
  }
  .grey-bg {
    background: #e6e9f0;
    color: #191919;
  }

  @media screen and (min-width: 570px) {
    padding: 4px;
    .opportunity {
      margin: 10px 0px 0 0px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 18px 24px;
      .topContent {
        padding: 0px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 66%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 8px;
            margin-left: 20px;
            padding: 4px 8px;
            height: 26px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            cursor: pointer;
            font-weight: 600;
            font-size: 24px;
            max-width: 60%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 20px;
            &.opp-width {
              max-width: 100%;
            }
          }
          .oppName-head {
            max-width: 300px;
          }
          .oppName:hover {
            text-decoration: underline;
          }
          .profile-pic {
            width: 40px;
            height: 40px;
          }
          .profilePicDiv {
            width: 40px;
            height: 40px;
          }
        }
        .profile-pic {
          font-size: 14px;
          width: 32px;
          height: 32px;
        }
        .profilePicDiv {
          font-size: 12px;
          width: 32px;
          height: 32px;
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
        padding: 2px;
        img {
          width: 100%;
          max-height: 100%;
          min-width: 36px;
        }
      }
      .oppDetaislfields {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .valueField {
        color: #000000;
        font-weight: 600;
        max-width: 300px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        height: 32px;
        padding: 4px 20px;
      }

      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }
      .addIcon {
        filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
          brightness(0%) contrast(103%);
      }
      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 14px 24px 14px 0px;
      }
      .CarrierPr {
        height: 40px;
        width: 40px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 4px 0px;
        margin-right: 32px;
        margin-bottom: 8px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 20px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        overflow-wrap: break-word;
        padding: 2px 8px;
        margin-right: 10px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        .tooltiptext {
          visibility: hidden;
          width: 176px;
          background: #191919;
          color: #d1d6e5;
          padding: 5px;
          border-radius: 8px;
          font-size: 14px;
          text-align: left;
          position: absolute;
          z-index: 1;
          top: 30px;
          right: -8px;
          ::after {
            content: ' ';
            position: absolute;
            top: -10px;
            right: 12px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #191919 transparent;
          }
        }
        :hover {
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }
    .ooportunityquotingbtn {
      width: 20%;
    }
    .CarrierImage {
      height: 40px;
    }
    .detailsStatusText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .opportunityList {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 1200px) {
    .opportunity {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 0px 24px;
      .topContent {
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 56%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 20px;
            padding: 4px 8px;
            height: 26px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            font-weight: 600;
            font-size: 24px;
            margin-left: 20px;
          }
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
      }
      .oppDetaislfields {
        width: 55%;
        padding: 17px 0px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .valueField {
        font-weight: 600;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        height: 42px;
        padding: 8px 20px;
      }
      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }
      .addIcon {
        filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
          brightness(0%) contrast(103%);
      }
      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 4px 14px 4px 0px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 14px 0px;
        margin-right: 32px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 20px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        padding: 2px 8px;
        margin-right: 10px;
        border-radius: 9px;
      }
    }
  }
`;

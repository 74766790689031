import styled from '@emotion/styled';

export const SearchContainer = styled.div`
  position: relative;
  .search-input {
    color: #000;
  }
  .carrierlogo-icon {
    width: 48px;
    height: 48px;
    background: rgb(245, 247, 252);
    margin-right: 8px;
    padding: 2px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 0;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
  .search-group {
    position: relative;
    .input-placeholder {
      font-size: 16px;
      left: 10px;
    }
    .filled .input-placeholder {
      font-size: 12px;
    }
    .filled.input-container.edit-search {
      border: none;
    }
  }
  .small-field {
    background: #fff;
    .smallFieldsLabel {
      color: #5a5f70;
    }
    border: none;
    :hover {
      .input-field {
        background: #f5f7fc !important;
      }
      background: #f5f7fc !important;
    }
  }
  .search-button,
  .close-button,
  .drop-button {
    position: absolute;
    right: 13px;
    top: calc(50% - 10px);
  }
  .smallFieldsIcon {
    display: none;
  }

  .drop-button {
    top: calc(50% - 3px);
  }
  .searchValues-container {
    box-shadow: 0px 2px 8px rgb(35 42 64 / 20%);
    border-radius: 8px;
    padding: 4px;
    max-height: 256px;
    overflow: hidden auto;
    position: absolute;
    z-index: 1000;
    background: #fff;
    width: 100%;
    min-width: 101%;
    &::-webkit-scrollbar {
      width: 6px;
      height: 159px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
      border-radius: 32px;
    }
  }
  .smallSearchvalue {
    overflow-y: auto;
    width: 280px;
    top: 55px;
    z-index: 10000;
    right: 0px;
    h6 {
      overflow: hidden;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .input-field:disabled {
    background: #f5f7fc !important;
    color: #8990a3;
    &::placeholder {
      color: #8990a3;
    }
    ~ label {
      color: #8990a3;
    }
  }
  .input-field {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .searchValuesCard {
    min-height: 56px;
    height: auto;
    width: 288px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    border-bottom: 1px solid #e6e9f0;
    padding: 0 12px;
    &.account-details {
      font-size: 14px;
    }
    &.account-search-card {
      overflow: unset;
    }
    > div:last-child {
      width: calc(100% - 50px);
    }
    .name-text-overflow {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h6 {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .profile-pic,
  .searchValues-container .searchValuesCard .profile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    border-radius: 46px;
    margin-right: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  img.profile-pic {
    background: none;
  }

  .loader {
    width: 20px;
    height: 20px;
    background: linear-gradient(#3c498a00, #3c498a);
    /* Show only 10px from the border */
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 4px),
      #fff 0
    );
    mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #fff 0);
    border-radius: 50%;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }

  .description {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 0;
  }

  .opportunity-producer {
    .user-details li {
      font-size: 10px;
      line-height: 10px;
    }
    .profile-pic {
      margin-right: 3px;
    }
    &.searchValuesCard {
      padding: 0;
    }
  }
  .input-container {
    label {
      font-size: 12px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .user-details {
    display: block;
    margin: 0;
    padding: 0;

    li {
      font-size: 12px;
      line-height: 16px;
      display: inline-block;

      &:first-child {
        margin-right: 5px;
        border-right: 1px solid #262758;
        padding-right: 5px;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .searchValuesCard {
      width: 400px;
    }
    .smallSearchvalue {
      overflow-y: auto;
      width: 320px;
      left: -50px;
      h6 {
        overflow: hidden;
        width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .small-field {
      .input-field {
        width: 120px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .input-container {
      label {
        font-size: 12px;
      }
    }
    .smallFieldsIcon {
      top: 14px;
    }
  }
  @media screen and (min-width: 1900px) {
    .input-container {
      .smallFieldsLabel {
        color: #5a5f70;
        top: -4px;
        font-size: 16px;
        :focus {
          top: -3px;
        }
      }
    }
    .smallSearchvalue {
      left: 0px;
    }
    .filled {
      .smallFieldsLabel {
        top: 0px;
      }
    }
    .close-button {
      top: 16px;
    }
  }
`;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addAgency, fetchAgencies, updateAgencyDetails } from '../../api/admin';
import { IAddAgency, IAgency } from '../../interfaces/Agency';

export type AgenciesSliceState = {
  status: string;
  agencies: IAgency[];
  isLoading: boolean;
  count: number;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  userGroup?: string[];
}

const initialState: AgenciesSliceState = {
  status: 'idle',
  agencies: [],
  isLoading: false,
  count: 0,
};

export const getAgencies = createAsyncThunk(
  'agencies/getAgencies',
  async ({ sortBy, orderBy, searchBy, limit, offset, userGroup }: IProps) => {
    const response = await fetchAgencies(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      userGroup
    );
    return response.data;
  }
);

export const addNewAgency = createAsyncThunk(
  'agencies/addNewAgency',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: IAddAgency;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addAgency(data, successCB, errorCB);
    return response.data;
  }
);

export const updateAgencyInfo = createAsyncThunk(
  'agencies/updateAgencyInfo',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await updateAgencyDetails(uuid, data, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

const agenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAgencies.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAgencies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agencies = action.payload.agencies;
        state.count = action.payload.count;
      })
      .addCase(getAgencies.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addNewAgency.fulfilled, (state, action) => {
        state.agencies.unshift(action.payload);
        state.isLoading = false;
      })
      .addCase(addNewAgency.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewAgency.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAgencyInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.agencies.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.agencies[index] = action.payload;
      })
      .addCase(updateAgencyInfo.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateAgencyInfo.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus } = agenciesSlice.actions;

export default agenciesSlice.reducer;

export const selectAllAgencies = (state: { agencies: AgenciesSliceState }) =>
  state.agencies.agencies;

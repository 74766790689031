import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { ActionsDropdown } from '../../Admin/GoalSettings/components/UserGoal';
import { CardDiv } from '../../../styles/CardDiv';
import { MobileCard } from '../style';
import SetGoalModal from '../../MyTeam/components/SetGoalDrawer';

const LeaderCard = ({
  leader,
  index,
  isMobile,
}: {
  leader: any;
  index: number;
  isMobile?: boolean;
}) => {
  const [showGoalModal, setShowGoalModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [scroll, setScroll] = useState(false);
  return (
    <>
      {isMobile ? (
        <MobileCard>
          <div className='d-flex justify-content-between name-container'>
            <div className='w-100 text-overflow-container'>
              {leader.rank}. <b className='name-text'>{leader.name}</b>
              <span className='tooltiptext'>
                <span className='tooltipInnertext'>
                  {leader.name ? leader.name : '--'}
                </span>
              </span>
            </div>
            <ActionsDropdown
              agentUUID={leader.agentUUID}
              setUUID={setUUID}
              scroll={scroll}
              setShowGoalModal={setShowGoalModal}
            />
          </div>
          <div>
            <ProgressBar
              className='leader-progress-bar'
              now={leader.premium}
              label={`${leader.premium}%`}
              visuallyHidden
            />
            <div className='d-flex justify-content-between'>
              <span className='premium-goal-text'>Premium goal</span>
              <label className='leader-text'>
                $
                {leader.policyPremium
                  ? leader.policyPremium.toLocaleString('en-US')
                  : 0}
                /${leader.goalPremium.toLocaleString('en-US')}
              </label>
            </div>
          </div>
          <div className='mt-4'>
            <ProgressBar
              className='leader-progress-bar'
              now={leader.policy}
              label={`${leader.policy}%`}
              visuallyHidden
            />
            <div className='d-flex justify-content-between'>
              <span className='premium-goal-text'>Policy count</span>
              <label className='leader-text'>
                {leader.policyCount ? leader.policyCount : 0}/
                {leader.goalPolicy}
              </label>
            </div>
          </div>
        </MobileCard>
      ) : (
        <CardDiv>
          <td className='name-td'>{leader.rank}</td>
          <td className=' text-overflow-container'>
            <b className='name-text'>{leader.name}</b>
            <span className='tooltiptext'>
              <span className='tooltipInnertext'>
                {leader.name ? leader.name : '--'}
              </span>
            </span>
          </td>

          <td className='d-xl-table-cell'>
            <ProgressBar
              className='leader-progress-bar'
              now={leader.premium}
              label={`${leader.premium}%`}
              visuallyHidden
            />
            <label className='leader-text'>
              $
              {leader.policyPremium
                ? leader.policyPremium.toLocaleString('en-US')
                : 0}
              &nbsp;out of ${leader.goalPremium.toLocaleString('en-US')}
            </label>
          </td>

          <td className='d-xl-table-cell'>
            <ProgressBar
              className='leader-progress-bar'
              now={leader.policy}
              label={`${leader.policy}%`}
              visuallyHidden
            />
            <label className='leader-text'>
              {leader.policyCount ? leader.policyCount : 0}
              &nbsp;out of {leader.goalPolicy}
            </label>
          </td>
          <td className='d-xl-table-cell'>
            <div className='d-flex align-items-center justify-content-between h-100 actionField'>
              <ActionsDropdown
                agentUUID={leader.agentUUID}
                setUUID={setUUID}
                scroll={scroll}
                setShowGoalModal={setShowGoalModal}
              />
            </div>
          </td>
        </CardDiv>
      )}
      {showGoalModal !== 'closed' && (
        <SetGoalModal
          show={showGoalModal}
          closeModal={setShowGoalModal}
          agentUUID={UUID}
        />
      )}
    </>
  );
};

export default LeaderCard;

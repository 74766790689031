import styled from '@emotion/styled';

export const PaginationDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 70px;
  padding-top: 10px;

  font-size: 14px;

  .form-select {
    background-color: #e6e9f0;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    margin-left: 10px;
  }

  button {
    background: none;
    border-radius: 8px;
    border: none;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    font-weight: 600;

    :hover {
      background: #ecf4f9;
    }
  }
  .active {
    background: #c1dbeb;
  }
`;

import { useEffect, useState } from 'react';
import FilterSelectField from '../../../components/FilterSelectField';
import Modal from '../../../components/Modal';
import cross from '../../../assets/logo/cross.svg';
import tick from '../../../assets/logo/tick.svg';
import { StyledModal } from '../../../components/FilterDrawer/styles';
import LocalStorageService from '../../../utils/LocalStorageService';
import { MobileFilterNavDiv } from '../../../components/FilterMobileDrawer/styles';
import { fetchEventType } from '../../../api/events';
import {
  fetchTaskPriority,
  fetchTaskStatus,
  fetchTaskType,
} from '../../../api/task';
import { fetchAgents } from '../../../api/agents';
import { IProducer } from '../../../interfaces/Agent';
import Skeleton from 'react-loading-skeleton';

const EventFilter = ({
  show,
  closeModal,
  filterFunction,
  eventType,
  setEventType,
  taskType,
  setTaskType,
  type,
  assignee,
  setAssignee,
  taskStatusValue,
  setTaskStatusValue,
  setTaskPriority,
  taskPriority,
}: {
  show: string;
  closeModal: Function;
  filterFunction: Function;
  eventType?: any;
  setEventType?: Function;
  taskType?: any;
  setTaskType?: Function;
  assignee?: any;
  setAssignee?: Function;
  setTaskStatusValue?: Function;
  taskStatusValue?: any;
  setTaskPriority?: Function;
  taskPriority?: any;
  type: string;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [eventTypeOp, setEventTypeOp] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const user = LocalStorageService.getUser();

  const [taskTypeOp, setTaskTypeOp] = useState([]);
  const [tastPriorityList, setTastPriorityList] = useState([]);
  const [tastStatus, setTastStatus] = useState([]);
  const [assignees, setAssignees] = useState<any>([]);

  useEffect(() => {
    setLoadingStatus('loading');
    fetchEventType((data: any) => {
      setEventTypeOp(data);
      setLoadingStatus('succeeded');
    });
    fetchTaskType((data: any) => {
      setTaskTypeOp(data);
      setLoadingStatus('succeeded');
    });
    fetchTaskPriority((data: any) => {
      setTastPriorityList(data);
      setLoadingStatus('succeeded');
    });
    fetchTaskStatus((data: any) => {
      setTastStatus(data);
      setLoadingStatus('succeeded');
    });
    fetchAgents(
      (data: IProducer[]) => {
        setAssignees(data);
        setLoadingStatus('succeeded');
      },
      'id',
      'asc',
      '',
      'True'
    );
  }, []);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      // setLoadingStatus('loading');
      filterFunction();
    }
  }, [eventType, assignee, taskType, taskPriority, taskStatusValue]);

  const filterContent = () => {
    return (
      <>
        {type !== 'task' ? (
          <>
            <div>
              <h6 className='head text-uppercase'>Event Type</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <FilterSelectField
                  options={eventTypeOp.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.uuid,
                    };
                  })}
                  placeholder='Select'
                  onChange={(value: any) => {
                    if (setEventType) setEventType(value);
                  }}
                  isMulti={true}
                  value={eventType}
                />
              </div>
            )}
          </>
        ) : null}
        {type !== 'event' ? (
          <>
            {type === 'all' ? <hr className='filter-hr' /> : null}
            <div>
              <h6 className='head text-uppercase'>Task Type</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <FilterSelectField
                  options={taskTypeOp.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.uuid,
                    };
                  })}
                  placeholder='Select'
                  onChange={(value: any) => {
                    if (setTaskType) setTaskType(value);
                  }}
                  isMulti={true}
                  value={taskType}
                />
              </div>
            )}

            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Task Priority</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <FilterSelectField
                  options={tastPriorityList.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.uuid,
                    };
                  })}
                  placeholder='Select'
                  onChange={(value: any) => {
                    if (setTaskPriority) setTaskPriority(value);
                  }}
                  isMulti={true}
                  value={taskPriority}
                />
              </div>
            )}
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Task Status</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <FilterSelectField
                  options={tastStatus.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.uuid,
                    };
                  })}
                  placeholder='Select'
                  onChange={(value: any) => {
                    if (setTaskStatusValue) setTaskStatusValue(value);
                  }}
                  isMulti={true}
                  value={taskStatusValue}
                />
              </div>
            )}
            {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
              <>
                <hr className='filter-hr' />
                <div>
                  <h6 className='head text-uppercase'>Assignee</h6>
                </div>
                {loadingStatus === 'loading' ? (
                  <Skeleton height={48} />
                ) : (
                  <div className='agency-fields'>
                    <FilterSelectField
                      options={assignees.map((item: any) => {
                        return {
                          label: item.User?.firstName,
                          value: item.User?.uuid,
                        };
                      })}
                      placeholder='Select'
                      onChange={(value: any) => {
                        if (setAssignee) setAssignee(value);
                      }}
                      value={assignee}
                    />
                  </div>
                )}
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(eventType?.length || 0) +
                          (taskPriority?.length || 0) +
                          (taskType?.length || 0) +
                          (taskStatusValue?.length || 0) +
                          (assignee?.value ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          if (setEventType) setEventType([]);
                          if (setTaskStatusValue) setTaskStatusValue([]);
                          if (setTaskPriority) setTaskPriority([]);
                          if (setTaskType) setTaskType([]);
                          if (setAssignee) setAssignee('');
                        }}
                        disabled={
                          (eventType?.length || 0) +
                            (taskPriority?.length || 0) +
                            (taskType?.length || 0) +
                            (taskStatusValue?.length || 0) +
                            (assignee?.value ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(eventType?.length || 0) +
                      (taskPriority?.length || 0) +
                      (taskType?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assignee?.value ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      if (setEventType) setEventType([]);
                      if (setTaskStatusValue) setTaskStatusValue([]);
                      if (setTaskPriority) setTaskPriority([]);
                      if (setTaskType) setTaskType([]);
                      if (setAssignee) setAssignee('');
                    }}
                    disabled={
                      (eventType?.length || 0) +
                        (taskPriority?.length || 0) +
                        (taskType?.length || 0) +
                        (taskStatusValue?.length || 0) +
                        (assignee?.value ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default EventFilter;

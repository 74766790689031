import { useRef, useEffect } from 'react';
import { StyledModal, CancelButton, ProceedButton } from './styles';
import cross from '../../assets/logo/cross.svg';
import rightArrow from '../../assets/logo/right-arrow.svg';
import deleteImg from '../../assets/illustrations/delete.svg';

const DeleteModal = ({
  showModal,
  closeModal,
  deleteFunction,
  uuid,
  loading,
  resource,
  name,
  content,
  deleteText,
}: {
  showModal: string;
  closeModal: Function;
  deleteFunction: Function;
  uuid: string;
  loading: string;
  resource: string;
  name: string;
  content?: any;
  deleteText?: string;
}) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onDelete = () => {
    deleteFunction(uuid);
  };

  return (
    <StyledModal>
      <div className='modal-container' ref={wrapperRef}>
        <button
          onClick={() => {
            closeModal('closed');
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <img src={deleteImg} alt='delete' className='delete-img' />

        <h4>Delete {resource}</h4>
        {content ? (
          content
        ) : (
          <p>
            Are you sure you want to delete the {name} {resource}?
          </p>
        )}

        <div className='border d-block d-sm-none'></div>
        <div className='d-flex button-container'>
          <CancelButton
            type='button'
            onClick={() => {
              closeModal('closed');
            }}
          >
            Cancel
          </CancelButton>
          <ProceedButton
            type='button'
            disabled={loading === 'loading' || showModal === 'closing'}
            onClick={() => onDelete()}
            className={deleteText ? 'move-delete' : ''}
          >
            {deleteText ? deleteText : 'Delete'}
            <img src={rightArrow} alt='' />
          </ProceedButton>
        </div>
      </div>
    </StyledModal>
  );
};

export default DeleteModal;

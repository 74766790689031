import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import moment from 'moment';
import {
  updateStage,
  getStageOpportuntities,
  fetchOpportunities,
} from '../../../api/opportunities';
import { AppDispatch } from '../../../store';
import {
  onDeleteOpportunity,
  OpportunitiesSliceState,
} from '../../../store/opportunities/opportunitiesSlice';
import MobileNav from '../../../components/MobileTabNav';
import Stage from './Stage';
import XDateModal from './XDateModal';
import LostModal from './LostModal';
import ErrorCard from './ErrorCard';
import OpportinutyWonModal from './OpportunityWonModal';
import WarningCard from './WarningCard';
import StageMovementModal from './StageMovementModal';
import KanbanSkeletonView from './kanbanSkeletonView';
import DeleteModal from '../../../components/DeleteModal';
import {
  IOpportunity,
  IPipeline,
  IPipelineStage,
  IStageData,
} from '../../../interfaces/Opportunity';
import { KanbanDiv } from '../elements/styles';
import bin from '../../../assets/logo/bin.svg';
import calendar from '../../../assets/logo/calendar.svg';

const KanbanView = ({
  stages,
  pipeline,
  lostStageId,
  xDateStageId,
  selectedProducts,
  pipeLineStage,
  searchBy,
  selectedActive,
}: {
  stages: IPipelineStage[];
  pipeline: IPipeline;
  lostStageId: string;
  xDateStageId: string;
  selectedProducts: any;
  pipeLineStage: any;
  searchBy: string;
  selectedActive: any;
}) => {
  const [allStages, setAllStages] = useState<IStageData[]>([]);
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');
  const [edit, setEdit] = useState(false);
  const [selectedOpp, setSelectedOpp] = useState({ id: '', initialIndex: 0 });
  const [errorMessage, setError] = useState('');
  const [opportunity, setOpportunity] = useState<IOpportunity>();
  const [wonOpportunity, setWonOpportunity] = useState('closed');
  const [showStageModal, setShowStageModal] = useState('closed');
  const [showXDateModal, setXDateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    async function fetchOpportunities() {
      const res = await getStageOpportuntities(
        '',
        searchBy,
        '',
        stages,
        '',
        selectedProducts?.map((item: any) => item.value),
        selectedActive?.value,
        pipeLineStage
      );
      setAllStages(res);
      if (res) {
        setIsLoading(false);
      }
    }
    fetchOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pipeline,
    pipeLineStage,
    selectedProducts,
    searchBy,
    selectedActive,
    edit,
  ]);

  const getMoreOpps = (
    offset: number,
    pipelineStageUUID: string,
    index: number
  ) => {
    fetchOpportunities(
      '',
      '',
      searchBy,
      pipelineStageUUID,
      '',
      (data: any) => {
        const udpatedStages = [...allStages];
        const updatedOpps = [
          ...udpatedStages[index].opportunities,
          ...data.opportunities,
        ];
        udpatedStages[index].opportunities = updatedOpps;
        setAllStages(udpatedStages);
      },
      '',
      '',
      25,
      offset
    );
  };

  const onLoseItem = (reason: string, xDate: string) => {
    updateStage(
      {
        action: 'changePipelineStage',
        pipelineStageUUID: action === 'LOST' ? lostStageId : xDateStageId,
        reason,
        xDate,
      },
      allStages[Number(selectedOpp.id)].opportunities[selectedOpp.initialIndex]
        .uuid,
      (opp: IOpportunity) => {
        const updatedStages = [...allStages];
        const initialIndex = Number(selectedOpp.id);
        const initialOppIndex = selectedOpp.initialIndex;
        updatedStages[initialIndex].sum -= opp.leastPremium;
        let name = opp.name ? opp.name : '--';
        updatedStages[initialIndex].opportunities.splice(initialOppIndex, 1);
        updatedStages[initialIndex].count -= 1;
        setAllStages(updatedStages);
        setShowModal(false);
        setXDateModal(false);
        action === 'LOST'
          ? setError(`<b>${name}</b> policy is deleted.`)
          : setError(
              `<b>${name}</b> policy moved back till <b>${moment(xDate).format(
                'MMMM Do YYYY'
              )}</b>.`
            );
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    );
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      setShowActions(false);
      return;
    }
    if (destination?.droppableId === 'delete') {
      setSelectedOpp({ id: source.droppableId, initialIndex: source.index });
      setAction('LOST');
      setShowModal(true);
    } else if (destination?.droppableId === 'xDate') {
      setSelectedOpp({ id: source.droppableId, initialIndex: source.index });
      setAction('X-Date');
      setXDateModal(true);
    } else if (source.droppableId !== destination.droppableId) {
      // check if won
      if (Number(destination.droppableId) === stages.length - 1) {
        setOpportunity(
          allStages[Number(source.droppableId)].opportunities[source.index]
        );
        setWonOpportunity('open');
        setSource(source);
      } else {
        setOpportunity(
          allStages[Number(source.droppableId)].opportunities[source.index]
        );
        updateStage(
          {
            action: 'changePipelineStage',
            pipelineStageUUID: stages[Number(destination.droppableId)].uuid,
          },
          allStages[Number(source.droppableId)].opportunities[source.index]
            .uuid,
          (opp: IOpportunity) => {
            const updatedStages = [...allStages];

            const sourceColumn = updatedStages[Number(source.droppableId)];
            const destColumn = updatedStages[Number(destination.droppableId)];
            const sourceOpps = [...sourceColumn.opportunities];
            const [removed] = sourceOpps.splice(source.index, 1);
            sourceColumn.opportunities = sourceOpps;
            destColumn.opportunities = [...destColumn.opportunities, removed];
            sourceColumn.sum -= opp.leastPremium;
            sourceColumn.count -= 1;
            destColumn.sum += opp.leastPremium;
            destColumn.count += 1;

            updatedStages[Number(source.droppableId)] = sourceColumn;
            updatedStages[Number(destination.droppableId)] = destColumn;

            setAllStages(updatedStages);
            setShowStageModal('open');
          }
        );
      }
    } else {
      setShowActions(false);
      return;
    }
    setShowActions(false);
  };

  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState('');
  const [UUID, setUUID] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);

  const onDelete = (uuid: string, opportunity: IOpportunity) => {
    dispatch(
      onDeleteOpportunity({
        uuid,
        successCB: () => {
          setShowDeleteModal('closed');
          setShowSuccess(true);
          setIsLoading(true);
          async function fetchOpportunities() {
            const res = await getStageOpportuntities(
              '',
              searchBy,
              '',
              stages,
              '',
              selectedProducts?.map((item: any) => item.value),
              selectedActive?.value,
              pipeLineStage
            );
            setAllStages(res);
            if (res) {
              setIsLoading(false);
            }
          }
          fetchOpportunities();

          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        },
      })
    );
  };

  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.status
  );

  return (
    <KanbanDiv>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting opportunity.'}
        />
      )}
      <div>
        {isLoading ? (
          <>
            <div className='d-flex'>
              {[1, 2, 3].map((item) => (
                <KanbanSkeletonView key={item} />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className='d-flex' style={{ minHeight: '50vh' }}>
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result)}
                onDragStart={() => setShowActions(true)}
              >
                {stages?.map((stage, index: number) => (
                  <Stage
                    key={stage.uuid}
                    stage={stage}
                    index={index}
                    isNotEnd={index < stages.length - 1}
                    stageData={allStages[index]}
                    setShowActions={setShowActions}
                    getMoreItems={getMoreOpps}
                    setShowDeleteModal={setShowDeleteModal}
                    setName={setName}
                    setEdit={setEdit}
                    edit={edit}
                    setUUID={setUUID}
                    UUID={UUID}
                  />
                ))}

                <div className='hover-div d-flex'>
                  <Droppable droppableId='delete'>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='col me-3'
                        >
                          <button
                            className='deleteButton'
                            style={{ display: showActions ? 'block' : 'none' }}
                          >
                            <img src={bin} alt='delete' />
                            Lost
                            {provided.placeholder}
                          </button>
                        </div>
                      );
                    }}
                  </Droppable>
                  <Droppable droppableId='xDate'>
                    {(provided, snapshot) => {
                      return (
                        <div
                          className='col'
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <button
                            className='xDateButton'
                            style={{
                              display: showActions ? 'inline-block' : 'none',
                            }}
                          >
                            <img src={calendar} alt='xdate' />
                            X-Date
                            {provided.placeholder}
                          </button>
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </>
        )}
      </div>

      <LostModal
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false);
          setSelectedOpp({ id: '', initialIndex: 0 });
        }}
        action={action}
        setAction={setAction}
        onLoseOpp={onLoseItem}
      />

      {action === 'LOST' && errorMessage.length > 0 && (
        <ErrorCard
          message={errorMessage}
          title={'Opportunity moved to lost.'}
        />
      )}
      {action === 'X-Date' && errorMessage.length > 0 && (
        <WarningCard
          message={errorMessage}
          title={`Successfully moved to X-date.`}
        />
      )}

      {showXDateModal && (
        <XDateModal
          showModal={showXDateModal}
          onCloseModal={() => {
            setXDateModal(false);
            setSelectedOpp({ id: '', initialIndex: 0 });
          }}
          action={action}
          setAction={setAction}
          onLoseOpp={onLoseItem}
        />
      )}
      {wonOpportunity !== 'closed' && opportunity && (
        <OpportinutyWonModal
          show={wonOpportunity}
          setWonOpportunity={setWonOpportunity}
          opportunity={opportunity}
          allStages={allStages}
          setAllStages={setAllStages}
          source={source}
          stages={stages}
        />
      )}
      {showStageModal !== 'closed' && opportunity && (
        <StageMovementModal
          open={showStageModal}
          closeModal={setShowStageModal}
          uuid={opportunity?.uuid}
          stages={stages}
        />
      )}
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='opportunity'
          name={name}
        />
      )}
    </KanbanDiv>
  );
};

export default KanbanView;

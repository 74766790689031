import { Route, Routes, useNavigate } from 'react-router-dom';
import Link from '../../components/Link';
import GlobalPrivacyStatement from './components/GlobalPrivacyStatement';
import TermsAndCondition from './components/TermsAndConditions';
// import arrow from '../../assets/logo/back.svg';
import logo from '../../assets/logo/logo.svg';
import { GlobalContainer, GlobalTC } from './styles';
import { TopBarDiv } from '../../components/TopBar/styles';
import { LoginDiv } from '../../styles/LoginFlow';
import MobileTab from './components/MobileTab';

const GlobalTerms = () => {
  const navigate = useNavigate();
  return (
    <GlobalContainer>
      <LoginDiv className='container-div'>
        <TopBarDiv>
          <img className='logo-svg' src={logo} alt='logo' />
        </TopBarDiv>
        <GlobalTC className='d-block d-sm-flex '>
          <div className='sideBar d-none d-md-block'>
            {/* <div onClick={() => window.close()} className='back-btn-div'>
              <img src={arrow} className='leftarrow  ' alt='leftarrow' />
              Back to Home
            </div> */}
            <Link to={`/global-terms-condition/terms-and-conditions`}>
              Terms and Conditions
            </Link>
            <Link to={`/global-terms-condition/global-privacy-statement`}>
              Global Privacy Statement
            </Link>
          </div>
          <div className='mobile-sidebar d-block d-sm-none'>
            <MobileTab />
          </div>
          <div className='w-100'>
            <Routes>
              <Route
                path='/terms-and-conditions'
                element={<TermsAndCondition />}
              />
              <Route
                path='/global-privacy-statement'
                element={<GlobalPrivacyStatement />}
              />
            </Routes>
          </div>
        </GlobalTC>
      </LoginDiv>
    </GlobalContainer>
  );
};

export default GlobalTerms;

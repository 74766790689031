import { CustomFieldDiv } from '../elements/CustominputStyles';
export interface IProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  onSetItem: Function;
  squareField?: boolean;
  firstField?: boolean;
  lastField?: boolean;
  values: any;
  prefix?: string;
}

const CustomInputField = ({
  name,
  type,
  placeholder,
  className,
  disabled,
  onSetItem,
  squareField,
  firstField,
  lastField,
  values,
  prefix,
}: IProps) => {
  return (
    <CustomFieldDiv>
      <div
        className={`d-flex flex-column w-100 ${className}  ${
          values[name] ? 'filled' : ''
        } input-container ${
          squareField
            ? 'squareField'
            : firstField
            ? 'firstField'
            : lastField
            ? 'lastField'
            : ''
        } ${prefix && 'input-style'} `}
      >
        {prefix && <span className='prefix-style'>{prefix}</span>}
        <input
          id={name}
          name={name}
          onChange={(e) => onSetItem(name, e.target.value)}
          className={`input-field ${squareField && 'squareInput'} ${
            firstField && 'firstInput'
          } ${lastField && 'lastInput'}`}
          disabled={disabled}
          value={values[name]}
          placeholder={placeholder}
          type={type}
          prefix={prefix}
          autoComplete='off'
        />
      </div>
    </CustomFieldDiv>
  );
};

export default CustomInputField;

import React, { useState, useRef, useEffect } from 'react';
import search from '../../assets/logo/search.svg';
import close from '../../assets/logo/cross.svg';
import { SearchContainer } from './style';
import { FieldDiv } from '../InputField/styles';
import dropdown from '../../assets/logo/dropdown.svg';
import useDebounce from '../../hooks/useDebounce';

interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  control?: any;
  setSearch: Function;
  values: String;
  searchValue: any;
  className?: string;
  disabled?: boolean;
  register?: Function;
  content: any;
  status?: String;
  loadFunction: Function;
  required?: boolean;
  addName?: Boolean;
  type?: String;
  setUUID?: Function;
  setProductUUID?: Function;
  smallField?: boolean;
  isEditDetail?: boolean;
}

const SearchDropdown = ({
  name,
  placeholder,
  options,
  control,
  setSearch,
  values,
  searchValue,
  register,
  disabled,
  className,
  content,
  status,
  loadFunction,
  required,
  addName,
  type,
  smallField,
  setUUID,
  setProductUUID,
  isEditDetail,
  ...props
}: IProps) => {
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocused(false);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const [searchDebounce, setSearchDebounce] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const debouncedSearchTerm = useDebounce(searchDebounce, 500);

  useEffect(() => {
    if (isSearched) {
      loadFunction(debouncedSearchTerm);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, isSearched]);

  const reg = register ? { ...register(name) } : null;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      type === 'email' ? e.target.value.toLowerCase() : e.target.value;
    setSearch(value);
    setSearchDebounce(value);
    setIsSearched(true);
  };

  return (
    <SearchContainer ref={wrapperRef}>
      <div className='search-group'>
        <FieldDiv>
          <div
            className={`d-flex flex-column w-100 ${className} ${
              isEditDetail ? 'edit-search' : ''
            } input-container ${smallField && 'small-field'}`}
          >
            <input
              className={`input-field `}
              disabled={disabled}
              type={type ? type : 'text'}
              name={addName ? name : null}
              autoComplete='off'
              {...reg}
              value={values}
              onChange={(e) => handleOnChange(e)}
              onFocus={() => setFocused(true)}
            />
            <label
              className={`input-placeholder ${
                smallField && 'smallFieldsLabel'
              } `}
            >
              {placeholder}
              {required ? <span className='requiredStar'>*</span> : null}
            </label>
          </div>
        </FieldDiv>
        {!values ? (
          <img
            src={search}
            alt=''
            className={`search-button ${smallField && 'smallFieldsIcon'}`}
          />
        ) : (
          [
            disabled ? (
              <img src={dropdown} alt='' className='drop-button' />
            ) : (
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setSearch('');
                  if (setUUID) {
                    setUUID('');
                  }
                  if (setProductUUID) {
                    setProductUUID('');
                  }
                }}
              />
            ),
          ]
        )}
      </div>
      {searchValue?.length && focused ? (
        <div
          className={`searchValues-container ${
            smallField && 'smallSearchvalue'
          }`}
          onClick={() => {
            setFocused(false);
          }}
        >
          {status === 'loading' ? (
            <div>
              <div className='loader' />
              Loading
            </div>
          ) : (
            <span className='textElement'>{content}</span>
          )}
        </div>
      ) : null}
    </SearchContainer>
  );
};

export default SearchDropdown;

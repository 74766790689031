import styled from '@emotion/styled';

export const PickerDiv = styled.div`
  .CallenderImg {
    position: absolute;
    right: 7px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
  }

  display: flex;
  height: 60px;
  align-items: center;
  // background: #f5f7fc;
  padding-top: 15px;
  padding-left: 0;
  border-radius: 8px;
  position: relative;
  padding-right: 22px;
  .date-input-placeholder {
    display: none;
  }
  .date-input::placeholder {
    color: #262758;
    font-weight: 600;
    font-size: 16px;
  }
  .CalenderImg {
    margin-right: 6px;
  }
  .react-datepicker {
    width: 81vw;
    right: 50px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
  }
  .react-datepicker__month-container {
    padding: 20px 40px;
    border-radius: 8px;
    width: 100%;
    background: #fff;
  }
  .react-datepicker__header {
    background: #fff;
    border: none;
  }
  .react-datepicker__current-month {
    color: #262758;
    font-size: 20px;
    text-align: center;
    line-height: 17.5px;
    button {
      border: 0;
      background: #fff;
      width: 20px;
      height: 21px;
      padding: 0;

      :disabled {
        opacity: 0.2;
      }
    }

    .date-info {
      padding: 0 5px;
    }
  }
  .react-datepicker__month {
    text-align: unset;
    width: 100%;
    margin: 0;
  }
  .react-datepicker__day-names {
    margin: 0;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: #3c498a;
    font-size: 14px;
  }
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__navigation {
    top: 33px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--today {
    position: relative;
    :before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #f04d46;
      display: block;
      position: absolute;
      top: 20px;
      left: 12px;
    }
    font-weight: 600;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day--outside-month {
    color: #cad9f3;
  }
  .react-datepicker__day--selected {
    background: #c1dbeb;
    border-radius: 50%;
    font-weight: 700;
    color: #262758;
  }
  .react-datepicker__day--keyboard-selected {
    background: #c1dbeb;
    border-radius: 50%;
    color: #262758;
  }
  .react-datepicker__year-dropdown-container--scroll {
    margin: 0 2px;
    position: absolute;
    right: 0px;
    bottom: 32px;
  }
  .react-datepicker__year-dropdown {
    width: 50px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  .react-datepicker__year-option {
    background: #fff;
    :hover {
      background: #ecf4f9;
    }
  }

  .date-input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 4px 10px;
    padding-right: 2px;
    outline: none;
    border-radius: 8px;
    border: 0;
    // background: #f5f7fc !important;
    font-weight: 600;
  }
  .react-datepicker-popper {
    transform: translate(59px, 37px) !important;
    min-width: 288px;
  }
  .date-placeholder {
    position: absolute;
    pointer-events: none;
    padding-left: 20px;
  }
  :hover {
    .CallenderImg {
      opacity: 1;
    }
    background: #f5f7fc;
    .date-input {
      background: #f5f7fc;
    }
  }
  :focus-within {
    padding: 10px 10px;
    padding-top: 25px;
    .CallenderImg {
      opacity: 1;
    }
    .input-placeholder {
      color: #191919;
      font-weight: 400;
      display: block;
      font-size: 12px;
    }
    .date-input {
      width: 100%;
      padding: 0px;
    }
    .date-placeholder {
      padding: 0;
    }
    label {
      font-size: 16px;
    }
  }

  label {
    position: absolute;
    color: #262758;
    pointer-events: none;
    font-size: 12px;
    left: 10px;
    top: 4px;
  }

  .prefilled {
    background: #f5f7fc !important;
    border-radius: 8px;
    padding: 4px 16px;
    .input-placeholder {
      color: #262758;
      font-weight: 400;
      font-size: 12px;
      display: block;
    }

    .date-input {
      width: 100%;
      padding: 0px;
      background: #f5f7fc !important;
    }

    :focus-within {
      border: none;
      background: #f5f7fc !important;
      padding: 0;
      .CallenderImg {
        opacity: 1;
      }

      .date-input {
        background: none !important;
      }
      .input-placeholder {
        font-size: 12px;
        display: block;
      }
    }
  }

  .error-field {
    border-radius: 8px;
    padding: 4px 16px;
    border: 1px solid #cc0000;

    .date-input {
      width: 100%;
      padding: 0px;
    }

    :focus-within {
      border: none;
      background: #f5f7fc !important;
      padding: 0;
    }
  }
  @media screen and (min-width: 576px) {
    .react-datepicker {
      width: 320px;
      right: 40px;
    }
    .react-datepicker__month-container {
      padding: 40px 35px;
      width: 100%;
      background: #fff;
    }
    .react-datepicker__navigation {
      top: 53px;
    }
  }
  @media screen and (min-width: 1200px) {
    .react-datepicker {
      width: 320px;
      top: 10px;
      right: 100px;
    }
  }

  @media screen and (min-width: 1900px) {
    padding-right: 20px;
    height: 64px;
    .react-datepicker {
      width: 380px;
      top: 20px;
      right: 80px;
    }
  }
`;

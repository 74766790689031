import styled from '@emotion/styled';

export const StyledTab = styled.div`
  border-bottom: 1px solid #e6e9f0;
  padding-bottom: 7px;
  position: relative;

  .navlink {
    color: #000000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
    cursor: pointer;
  }

  .count {
    background: #e6e9f0;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 4px;
    border-radius: 8px;
  }

  .active {
    font-weight: 600;
    border-bottom: 3px solid #262758;
    padding-bottom: 7px;

    .count {
      background: #c1dbeb;
    }
  }

  .mobile-tab {
    position: absolute;
    display: flex;
    z-index: 3;
    flex-direction: column;
    background: #fff;
    top: 32px;
    left: 0px;
    box-shadow: 0px 2px 8px 0px #26275833;
    border-radius: 8px;
    width: 227px;
    padding: 4px;
    .navlink {
      padding: 10px 12px;
      width: 100%;
    }

    .active {
      background: #c1dbeb;
      border-radius: 8px;
      border-bottom: 0;
    }
  }
  .ActivityName {
    text-transform: capitalize;
  }
  @media screen and (min-width: 1900px) {
    .navlink {
      font-size: 16px;
    }
  }
`;

export const LeadMobileInnerTabStyle = styled.div`
  // width:100%
  padding: 12px 8px 8px 20px;
  position: relative;

  .navlink {
    color: #000000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
    cursor: pointer;
  }

  .count {
    background: #e6e9f0;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 4px;
    border-radius: 8px;
  }

  .active {
    font-weight: 600;
    border-bottom: 3px solid #262758;
    padding-bottom: 16px;

    .count {
      background: #c1dbeb;
    }
  }

  .mobile-tab {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #fff;
    top: 58px;
    left: 0px;
    box-shadow: 0px 2px 8px 0px #26275833;
    border-radius: 8px;
    width: 227px;
    padding: 4px;
    margin-left: 10px;
    left: 8px;
    z-index: 8;
    .navlink {
      padding: 10px 8px;
      width: 100%;
    }

    .active {
      background: #c1dbeb;
      border-radius: 8px;
      border-bottom: 0;
    }
  }
  .ActivityName {
    text-transform: capitalize;
  }
  .leadInnerMob-Head {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .lead-headtext {
    margin-top: 15px;
  }
  .row {
    width: 85%;
    margin-left: 65px;
    border: 1px solid #e6e9f0;
  }
  .active-row {
    border-bottom: 3px solid #262758;
    width: 34%;
    margin-top: 4px;
    border-radius: 4px;
  }
  .vector-style {
    margin-left: 2px;
    width: 20px;
  }
  @media screen and (min-width: 1900px) {
    .navlink {
      font-size: 16px;
    }
  }
`;

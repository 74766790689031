import { StyledDeleteModal } from '../../elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import LongVector from '../../../../assets/logo/longVector.svg';

const DeleteWarningModal = ({
  showModal,
  onCloseModal,
  onDeletePipeline,
  UUID,
  name,
}: {
  showModal: boolean;
  onCloseModal: Function;
  onDeletePipeline: Function;
  UUID?: string;
  name: string;
}) => {
  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => onCloseModal(false)}
      />
      <h2>Delete this pipeline?</h2>
      <div className='sub-heading'>
        There are opportunities in this pipeline. If you delete this pipeline,
        all the opportunities in this pipeline will be moved to the default
        pipeline.
      </div>
      <div className='PipelineFlow d-flex align-items-center justify-content-between '>
        <span>
          from <b className='nameSpace'>{name}</b>
        </span>
        <img src={LongVector} alt='' />
        <span>
          To<b className='nameSpace'> Default Pipeline</b>
        </span>
      </div>
      <div className='buttonsDiv d-flex align-items-center'>
        <button
          onClick={() => {
            onCloseModal();
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='WarningButton d-flex align-items-center justify-content-between'
          onClick={() => {
            onDeletePipeline(true);
            onCloseModal(false);
          }}
        >
          Move and Delete
          <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </StyledDeleteModal>
  );
};

export default DeleteWarningModal;

import styled from '@emotion/styled';

export const CustomFieldDiv = styled.div`
  display: flex;
  border: none;
  height: 48px;
  width: 100%;
  position: relative;
  ::placeholder {
    font-size: 8px;
    font-weight: 400;
    padding: 10px;
  }

  .input-container {
    position: relative;
    border-radius: 8px;
    padding: 0 2px 0 0;
    margin-bottom: 10px;
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      background: #f5f7fc !important;
    }

    .prefix-style {
      display: none;
      position: absolute;
      color: #8990a3;
      left: 16px;
      pointer-events: none;
    }
  }
  .input-style {
    .input-field {
      padding: 16px 16px 4px 28px;
    }
    .input-field:hover {
      background: #f5f7fc !important;
    }
  }
  .squareField {
    border-radius: 0px;
    .input-field {
      border: none;
    }
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px;
    }
  }
  .firstField {
    border-radius: 8px 0px 0px 8px;
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px 0px 0px 8px;
      background: #f5f7fc !important;
    }
  }
  .lastField {
    border-radius: 0px 8px 8px 0px;
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px 8px 8px 0px;
      background: #f5f7fc !important;
    }
  }
  .input-field {
    width: 100%;
    height: 100%;
    padding: 16px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 600;
    :disabled {
      color: #cad9f3;
      background: #f5f7fc !important;
      .input-container::disabled {
        background: #f5f7fc !important;
      }
    }
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
    :hover {
      background: #f5f7fc !important;
    }
  }
  .squareInput {
    border-radius: 0px;
  }
  .input-field.firstInput {
    border-radius: 8px 0px 0px 8px;
  }
  .input-field.lastInput {
    border-radius: 0px 8px 8px 0px;
  }
  :focus-within {
    .input-container {
      background: #f5f7fc !important;
      .prefix-style {
        display: block;
        bottom: 5px;
      }
    }
    .input-field {
      background: #f5f7fc !important;
    }

    .error-field {
      border-radius: 8px;
      border: 1px solid #cc0000;
    }
  }

  .filled,
  .prefilled {
    .input-container {
      background: #f5f7fc !important;
    }

    .prefix-style {
      display: block;
      bottom: 5px;
    }
    .input-field {
      background: #fff !important;
    }
    .inputGoal-field {
      background: #f5f7fc !important;
    }
    .input-field:hover {
      background: #f5f7fc !important;
    }
  }

  .prefilled {
    background: #fff !important;

    border-radius: 8px;
    .input-container {
      background: #fff;
    }

    .input-field {
      background: #fff !important;
    }
    .inputGoal-field {
      background: #f5f7fc !important;
    }
  }

  .error-field {
    border-radius: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .inputGoal-field {
    height: 50%;
    background: #f5f7fc !important;
    :hover {
      background: #f5f7fc !important;
    }
    :focus-within {
      background: #f5f7fc !important;
    }
  }
  .input-containerGoalField {
    padding: 10px 0;
    width: 120px;
  }

  @media screen and (min-width: 576px) {
    .filled,
    .prefilled {
      .prefix-style {
        bottom: 5px;
      }
    }
    :focus-within {
      .input-container {
        background: #f5f7fc !important;
        .prefix-style {
          bottom: 5px;
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    .input-container {
      height: 50px;
      width: 180px;
    }

    .filled,
    .prefilled {
      .prefix-style {
        bottom: 12px;
      }
    }
    :focus-within {
      .input-container {
        background: #f5f7fc !important;
        .prefix-style {
          bottom: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 2800px) {
    height: 128px;

    .input-container {
      border-radius: 16px;
    }

    :focus-within {
    }

    .input-field {
      font-size: 32px;
      padding: 40px;
      border-radius: 16px;
    }
  }
`;

export const CustomTextFieldDiv = styled.div`
  display: flex;
  border: none;
  width: 100%;
  position: relative;

  ::placeholder {
    font-size: 8px;
    font-weight: 400;
    padding: 10px;
  }

  .input-container {
    position: relative;
    border-radius: 8px;
    padding: 0 2px 0 0;

    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      background: #f5f7fc !important;
    }

    .prefix-style {
      display: none;
      position: absolute;
      color: #8990a3;
      left: 16px;
      pointer-events: none;
    }
  }
  .input-style {
    .input-field {
      padding: 16px 16px 4px 28px;
    }
    .input-field:hover {
      background: #f5f7fc !important;
    }
  }
  .squareField {
    border-radius: 0px;
    .input-field {
      border: none;
    }
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px;
    }
  }
  .firstField {
    border-radius: 8px 0px 0px 8px;
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px 0px 0px 8px;
      background: #f5f7fc !important;
    }
  }
  .lastField {
    border-radius: 0px 8px 8px 0px;
    :hover {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px 8px 8px 0px;
      background: #f5f7fc !important;
    }
  }
  .input-field {
    width: 100%;
    height: 100%;
    padding: 4px 20px 5px 6px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 400;
    font-size: 16px;
    color: #191919;
    resize: none;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    :disabled {
      color: #cad9f3;
      background: #f5f7fc !important;
      .input-container::disabled {
        background: #f5f7fc !important;
      }
    }
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
    :hover {
      background: #f5f7fc !important;
    }
  }
  .squareInput {
    border-radius: 0px;
  }
  .firstInput {
    border-radius: 8px 0px 0px 8px;
  }
  .lastInput {
    border-radius: 0px 8px 8px 0px;
  }
  :focus-within {
    .input-container {
      background: #f5f7fc !important;
      .prefix-style {
        display: block;
        bottom: 5px;
      }
    }
    .input-field {
      background: #f5f7fc !important;
    }

    .error-field {
      border-radius: 8px;
      border: 1px solid #cc0000;
    }
  }

  .filled,
  .prefilled {
    .input-container {
      background: #f5f7fc !important;
    }

    .prefix-style {
      display: block;
      bottom: 5px;
    }
    .input-field {
      background: #fff !important;
    }
    .inputGoal-field {
      background: #f5f7fc !important;
    }
    .input-field:hover {
      background: #f5f7fc !important;
    }
  }

  .prefilled {
    background: #fff !important;

    border-radius: 8px;
    .input-container {
      background: #fff;
    }

    .input-field {
      background: #fff !important;
    }
    .inputGoal-field {
      background: #f5f7fc !important;
    }
  }

  .error-field {
    border-radius: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .inputGoal-field {
    height: 50%;
    background: #f5f7fc !important;
    :hover {
      background: #f5f7fc !important;
    }
    :focus-within {
      background: #f5f7fc !important;
    }
  }
  .input-containerGoalField {
    padding: 10px 0;
    width: 120px;
  }

  @media screen and (min-width: 576px) {
    .filled,
    .prefilled {
      .prefix-style {
        bottom: 5px;
      }
    }
    :focus-within {
      .input-container {
        background: #f5f7fc !important;
        .prefix-style {
          bottom: 5px;
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    .input-container {
      height: 50px;
      width: 180px;
    }

    .filled,
    .prefilled {
      .prefix-style {
        bottom: 12px;
      }
    }
    :focus-within {
      .input-container {
        background: #f5f7fc !important;
        .prefix-style {
          bottom: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 2800px) {
    height: 128px;

    .input-container {
      border-radius: 16px;
    }

    :focus-within {
    }

    .input-field {
      font-size: 32px;
      padding: 40px;
      border-radius: 16px;
    }
  }
`;

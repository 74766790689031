import { Goal } from '../styles';
import Select from 'react-select';
import goalIcon from '../../../../assets/logo/goal.svg';
import premium from '../../../../assets/logo/premium.svg';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ProgressBar } from 'react-bootstrap';
import progressIcon from '../../../../assets/logo/progress.png';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { GoalSliceState } from '../../../../store/goals/goalSlice';
const Goals = ({
  options,
  handleSelectGoal,
  customSelectStyles,
  isTab,
  percentage,
  goal,
  handleSelectGoalType,
  goalTypeSelected,
}: {
  options: any;
  handleSelectGoal: Function;
  customSelectStyles: any;
  isTab: boolean;
  percentage: number;
  goal: any;
  handleSelectGoalType: Function;
  goalTypeSelected: string;
}) => {
  const loadingStatus = useSelector(
    (state: { goals: GoalSliceState }) => state.goals.status
  );
  const goalType = [
    { label: 'Premium', value: 'PREMIUM' },
    { label: 'Policy', value: 'POLICY' },
  ];
  return (
    <div className='goal-div'>
      <div className='d-flex justify-content-between head-div'>
        <h4>Agency Goals</h4>
        <Select
          className='dashboard-select'
          defaultValue={options[0]}
          options={options}
          onChange={(e: any) => handleSelectGoal(e)}
          styles={customSelectStyles}
          isSearchable={false}
        />
      </div>

      <div
        className={`premium-value-container ${
          isTab ? 'd-flex tab-view align-items-center' : null
        }`}
      >
        <h5 className='goal-head'>
          <Select
            className='dashboard-select'
            defaultValue={goalType[0]}
            options={goalType}
            onChange={(e: any) => handleSelectGoalType(e)}
            styles={customSelectStyles}
            isSearchable={false}
          />
        </h5>
        {loadingStatus === 'loading' ? (
          <Skeleton height={isTab ? 50 : 80} />
        ) : (
          loadingStatus === 'succeeded' && (
            <h6 className='goal-stat'>
              {goalTypeSelected === 'PREMIUM' ? '$' : ''}
              {goal?.policyValue}
              <img src={progressIcon} alt='' />
            </h6>
          )
        )}
      </div>

      <Goal className='circle-wrap'>
        {!isTab ? (
          loadingStatus === 'loading' ? (
            <Skeleton
              height={200}
              width={200}
              style={{ borderRadius: '100px' }}
            />
          ) : (
            loadingStatus === 'succeeded' && (
              <CircularProgressbarWithChildren value={percentage}>
                <div className='goal-percentageText'>
                  <strong>{percentage.toFixed(2)}%</strong>
                  <span className='achieved'>Achieved</span>
                </div>
              </CircularProgressbarWithChildren>
            )
          )
        ) : loadingStatus === 'loading' ? (
          <Skeleton height={24} />
        ) : (
          loadingStatus === 'succeeded' && (
            <>
              <ProgressBar
                className='goal-progress-bar'
                now={percentage}
                label={`${percentage}%`}
                visuallyHidden
              />
              <label>
                <strong>{percentage}%</strong> Achieved
              </label>
            </>
          )
        )}
      </Goal>
      {loadingStatus === 'loading' ? (
        <>
          <Skeleton height={56} />
          <Skeleton height={56} />
        </>
      ) : (
        loadingStatus === 'succeeded' && (
          <div>
            <div className='goal-values'>
              <div className='goal-text'>
                <div className='goal-icon'>
                  <img src={premium} alt='' />
                </div>
                Still needed
              </div>
              {goalTypeSelected === 'PREMIUM' ? '$' : ''}
              {goal?.goalValue - goal?.policyValue}
            </div>
            <div className='goal-values'>
              <div className='goal-text'>
                <div className='goal-icon'>
                  <img src={goalIcon} alt='' />
                </div>
                Goal this month
              </div>
              {goalTypeSelected === 'PREMIUM' ? '$' : ''}
              {goal?.goalValue}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Goals;

import moment from 'moment';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import vector from '../../../assets/logo/dropdown.svg';
import { ActivitySliceState } from '../../../store/activity/activitiesSlice';
import { IActivitiesData } from '../../../interfaces/Task';

export interface IProps {
  events: Array<any>;
}

const getDisplayName = (data: IActivitiesData) => {
  let displayName = data?.TaskType?.name || 'Task/Event';

  if (data?.Account) {
    displayName = `${displayName} - ${data.Account?.name || ''}`;
    return displayName;
  }
  if (data?.Contact) {
    displayName = `${displayName} - ${data.Contact?.firstName || ''} ${
      data.Contact?.lastName || ''
    }`;
    return displayName;
  }
  if (data?.Opportunity) {
    displayName = `${displayName} - ${data.Opportunity?.name || ''}`;
    return displayName;
  }
  if (data?.Policy) {
    displayName = `${displayName} - ${data.Policy?.Accounts?.name || ''}`;
    return displayName;
  }
  if (data?.Lead) {
    displayName = `${displayName} - ${data.Lead?.data?.contact?.name || ''}`;
    return displayName;
  }
  return displayName;
};

const MyEvents = ({ events }: IProps) => {
  const [showAllEvents, setShowAllEvents] = useState(false);
  const navigate = useNavigate();
  const loadingStatus = useSelector(
    (state: { activities: ActivitySliceState }) => state.activities.status
  );
  return (
    <div>
      {loadingStatus === 'loading'
        ? [1, 2, 3].map((item) => (
            <div style={{ marginBottom: '40px' }}>
              <Skeleton height={48} key={item} />
            </div>
          ))
        : loadingStatus === 'succeeded' && (
            <>
              <div
                className={`event-scroll ${
                  showAllEvents ? 'event-scroll-allEvent' : ''
                }`}
              >
                {events.map((item, index) =>
                  showAllEvents || index < 3 ? (
                    item.type === 'EVENT' ? (
                      <div
                        className='d-flex'
                        onClick={() =>
                          navigate(`/activities/event`, {
                            state: { uuid: item.data.uuid },
                          })
                        }
                      >
                        <div className='event-time'>
                          <span>
                            {moment(item.data.scheduledDate).format('HH. mm')}
                          </span>
                          <span>
                            {moment(item.data.dueDate).format('HH. mm')}
                          </span>
                        </div>
                        <div
                          className={`d-flex event-list-card ${
                            index === 0 ? 'mt-0' : ''
                          }`}
                        >
                          <div className='eventIndicator '></div>
                          <div className='task-list-card'>
                            <div className='event-name'>{item.data.name}</div>
                            <div className='event-content'>
                              {item.data?.description}
                            </div>
                          </div>
                          <div className='event-img'>
                            <img src={vector} alt='vector' />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className='d-flex'
                        onClick={() =>
                          navigate(`/activities/task`, {
                            state: { uuid: item.data.uuid },
                          })
                        }
                      >
                        <div className='event-time'>
                          <span>
                            {moment(item.data.dueDate).format('HH. mm')}
                          </span>
                        </div>
                        <div
                          className={`d-flex event-list-card ${
                            index === 0 ? 'mt-0' : ''
                          }`}
                        >
                          <div className='taskIndicator'></div>
                          <div className='task-list-card'>
                            <div className='task-name'>
                              {item.data.text || getDisplayName(item.data)}
                            </div>
                            <div className='task-content'>
                              {item.data.content}
                            </div>
                          </div>
                          <div>
                            <img src={vector} alt='vector' />
                          </div>
                        </div>
                      </div>
                    )
                  ) : null
                )}
              </div>
              {events.length > 3 ? (
                <span
                  className='show-all event-showAll'
                  onClick={() => setShowAllEvents(!showAllEvents)}
                >
                  {showAllEvents ? 'Show less' : 'Show all'}
                </span>
              ) : null}
            </>
          )}
    </div>
  );
};

export default MyEvents;

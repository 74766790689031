import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { INotification } from '../../../interfaces/Notifications';
import { AppDispatch } from '../../../store';
import { notificationSettingsUpdate } from '../../../store/user/userSlice';
import WarningCard from '../../Pipeline/components/WarningCard';
import LocalStorageService from '../../../utils/LocalStorageService';

const NotificationsSettings = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [data, setData] = useState<INotification>({});
  const [isSoundChecked, setIsSoundChecked] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [isOpportunityChecked, setIsOpportunityChecked] = useState(false);
  const [isTaskReminederChecked, setIsTaskReminderChecked] = useState(true);
  const [isPolicyChecked, setIsPolicyChecked] = useState(true);
  const [isNewTaskChecked, setIsNewTaskChecked] = useState(false);

  useEffect(() => {
    const user = LocalStorageService.getUser();
    setIsSoundChecked(user.NotificationSettings?.isSoundOn);
    setIsOpportunityChecked(user.NotificationSettings?.opportunityAssignment);
    setIsNewTaskChecked(user.NotificationSettings?.taskAssignment);
    setIsPolicyChecked(user.NotificationSettings?.policySold);
    setIsTaskReminderChecked(user.NotificationSettings?.taskReminder);
  }, []);
  const settingsChange = (name: string) => {
    let notificationData = { ...data };
    if (name === 'isSoundOn') {
      notificationData = { ...data, isSoundOn: !isSoundChecked };
      setIsSoundChecked(!isSoundChecked);
    } else if (name === 'newPolicy') {
      notificationData = { ...data, policySold: !isPolicyChecked };
      setIsPolicyChecked(!isPolicyChecked);
    } else if (name === 'taskReminder') {
      notificationData = { ...data, taskReminder: !isTaskReminederChecked };
      setIsTaskReminderChecked(!isTaskReminederChecked);
    } else if (name === 'opportunityAssignment') {
      notificationData = {
        ...data,
        opportunityAssignment: !isOpportunityChecked,
      };
      setIsOpportunityChecked(!isOpportunityChecked);
    } else if (name === 'newTask') {
      notificationData = { ...data, taskAssignment: !isNewTaskChecked };
      setIsNewTaskChecked(!isNewTaskChecked);
    }
    dispatch(
      notificationSettingsUpdate({
        data: notificationData,
        successCB: () => {
          setData(notificationData);
          setshowSuccess(true);
          setTimeout(() => {
            setshowSuccess(false);
          }, 3000);
        },
      })
    );
  };

  const fieldsArray = [
    {
      name: 'isSoundOn',
      Head: 'Play a sound for new notifications',
      subHead:
        ' The system will play a sound whenever you receive a new notification.',
      checked: isSoundChecked,
    },
    {
      name: 'newPolicy',
      Head: 'New policy sales notification',
      subHead: 'Receive a notification whenever a new policy is sold.',
      checked: isPolicyChecked,
    },
    {
      name: 'taskReminder',
      Head: 'Task reminder notification',
      subHead: 'Receive reminder notification for all tasks assigned to you.',
      checked: isTaskReminederChecked,
    },
    {
      name: 'opportunityAssignment',
      Head: 'Opportunity assignment notification',
      subHead:
        ' Receive a notification whenever a opportunity is assigned to you.',
      checked: isOpportunityChecked,
    },
    {
      name: 'newTask',
      Head: 'New task notification',
      subHead: 'Receive a notification for all new tasks assigned to you.',
      checked: isNewTaskChecked,
    },
  ];

  return (
    <div className='notificationContainer'>
      {showSuccess && (
        <WarningCard
          message={`<b> Settings </b> has been Updated.`}
          title={`Settings updated.`}
        />
      )}
      <div className='subText d-none d-xl-block'>Notification Settings</div>
      <h6 className='subTextNotification'>
        We may still send you important notifications about your account outside
        of your preferred notification settings.
      </h6>
      {fieldsArray.map((element: any) => (
        <NotificationField
          Head={element.Head}
          SubHead={element.subHead}
          name={element.name}
          isChecked={element.checked}
          settingsChange={settingsChange}
        />
      ))}
    </div>
  );
};

export default NotificationsSettings;

const NotificationField = ({
  Head,
  SubHead,
  name,
  isChecked,
  settingsChange,
}: {
  Head: string;
  SubHead: string;
  name: string;
  isChecked: boolean;
  settingsChange: Function;
}) => {
  return (
    <div className='settingsCard d-flex'>
      <div className='checkboxDiv d-flex'>
        <label className='switch'>
          <input
            type='checkbox'
            className='inputCheckbox'
            checked={isChecked}
            onChange={() => {
              settingsChange(name);
            }}
          />
          <span className='slider' />
        </label>
      </div>
      <div className='settingsContainer'>
        <h2 className='settinghead'>{Head}</h2>
        <h6 className='settingsub'>{SubHead}</h6>
      </div>
    </div>
  );
};

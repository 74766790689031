import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getGoals,
  updateGoals,
  getAgencyGoal,
  getUserGoals,
} from '../../api/goals';
import { IGoals } from '../../interfaces/Goals';

export type GoalSliceState = {
  status: string;
  updateStatus: string;
  goals: any[];
  goalsDetails: any[];
  isLoading: boolean;
  agencyGoal: any;
  userGoals: any[];
  goalPolicyCount: number;
  goalPremiumSum: number;
  userGoalsCount: number;
};
export type IProps = {
  selectedMonthDate: string;
  agencyUUID?: string;
  agentUUID?: string;
  successCB: Function;
};
const initialState: GoalSliceState = {
  status: 'idle',
  updateStatus: 'idle',
  goalPolicyCount: 0,
  goalPremiumSum: 0,
  goals: [],
  goalsDetails: [],
  isLoading: false,
  agencyGoal: {},
  userGoals: [],
  userGoalsCount: 0,
};

export const fetchGoals = createAsyncThunk(
  'goal/fetchGoals',
  async ({ selectedMonthDate, agencyUUID, agentUUID, successCB }: IProps) => {
    const response = await getGoals(selectedMonthDate, agencyUUID, agentUUID);

    successCB(response.data);
    return response.data;
  }
);

export const fetchAgencyGoal = createAsyncThunk(
  'goal/fetchAgencyGoal',
  async ({
    goalType,
    duration,
    agencyUUID,
  }: {
    goalType: string;
    duration: string;
    agencyUUID?: string;
  }) => {
    const response = await getAgencyGoal(goalType, duration, agencyUUID);

    // successCB(response.data);
    return response.data;
  }
);

export const fetchUserGoals = createAsyncThunk(
  'goal/fetchUserGoals',
  async ({
    limit,
    offset,
    duration,
    agencyUUID,
    sortBy,
    orderBy,
  }: {
    limit: number;
    offset: number;
    duration: string;
    agencyUUID?: string;
    sortBy?: string;
    orderBy?: string;
  }) => {
    const response = await getUserGoals(
      limit,
      offset,
      duration,
      agencyUUID,
      sortBy,
      orderBy
    );

    // successCB(response.data);
    return response.data;
  }
);

export const updateGoalsDetails = createAsyncThunk(
  'goal/updateGoalsDetails',
  async ({ data, successCB }: { data: IGoals; successCB: Function }) => {
    const response = await updateGoals(data);
    successCB(response.data);
    return response.data;
  }
);

const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGoals.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.goals = action.payload.agents;
        state.goalPolicyCount = action.payload.goalPolicyCount;
        state.goalPremiumSum = action.payload.goalPremiumSum;
      })
      .addCase(fetchGoals.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchUserGoals.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUserGoals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userGoals = action.payload.leaderboard;

        state.userGoalsCount = action.payload.agentWithGoals;
      })
      .addCase(fetchUserGoals.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchAgencyGoal.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAgencyGoal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agencyGoal = action.payload;
      })
      .addCase(fetchAgencyGoal.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateGoalsDetails.pending, (state, action) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateGoalsDetails.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        const index = state.goals.findIndex(
          (item: any) => item.uuid === action.payload.agentsWithGoals[0].uuid
        );
        state.goals[index] = action.payload.agentsWithGoals[0];
        const goalIndex = state.userGoals.findIndex(
          (item: any) =>
            item.agentUUID === action.payload.agentsWithGoals[0].uuid
        );
        state.userGoals[goalIndex].goalPolicy =
          action.payload.agentsWithGoals[0].Goals[0].value;
        state.userGoals[goalIndex].goalPremium =
          action.payload.agentsWithGoals[0].Goals[1].value;
        state.goalPolicyCount = action.payload.goalPolicyCount;
        state.goalPremiumSum = action.payload.goalPremiumSum;
      })
      .addCase(updateGoalsDetails.rejected, (state, action) => {
        state.updateStatus = 'failed';
      });
  },
});

export const { updateStatus } = goalSlice.actions;

export default goalSlice.reducer;

export const selectAllGoals = (state: { goals: GoalSliceState }) =>
  state.goals?.goals;
export const selectPolicyCount = (state: { goals: GoalSliceState }) =>
  state.goals?.goalPolicyCount;
export const selectgoalPremiumSum = (state: { goals: GoalSliceState }) =>
  state.goals?.goalPremiumSum;
export const selectAgencyGoal = (state: { goals: GoalSliceState }) =>
  state.goals?.agencyGoal;
export const selectUserGoal = (state: { goals: GoalSliceState }) =>
  state.goals?.userGoals;
export const selectUserGoalCount = (state: { goals: GoalSliceState }) =>
  state.goals?.userGoalsCount;

import axios from './axios';

const fetchCount = () => {
  return axios.get('/user/mybook/count').then(
    (res) => {
      return res;
    },
    (error) => {
      return error.res;
    }
  );
};

export { fetchCount };

import React, { useEffect, useMemo, useState } from 'react';
import rightArrow from '../../../../assets/logo/right-arrow.svg';
import SelectField from '../../../../components/CustomSelectField';
import SearchDropdown from '../../../../components/SearchDropdown';
import { fetchAgents } from '../../../../api/agents';
import { IProducer } from '../../../../interfaces/Agent';
import warning from '../../../../assets/logo/warning.svg';
import { fetchCarriers } from '../../../../api/carriers';
import InputField from '../../../../components/CustomInputField';
import DateField from '../../../../components/CustomDateField';
import { PolicyInfo } from '../../elements/styles';
import { getIssuer, fetchMGA } from '../../../../api/policy';
import { FieldDiv } from '../../../../components/InputField/styles';
import MarkdownEditor from '../../../../components/MarkdownEditor';
import CustomFieldInputCustom from '../../../../components/CustomfieldsInputCustom';
import DocumentField from './DocumentField';
import { getDocumentType } from '../../../../api/documents';
import { scrollToError } from '../../../../utils/ScrollValidation';
import defaultCarrier from '../../../../assets/logo/defaultCarrier.svg';
import { ICustomField } from '../../../../interfaces/CustomField';

interface IProps {
  policy: any;
  itemIndex: number;
  policyIndex: number;
  selectedPolicies: any;
  setIndex: Function;
  control?: any;
  register: Function;
  setPolicies: Function;
  setValue: Function;
  isLoading: boolean;
  customFields: ICustomField[];
  setDocumentExist: Function;
}

const AddPolicyInfo = ({
  policy,
  itemIndex,
  policyIndex,
  setIndex,
  selectedPolicies,
  control,
  register,
  setPolicies,
  setDocumentExist,
  setValue,
  isLoading,
  customFields,

  ...props
}: IProps) => {
  const ERROR_MAP_IN_ORDER = useMemo(
    () =>
      new Map([
        ['agentUUID', ''],
        ['carrierUUID', ''],
        ['issuerUUID', ''],
        ['mgaUUID', ''],
        ['UWContactName', ''],
        ['UWPhone', ''],
        ['UWEmail', ''],
        ['policyNumber', ''],
        ['policyTerm', ''],
        ['policyTermMonth', ''],
        ['effectiveDate', ''],
        ['quoteWonDate', ''],
        ['expiryDate', ''],
        ['billType', ''],
        ['premiumFinance', ''],
        ['premium', ''],
        ['mgaFees', ''],
        ['esTax', ''],
        ['agencyFees', ''],
        ['otherFees', ''],
        ['totalAmount', ''],
      ]),
    []
  );

  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [submitted, setSubmitted] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [user, setUser] = useState<any>({});
  const [showDocument, setShowDocument] = useState(false);
  const [files, setFiles] = useState<any>();
  const [submit, setSubmit] = useState(false);
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carriers, setCarriers] = useState<any>([]);
  const [fileError, setFileError] = useState('');

  const [mgaSearchText, setMgaSearchText] = useState('');
  const [MGA, setMGA] = useState<any>([]);
  const [textCheck, setTextCheck] = useState('');

  useEffect(() => {
    setTimeout(() => {
      onSetItem('policyTerm', '6 months');
      onSetItem('PolicySoldThrough', 'direct');
    }, 1000);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (policy.type === 'PRODUCT') {
      onSetItem('productUUID', policy.value);
    } else if (policy.type === 'CUSTOM_PRODUCT') {
      onSetItem('customProductUUID', policy.value);
    }
    if (user?.UserRole) {
      if (user?.UserRole?.name === 'AGENT') {
        setProducerSearch('Me');
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const policySoldThrough = [
    // { label: 'MGA', value: 'MGA' },
    { label: 'Directly appointed carrier', value: 'direct' },
  ];

  const [issuingCompany, setIssuingCompany] = useState([]);

  const billType = [
    { label: 'Direct Bill', value: 'direct' },
    { label: 'Agency', value: 'Agency' },
  ];

  const policyTerm = [
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Custom', value: 'custom' },
  ];

  const values = policy;

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (carrierSearchText) {
        fetchCarriers('id', 'asc', value, 'True', (data: any) => {
          setCarriers(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setCarriers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    if (
      !selectedPolicies[itemIndex].carrierUUID &&
      selectedPolicies[itemIndex].issuerUUID
    ) {
      const item: any = issuingCompany.find(
        (el: any) => el.uuid === selectedPolicies[itemIndex].issuerUUID
      );
      loadCarrier(item?.name);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicies[itemIndex].issuerUUID]);

  const loadMGA = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchMGA(value, (data: any) => {
        setMGA(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  const [notes, setNotes] = useState<any>(['']);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  const validateForm = (name: string, updatedPolicy: any) => {
    if (!updatedPolicy.carrierUUID && !updatedPolicy.customCarrierUUID) {
      ERROR_MAP_IN_ORDER.set('carrierUUID', 'Please select carrier name');
    }

    if (updatedPolicy.carrierUUID && !updatedPolicy.issuerUUID) {
      ERROR_MAP_IN_ORDER.set('issuerUUID', 'Please select issuing company');
    }

    if (!updatedPolicy.policyNumber || updatedPolicy.policyNumber < 1) {
      ERROR_MAP_IN_ORDER.set(
        'policyNumber',
        'Please enter a valid policy Number'
      );
    }

    if (!updatedPolicy.policyTerm) {
      ERROR_MAP_IN_ORDER.set('policyTerm', 'Please select policy term');
    }

    if (
      updatedPolicy?.policyTerm === 'custom' &&
      (Number(updatedPolicy?.policyTermMonth) > 99 ||
        !updatedPolicy?.policyTermMonth)
    ) {
      ERROR_MAP_IN_ORDER.set('policyTermMonth', 'Please enter a valid month');
    }

    if (!updatedPolicy.effectiveDate) {
      ERROR_MAP_IN_ORDER.set('effectiveDate', 'Please enter effective date');
    }
    if (!updatedPolicy.quoteWonDate) {
      ERROR_MAP_IN_ORDER.set('quoteWonDate', 'Please enter Quote won date');
    }

    if (!updatedPolicy.expiryDate) {
      ERROR_MAP_IN_ORDER.set('expiryDate', 'Please enter expiry date');
    }

    if (
      !updatedPolicy.agentUUID &&
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      ERROR_MAP_IN_ORDER.set('agentUUID', 'Please select producer');
    }

    if (!updatedPolicy.directBill || !updatedPolicy.agencyBill) {
      ERROR_MAP_IN_ORDER.set('billType', 'Please select bill type');
    } else {
      ERROR_MAP_IN_ORDER.set('billType', '');
    }

    if (!updatedPolicy.premium || updatedPolicy.premium < 1) {
      ERROR_MAP_IN_ORDER.set('premium', 'Please enter premium Amount');
    }

    if (updatedPolicy.premiumFinanceAmount < 0) {
      ERROR_MAP_IN_ORDER.set('premiumFinanceAmount', 'Enter a valid amount');
    }

    if (updatedPolicy.esTax < 0) {
      ERROR_MAP_IN_ORDER.set('esTax', 'Enter a valid amount');
    }
    if (updatedPolicy.agencyFees < 0) {
      ERROR_MAP_IN_ORDER.set('agencyFees', 'Enter a valid amount');
    }
    if (updatedPolicy.otherFees < 0) {
      ERROR_MAP_IN_ORDER.set('otherFees', 'Enter a valid amount');
    }

    if (updatedPolicy?.agencyBill === 'true' && !updatedPolicy.premiumFinance) {
      ERROR_MAP_IN_ORDER.set('premiumFinance', 'Please select premium Finance');
    }

    if (updatedPolicy.notes) {
      if (textCheck && !textCheck.trim() && textCheck.length > 1) {
        ERROR_MAP_IN_ORDER.set('notes', 'Note cannot be blank space');
      }
    } else {
      if (textCheck) {
        ERROR_MAP_IN_ORDER.set('notes', '');
      }
    }

    if (updatedPolicy[name]) {
      if (name === 'customCarrierUUID') {
        ERROR_MAP_IN_ORDER.set('carrierUUID', '');
      } else {
        ERROR_MAP_IN_ORDER.set(name, '');
      }
    }
  };

  const onSetItem = (name: string, value: any, secondary?: string) => {
    let newPolicy = Object.assign({}, policy);
    let updatedPolicy = [...selectedPolicies];
    if (name === 'billType') {
      newPolicy[name] = value;
      if (value === 'Agency') {
        value = 'true';
        name = 'agencyBill';
        newPolicy['directBill'] = 'false';
      }
      if (value === 'direct') {
        value = 'true';
        name = 'directBill';
        newPolicy['agencyBill'] = 'false';
      }
    }
    if (
      name !== 'billType' &&
      newPolicy[name] &&
      (value === '' || value === null || value === undefined)
    ) {
      delete newPolicy[name];
    } else {
      if (name === 'UWPhone') {
        if (!value) newPolicy[name] = value;
        else {
          const phoneNumber = value.replace(/[^\d]/g, '');
          const phoneNumberLength = phoneNumber.length;
          if (phoneNumberLength < 4) newPolicy[name] = phoneNumber;
          else if (phoneNumberLength < 7) {
            newPolicy[name] = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
              3
            )}`;
          } else {
            newPolicy[name] = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
          }
        }
      } else {
        newPolicy[name] = value;
      }
    }
    if (secondary) {
      newPolicy[secondary] = '';
    }
    updatedPolicy[itemIndex] = newPolicy;
    setPolicies(updatedPolicy);
    validateForm(name, newPolicy);
  };

  const ondeleteItem = (name: string) => {
    let newPolicy = Object.assign({}, policy);
    let updatedPolicy = [...selectedPolicies];
    delete newPolicy[name];
    updatedPolicy[itemIndex] = newPolicy;
    setPolicies(updatedPolicy);
  };

  const resetCarrier = (value: string) => {
    onSetItem('carrierUUID', value, 'customCarrierUUID');
    setCarriers([]);
  };

  const resetMGA = (value: string) => {
    onSetItem('mgaUUID', value);
  };
  useEffect(() => {
    if (selectedPolicies[itemIndex].carrierUUID) {
      getIssuer(
        carrierSearchText,
        selectedPolicies[itemIndex].carrierUUID,
        (data: any) => {
          setIssuingCompany(data);
          setLoadingStatus('succeeded');
        }
      );
    } else {
      setIssuingCompany([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicies[itemIndex].carrierUUID]);

  const onSubmitClick = () => {
    policy.customFields = {};
    customFields.forEach((item: any) => {
      if (item.uuid in policy) {
        policy.customFields[item.uuid] = policy[item.uuid];
      }
    });
    setSubmit(true);
    if (!selectedPolicies[itemIndex].premiumFinance) {
      onSetItem('premiumFinance', 'false');
    }
    if (Array.from(ERROR_MAP_IN_ORDER.values()).every((item) => item === '')) {
      if (selectedPolicies[itemIndex].policyTerm === 'custom') {
        selectedPolicies[itemIndex].policyTerm =
          selectedPolicies[itemIndex].policyTermMonth + ' months';
        setPolicies(selectedPolicies);
      }
      if (Array.from(ERROR_MAP_IN_ORDER.keys()).length > 0) {
        selectedPolicies.length - 1 !== itemIndex && setIndex(++policyIndex);
      }
    }
    scrollToError(ERROR_MAP_IN_ORDER);
  };

  const setPremiumFinance = (e: any) => {
    if (e.target.value === 'yes') {
      onSetItem('premiumFinance', 'true');
    } else {
      onSetItem('premiumFinance', 'false');
    }
  };
  const [totalAmount, setTotalAmount] = useState('0');
  const [premiumDownPaymentAmount, setPremiumDownPaymentAmount] = useState('');
  const [amountCollected, setAmountCollected] = useState('0');
  useEffect(() => {
    setTotalAmount(
      String(
        parseFloat(values.premium) +
          parseFloat(values.agencyFees || 0) +
          parseFloat(values.mgaFees || 0) +
          parseFloat(values.esTax || 0) +
          parseFloat(values.otherFees || 0)
      )
    );
  }, [
    values.premium,
    values.agencyFees,
    values.mgaFees,
    values.esTax,
    values.otherFees,
  ]);

  useEffect(() => {
    if (values?.premiumFinance === 'true') {
      setPremiumDownPaymentAmount(
        String(
          parseFloat(values.premium) -
            parseFloat(values.premiumFinanceAmount || 0)
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.premium, values.premiumFinanceAmount]);

  useEffect(() => {
    if (
      values?.agencyBill === 'true' &&
      values?.premiumFinance === 'false' &&
      premiumDownPaymentAmount === ''
    ) {
      setAmountCollected(
        String(
          parseFloat(values.premium || 0) +
            parseFloat(values.agencyFees || 0) +
            parseFloat(values.mgaFees || 0) +
            parseFloat(values.otherFees || 0) +
            parseFloat(values.esTax || 0)
        )
      );
    } else if (
      values?.agencyBill === 'true' &&
      values?.premiumFinance === 'true' &&
      premiumDownPaymentAmount !== ''
    ) {
      setAmountCollected(
        String(
          parseFloat(values.premium || 0) +
            (parseFloat(values.agencyFees) || 0) +
            (parseFloat(values.mgaFees) || 0) +
            (parseFloat(values.otherFees) || 0) +
            (parseFloat(values.esTax) || 0) -
            (parseFloat(values.premiumFinanceAmount) || 0)
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    premiumDownPaymentAmount,
    values.premium,
    values.agencyFees,
    values.mgaFees,
    values.esTax,
    values.premiumFinance,
    values.otherFees,
  ]);
  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      selectedPolicies[itemIndex]?.effectiveDate &&
      selectedPolicies[itemIndex]?.policyTerm === 'custom' &&
      selectedPolicies[itemIndex]?.policyTermMonth < 100
    ) {
      let dateEffective = new Date(selectedPolicies[itemIndex]?.effectiveDate);

      onSetItem(
        'expiryDate',
        new Date(
          dateEffective.setDate(
            dateEffective.getDate() +
              Number(selectedPolicies[itemIndex]?.policyTermMonth) * 30
          )
        )
      );
    } else if (
      selectedPolicies[itemIndex]?.policyTerm !== 'custom' &&
      selectedPolicies[itemIndex]?.policyTerm &&
      selectedPolicies[itemIndex]?.effectiveDate
    ) {
      const month = selectedPolicies[itemIndex]?.policyTerm.split(' ')[0];
      let dateEffective = new Date(selectedPolicies[itemIndex]?.effectiveDate);
      onSetItem(
        'expiryDate',
        new Date(
          dateEffective.setDate(dateEffective.getDate() + Number(month) * 30)
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPolicies[itemIndex]?.effectiveDate,
    selectedPolicies[itemIndex]?.policyTermMonth,
    selectedPolicies[itemIndex]?.policyTerm,
  ]);

  useEffect(() => {
    if (selectedPolicies[itemIndex].PolicySoldThrough !== 'MGA') {
      if (selectedPolicies[itemIndex].mgaUUID) {
        ondeleteItem('mgaUUID');
      }
      if (selectedPolicies[itemIndex].UWContactName) {
        ondeleteItem('UWContactName');
      }
      if (selectedPolicies[itemIndex].UWPhone) {
        ondeleteItem('UWPhone');
      }
      if (selectedPolicies[itemIndex].UWEmail) {
        ondeleteItem('UWEmail');
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicies[itemIndex].PolicySoldThrough]);

  useEffect(() => {
    let newPolicy = Object.assign({}, policy);
    let updatedPolicy = [...selectedPolicies];
    if (policy.type === 'PRODUCT') {
      newPolicy['productUUID'] = policy.value;
    } else if (policy.type === 'CUSTOM_PRODUCT') {
      newPolicy['customProductUUID'] = policy.value;
    }
    newPolicy['fromLead'] = 'false';
    newPolicy['totalAmount'] = totalAmount;
    newPolicy['premiumDownPaymentAmount'] = premiumDownPaymentAmount;
    newPolicy['amountCollected'] = amountCollected;
    newPolicy['notes'] = notes;
    newPolicy['files'] = files;
    newPolicy['showDocument'] = showDocument;
    updatedPolicy[itemIndex] = newPolicy;
    setPolicies(updatedPolicy);
    if (showDocument && files && Object.keys(files).length > 0) {
      setDocumentExist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    premiumDownPaymentAmount,
    totalAmount,
    amountCollected,
    notes,
    files,
    showDocument,
  ]);

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  return (
    <PolicyInfo className='scroll-bar scrollPolicy  policyAddformSpec '>
      <div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields dateField'>
            <SearchDropdown
              name='agentUUID'
              addName={true}
              placeholder='Select producer'
              isEdit={true}
              values={producerSearch}
              searchValue={producers}
              setSearch={setProducerSearch}
              status={loadingStatus}
              className={`${producerSearch && 'filled'}`}
              register={register}
              loadFunction={loadProducer}
              setUUID={resetProducer}
              disabled={user?.UserRole?.name === 'AGENT'}
              required
              content={producers?.map((item) => (
                <div
                  className='searchValuesCard opportunity-producer d-flex align-items-center'
                  onClick={() => {
                    setProducerSearch(
                      `${item.User.firstName} ${
                        item.User.lastName ? item.User.lastName : ''
                      }`
                    );
                    onSetItem('agentUUID', item.uuid);
                  }}
                >
                  <div className='profile-pic d-flex align-items-center justify-content-center'>
                    {item?.User?.firstName?.charAt(0)}
                    {item?.User?.lastName?.charAt(0)}
                  </div>
                  <div>
                    <h6>
                      {`${item.User.firstName} ${
                        item.User.lastName ? item.User.lastName : ''
                      }`}
                    </h6>
                    <ul className='user-details'>
                      <li>{item.User?.email}</li>
                      <li>{item?.User?.phoneNumber}</li>
                    </ul>
                  </div>
                </div>
              ))}
            />
            {producerSearch !== 'Me' &&
            !selectedPolicies[itemIndex].agentUUID &&
            submit ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                Producer not found
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <h2 className='contact-info'>Policy sold through</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <SelectField
              options={policySoldThrough}
              disabled={true}
              name='PolicySoldThrough'
              placeholder='Policy sold through'
              onSetItem={onSetItem}
              values={values}
            />
          </div>
        </div>
        <div>
          <h2 className='contact-info'>Carrier details</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <SearchDropdown
              name='carrierUUID'
              addName={true}
              placeholder='Select carrier'
              isEdit={true}
              values={carrierSearchText}
              searchValue={carriers}
              setSearch={setCarrierSearchText}
              status={loadingStatus}
              className={`${carrierSearchText && 'filled'}`}
              loadFunction={loadCarrier}
              setUUID={resetCarrier}
              required
              content={carriers?.map((item: any) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setCarrierSearchText(item?.name);
                    if (item.type === 'CARRIER') {
                      onSetItem('carrierUUID', item?.uuid, 'customCarrierUUID');
                    } else if (item.type === 'CUSTOM_CARRIER') {
                      onSetItem('customCarrierUUID', item?.uuid, 'carrierUUID');
                    }
                  }}
                >
                  {item.logo ? (
                    <img src={item.logo} alt='' className='carrierlogo-icon' />
                  ) : (
                    <img
                      src={defaultCarrier}
                      alt=''
                      className='carrierlogo-icon'
                    />
                  )}
                  <div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
              ))}
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('carrierUUID') ?? ''}
              />
            )}
          </div>
          {selectedPolicies[itemIndex].carrierUUID ? (
            <div className='fields'>
              <SelectField
                options={issuingCompany.map((item: any) => {
                  return { label: item.name, value: item.uuid };
                })}
                name='issuerUUID'
                placeholder='Issuing company'
                onSetItem={onSetItem}
                required
                values={values}
              />
              {submit && (
                <ErrorMessage
                  errorMessage={ERROR_MAP_IN_ORDER.get('issuerUUID') ?? ''}
                />
              )}
            </div>
          ) : null}
        </div>

        {selectedPolicies[itemIndex].PolicySoldThrough === 'MGA' ? (
          <>
            <div>
              <h2 className='contact-info'>MGA details</h2>
            </div>
            <div className='d-sm-flex justify-content-between'>
              <div className='fields'>
                <SearchDropdown
                  name='mgaUUID'
                  placeholder='MGA’s name'
                  isEdit={true}
                  values={mgaSearchText}
                  searchValue={MGA}
                  setSearch={setMgaSearchText}
                  status={loadingStatus}
                  className={`${mgaSearchText && 'filled'}`}
                  loadFunction={loadMGA}
                  setUUID={resetMGA}
                  content={MGA?.map((item: any) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setMgaSearchText(item?.name);
                        onSetItem('mgaUUID', item?.uuid);
                      }}
                    >
                      <div>
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                  ))}
                />
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('mgaUUID') ?? ''}
                  />
                )}
              </div>

              <div className='fields'>
                <InputField
                  name='UWContactName'
                  type='text'
                  placeholder='UW contact name'
                  onSetItem={onSetItem}
                  values={values}
                />
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('UWContactName') ?? ''}
                  />
                )}
              </div>
            </div>
            <div className='d-sm-flex justify-content-between'>
              <div className='fields'>
                <InputField
                  name='UWPhone'
                  type='tel'
                  placeholder='UW phone number'
                  onSetItem={onSetItem}
                  values={values}
                  className=''
                />
                <ErrorMessage
                  errorMessage={ERROR_MAP_IN_ORDER.get('UWPhone') ?? ''}
                />
                {submit &&
                selectedPolicies[itemIndex].PolicySoldThrough?.value ===
                  'MGA' &&
                !/[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/.test(
                  selectedPolicies[itemIndex].UWPhone
                ) ? (
                  <div className='errorVal'>
                    <img src={warning} alt='warning' className='warning' />
                    Please enter valid UW Phone number
                  </div>
                ) : null}
                {submit &&
                !selectedPolicies[itemIndex].UWPhone &&
                ERROR_MAP_IN_ORDER.get('UWPhone') &&
                selectedPolicies[itemIndex].PolicySoldThrough?.value ===
                  'MGA' ? (
                  <div className='errorVal'>
                    <img src={warning} alt='warning' className='warning' />
                    Please enter a Phone number
                  </div>
                ) : null}
              </div>
              <div className='fields'>
                <InputField
                  name='UWEmail'
                  type='email'
                  placeholder='UW email address'
                  onSetItem={onSetItem}
                  values={values}
                />
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('UWEmail') ?? ''}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div>
          <h2 className='contact-info'>Policy specific details</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='policyNumber'
              type='text'
              placeholder='Policy Number'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('policyNumber') ?? ''}
              />
            )}
          </div>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <SelectField
              options={policyTerm}
              name='policyTerm'
              placeholder='Policy Term'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('policyTerm') ?? ''}
              />
            )}
          </div>
          {selectedPolicies[itemIndex]?.policyTerm === 'custom' ? (
            <div className='fields'>
              <InputField
                name='policyTermMonth'
                type='number'
                placeholder='Policy Term in Months'
                onSetItem={onSetItem}
                values={values}
                month='months'
                required
              />
              {submit && (
                <ErrorMessage
                  errorMessage={ERROR_MAP_IN_ORDER.get('policyTermMonth') ?? ''}
                />
              )}
            </div>
          ) : null}
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <DateField
              name='effectiveDate'
              placeholder='Effective Date'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('effectiveDate') ?? ''}
              />
            )}
          </div>
          <div className='fields'>
            <DateField
              name='expiryDate'
              placeholder='Expiry Date'
              onSetItem={onSetItem}
              readOnly={true}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('expiryDate') ?? ''}
              />
            )}
          </div>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <DateField
              name='quotePresentedDate'
              placeholder='Quote presented date'
              onSetItem={onSetItem}
              values={values}
            />
          </div>
          <div className='fields'>
            <DateField
              name='quoteWonDate'
              placeholder='Quote won Date'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('quoteWonDate') ?? ''}
              />
            )}
          </div>
        </div>

        <div>
          <h2 className='contact-info'>Bill Type</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <SelectField
              options={billType}
              name='billType'
              placeholder='Bill Type'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('billType') ?? ''}
              />
            )}
          </div>
        </div>

        <div>
          <h2 className='contact-info'>Payment Type</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <label className='radio-container'>
              <input
                type='radio'
                className='form-check-input'
                name='paymentType'
                value='Auto Pay'
                onChange={() => onSetItem('paymentType', 'Auto Pay')}
              />
              Auto Pay
            </label>
          </div>
          <div className='fields'>
            <label className='radio-container'>
              <input
                type='radio'
                className='form-check-input'
                name='paymentType'
                value='Manual Pay'
                onChange={() => onSetItem('paymentType', 'Manual Pay')}
              />
              Manual Pay
            </label>
          </div>
        </div>

        {selectedPolicies[itemIndex]?.agencyBill === 'true' ? (
          <>
            <div>
              <h2 className='contact-info'>Premium Finance</h2>
            </div>
            <div className='d-sm-flex justify-content-between'>
              <div className='fields'>
                <label className='radio-container'>
                  <input
                    type='radio'
                    className='form-check-input'
                    name='premiumFinance'
                    value='yes'
                    onChange={(e) => setPremiumFinance(e)}
                  />
                  Yes
                </label>
                {submit && (
                  <ErrorMessage
                    errorMessage={
                      ERROR_MAP_IN_ORDER.get('premiumFinance') ?? ''
                    }
                  />
                )}
              </div>
              <div className='fields'>
                <label className='radio-container'>
                  <input
                    type='radio'
                    className='form-check-input'
                    name='premiumFinance'
                    value='no'
                    onChange={(e) => setPremiumFinance(e)}
                  />
                  No
                </label>
              </div>
            </div>
          </>
        ) : null}
        {selectedPolicies[itemIndex]?.premiumFinance === 'true' &&
        selectedPolicies[itemIndex]?.agencyBill === 'true' ? (
          <div className='d-sm-flex justify-content-between'>
            <div className='fields'>
              <InputField
                name='premiumFinanceCompany'
                type='text'
                placeholder='Premium Finance Company'
                onSetItem={onSetItem}
                values={values}
              />
            </div>
          </div>
        ) : null}
        <div>
          <h2 className='contact-info'>Amount details</h2>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='premium'
              type='number'
              prefix='$'
              placeholder='Premium amount'
              onSetItem={onSetItem}
              values={values}
              required
            />
            {values.premium <= 0 ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                Please enter a valid Amount
              </div>
            ) : (
              <>
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('premium') ?? ''}
                  />
                )}
              </>
            )}
          </div>
          {selectedPolicies[itemIndex]?.premiumFinance === 'true' &&
          selectedPolicies[itemIndex]?.agencyBill === 'true' ? (
            <div className='fields'>
              <FieldDiv>
                <div
                  className={`d-flex flex-column w-100 ${
                    premiumDownPaymentAmount ? 'filled' : ''
                  } input-container`}
                >
                  <input
                    id='premiumDownPaymentAmount'
                    name='premiumDownPaymentAmount'
                    className={`input-field`}
                    type='number'
                    autoComplete='off'
                    value={premiumDownPaymentAmount}
                    readOnly
                    disabled
                  />
                  <label
                    htmlFor='premiumDownPaymentAmount'
                    className='input-placeholder'
                  >
                    Premium: Down Payment amount
                  </label>
                </div>
              </FieldDiv>
            </div>
          ) : (
            <div className='fields'>
              <InputField
                name='mgaFees'
                prefix='$'
                type='number'
                placeholder='MGA Fees (Optional)'
                onSetItem={onSetItem}
                values={values}
              />
              {values.mgaFees < 0 ? (
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  Please enter a valid Amount
                </div>
              ) : (
                <>
                  {submit && (
                    <ErrorMessage
                      errorMessage={ERROR_MAP_IN_ORDER.get('mgaFees') ?? ''}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {selectedPolicies[itemIndex]?.premiumFinance === 'true' &&
        selectedPolicies[itemIndex]?.agencyBill === 'true' ? (
          <div className='d-sm-flex justify-content-between'>
            <div className='fields'>
              <InputField
                name='premiumFinanceAmount'
                prefix='$'
                type='number'
                placeholder='Premium: Financed amount (optional)'
                onSetItem={onSetItem}
                values={values}
              />
            </div>
            <div className='fields'>
              <InputField
                name='mgaFees'
                type='number'
                prefix='$'
                placeholder='MGA Fees (Optional)'
                onSetItem={onSetItem}
                values={values}
              />
            </div>
          </div>
        ) : null}
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='esTax'
              type='number'
              prefix='$'
              placeholder='E&S Tax (optional)'
              onSetItem={onSetItem}
              values={values}
            />
            {values.esTax < 0 ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                Please enter a valid Amount
              </div>
            ) : (
              <>
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('esTax') ?? ''}
                  />
                )}
              </>
            )}
          </div>
          <div className='fields'>
            <InputField
              name='agencyFees'
              type='number'
              prefix='$'
              placeholder='Agency Fees (optional)'
              onSetItem={onSetItem}
              values={values}
            />
            {values.agencyFees < 0 ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                Please enter a valid Amount
              </div>
            ) : (
              <>
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('agencyFees') ?? ''}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='otherFees'
              type='number'
              prefix='$'
              placeholder='Other fees / charges (optional)'
              onSetItem={onSetItem}
              values={values}
            />
            {values.otherFees < 0 ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                Please enter a valid Amount
              </div>
            ) : (
              <>
                {submit && (
                  <ErrorMessage
                    errorMessage={ERROR_MAP_IN_ORDER.get('otherFees') ?? ''}
                  />
                )}
              </>
            )}
          </div>
          <div className='fields'>
            <FieldDiv>
              <div
                className={`d-flex flex-column w-100 ${
                  totalAmount ? 'filled' : ''
                } input-container input-style`}
              >
                <input
                  id='totalAmount'
                  name='totalAmount'
                  className={`input-field`}
                  type='number'
                  autoComplete='off'
                  value={totalAmount}
                  readOnly
                  disabled
                />
                <span className='prefix-style'>$</span>
                <label htmlFor='totalAmount' className='input-placeholder'>
                  Total amount
                </label>
              </div>
            </FieldDiv>
            {submit && (
              <ErrorMessage
                errorMessage={ERROR_MAP_IN_ORDER.get('totalAmount') ?? ''}
              />
            )}
          </div>
        </div>
        {selectedPolicies[itemIndex]?.agencyBill === 'true' ? (
          <div className='d-sm-flex justify-content-between'>
            <div className='fields '>
              <FieldDiv>
                <div
                  className={`d-flex flex-column w-100 ${
                    amountCollected ? 'filled' : ''
                  } input-container  input-style `}
                >
                  <input
                    id='amountCollected'
                    name='amountCollected'
                    className={`input-field`}
                    type='number'
                    autoComplete='off'
                    value={amountCollected}
                    readOnly
                    disabled
                  />
                  <span className='prefix-style'>$</span>
                  <label
                    htmlFor='amountCollected'
                    className='input-placeholder'
                  >
                    Amount collected{' '}
                    <small> (Excluding Payment Processing Fees)</small>
                  </label>
                </div>
              </FieldDiv>
            </div>
          </div>
        ) : null}

        <div>
          {customFields?.length > 0 ? (
            <>
              <div>
                <h2 className='contact-info'>Additional fields</h2>
              </div>
              {customFields?.map((item: any, index: number) =>
                index % 2 === 0 ? (
                  <div className='d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <CustomFieldInputCustom
                        item={item}
                        onSetItem={onSetItem}
                        values={values}
                      />
                    </div>
                    {customFields?.length > index + 1 ? (
                      <div className='fields'>
                        <CustomFieldInputCustom
                          item={customFields[index + 1]}
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null
              )}
            </>
          ) : null}
        </div>
        <div>
          {notes &&
            notes?.map((item: any, index: number) => {
              return (
                <div className='d-flex align-items-center descriptionField  col'>
                  <div className='textarea'>
                    <MarkdownEditor
                      placeholder='Write submission comments here'
                      note={item}
                      multi={true}
                      index={index}
                      setNote={onSetNotesValue}
                      textCheckFunction={setTextCheck}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        {submit && (
          <ErrorMessage errorMessage={ERROR_MAP_IN_ORDER.get('notes') ?? ''} />
        )}

        <h2 className='contact-info documnetsAdd'>
          <span>Add documents</span>
          <span>(optional)</span>

          <label className='switch'>
            <input
              type='checkbox'
              onClick={() => setShowDocument(!showDocument)}
            />
            <span className='slider round'></span>
          </label>
        </h2>
        {showDocument && (
          <div>
            <h2 className='contact-info'>REQUIRED DOCUMENTS</h2>

            <div className='d-sm-flex flex-wrap justify-content-between'>
              {DocumentTypes &&
                DocumentTypes?.map((item: any, index: number) => (
                  <div key={index} className='fields'>
                    <DocumentField
                      setFile={setFiles}
                      name={`${itemIndex}_${item.uuid}`}
                      placeholder={item.name}
                      file={files}
                      colorless={true}
                      setFileError={setFileError}
                    />
                  </div>
                ))}
            </div>
            {fileError ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                {fileError}
              </div>
            ) : null}
          </div>
        )}
        <div className='buttonsDiv d-flex align-items-center '>
          <button
            type={
              selectedPolicies.length - 1 === itemIndex &&
              selectedPolicies[itemIndex].policyNumber &&
              selectedPolicies[itemIndex].premium &&
              selectedPolicies[itemIndex].effectiveDate &&
              (selectedPolicies[itemIndex].directBill ||
                selectedPolicies[itemIndex].agencyBill) &&
              Array.from(ERROR_MAP_IN_ORDER.values()).every(
                (item) => item === ''
              ) &&
              submitted
                ? 'submit'
                : 'button'
            }
            className='nextButton'
            disabled={isLoading}
            onClick={() => {
              onSubmitClick();
              setTimeout(() => {
                if (
                  selectedPolicies.length - 1 === itemIndex &&
                  selectedPolicies[itemIndex].policyNumber &&
                  selectedPolicies[itemIndex].agentUUID &&
                  selectedPolicies[itemIndex].premium &&
                  selectedPolicies[itemIndex].effectiveDate &&
                  (selectedPolicies[itemIndex].directBill ||
                    selectedPolicies[itemIndex].agencyBill) &&
                  Array.from(ERROR_MAP_IN_ORDER.values()).every(
                    (item) => item === ''
                  )
                ) {
                  setSubmitted(false);
                }
              });
            }}
          >
            Add {policy.label}
            <img className='qualifyArrow' src={rightArrow} alt='' />
          </button>
        </div>
      </div>
    </PolicyInfo>
  );
};

export default AddPolicyInfo;

export const ErrorMessage = ({ errorMessage }: { errorMessage: String }) => {
  return (
    <React.Fragment>
      {errorMessage ? (
        <div className='errorVal'>
          <img src={warning} alt='warning' className='warning' />
          {errorMessage}
        </div>
      ) : null}
    </React.Fragment>
  );
};

import { useRef, useState } from 'react';
import dropdown from '../../../assets/logo/dropdown.svg';
import Link from '../../../components/Link';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { StyledTab } from '../../../styles/StyledTab';

const MobileTab = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropText, setDropText] = useState('Terms and Conditions');

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDropdown);
  return (
    <StyledTab className='d-sm-none mobileTab-text'>
      <div className='ActivityName' ref={dropRef}>
        <div
          className='activeCarrierTab'
          onClick={(e) => {
            setShowDropdown(!showDropdown);
          }}
        >
          {dropText}
          <img src={dropdown} alt='' />
        </div>
        {showDropdown && (
          <div className='mobile-tab '>
            <Link
              to={`/global-terms-condition/terms-and-conditions`}
              onClick={() => {
                setShowDropdown(false);
                setDropText('Terms and Conditions');
              }}
            >
              Terms and Conditions
            </Link>
            <Link
              to={`/global-terms-condition/global-privacy-statement`}
              onClick={() => {
                setShowDropdown(false);
                setDropText('Global Privacy Statement');
              }}
            >
              Global Privacy Statement
            </Link>
          </div>
        )}
      </div>
    </StyledTab>
  );
};

export default MobileTab;

import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { AppDispatch } from '../../store';
import {
  onUpdateLeadDetails,
  fetchLead,
  selectLeadDetails,
} from '../../store/leads/leadsSlice';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../store/customFields/customFieldsSlice';
import { getLeadSourceTypes, getLeadStage } from '../../api/lead';
import { fetchAgents, fetchAgentDetails } from '../../api/agents';
import { fetchIndustries, fetchIndustryDetails } from '../../api/industry';
import { fetchContacts } from '../../api/contact';
import { fetchJurisdiction } from '../../api/jurisdiction';
import CustomFieldInput from '../CustomfieldsInput';
import Opportunity from './Opportunity';
import InputField from '../InputField';
import SelectField from '../SelectField';
import PhoneNumberInput from '../PhoneNumberFormat';
import Modal from '../Modal';
import SuccessCard from '../SuccessCard';
import DateField from '../DateField';
import SearchDropdown from '../SearchDropdown';
import GoogleSearchInput from '../GoogleSearchInput';
import { IProducer } from '../../interfaces/Agent';
import { ILead, ILeadSourceType } from '../../interfaces/Lead';
import { IJurisdiction } from '../../interfaces/Jurisdiction';
import { LeadStyledModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import tick from '../../assets/logo/tick.svg';
import blackplus from '../../assets/logo/blackplus.svg';
import cross from '../../assets/logo/cross.svg';
import warning from '../../assets/logo/warning.svg';
import { ICustomField } from '../../interfaces/CustomField';

const LeadSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  leadStage: Yup.object().shape({
    value: Yup.string().required('Please select a Stage'),
  }),
  businessPhoneNumber: Yup.string().matches(
    /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
    { message: 'Phone number is not valid', excludeEmptyString: true }
  ),
});

const EditLeadDrawer = ({
  show,
  closeModal,
  UUID,
  qualifyMessage,
  setQulifyMessage,
}: {
  UUID: string;
  qualifyMessage?: string;
  show: string;
  setQulifyMessage?: any;
  closeModal: Function;
}) => {
  const navigate = useNavigate();

  const [moreDetailsbtn, isMoreDetailsbtn] = useState(false);
  const [addressObject, setAddressObject] = useState<any>({});
  const [address, setAddress] = useState('');
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [jurisdictionSearch, setJurisdictionSearch] = useState('');
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LeadSchema),
  });
  const dispatch = useDispatch<AppDispatch>();

  const maritalStatus = [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
  ];

  const watchAllFields = watch();

  const [opportunities, setOpportunities] = useState<any>([{}]);
  const [isCommercial, setCommercial] = useState(false);
  const [errorMessage, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [status, setStatus] = useState<ILead[]>([]);
  const [producerSearch, setProducerSearch] = useState('');
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [producer, setProducer] = useState('');
  const [user, setUser] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState<any>({});
  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const [submit, setSubmit] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);
  const [businessError, setBusinessError] = useState('');
  const [isOpportunityEdit, setIsOpportunityEdit] = useState(false);
  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const addsOpportunity = () => {
    opportunities
      ? setOpportunities([...opportunities, {}])
      : setOpportunities([{}]);
  };

  const leadDetails = useSelector(selectLeadDetails);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (jurisdictionSearch) {
        fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
          setLicensedState(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setLicensedState([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    if (show === 'open') {
      setIsLoading(true);
      dispatch(
        fetchLead({
          UUID,
          successCB: (lead: ILead) => {
            getLeadStage((data: ILead[]) => {
              setStatus(data);
            });
            if (lead.data.accountInfo.accountType === 'COMMERCIAL') {
              setCommercial(true);
              setValue('businessApt', lead.data.business.businessApt);
              setValue('businessEmail', lead.data.business.businessEmail);
              setValue('businessName', lead.data.business.businessName);
              setValue(
                'businessPhoneNumber',
                lead.data.business.businessPhoneNumber
              );
              if (lead.data.business?.isManuallyAdded) {
                setIsManual(true);
                setValue('address', lead.data.business?.businessStreetAddress);
                setValue('zipCode', lead.data.business?.businessZipCode);
                setValue('city', lead.data.business?.businessCity);
                setValue('state', lead.data.business?.businessState);
                setValue('country', lead.data.business?.businessCountry);
              } else {
                setAddressObject({
                  address: lead.data.business.businessStreetAddress,
                  city: lead.data.business?.businessCity,
                  state: lead.data.business?.businessState,
                  country: lead.data.business?.businessCountry,
                  zipCode: lead.data.business?.businessZipCode,
                });
              }
              setAddress(lead.data.business.businessStreetAddress);

              if (lead?.data?.business?.industry !== '') {
                fetchIndustryDetails(
                  lead?.data?.business?.industry,
                  (data: any) => {
                    setIndustrySearch(
                      `${data.naicsSixDigitCode} - ${data.naicsSixDigitCodeDescription}`
                    );
                    setIndustryUuid(data?.uuid);
                  }
                );
              }
            } else {
              setValue(
                'contactApt',
                lead.data.contact.contactApt || lead.data.contact.apartment
              );
              lead.data.contact?.maritalStatus &&
                setValue('maritalStatus', {
                  value: lead.data.contact?.maritalStatus,
                  label: lead.data.contact?.maritalStatus,
                });
              if (lead.data.contact?.isManuallyAdded) {
                setIsManual(true);
                setValue('address', lead.data.contact?.streetAddress);
                setValue('zipCode', lead.data.contact?.zipCode);
                setValue('city', lead.data.contact?.city);
                setValue('state', lead.data.contact?.state);
                setValue('country', lead.data.contact?.country);
              } else {
                setAddressObject({
                  address: lead.data.contact.streetAddress,
                  city: lead.data.contact?.city,
                  state: lead.data.contact?.state,
                  country: lead.data.contact?.country,
                  zipCode: lead.data.contact?.zipCode,
                });
              }
              setValue('liscenseNumber', lead.data.contact.liscenseNumber);
              // setValue('mailingAddress', lead.data.contact.streetAddress);
              setAddress(lead.data.contact.streetAddress);

              setValue(
                'dateofBirth',
                lead.data.contact.dateofBirth
                  ? moment(lead.data.contact.dateofBirth).toDate()
                  : null
              );
            }

            setValue('firstName', lead.data.contact?.firstName);
            setValue('middleName', lead.data.contact?.middleName);
            setValue('lastName', lead.data.contact?.lastName);
            setValue('email', lead.data.contact?.email);
            setEmailSearch(lead.data.contact?.email);
            setValue('phoneNumber', lead.data.contact?.phoneNumber);

            if (lead.LeadSource)
              setValue('leadSource', {
                label: lead.LeadSource.name,
                value: lead.LeadSource.uuid,
              });

            setValue('location', lead.data.accountInfo?.location);
            setJurisdictionSearch(lead.data.contact?.jurisdictionName);
            setJurisdictionsUUID(lead.data.contact?.jurisdictionUUID);
            setAccountInfo(lead.data?.accountInfo);
            setValue('accountCSR', lead.data.accountInfo?.accountCSR);
            lead.LeadStage?.name &&
              setValue('leadStage', {
                label: lead.LeadStage?.name,
                value: lead.LeadStage?.uuid,
              });

            setOpportunities(
              lead.data.opportunities ? lead.data.opportunities : [{}]
            );
            // setAccountInfo(aLead.data.accountInfo);
            dispatch(
              getCustomFields({
                uuid: '',
                resourceName: 'LEAD',
                successCB: (data: ICustomField[]) => {
                  data.map((item) => {
                    if (
                      item.FieldType?.name === 'DATEPICKER' &&
                      item.data?.defaultValue
                    ) {
                      setValue(
                        item.uuid,
                        moment(item.data?.defaultValue).toDate()
                      );
                    }
                    if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
                      const filteredData = item.data.options.filter(
                        (it: any) => it.default === true
                      );
                      setValue(
                        item.uuid,
                        filteredData.map((el) => {
                          return { value: el.order, label: el.value };
                        })
                      );
                    }
                    if (item.FieldType?.name === 'SINGLE_CHOICE') {
                      if (item.data?.defaultValue) {
                        setValue(item.uuid, {
                          label:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.value,
                          value:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.order,
                        });
                      }
                    }
                  });

                  if (
                    lead.LeadCustomField &&
                    lead.LeadCustomField?.length > 0
                  ) {
                    lead.LeadCustomField.map((item) => {
                      if (item.CustomField.FieldType?.name === 'DATEPICKER') {
                        setValue(
                          item.CustomField.uuid,
                          moment(item.value).toDate()
                        );
                      } else if (item.CustomField.FieldType?.name === 'TIME') {
                        setDueTimeVal([
                          ...duetimeVal,
                          {
                            uuid: item.CustomField.uuid,
                            value: moment(item.value).toDate(),
                          },
                        ]);
                      } else {
                        setValue(item.CustomField.uuid, item.value);
                      }
                    });
                  }

                  setIsLoading(false);
                },
              })
            );
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);

  const customFields = useSelector(selectAllCustomFields);

  const onSubmit = (data: any) => {
    data.customFields = {};
    customFields.map((item) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.customFields[item.uuid] = timeItem.value;
        } else {
          data.customFields[item.uuid] = data[item.uuid];
        }
      }
    });
    var canSubmit = true;
    if (industrySearch && !industryUuid) {
      canSubmit = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
      canSubmit = false;
    } else if (canSubmit) {
      data.accountType = isCommercial ? 'COMMERCIAL' : 'PERSONAL';
      data.opportunities = opportunities;
      data.leadStageUUID = data.leadStage?.value;
      data.accountProduces = producer;
      data.industry = industryUuid;
      data.jurisdictionUUID = jurisdictionsUUID;
      if (jurisdictionsUUID) {
        data.jurisdictionName = jurisdictionSearch;
      }

      data.maritalStatus = data.maritalStatus?.value;
      data.leadSourceUUID = data.leadSource?.value;
      data.isManuallyAdded = isManual;

      // To add addressObject with the form
      if (!isManual) {
        data = Object.assign(data, addressObject);
      }
      if (isCommercial && !data.businessName) {
        return setBusinessError('Please enter Business Name');
      }
      dispatch(
        onUpdateLeadDetails({
          data,
          UUID,
          successCB: () => {
            closeModal('closing');
            setQulifyMessage('');
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: (err: string) => {
            if (typeof err === 'string') setError(err);
          },
        })
      );
    }
  };

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setValue('email', '');
    setEmailAccounts([]);
  };

  const loadEmail = (value: string) => {
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
      if (user?.UserRole?.name === 'AGENT') {
        fetchAgents(
          (data: IProducer[]) => {
            const agentId = data.find((el: any) => el.User.uuid === user.uuid);
            setProducer(agentId?.uuid || '');
          },
          'id',
          'asc',
          '',
          'True'
        );
      }
    }
  }, []);

  useEffect(() => {
    if (user?.UserRole) {
      if (user?.UserRole?.name === 'PRINCIPAL_AGENT') {
        if (accountInfo?.accountProduces) {
          fetchAgentDetails(accountInfo?.accountProduces, (data: any) => {
            if (user.uuid === data?.User.uuid) {
              setProducerSearch('Me');
            } else {
              setProducerSearch(
                `${data?.User?.firstName} ${data?.User?.lastName || ''}`
              );
            }
          });
          setProducer(accountInfo?.accountProduces);
        }
      } else {
        setProducerSearch('Me');
      }
    }
  }, [user, accountInfo]);

  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
  }, []);

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (industrySearch) {
        fetchIndustries(
          (data: any) => {
            setIndustry(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setIndustry([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    setError('');
  }, [watchAllFields.phoneNumber]);

  const resetJurisdiction = (value: string) => {
    setLicensedState([]);
    setJurisdictionsUUID('');
  };

  const resetIndustry = (value: string) => {
    setIndustry([]);
    setIndustryUuid('');
  };

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  const leadSourceOptions = useMemo(() => {
    return sourceTypes.map((item) => {
      return {
        label: item.name,
        options: item.LeadSources.map((source) => {
          return {
            label: source.name,
            value: source.uuid,
          };
        }),
      };
    });
  }, [sourceTypes]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success updating lead.</b>`}
            message={` <b>${getValues('firstName')}</b> lead has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setShowSuccess(false);
              setQulifyMessage('');
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <LeadStyledModal>
        <Modal open={show} align='right'>
          <div className='entireModal'>
            <div className=' d-flex topHead justify-content-between'>
              <h2>Edit Lead</h2>
              <button
                onClick={() => {
                  reset();
                  onCloseModal();
                  setQulifyMessage('');
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='navbuttonDiv'>
                <button
                  className={
                    !isCommercial
                      ? 'naVigatorbuttonsActive'
                      : 'naVigatorbuttons'
                  }
                  onClick={() => setCommercial(false)}
                  disabled={isCommercial ? true : false}
                >
                  Personal
                </button>
                <button
                  className={
                    isCommercial ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                  }
                  onClick={() => setCommercial(true)}
                  disabled={!isCommercial ? true : false}
                >
                  Commercial
                </button>
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='leadModal'>
                {qualifyMessage && (
                  <div className='editLeadError emailError'>
                    <img src={warning} alt='warning' className='warning' />
                    <div>{qualifyMessage}</div>
                  </div>
                )}
                <h2
                  className={qualifyMessage ? 'HeadText topText' : 'HeadText'}
                >
                  Account Info
                </h2>
                <div className='d-block d-sm-flex RawLead justify-content-between '>
                  <div className='SelectField'>
                    <SelectField
                      options={leadSourceOptions}
                      name='leadSource'
                      placeholder='Lead source'
                      control={control}
                    />
                  </div>
                  <div className='SelectField'>
                    <SearchDropdown
                      name='producer'
                      placeholder='Select producer'
                      isEdit={true}
                      values={producerSearch}
                      searchValue={producers}
                      setSearch={setProducerSearch}
                      status={loadingStatus}
                      className={`${producerSearch && 'filled'}`}
                      register={register}
                      loadFunction={loadProducer}
                      setUUID={resetProducer}
                      disabled={user?.UserRole?.name === 'AGENT'}
                      content={producers?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setProducerSearch(
                              `${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`
                            );
                            setProducer(item?.uuid);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.User?.firstName?.charAt(0)}
                            {item?.User?.lastName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {`${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`}
                            </h6>
                            <ul className='user-details'>
                              <li>{item.User?.email}</li>
                              <li>{item?.User?.phoneNumber}</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                  </div>
                </div>

                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SelectField
                      options={status.map((item) => {
                        return {
                          label: `${item.name ? item.name : ''}`,
                          value: item.uuid,
                        };
                      })}
                      name='leadStage'
                      placeholder='Lead status'
                      required
                      className={`${errors.leadStage && 'error-field'} ${
                        watchAllFields.leadStage && 'filled'
                      }`}
                      control={control}
                    />
                    {errors.leadStage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.leadStage?.value?.message &&
                          'Please select a Stage'}
                      </div>
                    ) : null}
                  </div>
                </div>
                <h2 className='subText'>Contact Info</h2>
                <div
                  className={`d-block d-sm-flex  inputField justify-content-between ${
                    errors.firstName && 'name-error'
                  }`}
                >
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      className={`${errors.firstName && 'error-field'} ${
                        watchAllFields.firstName && 'filled'
                      }`}
                      register={register}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='smallField d-block d-sm-flex justify-content-between'>
                    <div className='fields-small smallfieldabove'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle Name'
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                        register={register}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last Name'
                        required
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                        register={register}
                      />
                      {errors.lastName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-block d-sm-flex inputField  justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      control={control}
                      type='email'
                      required
                      setUUID={resetEmail}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      errors.email?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message}
                        </div>
                      )
                    )}
                  </div>
                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number '
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                      control={control}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!isCommercial ? (
                  <div>
                    <div className='personaldetailas'>
                      {!isManual ? (
                        <div
                          className={`d-block d-sm-flex ${errors.email} justify-content-between`}
                        >
                          <div className='fields'>
                            <GoogleSearchInput
                              placeholder='Mailing address'
                              name='mailingAddress'
                              inputType='PERSONAL'
                              setAddressObject={setAddressObject}
                              addressObject={addressObject}
                              initialValue={address}
                              className={`${
                                errors.mailingAddress && 'error-field'
                              } ${
                                (Object.keys(addressObject).length > 0 ||
                                  address) &&
                                'filled'
                              }`}
                              setIsManual={setIsManual}
                            ></GoogleSearchInput>
                          </div>
                          <div className='fields'>
                            <InputField
                              name='contactApt.'
                              type='text'
                              placeholder='Apt.'
                              className={`${
                                errors.contactApt && 'error-field'
                              } ${watchAllFields.contactApt && 'filled'}`}
                              register={register}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <h2 className='subText mt-0'>Mailing address</h2>
                          <div className=' d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <InputField
                                name='address'
                                type='text'
                                placeholder='Address'
                                register={register}
                                className={`${
                                  errors.address && 'error-field'
                                } ${watchAllFields.address && 'filled'}`}
                              />
                            </div>
                            <div className='fields'>
                              <InputField
                                name='zipCode'
                                type='number'
                                placeholder='Zipcode'
                                register={register}
                                className={`${
                                  errors.zipCode && 'error-field'
                                } ${watchAllFields.zipCode && 'filled'}`}
                              />
                            </div>
                          </div>
                          <div className=' d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <InputField
                                name='city'
                                type='text'
                                placeholder='City'
                                register={register}
                                className={`${errors.city && 'error-field'} ${
                                  watchAllFields.city && 'filled'
                                }`}
                              />
                            </div>
                            <div className='fields'>
                              <InputField
                                name='state'
                                type='text'
                                placeholder='State'
                                register={register}
                                className={`${errors.state && 'error-field'} ${
                                  watchAllFields.state && 'filled'
                                }`}
                              />
                            </div>
                          </div>
                          <div className=' d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <InputField
                                name='country'
                                type='text'
                                placeholder='County'
                                register={register}
                                className={`${
                                  errors.country && 'error-field'
                                } ${watchAllFields.country && 'filled'}`}
                              />
                            </div>
                            <div className='fields'>
                              <InputField
                                name='contactApt'
                                type='text'
                                placeholder='Apt'
                                register={register}
                                className={`${
                                  errors.contactApt && 'error-field'
                                } ${watchAllFields.contactApt && 'filled'}`}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {moreDetailsbtn ? (
                        <div>
                          <div className='d-block d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <DateField
                                name='dateofBirth'
                                placeholder='Date of birth'
                                maxDate={new Date()}
                                control={control}
                                dateOfBirth
                                value={watchAllFields.dateofBirth}
                              />
                            </div>
                            <div className='fields'>
                              <SelectField
                                options={maritalStatus}
                                control={control}
                                name='maritalStatus'
                                placeholder='Marital status'
                              />
                            </div>
                          </div>

                          <div>
                            <div className='d-block d-sm-flex   justify-content-between'>
                              <div className='fields'>
                                <SearchDropdown
                                  name='jurisdictionUUID'
                                  placeholder='Licensed state'
                                  isEdit={true}
                                  values={jurisdictionSearch}
                                  searchValue={licensedState}
                                  setSearch={setJurisdictionSearch}
                                  status={loadingStatus}
                                  className={`${
                                    !jurisdictionsUUID &&
                                    submit &&
                                    'error-field'
                                  } ${jurisdictionSearch && 'filled'}`}
                                  register={register}
                                  control={control}
                                  loadFunction={loadLicensedState}
                                  setUUID={resetJurisdiction}
                                  content={
                                    licensedState.length > 0
                                      ? licensedState?.map((item) => (
                                          <div
                                            className='searchValuesCard d-flex align-items-center'
                                            onClick={() => {
                                              setJurisdictionSearch(item?.name);
                                              setJurisdictionsUUID(item?.uuid);
                                            }}
                                          >
                                            <div>
                                              <h6>{item.name}</h6>
                                            </div>
                                          </div>
                                        ))
                                      : null
                                  }
                                />
                              </div>
                              <div className='fields'>
                                <InputField
                                  name='liscenseNumber'
                                  type='text'
                                  placeholder='License Number'
                                  className={`${
                                    errors.liscenseNumber && 'error-field'
                                  } ${
                                    watchAllFields.liscenseNumber && 'filled'
                                  }`}
                                  register={register}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className='d-flex moredetails'
                          onClick={() => isMoreDetailsbtn(true)}
                        >
                          <img
                            src={blackplus}
                            alt='Add'
                            className='blackplus'
                          />
                          <h5 className='addbutton'>Add more details</h5>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {isCommercial ? (
                  <div>
                    <h2 className='subText commercialtext'>Business Info</h2>
                    <div className='d-block d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='businessName'
                          type='text'
                          placeholder='Business Name'
                          className={`${errors.businessName && 'error-field'} ${
                            watchAllFields.businessName && 'filled'
                          }`}
                          register={register}
                        />
                        {!watchAllFields.businessName && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {businessError}
                          </div>
                        ) : null}
                      </div>
                      {!isManual && (
                        <div className='d-block d-sm-flex subsmallField justify-content-between'>
                          <div className='fields-medium'>
                            <GoogleSearchInput
                              placeholder='Business address'
                              name='businessAddress'
                              inputType='PERSONAL'
                              setAddressObject={setAddressObject}
                              addressObject={addressObject}
                              initialValue={address}
                              className={`${
                                errors.businessAddress && 'error-field'
                              } ${
                                (Object.keys(addressObject).length > 0 ||
                                  address) &&
                                'filled'
                              }`}
                              setIsManual={setIsManual}
                            ></GoogleSearchInput>
                          </div>
                          <div className='fields-Xsmall'>
                            <InputField
                              name='businessApt'
                              type='text'
                              placeholder='Apt.'
                              className={`${
                                errors.businessApt && 'error-field'
                              } ${watchAllFields.businessApt && 'filled'}`}
                              register={register}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {isManual && (
                      <>
                        <h2 className='subText mt-0'>Business address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='businessApt'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.businessApt && 'error-field'
                              } ${watchAllFields.businessApt && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      <div className='d-block d-sm-flex   justify-content-between'>
                        <div className='fields'>
                          <PhoneNumberInput
                            name='businessPhoneNumber'
                            type='tel'
                            placeholder='Business PhoneNumber'
                            className={`${
                              errors.businessPhoneNumber && 'error-field'
                            } ${
                              watchAllFields.businessPhoneNumber && 'filled'
                            }`}
                            control={control}
                          />
                          {(!watchAllFields.businessPhoneNumber ||
                            !new RegExp(
                              /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/
                            ).test(watchAllFields.businessPhoneNumber)) &&
                          submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Please enter Business Phone Number
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <InputField
                            name='businessEmail'
                            type='email'
                            placeholder='Business email'
                            register={register}
                            className={`${
                              errors.businessEmail && 'error-field'
                            } ${watchAllFields.businessEmail && 'filled'}`}
                          />
                          {errors.businessEmail ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.businessEmail?.message}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='d-block d-sm-flex   justify-content-between'>
                        <div className='fields'>
                          {/* <SelectField
                          options={industry}
                          name='industry'
                          placeholder='Industry (Optional)'
                          className={`${
                            (errors.industry || errorMessage) && 'error-field'
                          } ${watchAllFields.industry && 'filled'}`}
                          control={control}
                        /> */}
                          <SearchDropdown
                            name='industry'
                            placeholder='Industry (Optional)'
                            isEdit={true}
                            values={industrySearch}
                            searchValue={industry}
                            setSearch={setIndustrySearch}
                            status={loadingStatus}
                            className={`${industrySearch && 'filled'}`}
                            register={register}
                            control={control}
                            loadFunction={loadIndustry}
                            setUUID={resetIndustry}
                            content={industry?.map((item: any) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setIndustrySearch(
                                    `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                  );
                                  setIndustryUuid(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>
                                    {item.naicsSixDigitCode} -{' '}
                                    {item.naicsSixDigitCodeDescription}
                                  </h6>
                                  <p className='description'>
                                    {item.naicsFourDigitCodeDescription}
                                  </p>
                                </div>
                              </div>
                            ))}
                          />
                          {industrySearch && !industryUuid && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Industry not found
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className='mt-4'>
                  {customFields?.length > 0
                    ? customFields?.map((item: any, index: number) =>
                        index % 2 === 0 ? (
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                                setDueTimeVal={setDueTimeVal}
                                duetimeVal={duetimeVal}
                              />
                            </div>
                            {customFields?.length > index + 1 ? (
                              <div className='fields'>
                                <CustomFieldInput
                                  item={customFields[index + 1]}
                                  register={register}
                                  control={control}
                                  value={
                                    watchAllFields[customFields[index + 1].uuid]
                                  }
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )
                    : null}
                </div>
                <div className='opportunityComponent'>
                  <h2
                    className={
                      !moreDetailsbtn
                        ? 'subText '
                        : 'subText opportunityComponentaddMore'
                    }
                  >
                    Add Opportunity
                  </h2>
                  {opportunities?.map((item: any, index: any) => (
                    <Opportunity
                      key={index}
                      opportunity={item}
                      index={index}
                      setOpportunities={setOpportunities}
                      opportunities={opportunities}
                      register={register}
                      isCommercial={isCommercial}
                      setIsOpportunityEdit={setIsOpportunityEdit}
                    />
                  ))}
                  <div
                    className=' d-flex addOpportunity'
                    onClick={() => addsOpportunity()}
                  >
                    <img src={blackplus} alt='Add' className='blackplus' />
                    <h5 className='addOp'>Add Another Opportunity</h5>
                  </div>
                </div>
              </div>

              <div className='buttonsDiv d-flex align-items-center'>
                <button
                  onClick={() => {
                    onCloseModal();
                    setQulifyMessage('');
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='agentButton'
                  onClick={() => setSubmit(true)}
                  disabled={
                    show === 'closing' ||
                    (watchAllFields?.leadSource?.value ===
                      leadDetails?.LeadSource?.uuid &&
                      !isOpportunityEdit &&
                      producer === leadDetails?.Agent?.uuid &&
                      watchAllFields?.leadStage?.value ===
                        leadDetails?.LeadStage?.uuid &&
                      watchAllFields?.firstName ===
                        leadDetails?.data?.contact?.firstName &&
                      watchAllFields?.middleName ===
                        leadDetails?.data?.contact?.middleName &&
                      watchAllFields.lastName ===
                        leadDetails?.data.contact?.lastName &&
                      emailSearch === leadDetails?.data.contact?.email &&
                      watchAllFields.phoneNumber ===
                        leadDetails?.data.contact?.phoneNumber &&
                      ((((!isCommercial &&
                        !isManual &&
                        addressObject?.address ===
                          leadDetails?.data?.contact?.streetAddress &&
                        watchAllFields?.contactApt ===
                          leadDetails?.data?.contact?.apartment) ||
                        (!isCommercial &&
                          isManual &&
                          watchAllFields?.address ===
                            leadDetails?.data?.contact?.streetAddress &&
                          watchAllFields?.zipCode ===
                            leadDetails?.data?.contact?.zipCode &&
                          watchAllFields?.contactApt ===
                            leadDetails?.data?.contact?.apartment &&
                          watchAllFields?.city ===
                            leadDetails?.data?.contact?.city &&
                          watchAllFields?.state ===
                            leadDetails?.data?.contact?.state &&
                          watchAllFields?.country ===
                            leadDetails?.data?.contact?.country)) &&
                        !isCommercial &&
                        watchAllFields?.maritalStatus?.value ===
                          leadDetails?.data?.contact?.maritalStatus &&
                        jurisdictionsUUID ===
                          leadDetails?.data?.contact?.jurisdictionUUID &&
                        watchAllFields?.liscenseNumber ===
                          leadDetails?.data?.contact?.liscenseNumber) ||
                        (isCommercial &&
                          watchAllFields.businessName ===
                            leadDetails?.data.business.businessName &&
                          watchAllFields.businessPhoneNumber ===
                            leadDetails?.data.business.businessPhoneNumber &&
                          watchAllFields.businessEmail ===
                            leadDetails?.data.business.businessEmail &&
                          industryUuid ===
                            leadDetails?.data.business.industry)) &&
                      (customFields?.length > 0
                        ? customFields?.every((item: any) => {
                            const opportunityCustom =
                              leadDetails?.LeadCustomField?.find(
                                (el: any) => el.CustomField.uuid === item.uuid
                              );
                            if (item.FieldType.name === 'SINGLE_CHOICE') {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid]?.label ===
                                  opportunityCustom.value?.label
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid]?.label !==
                                  undefined &&
                                watchAllFields[item.uuid]?.value !== undefined
                              ) {
                                return false;
                              }
                              return true;
                            } else if (
                              item.FieldType.name === 'MULTIPLE_CHOICE'
                            ) {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid].every(
                                    (watchEl: any) => {
                                      return opportunityCustom.value.find(
                                        (opEl: any) =>
                                          opEl.label === watchEl.label
                                      );
                                    }
                                  ) &&
                                  opportunityCustom.value.every(
                                    (watchEl: any) => {
                                      return watchAllFields[item.uuid].find(
                                        (opEl: any) =>
                                          opEl.label === watchEl.label
                                      );
                                    }
                                  )
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid].length > 0
                              ) {
                                return false;
                              }
                              return true;
                            } else if (item.FieldType.name === 'TIME') {
                              let timeItem = duetimeVal.find(
                                (el: any) => el.uuid === item.uuid
                              );
                              if (opportunityCustom && timeItem) {
                                return (
                                  moment(timeItem?.value).toISOString() ===
                                  opportunityCustom.value
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                timeItem !== '' &&
                                timeItem !== undefined
                              ) {
                                return false;
                              }
                              return true;
                            } else if (item.FieldType.name === 'DATEPICKER') {
                              if (opportunityCustom) {
                                return (
                                  moment(
                                    watchAllFields[item.uuid]
                                  ).toISOString() === opportunityCustom.value
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid] !== '' &&
                                watchAllFields[item.uuid] !== undefined
                              ) {
                                return false;
                              }
                              return true;
                            } else {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid] ===
                                  opportunityCustom.value
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid] !== '' &&
                                watchAllFields[item.uuid] !== undefined &&
                                watchAllFields[item.uuid] !== false
                              ) {
                                return false;
                              }
                              return true;
                            }
                          })
                        : true)) ||
                    isLoading
                  }
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
              {/* {warning ? (
                <WarningModal
                  open={showWarningModal}
                  closeModal={setShowWarningModal}
                />
              ) : null} */}
            </form>
          </div>
        </Modal>
      </LeadStyledModal>
    </>
  );
};

export default EditLeadDrawer;

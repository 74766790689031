import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuoteCardDiv from './components/Quotescard';
import QuoteDetailsCardMobile from './components/QuotesDetailsMobile';
import { InnerFieldStyle } from '../../styles/InnerFieldStyles';
import plus from '../../assets/logo/blackplus.svg';
import AddQuoteModal from '../AddQuoteModal';
import { useParams } from 'react-router-dom';
import {
  getAccountOpportunities,
  selectAccountOpportunities,
  AccountsSliceState,
  clearAccountOpportunities,
} from '../../store/accounts/accountsSlice';
import {
  getContactOpportunities,
  selectContactOpportunities,
  ContactsSliceState,
  clearOpportunities,
} from '../../store/contacts/contactsSlice';
import OpportunityQuoteCardDiv from '../../pages/OpportunityDetails/components/OpportunityQuoteInnerTab';
import {
  fetchQuotes,
  selectAllQuotes,
  QuoteSliceState,
  onDeleteQuote,
} from '../../store/quotes/quoteSlice';
import plusbutton from '../../assets/logo/plus.svg';
import { AppDispatch } from '../../store';
import Skeleton from 'react-loading-skeleton';
import EmptyResults from '../../pages/MyBook/components/Empty';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import QuotesFilter from './FilterDrawer';
import DeleteModal from '../DeleteModal';
import ErrorCard from '../../pages/Pipeline/components/ErrorCard';
import { IOption } from '../../interfaces/Opportunity';

const Quotes = ({
  pageName,
  isMobile,
  getCount,
  stageOpportunityUUID,
}: {
  pageName: string;
  isMobile?: boolean;
  getCount?: Function;
  stageOpportunityUUID?: string;
}) => {
  const [showQuoteModal, setShowQuoteModal] = useState('closed');

  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const accountOpportunities = useSelector(selectAccountOpportunities);
  const contactOpportunities = useSelector(selectContactOpportunities);
  const quotes = useSelector(selectAllQuotes);
  const accountsStatus = useSelector(
    (state: { accounts: AccountsSliceState }) =>
      state.accounts.oppportunityStatus
  );
  const contactsStatus = useSelector(
    (state: { contacts: ContactsSliceState }) =>
      state.contacts.opportunitiesStatus
  );

  const quotesStatus = useSelector(
    (state: { quotes: QuoteSliceState }) => state.quotes.status
  );
  const ContactQuotesCount = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.quotesBool
  );

  const [selectedQuoteType, setSelectedQuoteType] = useState<IOption>();
  const [selectedQuoteStatus, setSelectedQuoteStatus] = useState<IOption>();
  const [selectedQuoteStage, setSelectedQuoteStage] = useState<IOption[]>([]);
  const [selectedCoverageType, setSelectedCoverageType] = useState<IOption[]>(
    []
  );

  useEffect(() => {
    dispatch(clearOpportunities());
    dispatch(clearAccountOpportunities());
    if (uuid && !isMobile) {
      if (pageName === 'Account') {
        dispatch(
          getAccountOpportunities({
            uuid: uuid,
            offset: 0,
            getQuote: 'True',
            producerUUID: '',
            quoteTypeUUID: selectedQuoteType?.value,
            quoteStageUUID: selectedQuoteStage?.map((item: any) => item.value),
            quoteStatusUUID: selectedQuoteStatus?.value,
            coverageTypeUUID: selectedCoverageType?.map(
              (item: any) => item.value
            ),
          })
        );
      } else if (pageName === 'Contact') {
        dispatch(
          getContactOpportunities({
            uuid: uuid,
            offset: 0,
            getQuote: 'True',
            pipelineUUID: '',
            opportunityStatusUUID: '',
            productCategoryUUID: '',
            producerUUID: '',
            productUUID: '',
            customProductUUID: '',
            quoteTypeUUID: selectedQuoteType?.value,
            quoteStageUUID: selectedQuoteStage?.map((item: any) => item.value),
            quoteStatusUUID: selectedQuoteStatus?.value,
            coverageTypeUUID: selectedCoverageType?.map(
              (item: any) => item.value
            ),
          })
        );
      } else {
        dispatch(
          fetchQuotes({
            opportunityUUID: uuid
              ? uuid
              : stageOpportunityUUID
              ? stageOpportunityUUID
              : '',
            successCB: () => {},
            sortBy: '',
            orderBy: '',
            searchBy: '',
            limit: 0,
            offset: 0,
            getQuote: 'True',
            quoteTypeUUID: selectedQuoteType?.value,
            quoteStageUUID: selectedQuoteStage?.map((item: any) => item.value),
            quoteStatusUUID: selectedQuoteStatus?.value,
            coverageTypeUUID: selectedCoverageType?.map(
              (item: any) => item.value
            ),
          })
        );
      }
    }
  }, [
    uuid,
    selectedQuoteType,
    selectedCoverageType,
    selectedQuoteStatus,
    selectedQuoteStage,
  ]);

  const scrollAccount = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      accountsStatus !== 'loading' &&
      uuid
    ) {
      if (!ContactQuotesCount) {
        dispatch(
          getAccountOpportunities({
            uuid,
            offset: accountOpportunities.length,
          })
        );
      }
    }
  };
  const scrollContact = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      contactsStatus !== 'loading' &&
      uuid
    ) {
      if (!ContactQuotesCount) {
        dispatch(
          getContactOpportunities({
            uuid,
            offset: contactOpportunities.length,
          })
        );
      }
    }
  };

  const scrollQuotes = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      quotesStatus !== 'loading' &&
      uuid
    ) {
      if (quotes.length === 0) {
        dispatch(
          fetchQuotes({
            opportunityUUID: uuid
              ? uuid
              : stageOpportunityUUID
              ? stageOpportunityUUID
              : '',
            successCB: () => {},
            sortBy: '',
            orderBy: '',
            searchBy: '',
            limit: 0,
            offset: quotes.length,
            getQuote: 'True',
          })
        );
      }
    }
  };

  const [showFilter, setShowFilter] = useState('closed');

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const [detailName, setDetailName] = useState('');
  const [UUID, setUUID] = useState('');
  const loadingStatus = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.status
  );
  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setDetailName(name);
  };
  const onDelete = () => {
    dispatch(
      onDeleteQuote({
        uuid: UUID || '',
        successCB: () => {
          if (getCount) getCount();
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <InnerFieldStyle>
      {showSuccess && (
        <ErrorCard
          message={`<b>${detailName}</b> has been <b> deleted</b> `}
          title={'Success deleting quote.'}
        />
      )}
      <div className='taskDiv '>
        <div className='taskHead d-flex justify-content-between'>
          <button
            className='tertiaryButton d-none d-sm-block'
            onClick={() => setShowQuoteModal('open')}
            disabled={showQuoteModal !== 'closed'}
          >
            Add quote <img className='addIcon' src={plus} alt='' />
          </button>
          <button
            className='tertiaryButton filterbutton'
            onClick={() => setShowFilter('open')}
          >
            Filter by:&nbsp;{' '}
            <b>
              {selectedQuoteStage?.length +
                selectedCoverageType?.length +
                (selectedQuoteStatus?.value ? 1 : 0) +
                (selectedQuoteType?.value ? 1 : 0) ===
              0
                ? 'No'
                : selectedQuoteStage?.length +
                  selectedCoverageType?.length +
                  (selectedQuoteStatus?.value ? 1 : 0) +
                  (selectedQuoteType?.value ? 1 : 0)}{' '}
              filter
            </b>
          </button>
        </div>
        {pageName === 'Account' && (
          <div
            className='opportunityDiv d-none d-sm-block'
            onScroll={scrollAccount}
          >
            {accountOpportunities.length > 0
              ? accountOpportunities?.map((item: any, index: number) => (
                  <QuoteCardDiv details={item} name={pageName} />
                ))
              : accountsStatus !== 'loading' && <EmptyResults name='quote' />}
            {accountsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}
        {pageName === 'Contact' && (
          <div
            className='opportunityDiv d-none d-sm-block '
            onScroll={scrollContact}
          >
            {contactOpportunities.length > 0
              ? contactOpportunities?.map((item: any, index: number) => (
                  <QuoteCardDiv details={item} name={pageName} />
                ))
              : contactsStatus !== 'loading' && <EmptyResults name='quote' />}
            {contactsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}

        {pageName === 'Opportunity' && (
          <div
            className='opportunityDiv d-none d-sm-block '
            onScroll={scrollQuotes}
          >
            {quotes.length > 0
              ? quotes?.map((item: any, index: number) => (
                  <OpportunityQuoteCardDiv
                    details={item}
                    setShowDeleteModal={setShowDeleteModal}
                    onDelete={setDelete}
                  />
                ))
              : quotesStatus !== 'loading' && <EmptyResults name='quote' />}
            {quotesStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}
        {/* Mobile screen */}

        {pageName === 'Account' && (
          <div className='opportunityDiv d-sm-none' onScroll={scrollAccount}>
            {accountOpportunities.length > 0
              ? accountOpportunities?.map((item: any, index: number) => (
                  <QuoteDetailsCardMobile details={item} name={pageName} />
                ))
              : accountsStatus !== 'loading' && <EmptyResults name='quote' />}
            {accountsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}
        {pageName === 'Contact' && (
          <div className='opportunityDiv d-sm-none' onScroll={scrollContact}>
            {contactOpportunities.length > 0
              ? contactOpportunities?.map((item: any, index: number) => (
                  <QuoteDetailsCardMobile details={item} name={pageName} />
                ))
              : contactsStatus !== 'loading' && <EmptyResults name='quote' />}
            {contactsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}
        {pageName === 'Opportunity' && (
          <div className='opportunityDiv d-sm-none' onScroll={scrollQuotes}>
            {quotes.length > 0
              ? quotes?.map((item: any, index: number) => (
                  <QuoteDetailsCardMobile
                    details={item}
                    name={pageName}
                    onDelete={onDelete}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                ))
              : quotesStatus !== 'loading' && <EmptyResults name='quote' />}
            {quotesStatus === 'loading'
              ? [1, 2, 3].map((item) => <Skeleton height={80} key={item} />)
              : null}
          </div>
        )}
        <button
          className='addButton d-sm-none'
          onClick={() => setShowQuoteModal('open')}
        >
          <img src={plusbutton} alt='add' />
        </button>
      </div>
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type={pageName}
          opportunityUUID={stageOpportunityUUID}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <QuotesFilter
            show={showFilter}
            closeModal={setShowFilter}
            selectedQuoteType={selectedQuoteType}
            setSelectedQuoteType={setSelectedQuoteType}
            selectedQuoteStatus={selectedQuoteStatus}
            setSelectedQuoteStatus={setSelectedQuoteStatus}
            selectedQuoteStage={selectedQuoteStage}
            setSelectedQuoteStage={setSelectedQuoteStage}
            selectedCoverageType={selectedCoverageType}
            setSelectedCoverageType={setSelectedCoverageType}
          />
        )}
      </FilterContainer>
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid=''
          loading={loadingStatus || 'idle'}
          resource='quote'
          name={detailName}
        />
      )}
    </InnerFieldStyle>
  );
};

export default Quotes;

import React, { useEffect, useState } from 'react';
import { fetchIndustries } from '../../../../api/industry';
import InputField from '../../../../components/CustomInputField';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import SearchDropdown from '../../../../components/SearchDropdown';
import warning from '../../../../assets/logo/warning.svg';
import cross from '../../../../assets/logo/cross.svg';

const NewBusinessQuickForm = ({
  business,
  index,
  businesses,
  setBusiness,
  businessPhoneNumberError,
  businessNameError,
}: // amountError,
{
  business: any;
  index: number;
  businesses: any[];
  setBusiness: Function;
  businessPhoneNumberError?: string;

  businessNameError?: string;
  // amountError?: number;
}) => {
  const [addressObject, setAddressObject] = useState<any>({});

  const onItem = (name: string, value: string, address: boolean = false) => {
    if (address) {
      Object.assign(business, value);
      let newBussiness = business;
      let updatedBusiness = [...businesses];
      updatedBusiness[index] = newBussiness;
      setBusiness(updatedBusiness);
    } else {
      let newBussiness = business;
      let updatedBusiness = [...businesses];
      newBussiness[name] = value;
      updatedBusiness[index] = newBussiness;
      setBusiness(updatedBusiness);
    }
  };
  const formatNum = (num: string) => {
    const number = num.replace(/[^\d]/g, '').slice(0, 10);
    if (number.length < 3) {
      return `${number}`;
    }
    if (number.length < 4 && num.includes('(')) {
      return `${number}`;
    }
    if (number.length < 4) {
      return `(${number.slice(0, 3)})`;
    }
    if (number.length > 3 && number.length < 7) {
      return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    }
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
      6,
      10
    )}`;
  };
  const onbusinessItem = (name: string, value: string) => {
    let businessphonenumberValue: string;
    if (!value) businessphonenumberValue = value;
    businessphonenumberValue = formatNum(value);
    onItem('businessPhoneNumber', businessphonenumberValue, false);
  };

  const [industrySearch, setIndustrySearch] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [isManual, setIsManual] = useState(false);

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (industrySearch) {
        fetchIndustries(
          (data: any) => {
            setIndustry(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setIndustry([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  const resetIndustry = (value: string) => {
    onItem('industry', value);
    setIndustry([]);
  };
  useEffect(() => {
    onItem('isManuallyAdded', String(isManual));
  }, [isManual]);

  return (
    <div className={`${index > 0 ? 'business-container' : ''}`}>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          {index > 0 ? (
            <>
              <h2 className='business-info bussinessInfoHead'>New Business </h2>
              <button
                type='button'
                className='d-flex secondaryButton removeButton align-items-center'
                onClick={() => {
                  const updatedBusinesses = [...businesses];
                  updatedBusinesses.splice(index, 1);

                  setBusiness(updatedBusinesses);
                }}
              >
                Remove
                <img className='removeIcon mx-1' src={cross} alt='remove' />
              </button>
            </>
          ) : (
            <h2 className='business-info bussinessInfoHead'>Business info </h2>
          )}
        </div>
        <div className='top-field d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='name'
              type='text'
              placeholder='Business name'
              onSetItem={onItem}
              values={business}
              required
            />
            {businessNameError ? (
              <>
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {businessNameError}
                </div>
              </>
            ) : null}
          </div>
          <div className='fields'>
            {/* <SelectField
              options={industry}
              name='industry'
              placeholder='Industry(Optional)'
              values={business}
              onSetItem={onItem}
            /> */}
            <SearchDropdown
              name='industry'
              placeholder='Industry (Optional)'
              isEdit={true}
              values={industrySearch}
              searchValue={industry}
              setSearch={setIndustrySearch}
              status={loadingStatus}
              className={`${industrySearch && 'filled'}`}
              loadFunction={loadIndustry}
              setUUID={resetIndustry}
              content={industry?.map((item: any) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setIndustrySearch(
                      `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                    );
                    onItem('industry', item?.uuid);
                  }}
                >
                  <div>
                    <h6>
                      {item.naicsSixDigitCode} -{' '}
                      {item.naicsSixDigitCodeDescription}
                    </h6>
                    <p className='description'>
                      {item.naicsFourDigitCodeDescription}
                    </p>
                  </div>
                </div>
              ))}
            />
          </div>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='businessPhoneNumber'
              type='text '
              placeholder='Business phone number'
              onSetItem={onbusinessItem}
              values={business}
              required
            />
            {businessPhoneNumberError ? (
              <>
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {businessPhoneNumberError}
                </div>
              </>
            ) : null}
          </div>
          <div className='fields'>
            <InputField
              name='businessEmail'
              type='email'
              placeholder='Business email'
              onSetItem={onItem}
              values={business}
            />
          </div>
        </div>
        <div>
          {!isManual ? (
            <div className='d-sm-flex justify-content-between'>
              <div className='fields'>
                <GoogleSearchInput
                  placeholder='Business address'
                  name='businessAddress'
                  setAddressObject={setAddressObject}
                  addressObject={addressObject}
                  inputType='COMMERCIAL'
                  onSetItem={onItem}
                  index={index}
                  className={`${
                    Object.keys(businesses[index]).length > 1 && 'filled'
                  }`}
                  setIsManual={setIsManual}
                ></GoogleSearchInput>
              </div>
              <div className='fields'>
                <InputField
                  name='businessApt'
                  type='text'
                  placeholder='Apt'
                  onSetItem={onItem}
                  values={business}
                />
              </div>
            </div>
          ) : (
            <>
              <h2 className='business-info-address'>Business address</h2>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='address'
                    type='text'
                    placeholder='Address'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='zipCode'
                    type='number'
                    placeholder='Zipcode'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='city'
                    type='text'
                    placeholder='City'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='state'
                    type='text'
                    placeholder='State'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='country'
                    type='text'
                    placeholder='County'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='businessApt'
                    type='text'
                    placeholder='Apt'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewBusinessQuickForm;

import Select, { components } from 'react-select';
import {
  CustomMultiValue,
  CustomMultiValueContainer,
  customSelectStyles,
  CustomValueContainer,
  MultiOption,
} from '../SelectField';

interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  options: any[];
  values: any;
  disbaled?: boolean;
  onSetItem: Function;
}

const { Option } = components;

const SelectField = ({
  name,
  placeholder,
  options,
  values,
  disabled,
  onSetItem,
  ...props
}: IProps) => {
  return (
    <Select
      styles={customSelectStyles}
      options={options}
      placeholder={placeholder}
      components={{
        ValueContainer: props.isMulti
          ? CustomMultiValueContainer
          : CustomValueContainer,
        MultiValue: CustomMultiValue,
        Option: props.isMulti ? MultiOption : Option,
      }}
      isClearable
      isDisabled={disabled}
      isOptionDisabled={(option: any) => option.isDisabled}
      {...props}
      // value={
      //   options.find(
      //     (option: { value: string; label: string }) =>
      //       option.value === values[name]
      //   )
      //     ? options.find(
      //         (option: { value: string; label: string }) =>
      //           option.value === values[name]
      //       )
      //     : ''
      // }
      onChange={(val) => {
        props.isCustomField
          ? onSetItem(name, val)
          : onSetItem(name, val?.value);
      }}
    />
  );
};

export default SelectField;

import { ISearchResult } from '../interfaces/SearchResult';

class LocalStorageService {
  static setToken = (access_token: string, refresh_token: string) => {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
  };

  static rememberUser = (remember: string) => {
    localStorage.setItem('remember', remember);
  };

  static checkRemember = () => {
    return localStorage.getItem('remember') ? true : false;
  };

  static getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  static getRefreshToken = () => {
    const rToken = localStorage.getItem('refresh_token');
    if (rToken) {
      return rToken;
    } else return;
  };

  static setHistory = (item: {
    text: string;
    type: string;
    data?: ISearchResult;
  }) => {
    const history = localStorage.getItem('history');
    let updatedHistory = history ? JSON.parse(history) : [];
    updatedHistory.unshift(item);
    if (updatedHistory?.length > 8) {
      updatedHistory.pop();
    }
    updatedHistory = Array.from(
      new Map(updatedHistory.map((his: any) => [his['text'], his])).values()
    );
    localStorage.setItem('history', JSON.stringify(updatedHistory));
  };

  static getHistory = () => {
    const history = localStorage.getItem('history');
    return history ? JSON.parse(history) : [];
  };

  static removeFromHistory = (index: number) => {
    const history = localStorage.getItem('history');
    let updatedHistory = history ? JSON.parse(history) : [];
    if (index >= 0) updatedHistory.splice(index, 1);
    localStorage.setItem('history', JSON.stringify(updatedHistory));
  };

  static clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('history');
    localStorage.removeItem('user');
    localStorage.removeItem('remember');
  };

  static setUser = (user: string) => {
    localStorage.setItem('user', user);
  };

  static getUser = () => {
    const loggedUser = localStorage.getItem('user');
    return loggedUser ? JSON.parse(loggedUser) : null;
  };
}
export default LocalStorageService;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../store';
import {
  onEditLeadSource,
  selectLeadSourceTypes,
} from '../../../store/leads/leadsSlice';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import { ILeadSource, ILeadSourceType } from '../../../interfaces/Lead';
import { StyledModal } from '../../Pipeline/elements/styles';
import close from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

const LeadSourceSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter the name.')
    .max(25, 'Name length must be less than 25.'),
  type: Yup.object().shape({
    label: Yup.string().required('Please select a type.'),
    value: Yup.string().required('Please select a type.'),
  }),
});

const EditSourceModal = ({
  showEditSource,
  setShowEditSource,
  setSuccessMessage,
  defaultType,
  leadSource,
}: {
  showEditSource: boolean;
  setShowEditSource: Function;
  setSuccessMessage: Function;
  defaultType: ILeadSourceType;
  leadSource: ILeadSource;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const types = useSelector(selectLeadSourceTypes);
  const [errorMessage, setError] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LeadSourceSchema),
  });

  const watchName = watch('name');

  useEffect(() => {
    setValue('name', leadSource.name);
    setValue('type', { label: defaultType.name, value: defaultType.uuid }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadSource]);

  const onAddSource = (data: any) => {
    data.agencyUUID = uuid;
    data.typeUUID = data.type.value;
    dispatch(
      onEditLeadSource({
        uuid: leadSource.uuid,
        data,
        successCB: () => {
          reset();
          setShowEditSource(false);
          setSuccessMessage(`<b>${watchName}</b> has been updated.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
        errorCB: (msg: string) => setError(msg),
      })
    );
  };

  return (
    <StyledModal
      show={showEditSource}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt=''
        src={close}
        className='closeButton'
        onClick={() => setShowEditSource(false)}
      />
      <h2 className='mb-3'>Edit lead source</h2>
      <form onSubmit={handleSubmit(onAddSource)}>
        <div className='mb-3'>
          <SelectField
            options={types.map((type) => {
              return { label: type.name, value: type.uuid };
            })}
            name='type'
            placeholder='Select lead source type'
            control={control}
            isDisabled={true}
            required
          />
          {errors.type ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errors.type?.value?.message}
            </div>
          ) : null}
        </div>
        <div className='mb-3'>
          <InputField
            name='name'
            type='text'
            placeholder='Name of lead source'
            required
            register={register}
            className={`${(errors.name || errorMessage) && 'error-field'} ${
              watchName && 'filled'
            }`}
          />
          {errorMessage || errors.name ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errorMessage || errors.name?.message}
            </div>
          ) : null}
        </div>

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowEditSource(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button
            className='primaryButton'
            type='submit'
            disabled={leadSource.name === watchName}
          >
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default EditSourceModal;

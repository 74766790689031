import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { CardMobileDiv } from '../../../styles/InnerTabsCardStyles';
import EditAccountDetails from '../../../pages/AccountDetails/EditAccountDetails';

const DetailsCardMobile = ({
  name,
  details,
  onDelete,
  loadingStatus,
  showDeleteModal,
  setShowDeleteModal,
}: {
  name: string;
  details?: any;
  onDelete: Function;
  loadingStatus?: string;
  showDeleteModal?: string;
  setShowDeleteModal?: Function;
}) => {
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState('closed');
  return (
    <CardMobileDiv>
      {' '}
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center'>
            <div className='d-flex align-items-center AccountHead justify-content-between '>
              <div>
                <span className='oppName opp-width'>{details?.name}</span>
              </div>
              <div
                className={`oppstatus ${
                  details?.AccountType?.name === 'PERSONAL'
                    ? 'greenC'
                    : 'yellowC'
                }`}
              >
                <h6>{details?.AccountType?.name.toLowerCase()}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className='lowContent'>
          <div className='oppDetaisl'>
            <div className='oppDetaislfields  '>
              <>
                <div className='d-flex valHead justify-content-start'>
                  <span className='valueField borderDiv'>
                    <b>$</b>
                    {details?.premium
                      ? details?.premium.toLocaleString('en-US')
                      : 0}
                  </span>

                  <span className='valueField'>
                    <span className='number'>
                      {details?.Opportunities.length}
                    </span>
                    opportunities
                  </span>
                </div>
                <div>
                  <span className='valHead '>Created on</span>
                  <span className='valueField'>
                    <Moment format='MM/DD/YYYY'>{details?.createdAt}</Moment>
                  </span>
                </div>
              </>
            </div>
            <div className=' d-flex align-items-center justify-content-between '>
              <>
                <div className='profile-pic mobileProfileAccount d-flex align-items-center justify-content-center '>
                  {details?.name?.charAt(0) + details?.name?.charAt(5)}
                </div>
                <div className='d-flex'>
                  <button
                    className='tertiaryButton quotingbtn '
                    onClick={() => navigate(`/account/${details.uuid}`)}
                  >
                    View details <img className='addIcon' src={arrow} alt='' />
                  </button>
                  <ActionsDropdown
                    uuid={details?.uuid}
                    setShowDeleteModal={setShowDeleteModal}
                    name={details?.name}
                    deleteFun={onDelete}
                    setShowEditModal={setShowEditModal}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
        {details && showEditModal !== 'closed' && (
          <EditAccountDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={details.uuid}
          />
        )}
      </div>
    </CardMobileDiv>
  );
};

export default DetailsCardMobile;

const ActionsDropdown = ({
  uuid,
  setShowDeleteModal,
  name,
  deleteFun,
  setShowEditModal,
}: {
  uuid: string;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
  setShowEditModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => setShowActionsDropdown(!showActionsDropdown)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {setShowDeleteModal && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setShowActionsDropdown(false);
                deleteFun(uuid, name);
              }}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

import moment from 'moment';
import Moment from 'react-moment';
import SearchDropdown from '../../../components/SearchDropdown';

const LinkToField = ({
  resource,
  resourceSearch,
  resourceList,
  setResourceSearch,
  loadingStatus,
  register,
  control,
  loadResource,
  setResourceUUID,
}: {
  resource: string;
  resourceSearch: string;
  resourceList: any[];
  setResourceSearch: Function;
  loadingStatus: String;
  register: any;
  control: any;
  loadResource: Function;
  setResourceUUID: Function;
}) => {
  return (
    <div>
      <SearchDropdown
        name={resource.toLowerCase() + 'UUID'}
        placeholder={`Select ${resource.toLowerCase()}`}
        isEdit={true}
        values={resourceSearch}
        searchValue={resourceList}
        setSearch={setResourceSearch}
        status={loadingStatus}
        className={`${resourceSearch && 'filled'}`}
        register={register}
        control={control}
        loadFunction={loadResource}
        setUUID={setResourceUUID}
        required
        smallField={true}
        content={resourceList?.map((item) => (
          <DesignDiv
            resource={resource}
            item={item}
            setResourceSearch={setResourceSearch}
            setResourceUUID={setResourceUUID}
          />
        ))}
      />
    </div>
  );
};

export default LinkToField;

const DesignDiv = ({
  resource,
  item,
  setResourceSearch,
  setResourceUUID,
}: {
  resource: string;
  item: any;
  setResourceSearch: Function;
  setResourceUUID: Function;
}) => {
  return (
    <div
      className='searchValuesCard d-flex align-items-center'
      onClick={() => {
        if (resource === 'Contact') {
          setResourceSearch(item?.firstName + ' ' + item?.lastName);
          setResourceUUID(item?.uuid);
        } else if (resource === 'Opportunity') {
          setResourceSearch(item?.name);
          setResourceUUID(item?.uuid);
        } else if (resource === 'Account') {
          setResourceSearch(item?.name);
          setResourceUUID(item?.uuid);
        } else if (resource === 'Lead') {
          setResourceSearch(item?.data?.contact?.name);
          setResourceUUID(item?.uuid);
        } else if (resource === 'Policy') {
          setResourceSearch(item?.Accounts?.name);
          setResourceUUID(item?.uuid);
        }
      }}
    >
      <div className='profile-pic d-flex align-items-center justify-content-center'>
        {resource === 'Contact' &&
          `${item?.firstName?.charAt(0)} ${item?.lastName?.charAt(0)}`}
        {resource === 'Opportunity' &&
          `${item?.name ? item?.name.charAt(0) : '---'}`}
        {resource === 'Account' &&
          `${item?.name ? item?.name.charAt(0) : '---'}`}
        {resource === 'Lead' &&
          `${item?.data?.contact?.firstName.charAt(
            0
          )} ${item?.data?.contact?.lastName?.charAt(0)}`}
        {resource === 'Policy' &&
          `${
            item?.Accounts?.name?.charAt(0)
              ? item?.Accounts?.name?.charAt(0)
              : '---'
          }`}
      </div>
      <div>
        <h6>
          {resource === 'Contact' &&
            `${item?.firstName} ${item.lastName ? item.lastName : ''}`}
          {resource === 'Opportunity' && item?.name}
          {resource === 'Account' && item?.name}
          {resource === 'Lead' && `${item?.data?.contact?.name}`}
          {resource === 'Policy' &&
            `${item?.Accounts?.name ? item?.Accounts?.name : '---'}`}
        </h6>
        <ul
          className={`user-details ${
            resource === 'Opportunity' ? 'opportunity-list' : ''
          }`}
        >
          {resource === 'Contact' && (
            <>
              <li>
                {
                  item.ContactDetails?.find(
                    (item: any) => item.type === 'EMAIL'
                  ).value
                }
              </li>
              <li>
                {
                  item.ContactDetails?.find(
                    (item: any) => item.type === 'PHONENUMBER'
                  ).value
                }
              </li>
            </>
          )}
          {resource === 'Opportunity' && (
            <>
              <li>
                {item?.ProductId
                  ? item?.Product?.name
                  : item?.CustomProduct?.name}
              </li>
              <li>{item?.Account.name}</li>
            </>
          )}
          {resource === 'Account' && (
            <>
              <li>{item?.businessEmail ? item?.businessEmail : '-----'}</li>
              <li>
                {item?.businessPhoneNumber ? item?.businessPhoneNumber : '----'}
              </li>
            </>
          )}
          {resource === 'Lead' && (
            <>
              <li>
                {item?.data?.contact?.email
                  ? item?.data?.contact?.email
                  : '-----'}
              </li>
              <li>
                {item?.data?.contact?.phoneNumber
                  ? item?.data?.contact?.phoneNumber
                  : '----'}
              </li>
            </>
          )}
          {resource === 'Policy' && (
            <>
              <li>{item?.Product?.name ? item?.Product?.name : '---'}</li>
              <li>
                {item?.expiryDate ? (
                  <Moment format='MM/DD/YYYY'>{item.expiryDate}</Moment>
                ) : (
                  '---'
                )}
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

import { useNavigate } from 'react-router-dom';
import { IPolicy } from '../../../interfaces/Policy';
import moment from 'moment';
import edit from '../../../assets/logo/edit.svg';
import plus from '../../../assets/logo/blackplus.svg';
import chain from '../../../assets/logo/chain.svg';
import { contacts } from '../../../assets/nav-icons';

const PolicyDetailsDrawer = ({
  policyDetails,
  setShowEditModal,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  policyDetails: IPolicy;
  setShowEditModal: Function;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
}) => {
  const navigate = useNavigate();

  return (
    <div className='info-div'>
      <div className='details-div'>
        <div className='d-flex align-items-center'>
          <h5 className='section-title'>Details</h5>
          <img
            className='editIcon'
            src={edit}
            alt='edit'
            onClick={() => setShowEditModal('open')}
          />
        </div>
        <div className='sub-section'>
          <div className='subsection-title'>Carrier</div>
          <div>
            {policyDetails.Issuer
              ? policyDetails.Issuer.Carrier?.name
              : policyDetails.CustomCarrier
              ? policyDetails.CustomCarrier.name
              : '--'}
          </div>
          {policyDetails?.Issuer ? (
            <>
              <div className='subsection-title'>Issuer</div>
              <div>{policyDetails?.Issuer?.name}</div>
            </>
          ) : null}
          <div className='subsection-title'>Policy term</div>
          <div>{policyDetails?.policyTerm}</div>
          <div className='subsection-title'>Premium</div>
          <div>$ {policyDetails?.premium?.toLocaleString('en-US')}</div>
          <div className='subsection-title'>Lead Source</div>
          <div>
            {policyDetails?.LeadSource
              ? policyDetails.LeadSource?.name
              : 'Direct Policy'}
          </div>
          <div className='subsection-title'>Policy Effective Date</div>
          <div>
            {policyDetails.effectiveDate
              ? moment(policyDetails.effectiveDate).format('MM/DD/YYYY')
              : '--'}
          </div>

          {/* <div className='subsection-title'>Policy Sold to Date</div> */}
          {/* <div>
            {policyDetails.expectedCloseDate
              ? moment(policyDetails.expectedCloseDate).format('MM/DD/YYYY')
              : '--'}
          </div> */}

          <div className='subsection-title'>Policy Expiration Date</div>
        </div>
        <div>
          {policyDetails.expiryDate
            ? moment(policyDetails.expiryDate).format('MM/DD/YYYY')
            : '--'}
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title d-flex align-items-center'>
          Contacts
          {policyDetails.Contacts?.length > 0 && (
            <span className='count-div'>{policyDetails?.Contacts.length}</span>
          )}
          <button
            className='plus-button'
            type='button'
            onClick={() => {
              setShowAddContactModal('open');
            }}
          >
            <img src={plus} alt='plus-icon' />
          </button>
        </h5>
        <div className='sub-section'>
          {policyDetails?.Contacts?.map((contact) => (
            <div className='text-overflow-container'>
              <div
                key={contact.uuid}
                className='nav-decor'
                onClick={() => navigate(`/contacts/${contact.uuid}`)}
              >
                <img src={contacts} alt='' className='contact-img' />
                {contact.firstName} {contact.lastName}
              </div>
              <span className='tooltiptext'>
                <span className='tooltipInnertext'>
                  {contact.firstName} {contact.lastName}
                </span>
              </span>
            </div>
          ))}
          <span
            className='LinkContactDiv'
            onClick={() => setShowLinkContactModal('open')}
          >
            <img className='iconImage' src={chain} alt='' /> Link contact
          </span>
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title'>Team</h5>
        <div className='sub-section'>
          <div>
            <img src={contacts} alt='' className='contact-img' />
            Producer: {policyDetails?.Agents?.User?.firstName}{' '}
            {policyDetails?.Agents?.User?.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsDrawer;

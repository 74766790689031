import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { StyledDeleteModal } from '../../../Admin/elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import {
  updateGoalsDetails,
  fetchGoals,
} from '../../../../store/goals/goalSlice';

const DefaultGoalModal = ({
  showModal,
  setShowModal,
  setShowSuccess,
  month,
  setCheckbox,
}: {
  showModal: boolean;
  setShowModal: Function;
  setShowSuccess: Function;
  month: any;
  setCheckbox: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();

  const { handleSubmit } = useForm({});

  const months: any = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

  const reFetchGoals = () => {
    setShowModal(false);
    if (month.monthSelected) {
      if (uuid) {
        dispatch(
          fetchGoals({
            selectedMonthDate: month.monthSelected,
            agencyUUID: uuid,
            successCB: () => {
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
            },
          })
        );
      } else {
        dispatch(
          fetchGoals({
            selectedMonthDate: month.monthSelected,
            successCB: () => {
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
            },
          })
        );
      }
    }
  };

  const onDefaultGoal = (value: any) => {
    let data: any = {
      setDefaultGoal: 'true',
      selectedMonthDate: month.monthSelected,
    };
    if (uuid) {
      data.agencyUUID = uuid;
    }
    dispatch(
      updateGoalsDetails({
        data,
        successCB: () => {
          setShowModal(false);
          setCheckbox(false);
          reFetchGoals();
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          setTimeout(() => {
            setShowSuccess(false);
            setTimeout(() => {}, 300);
          }, 2000);
        },
      })
    );
  };

  let monthValue = new Date(month.monthSelected).getMonth();
  const date = months[`${monthValue}`];

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => {
          setCheckbox(false);
          setShowModal(false);
        }}
      />
      <h2>Use default monthly goals?</h2>
      <div className='sub-heading'>
        Are you sure you want to set default premium/policy goals for
        {''} {date} ? This is a irreversible action.
      </div>
      <form onSubmit={handleSubmit(onDefaultGoal)}>
        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setCheckbox(false);
              setShowModal(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='WarningButton d-flex align-items-center justify-content-between'>
            Set default goal
            <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledDeleteModal>
  );
};

export default DefaultGoalModal;

import styled from '@emotion/styled';

export const SelectStyle = styled.div`
  .SlelectField {
    position: relative;
    .input-placeholder {
      position: absolute;
      top: 0;
      left: 10px;
    }
    > div {
      border: 1px solid #cad9f3;
      border-radius: 8px;
    }
  }

  .select__input-container {
    left: 10px;
    position: absolute;
    top: -46px;
    height: 30px;
  }

  .select__placeholder {
    top: -47px;
    color: #262758;
    font-weight: 600;
    left: 10px;
  }

  .select__multi-value ~ .select__input-container {
    left: 10px;
    position: absolute;
    top: 15px;
    height: 30px;
  }
  .select__value-container {
    padding-top: 70px;
  }

  .select__control--is-focused .select__placeholder {
    top: -55px;
    font-size: 12px;
    left: 10px;
  }
  .select__value-container--has-value .select__placeholder {
    top: 20px;
    left: 10px;
  }
  .select__control--is-focused
    .select__value-container--has-value
    .select__placeholder {
    top: 5px;
    left: 18px;
  }
  .select__menu .select__menu-list {
    max-height: 200px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }
  }

  .select__multi-value {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: #e6e9f0;
    border-radius: 16px;
    padding: 4px 16px;
    margin: 5px 10px 5px 0;
  }
  .select__indicators {
    position: absolute;
    right: 0;
    top: 12px;
  }
  @media screen and (min-width: 576px) {
    .select__menu .select__menu-list {
      max-height: 100px;
    }
  }
  @media screen and (min-width: 1200px) {
    .select__menu .select__menu-list {
      max-height: 260px;
    }
  }
`;

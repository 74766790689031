import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../store';
import {
  createPipeline,
  PipelinesSliceState,
  getPipelineTemplate,
} from '../../store/pipelines/pipelineSlice';
import { fetchProductCategory } from '../../api/productCategory';
import Modal from '../../components/Modal';
import InputField from '../InputField';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import { IProductCategory } from '../../interfaces/ProductCategory';
import { IPipelineTemplate, ITemplateStage } from '../../interfaces/Pipeline';
import { StyledPipelineModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import edit from '../../assets/logo/edit.svg';
import warning from '../../assets/logo/warning.svg';
import 'rc-slider/assets/index.css';

import { fetchProducts } from '../../api/product';
import { IProduct } from '../../interfaces/Product';
import Skeleton from 'react-loading-skeleton';

const PipelineSchema = Yup.object().shape({
  pipelineName: Yup.string()
    .max(20, 'Pipeline Name cannot exceed 20 characters')
    .required('Please enter the Pipeline Name '),
});

const PipelineModal = ({
  show,
  closeModal,
  agencyUUID,
}: {
  show: string;
  closeModal: Function;
  agencyUUID?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [product, setProduct] = useState<IProduct[]>([]);
  const [template, setTemplate] = useState<IPipelineTemplate>();
  const [stages, setStages] = useState<ITemplateStage[]>([]);
  const [numStage, setNumStage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(PipelineSchema),
  });

  const watchName = watch('pipelineName');

  useEffect(() => {
    dispatch(
      getPipelineTemplate({
        successCB: (data: IPipelineTemplate) => {
          setTemplate(data);
          setStages(data?.defaultStages);
          setNumStage(data?.minStage);
        },
      })
    );
  }, []);

  const getPipelineTemplateLoading = useSelector(
    (state: { pipelines: PipelinesSliceState }) =>
      state.pipelines.pipeLineTemplateStatus
  );

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  useEffect(() => {
    fetchProducts(
      (data: IProduct[]) => {
        setProduct(data);
      },
      'name',
      'asc',
      '',
      agencyUUID,
      true
    );
  }, [agencyUUID]);

  const getMarks = (min: number, max: number) => {
    return Array.from(Array(max - min + 1).keys()).reduce((t: any, o) => {
      t[o + min] = o + min;
      return t;
    }, {});
  };

  const loading = useSelector(
    (state: { pipelines: PipelinesSliceState }) => state.pipelines.isLoading
  );

  const onEditStage = (type: string, value: string, index: number) => {
    const updatedStages = [...stages];
    const updatedStage = { ...updatedStages[index] };

    if (type === 'probability') {
      const percent = parseInt(value);
      if (percent >= 0 && percent <= 100) {
        updatedStage.stageProbability = percent;
      } else if (!percent) {
        updatedStage.stageProbability = 0;
      }
    } else {
      updatedStage.name = value;
    }
    updatedStages[index] = updatedStage;
    setStages(updatedStages);
  };

  const onChangeStage = (val: number | number[]) => {
    if (typeof val === 'number' && template) {
      const updatedStages = [...stages];

      if (val > numStage) {
        let numAdd = val - numStage;
        for (
          let i = numStage - template.minStage;
          i < numAdd + (numStage - template.minStage);
          i++
        ) {
          updatedStages.splice(i + 2, 0, template.additionalStages[i]);
        }
      } else {
        let numRemove = numStage - val;
        for (let i = 0; i < numRemove; i++) {
          updatedStages.splice(stages.length - 4 - i, 1);
        }
      }
      setNumStage(val);
      setStages(updatedStages);
    }
  };

  const onSubmit = (data: any) => {
    data.pipelineStages = stages;
    data.productUUID = data.opportunityTypes
      ? data.opportunityTypes
          .filter((item: any) => item.type === 'PRODUCT')
          .map((item: { label: string; value: string }) => item.value)
      : [];
    data.customProductUUID = data.opportunityTypes
      ? data.opportunityTypes
          .filter((item: any) => item.type === 'CUSTOM_PRODUCT')
          .map((item: { label: string; value: string }) => item.value)
      : [];
    data.agencyUUID = agencyUUID;
    dispatch(
      createPipeline({
        data,
        successCB: () => {
          setDisabled(true);
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
          setTimeout(() => {
            closeModal('closed');
          }, 2000);
        },
      })
    );
  };

  return (
    <>
      {showSuccess ? (
        <SuccessAlign>
          <div className={`success-container ${showSuccess && 'open-drawer'} `}>
            <SuccessCard
              title={'Success adding Pipeline'}
              message={`Pipeline <b>${watchName}</b> has been added.<b>`}
            />
            <button
              onClick={() => {
                setShowSuccess(false);
              }}
              className='crossbutton'
            >
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
      ) : (
        <StyledPipelineModal>
          <Modal open={show} align='right'>
            <div className='addPipeline-drawer drawer-content position-relative h-100'>
              <div className='top-div d-flex justify-content-between align-items-center'>
                <h2>Create a Pipeline</h2>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt=''
                    onClick={() => {
                      onCloseModal();
                    }}
                  />
                </button>
              </div>

              <form className='pipeline-form' onSubmit={handleSubmit(onSubmit)}>
                <div className='d-flex justify-content-between pipeline-field-container'>
                  <div className='fields'>
                    <InputField
                      name='pipelineName'
                      register={register}
                      type='text'
                      placeholder='Pipeline name'
                      required
                      className={`${errors?.pipelineName && 'error-field'} ${
                        watchName && 'filled'
                      }`}
                    />{' '}
                    {errors.pipelineName ? (
                      <div className='error-message'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.pipelineName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields'>
                    <SelectField
                      name='opportunityTypes'
                      control={control}
                      placeholder='Opportunity types'
                      options={product.map((item) => {
                        return {
                          value: item.uuid,
                          label: item.name,
                          type: item.type,
                        };
                      })}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className='stages-slider'>
                  <h6>Select number of stages</h6>

                  <div className='pipeline-slider'>
                    {template && (
                      <Slider
                        min={template?.minStage}
                        max={template?.maxStage}
                        included={false}
                        marks={getMarks(template?.minStage, template?.maxStage)}
                        onChange={onChangeStage}
                      />
                    )}
                  </div>
                </div>

                <div className='stage-list'>
                  <div className='d-flex header-div'>
                    <div className='col'>Stage Name</div>
                    <div className='col'>Stage Probability</div>
                  </div>

                  <div className='stage-options'>
                    {getPipelineTemplateLoading === 'loading'
                      ? [1, 2, 3].map((item) => (
                          <div>
                            {' '}
                            <Skeleton height={84} key={item} />
                          </div>
                        ))
                      : stages.map((item, index) => (
                          <StageCard
                            item={item}
                            index={index}
                            key={index}
                            onEditStage={onEditStage}
                          />
                        ))}
                  </div>
                </div>

                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    disabled={loading || show === 'closing' || disabled}
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </StyledPipelineModal>
      )}
    </>
  );
};

export default PipelineModal;

const StageCard = ({
  item,
  index,
  onEditStage,
}: {
  item: ITemplateStage;
  index: number;
  onEditStage: Function;
}) => {
  const [isEditProbability, setIsEditProbability] = useState(false);
  const [isEditName, setIsEditName] = useState(false);

  return (
    <div key={item.order} className='stage-card d-flex'>
      <div className='col'>
        {isEditName ? (
          <input
            value={item.name}
            type='text'
            onChange={(e) => onEditStage('name', e.target.value, index)}
            onBlur={() => setIsEditName(false)}
            autoFocus={true}
            maxLength={20}
          />
        ) : (
          <div onClick={() => setIsEditName(true && item.editable)}>
            {item.name} {item.editable && <img src={edit} alt='' />}
          </div>
        )}
      </div>
      <div className='col'>
        <div>
          {isEditProbability ? (
            <div className='percent-container d-flex'>
              <input
                value={item.stageProbability}
                type='number'
                onChange={(e) =>
                  onEditStage('probability', e.target.value, index)
                }
                onBlur={() => setIsEditProbability(false)}
                autoFocus={true}
                style={{ width: 30 }}
              />
            </div>
          ) : (
            <div onClick={() => setIsEditProbability(true)}>
              {item.stageProbability}% <img src={edit} alt='' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import styled from '@emotion/styled';

export const ValidationDiv = styled.div`
  width: 100%;
  padding: 8px 16px;
  background: #f5f7fc;
  border-radius: 8px;

  .statusIcon {
    width: 16px;
    margin-right: 8px;
  }

  ul {
    padding: 0;
    margin: 4px 0;
    font-size: 12px;
    font-weight: 600;
    color: #191919;
  }
  @media screen and (min-width: 576px) {
    width: 408px;
  }
  @media screen and (min-width: 1200px) {
    width: 488px;
  }
  @media screen and (min-width: 1900px) {
    width: 602px;
    font-size: 14px;
  }
`;

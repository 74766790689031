import styled from '@emotion/styled';

export const MobileNavDiv = styled.div`
  @keyframes formDrawer {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  height: 60px;
  z-index: 1;
  box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
  background: #ffffff;
  padding: 16px 32px;
  .nav-logo {
    width: 26px;
    height: 26px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
  .activeClass {
    filter: invert(97%) sepia(48%) saturate(390%) hue-rotate(176deg)
      brightness(99%) contrast(90%);
  }
  .activatelogo {
    filter: invert(66%) sepia(50%) saturate(202%) hue-rotate(184deg)
      brightness(87%) contrast(89%);
  }
  .notificationCounts {
    background: #cc0000;
    color: #fff;
    font-size: 10px;
    position: absolute;
    padding: 0px 4px;
    width: 16px;
    height: 16px;
    display: flex;
    right: -5px;
    align-items: center;
    justify-content: center;
    top: -6px;
    right: 0;
    border-radius: 50%;
  }
  .smallCount {
    border-radius: 30px;
    right: -8px;
    padding: 2px;
    width: 20px;
    height: 16px;
  }
  .myteam-logo {
    margin-left: 0px;
  }
  .avatar-img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .manage-logo {
    border: none;
    background: #fff;
  }

  .avatar-div {
    background: #262758;
    color: #f5f7fc;
    width: 28px;
    position: relative;
    height: 28px;
    border-radius: 50%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
  .profile-drop {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 50px);
    bottom: 50px;
    right: 0;
    background: #fafafa;
    border: 1px solid #f5f7fc;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    white-space: nowrap;
    z-index: 5;
    .topContainer {
      background: #fff;
      padding: 24px 32px;
      .logo-svg {
        height: 30px;
      }
      .logoAvatar {
        padding-bottom: 24px;
        margin-top: 40px;
      }
      .avatar-div {
        height: 60px;
        width: 60px;
      }
      .avatar-img {
        height: 60px;
        width: 60px;
      }
      .profileNames {
        margin-left: 16px;
        width: calc(100% - 80px);
      }
      h6 {
        margin: 0;
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h2 {
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .bottomContainer {
      padding: 8px 32px;
      .navigationItems {
        width: 100%;
        margin-top: 12px;
        background: #fff;
        padding: 12px;
        height: 48px;
      }
      .profileButtonDiv {
        border: none;
        background: #fff;
        margin-bottom: 24px;
        .ButtonImage {
          margin-right: 12px;
        }
      }
      .logOutButtonDiv {
        border: none;
        background: #fff;
        margin-bottom: 24px;
        .ButtonLogOutImage {
          width: 30px;
          margin-right: 12px;
        }
      }
    }
  }

  button.nav-logo {
    border: none;
    background: none;
  }
  .global-search {
    position: relative;
    max-width: 560px;
    margin: 0 auto;
    input {
      background: #e6e9f0;
      border: 1px solid #262758;
      outline: none;
      border-radius: 8px;
      width: 100%;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;
      :focus,
      &.filled {
        background: #fff;
        margin-bottom: 24px;
        .ButtonLogOutImage {
          width: 30px;
          margin-right: 12px;
        }
      }
    }

    button.nav-logo {
      border: none;
      background: none;
    }
    .global-search {
      position: relative;
      max-width: 560px;
      margin: 0 auto;
      input {
        background: #e6e9f0;
        border: 1px solid #262758;
        outline: none;
        border-radius: 8px;
        width: 100%;
        font-size: 14px;
        height: 100%;
        padding: 10px 48px;
        :focus,
        &.filled {
          background: #fff;
          width: 100%;
          outline: none;
          font-size: 14px;
          border: 1px solid #262758;
          border-radius: 8px;
          padding: 10px 48px;
        }
      }

      .search-button {
        margin: 0 -36px 0 16px;
        position: relative;
        z-index: 1;
      }

      .close-button {
        margin: 0 0px 0 -35px;
        cursor: pointer;
        display: none;
      }
      .key-button {
        margin: 0 0px 0 -35px;
        cursor: pointer;
      }
      input:focus ~ .close-button,
      .filled ~ .close-button {
        display: block;
      }
      input:focus ~ .key-button,
      .filled ~ .key-button {
        display: none;
      }
    }

    .mobileQuickAdd {
      transform: rotate(45deg);
      margin: 0 auto 2px auto;
    }
    .drawerHandle {
      width: 88px;
      border: 4px solid #e6e9f0;
      margin: 0 auto;
    }
    .backgroundQuickAdd {
      left: 0;
      right: 0;
      bottom: 60px;
      top: 0;
      position: fixed;
      background: rgba(35, 42, 64, 0.6);
      z-index: 1;
    }
    .search-mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
    }
  }
  .quickAddButton {
    position: relative;
    .quickFormsDropDown {
      position: fixed;
      bottom: 60px;
      transform: translate(0);
      transition: all 0.5s ease-in-out;
      left: 0;
      animation-name: formDrawer;
      animation-duration: 0.4s;
      width: 100vw;
      height: 380px;
      padding: 32px;
      background: #fff;
      border-radius: 16px 16px 0px 0px;
      backdrop-filter: blur(10px);
      .quickFormBtn {
        h6 {
          margin: 10px 12px;
        }
        color: #262758;
        border-bottom: 1px solid #e6e9f0;
        height: 40px;
        text-align: initial;
      }
      h2 {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 20px;
        color: #262758;
      }
      .lastQuickFormBtn {
        border-bottom: none;
      }
      .closed {
        animation-duration: 0.3s;
        transform: translateY(0%);
      }
    }
  }
  .imageIcon {
    margin-right: 12px;
  }
  .imageIc {
    margin-right: 9px;
  }
  .mobileQuickAdd {
    transform: rotate(45deg);
    margin: 0 auto 2px auto;
  }
  .drawerHandle {
    width: 88px;
    border: 4px solid #e6e9f0;
    margin: 0 auto;
  }
  .backgroundQuickAdd {
    left: 0;
    right: 0;
    bottom: 60px;
    top: 0;
    position: fixed;
    background: rgba(35, 42, 64, 0.6);
    z-index: 1;
  }
  .search-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
  }
  .Toastify__toast {
    color: #262758;
    font-size: 16px;
    border-radius: 8px !important;
    line-height: 20px;
    padding: 15px 8px;
    position: fixed;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 8px;
    top: 8px;
    width: 80%;
    font-weight: 600;
    background: #f5f7fc !important;
    margin-left: 25px !important;
  }
  .Toastify__progress-bar {
    background: #262758;
  }
  .Toastify__toast > button > svg {
    margin-top: 8px;
  }

  @media screen and (min-width: 576px) {
    .navbarcontent {
      width: 440px;
      margin: 0 auto;
    }
    .quickAddButton {
      .quickFormsDropDown {
        margin-left: 30%;
        width: 360px;
        height: 380px;
      }
    }
    .profile-drop {
      position: absolute;
      bottom: 50px;
      z-index: 5;

      h6 {
        margin: 10px 12px;
        cursor: pointer;
      }
    }
    .Toastify__toast > button > svg {
      margin-top: 8px;
    }
    .Toastify__toast {
      padding: 15px 20px;
      top: 40px;
      width: 35%;
    }
  }
`;

import { EmptyDiv } from '../elements/styles';
import empty from '../../../assets/logo/empty.svg';

const EmptyResults = ({ name, style }: { name: string; style?: any }) => {
  return (
    <EmptyDiv style={style}>
      <img src={empty} alt='' />
      <h2>No records found</h2>
      <div>Please add a new {name}</div>
    </EmptyDiv>
  );
};

export default EmptyResults;

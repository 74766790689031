import styled from '@emotion/styled';
export const StyledQuickOpportunityModal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  .topdiv {
    width: 90vw;
    margin-bottom: 15px;
    border-bottom: 1px solid #e6e9f0;
    z-index: 2;
    height: 67px;
  }
  .entire-div {
    width: 95vw;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 14px;
    top: 20px;
    border: none;
  }
  .scroll-bar {
    height: 88vh;
    width: 92vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 145px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 20px;
  }
  .personal {
    width: 56px;
  }
  .commercial {
    width: 80px;
    margin-left: 20px;
  }
  .fields {
    width: 90vw;
    margin-top: 16px;
    color: #262758;
    .search-group {
      .input-placeholder {
        left: 18px;
      }
      .input-field {
        padding: 18px 25px 10px 18px;
      }
    }
  }
  .fields::placeholder {
    color: #262758;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0px;
    right: 0px;
    background: #fff;
    z-index: 1;
    padding: 10px 20px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 20px;
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .naVigatorbuttons {
    color: #262758;
    background: none;
    border: none;
    cursor: pointer;
  }
  .naVigatorbuttons:disabled {
    border-bottom: none;
    font-weight: 400;
    color: #262758;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    border-bottom: 3px solid #262758;
    cursor: pointer;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
    font-weight: 600;
    :disabled {
      border-bottom: none;
      font-weight: 400;
    }
  }
  .bigfields {
    width: 90vw;
    height: 134px;
    padding: 16px 16px;
    max-height: 198px;
    margin: 0 auto;
    border: 1px solid #e6e9f0;
    border-radius: 8px;
    margin-top: 18px;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
    &:focus,
    &:active {
      border: 1px solid #3c498a;
      outline-offset: 0px;
      outline: none;
    }
  }
  .errorVal {
    margin-top: 8px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
    &.emailError {
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .form-title {
    margin-bottom: 15px;
  }
  .commercial-text {
    margin-left: 20px;
  }
  .textarea {
    width: 89vw;
    margin-top: 16px;
  }
  .addNotesButton {
    margin-top: 40px;
    border: none;
    background: #fff;
  }
  @media screen and (min-width: 576px) {
    width: 100%;
    .fields {
      width: 420px;
    }
    .topdiv {
      height: 72px;
      width: 100%;
    }
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 150px;
      }
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 40px;
      top: 26px;
    }
    .entire-div {
      width: 100%;
      padding: 0px;
    }
    .scroll-bar {
      height: 68vh;
      padding-bottom: 60px;
      padding-right: 7px;
      width: 100%;
    }
    .bigfields {
      margin-top: 25px;
      width: 99.9%;
    }
    .buttonsDiv {
      bottom: 0;
      width: 82%;
      padding: 21px 54px;
    }
    .textarea {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .fields {
      width: 420px;
      margin-top: 16px;
    }
    .topdiv {
      height: 73px;
      margin-bottom: 11px;
    }
    .entire-div {
      width: 883px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 41px;
      top: 24px;
    }
    .scroll-bar {
      height: 79vh;
      width: 100%;
    }
    .saveButton {
      width: 220px;
    }
    .bigfields {
      width: 99.9%;
      margin-top: 35px;
    }
    .buttonsDiv {
      bottom: 0px;
      width: 85%;
      padding: 21px 54px;
    }
    .form-title {
      margin-bottom: 18px;
    }
    .head {
      font-size: 24px;
    }
    .cross {
      width: 30px;
    }
  }
  @media screen and (min-width: 1900px) {
    .topdiv {
      width: 100%;
      height: 87px;
      margin-bottom: 24px;
    }
    .cross {
      width: 32px;
    }
    .personal {
      width: 56px;
    }
    .commercial {
      width: 80px;
      margin-left: 20px;
    }
    .fields {
      width: 576px;
      margin-top: 20px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 60px;
      top: 36px;
    }
    .buttonsDiv {
      width: 86%;
      bottom: 0;

      z-index: 2;
      padding: 31px 80px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
      right: 20px;
    }
    .entire-div {
      width: 1200px;
      height: 1080px;
    }
    .bigfields {
      margin-top: 48px;
      width: 99.9%;
    }

    .form-title {
      margin-bottom: 28px;
    }
    .head {
      font-size: 28px;
    }
  }
`;

import { CardDiv } from '../../../QuotesInnerTab/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { deleteADocument } from '../../../../store/documents/documentsSlice';
import AddDocumentsModal from './AddDocumentsModal';
import Moment from 'react-moment';
import { useState } from 'react';
const DetailsCardMobile = ({
  name,
  uuid,
  documents,
}: {
  name: string;
  uuid: string;
  documents: any;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [show, setShow] = useState('closed');
  const dispatch = useDispatch<AppDispatch>();
  const DeleteDoc = (uuid: string) => {
    dispatch(deleteADocument({ uuid, successCB: () => {} }));
  };
  return (
    <CardDiv>
      {' '}
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center'>
            <input
              type='checkbox'
              name='remember'
              className='form-check-input'
            />
            <span className='ms-3'>{documents?.name}</span>
          </div>
        </div>

        <div className='oppDetaisl documentField'>
          <div className='oppDetaislfields   '>
            <div className='d-flex justify-content-between '>
              <div className='documentDate'>
                <div className='valHead DocumentHead '>
                  {' '}
                  {documents?.contentType}
                </div>
                <div className='valueField addedBY d-flex'>
                  <span className='f'>Added by : </span>
                  <span>
                    {documents?.UploadedBy?.firstName +
                      documents?.UploadedBy?.lastName}
                  </span>
                </div>
              </div>
              <div className='documentDate'>
                <div className='valHead DocumentHead '>Added on</div>
                <div className='valueField DocumentHead'>
                  <Moment format='MM/DD/YYYY'>{documents?.updatedAt}</Moment>
                </div>
              </div>
            </div>

            <div className=' d-flex align-items-center justify-content-end'>
              {/* <span className='valueField '>Shared to</span>
              <div className='profile-pic d-flex mobileProfileAccount align-items-center justify-content-center'>
                AF
              </div> */}
              <div className='d-flex px-2 my-2 '>
                <button className='tertiaryButton viewbtn '>
                  View details <img className='addIcon' src={arrow} alt='' />
                </button>
                <div
                  className=' actionMenu d-flex justify-content-center'
                  onClick={() => setShowActionsDropdown(!showActionsDropdown)}
                >
                  <img src={vector} alt='vector' className='vectorIMage' />
                  {showActionsDropdown && (
                    <div className='dropdown-div'>
                      <div
                        className='edit-div'
                        onClick={() => {
                          setShowEditModal('open');
                          setShowActionsDropdown(false);
                        }}
                      >
                        Edit <img src={edit} alt='bin' />
                      </div>
                      <div
                        className='delete-div'
                        onClick={() => {
                          DeleteDoc(uuid);
                        }}
                      >
                        Delete <img src={bin} alt='bin' />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showEditModal !== 'closed' && (
          <AddDocumentsModal
            show={showEditModal}
            uuid={uuid}
            closeModal={setShowEditModal}
            isEdit={true}
            editUUID={uuid}
            name={name}
          />
        )}
        {show !== 'closed' && (
          <AddDocumentsModal
            show={show}
            closeModal={setShow}
            uuid={uuid}
            name={name}
          />
        )}
      </div>
    </CardDiv>
  );
};

export default DetailsCardMobile;

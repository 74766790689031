import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import {
  getCount,
  getOpportunityDetails,
  onUpdateOpportunityDetails,
  OpportunitiesSliceState,
  selectOpportunitiesCount,
} from '../../../store/opportunities/opportunitiesSlice';
import {
  createEvent,
  createNote,
  createTask,
} from '../../../store/tasks/tasksSlice';
import { fetchAccounts } from '../../../api/account';
import { fetchAgents } from '../../../api/agents';
import { fetchProducts } from '../../../api/product';
import { getLeadSourceTypes } from '../../../api/lead';
import { getCount as opportunityCount } from '../../../store/opportunities/opportunitiesSlice';
import LocalStorageService from '../../../utils/LocalStorageService';
import Documents from '../../../components/DocumentsInnerTab';
import Modal from '../../../components/Modal';
import SelectField from '../../../components/SelectField';
import DateField from '../../../components/DateField';
import InputField from '../../../components/InputField';
import SearchDropdown from '../../../components/SearchDropdown';
import MarkdownEditor from '../../../components/MarkdownEditor';
import TasksTab from '../../../components/ActivitiesInnerTab';
import SuccessCard from '../../../components/SuccessCard';
import Quotes from '../../../components/QuotesInnerTab';
import { IProducer } from '../../../interfaces/Agent';
import { IAccount } from '../../../interfaces/Account';
import { IProduct } from '../../../interfaces/Product';
import { IOpportunity, IPipelineStage } from '../../../interfaces/Opportunity';
import { ILeadSourceType } from '../../../interfaces/Lead';
import { StyledStageModal } from '../elements/styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import drop from '../../../assets/logo/dropdown.svg';
import cross from '../../../assets/logo/cross.svg';
import expand from '../../../assets/logo/expand-icon.svg';
import warning from '../../../assets/logo/warning.svg';
import tick from '../../../assets/logo/tick.svg';
import ham from '../../../assets/logo/hamburger.png';
import home from '../../../assets/logo/home.svg';
import right from '../../../assets/logo/right-arrow.svg';

const OpportunitySchema = Yup.object().shape({
  opportunityName: Yup.string().required('Please enter the name.'),
});

const StageMovementModal = ({
  open,
  closeModal,
  uuid,
  stages,
}: {
  open: string;
  closeModal: Function;
  uuid: string;
  stages: IPipelineStage[];
}) => {
  const user = LocalStorageService.getUser();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const oppLoadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.status
  );
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const opportunityDetailsCount = useSelector(selectOpportunitiesCount);

  const [activeTab, setActive] = useState<
    'Activities' | 'Details' | 'Quotes' | 'Documents'
  >('Activities');
  const [errorMessage, setError] = useState('');
  const [opportunity, setOpportunity] = useState<IOpportunity>();
  const [account, setAccount] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [policy, setPolicy] = useState('');
  const [policySearch, setPolicySearch] = useState('');
  const [producer, setProducer] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [showSuccess, setshowSuccess] = useState(false);
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [productCategory, setProductCategory] = useState<IProduct[]>([]);
  const [producerName, setProducerName] = useState('');
  const [isCommercial, setCommercial] = useState(false);
  const [productType, setProductType] = useState('');
  const [productUUID, setProductUUID] = useState('');
  const [accounts, setAccounts] = useState<any[]>([]);
  const [note, setNote] = useState('');
  const [submit, setSubmit] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [file, setFile] = useState<any>();
  const [docTypeUUID, setDocTypeUUID] = useState('');
  const [added, setAdded] = useState('');
  const [edited, setEdited] = useState('');
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OpportunitySchema),
  });

  const watchAllFields = watch();

  useEffect(() => {
    dispatch(getCount({ uuid }));
    if (oppLoadingStatus === 'idle' || oppLoadingStatus === 'succeeded') {
      dispatch(
        getOpportunityDetails({
          uuid,
          successCB: (Opportunity: { data: IOpportunity }) => {
            if (Opportunity.data.Account.AccountType.name === 'COMMERCIAL') {
              setCommercial(true);
            }
            setOpportunity(Opportunity.data);
            setAccountSearch(Opportunity.data?.Account.name);
            setAccount(Opportunity.data?.Account.uuid);
            if (Opportunity.data.CustomProduct) {
              setProductType('CUSTOM_PRODUCT');
              setPolicy(Opportunity?.data.CustomProduct?.uuid);
              setPolicySearch(Opportunity.data.CustomProduct?.name);
              setProductUUID(Opportunity?.data.CustomProduct?.uuid);
            } else if (Opportunity.data.Product) {
              setProductType('PRODUCT');
              setPolicySearch(Opportunity.data.Product?.name);
              setPolicy(Opportunity?.data.Product?.uuid);
              setProductUUID(Opportunity?.data.Product?.uuid);
            }
            setProducerName(
              `${Opportunity.data.Agent.User.firstName} ${
                Opportunity.data.Agent.User.lastName
                  ? Opportunity.data.Agent.User.lastName
                  : ''
              }`
            );
            setProducer(Opportunity.data?.Agent.uuid);
            setValue('opportunityName', Opportunity?.data?.name);
            setValue('sourceType', Opportunity?.data?.sourceType);
            setValue('sourceName', {
              label: Opportunity.data?.LeadSource?.name,
              value: Opportunity.data?.LeadSource?.uuid,
            });
            setValue('pipelineStage', {
              label: Opportunity.data.PipelineStage.name,
              value: Opportunity.data.PipelineStage.uuid,
            });
            if (Opportunity.data.effectiveDate) {
              setValue(
                'effectiveDate',
                moment(Opportunity.data.effectiveDate).toDate()
              );
            }
            if (Opportunity.data.expectedCloseDate) {
              setValue(
                'expectedCloseDate',
                moment(Opportunity.data.expectedCloseDate).toDate()
              );
            }

            if (Opportunity.data.Account.AccountType.name === 'COMMERCIAL') {
              setValue('business', Opportunity.data.Account.name);

              setValue('businessEmail', Opportunity.data.Account.businessEmail);
              setValue(
                'businessPhoneNumber',
                Opportunity.data.Account.businessPhoneNumber
              );
              setValue('annualRevenue', Opportunity.data.Account.annualRevenue);
              setValue('payroll', Opportunity.data.Account.payroll);
              setValue('employees', Opportunity.data.Account.employeeCount);
              setValue(
                'businessStartedYear',
                Opportunity.data.Account.businessStartedYear
              );
              setValue('businessapt', Opportunity.data.Account.Address?.street);
              setValue(
                'businessaddress',
                Opportunity.data.Account.Address?.city
              );
            }
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (policySearch) {
        fetchProducts(
          (data: IProduct[]) => {
            setProductCategory(data);
            setLoadingStatus('succeeded');
          },
          'name',
          'asc',
          value,
          '',
          true,
          isCommercial ? 'COMMERCIAL' : 'PERSONAL'
        );
      } else {
        setProductCategory([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  useEffect(() => {
    if (user?.UserRole) {
      if (user?.UserRole?.name === 'PRINCIPAL_AGENT') {
        if (producerName) {
          setProducerSearch(producerName);
        }
      } else {
        setProducerSearch('Me');
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producerName]);

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (accountSearch) {
        fetchAccounts(
          'name',
          'asc',
          value,
          25,
          0,
          opportunity?.Account.AccountType.name,
          (data: IAccount[]) => {
            setAccounts(data);
            setLoadingStatus('succeeded');
          },
          'True'
        );
      } else {
        setAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
  }, []);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const onUpdateNote = () => {};

  const onSubmit = (data: any) => {
    setSubmit(true);

    if (policy && account && !(producerSearch && !producer)) {
      setSubmit(false);
      data.notes = note;
      data.accountType = opportunity?.Account.AccountType.name;
      data.pipelineStage = opportunity?.PipelineStage.uuid;
      data.pipeline = opportunity?.PipelineStage.Pipeline.uuid;
      data.action = 'updateOpportunity';
      data.agentUUID = producer;
      data.accountUUID = account;
      if (productType === 'PRODUCT') {
        data.productUUID = productUUID;
      } else if (productType === 'CUSTOM_PRODUCT') {
        data.customProductUUID = productUUID;
      }
      data.sourceUUID = data.sourceName?.value;
      data.sourceName = data.sourceName?.label;
      dispatch(
        onUpdateOpportunityDetails({
          data,
          UUID: uuid,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
              // TODO : Edit opportunity in pipeline without refreshing the page
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }, 2000);
          },
          errorCB: (err: string) => {
            if (typeof err === 'string') {
              setError(err);
            }
          },
        })
      );
    }
  };

  const handleAddNote = (value: any) => {
    setAddNoteLoading(true);
    value.opportunityUUID = uuid;
    dispatch(
      createNote({
        data: value,
        successCB: () => {
          dispatch(opportunityCount({ uuid: value.opportunityUUID }));
          setAddNoteLoading(false);
          setAdded('Note');
          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: (err: any) => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const handleAddTask = (value: any) => {
    setAddNoteLoading(true);
    let formData = new FormData();
    formData.append('opportunityUUID', uuid);
    formData.append('docFile', file);
    formData.append('resource', 'Opportunity');
    formData.append('resourceUUID', uuid);
    formData.append('documentTypeUUID', docTypeUUID);
    for (let key in value) {
      formData.append(key, value[key]);
    }

    setAddNoteLoading(true);
    dispatch(
      createTask({
        data: formData,
        successCB: () => {
          dispatch(opportunityCount({ uuid }));
          setAdded('Task');
          setAddNoteLoading(false);

          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: () => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const handleAddEvent = (value: any) => {
    setAddNoteLoading(true);
    let formData = new FormData();
    formData.append('opportunityUUID', uuid);
    formData.append('docFile', file);
    formData.append('resource', 'Opportunity');
    formData.append('resourceUUID', uuid);
    formData.append('documentTypeUUID', docTypeUUID);
    for (let key in value) {
      if (key === 'guests') {
        formData.append(key, JSON.stringify(value[key]));
      } else {
        formData.append(key, value[key]);
      }
    }

    setAddNoteLoading(true);
    dispatch(
      createEvent({
        data: formData,
        successCB: () => {
          dispatch(opportunityCount({ uuid }));
          setAddNoteLoading(false);
          setAdded('Event');
          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: () => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const dropRef = useRef<any>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropRef.current &&
        dropRef.current?.parentNode?.parentNode === event.target
      ) {
        onCloseModal();
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropRef]);

  const resetPolicy = (value: string) => {
    setPolicy('');
    setProductCategory([]);
  };

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  const resetAccount = (value: string) => {
    setAccounts([]);
    setAccount('');
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success editing opportunity!</b>`}
            message={`<b>${watchAllFields.opportunityName}</b> has been edited`}
          />
          <button
            onClick={() => {
              reset();
              onCloseModal();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={open} align='right'>
        <StyledStageModal className='position-relative h-100' ref={dropRef}>
          <div className='d-flex justify-content-between align-items-center header-div'>
            <div className='d-flex align-items-center'>
              <img
                alt=''
                src={ham}
                className='d-xl-none'
                style={{ marginRight: 12 }}
              />
              <div className='opp-pic d-none d-sm-flex d-xl-none align-items-center justify-content-center'>
                <img src={home} alt='' />
                {/* { TODO : Show assets based on type} */}
              </div>
              <h2 style={{ marginBottom: 0 }}>
                {opportunity?.name ? opportunity?.name : 'Unknown'}
              </h2>
              <div className='status'>{opportunity?.PipelineStage?.name}</div>
            </div>
            <div className='d-flex align-items-center'>
              <button className='d-none d-sm-inline-flex d-xl-none moveButton me-3'>
                Move to
                <img alt='' src={drop} />
              </button>
              <button className='d-none d-sm-inline-flex d-xl-none primaryButton quotingButton me-3'>
                Add quote
                <img alt='' src={right} />
              </button>
              <button className='action-button d-none d-sm-inline-block'>
                <img alt='' src={drop} />
              </button>
              <button
                className='action-button d-none d-xl-inline-block'
                onClick={() => navigate(`/opportunities/${uuid}`)}
              >
                <img alt='' src={expand} />
              </button>
              <img
                src={cross}
                alt=''
                onClick={() => onCloseModal()}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
          <div className='tab-div d-sm-none'>
            <button
              className={'active tab'}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {activeTab}
              {activeTab !== 'Details' && (
                <span className='count'>
                  {opportunityDetailsCount[activeTab.toLowerCase()]}
                </span>
              )}
              <img alt='' src={drop} />

              {showDropdown && (
                <div className='tab-dropdown d-flex flex-column align-items-start'>
                  <button
                    className={activeTab === 'Details' ? 'active tab' : 'tab'}
                    onClick={() => setActive('Details')}
                  >
                    Details
                  </button>

                  <button
                    className={
                      activeTab === 'Activities' ? 'active tab' : 'tab'
                    }
                    onClick={() => setActive('Activities')}
                  >
                    Activities
                    <span className='count'>
                      {opportunityDetailsCount.activities}
                    </span>
                  </button>

                  <button
                    className={activeTab === 'Quotes' ? 'active tab' : 'tab'}
                    onClick={() => setActive('Quotes')}
                  >
                    Quotes
                    <span className='count'>
                      {opportunityDetailsCount.quotes}
                    </span>
                  </button>

                  <button
                    className={activeTab === 'Documents' ? 'active tab' : 'tab'}
                    onClick={() => setActive('Documents')}
                  >
                    Documents
                    <span className='count'>
                      {opportunityDetailsCount.documents}
                    </span>
                  </button>
                </div>
              )}
            </button>
          </div>
          <div className='tab-div d-none d-sm-block'>
            <button
              className={activeTab === 'Details' ? 'active tab' : 'tab'}
              onClick={() => setActive('Details')}
            >
              Details
            </button>

            <button
              className={activeTab === 'Activities' ? 'active tab' : 'tab'}
              onClick={() => setActive('Activities')}
            >
              Activities
              <span className='count'>
                {opportunityDetailsCount.activities}
              </span>
            </button>

            <button
              className={activeTab === 'Quotes' ? 'active tab' : 'tab'}
              onClick={() => setActive('Quotes')}
            >
              Quotes
              <span className='count'>{opportunityDetailsCount.quotes}</span>
            </button>

            <button
              className={activeTab === 'Documents' ? 'active tab' : 'tab'}
              onClick={() => setActive('Documents')}
            >
              Documents
              <span className='count'>{opportunityDetailsCount.documents}</span>
            </button>
          </div>
          {activeTab === 'Details' && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div className=' d-sm-flex justify-content-between'>
                  <div className='opp-fields'>
                    <SelectField
                      options={sourceTypes.map((item) => {
                        return {
                          label: item.name,
                          options: item.LeadSources.map((source) => {
                            return {
                              label: source.name,
                              value: source.uuid,
                            };
                          }),
                        };
                      })}
                      name='sourceName'
                      placeholder='Opportunity source'
                      control={control}
                      isEdit={true}
                    />
                  </div>
                  <div className='opp-fields'>
                    <SelectField
                      options={stages.map((item) => {
                        return { label: item.name, value: item.uuid };
                      })}
                      name='pipelineStage'
                      placeholder='Pipeline stage'
                      control={control}
                      disabled={true}
                      className={`${errors.pipelineStage && 'error-field'} ${
                        watchAllFields.pipelineStage && 'filled'
                      }`}
                    />

                    {errors.pipelineStage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.pipelineStage?.message
                          ? 'Please select a pipeline stage'
                          : errors.pipelineStage?.value?.message}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='  d-sm-flex justify-content-between'>
                  <div className='opp-fields'>
                    <SearchDropdown
                      name='account'
                      placeholder='Account associated to'
                      isEdit={true}
                      values={accountSearch}
                      searchValue={accounts}
                      setSearch={setAccountSearch}
                      status={loadingStatus}
                      className={`${!account && submit && 'error-field'} ${
                        accountSearch && 'filled'
                      }`}
                      register={register}
                      control={control}
                      setUUID={resetAccount}
                      loadFunction={loadAccount}
                      content={accounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setAccountSearch(item?.name);
                            setAccount(item?.uuid);
                          }}
                          key={item.uuid}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.name?.charAt(0)}
                          </div>
                          <div>
                            <h6>{item.name}</h6>
                            <ul className='user-details'>
                              <li>
                                {opportunity?.Account.AccountType.name !==
                                'PERSONAL'
                                  ? item?.businessEmail
                                  : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                      (value: any) => value.type === 'EMAIL'
                                    )?.value}
                              </li>
                              <li>
                                {opportunity?.Account.AccountType.name !==
                                'PERSONAL'
                                  ? item?.businessPhoneNumber
                                  : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                      (value: any) =>
                                        value.type === 'PHONENUMBER'
                                    )?.value}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {!account && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Account is required
                      </div>
                    ) : null}
                  </div>
                  <div className='opp-fields'>
                    <SearchDropdown
                      name='producer'
                      placeholder='Select producer'
                      isEdit={true}
                      addName={true}
                      values={producerSearch}
                      searchValue={producers}
                      setSearch={setProducerSearch}
                      status={loadingStatus}
                      className={`${producerSearch && 'filled'}`}
                      register={register}
                      control={control}
                      setUUID={resetProducer}
                      loadFunction={loadProducer}
                      disabled={user?.UserRole?.name === 'AGENT'}
                      content={producers?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          key={item.User.uuid}
                          onClick={() => {
                            setProducerSearch(
                              `${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`
                            );
                            setProducer(item?.uuid);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.User?.firstName?.charAt(0)}
                            {item?.User?.lastName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {`${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`}
                            </h6>
                            <ul className='user-details'>
                              <li>{item.User?.email}</li>
                              <li>{item?.User?.phoneNumber}</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {producerSearch && !producer && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Producer not found
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='  d-sm-flex justify-content-between'>
                  <div className='opp-fields'>
                    <InputField
                      name='opportunityName'
                      type='text'
                      placeholder='Opportunity name'
                      required={true}
                      register={register}
                      className={`${errors.opportunityName && 'error-field'} ${
                        watchAllFields.opportunityName && 'filled'
                      }`}
                    />
                    {errors.opportunityName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.opportunityName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='opp-fields'>
                    <SearchDropdown
                      name='productCategory'
                      placeholder='Select Policy'
                      isEdit={true}
                      values={policySearch}
                      searchValue={productCategory}
                      setSearch={setPolicySearch}
                      status={loadingStatus}
                      className={`${!policy && submit && 'error-field'} ${
                        policySearch && 'filled'
                      }`}
                      register={register}
                      control={control}
                      loadFunction={loadPolicy}
                      setUUID={resetPolicy}
                      setProductUUID={setProductUUID}
                      content={productCategory?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setPolicySearch(item?.name);
                            setPolicy(item?.uuid);
                            setProductUUID(item?.uuid);
                            setProductType(item?.type);
                          }}
                        >
                          <div>
                            <h6>{item.name}</h6>
                          </div>
                        </div>
                      ))}
                    />
                    {!policy && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Policy type required
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='  d-sm-flex justify-content-between'>
                  <div className='opp-fields'>
                    <InputField
                      name='sourceType'
                      type='number'
                      placeholder='Opportunity value'
                      register={register}
                      prefix='$'
                      className={`${errors.sourceType && 'error-field'} ${
                        watchAllFields.sourceType && 'filled'
                      }`}
                    />
                    {errors.sourceType || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.sourceType?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                  <div className='opp-fields'>
                    <DateField
                      name='effectiveDate'
                      placeholder='Effective date'
                      control={control}
                      value={watchAllFields.effectiveDate}
                    />
                  </div>
                </div>
                <div className='opp-fields'>
                  <DateField
                    name='expectedCloseDate'
                    placeholder='Estimated close date'
                    control={control}
                    value={watchAllFields.expectedCloseDate}
                  />
                </div>
                <div>
                  <div className='textarea'>
                    <MarkdownEditor
                      placeholder='Add notes here'
                      note={note}
                      setNote={setNote}
                    />
                  </div>
                </div>

                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button secondaryButton'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='saveButton primaryButton'>
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </div>
            </form>
          )}
          {activeTab === 'Activities' && (
            <div>
              <TasksTab
                addNote={handleAddNote}
                setFile={setFile}
                file={file}
                docTypeUUID={docTypeUUID}
                setDocTypeUUID={setDocTypeUUID}
                addNoteLoading={addNoteLoading}
                ApiSuccess={false}
                addTask={handleAddTask}
                addEvent={handleAddEvent}
                updateNote={onUpdateNote}
                name={'Opportunity'}
                uuid={uuid}
                added={added}
                edited={edited}
                setEdited={setEdited}
                //   isMobile={isMobile}
              />
            </div>
          )}
          {activeTab === 'Documents' && (
            <div>
              <Documents name={'Opportunity'} uuid={uuid} />
            </div>
          )}
          {activeTab === 'Quotes' && (
            <div>
              <Quotes pageName='Opportunity' stageOpportunityUUID={uuid} />
            </div>
          )}
        </StyledStageModal>
      </Modal>
    </>
  );
};

export default StageMovementModal;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { CardDiv } from '../styles';
import arrow from '../../../assets/logo/right-arrow.svg';
import home from '../../../assets/logo/home.svg';

const QuoteCardDiv = ({
  details,
  name,
}: {
  details: IOpportunity;
  name: string;
}) => {
  const navigate = useNavigate();

  return (
    <CardDiv>
      {' '}
      <div className='opportunity'>
        <div className='lowContent'>
          <div className='oppDetaisl d-flex justify-content-between'>
            <div className='oppDetaislfieldsQuotes  d-flex justify-content-between '>
              <div className='d-flex'>
                <div className='imageCard d-none d-sm-block'>
                  <img src={home} alt='' />
                </div>
                <div className='quotesName'>
                  <span className='text-overflow-container'>
                    <span className='valHead oppHead '>{details.name}</span>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>{details.name}</span>
                    </span>
                  </span>
                  <span className='valueText quoteCount'>
                    {details?.QuoteRequests?.length || 0} quotes
                  </span>
                </div>
              </div>
              {name === 'Contact' ? (
                <div className='d-block'>
                  <span className='valHead'>Account name</span>
                  <span className='text-overflow-container'>
                    <span className='valueField accountText d-none d-sm-block'>
                      {details.Account.name}
                    </span>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {details.Account?.name}
                      </span>
                    </span>
                  </span>
                </div>
              ) : null}

              <div className='account-premium'>
                <span className='valHead'>Best premium</span>
                <span className='valueField accountText'>
                  $
                  {details?.leastPremium
                    ? details?.leastPremium.toLocaleString('en-US')
                    : 0}
                </span>
              </div>
            </div>
            <div className=' d-flex align-items-center justify-content-center '>
              <button
                className='tertiaryButton quotingbtn '
                onClick={() =>
                  navigate(`/opportunities/${details.uuid}/quotes`)
                }
              >
                View quotes
                <img className='arrowIcon' src={arrow} alt='' />
              </button>

              {/* <div className='actionMenu d-flex align-items-center justify-content-center'>
                <img src={vector} alt='vector' />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </CardDiv>
  );
};

export default QuoteCardDiv;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPassword } from '../../api';
import SuccessCard from '../../components/SuccessCard';
import InputField from '../../components/InputField';
import { LoginDiv } from '../../styles/LoginFlow';
import logo from '../../assets/logo/logo.svg';
import arrow from '../../assets/logo/right-arrow.svg';
import warning from '../../assets/logo/warning.svg';
import brandImg from '../../assets/illustrations/Reset-password.svg';
import sentImg from '../../assets/illustrations/mail-sent.svg';

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Entered email is not valid.')
    .required('Please enter your email '),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [errorMessage, setError] = useState('');

  const onSubmit = (values: any) => {
    resetPassword(
      values.email,
      () => setResetPasswordSent(true),
      (msg: string) => setError(msg)
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const watchAllFields = watch();

  return (
    <LoginDiv>
      <img className='logo-svg d-none d-xl-block' src={logo} alt='logo' />
      {resetPasswordSent ? (
        <>
          <img className='logo-svg d-block d-xl-none' src={logo} alt='logo' />
          <div className='content-div reset-password-div'>
            <div className='center-div'>
              <h2 className='welcome-heading'>Done!</h2>
              <div className='success-div'>
                <SuccessCard
                  title={'Success'}
                  message={`An email with the instructions to reset the password has been sent to <b>${getValues(
                    'email'
                  )}</b>.`}
                />
              </div>

              <p className='terms'>
                Can&apos;t find the mail? Check your spam folder. It was sent
                from
                <b> support@renegadeinsurance.com</b>.
              </p>

              <button
                className='backButton  submit-button'
                onClick={() => navigate('/')}
              >
                <img
                  src={arrow}
                  className='d-none d-sm-block left-arrow'
                  alt=''
                />
                Back to Log In
              </button>
            </div>
            <div className='d-none d-sm-block'>
              <img className='sent-img' src={sentImg} alt='welcome' />
            </div>
          </div>
        </>
      ) : (
        <>
          <button
            className='d-block d-xl-none primaryBackButton'
            onClick={() => navigate('/')}
          >
            <img src={arrow} className='left-arrow' alt='' />
            Back to Log In
          </button>
          <div className='content-div reset-password-div'>
            <div className='center-div'>
              <div>
                <button
                  className='d-none d-xl-block primaryBackButton'
                  onClick={() => navigate('/')}
                >
                  <img src={arrow} className='left-arrow' alt='' />
                  Back to Log In
                </button>
              </div>

              <h2 className='welcome-heading'>
                Reset <br className='d-block d-sm-none' />
                your password
              </h2>

              <p className='sub'>
                Enter the email address you used for your account, and we will{' '}
                <br className='d-none d-sm-block' />
                send a link to reset your password.
              </p>

              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='fields'>
                    <InputField
                      name='email'
                      type='email'
                      placeholder='Email'
                      register={register}
                      className={`${
                        (errors.email || errorMessage) && 'error-field'
                      } ${watchAllFields.email && 'filled'}`}
                    />
                    {errors.email || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.email?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>

                  <button
                    type='submit'
                    className='submit-button primaryButton resetButton'
                  >
                    Reset Password
                    <img className='arrowl' src={arrow} alt='arrow-function' />
                  </button>
                </form>
              </div>
            </div>
            <div className='d-none d-sm-block'>
              <img className='reset-img' src={brandImg} alt='welcome' />
            </div>
          </div>
        </>
      )}
    </LoginDiv>
  );
};

export default ResetPassword;

import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import SuccessCard from '../../../../components/SuccessCard';
import LocalStorageService from '../../../../utils/LocalStorageService';
import Modal from '../../../../components/Modal';
import { AppDispatch } from '../../../../store';
import ChangePrimaryLocationModal from './ChangePrimaryLocationModal';
import {
  createLocation,
  LocationSliceState,
  PrimaryLocations,
} from '../../../../store/location/locationSlice';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import { ILocation } from '../../../../interfaces/Location';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
);
const AgentSchema = Yup.object().shape(
  {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Entered phone number is not valid.')
      .required('Please enter your phone number'),
    zipCode: Yup.string().max(9, 'Maximum length is 9 digit'),

    faxNumber: Yup.string()
      .nullable()
      .notRequired()
      .when('faxNumber', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(phoneRegExp, 'Entered phone Fax number is not valid.'),
      }),
    name: Yup.string().required('Please enter your LocationName '),
    websiteUrl: Yup.string()
      .nullable()
      .notRequired()
      .when('websiteUrl', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(urlRegex, 'Entered url is not valid.'),
      }),
  },
  [
    ['faxNumber', 'faxNumber'],
    ['websiteUrl', 'websiteUrl'],
  ]
);

const LocationAddModal = ({
  show,
  closeModal,
  agencyUUID,
  firstLocation,
}: {
  show: string;
  closeModal: Function;
  agencyUUID?: string;
  firstLocation: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [success, setSuccess] = useState(false);
  const [primary, setPrimary] = useState(false);
  const [addressObject, setAddressObject] = useState<any>({});
  const [isManual, setIsManual] = useState(false);
  const [submit, isSubmit] = useState(false);
  const [primaryLocationData, setPrimaryLocationData] = useState<ILocation>();
  const [primaryLocation, setPrimaryLocation] = useState(false);
  const [changePrimaryLocation, setChangePrimaryLocation] = useState(false);
  const [Submitdata, setSubmitdata] = useState<ILocation>();
  const [addressError, setAddressError] = useState('');
  const user = LocalStorageService.getUser();
  const [disabled, setDisabled] = useState(false);

  const stateRef = useRef();
  // stateRef.current = primaryLocation;

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgentSchema),
  });

  const watchAllFields = watch();

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { locations: LocationSliceState }) => state.locations.isLoading
  );
  useEffect(() => {
    if (user?.UserRole.name === 'ADMIN' && agencyUUID) {
      dispatch(
        PrimaryLocations({
          agencyUUID,
          successCB: (data: ILocation) => {
            setPrimaryLocationData(data);
            setPrimaryLocation(true);
          },
        })
      );
    } else {
      dispatch(
        PrimaryLocations({
          successCB: (data: ILocation) => {
            setPrimaryLocationData(data);
            setPrimaryLocation(true);
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyUUID]);

  const [locationExistError, setLocationExistError] = useState('');

  const onSubmit = (data: any) => {
    if (primary && primaryLocation) {
      setSubmitdata(data);
      setPrimaryLocation(false);
      return setChangePrimaryLocation(true);
    }
    if (Object.keys(addressObject).length <= 0 && !isManual) {
      return isSubmit(true);
    } else {
      isSubmit(false);
    }
    data.agencyUUID = agencyUUID;
    data.isPrimary = firstLocation ? true : primary;
    if (!isManual) data.apartment = '';
    if (
      isManual &&
      (!watchAllFields.address ||
        !watchAllFields.city ||
        !watchAllFields.state ||
        !watchAllFields.country ||
        !watchAllFields.zipCode ||
        !watchAllFields.apartment)
    ) {
      return setAddressError('field cannot be empty');
    }
    data.isManuallyAdded = isManual;
    if (!isManual) {
      data = Object.assign(data, addressObject);
    }
    dispatch(
      createLocation({
        data,
        successCB: () => {
          setDisabled(true);
          closeModal('closing');
          setTimeout(() => {
            setSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 4000);
        },
        errorCB: (error: any) => {
          setDisabled(false);
          if (error.data.error) {
            setLocationExistError(error.data.error);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (locationExistError) {
      setLocationExistError('');
    }
  }, [
    watchAllFields.name,
    watchAllFields.address,
    watchAllFields.city,
    watchAllFields.state,
    watchAllFields.country,
    watchAllFields.zipCode,
    addressObject,
  ]);
  return (
    <>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'Success adding location'}
            message={`<b>${getValues('name')}</b> has been Added`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div>
            <h2>Add location</h2>
          </div>
          <div>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='name'
                        type='text'
                        placeholder='Location name'
                        required
                        className={`${
                          (errors.name || locationExistError) && 'error-field'
                        } ${watchAllFields.name && 'filled'}`}
                      />
                      {errors.name ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.name?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <input
                        type='checkbox'
                        name='isPrimary'
                        className='form-check-input'
                        defaultChecked={firstLocation}
                        disabled={firstLocation}
                        onChange={(e) => setPrimary(!primary)}
                      />
                      <span
                        className={
                          firstLocation
                            ? 'disabledCheckboxText'
                            : 'CheckboxText'
                        }
                      >
                        Make this location primary
                      </span>
                    </div>
                    <div className='agency-fields'>
                      <PhoneNumberInput
                        control={control}
                        name='phoneNumber'
                        required
                        type='tel'
                        placeholder='Phone Number'
                        className={`${errors.phoneNumber && 'error-field'} ${
                          watchAllFields.phoneNumber && 'filled'
                        }`}
                      />
                      {errors.phoneNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <PhoneNumberInput
                        control={control}
                        name='faxNumber'
                        type='tel'
                        placeholder='Fax number'
                        className={`${errors.faxNumber && 'error-field'} ${
                          watchAllFields.faxNumber && 'filled'
                        }`}
                      />
                      {errors.faxNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.faxNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='websiteUrl'
                        type='text'
                        placeholder='Website'
                        className={`${errors.websiteUrl && 'error-field'} ${
                          watchAllFields.websiteUrl && 'filled'
                        }`}
                      />
                      {errors.websiteUrl ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.websiteUrl?.message}
                        </div>
                      ) : null}
                    </div>{' '}
                    {!isManual ? (
                      <div className='agency-fields'>
                        <GoogleSearchInput
                          placeholder='Address'
                          name='address'
                          setAddressObject={setAddressObject}
                          addressObject={addressObject}
                          inputType='PERSONAL'
                          required
                          className={`${
                            (errors.address || locationExistError) &&
                            'error-field'
                          } ${
                            Object.keys(addressObject).length > 0 && 'filled'
                          }`}
                          setIsManual={setIsManual}
                        ></GoogleSearchInput>

                        {submit && Object.keys(addressObject).length < 1 ? (
                          <div className='error-div'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter an address
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        <h2 className='business-info'> Address</h2>

                        <div className='agency-fields'>
                          <InputField
                            name='address'
                            type='text'
                            required
                            placeholder='Address'
                            register={register}
                            className={`${
                              (errors.address || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.address && 'filled'}`}
                          />{' '}
                          {!watchAllFields.address && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='zipCode'
                            type='number'
                            required
                            placeholder='Zipcode'
                            register={register}
                            className={`${
                              (errors.zipCode || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.zipCode && 'filled'}`}
                          />{' '}
                          {!watchAllFields.zipCode && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : errors.zipCode ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.zipCode?.message}
                            </div>
                          ) : null}
                        </div>

                        <div className='agency-fields'>
                          <InputField
                            name='city'
                            type='text'
                            required
                            placeholder='City'
                            register={register}
                            className={`${
                              (errors.city || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.city && 'filled'}`}
                          />{' '}
                          {!watchAllFields.city && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='state'
                            type='text'
                            required
                            placeholder='State'
                            register={register}
                            className={`${
                              (errors.state || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.state && 'filled'}`}
                          />{' '}
                          {!watchAllFields.state && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>

                        <div className='agency-fields'>
                          <InputField
                            name='country'
                            type='text'
                            placeholder='County'
                            required
                            register={register}
                            className={`${errors.country && 'error-field'} ${
                              watchAllFields.country && 'filled'
                            }`}
                          />
                          {!watchAllFields.country && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='apartment'
                            type='text'
                            placeholder='Apt'
                            required
                            register={register}
                            className={`${errors.apartment && 'error-field'} ${
                              watchAllFields.apartment && 'filled'
                            }`}
                          />
                          {!watchAllFields.apartment && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                    {locationExistError ? (
                      <div className='error-div'>
                        <img src={warning} alt='warning' className='warning' />
                        {locationExistError}
                      </div>
                    ) : null}
                  </div>

                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      disabled={loading || show === 'closing' || disabled}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                  {changePrimaryLocation &&
                    primaryLocationData &&
                    Submitdata && (
                      <ChangePrimaryLocationModal
                        showModal={changePrimaryLocation}
                        closeTheModal={setChangePrimaryLocation}
                        deletingLocation={primaryLocationData}
                        name={watchAllFields.name}
                        setPrimaryLocation={setPrimaryLocation}
                        data={Submitdata}
                        onSubmit={onSubmit}
                      />
                    )}
                </form>
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default LocationAddModal;

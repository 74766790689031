import DatePicker from 'react-datepicker';
import moment from 'moment';
import { PickerDiv } from '../elements/CustomDateField';
import 'react-datepicker/dist/react-datepicker.css';
import { YearMonthPicker } from '../../../components/DateField';

export interface IProps {
  name: string;
  placeholder: string;
  values: any;
  dateOnly?: boolean;
  minTime?: any;
  maxTime?: any;
  onSetItem: Function;
  [x: string]: any;
  required?: boolean;
}
const DateField = ({
  name,
  placeholder,
  values,
  dateOnly,
  onSetItem,
  minTime,
  maxTime,
  required,
  ...props
}: IProps) => {
  return (
    <div>
      <PickerDiv>
        <div
          className={`d-flex flex-column w-100 input-container ${
            (values[name] || (dateOnly && values[name])) && 'filled'
          } `}
        >
          {!dateOnly && (
            <DatePicker
              className='date-input'
              onChangeRaw={(e) => e.preventDefault()}
              onChange={(date) => {
                if (date) {
                  onSetItem(
                    name,
                    new Date(
                      date?.getTime() - date?.getTimezoneOffset() * 60000
                    )
                  );
                } else {
                  onSetItem(name, date);
                }
              }}
              selected={values[name] ? moment(values[name]).toDate() : null}
              dateFormat={'MMM dd, yyyy'}
              {...props}
              renderCustomHeader={({
                date,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
                decreaseYear,
                increaseYear,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <YearMonthPicker
                  date={date}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  prevYearButtonDisabled={prevYearButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  nextYearButtonDisabled={nextYearButtonDisabled}
                  decreaseMonth={decreaseMonth}
                  decreaseYear={decreaseYear}
                  increaseMonth={increaseMonth}
                  increaseYear={increaseYear}
                />
              )}
            />
          )}
          {dateOnly && (
            <DatePicker
              className='date-input'
              onChangeRaw={(e) => e.preventDefault()}
              selected={values[name] ? moment(values[name]).toDate() : null}
              onChange={(date) => {
                if (date) {
                  onSetItem(
                    name,
                    new Date(
                      date?.getTime() - date?.getTimezoneOffset() * 60000
                    )
                  );
                } else {
                  onSetItem(name, date);
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              minTime={minTime}
              maxTime={maxTime}
              timeCaption='Time'
              dateFormat='h:mm aa'
              renderCustomHeader={({
                date,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
                decreaseYear,
                increaseYear,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <YearMonthPicker
                  date={date}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  prevYearButtonDisabled={prevYearButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  nextYearButtonDisabled={nextYearButtonDisabled}
                  decreaseMonth={decreaseMonth}
                  decreaseYear={decreaseYear}
                  increaseMonth={increaseMonth}
                  increaseYear={increaseYear}
                />
              )}
              {...props}
            />
          )}

          <label htmlFor={name} className='date-placeholder '>
            {placeholder}
            {required ? <span className='requiredStar'>*</span> : null}
          </label>
        </div>
      </PickerDiv>
    </div>
  );
};

export default DateField;

import axios from './axios';

const fetchAudit = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  resource: string,
  resourceUUID: string,
  offset: number
) => {
  return axios
    .get('/audit', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        resource,
        resourceUUID,
        limit: 15,
        offset,
      },
    })
    .then((res) => {
      return res;
    });
};
export { fetchAudit };

import axios from './axios';

const getLostReasons = (
  successCB: Function,
  agencyUUID?: string,
  searchBy?: string
) => {
  return axios
    .get('/reason/lost', { params: { agencyUUID, searchBy } })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const createLostReason = (
  data: { agencyUUID?: string; name: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .post('/reason/lost', data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const editLostReason = (
  uuid: string,
  data: { agencyUUID?: string; name: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .patch(`/reason/lost/${uuid}`, data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const deleteLostReason = (uuid: string, data: { agencyUUID?: string }) => {
  return axios.delete(`/reason/lost/${uuid}`, { data });
};

const getXDateReasons = (
  successCB: Function,
  agencyUUID?: string,
  searchBy?: string
) => {
  return axios
    .get('/reason/xdate', { params: { agencyUUID, searchBy } })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const createXDateReason = (
  data: { agencyUUID?: string; name: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .post('/reason/xdate', data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const editXDateReason = (
  uuid: string,
  data: {
    agencyUUID?: string;
    name: string;
  },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .patch(`/reason/xdate/${uuid}`, data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const deleteXDateReason = (uuid: string, data: { agencyUUID?: string }) => {
  return axios.delete(`/reason/xdate/${uuid}`, { data });
};

export {
  getXDateReasons,
  editXDateReason,
  createXDateReason,
  getLostReasons,
  editLostReason,
  createLostReason,
  deleteLostReason,
  deleteXDateReason,
};

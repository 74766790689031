import styled from '@emotion/styled';

export const GlobalSearch = styled.div`
  position: absolute;
  background: #ffffff;
  width: 100%;
  top: 50px;
  left: 0;
  padding: 8px;

  .prev-search-drop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 12px;
    background: #fff;
    border: 0;
    align-items: center;
    :hover {
      background: #fafafa;
      border-radius: 8px;
    }
  }

  .prev-search-content {
    display: flex;
    align-items: center;
  }
  .search-content {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #fff;

    :hover {
      background: #fafafa;
      cursor: pointer;
    }
    h6 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #262758;
      text-overflow: ellipsis;
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;

      @media screen and (max-width: 576px) {
        max-width: 60vw;
      }
    }
  }

  .scroll-div {
    .nav-tabs {
      overflow: auto;
      flex-wrap: nowrap;
      width: 100%;

      .nav-link:first-of-type {
        margin-left: 9px;
      }

      align-items: center;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .nav-vector {
      display: none;
      padding: 2px;
      margin-bottom: 16px;
      margin-left: -15px;
      background: #fff;
      :hover {
        background: #e6e9f0;
        cursor: pointer;
        border-radius: 50%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1900px) {
        display: inline-block;
      }
    }

    .right-vector {
      transform: rotate(180deg);
    }
  }
  .nav-tabs {
    border-bottom: 1px solid #e6e9f0;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: none;
    border-bottom: 2px solid #262758;
  }
  .nav-tabs .nav-link {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-bottom: 2px solid transparent;
    padding-left: 1px;
    padding-right: 1px;
    margin: 0 20px;
    :hover {
      color: #000000;
      border: none;
      border-bottom: 2px solid #262758;
    }
  }
  .user-details {
    padding: 0;
    margin: 0;
    li {
      color: #8990a3;
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      border-left: 1px solid #8990a3;
      padding: 0 5px;
      &:first-child {
        border: none;
        padding-left: 0;
      }
      span {
        color: #262758;
        font-weight: 600;
      }
    }
  }

  .result-count {
    font-size: 14px;
    line-height: 20px;
    color: #8990a3;
    padding: 12px;
  }

  .profile-pic {
    background: #262758;
    width: 32px;
    height: 32px;
    border-radius: 46px;
    margin-right: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    flex: 0 0 40px;
    max-width: 32px;
    max-height: 32px;
  }
  .profile-img {
    background: rgba(230, 233, 240, 0.5);
    border-radius: 73px;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    flex: 0 0 40px;
    max-width: 32px;
    max-height: 32px;
    img {
      width: 16px;
    }
  }
  .cross-icon {
    width: 16px;
    height: 16px;
  }
  .viewAll-button {
    padding: 14px;
    background: #fff;
    border-top: 1px solid #d1d6e5;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    img {
      margin-left: 12px;
    }
  }

  .empty-div {
    padding: 10px 12px;
  }

  @media screen and (min-width: 576px) {
    top: 65px;
    border-radius: 16px;
  }

  @media screen and (min-width: 1200px) {
    box-shadow: 0px 2px 8px rgb(35 42 64 / 20%);
    width: 98%;
    top: 48px;
  }

  @media screen and (min-width: 1900px) {
    width: 98%;
    top: 58px;
  }

  @media screen and (max-width: 1200px) {
    height: 70vh;
    z-index: 1;
  }
`;

import { useEffect, useState } from 'react';
import { fetchIndustryDetails } from '../../../../api/industry';
import edit from '../../../../assets/logo/edit.svg';
// import { ILead } from '../../../../interfaces/Lead';

const LeadDetailsDrawer = ({
  leadDetails,
  setShowEditModal,
}: {
  leadDetails: any;
  setShowEditModal: Function;
}) => {
  const [industry, setIndustry] = useState('');
  useEffect(() => {
    if (leadDetails?.data?.business?.industry !== '') {
      fetchIndustryDetails(
        leadDetails?.data?.business?.industry,
        (data: any) => {
          setIndustry(
            `${data.naicsSixDigitCode} - ${data.naicsSixDigitCodeDescription}`
          );
          // setIndustryUuid(data?.uuid);
        }
      );
    } else {
      setIndustry('');
    }
  }, [leadDetails]);

  return (
    <div className='details-div'>
      <div className='d-flex align-items-center mb-2'>
        <h5 className='section-title'>Details</h5>
        <img
          className='editIcon'
          src={edit}
          alt='edit'
          onClick={() => setShowEditModal('open')}
        />
      </div>
      <div className='sub-section'>
        <div className='subsection-title'>Lead Source</div>
        <div>
          {leadDetails?.LeadSource ? leadDetails?.LeadSource.name : '--'}
        </div>
        <div className='subsection-title'>Associated Account</div>
        <div className='text-overflow-container'>
          <div className='name-text-overflow'>
            {leadDetails?.data?.accountInfo?.accountType === 'COMMERCIAL'
              ? leadDetails?.data?.business?.businessName
                ? leadDetails?.data?.business?.businessName
                : '--'
              : leadDetails?.data?.contact.firstName
              ? leadDetails?.data?.contact.firstName +
                ' ' +
                leadDetails?.data?.contact.lastName
              : '--'}
          </div>
          <span className='tooltiptext tooltip-producer'>
            <span className='tooltipInnertext'>
              {leadDetails?.data?.accountInfo?.accountType === 'COMMERCIAL'
                ? leadDetails?.data?.business?.businessName
                  ? leadDetails?.data?.business?.businessName
                  : '--'
                : leadDetails?.data?.contact.firstName
                ? leadDetails?.data?.contact.firstName +
                  ' ' +
                  leadDetails?.data?.contact.lastName
                : '--'}
            </span>
          </span>
        </div>
        {leadDetails?.data?.accountInfo?.accountType === 'COMMERCIAL' && (
          <>
            <div className='subsection-title'>Industry</div>
            <div>{industry ? industry : '--'}</div>
          </>
        )}
        <div className='subsection-title'>Phone Number </div>
        <div>{leadDetails?.data?.contact.phoneNumber}</div>
        <div className='subsection-title'>Email </div>
        <div>{leadDetails?.data?.contact.email}</div>
        <div className='subsection-title'>
          {' '}
          {leadDetails?.data?.accountInfo?.accountType === 'COMMERCIAL'
            ? 'Business Address'
            : 'Mailing Address'}
        </div>
        <div>
          {leadDetails?.data?.accountInfo?.accountType === 'COMMERCIAL' ? (
            leadDetails?.data?.business.businessStreetAddress ? (
              leadDetails?.data?.business?.isManuallyAdded ? (
                <>
                  {leadDetails?.data?.business?.businessApt
                    ? `${leadDetails?.data?.business?.businessApt}, `
                    : ''}{' '}
                  {leadDetails?.data?.business.businessStreetAddress
                    ? `${leadDetails?.data?.business.businessStreetAddress}, `
                    : ''}
                  {leadDetails?.data?.business?.businessCity
                    ? `${leadDetails?.data?.business?.businessCity}, `
                    : ''}{' '}
                  {leadDetails?.data?.business?.businessState
                    ? `${leadDetails?.data?.business?.businessState}, `
                    : ''}{' '}
                  {leadDetails?.data?.business?.businessCountry
                    ? `${leadDetails?.data?.business?.businessCountry}, `
                    : ''}{' '}
                  {leadDetails?.data?.business?.businessZipCode}
                </>
              ) : (
                <>
                  {leadDetails?.data?.business?.businessApt
                    ? `${leadDetails?.data?.business?.businessApt}, `
                    : ''}{' '}
                  {leadDetails?.data?.business.businessStreetAddress}
                </>
              )
            ) : (
              '--'
            )
          ) : leadDetails?.data?.contact?.streetAddress ? (
            leadDetails?.data?.contact?.isManuallyAdded ? (
              <>
                {leadDetails?.data?.contact?.contactApt
                  ? `${leadDetails?.data?.contact?.contactApt}, `
                  : ''}{' '}
                {leadDetails?.data?.contact.streetAddress
                  ? `${leadDetails?.data?.contact.streetAddress}, `
                  : ''}
                {leadDetails?.data?.contact?.city
                  ? `${leadDetails?.data?.contact?.city}, `
                  : ''}{' '}
                {leadDetails?.data?.contact?.state
                  ? `${leadDetails?.data?.contact?.state}, `
                  : ''}{' '}
                {leadDetails?.data?.contact?.country
                  ? `${leadDetails?.data?.contact?.country}, `
                  : ''}{' '}
                {leadDetails?.data?.contact?.zipCode}
              </>
            ) : (
              <>
                {leadDetails?.data?.contact?.contactApt
                  ? `${leadDetails?.data?.contact?.contactApt}, `
                  : ''}{' '}
                {leadDetails?.data?.contact?.streetAddress}
              </>
            )
          ) : (
            '--'
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadDetailsDrawer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addUser, fetchAgencyAgents } from '../../api/admin';
import { deleteAgent, fetchAgents, updateAgent } from '../../api/agents';
import { updateUser } from '../../api/user';
import { IAgency } from '../../interfaces/Agency';
import { IAddUser, IAgent } from '../../interfaces/User';

export type AgentsSliceState = {
  status: string;
  agents: IAgent[];
  count: number;
  agency?: IAgency;
  isLoading: boolean;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  agencyUUID?: string;
  limit: number;
  offset: number;
  successCB?: Function;
  userRole?: string;
  agentStatus?: string;
}

const initialState: AgentsSliceState = {
  status: 'idle',
  agents: [],
  agency: undefined,
  isLoading: false,
  count: 0,
};

export const getAgents = createAsyncThunk(
  'agents/getAgents',
  async ({
    agencyUUID,
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    successCB,
    userRole,
    agentStatus,
  }: IProps) => {
    const response = await fetchAgencyAgents(
      agencyUUID,
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      userRole,
      agentStatus
    );
    if (successCB) {
      successCB(response.data);
    }
    return response.data;
  }
);

export const addNewAgent = createAsyncThunk(
  'agents/addNewAgent',
  async ({
    value,
    successCB,
    errorCB,
  }: {
    value: IAddUser;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response: any = await addUser(value, successCB, errorCB);
    return response.data;
  }
);

export const onGetAgents = createAsyncThunk(
  'agents/onGetAgents',
  async ({
    successCB,
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    userRole,
    agentStatus,
    includeInActive,
  }: {
    successCB: Function;
    sortBy: string;
    orderBy: string;
    searchBy: string;
    limit: number;
    offset: number;
    userRole?: string;
    agentStatus?: string;
    includeInActive?: boolean;
  }) => {
    const response = await fetchAgents(
      successCB,
      sortBy,
      orderBy,
      searchBy,
      '',
      limit,
      offset,
      '',
      userRole,
      agentStatus,
      includeInActive
    );
    return response.data;
  }
);
export const updateAgentDetail = createAsyncThunk(
  'agents/updateAgentDetail',
  async ({
    uuid,
    data,
  }: {
    uuid: string;
    data: { action: string; status: string };
  }) => {
    const response = await updateAgent(uuid, data);
    return response.data;
  }
);

export const removeAgent = createAsyncThunk(
  'agents/removeAgent',
  async ({
    uuid,
    deleteUUID,
    successCB,
    errorCB,
  }: {
    uuid: string;
    deleteUUID: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await deleteAgent(uuid, deleteUUID, successCB, errorCB);
    return response.data;
  }
);

export const updateUserDetail = createAsyncThunk(
  'agents/updateUserDetail',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await updateUser(uuid, data, successCB, errorCB);
    return response.data;
  }
);

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    updateGoal: (state, action) => {
      const index = state.agents.findIndex(
        (item: IAgent) => item.User.uuid === action.payload.userUUID
      );
      state.agents[index].policyGoal = action.payload.policyGoal;
      state.agents[index].premiumGoal = action.payload.premiumGoal;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAgents.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agents = action.payload.agency?.Agents
          ? action.payload.agency.Agents
          : [];
        state.count = action.payload.count;
        state.agency = action.payload.agency;
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addNewAgent.fulfilled, (state, action) => {
        state.agents.unshift(action.payload);
        state.isLoading = false;
      })
      .addCase(addNewAgent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewAgent.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onGetAgents.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onGetAgents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agents = action.payload.agents;
        state.count = action.payload.count;
      })
      .addCase(onGetAgents.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateAgentDetail.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateAgentDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.agents.findIndex(
          (item: any) => item.uuid === action.payload.uuid
        );
        state.agents[index] = action.payload;
      })
      .addCase(updateAgentDetail.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateUserDetail.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateUserDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.agents.findIndex(
          (item: any) => item.uuid === action.payload.uuid
        );
        state.agents[index] = action.payload;
      })
      .addCase(updateUserDetail.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(removeAgent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removeAgent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.agents.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        if (index >= 0) {
          state.agents.splice(index, 1);
        }
      })
      .addCase(removeAgent.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, updateGoal } = agentsSlice.actions;

export default agentsSlice.reducer;

export const selectAllAgents = (state: { agents: AgentsSliceState }) =>
  state.agents.agents;

export const selectAgency = (state: { agents: AgentsSliceState }) =>
  state.agents.agency;

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import {
  addDocument,
  updateDocumentDetails,
  getDocumentDetails,
  reaploadDocumentDetails,
} from '../../../../store/documents/documentsSlice';
import { getCount as contactCount } from '../../../../store/contacts/contactsSlice';
import { getCount as accountCount } from '../../../../store/accounts/accountsSlice';
import { getCount as policyCount } from '../../../../store/policies/policiesSlice';
import { getLeadCount as leadCount } from '../../../../store/leads/leadsSlice';
import { getCount as opportunityCount } from '../../../../store/opportunities/opportunitiesSlice';
import { getDocumentType } from '../../../../api/documents';
import Modal from '../../../Modal';
import DocumentsField from '../../../DocumentsField';
import WarningCard from '../../../../pages/Pipeline/components/WarningCard';
import SuccessCard from '../../../../components/SuccessCard';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import { DocumentsModalDiv } from '../styles';
import tick from '../../../../assets/logo/tick.svg';
import document from '../../../../assets/logo/document.svg';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import cross from '../../../../assets/logo/cross.svg';
import warning from '../../../../assets/logo/warning.svg';

const AddDocumentsModal = ({
  show,
  closeModal,
  uuid,
  isEdit,
  resourceUUID,
  editUUID,
  name,
}: {
  show: string;
  uuid: string;
  resourceUUID?: string;
  isEdit?: boolean;
  editUUID?: string;
  name: string;
  closeModal: Function;
}) => {
  const { handleSubmit } = useForm({});
  const [file, setFile] = useState<any>();
  const [dropDown, setDropDown] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [type, setType] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [typeUUID, setTypeUUID] = useState('');
  const [reapload, setReapload] = useState(false);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [fileError, setFileError] = useState('');
  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);
  const createDoc = () => {
    if (!typeUUID) {
      setError('Please provide a document type');
      return;
    }
    let formData = new FormData();
    formData.append('docFile', file);
    formData.append('resource', name);
    formData.append('resourceUUID', uuid);
    formData.append('documentTypeUUID', typeUUID);
    setDisabled(true);

    dispatch(
      addDocument({
        formData,
        successCB: () => {
          if (name === 'Contact') {
            dispatch(contactCount({ uuid }));
          } else if (name === 'Account') {
            dispatch(accountCount({ uuid }));
          } else if (name === 'Opportunity') {
            dispatch(opportunityCount({ uuid }));
          } else if (name === 'Policy') {
            dispatch(policyCount({ uuid }));
          } else if (name === 'lead') {
            dispatch(leadCount({ uuid }));
          }
          setDisabled(false);
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 2000);
        },
        errorCB: () => {
          setDisabled(false);
        },
      })
    );
  };
  const editDoc = (data: any) => {
    if (editUUID && data) {
      if (reapload && resourceUUID) {
        let formData = new FormData();
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', resourceUUID);
        formData.append('documentTypeUUID', typeUUID);
        formData.append('action', 'updateDocument');
        dispatch(
          reaploadDocumentDetails({
            uuid: editUUID,
            data: formData,
            successCB: () => {
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
              closeModal('closing');
              setTimeout(() => {
                setShowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
          })
        );
      } else if (resourceUUID) {
        data.documentTypeUUID = typeUUID;
        data.action = 'documentTypeUpdate';
        dispatch(
          updateDocumentDetails({
            uuid: editUUID,
            data: data,
            successCB: () => {
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
              closeModal('closing');
              setTimeout(() => {
                setShowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
          })
        );
      }
    }
  };
  useEffect(() => {
    if (isEdit) {
      dispatch(
        getDocumentDetails({
          uuid,
          successCB: (data: any) => {
            setFile(data);
            setType(data?.DocumentType?.name);
            setTypeUUID(data?.DocumentType?.uuid);
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  return (
    <>
      {showSuccess && isEdit && (
        <WarningCard
          message={`<b> Doument </b> has been  <b> Updated.</b>`}
          title={`Success updating document`}
        />
      )}
      {
        <SuccessAlign>
          <div
            className={`success-container ${
              showSuccess && !isEdit && 'open-drawer'
            } `}
          >
            <SuccessCard
              title={'Success adding Document'}
              message={`<b>Document</b> has been added<b>`}
            />
            <button className='crossbutton'>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
      }
      <Modal open={show} align='right'>
        <DocumentsModalDiv>
          <div className='topHead'>
            <h2 className='topHeadTitle'>
              {isEdit ? 'Edit document' : 'Add document'}
            </h2>
            <button
              onClick={() => {
                closeModal('closed');
              }}
              className='crossbutton'
            >
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>
          <form
            onSubmit={isEdit ? handleSubmit(editDoc) : handleSubmit(createDoc)}
            className='FormDiv'
          >
            <div className='drawer-content '>
              <DocumentsField
                setFile={setFile}
                setFileError={setFileError}
                file={file}
                disabled={file && typeUUID && isEdit}
              />
            </div>
            {/* {file?.length > 0 &&
            file?.map((item: any) => ( */}
            {!isEdit && file && (
              <div className='documentDetails'>
                {' '}
                <img src={document} alt='' />
                <span className='docName'>{file?.name}</span>
                <div className='itemsDiv d-flex justify-content-between align-items-center'>
                  <button
                    className='documentDropDown d-flex justify-content-between align-items-center'
                    onClick={() => setDropDown(!dropDown)}
                    type='button'
                  >
                    {type ? type : 'Document type'}
                    <img src={vector} alt='' />
                    {dropDown && (
                      <div className='documentTypeDrop'>
                        {' '}
                        {DocumentTypes &&
                          DocumentTypes?.map((item: any) => (
                            <div
                              className='listType d-flex align-items-center '
                              onClick={() => {
                                setDropDown(false);
                                setType(item?.name);
                                setTypeUUID(item.uuid);
                              }}
                            >
                              <ul>{item?.name}</ul>
                            </div>
                          ))}
                      </div>
                    )}
                  </button>
                  <img
                    className='binImage'
                    src={bin}
                    alt=''
                    onClick={() => {
                      setFile('');
                      setTypeUUID('');
                      setType('');
                    }}
                  />
                </div>
                {error && !typeUUID && (
                  <div className='errorDIv d-flex align-items-center'>
                    <span className='errorVal'>
                      <img className='erricon' src={warning} alt='' />
                      {error}
                    </span>
                  </div>
                )}
              </div>
            )}
            {fileError && (
              <div className='errorDIv d-flex align-items-center'>
                <span className='errorVal'>
                  <img className='erricon' src={warning} alt='' />
                  {fileError}
                </span>
              </div>
            )}
            {isEdit && file && (
              <div className='documentDetails'>
                {' '}
                <div className='d-flex align-items-center'>
                  <img src={document} alt='' />
                  <span className='docName'>{file?.name}</span>
                </div>
                <div className='itemsDiv d-flex justify-content-center align-items-center'>
                  <button
                    className='documentDropDown d-flex justify-content-between align-items-center'
                    onClick={() => setDropDown(!dropDown)}
                    type='button'
                  >
                    {type ? type : 'Document type'}
                    <img src={vector} alt='' />
                    {dropDown && (
                      <div className='documentTypeDrop'>
                        {' '}
                        {DocumentTypes &&
                          DocumentTypes?.map((item: any) => (
                            <div
                              className='listType d-flex align-items-center '
                              onClick={() => {
                                setDropDown(false);
                                setType(item?.name);
                                setTypeUUID(item.uuid);
                              }}
                            >
                              <ul>{item?.name}</ul>
                            </div>
                          ))}
                      </div>
                    )}
                  </button>
                  <img
                    className='binImage'
                    src={bin}
                    alt=''
                    onClick={() => {
                      setFile('');
                      setTypeUUID('');
                      setType('');
                      setReapload(true);
                    }}
                  />
                </div>
              </div>
            )}

            <div className='buttonsDiv d-flex align-items-center'>
              <button
                onClick={() => closeModal('closing')}
                className='cancel-button'
                type='button'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='saveButton'
                onClick={() => createDoc}
                disabled={disabled}
              >
                Save <img className='tick' src={tick} alt='tick-sign' />
              </button>
            </div>
          </form>
        </DocumentsModalDiv>
      </Modal>
    </>
  );
};

export default AddDocumentsModal;

import { TimePickerDiv } from './styles';
import dropDown from '../../assets/logo/dropdown.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { updateTime } from '../../utils/DateTimeFormatter';

const TimePicker = ({
  setTaskDueTime,
  setDueTime,
  innerTab,
  dueTime,
}: {
  setTaskDueTime: Function;
  setDueTime: Function;
  innerTab?: boolean;
  dueTime: string;
}) => {
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setTaskDueTime);
  const hours = [
    `01`,
    `02`,
    `03`,
    `04`,
    `05`,
    `06`,
    `07`,
    `08`,
    `09`,
    `10`,
    `11`,
    `12`,
  ];
  const minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push(`0${i}`);
    } else {
      minutes.push(`${i}`);
    }
  }
  const ampm = ['AM', 'PM'];
  const [AMPM, setAMPM] = useState('');
  const [Hour, setHour] = useState('');
  const [Minute, setMinute] = useState('');
  const [showAMPM, setShowAMPM] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showMinute, setShowMinute] = useState(false);

  const setHhMmAmPm = useCallback(() => {
    const hh = `${
      new Date(dueTime).getHours() % 12 === 0
        ? 12
        : new Date(dueTime).getHours() % 12
    }`;
    setHour(hh);
    setAMPM(new Date(dueTime).getHours() > 11 ? 'PM' : 'AM');
    setMinute(`${new Date(dueTime).getMinutes()}`.padStart(2, '0'));
  }, [dueTime]);

  useEffect(() => {
    if (dueTime) {
      setHhMmAmPm();
    }
  }, [dueTime, setHhMmAmPm]);

  const handleTimeChange = (item: string, type: string) => {
    let timeObj = { hours: Hour, minutes: Minute, ampm: AMPM };

    switch (type) {
      case 'HH':
        setHour(item);
        timeObj = { ...timeObj, hours: item };
        break;
      case 'MM':
        setMinute(item);
        timeObj = { ...timeObj, minutes: item };
        break;
      case 'AMPM':
        setAMPM(item);
        timeObj = { ...timeObj, ampm: item };
        break;
      default:
        break;
    }

    const time = updateTime(timeObj);

    setDueTime(
      moment(
        moment('2022-10-12').format('YYYY-MM-DD') +
          moment(`${time}`, ['h:mm A']).format('HH:mm A'),
        'YYYY-MM-DDLT'
      ).toDate()
    );
  };

  return (
    <TimePickerDiv>
      <div
        className={`${
          innerTab ? 'innerTabPicker' : 'pickerModalContainer'
        } d-flex justify-content-between align-items-center`}
      >
        <div className='timeField '>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(false);
              setShowMinute(false);
              setShowHour(!showHour);
            }}
          >
            {Hour ? Hour : 'HH'}
            <img
              src={dropDown}
              alt=''
              className={`${showHour && 'inverted'}`}
            />
            <div className={showHour ? 'dropDownList' : 'd-none'}>
              {hours?.map((item: string) => (
                <>
                  <ul key={item} onClick={() => handleTimeChange(item, 'HH')}>
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className='timeField'>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(false);
              setShowMinute(!showMinute);
              setShowHour(false);
            }}
          >
            {Minute ? Minute : 'MM '}
            <img
              src={dropDown}
              alt=''
              className={`${showMinute && 'inverted'}`}
            />
            <div className={showMinute ? 'dropDownList' : 'd-none'}>
              {minutes?.map((item: string) => (
                <>
                  <ul key={item} onClick={() => handleTimeChange(item, 'MM')}>
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className='timeField'>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(!showAMPM);
              setShowMinute(false);
              setShowHour(false);
            }}
          >
            {AMPM ? AMPM : 'Period'}
            <img
              src={dropDown}
              alt=''
              className={`${showAMPM && 'inverted'}`}
            />
            <div
              className={showAMPM ? 'dropDownList dropDownListAmPm' : 'd-none'}
            >
              {ampm?.map((item: string) => (
                <>
                  <ul key={item} onClick={() => handleTimeChange(item, 'AMPM')}>
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TimePickerDiv>
  );
};

export default TimePicker;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../store';
import { onEditLostReason } from '../../../store/reasons/reasonsSlice';
import InputField from '../../../components/InputField';
import { ILostReason } from '../../../interfaces/Lead';
import { StyledModal } from '../../Pipeline/elements/styles';
import close from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a reason.')
    .max(50, 'Reason length must be less than 50.'),
});

const EditLostReasonModal = ({
  lostReason,
  showEditLostReason,
  setShowEditLostReason,
  setSuccessMessage,
}: {
  lostReason: ILostReason;
  showEditLostReason: boolean;
  setShowEditLostReason: Function;
  setSuccessMessage: Function;
}) => {
  const { uuid } = useParams();
  const [errorMessage, setError] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const watchName = watch('name');

  useEffect(() => {
    setValue('name', lostReason.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostReason]);

  const editLostReason = (data: any) => {
    data.agencyUUID = uuid;
    dispatch(
      onEditLostReason({
        uuid: lostReason.uuid,
        data,
        successCB: () => {
          reset();
          setShowEditLostReason(false);
          setSuccessMessage(`Lost reason has been updated.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
        errorCB: (msg: string) => setError(msg),
      })
    );
  };

  return (
    <StyledModal
      show={showEditLostReason}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt=''
        src={close}
        className='closeButton'
        onClick={() => setShowEditLostReason(false)}
      />
      <h2 className='mb-3'>Edit lost reason</h2>
      <form onSubmit={handleSubmit(editLostReason)}>
        <div className='mb-3'>
          <InputField
            name='name'
            type='text'
            placeholder='Write a lost reason'
            register={register}
            required
            className={`${(errors.name || errorMessage) && 'error-field'} ${
              watchName && 'filled'
            }`}
          />

          {errorMessage || errors.name ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errorMessage || errors.name?.message}
            </div>
          ) : null}
        </div>

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowEditLostReason(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button
            className='primaryButton'
            type='submit'
            disabled={lostReason.name === watchName}
          >
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default EditLostReasonModal;

export const convertTime = (timeStr: any) => {
  const [selectedTime, modifier] = timeStr.split(' ');
  let [hours, minutes] = selectedTime.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const updateTime = ({
  hours,
  minutes,
  ampm,
}: {
  hours?: string;
  minutes?: string;
  ampm?: string;
}) => {
  let hour = hours;
  if (!hours) {
    hour = '12';
  }
  if (ampm === 'PM') {
    return convertTime(`${hour}:${minutes} ${ampm}`);
  } else {
    return `${hour}:${minutes} AM`;
  }
};

import { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { AppDispatch } from '../../store';
import { IAgent } from '../../interfaces/User';
import {
  onGetAgents,
  AgentsSliceState,
  selectAllAgents,
} from '../../store/agents/agentsSlice';
import ActionsCard from './components/MyTeamActionsCard';
import AgentModal from '../Admin/AgencyDetails/components/AgentModal';
import MobileNav from '../../components/MobileTabNav';
import MyTeamMobileCard from './components/MyTeamMobileCard';
import TopBar from '../../components/TopBar';
import Table from '../../components/Table';
import EmptyResults from '../MyBook/components/Empty';
import PaginationComponent from '../../components/PaginationComponent';
import SideBar from '../../components/SideBar';
import { FilterContainer, MyBookDiv } from '../MyBook/elements/styles';
import close from '../../assets/logo/cross.svg';
import sort from '../../assets/logo/sort.svg';
import sortup from '../../assets/logo/sortup.svg';
import sortdown from '../../assets/logo/sortdown.svg';
import filter from '../../assets/logo/filter-mobile.svg';
import Filter from '../../assets/logo/filter.svg';
import plus from '../../assets/logo/plus.svg';
import search from '../../assets/logo/search.svg';
import { AdminDiv } from '../../styles/AdminManage';
import SetGoalModal from './components/SetGoalDrawer';
import UserFilter from '../Admin/AgencyDetails/components/FilterDrawer';
import EditUser from '../../components/EditUser';

const MyTeamList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const agents = useSelector(selectAllAgents);
  const loadingStatus = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents?.status
  );
  const agentsCount = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents?.count
  );

  const searchRef = useRef(null);

  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const [sortBy, setSortBy] = useState('userName');
  const [orderBy, setOrderBy] = useState('desc');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showModal, setShowModal] = useState('closed');
  const [showGoalModal, setShowGoalModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [userRole, setUserRole] = useState<any>('');
  const [userStatus, setUserStatus] = useState<any>('');
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        onGetAgents({
          successCB: () => {},
          sortBy: sortBy,
          orderBy: orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          userRole: userRole?.value,
          agentStatus: userStatus?.value,
          includeInActive: true,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    sortBy,
    orderBy,
    debouncedSearchTerm,
    limit,
    page,
    userRole,
    userStatus,
  ]);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const [showFilter, setShowFilter] = useState('closed');
  const [edituser, setEditUser] = useState('closed');

  const [editData, setEditData] = useState<any>({});

  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <TopBar>
        <div className='head-mob d-flex align-items-center justify-content-between'>
          <h2>My Team</h2>
        </div>
      </TopBar>
      <MyBookDiv>
        <button
          onClick={() => setShowModal('open')}
          className='addButton d-sm-none'
        >
          <img src={plus} alt='add' />
        </button>
        <div className='myteam-content'>
          <div className='d-none d-sm-flex justify-content-between'>
            <button
              className='addButton d-none d-sm-block'
              onClick={() => setShowModal('open')}
            >
              Add user
            </button>
            <div className='book-filter d-flex ms-auto' ref={searchRef}>
              {isSearchClicked || searchBy ? (
                <div className='book-search d-flex align-items-center'>
                  <img src={search} alt='' className='search-button' />
                  <input
                    onChange={(e) => {
                      setPage(0);
                      setSearchBy(e.target.value);
                    }}
                    autoFocus
                    value={searchBy}
                  />
                  <img
                    src={close}
                    alt=''
                    className='close-button'
                    onClick={() => {
                      setPage(0);
                      setSearchBy('');
                      setIsSearchClicked(false);
                    }}
                  />
                </div>
              ) : (
                <div
                  className='book-shrinked'
                  onClick={() => setIsSearchClicked(true)}
                >
                  <img src={search} alt='' />
                </div>
              )}

              <span className='divider d-none d-sm-block' />
              <div
                className='book-shrinked d-none d-sm-flex'
                onClick={() => setShowFilter('open')}
              >
                <img src={Filter} alt='' />
                {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) > 0 && (
                  <span className='filter-counter'>
                    {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)}
                  </span>
                )}
              </div>
              <span
                className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
              />
              <div
                className={`book-shrinked d-sm-none ${
                  isSearchClicked && 'd-none'
                }`}
                onClick={() => setShowFilter('open')}
              ></div>
            </div>
          </div>

          <div className='d-flex d-sm-none mobilecardDiv'>
            <h2 className='HeadNavigator'></h2>
            <div className='d-flex'>
              <div className='d-flex search-Field  w-100'>
                <img className='mx-2' src={search} alt='' />
                <input
                  className='inputSearchField mx-2'
                  type='text'
                  placeholder='Search users'
                  onChange={(e) => {
                    setPage(0);
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
              </div>
              <div
                className='d-flex search-Field filterField position-relative'
                onClick={() => setShowFilter('open')}
              >
                <img src={filter} alt='' />
                {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) > 0 && (
                  <span className='filter-counter'>
                    {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='mybook-content'>
            <div className='d-sm-none'>
              {loadingStatus === 'loading' ? (
                [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
              ) : loadingStatus === 'succeeded' && agents.length > 0 ? (
                <>
                  {agents.map((item: IAgent) => (
                    <div key={item.uuid}>
                      <MyTeamMobileCard
                        agent={item}
                        key={item.uuid}
                        loadingStatus={loadingStatus}
                      />
                    </div>
                  ))}
                  <PaginationComponent
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
                    count={agentsCount}
                  />
                </>
              ) : (
                <EmptyResults name='agents' />
              )}
            </div>

            <div className='d-none d-sm-block'>
              <Table
                limit={limit}
                setLimit={setLimit}
                setScroll={() => {
                  setScroll(!scroll);
                }}
                page={page}
                setPage={setPage}
                count={agentsCount}
                loadingStatus={loadingStatus}
                className='mybook-table'
                name='agent'
                columns={[
                  {
                    accessor: 'name',
                    resizable: true,
                    sortable: true,
                    minWidth: 140,
                    Header: () => (
                      <div
                        onClick={() => handleSort('userName')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between  w-100'
                      >
                        <span>User name</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'userName'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),

                    Cell: ({ row }: { row: any }) => (
                      <div className='user-nameText row-name d-flex align-items-center'>
                        <b>
                          {row.original.User?.firstName +
                            ' ' +
                            row.original.User?.lastName}
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'status',
                    resizable: true,
                    minWidth: 100,
                    Header: () => (
                      <div className='d-flex justify-content-between  w-100'>
                        <span className='d-none  d-sm-block'>Status</span>
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center h-100'>
                        <div
                          className={`status ${
                            row.original.AgentStatus?.name === 'ACTIVE'
                              ? 'status-Active'
                              : 'statusInvited'
                          }`}
                        >
                          {row.original.AgentStatus?.name
                            .toLowerCase()
                            .split('_')
                            .map(
                              (word: any) =>
                                word[0].toUpperCase() + word.substring(1)
                            )
                            .join(' ')}
                        </div>
                      </div>
                    ),
                  },
                  {
                    accessor: 'Referral partners ',
                    resizable: true,
                    sortable: true,
                    minWidth: 209,
                    width: 209,
                    Header: () => (
                      <div className='d-flex justify-content-between w-100'>
                        <span className='hide-small'>
                          No.of referral partners
                        </span>
                        <span className='hide-large '>#Referal Partners</span>
                      </div>
                    ),
                    Cell: () => <div>{'0'}</div>,
                  },
                  {
                    accessor: 'Pipeline premium',
                    resizable: true,
                    minWidth: 180,
                    Header: () => (
                      <div className='d-flex justify-content-between w-100'>
                        <span className='hide-small'>Pipeline premium</span>
                        <span className='hide-large'>Pl.premium</span>
                      </div>
                    ),

                    Cell: ({ row }: { row: any }) => (
                      <div className='row-status '>
                        <b>
                          {'$'}
                          {row.original.pipelinePremium
                            ? row.original.pipelinePremium.toLocaleString(
                                'en-US'
                              )
                            : 0}
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'Policy goal',

                    resizable: true,
                    minWidth: 175,
                    width: 80,
                    Header: () => (
                      <div className='d-flex justify-content-between w-100'>
                        <span>Policy goal</span>
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div>
                        <div className='progress-bar'>
                          <span
                            style={{
                              width: `${
                                ((row.original.policyCount
                                  ? row.original.policyCount
                                  : 0) /
                                  row.original.policyGoal) *
                                100
                              }%`,
                            }}
                          ></span>
                        </div>
                        <b>
                          {row.original.policyCount
                            ? row.original.policyCount
                            : 0}
                          /{row.original.policyGoal}
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'premium goal',
                    resizable: true,
                    minWidth: 175,
                    Header: () => (
                      <div className='d-flex justify-content-between w-100'>
                        <span>Premium goal</span>
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div>
                        <div className='progress-bar'>
                          <span
                            style={{
                              width: `${
                                ((row.original.policyPremium
                                  ? row.original.policyPremium
                                  : 0) /
                                  row.original.premiumGoal) *
                                100
                              }%`,
                            }}
                          ></span>
                        </div>
                        <b>
                          {'$'}{' '}
                          {row.original.policyPremium
                            ? row.original.policyPremium.toLocaleString('en-US')
                            : 0}
                          /{'$'}{' '}
                          {row.original.premiumGoal?.toLocaleString('en-US')}
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'actions',
                    Header: 'Actions',
                    sticky: 'right',
                    resizable: false,
                    width: 150,
                    minWidth: 150,
                    maxWidth: 150,
                    Cell: ({ row }: { row: any }) => (
                      <>
                        <ActionsCard
                          agents={row.original}
                          loadingStatus={loadingStatus}
                          setUUID={setUUID}
                          setShowGoalModal={setShowGoalModal}
                          setShowEditModal={setEditUser}
                          setData={setEditData}
                          scroll={scroll}
                        />
                      </>
                    ),
                  },
                ]}
                data={agents}
              />
            </div>
          </div>
        </div>
      </MyBookDiv>
      {showGoalModal !== 'closed' && (
        <SetGoalModal
          show={showGoalModal}
          closeModal={setShowGoalModal}
          agentUUID={UUID}
        />
      )}
      {edituser !== 'closed' && (
        <EditUser show={edituser} closeModal={setEditUser} data={editData} />
      )}
      <div className='d-xl-none'>
        <MobileNav />
      </div>
      {showModal !== 'closed' && (
        <AgentModal show={showModal} closeModal={setShowModal} />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <UserFilter
            show={showFilter}
            closeModal={setShowFilter}
            userRole={userRole}
            setUserRole={setUserRole}
            setUserStatus={setUserStatus}
            userStatus={userStatus}
          />
        )}
      </FilterContainer>
    </AdminDiv>
  );
};

export default MyTeamList;

import styled from '@emotion/styled';
export const PlacesContainer = styled.div`
  position: relative;
  ul {
    list-style: none;
    box-shadow: 0px 0px 8px rgb(35 42 64 / 20%);
    border-radius: 8px;
    padding: 4px;
    max-height: 256px;
    overflow: hidden auto;
    position: absolute;
    z-index: 1000;
    background: #fff;
    max-width: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 99.5%;
    margin: auto;
    li {
      min-height: 56px;
      height: auto;
      border-radius: 8px;
      border-bottom: 1px solid #e6e9f0;
      padding: 0 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &#manual-address {
        border-bottom: none;
        font-weight: 700;
      }
    }
  }
`;

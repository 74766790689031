import axios from './axios';

const fetchIndustries = (
  successCB: Function,
  sortBy?: string,
  orderBy?: string,
  searchBy?: string,
  minimal?: string
) => {
  return axios
    .get(`/industry`, { params: { sortBy, searchBy, orderBy, minimal } })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const fetchIndustryDetails = (uuid: string, successCB: Function) => {
  return axios.get(`/industry/${uuid}`).then((res) => {
    successCB(res.data);
    return res;
  });
};
export { fetchIndustries, fetchIndustryDetails };

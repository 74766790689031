import styled from '@emotion/styled';

export const TopBarDiv = styled.div`
  background: #ffffff;
  // position: relative;
  display: flex;
  z-index: 1;
  align-items: center;

  .global-shrinked {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    background: #e6e9f0;
    border-radius: 8px;
    margin-right: 16px;
    :hover {
      background: #e6e9f0;
    }
  }

  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 20px;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .global-search {
    position: relative;
    input {
      background: #e6e9f0;
      border: none;
      outline: none;
      border-radius: 8px;
      width: 284px;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;

      :focus,
      &.filled {
        background: #fff;
        width: 500px;
        height: 40px;
        outline: none;
        font-size: 14px;
        border: 1px solid #262758;
        border-radius: 8px;
        padding: 10px 48px;
      }
    }

    .search-button {
      margin: 0 -36px 0 16px;
      position: relative;
      z-index: 1;
    }

    .close-button {
      margin: 0 16px 0 -35px;
      cursor: pointer;
      display: none;
    }
    .key-button {
      margin: 0 27px 0 -35px;
      cursor: pointer;
    }
    .win-button {
      margin: 0 27px 0 -51px;
    }
    input:focus ~ .close-button,
    .filled ~ .close-button {
      display: block;
    }
    input:focus ~ .key-button,
    .filled ~ .key-button {
      display: none;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  .avatar-img {
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .profile-container {
    position: relative;
  }

  .profile-drop {
    position: absolute;
    right: 0;
    top: 50px;
    width: 347px;
    height: 340px;
    padding: 23px;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    backdrop-filter: blur(10px);
    white-space: nowrap;
    z-index: 5;
    .avatar-name-div {
      width: calc(100% - 50px);
    }

    h6 {
      font-size: 16px;
      font-weight: 600;
      margin: 0 20px;
      cursor: pointer;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pointerDiv {
      position: absolute;
      background: #fff;
      transform: rotate(45deg);
      width: 10px;
      top: -4px;
      right: 10px;
      height: 10px;
    }
  }
  .onlylogout {
    height: 170px;
  }

  .AgencyNameDiv {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar-div {
    background: #262758;
    color: #f5f7fc;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .leftarrow {
    cursor: pointer;
  }

  .navbar-button {
    margin-right: 16px;
    background: #e6e9f0;
    position: relative;
    border-radius: 8px;
    border: none;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
  }

  .action-button {
    padding: 8px 30px;
  }

  .breadcrumb-div {
    font-weight: 600;
    font-size: 14px;
    width: 350px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .breadcrumb-item {
      font-weight: 400;
      color: #8990a3;
      margin-right: 5px;
      cursor: pointer;
    }

    .breadcrumb-item:before {
      display: none;
    }
  }

  .prev-search-container {
    display: none;
  }

  #search:focus ~ .prev-search-container {
    display: block;
  }

  .global-search:focus-within {
    input {
      background: #fff;
      width: 500px;
      height: 40px;
      outline: none;
      font-size: 14px;
      border: 1px solid #262758;
      border-radius: 8px;
      padding: 10px 48px;
    }
    .prev-search-container {
      display: block;
    }
  }
  .bellIcon {
    position: relative;
    width: 40px;
    background: #262758;
    height: 40px;
  }
  .notificationCounts {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    position: absolute;
    padding: 0px 4px;
    width: 16px;
    height: 16px;
    display: flex;
    right: -5px;
    align-items: center;
    justify-content: center;
    top: -4px;
    border-radius: 50%;
  }
  .bigCount {
    border-radius: 30px;
    right: -10px;
    padding: 6px 2px;
    width: 35px;
    height: 16px;
  }
  .Toastify__toast {
    color: #262758;
    font-size: 16px;
    border-radius: 8px !important;
    line-height: 20px;
    padding: 15px 20px;
    width: 100%;
    font-weight: 600;
    background: #f5f7fc !important;
    margin-left: 25px !important;
  }
  .Toastify__progress-bar {
    background: #262758;
  }
  .Toastify__toast > button > svg {
    margin-top: 8px;
  }
  @media (min-width: 576px) {
    position: fixed;
    justify-content: space-between;
    width: 100%;
    left: 0;
    top: 0;
    padding: 18px 32px;
    box-shadow: 0px -1px 0px 0px #0000001a inset;
    h2 {
      padding-left: 0px;
    }
  }

  @media (min-width: 1200px) {
    height: 72px;
    z-index: 2;
    padding: 16px 48px 16px 135px;
    .global-search {
      input {
        max-width: unset;
      }

      .close-button {
        margin: 0 27px 0 -35px;
      }
    }
    .navigationButtonsDiv {
      align-items: start;
      padding: 24px 12px 0 12px;
      border-bottom: 1px solid #e6e9f0;
    }
    .UserImgDiv {
      padding-bottom: 23px;
      border-bottom: 1px solid #e6e9f0;
    }
    .profileButtonDiv {
      border: none;
      background: #fff;
      margin-bottom: 24px;
      .ButtonImage {
        margin-right: 12px;
      }
    }
    .logOutButtonDiv {
      border: none;
      background: #fff;
      padding-top: 20px;
      margin-bottom: 24px;
      .ButtonLogOutImage {
        width: 30px;
        margin-left: 6px;
        margin-right: 12px;
      }
    }
    .dropDownAvatar {
      height: 40px;
      width: 40px;
    }
    .AgencyNameDiv {
      font-size: 14px;
      margin: 0 20px;
    }
    .dropDownAvatar {
      height: 40px;
      width: 40px;
    }
    .AgencyNameDiv {
      font-size: 14px;
      margin: 0 20px;
    }
  }

  .logo {
    height: 28px;
  }

  @media (min-width: 1900px) {
    height: 84px;
    padding: 22px 120px 22px 208px;

    .avatar-img,
    .avatar-div {
      height: 48px;
      width: 48px;
    }

    .navbar-button {
      height: 48px;
      min-width: 48px;
    }

    .global-search {
      input {
        width: 340px;

        :focus,
        &.filled {
          width: 632px;
          height: 48px;
        }
      }
    }
    .profile-drop {
      top: 55px;
      right: 10px;
      width: 371px;
      h6 {
        font-size: 20px;
      }
    }
    .dropDownAvatar {
      height: 52px;
      width: 52px;
    }
    .NotificationDropDown {
      width: 487px;
      height: 754px;
      .topNotificationContainer {
        padding: 16px 24px 10px 28px;
        h2 {
          text-align: start;
          font-size: 24px;
        }
      }
      .togglebuttons {
        margin-top: 32px;
      }
      .scrollContainer {
        max-height: 580px;
      }
      .shwBtnDiv {
        padding-top: 11px;
      }
    }
  }
  @media (min-width: 2800px) {
    height: 168px;
    padding: 44px 240px 44px 416px;

    .avatar-img,
    .avatar-div {
      height: 96px;
      width: 96px;
      font-size: 32px;
    }

    .navbar-button {
      height: 96px;
      min-width: 96px;
    }
  }

  @media (max-width: 576px) {
    .global-search {
      margin-bottom: 24px;
    }

    .head-mob {
      margin: 12px 0 0 20px;
    }
  }

  @media (max-width: 1200px) {
    .global-search {
      width: 100%;
      max-width: 577px;

      input {
        :focus,
        &.filled {
          width: 100%;
        }
      }
    }
    .notificationTop {
      width: 72%;
    }
  }
  @media (max-width: 576px) {
    .notificationTop {
      width: 90%;
    }
  }
`;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import { FilterContainer, MyBookDiv } from '../elements/styles';
import { AppDispatch } from '../../../store';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import {
  AccountsSliceState,
  getAccounts,
  onDeleteAccount,
  selectAllAccounts,
} from '../../../store/accounts/accountsSlice';
import {
  getCount,
  selectCount,
  updateCount,
} from '../../../store/book/bookSlice';
import Link from '../../../components/Link';
import AccountMobileCard from './components/AccountMobileCard';
import EmptyResults from '../components/Empty';
import AccountDrawer from './components/AccountAddDrawer';
import MobileTab from '../../../components/MobileTab';
import Table from '../../../components/Table';
import PaginationComponent from '../../../components/PaginationComponent';
import { IAccount } from '../../../interfaces/Account';
import { IOpportunity, IOption } from '../../../interfaces/Opportunity';
import { StyledTab } from '../../../styles/StyledTab';
import dropdown from '../../../assets/logo/dropdown.svg';
import search from '../../../assets/logo/search.svg';
import filter from '../../../assets/logo/filter.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import close from '../../../assets/logo/cross.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import plus from '../../../assets/logo/plus.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import AccountsFilter from './components/FilterDrawer';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import EditAccountDetails from '../../../pages/AccountDetails/EditAccountDetails';
import blackplus from '../../../assets/logo/blackplus.svg';

const Accounts = () => {
  const tabRef = useRef(null);
  const searchRef = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector(selectAllAccounts);
  const count = useSelector(selectCount);
  const loadingStatus = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.status
  );
  const accountCount = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.count
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [addAccount, setAddAccount] = useState('closed');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [producer, setProducer] = useState('');
  const [accountType, setAccountType] = useState<IOption[]>([]);
  const [accountStatus, setAccountStatus] = useState([]);
  const [hasPolicy, setHasPolicy] = useState<any>([]);
  const [scroll, setScroll] = useState(false);
  useOutsideClick(tabRef, setShowDropdown, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getAccounts({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          type: accountType.map((item) => item.value),
          minimal: undefined,
          contactUUID: undefined,
          opportunityUUID: undefined,
          accountStatusUUID: accountStatus.map((item: any) => item.value),
          hasPolicy: hasPolicy?.value,
          producerUUID: producer,
          successCB: (data: any) => {
            dispatch(updateCount({ type: 'accountsCount', count: data.count }));
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    sortBy,
    orderBy,
    debouncedSearchTerm,
    page,
    limit,
    hasPolicy,
    accountStatus,
    accountType,
    producer,
  ]);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const [showFilter, setShowFilter] = useState('closed');

  const [producerSearch, setProducerSearch] = useState('');

  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteAccount({
        uuid,
        successCB: () => {
          if (accounts.length === 1) {
            setPage(0);
          }
          dispatch(getCount());
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <MyBookDiv>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting account.'}
        />
      )}
      <button
        className='addButton d-sm-none'
        onClick={() => setAddAccount('open')}
      >
        <img src={plus} alt='add' />
      </button>
      <div className='d-sm-none mb-4'>
        <StyledTab ref={tabRef}>
          <Link
            to='/mybook/accounts'
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            Accounts
            <span className='count'>{count.accounts}</span>
            <img src={dropdown} alt='' />
          </Link>
          {showDropdown && <MobileTab />}
        </StyledTab>
      </div>

      <div className='d-flex justify-content-between'>
        <button
          className='addButton d-none d-sm-block'
          onClick={() => setAddAccount('open')}
          disabled={addAccount !== 'closed'}
        >
          Add account
        </button>
        <div className='book-filter d-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setPage(0);
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {accountType.length +
              accountStatus.length +
              (hasPolicy?.value ? 1 : 0) +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {accountType.length +
                  accountStatus.length +
                  (hasPolicy?.value ? 1 : 0) +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {accountType.length +
              accountStatus.length +
              (hasPolicy?.value ? 1 : 0) +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {accountType.length +
                  accountStatus.length +
                  (hasPolicy?.value ? 1 : 0) +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && accounts?.length > 0 ? (
            <>
              {accounts.map((item: IAccount) => (
                <AccountMobileCard
                  account={item}
                  key={item.uuid}
                  setShowDeleteModal={setShowDeleteModal}
                  setUUID={setUUID}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={accountCount}
              />
            </>
          ) : (
            <EmptyResults name='account' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            loadingStatus={loadingStatus}
            count={accountCount}
            setScroll={() => {
              setScroll(!scroll);
            }}
            className='mybook-table'
            name='account'
            columns={[
              {
                accessor: 'name',
                resizable: true,
                sortable: true,
                minWidth: 180,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Account name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center text-overflow-container'>
                    <b
                      onClick={() => {
                        navigate(`/account/${row.original.uuid}`);
                      }}
                      className='overflowed-text'
                    >
                      {row.original.name ? row.original.name : '--'}
                    </b>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.name ? row.original.name : '--'}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'type',
                Header: 'Account type',
                resizable: true,
                minWidth: 115,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center h-100'>
                    <div
                      className={`status ${
                        row.original.Policies?.length > 0
                          ? 'statusActive'
                          : 'statusInvited'
                      }`}
                    >
                      {row.original.Policies?.length > 0
                        ? 'Customer'
                        : 'Prospect'}
                    </div>
                  </div>
                ),
              },
              {
                accessor: 'insurance type',
                Header: 'Insurance type',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div>
                    {row?.original.AccountType?.name.charAt(0) +
                      row.original.AccountType?.name.slice(1).toLowerCase()}
                  </div>
                ),
              },

              {
                accessor: 'createdAt',
                resizable: true,
                sortable: true,
                minWidth: 155,
                Header: () => (
                  <div
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Created date</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'createdAt'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <Moment format='MM/DD/YYYY'>{row.original.createdAt}</Moment>
                ),
              },
              {
                accessor: 'opportunities',
                Header: '# of Opportunities',
                minWidth: 150,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div className='opportunity-div'>
                    <span className='opp-num'>
                      {row.original?.Opportunities?.length}
                    </span>
                    {row.original?.Opportunities?.map(
                      (opportunity: IOpportunity) =>
                        opportunity?.Product
                          ? opportunity?.Product?.name
                          : opportunity?.CustomProduct &&
                            opportunity?.CustomProduct?.name
                    ).join(', ')}
                  </div>
                ),
              },
              {
                accessor: 'producer',
                Header: 'Producer',
                resizable: true,
                minWidth: 100,
                Cell: ({ row }: { row: any }) => (
                  <div className='toolDiv info d-flex align-items-center h-100'>
                    {row.original.Agent?.User?.avatar ? (
                      <img
                        src={row.original.Agent?.User?.avatar}
                        alt=''
                        className='user-pic'
                      />
                    ) : (
                      <div className='user-pic'>
                        {row.original.Agent?.User?.firstName
                          ?.substring(0, 1)
                          .toUpperCase()}
                        {row.original.Agent?.User?.lastName
                          ?.substring(0, 1)
                          .toUpperCase()}
                      </div>
                    )}
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.Agent?.User?.firstName +
                          ' ' +
                          row.original.Agent?.User?.lastName}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'premium',
                Header: 'Premium',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <b>
                    $
                    {row.original.premium
                      ? row.original.premium.toLocaleString('en-US')
                      : 0}
                  </b>
                ),
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                width: window.innerWidth > 1900 ? 245 : 206,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <div
                      className='actionButton'
                      onClick={() => {
                        navigate(`/account/${row.original.uuid}`);
                      }}
                    >
                      View details
                    </div>
                    <ActionsDropdown
                      uuid={row.original.uuid}
                      setUUID={setUUID}
                      setShowEditModal={setShowEditModal}
                      scroll={scroll}
                      setShowDeleteModal={setShowDeleteModal}
                      setName={setName}
                      name={row.original.name}
                    />
                  </div>
                ),
              },
            ]}
            data={accounts}
          />
        </div>
      </div>
      {showEditModal !== 'closed' && (
        <EditAccountDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          uuid={UUID}
        />
      )}

      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='account'
          name={name}
        />
      )}
      {addAccount !== 'closed' && (
        <AccountDrawer show={addAccount} closeModal={setAddAccount} />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <AccountsFilter
            show={showFilter}
            closeModal={setShowFilter}
            producer={producer}
            setProducer={setProducer}
            accountType={accountType}
            setAccountType={setAccountType}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            accountStatus={accountStatus}
            setAccountStatus={setAccountStatus}
            hasPolicy={hasPolicy}
            setHasPolicy={setHasPolicy}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Accounts;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setShowDeleteModal,
  setUUID,
  scroll,
  setName,
  name,
}: {
  uuid: string;
  setShowEditModal: Function;
  setShowDeleteModal: Function;
  scroll: boolean;
  setUUID: Function;
  setName: Function;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const navigate = useNavigate();
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='action-dropContainer position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 130,
          }}
        >
          <div className='dropdown-div'>
            <div
              className='edit-div'
              onClick={() => {
                setShowEditModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
              }}
              tabIndex={0}
            >
              Edit <img src={edit} alt='bin' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='edit-div'
              style={{ paddingTop: '10px' }}
              onClick={() => navigate(`/account/${uuid}/activities`)}
              tabIndex={1}
            >
              Add activity <img src={blackplus} alt='plusIcon' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
                setName(name);
              }}
              tabIndex={2}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../../store';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import close from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import { StyledModal } from '../../../Pipeline/elements/styles';
import { fetchLineOfBusiness } from '../../../../api/lineOfBusiness';
import { ILineOfBusiness } from '../../../../interfaces/ProductCategory';
import {
  createProduct,
  selectAllProductCategories,
} from '../../../../store/productCategories/productCategoriesSlice';

const PolicyTypeSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter the name.')
    .max(25, 'Name length must be less than 25.'),
  type: Yup.object().shape({
    value: Yup.string().required('Please select a Policy category.'),
  }),
  lineOfBusiness: Yup.object().shape({
    value: Yup.string().required('Please select a Line of Business.'),
  }),
});

const AddPolicyType = ({
  showAddType,
  setShowAddType,
  setSuccessMessage,
  defaultType,
}: {
  showAddType: boolean;
  setShowAddType: Function;
  setSuccessMessage: Function;
  defaultType?: any;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const [lineOfBusiness, setLineOfBusiness] = useState<ILineOfBusiness[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PolicyTypeSchema),
  });

  const watchName = watch('name');

  useEffect(() => {
    if (defaultType)
      setValue('type', { label: defaultType.name, value: defaultType.uuid });
  }, []);

  const customProductCategories = useSelector(selectAllProductCategories);

  const onSubmit = (data: any) => {
    data.agencyUUID = uuid;
    data.action = 'createCustom';
    data.lineOfBusinessUUID = data.lineOfBusiness.value;
    data.productCategoryUUID = data.type?.value;

    dispatch(
      createProduct({
        data,
        successCB: () => {
          setShowAddType(false);
          setSuccessMessage(
            `${data.lineOfBusiness.label} policy type <b>"${data.name}"</b> has been added in the policy category <b>"${data.type.label}".</b>`
          );
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  useEffect(() => {
    fetchLineOfBusiness((data: ILineOfBusiness[]) => setLineOfBusiness(data));
  }, []);

  return (
    <StyledModal
      show={showAddType}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt=''
        src={close}
        className='closeButton'
        onClick={() => setShowAddType(false)}
      />
      <h2 className='mb-3'>Add policy type</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-3'>
          <SelectField
            options={customProductCategories
              .filter((item: any) => item.type === 'CUSTOM_PRODUCT_CATEGORY')
              .map((type) => {
                return { label: type.name, value: type.uuid };
              })}
            name='type'
            placeholder='Select policy category'
            control={control}
            required
          />
          {errors.type ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errors.type?.value?.message}
            </div>
          ) : null}
        </div>

        <div className='mb-3'>
          <SelectField
            options={lineOfBusiness.map((type) => {
              return {
                label:
                  type.name[0].toUpperCase() + type.name.slice(1).toLowerCase(),
                value: type.uuid,
              };
            })}
            name='lineOfBusiness'
            placeholder='Select line of business'
            control={control}
            required
          />
          {errors.lineOfBusiness ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errors.lineOfBusiness?.value?.message}
            </div>
          ) : null}
        </div>

        <InputField
          name='name'
          type='text'
          placeholder='Name of policy type'
          register={register}
          className={watchName && 'filled'}
          required
        />

        {errors.name ? (
          <div className='errorVal'>
            <img src={warning} alt='warning' className='warning' />
            {errors.name?.message}
          </div>
        ) : null}

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowAddType(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='primaryButton' type='submit'>
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default AddPolicyType;

import TopBar from '../../../components/TopBar';
import arrow from '../../../assets/logo/right-arrow.svg';
import editIcon from '../../../assets/logo/edit-icon.svg';
import { GoalContainer, GoalContent } from './styles';
import { useNavigate, useParams } from 'react-router';
import LocalStorageService from '../../../utils/LocalStorageService';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Goals from './components/Goals';
import UserGoal from './components/UserGoal';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import GoalEditModal from './components/GoalEditModal';
import {
  fetchAgencyGoal,
  fetchUserGoals,
  selectAgencyGoal,
  selectUserGoal,
} from '../../../store/goals/goalSlice';

export const customSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: '#f5f7fc',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',

    'white-space': 'nowrap',
    '@media only screen and (max-width: 575px)': {
      width: '130px',
      textOvefflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    backgroundColor: '#f5f7fc',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
    width: 'auto',
    'white-space': 'nowrap',
    marginTop: 0,
    right: 0,
    zIndex: 100,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    paddingRight: state.isFocused ? '8px' : 0,
    paddingLeft: state.isFocused ? 0 : '8px',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  }),
  control: (provided: object, state: any) => ({
    ...provided,
    '&:hover': {
      borderColor: 'none',
      backgroundColor: 'transparent',
    },
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: 'none',
  }),
  indicatorSeparator: () => ({ background: 'transparent' }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      color: 'inherit',
      fontWeight: 600,
      fontSize: 'inherit',
      paddingLeft: '2px',
      paddingRight: '2px',
      marginLeft: 0,
      marginRight: 0,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    padding: 0,
    height: '39px',
  }),
};

const GoalSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = LocalStorageService.getUser();
  const options = [
    { label: 'This month', value: 'month' },
    { label: 'This week', value: 'week' },
  ];
  const [goalFilter, setGoalFilter] = useState('month');
  const handleSelectGoal = (data: any) => {
    setGoalFilter(data.value);
  };
  const [isTab, setIsTab] = useState(window.innerWidth <= 1200);

  const [sortBy, setSortBy] = useState('rank');
  const [orderBy, setOrderBy] = useState('asc');

  const handleWindowSizeChange = () => {
    setIsTab(window.innerWidth <= 1200);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [leaderFilter, setLeaderFilter] = useState('month');
  const [goalType, setGoalType] = useState('PREMIUM');
  const [showEditModal, setShowEditModal] = useState('closed');
  const leaderboard = useSelector(selectUserGoal);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  useEffect(() => {
    dispatch(
      fetchUserGoals({
        limit: limit,
        offset: page * limit,
        duration: leaderFilter,
        agencyUUID: uuid || '',
        orderBy,
        sortBy,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderFilter, limit, page, sortBy, orderBy, showEditModal]);

  const handleSelectGoalType = (data: any) => {
    setGoalType(data.value);
  };

  useEffect(() => {
    if (user) {
      dispatch(
        fetchAgencyGoal({
          goalType: goalType,
          duration: goalFilter,
          agencyUUID: uuid || '',
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalFilter, goalType]);

  const handleSelectLeader = (data: any) => {
    setLeaderFilter(data.value);
  };
  const goal = useSelector(selectAgencyGoal);
  const percentage = goal?.policyValue
    ? goal.goalValue > goal.policyValue
      ? (goal.policyValue / goal.goalValue) * 100
      : 100
    : 0;

  return (
    <GoalContainer className='carrier-styledtab'>
      <TopBar>
        <div
          className='d-flex align-items-center justify-content-between'
          onClick={() =>
            navigate(
              user.UserRole.name === 'ADMIN'
                ? `/admin/agency/${uuid}`
                : `/settings`
            )
          }
          role='button'
        >
          <img src={arrow} className='leftArrow' alt='arrow' />
          <h2>Goal Settings</h2>
        </div>
      </TopBar>
      <GoalContent>
        <button
          className='editGoalButton d-flex '
          type='button'
          onClick={() => setShowEditModal('open')}
        >
          Edit goals <img src={editIcon} alt='edit' />
        </button>
        <Row className='goal-content-div'>
          <Col md={4}>
            <Goals
              options={options}
              handleSelectGoal={handleSelectGoal}
              customSelectStyles={customSelectStyles}
              isTab={isTab}
              percentage={percentage}
              goal={goal}
              handleSelectGoalType={handleSelectGoalType}
              goalTypeSelected={goalType}
            />
          </Col>
          <Col md={8}>
            <div className='list-container users-list'>
              <div className='d-flex justify-content-between head-div'>
                <h4>User goals</h4>
                <Select
                  className='dashboard-select'
                  defaultValue={options[0]}
                  options={options}
                  onChange={(e) => handleSelectLeader(e)}
                  styles={customSelectStyles}
                  isSearchable={false}
                />
              </div>
              <div>
                <UserGoal
                  leaders={leaderboard}
                  setSortBy={setSortBy}
                  setOrderBy={setOrderBy}
                  sortBy={sortBy}
                  orderBy={orderBy}
                  limit={limit}
                  setLimit={setLimit}
                  setPage={setPage}
                  page={page}
                />
              </div>
            </div>
          </Col>
        </Row>
      </GoalContent>

      {showEditModal !== 'closed' && (
        <GoalEditModal show={showEditModal} closeModal={setShowEditModal} />
      )}
    </GoalContainer>
  );
};

export default GoalSettings;

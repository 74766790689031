import Select from 'react-select';
import { CustomValueContainer } from '../SelectField';

interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  options: any[];
  values: any;
  onSetItem: Function;
  isFirst?: boolean;
  isLast?: boolean;
}

export const FirstSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    margin: '4px 0',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
    borderRadius: '8px 0px 0px 8px',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px 0px 0px 8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#D1D6E5' : '#FFF',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      border: '1px solid #cad9f3',
      boxShadow: '0px 1px 4px rgba(35, 42, 64, 0.2)',
      borderRadius: '8px 0px 0px 8px',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '8px 0px 0px 8px',
    height: '48px',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '8px',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '-5px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
  }),
};

export const SelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    margin: '4px 0',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
    borderRadius: '0px',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,

    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
    borderRadius: '0px',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#D1D6E5' : '#FFF',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      borderRadius: '0px',
      border: '1px solid #cad9f3',
      boxShadow: '0px 1px 4px rgba(35, 42, 64, 0.2)',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '0px',
    height: '48px',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '8px',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '-5px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
  }),
};

export const LastSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    margin: '4px 0',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
    borderRadius: '0px 8px 8px 0px',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,

    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#D1D6E5' : '#FFF',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      border: '1px solid #cad9f3',
      boxShadow: '0px 1px 4px rgba(35, 42, 64, 0.2)',
      borderRadius: '0px 8px 8px 0px',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '0px 8px 8px 0px',
    height: '48px',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '8px',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '-5px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
  }),
};

const SelectField = ({
  name,
  placeholder,
  options,
  values,
  onSetItem,
  isFirst,
  isLast,
  ...props
}: IProps) => {
  return (
    <Select
      styles={
        !isFirst && !isLast
          ? SelectStyles
          : isFirst
          ? FirstSelectStyles
          : LastSelectStyles
      }
      options={options}
      placeholder={placeholder}
      components={{ ValueContainer: CustomValueContainer }}
      isClearable
      {...props}
      value={
        options.find(
          (option: { value: string; label: string }) =>
            option.value === values[name]
        )
          ? options.find(
              (option: { value: string; label: string }) =>
                option.value === values[name]
            )
          : ''
      }
      onChange={(val) => onSetItem(name, val?.value)}
    />
  );
};

export default SelectField;

import {
  accounts,
  accountsActive,
  contacts,
  contactsActive,
  policies,
  policiesActive,
} from '../assets/nav-icons';

export const MY_BOOK_MENU_LIST = [
  {
    name: 'Leads',
    path: '/mybook/leads',
    activeIcon: contactsActive,
    inactiveIcon: contacts,
    isMyBook: true,
    isShowDropdown: false,
    order: 1,
  },
  {
    name: 'Opportunities',
    path: '/mybook/opportunities',
    activeIcon: policiesActive,
    inactiveIcon: policies,
    isMyBook: true,
    isShowDropdown: false,
    isActiveState: 'isOpportunity',
    order: 2,
  },
  {
    name: 'Accounts',
    path: '/mybook/accounts',
    activeIcon: accountsActive,
    inactiveIcon: accounts,
    isMyBook: true,
    isShowDropdown: false,
    isActiveState: 'isAccount',
    order: 3,
  },
  {
    name: 'Policies',
    path: '/mybook/policies',
    activeIcon: policiesActive,
    inactiveIcon: policies,
    isMyBook: true,
    isShowDropdown: false,
    isActiveState: 'isPolicy',
    order: 4,
  },
  {
    name: 'Contacts',
    path: '/mybook/contacts',
    activeIcon: contactsActive,
    inactiveIcon: contacts,
    isMyBook: true,
    isShowDropdown: false,
    isActiveState: 'isContact',
    order: 5,
  },
] as const;

export const QUICK_MENU_LIST_AGENT = [
  {
    name: 'Add Lead',
    icon: contacts,
    order: 1,
  },
  {
    name: 'Add Contact',
    icon: contacts,
    order: 2,
  },
  {
    name: 'Add Account',
    icon: accounts,
    order: 3,
  },
  {
    name: 'Add Opportunity',
    icon: policies,
    order: 4,
  },
  {
    name: 'Add Quote',
    icon: accounts,
    order: 5,
  },
  {
    name: 'Add Policy',
    icon: policies,
    order: 6,
  },
] as const;

export const QUICK_MENU_LIST_ADMIN = [
  {
    name: 'Add Agency',
    icon: '',
    order: 1,
  },
] as const;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAccount,
  fetchDashboard,
  fetchFunnelGraph,
  fetchGoal,
  fetchLeaders,
  fetchPolicy,
  fetchsalesGraph,
} from '../../api/dashboard';

export type DashboardSliceState = {
  status: string;
  dashboard?: any;
  account?: any;
  policy?: any;
  goal?: any;
  salesGraph?: any;
  funnelGraph?: any;
  leaderBoard?: any;
};

const initialState: DashboardSliceState = {
  status: 'idle',
  dashboard: undefined,
  account: undefined,
  policy: undefined,
  goal: undefined,
  salesGraph: undefined,
  funnelGraph: undefined,
  leaderBoard: undefined,
};

export const getDashboard = createAsyncThunk(
  'dashboard/fetchDashboard',
  async () => {
    const response = await fetchDashboard();
    return response.data;
  }
);

export const getPolicy = createAsyncThunk(
  'dashboard/getPolicy',
  async ({ filter, showAgency }: { filter: string; showAgency?: string }) => {
    const response = await fetchPolicy(filter, showAgency);
    return response.data;
  }
);

export const getAccount = createAsyncThunk(
  'dashboard/getAccount',
  async ({ filter, showAgency }: { filter: string; showAgency?: string }) => {
    const response = await fetchAccount(filter, showAgency);
    return response.data;
  }
);

export const getGoal = createAsyncThunk(
  'dashboard/getGoal',
  async ({
    filter,
    goalType,
    showAgency,
  }: {
    filter: string;
    goalType: string;
    showAgency?: string;
  }) => {
    const response = await fetchGoal(filter, goalType, showAgency);
    return response.data;
  }
);

export const getLeaders = createAsyncThunk(
  'dashboard/getLeaders',
  async ({
    duration,
    showAgency,
    sortBy,
    orderBy,
  }: {
    duration: string;
    showAgency: string;
    sortBy: string;
    orderBy: string;
  }) => {
    const response = await fetchLeaders(duration, showAgency, sortBy, orderBy);
    return response.data;
  }
);

export const getSalesGraph = createAsyncThunk(
  'dashboard/getSalesGraph',
  async ({ type, showAgency }: { type: string; showAgency?: string }) => {
    const response = await fetchsalesGraph(type, showAgency);
    return response.data;
  }
);

export const getFunnelGraph = createAsyncThunk(
  'dashboard/getFunnelGraph',
  async ({
    pipelineUUID,
    duration,
    showAgency,
  }: {
    pipelineUUID: string;
    duration: string;
    showAgency?: string;
  }) => {
    const response = await fetchFunnelGraph(pipelineUUID, duration, showAgency);
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    updateLeaderboardGoals: (state, action) => {
      const index = state.leaderBoard.findIndex(
        (item: any) => item.agentUUID === action.payload.agentsWithGoals[0].uuid
      );
      state.leaderBoard[index].goalPolicy =
        action.payload.agentsWithGoals[0].Goals[0].value;
      state.leaderBoard[index].goalPremium =
        action.payload.agentsWithGoals[0].Goals[1].value;
      state.leaderBoard[index].premium =
        (state.leaderBoard[index].policyPremium * 100) /
        state.leaderBoard[index].goalPremium;
      state.leaderBoard[index].policy =
        (state.leaderBoard[index].policyCount * 100) /
        state.leaderBoard[index].goalPolicy;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dashboard = action.payload;
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.account = action.payload;
      })
      .addCase(getAccount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAccount.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getGoal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.goal = action.payload;
      })
      .addCase(getGoal.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getGoal.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getLeaders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.leaderBoard = action.payload;
      })
      .addCase(getLeaders.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLeaders.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getPolicy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.policy = action.payload;
      })
      .addCase(getPolicy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getPolicy.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getSalesGraph.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.salesGraph = action.payload;
      })
      .addCase(getSalesGraph.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSalesGraph.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getFunnelGraph.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const sortedData = action.payload.data.sort((a: any, b: any) =>
          a.order > b.order ? 1 : -1
        );
        state.funnelGraph = { data: sortedData };
      })
      .addCase(getFunnelGraph.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFunnelGraph.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, updateLeaderboardGoals } = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectDashboard = (state: { dashboard: DashboardSliceState }) => {
  return state.dashboard.dashboard;
};

export const selectPolicy = (state: { dashboard: DashboardSliceState }) => {
  return state.dashboard.policy;
};

export const selectAccount = (state: { dashboard: DashboardSliceState }) => {
  return state.dashboard.account;
};

export const selectGoal = (state: { dashboard: DashboardSliceState }) => {
  return state.dashboard.goal;
};

export const selectSalesGraph = (state: { dashboard: DashboardSliceState }) => {
  return state.dashboard.salesGraph;
};

export const selectLeaderBoard = (state: {
  dashboard: DashboardSliceState;
}) => {
  return state.dashboard.leaderBoard;
};

export const selectFunnelGraph = (state: {
  dashboard: DashboardSliceState;
}) => {
  return state.dashboard.funnelGraph;
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCount } from '../../api/book';

export type SliceState = {
  status: string;
  leadsCount: number;
  contactsCount: number;
  accountsCount: number;
  opportunitiesCount: number;
  policiesCount: number;
};

const initialState: SliceState = {
  status: 'idle',
  leadsCount: 0,
  accountsCount: 0,
  contactsCount: 0,
  opportunitiesCount: 0,
  policiesCount: 0,
};

export const getCount = createAsyncThunk('book/getCount', async () => {
  const response = await fetchCount();
  return response.data;
});

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    updateCount: (state: any, action) => {
      state[action.payload?.type] = action.payload?.count;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.leadsCount = action.payload.leads;
        state.contactsCount = action.payload.contacts;
        state.accountsCount = action.payload.accounts;
        state.opportunitiesCount = action.payload.opportunities;
        state.policiesCount = action.payload.policies;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, updateCount } = bookSlice.actions;

export default bookSlice.reducer;

export const selectCount = (state: { book: SliceState }) => {
  return {
    leads: state.book.leadsCount,
    contacts: state.book.contactsCount,
    accounts: state.book.accountsCount,
    policies: state.book.policiesCount,
    opportunities: state.book.opportunitiesCount,
  };
};

import styled from '@emotion/styled';
import circle from '../../../assets/logo/circle.svg';
import tick from '../../../assets/logo/success.svg';

export const MyBookDiv = styled.div`
  padding-top: 20px;

  .mybook-content {
    margin-top: 20px;
  }
  .overflowed-text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .HeadNavigator {
    position: fixed;
    top: 16px;
  }
  .searchField {
    padding: 6px;
    margin: 10px 10px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .search-Field {
    padding: 6px;
    margin: 10px 4px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .inputSearchField {
    outline: none;
    border: none;
    background: #e6e9f0;
  }
  .filterField {
    padding: 8px;
    margin-right: 8px;
  }
  .myteam-content {
    padding: 0 17px 60px 15px;
  }
  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    width: 48px;
    height: 48px;
    right: 20px;

    background: #262758;
    border-radius: 50%;
    z-index: 1;
  }
  .hide-small {
    display: none;
  }
  .info {
    cursor: pointer;
    position: relative;
    .tooltiptext {
      visibility: hidden;
      width: 100px;
      height: 38px;
      background: #191919;
      color: #d1d6e5;
      padding: 0px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 0px;
      right: 15px;

      &.tooltip-producer {
        left: 0;
        line-height: 14px;
        right: unset;
        display: flex;
        align-items: center;
        padding: 0 12px;
        width: 120px;
        top: 40px;
        ::after {
          top: -9px;
          left: 16px;
          right: unset;
        }
      }
      .tooltipInnertext {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        line-height: 23px;
      }

      ::after {
        content: ' ';
        position: absolute;
        top: 0px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .addButton {
      padding: 8px 25px;
      background: #ecf4f9;
      border-radius: 8px;
      border: 0;
      font-weight: 600;
      color: #262758;
    }
    .add-Button {
      padding: 8px 25px;
      background: #ecf4f9;
      border-radius: 8px;
      border: 0;
      font-weight: 600;
      color: #262758;
      margin-bottom: 14px;
      width: 134px;
    }
    .myteam-content {
      padding: 80px 40px 0 40px;
    }
    .booksearch {
      input {
        background: #e6e9f0;
        border: 1px solid #262758;
        outline: none;
        border-radius: 8px;
        width: 384px;
        font-size: 14px;
        height: 100%;
        padding: 10px 48px;
      }
      .searchbutton {
        margin-right: -28px;
        position: relative;
        z-index: 1;
      }

      .closebutton {
        margin: 0 20px 0 -40px;
        cursor: pointer;
      }
      .sort {
        margin-left: 20px;
      }
    }

    .Divider {
      border-right: 1.6px solid #d1d6e5;
      margin: 0 12px;
      height: 37px;
    }
    .bookshrinked {
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;

      :hover {
        background: #e6e9f0;
      }
    }
    .toolDiv {
      background: #fff;
      border: none;
    }
    .info {
      cursor: pointer;
      position: relative;
      .tooltiptext {
        visibility: hidden;
        width: 100px;
        height: 38px;
        background: #191919;
        color: #d1d6e5;
        padding: 0px 24px;
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        position: absolute;
        z-index: 1;
        top: 0px;
        right: 15px;

        &.tooltip-producer {
          left: 45px;
          line-height: 14px;
          right: unset;
          display: flex;
          align-items: center;
          padding: 0 12px;
          width: 120px;
          top: 10px;
          ::after {
            top: 12px;
            left: -5px;
            right: unset;
            transform: rotate(-90deg);
          }
        }
        .tooltipInnertext {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
          overflow: hidden;
          line-height: 23px;
        }

        ::after {
          content: ' ';
          position: absolute;
          top: 0px;
          right: 12px;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #191919 transparent;
        }
      }

      :hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .myteam-content {
      padding: 80px 60px 0 120px;
    }
    .add-button {
      margin-bottom: 20px;
      width: 149px;
    }
    .sort {
      margin-left: 15px;
    }
  }
  @media screen and (min-width: 1900px) {
    padding-top: 30px;

    .mybook-content {
      margin-top: 28px;
    }
    .myteam-content {
      padding: 108px 120px 0 180px;
    }
    .add-Button {
      margin-bottom: 16px;
      height: 48px;
      width: 181px;
    }
    .hide-small {
      display: block;
    }
    .hide-large {
      display: none;
    }
  }
`;

export const EmptyDiv = styled.div`
  height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #5a5f70;

  h2 {
    font-size: 24px;
    color: #191919;
  }
`;

export const StyledCard = styled.div`
  box-shadow: 0px 1px 4px 0px #26275833;
  padding: 16px 20px;
  margin-bottom: 8px;
  border-radius: 16px;
  font-size: 12px;

  h6 {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-div {
    background: #f5f7fc;
    padding: 4px 12px;
    border-radius: 16px;
    color: #191919;
    font-weight: 600;
  }

  .info-div {
    margin: 14px 0 20px 0;
  }

  .type-div {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #e6e9f0;
    font-weight: 600;
    text-transform: capitalize;
  }

  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-item {
    width: 32px;
    height: 32px;
    background: #ecf4f9;
    border-radius: 8px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vector-div {
    position: relative;

    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 0px;
      bottom: -65px;
      font-size: 14px;
      z-index: 1;
      width: 160px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .delete-div {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }

      .edit-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .editDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
    }
    .drop-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 0px;
      bottom: -98px;
      font-size: 14px;
      z-index: 1;
      width: 194px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .deletediv {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }

      .editdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .junkdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
    }
    .drop-Div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 0px;
      bottom: -72px;
      z-index: 1;
      width: 217px;
      cursor: pointer;
      line-height: 22px;
      div {
        padding: 4px 0;
      }

      .agent-drop {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .active-drop {
        color: #3d8a68;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
      .inactive-drop {
        color: #cc0000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
      .agentdrop-mob {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .disableStyle {
        background: #f5f7fc;
        color: #5c698c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
        border-radius: 8px;
        cursor: not-allowed;
      }
    }
  }

  .actionButton {
    font-weight: 600;
    background: #ecf4f9;
    text-align: center;
    padding: 5px;
    border-radius: 8px;
    color: #262758;
    height: 32px;
    font-size: 14px;
    margin-right: 16px;
    width: 168px;
  }

  .qualifyButton {
    width: 80%;
  }

  .status {
    width: 84px;
    text-align: center;
    border-radius: 16px;
    font-size: 12px;
    padding: 4px 0;
    font-weight: 600;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }

  .user-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #262758;
    color: #f5f7fc;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter-area {
    width: 100%;
    justify-content: space-between;
  }
`;

export const LeadDetailsDiv = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  .details-modal {
    .drawer-container {
      padding: 0;
      .drawer-container {
        padding: 24px 20px;
      }
    }
    .name-text-overflow {
      width: calc(100% - 100px);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;
      vertical-align: bottom;
    }
    @media screen and (min-width: 576px) {
      .drawer-container {
        .drawer-container {
          padding: 35px 60px;
        }
      }
    }
  }
  .leadQualifyMobButton {
    width: 268px;
    height: 32px;
    font-size: 14px;
  }

  .content-div {
    padding: 0;
    width: 100vw;

    @media screen and (min-width: 1200px) {
      width: 93vw;
    }
  }

  @media screen and (max-width: 1200px) {
    @media screen and (min-width: 576px) {
      .modal-content-div {
        width: 360px;
      }
    }
    .modal-content-div {
      position: relative;
      .header-div {
        padding: 0 0 30px 0;

        .profile-pic {
          height: 56px;
          width: 56px;
          font-size: 16px;
        }
      }
      .crossbutton {
        position: absolute;
        right: 0;
        margin: 0;
      }
    }

    .header-div {
      padding: 24px 13px;

      .profile-pic {
        width: 32px;
        height: 32px;
        font-size: 12px;
      }
    }

    .options-div {
      width: 100%;
      padding: 24px 33px;
    }
  }

  .crossbutton {
    margin-left: 30px;
    height: 32px;
    width: 32px;
    border: none;
    background: none;
  }

  .qualifyArrow {
    margin-left: 25px;
  }

  @media screen and (max-width: 576px) {
    .content-div .timeline {
      padding-left: 20px;
      padding-right: 20px;
      .activity-scroll {
        max-height: calc(100vh - 265px);
        overflow-x: hidden;
      }
      .DocNameField {
        max-width: 121px;
        width: unset;
      }
      .addTaskButton {
        padding-left: 0;
        padding-right: 0;
      }
      .ButtonsContainer {
        margin-top: 8px;
      }
      .task-edit-container {
        .field,
        .fields {
          margin-top: 8px;
          div,
          .input-field {
            border-radius: 8px;
          }
        }
      }
    }
  }
`;

export const StyledAddModal = styled.div`
  width: 360px;
  &.policyModal {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    &.policyModal {
      width: 100%;
    }
  }

  .navButtons {
    border-bottom: 1px solid #e6e9f0;
  }

  h2 {
    margin-bottom: 0;
    font-size: 20px;
  }

  .form-title {
    margin-bottom: 20px;
  }

  .entire-div {
    width: 100%;
    padding: 0px 0px;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 14px;
    top: 20px;
    border: none;
  }
  .backArrow {
    width: 20px;
    filter: invert(1);
    margin-bottom: 5px;
    transform: rotate(180deg);
  }
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
    &.emailError {
      color: #000;
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .bigfields {
    width: 82vw;
    height: 134px;
    padding: 16px 16px;
    max-height: 198px;
    margin: 0 auto;
    border: 1px solid #e6e9f0;
    border-radius: 8px;
    margin-top: 20px;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
    &:focus,
    &:active {
      border: 1px solid #3c498a;
      outline-offset: 0px;
      outline: none;
    }
  }
  .scroll-bar {
    height: 90vh;
    width: 92vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 230px;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 20px;
  }
  .personal {
    width: 56px;
  }
  .commercial {
    width: 92px;
    margin-left: 40px;
  }
  .contact-info {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
  }
  .business-info {
    margin-top: 20px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .removeButton {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
  }

  .business-info-address {
    font-size: 16px;
    margin-top: 30px;
  }
  .fields {
    width: 320px;
    width: 100%;
    margin-top: 16px;
    color: #262758;
    .search-group {
      .input-placeholder {
        left: 18px;
      }
      .input-field {
        padding: 18px 25px 10px 18px;
      }
    }
  }
  .fields::placeholder {
    color: #262758;
  }
  .fields-small {
    margin-top: 16px;
  }
  .fields-medium {
    width: 200px;
    margin-top: 16px;
  }
  .fields-xsmall {
    margin-top: 16px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    padding: 10px 20px;
  }
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
    &.emailError {
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .Successcard {
    position: fixed;
    width: 528px;
    height: 92px;
    top: 100px;
    right: 0;
    transition: all 0.3s ease-in-out;
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }

  .lead-head {
    font-weight: 600;
    font-size: 12px;
    padding: 8px 40px 8px 16px;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 16px;
    margin-left: 0px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }

  .nextButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    min-width: 188px;
    height: 48px;
    padding: 0 16px;
    margin-left: auto;
    border: none;
    &:disabled {
      background: #d1d6e5;
    }
    img {
      margin-left: 8px;
    }
  }

  .naVigatorbuttons {
    cursor: pointer;
    margin-bottom: -2px;
    border-bottom: 4px solid transparent;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    border-bottom: 4px solid #262758;
    cursor: pointer;
    margin-bottom: -2px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .business-container {
    border-top: 1px solid #e6e9f0;
    margin-top: 25px;
  }
  .naVigatorbuttons:disabled {
    border-bottom: none;
    font-weight: 400;
  }
  .naVigatorbuttons:hover {
    border-bottom: 4px solid #262758;
    font-weight: 600;
    :disabled {
      border-bottom: none;
      font-weight: 400;
      font-color: none;
    }
  }
  .LeadSourcefield {
    font-size: 12px;
    width: 240px;
    height: 32px;
    margin-left: 1px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .navButton {
    position: fixed;
    height: 58px;
    top: 105px;
  }

  .navButtons {
    .secondaryButton {
      width: 58px;
      padding-left: 0;
      padding-right: 0;
    }

    .commercial-text {
      margin-left: 24px;
      .secondaryButton {
        width: 83px;
      }
    }
  }
  .textarea {
    width: 90vw;
    margin-top: 44px;
  }
  .addNotesButton {
    margin-top: 40px;
    border: none;
    background: #fff;
  }
  .removeIcon {
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg)
      saturate(600%) contrast(0.8);
  }
  .addAnotherBusiness {
    margin-right: 15px;
  }
  .smallAddbutton {
    margin-right: 10px;
    margin-top: -2px;
  }
  .search-group {
    .input-placeholder {
      font-size: 16px;
      left: 16px;
    }
  }

  &.policyModal {
    .d-sm-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .small-div.d-sm-flex {
      margin-left: 0;
      margin-right: 15px;
    }
    .fields {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media screen and (max-width: 720px) {
      .small-div {
        width: 240px;
      }
      .small-div .fields-small:first-child {
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 576px) {
      .d-sm-flex {
        margin-left: 0;
        margin-right: 0;
      }
      .small-div.d-sm-flex {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      .small-div .fields-small:first-child {
        margin-right: 0;
      }
      .fields {
        margin-left: 0;
        margin-right: 0;
      }
      .bigfields {
        max-width: 100%;
      }
      .fields-small,
      .small-div {
        max-width: 100%;
      }
    }
  }
  @media screen and (min-width: 576px) {
    width: 100%;
    .fields {
      width: 420px;
    }
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 159px;
        margin-left: 20px;
      }
    }
    .small-div {
      width: 420px;
    }
    .buttonsDiv {
      padding: 15px 50px 15px 0px;
    }

    .contact-info {
      margin-top: 24px;
    }

    .navButton {
      top: 78px;
    }
    .fields-small {
      width: 200px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .fields-xsmall {
      width: 104px;
    }
    .fields-medium {
      width: 240px;
    }
    .cross-btn {
      right: 40px;
      top: 26px;
    }
    .entire-div {
      width: 100%;
      padding: 0px;
    }
    .scroll-bar {
      height: 68vh;
      padding-bottom: 100px;
      padding-right: 7px;
      min-width: 100%;
    }
    .commercial {
      width: 91px;
      margin-left: 24px;
    }
    .bigfields {
      width: 78vw;
      margin-top: 34px;
    }
    .textarea {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 883px;
    .fields {
      width: 420px;
    }
    .bigfields {
      width: 99.9%;
      height: 136px;
      max-height: 198px;
      border: 1px solid #e6e9f0;
      margin-top: 38px;
    }

    h2 {
      font-size: 24px;
    }

    .navButton {
      top: 92px;
    }
    .entire-div {
      width: 100%;
      margin: 0 auto;
      padding: 0px;
    }
    .small-div {
      width: 420px;
    }
    .fields-small {
      width: 200px;
    }
    .fields-medium {
      width: 298px;
      margin-top: 16px;
    }
    .fields-xsmall {
      width: 166px;
      margin-top: 16px;
    }

    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 41px;
      top: 24px;
    }
    .scroll-bar {
      height: 78vh;
      width: 100%;
    }
    .buttonsDiv {
      bottom: 0;
      padding: 12px 50px 12px 0px;
    }
    &.policyModal {
      .fields-small {
        width: 50%;
      }
      .fields-small:first-child {
        margin-right: 30px;
      }
      .small-div.d-sm-flex {
        margin-left: 15px;
        margin-right: 15px;
        width: 420px;
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1900px) {
    .policyAddformSpec {
      width: 900px !important;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 1200px;

    h2 {
      font-size: 28px;
    }

    .navButtons {
      .secondaryButton {
        font-size: 16px;
        width: 66px;
      }

      .commercial-text {
        .secondaryButton {
          width: 96px;
        }
      }
    }

    .topdiv {
      width: 100%;
    }
    .navButton {
      top: 102px;
    }
    &.policyModal {
      .small-div.d-sm-flex {
        width: 578px;
      }
    }
    .cross {
      width: 32px;
    }
    .personal {
      width: 56px;
    }
    .scroll-bar {
      height: 78vh;
      width: 100%;
    }
    .scrollPolicy {
      padding-right: 180px;
      margin-right: 59px;
    }
    .commercial {
      width: 90px;
      margin-left: 28px;
    }
    .contact-info {
      font-size: 20px;
      line-height: 28px;
      margin-top: 32px;
    }
    .business-info {
      font-size: 20px;
      line-height: 28px;
    }
    .fields {
      width: 576px;
      margin-top: 20px;
    }
    .small-div {
      width: 576px;
    }
    .fields-small {
      width: 278px;
      margin-top: 20px;
    }
    .fields-medium {
      width: 416px;
      margin-top: 20px;
    }
    .fields-xsmall {
      width: 200px;
      margin-top: 20px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 60px;
      top: 36px;
    }
    .buttonsDiv {
      padding: 18px 80px 18px 0px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
    }
    .entire-div {
      width: 1200px;
      margin: 0 auto;
      padding: 0px;
    }
    .heading {
      font-size: 20px;
    }
    .bigfields {
      width: 99.9%;
      margin-top: 40px;
    }
    .textarea {
      margin-top: 52px;
    }
    .addAnotherBusiness {
      margin-right: 10px;
    }

    .navButtons {
      margin-top: 10px;
    }
  }
`;

export const SelectedContactCard = styled.div`
  height: 56px;
  background: #f5f7fc;
  border-radius: 8px;
  padding: 0 12px;
  margin-top: 8px;

  .name-text-overflow {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact-card-div {
    width: calc(100% - 80px);
  }

  &.account-details {
    font-size: 14px;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 24px;
  }

  .profile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    border-radius: 46px;
    margin-right: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  img.profile-pic {
    background: none;
  }
  img.close-button {
    margin-left: auto;
  }
  .user-details {
    display: flex;
    margin: 0px;
    padding: 0px;

    li {
      font-size: 12px;
      line-height: 16px;
      width: 99px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        margin-right: 5px;
        border-right: 1px solid #262758;
        padding-right: 5px;
      }
    }
  }
`;

export const ProgressInfo = styled.div`
  text-align: center;
  margin-right: 35px;
  margin-left: 35px;
  margin-top: 40px;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
  p {
    margin: 0;
    white-space: nowrap;
  }
  .progress {
    width: 20px;
    height: 20px;
    background: #262758;
    border-radius: 50px;
    margin: auto;
    margin-bottom: 8px;
  }
  .completed {
    width: 20px;
    height: 20px;
    background: url(${tick});
    border-radius: 50px;
    margin: auto;
    margin-bottom: 8px;
  }
  .mid-line {
    width: 0px;
    height: 80px;
    border: 1px solid #262758;
    margin: auto;
    margin: 8px auto;
  }
  .next-level {
    width: 8px;
    height: 8px;
    background: #cad9f3;
    border-radius: 50px;
    margin: auto;
    margin-bottom: 8px;
  }
`;

export const PolicyInfo = styled.div`
  .radio-container {
    padding: 19px 15px;

    background: #ffffff;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    width: 100%;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }

    .form-check-input {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .form-check-input:checked[type='radio'] {
      background-image: url(${circle});
      background-color: #fff;
      box-shadow: none;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    float: right;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #58c070;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #58c070;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .documnetsAdd {
    padding-bottom: 32px;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e6e4e4;
  }
`;

export const FilterContainer = styled.div`
  z-index: 10;
  position: fixed;
  .search-group .input-container {
    border: none;
    background: #f5f7fc;
    height: 38px;
    label {
      transform: translate(0, 8px);
    }
    &:focus-within {
      label {
        display: none;
      }
    }
    &.filled,
    &.prefilled {
      label {
        display: none;
      }
    }

    @media screen and (min-width: 1900px) {
      height: 48px;
    }
    @media only screen and (min-width: 2800px) : {
      height: 128px;
    }
    .input-field {
      background: #f5f7fc !important;
      padding-top: 10px;
      padding-left: 10px;
      &:focus,
      &:hover {
        border: none !important;
      }
    }
    &.filled {
      border: none;
    }
  }
  .search-group {
    .search-button,
    .close-button,
    .drop-button {
      top: calc(50% - 14px);
    }
  }
`;

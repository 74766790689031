import styled from '@emotion/styled';
export const PolicyContainer = styled.div`
  margin-top: 15px;
  background: #fff;

  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
  }
  @media screen and (min-width: 576px) {
    background: #fff;

    .leftArrow {
      margin-left: 15px;
    }
  }
`;

export const PolicyContent = styled.div`
  padding: 23px 10px 0px 0;
  .tertiaryButton {
    background: #ecf4f9;
    color: #262758;
    margin-left: 20px;
    img {
      filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(3197%)
        hue-rotate(188deg) brightness(102%) contrast(88%);
    }
  }
  .addPolicyButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
    width: 244px;
  }
  .cards-container {
    margin-top: 20px;
    padding: 2px 4px 58px 18px;
    max-height: 99vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }

    .type-card {
      background: #ffffff;
      color: #000;
      border-radius: 8px;
      width: 100%;
      margin-bottom: 16px;
      padding: 16px;
      box-shadow: 0px 1px 4px 0px #26275833;

      h6 {
        font-weight: 600;
        margin: 0;
      }
    }

    .reason-card {
      margin: 0 0 12px 0;
      padding: 16px 20px 16px 24px;
      height: 64px;

      .tertiaryButton {
        margin-right: 20px;
      }

      button {
        font-size: 14px;
        height: 32px;
        width: 119px;
      }
    }

    .title-div {
      padding-bottom: 16px;
      border-bottom: 1px solid #e6e9f0;
      height: 49px;
      .topEditDeletediv {
        margin-right: -8px;
      }
    }

    .card-icon {
      margin: 0 8px;
      padding: 6px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        background: #e6e9f0;
        cursor: pointer;
      }
    }

    .edit-icon {
      border-right: 1px solid #d1d6e5;
    }
  }

  .source-container {
    padding: 12px 0 20px 0;
  }

  .source-content {
    height: 211px;
    overflow: auto;
    padding-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
    .empty {
      padding-left: 40%;
      color: #000000;
    }
  }
  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    border: none;
    width: 48px;
    height: 48px;
    right: 20px;
    background: #262758;
    border-radius: 50%;
    z-index: 3;

    .dropUp {
      position: absolute;
      width: 227px;
      bottom: 50px;
      z-index: 5;
      padding: 10px 12px 5px 5px;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;

      ul {
        padding: 8px 12px;
        border-bottom: 1px solid #e6e9f0;
        border-radius: 8px;
        margin: 0;
        font-weight: 600;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .addButton-background {
    position: fixed;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(35, 42, 64, 0.4);
    top: 0;
    z-index: 3;
  }
  .dropOpen {
    .addButton-img {
      transform: rotate(45deg);
    }
    img {
      filter: invert(1);
    }
    background: #f5f7fc;
  }

  .source-card {
    margin-bottom: 4px;
    background: #f5f7fc;
    padding: 8px 20px;
    border-radius: 8px;
    height: 48px;

    .source-title {
      font-size: 14px;
    }
    .source-type {
      text-transform: capitalize;
      color: #5a5f70;
      font-size: 12px;
    }
    .editDeleteIcon {
      margin-right: -18px;
    }
  }
  .addSourceButton {
    font-weight: 600;
    width: 100%;
    background: #e6e9f0;
    color: #262758;
    border-radius: 8px;
    border: 0;
    padding: 8px 20px;
    :hover {
      background: #f5f7fc;
    }
  }
  .searchFilterDiv {
    margin-left: 15px;
  }
  .HeadNavigator {
    position: fixed;
    top: 16px;
  }
  .search-Field {
    padding: 6px;
    margin: 10px 5px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .inputSearchField {
    outline: none;
    border: none;
    background: #e6e9f0;
  }
  .filterField {
    padding: 8px;
    margin-right: 8px;
  }
  .filter-counter {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: -3px;
    right: 0px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
    align-items: center;
  }

  @media screen and (min-width: 576px) {
    padding: 80px 35px 48px 28px;
    background: #fafafa;

    .cards-container {
      .type-card {
        width: 32.4%;
        box-shadow: 0px 1px 4px 0px #26275833;
        margin: 0 12px 12px 0;
      }
      .type-card:nth-child(3n) {
        margin-right: 0;
      }
    }
    .addPolicyButton {
      padding: 5px 20px;
      width: 225px;
      margin-left: 20px;
    }
    .filter-counter {
      top: 3px;
      right: 2px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 80px 12px 0 132px;
    .cards-container {
      margin-top: 24px;
      padding: 0 5px 0 21;
      .type-card {
        width: 31.15%;
        box-shadow: none;
        padding: 23px 30px;
        margin: 0 20px 20px 0;
      }
    }
    .addPolicyButton {
      padding: 10px 20px;
      width: 240px;
      margin-left: 20px;
    }
    .book-filter {
      margin-right: 23px;
    }
  }

  @media screen and (min-width: 1900px) {
    padding: 110px 94px 0 200px;
    .text-div {
      margin-top: 12px;
      padding-left: 20%;
      padding-right: 18%;
    }
    .cards-container {
      margin-top: 32px;
      .type-card {
        width: 24.15%;
        margin-right: 17px;
        margin: 0 22px 22px 0;
      }
      .type-card:nth-child(3n) {
        margin-right: 17px;
      }

      .type-card:nth-child(4n) {
        margin-right: 0;
      }
    }
    .addPolicyButton {
      padding: 13px 20px;
      width: 244px;
    }
    .addSourceButton {
      padding: 14px 20px;
    }
  }
`;

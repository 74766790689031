import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { AdminDiv } from '../../styles/AdminManage';
import MobileNav from '../../components/MobileTabNav';
import Tab from './components/Tab';
import Leads from './Leads';
import Opportunities from './Opportunities';
import Policies from './Policies';
import Contacts from './Contacts';
import Accounts from './Accounts';
import { getCount } from '../../store/book/bookSlice';
import { AppDispatch } from '../../store';
import 'react-loading-skeleton/dist/skeleton.css';

function MyBook() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <div className='content-div'>
        <TopBar>
          <div className='d-flex align-items-center'>
            <h2>My Book</h2>
          </div>
        </TopBar>
        <div className='tab-div d-none d-sm-block'>
          <Tab />
        </div>
        <Routes>
          <Route path='/leads' element={<Leads />} />
          <Route path='/accounts' element={<Accounts />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/opportunities' element={<Opportunities />} />
          <Route path='/policies' element={<Policies />} />

          <Route path='*' element={<Navigate to='/mybook/leads' replace />} />
        </Routes>
      </div>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </AdminDiv>
  );
}

export default MyBook;

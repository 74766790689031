import styled from '@emotion/styled';
export const StyledEditOpportunityDetailsDiv = styled.div`
  .topdiv {
    width: 100%;
    border-bottom: 1px solid #e6e9f0;
    z-index: 2;
    height: 80px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 400px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 0;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
  .opp-heading-overflow {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .subhead {
    font-size: 14px;
    color: #5a5f70;
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }

  .edit-fields .input-container,
  .edit-fields .input-field {
    border: none;
    &:hover {
      background: #f5f7fc !important;
      box-shadow: none;
    }
  }
  .input-container {
    width: 90%;
  }
  .edit-fields .profile-pic {
    margin-right: 5px;
  }
  .edit-fields .searchValuesCard {
    padding: 0 1px;
  }
  .account-drop .searchValues-container {
    right: 0;
  }

  @media screen and (max-width: 1200px) {
    .account-drop .searchValues-container {
      right: unset;
    }
  }

  .entire-div {
    width: 100%;
    padding: 16px 15px;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
    padding: 20px 22px;
  }
  .opphead-div {
    font-size: 20px;
  }
  .scroll-bar {
    height: 88vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 145px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 20px;
  }

  .contact-info {
    left: 49px;
    top: 133.96px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-top: 22px;
    margin-left: 8px;
  }

  .edit-fields {
    width: 186px;
    margin-top: 16px;
  }
  .fields::placeholder {
    color: #262758;
  }

  .small-div {
    width: 320px;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 91%;
    bottom: 0;
    left: 11px;
    background: #fff;
    z-index: 1;
    padding: 20px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 20px;
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #c8cacf;
      border: none;
    }
  }

  .mail-info {
    color: #191919;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .border-div {
    border-bottom: 1px solid #e6e9f0;
    height: 14px;
  }
  .select__control:hover {
    .select__dropdown-indicator {
      display: block;
    }
  }
  .select__clear-indicator {
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .search {
    .search-group {
      .input-placeholder {
        font-size: 12px;
        color: #5a5f70;
      }
      .input-field {
        padding: 38px 25px 10px 10px;
      }
    }
  }
  .input-field {
    padding: 30px 50px 4px 10px;
  }
  .ValueContainer {
    padding: 16px 8px;
  }

  @media screen and (min-width: 576px) {
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 58px;
      }
    }
    .topdiv {
      height: 68px;
      width: 784px;
    }
    .subhead {
      margin-left: 32px;
      margin-top: 3px;
    }
    .cross-btn {
      padding: 26.68px 46px;
      right: 40px;
    }
    .entire-div {
      padding: 0px 40px;
      width: 848px;
      margin-bottom: 20px;
      margin: 0 auto;
    }
    .scroll-bar {
      height: 68vh;
      padding-bottom: 60px;
    }
    .buttonsDiv {
      width: 82%;
      left: 101px;
    }
    .edit-fields {
      width: 224px;
      margin-right: 30px;
      margin-top: 8px;
    }
    .tab-head {
      margin-left: 18px;
      margin-top: 11px;
    }
    .barImg {
      margin-left: 18px;
      height: 19px;
    }
    .contact-info {
      margin-top: 18px;
      margin-left: 8px;
    }
    .error-val {
      margin-top: 24px;
    }
  }
  @media screen and (min-width: 1200px) {
    .topdiv {
      height: 69px;
    }
    .entire-div {
      padding: 0px 48px;
      width: 883px;
      margin: 0 auto;
    }
    .contact-info {
      margin-top: 24px;
      margin-left: 8px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      padding: 24px 33px;
      right: 220px;
    }
    .cross {
      width: 30px;
    }
    .scroll-bar {
      height: 75vh;
    }
    .saveButton {
      width: 220px;
    }
    .buttonsDiv {
      width: 62%;
      z-index: 2;
      padding: 20px;
      bottom: 0;
      left: 245px;
    }
    .opphead-div {
      font-size: 24px;
    }
    .edit-fields {
      margin-right: 10px;
      width: 184px;
      .input-field {
        padding: 17px 50px 4px 12px;
      }
    }
    .search {
      .search-group {
        .input-field {
          padding: 32px 25px 10px 10px;
        }
      }
    }

    .ValueContainer {
      padding: 5px 8px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 1920px;
    .cross-btn {
      right: 385px;
    }
    .topdiv {
      width: 100%;
      height: 69px;
    }
    .cross {
      width: 32px;
    }

    .contact-info {
      font-size: 20px;
      line-height: 28px;
      margin-top: 36px;
      margin-left: 9px;
    }
    .business-info {
      font-size: 20px;
      line-height: 28px;
    }

    .buttonsDiv {
      width: 57%;
      z-index: 2;
      padding: 48px;
      margin: 0 auto;
      left: 437px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
    }
    .entire-div {
      padding: 0px 60px;
      width: 1208px;
      margin: 0 auto;
    }

    .scroll-bar {
      height: 75vh;
    }
    .border-div {
      height: 22px;
    }
    .opphead-div {
      font-size: 24px;
    }
    .edit-fields {
      margin-right: 60px;
      margin-top: 11px;
    }
    .error-val {
      margin-top: 10px;
    }
    .search {
      .search-group {
        .input-field {
          padding: 23px 25px 10px 10px;
        }
      }
    }
    .input-field {
      padding: 19px 50px 4px 10px;
    }
    .ValueContainer {
      padding: 16px 8px;
    }
  }
`;

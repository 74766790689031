import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchDocuments,
  createDocument,
  updateDocument,
  fetchDoumentDetails,
  deleteDocument,
  reaploadDocument,
} from '../../api/documents';

export type DocumentSliceState = {
  status: string;
  document: any[];
  DocsDetails: any[];
  isLoading: boolean;
};
export type IProps = {
  contactUUID?: string;
  accountUUID?: string;
  leadUUID?: string;
  opportunityUUID?: string;
  taskUUID?: string;
  eventUUID?: string;
  quoteUUID?: string;
  policyUUID?: string;
  documentTypeUUID?: any;
  sortBy?: string;
  orderBy?: string;
};
export const getDocuments = createAsyncThunk(
  'document/getDocuments',
  async ({
    contactUUID,
    accountUUID,
    leadUUID,
    opportunityUUID,
    taskUUID,
    eventUUID,
    quoteUUID,
    policyUUID,
    documentTypeUUID,
    sortBy,
    orderBy,
  }: IProps) => {
    const response = await fetchDocuments(
      contactUUID,
      accountUUID,
      leadUUID,
      opportunityUUID,
      taskUUID,
      eventUUID,
      quoteUUID,
      documentTypeUUID,
      policyUUID,
      sortBy,
      orderBy
    );
    return response.data;
  }
);

export const addDocument = createAsyncThunk(
  'document/addDocument',
  async ({
    formData,
    successCB,
    errorCB,
  }: {
    formData: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await createDocument(formData, successCB, errorCB);
    return response.data;
  }
);

export const updateDocumentDetails = createAsyncThunk(
  'document/updateDocumentDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await updateDocument(uuid, data);
    successCB(response.data);
    return response.data;
  }
);
export const reaploadDocumentDetails = createAsyncThunk(
  'document/reaploadDocumentDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await reaploadDocument(uuid, data);
    successCB(response.data);
    return response.data;
  }
);
export const getDocumentDetails = createAsyncThunk(
  'document/getDocumentDetails',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchDoumentDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

export const deleteADocument = createAsyncThunk(
  'document/deleteADocument',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteDocument(uuid);
    successCB(response.data);
    response.uuid = uuid;

    return response;
  }
);

const initialState: DocumentSliceState = {
  status: 'idle',
  document: [],
  DocsDetails: [],
  isLoading: false,
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.document = action.payload;
      })
      .addCase(getDocuments.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addDocument.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.document.unshift(action.payload);
      })
      .addCase(updateDocumentDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.document.findIndex(
          (item: any) => item?.uuid === action.payload.uuid
        );
        state.document[index] = action.payload;
      })
      .addCase(getDocumentDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.DocsDetails = action.payload;
      })
      .addCase(reaploadDocumentDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.document.findIndex(
          (item: any) => item?.uuid === action.payload.oldDocumentUUID
        );
        state.document[index] = action.payload;
      })
      .addCase(deleteADocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.document.findIndex(
          (item: any) => item?.uuid === action.payload.uuid
        );
        state.document.splice(index, 1);
      });
  },
});
export const { updateStatus } = documentSlice.actions;

export default documentSlice.reducer;

export const selectAllDocuments = (state: {
  documents: DocumentSliceState;
}) => {
  return state.documents?.document;
};

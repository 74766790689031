import axios from './axios';

const fetchCustomFieldTypes = () => {
  return axios.get(`/customField/types`).then((res) => {
    return res;
  });
};

const fetchCustomFields = (
  agencyUUID: string,
  resourceName: string,
  searchBy?: string,
  isAddForm?: string,
  fieldTypeUUID?: string,
  sortBy?: string,
  orderBy?: string
) => {
  return axios
    .get(`/customField`, {
      params: {
        agencyUUID,
        resourceName,
        searchBy,
        isAddForm,
        fieldTypeUUID,
        sortBy,
        orderBy,
      },
    })
    .then((res) => {
      return res;
    });
};

const addCustomFields = (data: any, successCB: Function) => {
  return axios.post(`/customField`, data).then((res) => {
    successCB(res);
    return res;
  });
};

const updateCustomFields = (uuid: string, data: any, successCB: Function) => {
  return axios.patch(`/customField/${uuid}`, data).then((res) => {
    successCB(res);
    return res;
  });
};

const deleteCustomFields = (uuid: string, data: any) => {
  return axios.delete(`/customField/${uuid}`, { data }).then((res) => {
    return res;
  });
};

export {
  fetchCustomFieldTypes,
  fetchCustomFields,
  addCustomFields,
  updateCustomFields,
  deleteCustomFields,
};

import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { IPipeline, IPipelineStage } from '../../../../interfaces/Opportunity';
import EditPipelineDrawer from '../../../../components/EditPipelineDrawer';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import { pipelineStatusUpdate } from '../../../../store/pipelines/pipelineSlice';
import { MobilePipelineStyle } from './elements/styles';
import MobileDeleteWarningModal from './MobileDeleteWarningModal';
import MobileDeletePipelineModal from './MobileDeletePipelineModal';
import MobileInActiveWarningModal from './MobileInActiveWarningModal';

const MobileManageCard = ({
  pipeline,
  setShowSuccess,
  setDeletedItem,
  deletingSuccess,
}: {
  pipeline: IPipeline;
  setShowSuccess: Function;
  setDeletedItem: Function;
  deletingSuccess: boolean;
}) => {
  const dropRef = useRef(null);

  const [pipelineStage, setpipelineStage] = useState<IPipelineStage[]>();
  const [dropDown, setDropDown] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [onDelete, setDelete] = useState(false);
  const [showEdit, showEditModal] = useState('closed');
  const [deleteSuccess, setDeleteSuccess] = useState('close');
  const [showInActive, showInActiveModal] = useState(false);

  useEffect(() => {
    onSetPipelineValue(pipeline);
  }, [pipeline]);

  useOutsideClick(dropRef, setDropDown);
  const { uuid } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item?.PipelineStages?.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];
      setpipelineStage(result);
      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }
    }
  };
  const updateStatus = (pipelineUUID: string) => {
    let data = {
      agencyUUID: uuid,
      action: pipeline.active ? 'inactive' : 'active',
    };
    dispatch(
      pipelineStatusUpdate({
        uuid: pipelineUUID,
        data: data,
      })
    );
  };

  const InactiveModal = () => {
    if (pipeline?.active) {
      showInActiveModal(true);
    } else {
      updateStatus(pipeline?.uuid);
      showInActiveModal(false);
    }
  };
  return (
    <MobilePipelineStyle>
      <div className='  mobContent'>
        <div className='d-flex'>
          <div className='checkboxDivMob'>
            <label className='switchMob'>
              <input
                type='checkbox'
                disabled={pipeline?.default ? true : false}
                checked={pipeline?.active}
                className='inputCheckbox'
                onClick={() => {
                  InactiveModal();
                }}
              />
              <span className='sliderMob' />
            </label>
          </div>
          <div className='PipelineStagesRowMob d-flex justify-content-between w-100 align-items-center'>
            <div>
              <h2 className='SubHeadMob'>
                {pipeline.name ? pipeline?.name : 'unknown'}
              </h2>
            </div>

            <div className=''>
              <div
                className='vector d-flex align-items-center justify-content-center position-relative'
                style={{ cursor: pipeline.default ? 'not-allowed' : 'pointer' }}
                onClick={() => {
                  pipeline.default
                    ? setDropDown(false)
                    : setDropDown(!dropDown);
                }}
                ref={dropRef}
              >
                <img src={vector} alt='vector' />
                {dropDown && (
                  <div className='dropdown-div'>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setDropDown(false);
                        showEditModal('open');
                      }}
                    >
                      Edit <img src={edit} alt='bin' />
                    </div>
                    <div
                      className='delete-div'
                      onClick={() => {
                        setDropDown(false);
                        if (pipeline.opportunityCount === 0) {
                          setDelete(true);
                        } else {
                          setDeleteWarning(true);
                        }
                      }}
                    >
                      Delete <img src={bin} alt='bin' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='pipelineStageBorder'></div>
        {pipelineStage &&
          pipelineStage.map((item: IPipelineStage) => {
            return (
              <span key={item.id}>
                <span className='PipelineStageMob'>{item.name}</span>
                {item.nextStageId ? (
                  <img src={vector} className='NextVectorMob' alt='' />
                ) : null}
              </span>
            );
          })}
        {deleteWarning && (
          <MobileDeleteWarningModal
            showModal={deleteWarning}
            onCloseModal={setDeleteWarning}
            UUID={pipeline?.uuid}
            onDeletePipeline={setDelete}
            name={pipeline?.name}
          />
        )}
        {onDelete && (
          <MobileDeletePipelineModal
            showModal={onDelete}
            onCloseModal={setDelete}
            UUID={pipeline?.uuid}
            onDelete={setDelete}
            setDeleteSuccess={setDeleteSuccess}
          />
        )}
        {showEdit !== 'closed' && deleteSuccess === 'close' && (
          <EditPipelineDrawer
            show={showEdit}
            closeModal={showEditModal}
            pipeline={pipeline}
            setDeleteSuccess={setShowSuccess}
            deleteSuccess={deletingSuccess}
          />
        )}
        {showInActive ? (
          <MobileInActiveWarningModal
            setShowModal={showInActiveModal}
            onCloseModal={updateStatus}
            pipelineUUID={pipeline?.uuid}
            name={pipeline?.name}
          />
        ) : null}
      </div>
    </MobilePipelineStyle>
  );
};

export default MobileManageCard;

import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  width: 100%;
  p {
    margin-bottom: 0;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-syntax {
    white-space: normal;
    background: #23241f;
    border-radius: 4px;
    padding: 5px 10px;
    color: #f8f8f2;
    margin-bottom: 0;
  }
`;

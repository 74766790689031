import { CustomTextFieldDiv } from '../elements/CustominputStyles';
import { useEffect, useRef } from 'react';
export interface IProps {
  name: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  onSetItem: Function;
  values: any;
}

const TextAreaField = ({
  name,
  placeholder,
  className,
  disabled,
  onSetItem,
  values,
}: IProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, values]);
  return (
    <CustomTextFieldDiv>
      <div
        className={`d-flex flex-column w-100 ${className}  ${
          values[name] ? 'filled' : ''
        } input-container  `}
      >
        <textarea
          id={name}
          name={name}
          onChange={(e) => onSetItem(name, e.target.value)}
          className={`input-field`}
          disabled={disabled}
          value={values[name]}
          placeholder={placeholder}
          autoComplete='off'
          ref={textAreaRef}
        />
      </div>
    </CustomTextFieldDiv>
  );
};

export default TextAreaField;

import { useState, useEffect, useRef } from 'react';
import { FieldDiv } from '../InputField/styles';
import { FieldDiv as EditFieldDiv } from '../DetailsEditFormInput/styles';
import edit from '../../assets/logo/edit.svg';
import { PlacesContainer } from './style';
import { useOutsideClick } from '../../hooks/useOutsideClick';

let autoComplete: any;

const loadScript = (url: any, callback: any) => {
  let script: any = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad() {
  autoComplete = new window.google.maps.places.AutocompleteService();
}

async function handlePlaceSelect(
  updateQuery: any,
  setAddressObject: any,
  inputType: any,
  onSetItem: any = null,
  addressObject: any
) {
  const query = addressObject.formatted_address;
  updateQuery(query);
  let AddressComponents = {};

  addressObject.address_components
    .filter(
      (item: any) =>
        item.types.includes('postal_code') ||
        item.types.includes('administrative_area_level_2') ||
        item.types.includes('locality') ||
        item.types.includes('administrative_area_level_1')
      //   item.types.includes('route') ||
      // item.types.includes('locality')
    )
    .map((item: any) => {
      if (item.types.includes('postal_code')) {
        Object.assign(AddressComponents, { zipCode: item.long_name });
        return { zipCode: item.long_name };
      } else if (item.types.includes('administrative_area_level_2')) {
        Object.assign(AddressComponents, { country: item.long_name });
        return { country: item.long_name };
      } else if (item.types.includes('locality')) {
        Object.assign(AddressComponents, { city: item.long_name });
        return { city: item.long_name };
      } else if (item.types.includes('administrative_area_level_1')) {
        Object.assign(AddressComponents, { state: item.long_name });
        return { state: item.long_name };
        // } else if (item.types.includes('route')) {
        //   return { value: item.long_name, type: 'apt' };
        // } else if (item.types.includes('locality')) {
        //   return { value: item.long_name, type: 'street' };
      }
    });

  Object.assign(AddressComponents, {
    address: addressObject.formatted_address,
  });
  if (inputType === 'PERSONAL' || inputType === 'EDIT') {
    setAddressObject(AddressComponents);
  } else if (inputType === 'COMMERCIAL') {
    onSetItem('addressComponents', AddressComponents, true);
  }
}

function GoogleSearchInput({
  placeholder,
  name,
  className,
  setAddressObject,
  addressObject,
  inputType,
  onSetItem,
  index,
  initialValue = '',
  disabled,
  setIsManual,
  required,
}: {
  placeholder: string;
  name: string;
  className?: string;
  setAddressObject: any;
  addressObject: any;
  inputType: 'PERSONAL' | 'COMMERCIAL' | 'EDIT';
  onSetItem?: any;
  index?: number;
  initialValue?: string;
  disabled?: boolean;
  setIsManual: Function;
  required?: boolean;
}) {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  const placesRef = useRef(null);
  const [places, setPlaces] = useState([]);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query !== '') {
      autoComplete?.getPlacePredictions(
        { input: query, componentRestrictions: { country: 'us' } },
        (predictions: any) => {
          setPlaces(predictions);
        }
      );
    } else {
      setPlaces([]);
      setAddressObject({});
    }
  }, [query]);

  const getPlacesDetail = (placeId: any) => {
    var service = new google.maps.places.PlacesService(
      document.createElement('div')
    );
    service.getDetails(
      {
        placeId,
        fields: ['address_components', 'formatted_address'],
      },
      (place) =>
        handlePlaceSelect(
          setQuery,
          setAddressObject,
          inputType,
          onSetItem,
          place
        )
    );
  };

  useOutsideClick(placesRef, setFocused);

  useEffect(() => {
    setQuery(initialValue);
  }, [initialValue]);

  return (
    <div ref={placesRef}>
      {inputType === 'PERSONAL' ? (
        <FieldDiv>
          <div
            className={`d-flex flex-column w-100 ${className} input-container ${
              query ? 'filled' : ''
            }`}
          >
            <input
              ref={autoCompleteRef}
              onChange={(event) => setQuery(event.target.value)}
              className={`input-field`}
              value={query}
              name={name}
              id={name}
              type='text'
              autoComplete='off'
              placeholder=''
              onFocus={() => setFocused(true)}
            />
            <label htmlFor={name} className='input-placeholder'>
              {placeholder}
              {required ? <span className='requiredStar'>*</span> : null}
            </label>
          </div>
        </FieldDiv>
      ) : inputType === 'COMMERCIAL' ? (
        <FieldDiv>
          <div
            className={`d-flex flex-column w-100 ${className} input-container ${
              query ? 'filled' : ''
            }`}
          >
            <input
              ref={autoCompleteRef}
              onChange={(event) => setQuery(event.target.value)}
              className={`input-field`}
              value={query}
              name={name}
              id={`${name}-${index}`}
              type='text'
              autoComplete='off'
              placeholder=''
              onFocus={() => setFocused(true)}
            />
            <label htmlFor={name} className='input-placeholder'>
              {placeholder}
            </label>
          </div>
        </FieldDiv>
      ) : (
        inputType === 'EDIT' && (
          <EditFieldDiv>
            <div
              className={`d-flex flex-column w-100 ${className} input-container ${
                query ? 'filled' : ''
              }`}
            >
              <input
                ref={autoCompleteRef}
                onChange={(event) => setQuery(event.target.value)}
                className={`input-field`}
                value={query}
                name={name}
                id={name}
                disabled={disabled}
                type='text'
                autoComplete='off'
                placeholder='-'
                onFocus={() => setFocused(true)}
              />
              <label htmlFor={name} className='input-placeholder'>
                {placeholder}
              </label>
              <img className='editIcon' src={edit} alt='edit' />
            </div>
          </EditFieldDiv>
        )
      )}
      {focused && (
        <PlacesContainer>
          <ul>
            {places?.map((item: any) => (
              <li
                onClick={() => {
                  getPlacesDetail(item?.place_id);
                  setFocused(false);
                }}
              >
                {item?.description}
              </li>
            ))}
            <li
              id='manual-address'
              onClick={() => {
                setIsManual(true);
                setFocused(false);
              }}
            >
              Can't find address?
            </li>
          </ul>
        </PlacesContainer>
      )}
    </div>
  );
}

export default GoogleSearchInput;

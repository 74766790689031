import axios from './axios';

const fetchTaskType = (successCB: Function) => {
  return axios.get('/task/taskType').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const fetchTaskPriority = (successCB: Function) => {
  return axios.get('/task/taskPriority').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const fetchTaskDetails = (uuid: string) => {
  return axios.get(`/task/${uuid}`).then(
    (res) => {
      return res;
    },
    (error) => error
  );
};

const addTask = (data: any, successCB: Function, errorCB: Function) => {
  return axios
    .post('/task', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB('Please try again.');
        return error.res;
      }
    );
};

const fetchTaskStatus = (successCB: Function) => {
  return axios.get('/task/taskStatus').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const fetchActivities = (
  uuidName: string,
  uuid: string,
  showAll: string,
  successCB: Function,
  tasksOffset: number,
  eventsOffset: number,
  notesOffset: number,
  eventTypeUUID?: string[],
  taskTypeUUID?: string[],
  taskStatusUUID?: string[],
  taskPriorityUUID?: string[],
  assigneeUUID?: string,
  searchBy?: string
) => {
  return axios
    .get(`/activities?${uuidName}=${uuid}`, {
      params: {
        showAll,
        limit: 8,
        tasksOffset,
        eventsOffset,
        notesOffset,
        eventTypeUUID,
        taskTypeUUID,
        taskStatusUUID,
        taskPriorityUUID,
        assigneeUUID,
        searchBy,
        sortBy: 'createdAt',
        orderBy: 'desc',
      },
    })
    .then((res) => {
      successCB(res.data);
      return res.data;
    });
};

const updateTask = (uuid: string, data: any) => {
  return axios.patch(`task/${uuid}`, data).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

const deleteTask = (uuid: string, successCB: Function) => {
  return axios.delete(`task/${uuid}`).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      return error;
    }
  );
};

export {
  fetchTaskType,
  fetchTaskPriority,
  addTask,
  updateTask,
  fetchTaskDetails,
  fetchTaskStatus,
  fetchActivities,
  deleteTask,
};

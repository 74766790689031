import styled from '@emotion/styled';
export const MobilePipelineStyle = styled.div`
  box-shadow: 0px 1px 4px 0px #26275833;
  padding: 16px 20px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 16px;
  .mobContent {
    margin-top: -14px;
  }
  .checkboxDivMob {
    padding: 15px 4px;
    margin-right: 12px;
  }
  .switchMob {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    width: 36px;
    height: 20px;
    background: #dfe6f5;
    transition: 0.4s;

    .inputCheckbox {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .sliderMob {
    position: absolute;
    border-radius: 40px;
    cursor: pointer;
    top: 0;

    right: 0;
    left: 0;
    bottom: 0;
  }
  .sliderMob:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background: #ffffff;
    transition: 0.4s;
  }
  .inputCheckbox:checked + .sliderMob:before {
    transform: translateX(17px);
  }
  .inputCheckbox:checked + .sliderMob {
    background: #58c070;
  }
  .SubHeadMob {
    font-size: 14px;
    margin-top: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    posiotn: relative;
  }
  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0px;
    bottom: -61px;
    z-index: 1;
    width: 144px;
    cursor: pointer;
    line-height: 14px;
    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .junk-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  .PipelineStageMob {
    padding: 0 0px 0px 6px;
    color: #5a5f70;
  }
  .NextVectorMob {
    width: 12px;
    transform: rotate(-90deg);
    color: #5a5f70;
    margin-left: 4px;
  }
  .pipelineStageBorder {
    border-top: 1px solid #e6e9f0;
    margin-bottom: 12px;
    margin-top: 3px;
  }
`;

import { useEffect, useState } from 'react';
import { StyledTab } from '../../../../styles/StyledTab';
import TasksTab from '../../../../components/ActivitiesInnerTab';
import Documents from '../../../../components/DocumentsInnerTab';
import HistoryTab from '../../../../components/HistoryInnerTab';
import { AppDispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import { updateNoteDetails } from '../../../../store/tasks/tasksSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const LeadsInnerTab = ({
  leadUUID,
  addNote,
  addNoteLoading,
  handleAddTask,
  handleAddEvent,
  LeadDocumentCount,
  file,
  setFile,
  setDocTypeUUID,
  docTypeUUID,
  ApiSuccess,
  added,
  edited,
  setEdited,
  setAdded,
}: {
  leadUUID?: string;
  addNote: Function;
  setFile: Function;
  setDocTypeUUID: Function;
  docTypeUUID: string;
  file: any;
  addNoteLoading: boolean;
  ApiSuccess: boolean;
  LeadDocumentCount: any;
  handleAddTask: Function;
  handleAddEvent: Function;
  setAdded: Function;
  setEdited: Function;
  edited: string;
  added: string;
}) => {
  const [activities, setActivities] = useState(true);
  const [document, setDocument] = useState(false);
  const [history, setHistory] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const handleUpdateNote = (UUID: any, data: any, uuid: string) => {
    data.leadUUID = uuid;
    setEdited('Note');
    dispatch(
      updateNoteDetails({
        uuid: UUID,
        data: data,
        successCB: () => {
          setTimeout(() => {
            setEdited('');
          }, 2500);
        },
      })
    );
  };

  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.type === 'DOCUMENTS') {
      setActivities(false);
      setDocument(true);
      navigate(location.pathname);
    }
  }, [location]);

  return (
    <>
      <StyledTab>
        <span
          className={activities ? `navlink active` : 'navlink'}
          onClick={() => {
            setActivities(true);
            setDocument(false);
            setHistory(false);
          }}
        >
          Activities{' '}
          <span className='count'>{LeadDocumentCount.activities}</span>
        </span>
        <span
          className={document ? `navlink active` : 'navlink'}
          onClick={() => {
            setDocument(true);
            setActivities(false);
            setHistory(false);
          }}
        >
          Documents <span className='count'>{LeadDocumentCount.document}</span>
        </span>
        <span
          className={history ? `navlink active` : 'navlink'}
          onClick={() => {
            setHistory(true);
            setActivities(false);
            setDocument(false);
          }}
        >
          History
        </span>
      </StyledTab>
      {activities && (
        <TasksTab
          addNote={addNote}
          setFile={setFile}
          file={file}
          docTypeUUID={docTypeUUID}
          setDocTypeUUID={setDocTypeUUID}
          addNoteLoading={addNoteLoading}
          addTask={handleAddTask}
          addEvent={handleAddEvent}
          ApiSuccess={ApiSuccess}
          updateNote={handleUpdateNote}
          name='Lead'
          uuid={leadUUID}
          added={added}
          edited={edited}
          setEdited={setEdited}
        />
      )}
      {document && <Documents name={'lead'} uuid={leadUUID ? leadUUID : ''} />}
      {history && <HistoryTab resourceName='lead' rUUID={leadUUID} />}
    </>
  );
};

export default LeadsInnerTab;

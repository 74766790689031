import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../store';
import {
  getAudits,
  auditsSliceState,
  selectAllAudits,
  clearHistory,
} from '../../store/audits/auditsSlice';
import { IAudit } from '../../interfaces/Audit';
import { InnerFieldStyle, StyledActivity } from './styles';
import search from '../../assets/logo/search.svg';
import close from '../../assets/logo/cross.svg';
import useDebounce from '../../hooks/useDebounce';

const HistoryTab = ({
  resourceName,
  rUUID,
  isMobile,
}: {
  resourceName: string;
  rUUID?: string;
  isMobile?: boolean;
}) => {
  const [history, setHistory] = useState<any>({ keys: [] });

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { audits: auditsSliceState }) => state.audits?.status
  );
  const audits = useSelector(selectAllAudits);
  let { uuid } = useParams();

  useEffect(() => {
    formatHistory(audits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audits]);

  const formatHistory = (audit: IAudit[]) => {
    const temp = audit.reduce((data: any, currentData: any) => {
      let x: string | Date = new Date(currentData.createdAt);
      let currentDate = new Date();
      if (
        currentDate.getDate() === x.getDate() &&
        currentDate.getMonth() === x.getMonth() &&
        currentDate.getFullYear() === x.getFullYear()
      ) {
        x = `Today`;
      } else if (
        currentDate.getDate() - x.getDate() === 1 &&
        currentDate.getMonth() === x.getMonth() &&
        currentDate.getFullYear() === x.getFullYear()
      ) {
        x = `Yesterday`;
      } else {
        x = x.toLocaleString('default', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        });
      }

      if (data[x]) {
        const currentHistory = data[x];
        currentHistory.push(currentData);
        data[x] = currentHistory;
      } else {
        data[x] = [currentData];
      }
      return data;
    }, {});
    setHistory({ history: temp, keys: Object.keys(temp) });
  };

  useEffect(() => {
    if (
      (loadingStatus === 'idle' || loadingStatus === 'succeeded') &&
      !isMobile
    ) {
      dispatch(clearHistory());
      dispatch(
        getAudits({
          sortBy: '',
          orderBy: '',
          searchBy: debouncedSearchTerm,
          resource: resourceName.toLowerCase(),
          resourceUUID: resourceName === 'lead' ? rUUID : uuid,
          successCB: (audit: any) => {},
          offset: 0,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onScrollHistory = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingStatus !== 'loading' &&
      (uuid || rUUID)
    ) {
      dispatch(
        getAudits({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          resource: resourceName.toLowerCase(),
          resourceUUID: resourceName === 'lead' ? rUUID : uuid,
          successCB: () => {},
          offset: audits.length,
        })
      );
    }
  };

  return (
    <div>
      <InnerFieldStyle>
        <div className='d-block d-sm-none'>
          {/* <div className='d-flex d-sm-none activityMobileCard justify-content-between'>
            <h4></h4>
            <div>
              <img className='SearchField' src={search} alt='' />
              <img src={filter} alt='' />
            </div>
          </div> */}
          <div className='scroll-div' onScroll={onScrollHistory}>
            {history.keys.map((item: any) => {
              return (
                <div key={item}>
                  <div>
                    <h6 className='day-head'>{item}</h6>
                  </div>
                  {history.history[item].map((value: any, index: number) => (
                    <div className='bullet-list date-div' key={index}>
                      <div>
                        <div className='imageCard'></div>
                        <div className='bullet-div'></div>

                        {new Date(value.createdAt).toLocaleString('en-US', {
                          hour: 'numeric',
                          hour12: true,
                          minute: 'numeric',
                        })}
                      </div>
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: value.description,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </InnerFieldStyle>

      <StyledActivity>
        <div className='d-none d-sm-block '>
          <div className='upper-div d-flex justify-content-between'>
            <h5 className='activity-head'>History</h5>
            <div className='top-section d-flex'>
              <div className=' d-flex align-items-center navbar-button justify-content-center'>
                <div className='book-filter d-flex'>
                  {isSearchClicked || searchBy ? (
                    <div className='book-search d-flex align-items-center'>
                      <img src={search} alt='' className='search-button' />
                      <input
                        onChange={(e) => {
                          setSearchBy(e.target.value);
                        }}
                        autoFocus
                        value={searchBy}
                      />
                      <img
                        src={close}
                        alt=''
                        className='close-button'
                        onClick={() => {
                          setSearchBy('');
                          setIsSearchClicked(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className='book-shrinked'
                      onClick={() => setIsSearchClicked(true)}
                    >
                      <img src={search} alt='' />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='scroll-div' onScroll={onScrollHistory}>
            {history.keys.map((item: any) => {
              return (
                <>
                  <div>
                    <div>
                      <h6 className='day-head'>{item}</h6>
                    </div>
                    <div>
                      {history.history[item].map((value: any) => (
                        <div className='bullet-list date-div'>
                          <div>
                            <div className='imageCard'></div>
                            <div className='bullet-div'></div>

                            {new Date(value.createdAt).toLocaleString('en-US', {
                              hour: 'numeric',
                              hour12: true,
                              minute: 'numeric',
                            })}
                          </div>
                          <div>
                            <div
                              className='description'
                              dangerouslySetInnerHTML={{
                                __html: value.description,
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </StyledActivity>
    </div>
  );
};

export default HistoryTab;

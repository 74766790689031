import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../hooks/useDebounce';
import { AppDispatch } from '../../../../store';
import {
  getCarriers,
  CarrierSliceState,
  selectAllCarriers,
  clearCarrier,
  onUpdateCarrierData,
  onDeleteCarrier,
} from '../../../../store/carriers/carriersSlice';
import EmptyResults from '../../../../pages/MyBook/components/Empty';
import PaginationComponent from '../../../../components/PaginationComponent';
import MobileTabNav from '../../../../components/MobileTabNav';
import Table from '../../../../components/Table';
import CarrierBatchDrawer from './CarrierBatchDrawer';
import MobileTab from './MobileTab';
import DeleteModal from '../../../../components/DeleteModal';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
import SystemCarrierFilter from './SystemCarrierFilter';
import ActivateModal from './ActivateModal';
import SuccessCard from '../../../../components/SuccessCard';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { IAgency } from '../../../../interfaces/Agency';
import { IOption } from '../../../../interfaces/Opportunity';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import {
  FilterContainer,
  MyBookDiv,
} from '../../../../pages/MyBook/elements/styles';
import { CarriersMobileCard } from '../styles';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import vector from '../../../../assets/logo/dropdown.svg';
import filter from '../../../../assets/logo/filter-mobile.svg';
import filterIcon from '../../../../assets/logo/filter.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import search from '../../../../assets/logo/search.svg';
import closeIcon from '../../../../assets/logo/cross.svg';
import plus from '../../../../assets/logo/blackplus.svg';
import bin from '../../../../assets/logo/bin.svg';
import cross from '../../../../assets/logo/cross.svg';
import { IProduct } from '../../../../interfaces/Product';
import defaultCarrier from '../../../../assets/logo/defaultCarrier.svg';

const SystemDefinedCarriers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { carriers: CarrierSliceState }) => state.carriers?.status
  );
  let carrierCount = useSelector(
    (state: { carriers: CarrierSliceState }) => state.carriers?.count
  );

  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  let uuidValue = useRef('');
  const { uuid } = useParams();
  const user = LocalStorageService.getUser();
  useEffect(() => {
    if (user.UserRole.name === 'ADMIN') {
      uuidValue.current = uuid || '';
    } else {
      uuidValue.current = user.uuid;
    }
  }, [user, uuid]);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedPolicyTypes, setSelectedPolicyTypes] = useState<IOption[]>([]);
  const [status, setStatus] = useState<any>();
  const [licenseSearch, setLicenseSearch] = useState('');
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');

  const fetchCarriers = useCallback(
    (sort?: string) => {
      dispatch(
        getCarriers({
          sortBy: sort ?? sortBy,
          searchBy: debouncedSearchTerm,
          orderBy,
          minimal: '',
          agencyUUID: uuidValue.current,
          successCB: () => {},
          limit,
          offset: page * limit,
          type: 'SYSTEM_CARRIER',
          isActive: status?.value,
          productUUIDs: selectedPolicyTypes?.map((item) => item.value),
          jurisdictionUUIDs: jurisdictionsUUID,
        })
      );
    },
    [
      debouncedSearchTerm,
      dispatch,
      jurisdictionsUUID,
      limit,
      orderBy,
      page,
      selectedPolicyTypes,
      sortBy,
      status?.value,
      uuidValue,
    ]
  );

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(clearCarrier());
      fetchCarriers();
    }
  }, [dispatch, fetchCarriers]);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const searchRef = useRef(null);

  let carriers = useSelector(selectAllCarriers);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [addCarrier, setAddCarrier] = useState('closed');
  const [scroll, setScroll] = useState(false);
  const [showSuccessBatch, setShowSuccessBatch] = useState(false);

  const onCloseModal = (action?: string) => {
    if (action === 'ADD_BATCH') {
      setAddCarrier('closing');
      setTimeout(() => {
        setShowSuccessBatch(true);
      }, 100);
      setTimeout(() => {
        setShowSuccessBatch(false);
        setAddCarrier('closed');
      }, 2000);
      fetchCarriers('createdAt');
    } else {
      setAddCarrier('closing');
      setTimeout(() => {
        setAddCarrier('closed');
      }, 300);
    }
  };

  const [showSuccessActivate, setShowSuccessActivate] = useState(false);

  const activateAction = () => {
    let actionType = 'ACTIVATE';
    if (isActive) {
      actionType = 'DEACTIVATE';
    }
    dispatch(
      onUpdateCarrierData({
        uuid: UUID,
        data: { actionType, type: 'SYSTEM_CARRIER', agencyUUID: uuid || '' },
        successCB: () => {
          setShowActivate('closed');
          setTimeout(() => {
            setShowSuccessActivate(true);
          }, 100);
          setTimeout(() => {
            setShowSuccessActivate(false);
          }, 2000);
        },
      })
    );
  };

  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [UUID, setUUID] = useState('');

  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setName(name);
  };

  const onDelete = () => {
    dispatch(
      onDeleteCarrier({
        uuid: UUID,
        successCB: () => {
          if (page > 0 && carriers.length === 1) {
            setPage(page - 1);
            dispatch(clearCarrier());
            dispatch(
              getCarriers({
                sortBy,
                searchBy: debouncedSearchTerm,
                orderBy,
                minimal: '',
                agencyUUID: uuidValue.current,
                successCB: () => {},
                limit,
                offset: (page - 1) * limit,
                type: 'SYSTEM_CARRIER',
              })
            );
          }
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
        data: { type: 'SYSTEM_CARRIER', agencyUUID: uuid },
      })
    );
  };

  const [showFilter, setShowFilter] = useState('closed');
  const [licenseSearchBatch, setLicenseSearchBatch] = useState('');
  const [jurisdictionsUUIDBatch, setJurisdictionsUUIDBatch] = useState('');

  const [showBatchFilter, setShowBatchFilter] = useState('closed');

  const [selectedBatchPolicyTypes, setSelectedBatchPolicyTypes] = useState<
    IOption[]
  >([]);

  const [sortByBatch, setSortByBatch] = useState('name');
  const [orderByBatch, setOrderByBatch] = useState('desc');
  const [limitBatch, setLimitBatch] = useState(25);
  const [pageBatch, setPageBatch] = useState(0);

  const [searchByBatch, setSearchByBatch] = useState('');

  const debouncedSearchTermBatch = useDebounce(searchByBatch, 500);

  const [showActivate, setShowActivate] = useState('closed');
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting carriers'}
        />
      )}
      <SuccessAlign>
        <div
          className={`success-container ${
            showSuccessActivate && 'open-drawer'
          } `}
        >
          <SuccessCard
            title={`Success ${
              !isActive ? 'Activating' : 'De-activating'
            } carrier`}
            message={`<b>${name} carrier has been ${
              !isActive ? 'Activated' : 'De-activated'
            }<b>`}
          />
          <button
            onClick={() => {
              setShowSuccessActivate(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
        <div
          className={`success-container ${showSuccessBatch && 'open-drawer'} `}
        >
          <SuccessCard
            title={`Success adding to system defined carrier`}
            message={`<b>Carriers has been added as system defined carrier<b>`}
          />
          <button
            onClick={() => {
              setShowSuccessBatch(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <MyBookDiv>
        <div className='mybook-content carrier-content'>
          <div className='d-flex justify-content-between'>
            <button
              className='addCarrierButton d-none d-sm-flex'
              onClick={() => setAddCarrier('open')}
            >
              Add carriers in batch
              <img src={plus} className='' alt='cross-icon' />
            </button>
            <div
              className='book-filter d-none d-sm-flex ms-auto'
              ref={searchRef}
            >
              {isSearchClicked || searchBy ? (
                <div className='book-search d-flex align-items-center'>
                  <img src={search} alt='' className='search-button' />
                  <input
                    onChange={(e) => {
                      setPage(0);
                      setSearchBy(e.target.value);
                    }}
                    autoFocus
                    value={searchBy}
                  />
                  <img
                    src={closeIcon}
                    alt=''
                    className='close-button'
                    onClick={() => {
                      setPage(0);
                      setSearchBy('');
                      setIsSearchClicked(false);
                    }}
                  />
                </div>
              ) : (
                <div
                  className='book-shrinked'
                  onClick={() => setIsSearchClicked(true)}
                >
                  <img src={search} alt='' />
                </div>
              )}

              <span className='divider d-none d-sm-block' />
              <div
                className='book-shrinked d-none d-sm-flex'
                onClick={() => setShowFilter('open')}
              >
                <img src={filterIcon} alt='' />
                {(status?.value ? 1 : 0) +
                  selectedPolicyTypes.length +
                  (jurisdictionsUUID ? 1 : 0) >
                  0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) +
                      selectedPolicyTypes.length +
                      (jurisdictionsUUID ? 1 : 0)}
                  </span>
                )}
              </div>
              <span
                className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
              />
              <div
                className={`book-shrinked d-sm-none ${
                  isSearchClicked && 'd-none'
                }`}
                onClick={() => setShowFilter('open')}
              >
                <img src={filter} alt='' />
                {(status?.value ? 1 : 0) +
                  selectedPolicyTypes.length +
                  (jurisdictionsUUID ? 1 : 0) >
                  0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) +
                      selectedPolicyTypes.length +
                      (jurisdictionsUUID ? 1 : 0)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='HeadDiv d-sm-none carrierHead-div'>
            <h2
              className='HeadNavigator'
              onClick={() =>
                navigate(
                  user.UserRole.name === 'ADMIN'
                    ? `/admin/agency/${uuid}`
                    : `/settings`
                )
              }
            >
              <img src={arrow} className='leftArrow back-button-head' alt='' />
              Carrier Settings
            </h2>
            <button
              onClick={() => setAddCarrier('open')}
              className='addButton d-sm-none'
            >
              <img src={plus} alt='add' />
            </button>
            <MobileTab uuid={uuid || ''} dropText='System Defined Carriers' />
            <div className='d-flex justify-content-between searchBackground'>
              <div className='d-flex searchField '>
                <img className='mx-2' src={search} alt='' />
                <input
                  className='inputSearchField '
                  type='search'
                  placeholder='Search carriers'
                  onChange={(e) => {
                    setPage(0);
                    setSearchBy(e.target.value);
                  }}
                />
              </div>
              <div
                className='d-flex searchField filterField position-relative'
                onClick={() => setShowFilter('open')}
              >
                <img src={filter} alt='' />
                {(status?.value ? 1 : 0) +
                  selectedPolicyTypes.length +
                  (jurisdictionsUUID ? 1 : 0) >
                  0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) +
                      selectedPolicyTypes.length +
                      (jurisdictionsUUID ? 1 : 0)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=' d-sm-none mobilecardHeadDiv'>
            {loadingStatus === 'loading' ? (
              [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
            ) : loadingStatus === 'succeeded' && carriers?.length > 0 ? (
              <>
                {carriers.map((item) => (
                  <CarriersMobileCard>
                    <div className='d-flex justify-content-between carrier-card-head'>
                      <span className='CarrierName'>
                        {item.name ? item.name : '-'}
                      </span>

                      <div
                        className={`d-flex align-items-baseline ${
                          item.Agencies?.find((el: IAgency) => el.uuid === uuid)
                            ?.isActive
                            ? 'activeStage'
                            : 'inactiveStage'
                        }`}
                      >
                        <div
                          className={`dot  mx-2 ${
                            item.Agencies?.find(
                              (el: IAgency) => el.uuid === uuid
                            )?.isActive
                              ? 'avtive'
                              : 'inactive'
                          }`}
                        ></div>
                        {item.Agencies?.find((el: IAgency) => el.uuid === uuid)
                          ?.isActive
                          ? 'Active'
                          : 'Inactive'}
                      </div>
                    </div>
                    <div className='d-block'>
                      <div className='stateandnames'>
                        States :{' '}
                        <b>
                          {item.jurisdictions?.length > 4
                            ? `${String(item.jurisdictions.slice(0, 4))} and ${
                                item.jurisdictions?.length - 4
                              } others`
                            : String(item.jurisdictions)}
                          {item.jurisdictions?.length < 1 && '-'}
                        </b>
                      </div>
                      <div className='stateandnames '>
                        Policies :{' '}
                        <b>
                          {item.Products?.map((el, index: number) => {
                            if (index < 3) {
                              return `${el.name
                                ?.toLowerCase()
                                ?.split('_')
                                ?.join(' ')}${
                                index !== item.Products?.length - 1 ? ', ' : ''
                              }`;
                            } else if (index === item.Products?.length - 1) {
                              return ` and ${item.Products?.length - 3} others`;
                            }
                            return null;
                          })}
                        </b>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between buttonsDiv'>
                      <div
                        className={`actionButton ${
                          item.Agencies?.find((el) => el.CarrierId === item.id)
                            ?.isActive
                            ? 'Inactivebtn'
                            : 'Activebtn'
                        }`}
                        onClick={() => {
                          setUUID(item.uuid);
                          setIsActive(
                            item.Agencies?.find(
                              (el) => el.CarrierId === item.id
                            )?.isActive || false
                          );
                          setShowActivate('open');
                          setName(item.name);
                        }}
                      >
                        {item.Agencies?.find((el) => el.CarrierId === item.id)
                          ?.isActive
                          ? 'De-activate'
                          : 'Activate'}
                      </div>
                      <div
                        className='d-flex align-items-center justify-content-end h-100 actionsButton'
                        style={{ background: 'none' }}
                      >
                        <div className='d-flex align-items-center  h-100'>
                          <ActionsDropdown
                            uuid={item.uuid}
                            onDelete={setDelete}
                            scroll={scroll}
                            setShowDeleteModal={setShowDeleteModal}
                            name={item.name}
                          />
                        </div>
                      </div>
                    </div>
                  </CarriersMobileCard>
                ))}
                <PaginationComponent
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  count={carrierCount}
                />
              </>
            ) : (
              <EmptyResults name='Carriers' />
            )}
            <MobileTabNav />
          </div>

          <div className='d-none d-sm-block'>
            <div className='d-none d-xl-block carrier-table'>
              <Table
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                setScroll={() => {
                  setScroll(!scroll);
                }}
                count={carrierCount}
                loadingStatus={loadingStatus}
                name='System defined carriers'
                columns={[
                  {
                    accessor: 'name',
                    resizable: true,
                    minWidth: 300,
                    sortable: true,
                    cellClass: 'carrierName',
                    headerCellClass: 'carrierName',
                    Header: () => (
                      <div
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Carrier</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'carrierName'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div className='row-name d-flex align-items-center'>
                        {row.original.logo ? (
                          <img
                            className='logoImage'
                            src={row.original.logo}
                            alt=''
                          />
                        ) : (
                          <img
                            src={defaultCarrier}
                            alt=''
                            className='logoImage'
                          />
                        )}
                        <b
                          onClick={() => {
                            // setShowDetailModal('open');
                            // setUUID(row.original.uuid);
                          }}
                        >
                          {row.original.name ? row.original.name : '-'}
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'States',
                    Header: 'States',
                    resizable: true,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex'>
                        {row.original.jurisdictions?.length > 4
                          ? `${String(
                              row.original.jurisdictions.slice(0, 4)
                            )} and ${
                              row.original.jurisdictions?.length - 4
                            } others`
                          : String(row.original.jurisdictions)}
                        {row.original.jurisdictions?.length < 1 && '-'}
                      </div>
                    ),
                  },
                  {
                    accessor: 'productCategories',
                    Header: 'Policies offered',
                    resizable: true,
                    sortable: true,

                    Cell: ({ row }: { row: any }) => (
                      <>
                        <div className='d-flex align-items-center h-100'>
                          {row.original.Products?.map(
                            (item: IProduct, index: number) => {
                              if (index < 3) {
                                return `${item.name
                                  ?.toLowerCase()
                                  ?.split('_')
                                  ?.join(' ')}${
                                  index !== row.original.Products?.length - 1
                                    ? ', '
                                    : ''
                                }`;
                              } else if (
                                index ===
                                row.original.Products?.length - 1
                              ) {
                                return ` and ${
                                  row.original?.Products?.length - 3
                                } others`;
                              }
                              return null;
                            }
                          )}
                          {row.original.Products?.length < 1 && '-'}
                        </div>
                      </>
                    ),
                  },
                  {
                    accessor: 'Status',
                    Header: 'Status',
                    resizable: true,
                    Cell: ({ row }: { row: any }) => (
                      <div className='row-status '>
                        <div
                          className={`d-flex align-items-center
                          ${
                            row.original.Agencies?.find(
                              (item: IAgency) =>
                                item.CarrierId === row.original.id
                            )?.isActive
                              ? 'activeStage'
                              : 'inactiveStage'
                          }`}
                        >
                          <div
                            className={`dot  mx-2 ${
                              row.original.Agencies?.find(
                                (item: IAgency) =>
                                  item.CarrierId === row.original.id
                              )?.isActive
                                ? 'avtive'
                                : 'inactive'
                            }`}
                          ></div>
                          {row.original.Agencies?.find(
                            (item: IAgency) =>
                              item.CarrierId === row.original.id
                          )?.isActive
                            ? 'Active'
                            : 'Inactive'}
                        </div>
                      </div>
                    ),
                  },
                  {
                    accessor: 'actions',
                    Header: 'Actions',
                    sticky: 'right',
                    resizable: false,
                    headerCellClass: 'actions-header',
                    width: window.innerWidth > 1900 ? 276 : 206,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center  h-100'>
                        <div
                          className={`actionButton ${
                            row.original.Agencies?.find(
                              (item: IAgency) =>
                                item.CarrierId === row.original.id
                            )?.isActive
                              ? 'Inactivebtn'
                              : 'Activebtn'
                          }`}
                          onClick={() => {
                            setUUID(row.original.uuid);
                            setIsActive(
                              row.original.Agencies?.find(
                                (item: IAgency) =>
                                  item.CarrierId === row.original.id
                              )?.isActive
                            );
                            setShowActivate('open');
                            setName(row.original.name);
                          }}
                        >
                          {row.original.Agencies?.find(
                            (item: IAgency) =>
                              item.CarrierId === row.original.id
                          )?.isActive
                            ? 'De-activate'
                            : 'Activate'}
                        </div>

                        <div
                          className='d-flex align-items-center justify-content-end h-100 actionsButton'
                          style={{ background: 'none' }}
                        >
                          <div className='d-flex align-items-center  h-100'>
                            <ActionsDropdown
                              uuid={row.original.uuid}
                              onDelete={setDelete}
                              scroll={scroll}
                              setShowDeleteModal={setShowDeleteModal}
                              name={row.original.name}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                data={carriers}
              />
            </div>

            <div className='d-none d-sm-block d-xl-none table-tab-view carrier-table'>
              <Table
                limit={limit}
                setLimit={setLimit}
                setScroll={() => {
                  setScroll(!scroll);
                }}
                page={page}
                setPage={setPage}
                count={carrierCount}
                loadingStatus={loadingStatus}
                name='System defined carriers'
                columns={[
                  {
                    accessor: 'name',
                    resizable: true,
                    minWidth: 300,
                    sortable: true,
                    cellClass: 'carrierName',
                    headerCellClass: 'carrierName',
                    Header: () => (
                      <div
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Carrier</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'carrierName'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div className='row-name d-flex align-items-center h-100'>
                        {row.original.logo ? (
                          <img
                            className='logoImage'
                            src={row.original.logo}
                            alt=''
                          />
                        ) : (
                          <div className='logoImage'>
                            <span>
                              {row.original.name[0]}
                              {row.original.name[1]}
                            </span>
                          </div>
                        )}
                        <b
                          onClick={() => {
                            // setShowDetailModal('open');
                            // setUUID(row.original.uuid);
                          }}
                          className='d-flex'
                        >
                          <div>
                            {row.original.name ? row.original.name : '-'}
                            <div
                              className={`d-flex align-items-center item-status
                          ${
                            row.original.Agencies?.find(
                              (item: IAgency) => item.uuid === uuid
                            )?.isActive
                              ? 'activeStage'
                              : 'inactiveStage'
                          }`}
                            >
                              <div
                                className={`dot ${
                                  row.original.Agencies?.find(
                                    (item: IAgency) => item.uuid === uuid
                                  )?.isActive
                                    ? 'avtive'
                                    : 'inactive'
                                }`}
                              ></div>
                              {row.original.Agencies?.find(
                                (item: IAgency) => item.uuid === uuid
                              )?.isActive
                                ? 'Active'
                                : 'Inactive'}
                            </div>
                          </div>
                        </b>
                      </div>
                    ),
                  },
                  {
                    accessor: 'States',
                    Header: 'States',
                    resizable: true,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center h-100'>
                        {row.original.jurisdictions?.length > 4
                          ? `${String(
                              row.original.jurisdictions.slice(0, 4)
                            )} and ${
                              row.original.jurisdictions?.length - 4
                            } others`
                          : String(row.original.jurisdictions)}
                        {row.original.jurisdictions?.length < 1 && '-'}
                      </div>
                    ),
                  },
                  {
                    accessor: 'productCategories',
                    Header: 'Policies offered',
                    resizable: true,
                    sortable: true,

                    Cell: ({ row }: { row: any }) => (
                      <>
                        <div className='d-flex align-items-center h-100'>
                          {row.original.Products?.map(
                            (item: IProduct, index: number) => {
                              if (index < 3) {
                                return `${item.name
                                  ?.toLowerCase()
                                  ?.split('_')
                                  ?.join(' ')}${
                                  index !== row.original.Products?.length - 1
                                    ? ', '
                                    : ''
                                }`;
                              } else if (
                                index ===
                                row.original.Products?.length - 1
                              ) {
                                return ` and ${
                                  row.original?.Products?.length - 3
                                } others`;
                              }
                              return null;
                            }
                          )}
                          {row.original.Products?.length < 1 && '-'}
                        </div>
                      </>
                    ),
                  },
                  {
                    accessor: 'actions',
                    Header: 'Actions',
                    sticky: 'right',
                    resizable: false,
                    headerCellClass: 'actions-header',
                    width: window.innerWidth > 1900 ? 276 : 206,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center  h-100'>
                        <div
                          className={`actionButton ${
                            row.original.Agencies?.find(
                              (item: IAgency) => item.uuid === uuid
                            )?.isActive
                              ? 'Inactivebtn'
                              : 'Activebtn'
                          }`}
                          onClick={() => {
                            setUUID(row.original.uuid);
                            setIsActive(
                              row.original.Agencies?.find(
                                (item: IAgency) => item.uuid === uuid
                              )?.isActive
                            );
                            setShowActivate('open');
                            setName(row.original.name);
                          }}
                        >
                          {row.original.Agencies?.find(
                            (item: IAgency) => item.uuid === uuid
                          )?.isActive
                            ? 'De-activate'
                            : 'Activate'}
                        </div>

                        <div
                          className='d-flex align-items-center justify-content-end h-100 actionsButton'
                          style={{ background: 'none' }}
                        >
                          <div className='d-flex align-items-center  h-100'>
                            <ActionsDropdown
                              uuid={row.original.uuid}
                              onDelete={setDelete}
                              scroll={scroll}
                              setShowDeleteModal={setShowDeleteModal}
                              name={row.original.name}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                data={carriers}
              />
            </div>
          </div>
          <div className='d-xl-none'>
            <MobileTabNav />
          </div>
        </div>
      </MyBookDiv>
      {addCarrier !== 'closed' && (
        <CarrierBatchDrawer
          loadingStatus={loadingStatus}
          addCarrier={addCarrier}
          onCloseModal={onCloseModal}
          setShowFilter={setShowBatchFilter}
          debouncedSearchTerm={debouncedSearchTermBatch}
          setSearchBy={setSearchByBatch}
          sortBy={sortByBatch}
          setSortBy={setSortByBatch}
          orderBy={orderByBatch}
          setOrderBy={setOrderByBatch}
          limit={limitBatch}
          setLimit={setLimitBatch}
          page={pageBatch}
          setPage={setPageBatch}
          filterCount={
            selectedBatchPolicyTypes.length + (jurisdictionsUUIDBatch ? 1 : 0)
          }
          selectedBatchPolicyTypes={selectedBatchPolicyTypes}
          jurisdictionsUUIDBatch={jurisdictionsUUIDBatch}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='system carrier'
          name={name}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <SystemCarrierFilter
            show={showFilter}
            closeModal={setShowFilter}
            setStatus={setStatus}
            status={status}
            setSelectedPolicyTypes={setSelectedPolicyTypes}
            selectedPolicyTypes={selectedPolicyTypes}
            batchFilter={false}
            licenseSearch={licenseSearch}
            setLicenseSearch={setLicenseSearch}
            jurisdictionsUUID={jurisdictionsUUID}
            setJurisdictionsUUID={setJurisdictionsUUID}
          />
        )}
        {showBatchFilter !== 'closed' && (
          <SystemCarrierFilter
            show={showBatchFilter}
            closeModal={setShowBatchFilter}
            setSelectedPolicyTypes={setSelectedBatchPolicyTypes}
            selectedPolicyTypes={selectedBatchPolicyTypes}
            batchFilter={true}
            licenseSearch={licenseSearchBatch}
            setLicenseSearch={setLicenseSearchBatch}
            jurisdictionsUUID={jurisdictionsUUIDBatch}
            setJurisdictionsUUID={setJurisdictionsUUIDBatch}
          />
        )}
      </FilterContainer>
      {showActivate !== 'closed' && (
        <ActivateModal
          showModal={showActivate}
          closeModal={setShowActivate}
          submitFunction={activateAction}
          uuid={UUID}
          loading={loadingStatus}
          resource='system carrier'
          name={name}
          isActive={isActive}
        />
      )}
    </>
  );
};
export default SystemDefinedCarriers;

const ActionsDropdown = ({
  uuid,
  onDelete,
  setShowDeleteModal,
  scroll,
  name,
}: {
  uuid: string;
  onDelete: Function;
  setShowDeleteModal: Function;
  scroll: boolean;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='dropdown-div position-fixed'
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 110,
          }}
        >
          <div
            className='delete-div'
            onClick={() => {
              setShowActionsDropdown(!showActionsDropdown);
              onDelete(uuid, name);
              setShowDeleteModal('open');
            }}
            tabIndex={0}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};

import styled from '@emotion/styled';

export const StyledTab = styled.div`
  border-bottom: 1px solid #e6e9f0;
  position: relative;

  .navItems {
    color: #000000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
    cursor: pointer;
  }

  .count {
    background: #e6e9f0;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 4px;
    border-radius: 8px;
  }

  .active {
    font-weight: 600;
    border-bottom: 3px solid #262758;
    padding-bottom: 7px;
    margin-bottom: -2px;

    .count {
      background: #c1dbeb;
    }
  }
`;
export const FieldComponents = styled.div`
  .dropDown {
    border: 1px solid #cad9f3;
    width: 100%;
    background: #fff;
    height: 100%;
  }
  .addTaskButton {
    padding: 20px 24px;
  }
  .input-container .react-datepicker-wrapper .date-input {
    padding: 7px 35px 0 16px;
  }
  .dropDown:focus {
    border: 1px solid #3c498a;
  }
  .First {
    border-radius: 8px 0px 0px 8px;
  }
  .Last {
    border-radius: 0px 8px 8px 0px;
  }
  .Middle {
    border-radius: 0;
  }
  .guest-addContainer {
    position: relative;
    padding: 20px 0px 0px 0px;
    .usersContainer {
      top: 60px;
    }
  }
  .guestsContainer {
    padding-left: 10px;
    .profile-pic {
      margin-left: -16px;
      background: #262758;
      border-radius: 46px;
      margin-right: 16px;
      color: #fff;
      text-transform: uppercase;
      margin-right: 0;
      border: 1px solid #fff;
      width: 40px;
      height: 40px;
    }
  }
  .addUser {
    width: 40px;
    height: 40px;
    background: #ecf4f9;
    border-radius: 50%;
  }
  .addUsersinTask {
    position: relative;
    height: 40px;
    padding: 5px;
    width: 160px;
    background: #e6e9f0;
    border-radius: 20px;
    .profile-pic {
      width: 32px;
      height: 32px;
      font-size: 14px;
      line-height: 16px;
      margin-right: 8px;
    }
    .drop-icon {
      margin-right: 12px;
    }
  }

  .usersContainer {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid #e6e9f0;
    background: #fff;
    width: 200px;
    max-height: 200px;
    overflow: auto;
  }

  .usersContainer::-webkit-scrollbar {
    display: none;
  }
  .addDocument {
    border: none;
    width: 40px;
    height: 40px;
    background: #e6e9f0;
    border-radius: 8px;
  }
  .usersList {
    display: flex;
    height: 40px;
    margin: 10px 8px;
    border-bottom: 1px solid #e6e9f0;
    align-items: center;
  }
  .usersList:last-child {
    border: none;
  }
  .usersListOrder {
    padding: 0px;
    list-style-type: none;
    margin-bottom: 0;
  }
  .react-datepicker {
    width: max-content;
  }
  .toolbar-button-container {
    margin-bottom: 0;
  }
  .rdw-image-wrapper {
    margin-bottom: 0;
  }
  .toolbar-button-container > div,
  .image-upload {
    padding: 3px 10px 5px;
    border-radius: 8px;
    margin: 0 1px;
    background: transparent;
    height: 32px;
    width: 32px;
    border: none;
    &[aria-selected='true'] {
      background: #3c498a;
      box-shadow: none;
      img {
        filter: invert(1);
      }
    }
  }
  .demo-editor {
    padding: 12px;
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
  }
  .addNoteButtonContainer {
    padding: 16px 24px;
    button {
      margin-left: auto;
      display: block;
    }
  }
  .demo-wrapper {
    border: 1px solid #cad9f3;
    border-radius: 8px;
  }
  .toolbar-container {
    background: #ecf4f9;
    border-radius: 6px;
    padding: 6px 12px;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .errorVal {
    margin-top: 12px;
    font-size: 12px;
    color: #cc0000;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1200px) {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 576px) {
    .guest-addContainer {
      position: relative;
      padding: 16px 23px 14px 15px;
      .usersContainer {
        top: 60px;
      }
      .innertab-event-guest .TopContainer .GuestDropDown {
        left: -25px;
      }
    }
  }

  @media screen and (min-width: 1900px) {
    .guest-addContainer {
      padding: 16px 23px 24px 15px;
    }
  }
`;

export const ActivitiesStyledDiv = styled.div`
  padding: 40px 0px;
  height: 50%;
  .titleHead {
    color: #191919;
    font-size: 20px;
    position: relative;
  }
  .book-shrinked {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    background: #e6e9f0;
    margin-right: 16px;
  }
  .book-filter {
    max-width: calc(100% - 10px);
  }
  .book-search {
    width: 100%;
    padding-left: 0;
    justify-content: end;
    margin-right: 16px;
    input {
      background: #e6e9f0;
      border: 1px solid #262758;
      outline: none;
      border-radius: 8px;
      width: 384px;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;
    }

    .search-button {
      margin-right: -32px;
      position: relative;
      z-index: 1;
      filter: invert(0.3);
    }

    .close-button {
      margin: 0 20px 0 -40px;
      cursor: pointer;
    }
  }
  .task-text-mobile {
    width: calc(100% - 50px);
    border-right: 2px solid #e6e9f0;
    padding-right: 16px;
    margin-right: 16px;
  }
  .filter-counter {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: -1px;
    right: -3px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
    align-items: center;
  }
  .scroll-div {
    overflow-y: scroll;
    max-height: 35vh;
    padding: 0 0 20px 20px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  .navbar-button {
    margin-right: 16px;
    background: #e6e9f0;
    border-radius: 8px;
    border: none;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
  }
  .filter-button {
    background: #e6e9f0;
    border-radius: 8px;
    width: 212px;
    color: #262758;
    cursor: pointer;
    white-space: break-spaces;
  }
  .timeline {
    background: var(--primary-color);

    padding: 0;
    > .card {
      padding: 0 0 10px 20px;
      margin-left: 20px;
      .outer {
        bottom: 0;
        height: 72px;
      }
    }
  }

  .outer {
    position: absolute;
    height: 100%;
    width: 2px;
    left: -13px;
    bottom: 0;
    background: repeating-linear-gradient(
      180deg,
      #d1d6e5,
      #d1d6e5 5px,
      #fff 5px,
      #fff 10px
    );
  }

  .activity-scroll {
    overflow-y: auto;
    max-height: 80vh;
    padding-left: 20px;
    padding-bottom: 50px;
    overflow-x: hidden;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .card {
    position: relative;
    padding: 10px 0 10px 10px;
    color: gray;
    border-radius: 8px;
    border: none;
    max-width: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .imageCard {
    color: #cad9f3;
  }
  .title {
    padding-top: 6px;
    color: #262758;
    font-size: 16px;
    padding-left: 5px;
  }
  .Heading {
    padding-left: 10px;
  }
  .dateFont {
    color: #8990a3;
    font-weight: 400;
  }
  .added {
    color: #191919;
    font-weight: 400;
  }

  .imageCard::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    left: -18px;
    background: #cad9f3;
    z-index: 2;
    @media screen and (max-width: 576px) {
      top: 16px;
    }
  }

  .user-pic {
    width: 35px;
  }
  .contentDiv {
    margin: 0 20px;
    padding: 8px 18px;
    border: solid 1px #e6e9f0;
    font-size: 12px;
    color: #262758;
    border-radius: 8px;
  }

  .priorityDiv {
    text-transform: capitalize;
    margin-left: 13px;
    padding: 4px 18px;
    font-size: 12px;
    border-radius: 16px;
    height: 20px;
    font-weight: 600;
    line-height: 12px;
  }
  .lowPriority {
    background: #d4fad4;
    color: #3d8a68;
  }
  .highPriority {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .mediumPriority {
    background: #fcf2cf;
    color: #914123;
  }
  .task-type-icon-div {
    width: 32px;
    height: 32px;
    text-align: center;
    background: #ecf4f9;
    border-radius: 8px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    border: none;
    &.task-drop {
      background: #f5f7fc;
      margin-left: 16px;
    }
  }
  .event-button-container {
    position: relative;
  }
  .divider {
    margin: 0 4px 0px 8px;
    border-right: 3px solid #f5f7fc;
  }
  .search {
    width: 25px;
  }
  .filter {
    width: 30px;
  }
  .edit-delete-drop {
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid #e6e9f0;
    background: #fff;
    width: 136px;
    max-height: 200px;
    overflow: auto;
    & > div {
      padding: 4px 15px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .editdiv {
      border-bottom: 1px solid #e6e9f0;
    }
    .deletediv {
      color: #9a0a0d;
    }
  }
  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    border: none;
    width: 48px;
    height: 48px;
    right: 20px;
    background: #262758;
    border-radius: 50%;
    z-index: 3;
    .dropUp {
      position: absolute;
      width: 227px;
      top: -145px;
      z-index: 5;

      padding: 10px 12px 5px 5px;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      .tasks {
        padding: 8px 12px;
        border-bottom: 1px solid #e6e9f0;
        border-radius: 8px;
        margin: 0;
        font-weight: 600;
        line-height: 24px;
        &:last-child {
          border-bottom: none;
        }
      }
      .tasks:hover {
        background: #ecf4f9;
      }
    }
  }
  .addButton-background {
    position: fixed;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(35, 42, 64, 0.4);
    top: 0;
    z-index: 3;
  }
  .dropOpen {
    img.addButton-img {
      transform: rotate(45deg);
      filter: invert(1);
    }
    background: #f5f7fc;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 126px;
      max-width: 170px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 40px;
      left: -10px;
      overflow-wrap: break-word;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .timeline {
      padding: 0;
      > .card {
        .outer {
          height: 80px;
        }
      }
    }
    .contentDiv {
      margin: 0 50px;
      padding: 15px 20px;
      margin-left: 88px;
    }
    .activity-scroll {
      max-height: 40vh;
      padding-bottom: 30px;
    }
    .book-search {
      input {
        width: 320px;
      }
    }
    .dateFont {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 1200px) {
    .activity-scroll {
      max-height: calc(100vh - 600px);
    }

    .contentDiv {
      margin-left: 80px;
      padding: 16px 30px;
    }
    .book-search {
      input {
        width: 384px;
      }
    }
    .dateFont {
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (min-width: 1900px) {
    .activity-scroll {
      max-height: calc(100vh - 640px);
    }
  }
  @media screen and (max-width: 576px) {
    .activity-scroll {
      max-height: calc(100vh - 220px);
      overflow-x: hidden;
    }
    .task-edit-container {
      .field,
      .fields {
        text-overflow: ellipsis;
        margin-top: 8px;
        div,
        .input-field {
          border-radius: 8px;
        }
      }
    }
    h6.due-div {
      margin-right: 8px;
    }
    .react-datepicker {
      display: flex;
    }

    .tab-content-div {
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
      .input-placeholder,
      .date-placeholder {
        font-size: 14px;
      }
    }
    .title {
      padding-left: 0;
      padding-right: 5px;
    }
    .contentDiv {
      margin-left: 0;
      margin-right: 0;
    }
    .mobile-imagecard {
      position: relative;
      &::before {
        top: 7px;
        left: -28px;
      }
    }
    .mobile-notes-container {
      .textarea {
        padding-left: 0;
        padding-right: 0;
      }
      .imageCard::before {
        display: none;
      }
      .fields {
        margin-top: 8px;
        .react-datepicker__day--range-end {
          border-radius: 40px;
        }
        .react-datepicker__day--selected {
          border-radius: 40px;
        }
        div {
          border-radius: 8px;
        }
      }
    }
  }
`;

export const EditTask = styled.div`
  .addUsersinTask {
    position: relative;
    height: 40px;
    padding: 5px;
    width: 160px;
    background: #e6e9f0;
    border-radius: 20px;
    color: #000;
    .profile-pic {
      width: 32px;
      height: 32px;
      font-size: 14px;
      line-height: 16px;
      margin-right: 8px;
    }
    .drop-icon {
      margin-right: 12px;
    }
  }
  .imageCard .task-edit-container .addTaskButton {
    padding: 20px 35px;
    @media screen and (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .usersContainer {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid #e6e9f0;
    background: #fff;
    width: 200px;
    max-height: 200px;
    overflow: auto;
  }

  .usersContainer::-webkit-scrollbar {
    display: none;
  }
  .usersList {
    display: flex;
    height: 40px;
    margin: 10px 8px;
    border-bottom: 1px solid #e6e9f0;
    align-items: center;
  }
  .usersList:last-child {
    border: none;
  }
  .usersListOrder {
    padding: 0px;
    list-style-type: none;
    margin-bottom: 0;
  }
  .task-edit-container {
    width: calc(100% - 99px);
    .cancelEdit {
      background: #e6e9f0;
      color: #000;
      border-radius: 8px;
      border: none;
      padding: 8px 15px;
      margin-right: 8px;
    }
    .addTaskButton {
      padding: 20px 22px;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  .event-edit-container {
    .usersList {
      color: #000;
    }
  }
  .DocNameField {
    width: 150px;
    color: #000;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 576px) {
      width: unset;
      max-width: 121px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .addDocument {
    border: none;
    width: 40px;
    height: 40px;
    background: #e6e9f0;
    border-radius: 8px;
  }

  .errorVal {
    font-size: 12px;
    color: #cc0000;
    margin-top: 2px;
  }

  @media screen and (min-width: 1200px) {
    .imageCard .task-edit-container {
      .tab-content-div {
        padding-left: 10px;
      }
      .addTaskButton {
        padding-left: 10px;
        padding-right: 25px;
      }
    }
  }
`;

export const StyledDiv = styled.div`
  .activities-div {
    border: 1px solid #ffffff;
    margin-top: 25px;
    padding: 23px 0 21px 0;
    border-radius: 8px;
    background: #fafafa;

    .active {
      border-bottom: 3px solid #c1dbeb;
    }
  }
  .scrollActivity {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 180px);
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &.lead-activity .scrollActivity {
    height: calc(100vh - 200px);
  }

  &.lead-activity .imageCard::before {
    left: -28px;
  }

  .tab-content-div {
    padding: 16px 23px 0px 24px;
  }
  .DocNameField {
    width: 150px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  .due-div {
    color: #5a5f70;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .due-div ~ span {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #262758;
  }
  .documentIcon {
    width: 20px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 300px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 35px;
      left: 0;
      overflow-wrap: break-word;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .scrollActivity {
      height: calc(100vh - 190px);
    }
  }

  @media screen and (min-width: 1200px) {
    .scrollActivity {
      height: calc(100vh - 260px);
    }
  }

  @media screen and (min-width: 1900px) {
    .activities-div {
      margin-top: 32px;
      padding: 24px 0 0 0;
    }
    .scrollActivity {
      height: calc(100vh - 300px);
    }

    .tab-content-div {
      padding: 24px 32px 0px 32px;
    }
  }
`;
export const NotesMobileDiv = styled.div`
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 30;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  .mobile-notes-container {
    padding: 0 20px;
  }
  .input-field {
    padding: 12px 10px;
  }
  .filled.input-container .date-input {
    padding: 8px 10px;
  }
  .filled label.date-placeholder,
  .filled label.input-placeholder {
    font-size: 12px;
  }
  .buttonsDiv {
    position: fixed;
    width: 100%;
    padding: 16px 20px;
    border-top: 1px solid #e6e9f0;
    height: 64px;
    bottom: 0;
    background: #fff;
  }

  .backButton {
    padding: 0 18px;
    border: none;
    height: 34px;
    width: 104px;
    background: #e6e9f0;
    border-radius: 8px;
  }
  .addNotesButton {
    padding: 0 18px;
    border: none;
    height: 34px;
    width: 200px;
    background: #ecf4f9;
    border-radius: 8px;
  }

  .cross {
    transform: rotate(180deg);
    filter: brightness(0);
  }
  .navContainer {
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #e6e9f0;
  }
  .navButton {
    cursor: pointer;
    margin: 0 24px;
  }
  .active {
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 3px solid #262758;
  }
  .textarea {
    height: 200px;
    padding: 20px;
    width: 100%;
  }
  .mobile-notes-container .input-container label {
    left: 9px;
  }
  .DocNameField {
    width: unset;
    max-width: 121px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .addTaskButton .tertiaryButton {
    position: fixed;
    right: 16px;
    bottom: 13px;
    z-index: 4;
  }
`;

type DivProps = {
  isEdit: boolean;
};

export const StyledGuestComponent = styled.div<DivProps>`
  .ManageGuestHead {
    color: #262758;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding: 0px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      text-decoration-color: #3c498a;
      text-decoration-thickness: 2px;
    }
    .GuestDropDown {
      top: 30px;
    }
  }
  .TopContainer {
    .GuestDropDown {
      left: 0;
    }
  }
  .Guestprofile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    letter-spacing: -1px;
    border-radius: 46px;
    margin-left: -12px;
    z-index: 0;
    border: 1.5px solid #fff;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  .GuestprofileNumber {
    background: #e6e9f0;
    color: #000;
  }
  @media screen and (max-width: 576px) {
    .GuestDropDown {
      left: ${(props) => (props.isEdit ? '-100px !important' : '2px')};
    }
  }
  @media screen and (min-width: 576px) {
    .GuestDropDown {
      left: ${(props) => (props.isEdit ? '-100px !important' : '2px')};
    }
  }
`;

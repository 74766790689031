import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../../store';
import InputField from '../../../../components/InputField';
import { StyledModal } from '../../../Pipeline/elements/styles';
import close from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import { createProductCategory } from '../../../../store/productCategories/productCategoriesSlice';

const PolicyCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter the name.')
    .max(25, 'Name length must be less than 25.'),
});

const AddPolicyCategoryModal = ({
  showAddPolicyCategory,
  setShowAddPolicyCategory,
  setSuccessMessage,
}: {
  showAddPolicyCategory: boolean;
  setShowAddPolicyCategory: Function;
  setSuccessMessage: Function;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PolicyCategorySchema),
  });

  const watchName = watch('name');

  const onSubmit = (data: any) => {
    data.agencyUUID = uuid;
    data.action = 'createCustom';
    dispatch(
      createProductCategory({
        data,
        successCB: () => {
          setShowAddPolicyCategory(false);
          setSuccessMessage(
            `<b>${watchName}</b> has been added as a custom product category.`
          );
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  return (
    <StyledModal
      show={showAddPolicyCategory}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt=''
        src={close}
        className='closeButton'
        onClick={() => setShowAddPolicyCategory(false)}
      />
      <h2 className='mb-3'>Add policy category </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          name='name'
          type='text'
          placeholder='Name of policy category'
          register={register}
          required
          className={watchName && 'filled'}
        />

        {errors.name ? (
          <div className='errorVal'>
            <img src={warning} alt='warning' className='warning' />
            {errors.name?.message}
          </div>
        ) : null}

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowAddPolicyCategory(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='primaryButton' type='submit'>
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default AddPolicyCategoryModal;

import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import ProfileSttings from './components/ProfileSttings';
import MobileNav from '../../components/MobileTabNav';
import ChangePassword from './components/ChangePassword';
import Link from '../../components/Link';
import NotificationsSettings from './components/NotificationsSettings';
import { UserProfile } from './styles';
import arrow from '../../assets/logo/back.svg';

const MyProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <UserProfile>
        <div className='d-none d-xl-block'>
          <SideBar />
        </div>
        <TopBar>
          <div
            className='d-flex align-items-centers'
            onClick={() => navigate(-1)}
          >
            <div className='mobileNavBar d-flex align-items-center'>
              <img src={arrow} className='leftarrow  ' alt='leftarrow' />
              <h2 className='d-none d-xl-block '>Go back</h2>
              <h2 className=' d-xl-none '>
                {location.pathname.split('/').pop() === 'profilesetting'
                  ? 'My Profile'
                  : location.pathname.split('/').pop() ===
                    'notificationsettings'
                  ? 'Notification Settings'
                  : 'Change password'}
              </h2>
            </div>
          </div>
        </TopBar>
        <div className='d-block d-sm-flex '>
          <div className='sideBar d-none d-xl-block'>
            <div className='sideBarPadding'>
              <Link to={`/myprofile/profilesetting`}>My Profile</Link>
              <Link to={`/myprofile/notificationsettings`}>
                Notification Settings
              </Link>
              <Link to={`/myprofile/changepassword`}>Change Password</Link>
            </div>
          </div>
          <div className='w-100'>
            <Routes>
              <Route path='/profilesetting' element={<ProfileSttings />} />
              <Route path='/changepassword' element={<ChangePassword />} />
              <Route
                path='/notificationsettings'
                element={<NotificationsSettings />}
              />
            </Routes>
          </div>
        </div>
      </UserProfile>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </>
  );
};

export default MyProfile;

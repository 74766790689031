import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { DashboardSliceState } from '../../../store/dashboard/dashboardSlice';
import LeaderCard from './LeaderCard';
import EmptyResults from '../../MyBook/components/Empty';
import { TableDiv } from '../../../styles/TableDiv';
import { LeaderBoardMobile, MobileCard } from '../style';
import { CardDiv } from '../../../styles/CardDiv';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';

const LeaderboardTable = ({
  leaders,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
  isMobile,
}: {
  leaders: any;
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
  isMobile: boolean;
}) => {
  const loadingStatus = useSelector(
    (state: { dashboard: DashboardSliceState }) => state.dashboard.status
  );

  const [showAllLeader, setShowAllLeader] = useState(false);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return !isMobile ? (
    <>
      <div className='leader-scroll'>
        <TableDiv>
          <thead>
            <tr>
              <th
                className='statusContainerHead'
                onClick={() => handleSort('rank')}
              >
                <div className='d-flex justify-content-between'>
                  Rank
                  <img
                    className='sort'
                    src={
                      sortBy !== 'rank'
                        ? sort
                        : orderBy === 'asc'
                        ? sortdown
                        : sortup
                    }
                    alt='sort'
                  />
                </div>
              </th>
              <th className='agencyNameHead' onClick={() => handleSort('name')}>
                <div className='d-flex'>
                  <div className='w-100 d-flex justify-content-between'>
                    User name
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                </div>
              </th>
              <th
                className='agencyNameHead'
                onClick={() => handleSort('premium')}
              >
                <div className='d-flex'>
                  <div className='w-100 d-flex justify-content-between'>
                    Premium goal
                    <img
                      className='sort'
                      src={
                        sortBy !== 'premium'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                </div>
              </th>
              <th
                className='agencyNameHead'
                onClick={() => handleSort('policy')}
              >
                <div className='d-flex'>
                  <div className='w-100 d-flex justify-content-between'>
                    Policy count
                    <img
                      className='sort'
                      src={
                        sortBy !== 'policy'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                </div>
              </th>
              <th className='statusContainerHead'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loadingStatus === 'loading' ? (
              [1, 2, 3].map((index) => (
                <CardDiv>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <td key={item}>
                      <Skeleton height={24} />
                    </td>
                  ))}
                </CardDiv>
              ))
            ) : loadingStatus === 'succeeded' && leaders?.length > 0 ? (
              leaders?.map((el: any, index: number) =>
                showAllLeader || index < 3 ? (
                  <LeaderCard leader={el} key={el.uuid} index={index} />
                ) : null
              )
            ) : (
              <tr className='empty-div'>
                <td colSpan={7} className='d-none d-xl-table-cell'>
                  <EmptyResults name='leader' />
                </td>
              </tr>
            )}
          </tbody>
        </TableDiv>
      </div>
      {leaders?.length > 3 ? (
        <span
          className='show-all'
          onClick={() => setShowAllLeader(!showAllLeader)}
        >
          {showAllLeader ? 'Show less' : 'Show all'}
        </span>
      ) : null}
    </>
  ) : (
    <LeaderBoardMobile>
      {loadingStatus === 'loading' ? (
        <MobileCard>
          {[1, 2, 3].map((item) => (
            <Skeleton height={80} />
          ))}
        </MobileCard>
      ) : loadingStatus === 'succeeded' && leaders?.length > 0 ? (
        leaders?.map((el: any, index: number) =>
          showAllLeader || index < 3 ? (
            <LeaderCard
              leader={el}
              key={el.uuid}
              index={index}
              isMobile={isMobile}
            />
          ) : null
        )
      ) : (
        <EmptyResults name='leader' />
      )}
      {leaders?.length > 3 ? (
        <span
          className='show-all'
          onClick={() => setShowAllLeader(!showAllLeader)}
        >
          {showAllLeader ? 'Show less' : 'Show all'}
        </span>
      ) : null}
    </LeaderBoardMobile>
  );
};

export default LeaderboardTable;

import * as dotenv from 'dotenv'; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import

export const env = {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  isStaging: process.env.NODE_ENV === 'test',
  app: {
    baseUrl: process.env.REACT_APP_BASEURL,
    googleKey: process.env.REACT_APP_GOOGLE_API_KEY,
  },
};

import axios from './axios';

const fetchDashboard = () => {
  return axios.get('/dashboard').then((res) => {
    return res;
  });
};

const fetchGoal = (duration: string, goalType: string, showAgency?: string) => {
  return axios
    .get('/dashboard/goal', { params: { duration, goalType, showAgency } })
    .then((res) => {
      return res;
    });
};

const fetchPolicy = (duration: string, showAgency?: string) => {
  return axios
    .get('/dashboard/policy', { params: { duration, showAgency } })
    .then((res) => {
      return res;
    });
};

const fetchAccount = (duration: string, showAgency?: string) => {
  return axios
    .get('/dashboard/account', { params: { duration, showAgency } })
    .then((res) => {
      return res;
    });
};

const fetchFunnelGraph = (
  pipelineUUID: string,
  duration?: string,
  showAgency?: string
) => {
  return axios
    .get('/dashboard/graph', {
      params: { duration, name: 'leadFunnel', pipelineUUID, showAgency },
    })
    .then((res) => {
      return res;
    });
};

const fetchsalesGraph = (type: string, showAgency?: string) => {
  return axios
    .get('/dashboard/graph', {
      params: { name: 'salesGraph', type, showAgency },
    })
    .then((res) => {
      return res;
    });
};

const fetchLeaders = (
  duration: string,
  showAgency: string,
  sortBy: string,
  orderBy: string
) => {
  return axios
    .get('/dashboard/leaderboard', {
      params: { duration, showAgency, sortBy, orderBy },
    })
    .then((res) => {
      return res;
    });
};

export {
  fetchDashboard,
  fetchGoal,
  fetchPolicy,
  fetchAccount,
  fetchFunnelGraph,
  fetchsalesGraph,
  fetchLeaders,
};

import styled from '@emotion/styled';

export const SideBarDiv = styled.div`
  width: 84px;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
  padding: 0 8px;
  :hover {
    width: 204px;
    box-shadow: 0px 2px 5px rgba(35, 42, 64, 0.2);
    transition: all 0.5s ease;
    .nav-textdiv {
      display: block d-sm-flex;
      position: absolute;
      margin-left: 61px;
      margin-top: 7px;
      white-space: nowrap;
    }
    .vector-img {
      margin-left: 32px;
      dispaly: block;
      align-items: center;
      margin-top: -5px;
      animation: fadeIn 0.5s;
    }
    .vector-rotate {
      margin-left: 32px;
      dispaly: block;
      align-items: center;
      transform: rotate(180deg);
    }
    .nav-text {
      text-align: center;
      animation: fadeIn 0.5s;
    }
    .quickadd-text {
      display: block;
      position: absolute;
      margin-left: 62px;
      margin-top: 39px;
      white-space: nowrap;
    }
  }

  @keyframes fadeIn {
    0% {
      visibility: hidden;
    }
    90% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
  .LogoDiv {
    width: 60px;
    padding: 25px 10px 20px 15px;
  }
  .quickAddButton {
    position: relative;
    margin: 28px auto 44px auto;
    margin-bottom: 44px;
    margin-left: 12px;
    height: 40px;
    width: 40px;
    background: #262758;
    border-radius: 8px;
    border: 0;
    z-index: 5;
    .quickFormsDropDown {
      left: 8px;
      top: 45px;
      position: absolute;
      padding: 10px 16px;
      width: 208px;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      background: #fff;
      border-radius: 8px;
      .quickFormBtn {
        color: #262758;
        border-bottom: 1px solid #e6e9f0;
        padding: 6px 0;
        text-align: initial;
        font-size: 14px;
        :hover {
          background: #f5f7fc;
          border-radius: 8px;
        }
        .imageIcon {
          margin-right: 12px;
          width: 20px;
        }
        .imageIc {
          width: 20px;
          margin-right: 9px;
        }
      }
      .lastQuickFormBtn {
        border-bottom: none;
      }
    }
    .admin-quickAdd {
      height: auto;
    }
  }

  .navlink {
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    padding: 6px 0;
    margin-bottom: 32px;
    border-radius: 8px;
  }
  .nav-logo {
    width: 28px;
    height: 28px;
    border: 1px solid #24293d;
    margin-left: 20px;
  }
  .navlink {
    img {
      border: none;
    }
  }

  .active,
  .navlink:hover {
    background: #f5f7fc;
  }
  .vector-nav {
    position: relative;

    .drop-Div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      z-index: 1;
      width: 209px;
      bottom: -185px;
      cursor: pointer;
      line-height: 22px;
      margin-left: 20px;
      div {
        padding: 4px 0;
      }
      .mybook-drop {
        color: #000000;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-top: 3px;

        img {
          width: 20px;
        }
      }
      .mybook-drop:hover {
        background: #f5f7fc;
        border-radius: 8px;
      }
      .image-drop {
        margin-right: 15.5px;
      }
      .image-account {
        margin-right: 12.5px;
      }
    }
  }

  .nav-textdiv {
    display: none;
  }
  .quickadd-text {
    display: none;
  }
  .nav-text {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  .mybook-navdiv {
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    padding: 6px 0;
    margin-bottom: 32px;
    border-radius: 8px;
    cursor: pointer;
    img {
      border: none;
    }
    :hover {
      background: #f5f7fc;
    }
  }
  .active-div {
    background: #f5f7fc;
    border-radius: 8px;
  }
  @media (min-width: 1900px) {
    width: 88px;
    padding: 0 10px;
    .LogoDiv {
      padding: 30px 10px 15px 10px;
    }
  }

  @media (min-width: 2800px) {
    width: 176px;
    .LogoDiv {
      padding: 40px 10px 15px 10px;
    }
    .logo {
      width: 77px;
    }

    .navlink {
      padding: 16px 0 12px 0;
      font-size: 24px;
    }

    .nav-logo {
      width: 52px;
      height: 52px;
      margin: 0 auto 6px auto;
    }
  }
`;

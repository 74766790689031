import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../store';
import {
  createLeadSource,
  selectLeadSourceTypes,
} from '../../../store/leads/leadsSlice';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import { ILeadSourceType } from '../../../interfaces/Lead';
import { StyledModal } from '../../Pipeline/elements/styles';
import close from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

const LeadSourceSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter the lead source name.')
    .max(25, 'Name length must be less than 25.'),
  type: Yup.object().shape({
    label: Yup.string().required('Please select lead source type.'),
    value: Yup.string().required('Please select lead source type.'),
  }),
});

const AddLeadSource = ({
  showAddSource,
  setShowAddSource,
  setSuccessMessage,
  defaultType,
}: {
  showAddSource: boolean;
  setShowAddSource: Function;
  setSuccessMessage: Function;
  defaultType?: ILeadSourceType;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const types = useSelector(selectLeadSourceTypes);
  const [errorMessage, setError] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LeadSourceSchema),
  });

  const watchName = watch('name');

  useEffect(() => {
    if (defaultType)
      setValue('type', { label: defaultType.name, value: defaultType.uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddSource = (data: any) => {
    data.agencyUUID = uuid;
    data.typeUUID = data.type.value;
    dispatch(
      createLeadSource({
        data,
        successCB: () => {
          reset();
          setShowAddSource(false);
          setSuccessMessage(
            `<b>${watchName}</b> has been added as a lead source.`
          );
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
        errorCB: (msg: string) => setError(msg),
      })
    );
  };

  const onModalClose = () => {
    setShowAddSource(false);
    reset({
      name: '',
      type: null,
    });
  };

  return (
    <StyledModal
      show={showAddSource}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt='close'
        src={close}
        className='closeButton'
        onClick={onModalClose}
      />
      <h2 className='mb-3'>Add new lead source</h2>
      <form onSubmit={handleSubmit(onAddSource)}>
        <div className='mb-3'>
          <SelectField
            options={types.map((type) => {
              return { label: type.name, value: type.uuid };
            })}
            name='type'
            placeholder='Select lead source type'
            control={control}
            required
            ariaInvalid={!!errors.type}
          />
          {errors.type ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errors.type?.value?.message || 'Please select lead source type.'}
            </div>
          ) : null}
        </div>
        <div className='mb-3'>
          <InputField
            name='name'
            type='text'
            placeholder='Name of lead source'
            register={register}
            required
            className={`${(errors.name || errorMessage) && 'error-field'} ${
              watchName && 'filled'
            }`}
          />

          {errorMessage || errors.name ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errorMessage || errors.name?.message}
            </div>
          ) : null}
        </div>

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={onModalClose}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='primaryButton' type='submit'>
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default AddLeadSource;

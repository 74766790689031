import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import calender from '../../../assets/logo/calendar.svg';
import Table from '../../../assets/logo/Table.svg';
import { AllActivityContentDiv } from '../elements/styles';
import plus from '../../../assets/logo/blackplus.svg';
import vector from '../../../assets/logo/dropdown.svg';
import AddTaskEventModal from './AddTaskEventModal';
import TaskCard from './TaskCard';
import filter from '../../../assets/logo/filter.svg';
import search from '../../../assets/logo/search.svg';
import Skeleton from 'react-loading-skeleton';
import whitePlus from '../../../assets/logo/plus.svg';
import { AppDispatch } from '../../../store';
import {
  getActivties,
  selectAllActivities,
  TaskSliceState,
  clearActivities,
  getAllActivtiesCount,
  currentTab,
} from '../../../store/tasks/tasksSlice';
import DetailsTaskModal from './TaskDetailsModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterContainer } from '../../MyBook/elements/styles';
import EventFilter from './FilterDrawer';
import useDebounce from '../../../hooks/useDebounce';
import close from '../../../assets/logo/cross.svg';
import WarningCard from '../../Pipeline/components/WarningCard';
import ErrorCard from '../../Pipeline/components/ErrorCard';
const Tasks = ({
  count,
  showAddTaskEvent,
  setShowAddTaskEvent,
  setAction,
}: {
  count: any;
  showAddTaskEvent: string;
  setShowAddTaskEvent: Function;
  setAction: Function;
}) => {
  const [tableView, setTableView] = useState(true);
  const [dateView, setDateView] = useState(false);
  const [overdew, showOverdew] = useState(false);
  const [thisWeek, setThisWeek] = useState(false);
  const [nextWeek, setNextWeek] = useState(false);
  const [UUID, setUUID] = useState('');
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [dew, showDew] = useState(false);
  const [showToday, setShowToday] = useState(false);
  const [taskDrawer, setTaskDrawer] = useState('closed');
  const [activityOwner, setActivityOwner] = useState({ name: '', type: '' });
  const tasksOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.tasksOffset
  );
  const eventsOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.eventsOffset
  );

  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks?.status
  );
  const sortBy = 'dueDate';
  const orderBy = 'asc';
  const limit = 8;

  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.uuid) {
      setUUID(location?.state?.uuid);
      setTaskDrawer('open');

      navigate(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    setCloseAllTabs(false);
  }, [closeAllTabs]);

  const getTask = (type: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(clearActivities());
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset: 0,
          eventsOffset: 0,
          taskType: taskType?.map((item: any) => item.value),
          taskStatus: taskStatusValue?.map((item: any) => item.value),
          taskPriority: taskPriority?.map((item: any) => item.value),
          assignee: assignee?.value,
          searchBy,
        })
      );
    }
  };

  const sortedTask = (type: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset,
          eventsOffset,
          taskType: taskType?.map((item: any) => item.value),
          taskStatus: taskStatusValue?.map((item: any) => item.value),
          taskPriority: taskPriority?.map((item: any) => item.value),
          assignee: assignee?.value,
          searchBy,
        })
      );
    }
  };

  const allActivities = useSelector(selectAllActivities);
  const allTasks = allActivities?.filter((item: any) => item.type === 'TASK');

  const [showFilter, setShowFilter] = useState('closed');

  const [taskStatusValue, setTaskStatusValue] = useState([]);
  const [taskType, setTaskType] = useState([]);

  const [taskPriority, setTaskPriority] = useState([]);
  const [assignee, setAssignee] = useState<any>('');

  const filterEvent = () => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    dispatch(
      getAllActivtiesCount({
        taskType: taskType?.map((item: any) => item.value),
        taskStatus: taskStatusValue?.map((item: any) => item.value),
        taskPriority: taskPriority?.map((item: any) => item.value),
        assignee: assignee?.value,
        searchBy: debouncedSearchTerm,
      })
    );
  };

  useEffect(() => {
    dispatch(currentTab({ currentTab: 'Tasks' }));
  }, []);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const [onDelete, setDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [markAsDone, setMarkAsDone] = useState(false);
  useEffect(() => {
    filterEvent();
  }, [debouncedSearchTerm]);
  return (
    <AllActivityContentDiv>
      <div className='content-div'>
        {showSuccess && markAsDone && (
          <WarningCard
            message={`<b>${activityOwner.name}'s</b> task is completed`}
            title={`Success updating Task.`}
          />
        )}
        {showSuccess && onDelete && (
          <ErrorCard
            message={`<b>${activityOwner.name}'s </b>${activityOwner.type}  has been  <b>Deleted</b>`}
            title={'Succes deleting Task.'}
          />
        )}
        <div className='d-flex'>
          <div className='d-flex toggleContainer'>
            <div className='d-none d-sm-flex'>
              <button
                onClick={() => {
                  setDateView(false);
                  setTableView(true);
                }}
                className={`d-flex align-items-center justify-content-center toggleImage m-0 ${
                  tableView && 'active'
                }`}
              >
                <img src={Table} alt='' />
              </button>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <button
                    onClick={() => {
                      setDateView(false);
                      setTableView(true);
                    }}
                    className={`d-flex align-items-center justify-content-center toggleImage m-0 ${
                      tableView && 'active'
                    }`}
                  >
                    <img src={Table} alt='' />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='d-none d-sm-block'>
            <button
              onClick={() => setShowAddTaskEvent('open')}
              className='tertiaryButton  addButton  d-flex align-items-center justify-content-between '
              type='submit'
            >
              Add Task <img src={plus} alt='' />
            </button>
          </div>
          <button
            onClick={() => setShowAddTaskEvent('open')}
            className='addbutton-mob d-sm-none'
          >
            <img src={whitePlus} alt='add' />
          </button>
          <div className='d-flex  justify-content-end  w-100'>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center m-0'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}
            <div className='d-none d-sm-flex'>
              <span className={`divider `} />
              <div
                className='d-flex align-items-center position-relative'
                onClick={() => setShowFilter('open')}
                role='button'
              >
                <img src={filter} alt='' className='filter' />
                {(taskPriority?.length || 0) +
                  (taskType?.length || 0) +
                  (taskStatusValue?.length || 0) +
                  (assignee?.value ? 1 : 0) >
                  0 && (
                  <span className='filter-counter'>
                    {(taskPriority?.length || 0) +
                      (taskType?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assignee?.value ? 1 : 0)}
                  </span>
                )}
              </div>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <span className={`divider `} />
                  <div
                    className='d-flex align-items-center position-relative'
                    onClick={() => setShowFilter('open')}
                  >
                    <img src={filter} alt='' className='filter' />
                    {(taskPriority?.length || 0) +
                      (taskType?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assignee?.value ? 1 : 0) >
                      0 && (
                      <span className='filter-counter'>
                        {(taskPriority?.length || 0) +
                          (taskType?.length || 0) +
                          (taskStatusValue?.length || 0) +
                          (assignee?.value ? 1 : 0)}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='activitiesContent'>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(!dew);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              getTask('overdue');
            }}
          >
            <div className='d-flex'>
              <div className='countDanger d-flex justify-content-center align-items-center '>
                {count?.overdueTaskCount ? count?.overdueTaskCount : 0}
              </div>
              <span className='DueName'>Overdue</span>
            </div>
            <img src={vector} alt='' className={`vector ${dew && 'invert'}`} />
          </div>
          <div className='ContentsDiv'>
            {dew &&
              allTasks.length > 0 &&
              allTasks?.map((item: any, index) => (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                  setActivityOwner={setActivityOwner}
                />
              ))}
            {dew &&
              count?.overdueTaskCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {dew && allTasks.length !== count?.overdueTaskCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedTask('overdue')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(!showToday);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              getTask('duetoday');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.duetodayTaskCount ? count?.duetodayTaskCount : 0}
              </div>
              <span className='DueName'>Due today</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${showToday && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {showToday &&
              allTasks.length > 0 &&
              allTasks.map((item: any, index) => (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                  setActivityOwner={setActivityOwner}
                />
              ))}{' '}
            {showToday &&
              count?.duetodayTaskCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {showToday && allTasks.length !== count?.duetodayTaskCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedTask('duetoday')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(!thisWeek);
              setNextWeek(false);
              showOverdew(false);
              getTask('duethisWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.duethisWeekTaskCount ? count?.duethisWeekTaskCount : 0}
              </div>
              <span className='DueName'>Due This week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${thisWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {thisWeek &&
              allTasks.length > 0 &&
              allTasks.map((item: any, index) => (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                  setActivityOwner={setActivityOwner}
                />
              ))}
            {thisWeek &&
              count?.duethisWeekTaskCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {thisWeek && allTasks.length !== count?.duethisWeekTaskCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedTask('duethisWeek')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(!nextWeek);
              showOverdew(false);
              getTask('dueNextWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.dueNextWeekTaskCount ? count?.dueNextWeekTaskCount : 0}
              </div>
              <span className='DueName'>Due Next week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${nextWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {nextWeek &&
              allTasks.length > 0 &&
              allTasks.map((item: any, index) => (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                  setActivityOwner={setActivityOwner}
                />
              ))}
            {nextWeek &&
              count?.dueNextWeekTaskCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {nextWeek && allTasks.length !== count?.dueNextWeekTaskCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedTask('duethisWeek')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(!overdew);
              getTask('upcomingWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.upcomingWeekTaskCount
                  ? count?.upcomingWeekTaskCount
                  : 0}
              </div>
              <span className='DueName'>Up coming</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${overdew && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {overdew &&
              allTasks.length > 0 &&
              allTasks.map((item: any, index) => (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                  setActivityOwner={setActivityOwner}
                />
              ))}
            {overdew &&
              count?.upcomingWeekTaskCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {overdew && allTasks.length !== count?.upcomingWeekTaskCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedTask('upcomingWeek')}
              >
                Show more
              </button>
            )}
          </div>
        </div>
        {showAddTaskEvent !== 'closed' && (
          <AddTaskEventModal
            show={showAddTaskEvent}
            closeModal={setShowAddTaskEvent}
            setAction={setAction}
          />
        )}
        {taskDrawer !== 'closed' && (
          <DetailsTaskModal
            show={taskDrawer}
            closeModal={setTaskDrawer}
            uuid={UUID}
            setAction={setAction}
            setCloseAllTabs={setCloseAllTabs}
          />
        )}
        <FilterContainer>
          {showFilter !== 'closed' && (
            <EventFilter
              show={showFilter}
              closeModal={setShowFilter}
              filterFunction={filterEvent}
              taskType={taskType}
              setTaskType={setTaskType}
              taskStatusValue={taskStatusValue}
              setTaskStatusValue={setTaskStatusValue}
              setTaskPriority={setTaskPriority}
              taskPriority={taskPriority}
              assignee={assignee}
              setAssignee={setAssignee}
              type='task'
            />
          )}
        </FilterContainer>
      </div>
    </AllActivityContentDiv>
  );
};

export default Tasks;

import Modal from '../components/Modal';
import styled from '@emotion/styled';

export const FormModal = styled(Modal)`
  .drawer-container {
    width: 100%;
  }

  @media screen and(max-width: 1199px) and(min-width: 576px) {
    .drawer-container {
      width: 100%;
    }
  }
`;

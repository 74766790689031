import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchProductCategory,
  addProductCategory,
  updateProductCategory,
  deleteProductCategory,
} from '../../api/productCategory';
import { IProductCategory } from '../../interfaces/ProductCategory';
import { addProduct, updateProduct, deleteProduct } from '../../api/product';
import { IProduct } from '../../interfaces/Product';

export type ProductCategoriesSliceState = {
  status: string;
  productCategories: IProductCategory[];
  isLoading: boolean;
  CustomProduct: IProduct[];
};

interface IProps {
  successCB: Function;
  sortBy: string;
  searchBy: string;
  orderBy: string;
  agencyUUID?: string;
  includeCustom?: boolean;
  customCategoryOnly?: boolean;
  type?: string;
  lineOfBusiness?: string;
}

const initialState: ProductCategoriesSliceState = {
  status: 'idle',
  productCategories: [],
  isLoading: false,
  CustomProduct: [],
};

export const getProductCategories = createAsyncThunk(
  'productCategories/getProductCategories',
  async ({
    successCB,
    sortBy,
    orderBy,
    searchBy,
    agencyUUID,
    customCategoryOnly,
    includeCustom,
    type,
    lineOfBusiness,
  }: IProps) => {
    const response = await fetchProductCategory(
      successCB,
      sortBy,
      orderBy,
      searchBy,
      agencyUUID,
      customCategoryOnly,
      includeCustom,
      type,
      lineOfBusiness
    );
    if (successCB) {
      successCB(response.data);
    }
    return response.data;
  }
);

export const createProductCategory = createAsyncThunk(
  'productCategories/createProductCategory',
  async ({
    data,
    successCB,
  }: {
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await addProductCategory(data, successCB);
    return response.data;
  }
);

export const onEditProductCategory = createAsyncThunk(
  'productCategories/onEditProductCategory',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await updateProductCategory(uuid, data, successCB);
    return response.data;
  }
);

export const onDeleteProductCategory = createAsyncThunk(
  'productCategories/onDeleteProductCategory',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await deleteProductCategory(uuid, data);
    successCB();
    return response.data;
  }
);

export const createProduct = createAsyncThunk(
  'productCategories/createProduct',
  async ({
    data,
    successCB,
  }: {
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await addProduct(data, successCB);
    return response.data;
  }
);

export const onUpdateProduct = createAsyncThunk(
  'productCategories/onUpdateProduct',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await updateProduct(uuid, data, successCB);
    return response.data;
  }
);

export const onDeleteProduct = createAsyncThunk(
  'productCategories/onDeleteProduct',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await deleteProduct(uuid, data);
    successCB();
    return response.data;
  }
);

const productCategorySlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProductCategories.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProductCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productCategories = action.payload;
      })
      .addCase(getProductCategories.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createProductCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productCategories.unshift(action.payload);
      })
      .addCase(createProductCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProductCategory.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onEditProductCategory.fulfilled, (state, action) => {
        const index = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.productCategories[index] = action.payload;
      })
      .addCase(onDeleteProductCategory.fulfilled, (state, action) => {
        const index = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.customProductCategory.uuid
        );

        const destIndex = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.destination.uuid
        );

        state.productCategories[destIndex].CustomProduct.push(
          ...state.productCategories[index].CustomProduct
        );

        state.productCategories.splice(index, 1);
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        const index = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.CustomProductCategory.uuid
        );
        state.productCategories[index].CustomProduct.push(action.payload);
      })
      .addCase(onUpdateProduct.fulfilled, (state, action) => {
        const index = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.CustomProductCategory.uuid
        );
        const sourceIndex = state.productCategories[
          index
        ].CustomProduct.findIndex((item) => item.uuid === action.payload.uuid);
        const updatedSourceList = [
          ...state.productCategories[index].CustomProduct,
        ];
        updatedSourceList.splice(sourceIndex, 1, action.payload);
        state.productCategories[index].CustomProduct = updatedSourceList;
      })
      .addCase(onDeleteProduct.fulfilled, (state, action) => {
        const index = state.productCategories.findIndex(
          (item) => item.uuid === action.payload.CustomProductCategory.uuid
        );

        const sourceIndex = state.productCategories[
          index
        ].CustomProduct.findIndex((item) => item.uuid === action.payload.uuid);
        const updatedSourceList = [
          ...state.productCategories[index].CustomProduct,
        ];

        updatedSourceList.splice(sourceIndex, 1);
        state.productCategories[index].CustomProduct = updatedSourceList;
      });
  },
});

export const { updateStatus } = productCategorySlice.actions;

export default productCategorySlice.reducer;

export const selectAllProductCategories = (state: {
  productCategories: ProductCategoriesSliceState;
}) => state.productCategories?.productCategories;

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import EditOpportunityDetails from '../../../pages/OpportunityDetails/EditOpportunityDetails';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { CardMobileDiv } from '../../../styles/InnerTabsCardStyles';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';

const MobileInnerCard = ({
  details,
  onDelete,
  setShowDeleteModal,
}: {
  details: IOpportunity;
  onDelete: Function;
  loadingStatus?: string;
  showDeleteModal?: string;
  setShowDeleteModal?: Function;
  setStartQuoting: Function;
  setOpportunity?: Function;
}) => {
  const [showEditModal, setShowEditModal] = useState('closed');
  const navigate = useNavigate();
  return (
    <CardMobileDiv>
      {' '}
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center '>
            <div className=' d-flex align-items-center justify-content-between w-100'>
              <span
                className='oppName'
                onClick={() => navigate(`/opportunities/${details.uuid}`)}
              >
                {`${details.name ? details.name : 'Unknown'} -  ${
                  details.ProductId
                    ? details.Product?.name
                    : details.CustomProduct?.name
                }`}
              </span>
              <div className='oppstatus yellowC '>
                <h6> {details.PipelineStage?.name}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className='lowContent'>
          <div className='oppDetaisl'>
            <div className='oppDetaislfields  '>
              <>
                <div className='d-flex'>
                  <span className='valHead '>Effective date</span>
                  <span className='valueField valHead'>
                    {details?.effectiveDate ? (
                      <Moment format='MM/DD/YYYY'>
                        {details?.effectiveDate}
                      </Moment>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
                <div className='d-flex ammountDetails'>
                  <span className='valueField p-0 borderDiv'>
                    {details.Account?.AccountType?.name}
                  </span>
                  <span className='valueField'>
                    <b>$</b>
                    {details?.leastPremium
                      ? details?.leastPremium.toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
              </>
            </div>
            <div className=' d-flex align-items-center justify-content-between '>
              <>
                <div className='profile-pic mobileProfile d-flex align-items-center'>
                  {details?.Agent?.User?.avatar
                    ? details?.Agent?.User?.avatar
                    : details?.Agent?.User?.firstName?.charAt(0) +
                      details?.Agent?.User?.lastName?.charAt(0)}
                </div>
                <div className='d-flex'>
                  <button className='tertiaryButton quotingbtn d-flex align-items-center '>
                    Add quote <img className='addIcon' src={arrow} alt='' />
                  </button>
                  <ActionsDropdown
                    uuid={details?.uuid}
                    setShowDeleteModal={setShowDeleteModal}
                    name={details?.name}
                    deleteFun={onDelete}
                    setShowEditModal={setShowEditModal}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {details && showEditModal !== 'closed' && (
        <EditOpportunityDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={details?.uuid}
        />
      )}
    </CardMobileDiv>
  );
};

export default MobileInnerCard;

const ActionsDropdown = ({
  uuid,
  setShowDeleteModal,
  name,
  deleteFun,
  setShowEditModal,
}: {
  uuid: string;
  name: string;
  deleteFun: Function;
  setShowDeleteModal?: Function;
  setShowEditModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => setShowActionsDropdown(!showActionsDropdown)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {setShowDeleteModal && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setShowActionsDropdown(false);
                deleteFun(uuid, name);
              }}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

import axios from './axios';

const fetchProductCategory = (
  successCB: Function,
  sortBy?: string,
  orderBy?: string,
  searchBy?: string,
  agencyUUID?: string,
  customCategoryOnly?: boolean,
  includeCustom?: boolean,
  type?: string,
  lineOfBusiness?: string
) => {
  return axios
    .get(`/productCategory`, {
      params: {
        sortBy,
        searchBy,
        orderBy,
        agencyUUID,
        customCategoryOnly,
        includeCustom,
        type,
        lineOfBusiness,
      },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const fetchSingleProductCategory = (uuid: string, successCB: Function) => {
  return axios.get(`/productCategory/${uuid}`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const addProductCategory = (
  data: { agencyUUID?: string; action: string },
  successCB: Function
) => {
  return axios.post('/productCategory', data).then((res) => {
    successCB(res);
    return res;
  });
};

const updateProductCategory = (
  uuid: string,
  data: { agencyUUID?: string; action: string },
  successCB: Function
) => {
  return axios.patch(`/productCategory/${uuid}`, data).then((res) => {
    successCB();
    return res;
  });
};

const deleteProductCategory = (
  uuid: string,
  data: {
    agencyUUID?: string;
    action: string;
  }
) => {
  return axios.delete(`/productCategory/${uuid}`, { data });
};

export {
  fetchProductCategory,
  fetchSingleProductCategory,
  addProductCategory,
  updateProductCategory,
  deleteProductCategory,
};

import styled from '@emotion/styled';

export const SuccessAlign = styled.div`
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 18px;
    top: 10px;
    border: none;
    background: none;
  }

  .success-container {
    position: fixed;
    bottom: 80px;
    right: 20px;
    transition: all 0.3s ease-in-out;
    transform: translate(120%);
    z-index: 8;
  }

  .open-drawer {
    transform: translate(0);
  }
  @media screen and (min-width: 576px) {
    .success-container {
      right: 32px;
    }
  }

  @media screen and (min-width: 1200px) {
    .success-container {
      top: 80px;
      right: 0;
    }

    .open-drawer {
      transform: translate(-48px);
    }
  }
`;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  fetchLeadSourceTypes,
  LeadsSliceState,
  selectLeadSourceTypes,
} from '../../../store/leads/leadsSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import SuccessCard from '../../../components/SuccessCard';
import AddTypeModal from './AddTypeModal';
import EditTypeModal from './EditTypeModal';
import AddLeadSource from './AddLeadSource';
import EditSourceModal from './EditSourceModal';
import DeleteTypeModal from './DeleteTypeModal';
import DeleteSourceModal from './DeleteSourceModal';
import { ILeadSource, ILeadSourceType } from '../../../interfaces/Lead';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import plus from '../../../assets/logo/plus.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import cross from '../../../assets/logo/redcross.svg';
import close from '../../../assets/logo/cross.svg';
import whitePlus from '../../../assets/logo/plus.svg';
import search from '../../../assets/logo/search.svg';
import useDebounce from '../../../hooks/useDebounce';

const LeadSources = () => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const sourceTypes = useSelector(selectLeadSourceTypes);
  const loadingStatus = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.status
  );

  const [showAddSourceType, setShowAddSourceType] = useState(false);
  const [showAddSource, setShowAddSource] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');

  const addRef = useRef(null);
  const searchRef = useRef(null);

  useOutsideClick(addRef, setShowAddOptions, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    dispatch(fetchLeadSourceTypes({ uuid, searchBy: searchBy })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className='settings-div'>
      <SuccessAlign>
        <div
          className={`success-container ${
            successMessage.length > 0 && 'open-drawer'
          } `}
        >
          <SuccessCard title={`<b>Success!</b>`} message={successMessage} />
          <button
            onClick={() => {
              setSuccessMessage('');
            }}
            className='crossbutton'
          >
            <img src={close} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      {showAddOptions && <div className='addButton-background' />}
      <button
        onClick={() => setShowAddOptions(!showAddOptions)}
        className={`addbutton-mob d-sm-none ${
          showAddOptions ? 'dropOpen' : ''
        }`}
        ref={addRef}
      >
        {showAddOptions && (
          <div className='dropUp'>
            <ul
              className='d-flex justify-content-between w-100 '
              onClick={() => {
                setShowAddSource(true);
                setShowAddOptions(false);
              }}
            >
              Add lead source <img src={plus} alt='' />
            </ul>
            <ul
              className='d-flex justify-content-between w-100 '
              onClick={() => {
                setShowAddSourceType(true);
                setShowAddOptions(false);
              }}
            >
              Add source type <img src={plus} alt='' />
            </ul>
          </div>
        )}
        <img src={whitePlus} alt='add' className='addButton-img' />
      </button>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <button
            className='primaryButton addButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setShowAddSource(true)}
          >
            Add lead source <img alt='' src={plus} />
          </button>
          <button
            className='addButton tertiaryButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setShowAddSourceType(true)}
          >
            Add source type
            <img alt='' src={plus} />
          </button>
        </div>

        <div className='book-filter d-flex' ref={searchRef}>
          {isSearchClicked || searchBy || window.innerWidth < 576 ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}
        </div>
      </div>
      <div className='d-flex flex-wrap cards-container'>
        {loadingStatus === 'loading' ? (
          <>
            <div className='col-12 col-md-4 p-2'>
              <Skeleton height={300} />
            </div>
            <div className='col-12 col-md-4 p-2'>
              <Skeleton height={300} />
            </div>{' '}
            <div className='col-12 col-md-4 p-2'>
              <Skeleton height={300} />
            </div>
          </>
        ) : (
          sourceTypes.map((item) => (
            <TypeCard
              item={item}
              setSuccessMessage={setSuccessMessage}
              key={item.uuid}
            />
          ))
        )}
      </div>
      <AddTypeModal
        showAddSourceType={showAddSourceType}
        setShowAddSourceType={setShowAddSourceType}
        setSuccessMessage={setSuccessMessage}
      />
      <AddLeadSource
        showAddSource={showAddSource}
        setShowAddSource={setShowAddSource}
        setSuccessMessage={setSuccessMessage}
      />
    </div>
  );
};

export default LeadSources;

const TypeCard = ({
  item,
  setSuccessMessage,
}: {
  item: ILeadSourceType;
  setSuccessMessage: Function;
}) => {
  const [showEditSourceType, setShowEditSourceType] = useState(false);
  const [showAddSource, setShowAddSource] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className='type-card'>
      <div className='title-div d-flex justify-content-between align-items-center'>
        <h6>{item.name}</h6>
        {!item.isDefault && (
          <div className='d-flex'>
            <div className='edit-icon'>
              <div
                className='card-icon'
                onClick={() => setShowEditSourceType(true)}
              >
                <img src={edit} alt='' />
              </div>
            </div>

            <div className='card-icon' onClick={() => setShowDeleteModal(true)}>
              <img src={bin} alt='' />
            </div>
          </div>
        )}
      </div>
      <div className='source-container'>
        <div className='source-content'>
          {item.LeadSources?.length === 0 ? (
            <div
              className='empty-div justify-content-center h-100 d-flex align-items-center'
              style={{ opacity: 0.5 }}
            >
              No lead sources present.
            </div>
          ) : (
            <>
              {item.LeadSources.map((source) => (
                <SourceCard
                  type={item}
                  source={source}
                  key={source.uuid}
                  setSuccessMessage={setSuccessMessage}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <button
        className='addSourceButton'
        onClick={() => setShowAddSource(true)}
      >
        Add new lead source
      </button>
      {showEditSourceType && (
        <EditTypeModal
          showEditSourceType={showEditSourceType}
          setShowEditSourceType={setShowEditSourceType}
          sourceType={item}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      {showAddSource && (
        <AddLeadSource
          showAddSource={showAddSource}
          setShowAddSource={setShowAddSource}
          setSuccessMessage={setSuccessMessage}
          defaultType={item}
        />
      )}

      {showDeleteModal && (
        <DeleteTypeModal
          sourceType={item}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
};

const SourceCard = ({
  source,
  type,
  setSuccessMessage,
}: {
  source: ILeadSource;
  type: ILeadSourceType;
  setSuccessMessage: Function;
}) => {
  const [showEditSource, setShowEditSource] = useState(false);
  const [showDeleteSource, setShowDeleteSource] = useState(false);

  return (
    <div className='source-card d-flex justify-content-between align-items-center'>
      <div className='source-title'>{source.name}</div>
      {!source.isDefault && (
        <div className='d-flex'>
          <div className='edit-icon'>
            <div className='card-icon' onClick={() => setShowEditSource(true)}>
              <img src={edit} alt='' />
            </div>
          </div>

          <div className='card-icon' onClick={() => setShowDeleteSource(true)}>
            <img src={cross} alt='' />
          </div>
        </div>
      )}
      {showEditSource && (
        <EditSourceModal
          leadSource={source}
          defaultType={type}
          setShowEditSource={setShowEditSource}
          showEditSource={showEditSource}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      {showDeleteSource && (
        <DeleteSourceModal
          showModal={showDeleteSource}
          setShowModal={setShowDeleteSource}
          leadSource={source}
          type={type}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
};

import styled from '@emotion/styled';
import 'react-datepicker/dist/react-datepicker.css';
export const PickerDiv = styled.div`
  .CallenderImg {
    position: absolute;
    right: 8px;
    cursor: pointer;
    pointer-events: none;
  }
  display: flex;
  height: 48px;
  align-items: center;
  background: #ffffff;
  padding-right: 10px;
  padding-left: 0px;
  border: 1px solid #cad9f3;
  border-radius: 8px;
  position: relative;
  &.filled-picker {
    border: 1px solid #262758;
  }
  &.disabled-picker {
    color: hsl(0, 0%, 60%);
    background: #f5f7fc !important;
    border: none;
    border-color: hsl(0, 0%, 90%);
    label {
      color: #8990a3 !important;
    }
    .input-container::disabled {
      background: #f5f7fc !important;
    }
  }
  &:hover {
    border: 1px solid #cad9f3;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  }
  .date-input-placeholder {
    display: none;
  }
  .input-container {
    label {
      position: absolute;
      color: #262758;
      pointer-events: none;
      transform: translate(0, 2px);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      font-size: 16px;
      left: 16px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  .react-datepicker-popper {
    padding-top: 8px;
    width: 100%;
    min-width: 300px;
  }
  .react-datepicker {
    width: 100%;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
    left: 2px;
    min-width: 280px;
  }
  .react-datepicker__month-container {
    padding: 20px 40px;
    border-radius: 8px;
    width: 100%;
    background: #fff;
  }
  .react-datepicker__header {
    background: #fff;
    border: none;
  }
  .react-datepicker__current-month {
    color: #262758;
    font-size: 20px;
    text-align: center;
    line-height: 17.5px;
    button {
      border: 0;
      background: #fff;
      width: 20px;
      height: 21px;
      padding: 0;

      :disabled {
        opacity: 0.2;
      }
    }

    .date-info {
      padding: 0 5px;
    }
  }
  .react-datepicker__month {
    text-align: unset;
    width: 100%;
    margin: 0;
  }
  .react-datepicker__day-names {
    margin: 0;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: #3c498a;
    font-size: 14px;
  }
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__navigation {
    top: 33px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--today {
    position: relative;

    :before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #f04d46;
      display: block;
      position: absolute;
      top: 20px;
      left: 12px;
    }
    font-weight: 600;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day--outside-month {
    color: #cad9f3;
  }
  .react-datepicker__day--selected {
    background: #c1dbeb;
    border-radius: 50%;
    font-weight: 700;
    color: #262758;
  }
  .react-datepicker__day--keyboard-selected {
    background: #c1dbeb;
    border-radius: 50%;
    color: #262758;
  }
  .react-datepicker__year-dropdown-container--scroll {
    margin: 0 2px;
    position: absolute;
    right: 0px;
    bottom: 32px;
  }
  .react-datepicker__year-dropdown {
    width: 50px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  .react-datepicker__year-option {
    background: #fff;
    :hover {
      background: #ecf4f9;
    }
  }
  .date-input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 11px 0px 0px 16px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 600;
    font-size: 15px;
    :disabled {
      color: #cad9f3;
      background: #f5f7fc !important;
      .input-container::disabled {
        background: #f5f7fc !important;
        label {
          color: #cad9f3 !important;
        }
      }
    }
  }
  .filled {
    label {
      font-size: 12px;
      transform: translate(0, -6px);
      font-weight: 400;
    }
  }
  :focus-within {
    border: 1px solid #3c498a;
    .input-container {
      label {
        font-size: 12px;
        transform: translate(0, -6px);
        font-weight: 400;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .react-datepicker__month-container {
      padding: 30px;
      width: 100%;
      background: #fff;
    }
    .react-datepicker__navigation {
      top: 43px;
    }
  }

  @media screen and (min-width: 1900px) {
    padding-right: 20px;
    height: 64px;
    .filled {
      label {
        transform: translate(0, -10px);
      }
    }
    .react-datepicker-popper {
      padding-top: 18px;
    }

    :focus-within {
      .input-container {
        label {
          transform: translate(0, -10px);
        }
      }
    }
  }
`;

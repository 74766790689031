import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getAllLocations,
  selectAllLocations,
  LocationSliceState,
} from '../../../store/location/locationSlice';
import useDebounce from '../../../hooks/useDebounce';
import MobileNav from '../../../components/MobileTabNav';
import EmptyResults from '../../MyBook/components/Empty';
import Table from '../../../components/Table';
import PaginationComponent from '../../../components/PaginationComponent';
import search from '../../../assets/logo/search.svg';
import plus from '../../../assets/logo/plus.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import primary from '../../../assets/logo/primary.svg';
import LocationAddModal from './components/AddLocationModal';
import LocalStorageService from '../../../utils/LocalStorageService';
import arrow from '../../../assets/logo/arrow.svg';
// import AgentTable from '../AgencyDetails/components/AgentTable';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import EditLocationModal from './components/EditLocationModal';
import DeleteLocationModal from './components/DeleteLocationModal';
import { ILocation } from '../../../interfaces/Location';
import LocationCardMobile from './components/LocationCardMobile';
import TopBar from '../../../components/TopBar';
import { StyledAgencySettings } from '../AgencySettingsPage/styles';
import close from '../../../assets/logo/cross.svg';
const LocationSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const locations = useSelector(selectAllLocations);
  const loadingStatus = useSelector(
    (state: { locations: LocationSliceState }) => state.locations?.status
  );
  const locationsCount = useSelector(
    (state: { locations: LocationSliceState }) => state.locations?.count
  );
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = LocalStorageService.getUser();
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isLocations, setLocations] = useState(true);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [location, setLocation] = useState<ILocation>();
  const [UUID, setUUID] = useState('');
  const [adduser, setAddUser] = useState('closed');
  const [sortBy, setSortBy] = useState('name');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const addUserFunction = () => {
    setAddUser('open');
  };
  const [scroll, setScroll] = useState(false);
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (user?.UserRole.name === 'ADMIN' && uuid) {
        dispatch(
          getAllLocations({
            sortBy,
            orderBy,
            searchBy: debouncedSearchTerm,
            limit,
            offset: page * limit,
            agencyUUID: uuid,
            successCB: (data: any) => {},
          })
        );
      } else {
        dispatch(
          getAllLocations({
            sortBy,
            orderBy,
            searchBy: debouncedSearchTerm,
            limit,
            offset: page * limit,
            agencyUUID: uuid,
            successCB: (data: any) => {},
          })
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortBy, orderBy, debouncedSearchTerm, uuid, limit, page]);
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  return (
    <>
      <StyledAgencySettings>
        <TopBar>
          <div
            className='mt-4 mt-sm-0 d-flex align-items-center'
            onClick={() =>
              user?.UserRole?.name === 'ADMIN'
                ? navigate(`/admin`)
                : navigate('/settings')
            }
            role='button'
          >
            <img src={arrow} className='leftarrow  ' alt='leftarrow' />
            <h2>Agency Settings</h2>
          </div>
        </TopBar>
        <div className='agencySettingsContent locationSettingsContent'>
          <div className='topDiv LocationContent'>
            <div className='d-flex '>
              <p
                onClick={() => {
                  setLocations(false);
                  user?.UserRole?.name === 'PRINCIPAL_AGENT'
                    ? navigate(`/settings/Agencysettings`)
                    : navigate(`/admin/agency/${uuid}/settings`);
                }}
                className={
                  !isLocations ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                }
              >
                General Info
              </p>
              <div className='locationText'>
                <p
                  onClick={() => {
                    setLocations(true);
                  }}
                  className={
                    isLocations ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                  }
                >
                  Locations
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyledAgencySettings>
      <div className='content-div locationTableDiv'>
        <div className='topButtonsDiv d-flex justify-content-between w-100 '>
          <button
            className='addButton userButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={addUserFunction}
          >
            Add Location
            <img className='plus' src={plus} alt='add' />
          </button>
          <div className='d-flex align-items-center filter justify-content-between justify-content-sm-start'>
            {isSearchClicked || searchBy ? (
              <div className='d-none d-sm-flex book-search d-flex align-items-center'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setPage(0);
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setPage(0);
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='d-none d-sm-flex book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}
            <div className={`d-sm-none search-div`}>
              <img src={search} className='search-icon' alt='search' />
              <input
                type='text'
                placeholder='Search Locations'
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <button onClick={addUserFunction} className='addButton d-sm-none'>
          <img src={plus} alt='add' />
        </button>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && locations?.length > 0 ? (
            <>
              {locations.map((el: ILocation) => (
                <LocationCardMobile item={el} />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={locationsCount}
              />
            </>
          ) : (
            <EmptyResults name='location' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            setScroll={() => {
              setScroll(!scroll);
            }}
            page={page}
            setPage={setPage}
            count={locationsCount}
            className='mybook-table'
            loadingStatus={loadingStatus}
            name='location'
            columns={[
              {
                accessor: 'Location name',
                resizable: true,
                sortable: true,
                minWidth: 100,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Location name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center'>
                    <b>{row.original?.name}</b>
                    {row.original?.isPrimary && (
                      <img className='primaryName' src={primary} alt='prime' />
                    )}
                  </div>
                ),
              },
              {
                accessor: 'address',
                Header: 'Address ',
                resizable: true,
                width: 130,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center h-100'>
                    <div>
                      {row.original?.isManuallyAdded
                        ? row.original?.address +
                          ' ,' +
                          row.original?.city +
                          ' ,' +
                          row.original?.state +
                          ' ,' +
                          row.original?.country
                        : row.original?.address}
                    </div>
                  </div>
                ),
              },
              {
                accessor: 'phone ',
                Header: 'Phone Number',
                resizable: true,
                minWidth: 130,
                Cell: ({ row }: { row: any }) => (
                  <>{row.original?.phoneNumber}</>
                ),
              },
              {
                accessor: 'fax ',
                Header: 'Fax number',
                resizable: true,
                minWidth: 130,
                Cell: ({ row }: { row: any }) => <>{row.original?.faxNumber}</>,
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                width: window.innerWidth > 1900 ? 50 : 76,
                maxWidth: 55,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <ActionsDropdown
                      uuid={row.original?.uuid}
                      setUUID={setUUID}
                      deletingLocation={row.original}
                      isPrimaryLocation={row.original?.isPrimary}
                      setLocation={setLocation}
                      setShowEditModal={setShowEditModal}
                      scroll={scroll}
                      setShowDeleteModal={setShowDeleteModal}
                    />
                  </div>
                ),
              },
            ]}
            data={locations}
          />
        </div>
      </div>
      <div className='d-xl-none'>
        <MobileNav />
      </div>
      {adduser !== 'closed' && (
        <LocationAddModal
          show={adduser}
          closeModal={setAddUser}
          agencyUUID={uuid}
          firstLocation={locations.length === 0 ? true : false}
        />
      )}
      {showEditModal !== 'closed' && location && (
        <EditLocationModal
          show={showEditModal}
          closeModal={setShowEditModal}
          uuid={UUID}
          agencyUUID={uuid}
          isThisprimaryLocation={location.isPrimary}
        />
      )}
      {showDeleteModal && location && (
        <DeleteLocationModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deletingLocation={location}
          agencyUUID={uuid}
        />
      )}
    </>
  );
};
export default LocationSettings;
const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  setShowDeleteModal,
  setLocation,
  deletingLocation,
  isPrimaryLocation,
  scroll,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  setLocation: Function;
  deletingLocation?: ILocation;
  setShowDeleteModal: Function;
  scroll: boolean;
  isPrimaryLocation: boolean;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className={
            isPrimaryLocation
              ? 'onlyEdit-dropdown-div position-fixed'
              : 'dropdown-div position-fixed'
          }
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 130,
          }}
        >
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
              setLocation(deletingLocation);
            }}
            tabIndex={0}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {!isPrimaryLocation && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal(true);
                setUUID(uuid);
                setLocation(deletingLocation);
                setShowActionsDropdown(false);
              }}
              tabIndex={1}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

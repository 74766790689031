import InputField from '../../CustomInputField';
import SelectField from '../../ActivityCustomSelectField';
import pin from '../../../assets/logo/pin.svg';
import DateField from '../../ActivityCustomeDateField';
import vector from '../../../assets/logo/dropdown.svg';
import { useEffect, useRef, useState } from 'react';
import DocumentAddModal from './DocumentAddModal';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

const Task = ({
  setFile,
  file,
  ApiSuccess,
  docTypeUUID,
  setDocTypeUUID,
  taskType,
  onItem,
  tasks,
  taskPriority,
  assignee,
  setAssignee,
  producers,
  handleTaskSubmit,
  addNoteLoading,
  editTask,
  isMobile,
  setShowEdit,
}: {
  setFile: Function;
  file: any;
  ApiSuccess: boolean;
  docTypeUUID: string;
  setDocTypeUUID: Function;
  taskType: any;
  onItem: Function;
  tasks: {
    description?: string;
    taskStatusUUID?: string;
    assigneeUUID?: string;
    taskTypeUUID?: string;
    taskPriorityUUID?: string;
    dueDate?: string;
    file?: any;
  };
  taskPriority: any;
  assignee: any;
  setAssignee: Function;
  producers: any;
  handleTaskSubmit: Function;
  addNoteLoading: boolean;
  editTask?: boolean;
  isMobile?: boolean;
  setShowEdit?: Function;
}) => {
  const [userSelect, isUserSelect] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showModal, setShowModal] = useState('closed');
  const [error, setError] = useState<{
    taskTypeUUID?: string;
    dueDate?: string;
    taskPriorityUUID?: string;
    description?: string;
  }>();

  const dropRef = useRef(null);
  useOutsideClick(dropRef, isUserSelect);

  useEffect(() => {
    let errorObj: {
      taskTypeUUID?: string;
      dueDate?: string;
      taskPriorityUUID?: string;
      description?: string;
    } = {};
    if (
      error?.description ||
      error?.taskPriorityUUID ||
      error?.dueDate ||
      error?.taskTypeUUID
    ) {
      if (tasks && !tasks.taskTypeUUID) {
        errorObj.taskTypeUUID = 'Please select a task type';
      }
      if (tasks && !tasks?.dueDate) {
        errorObj.dueDate = 'Please select a Due date';
      }
      if (tasks && tasks.dueDate && new Date(tasks.dueDate) < new Date()) {
        errorObj.dueDate = ' Please select a Future Date';
      }
      if (tasks && !tasks.taskPriorityUUID) {
        errorObj.taskPriorityUUID = 'Please select a Priority';
      }
      setError(errorObj);
    }
  }, [tasks]);
  return (
    <>
      <div className='d-sm-flex tab-content-div'>
        <div className={!isMobile ? 'col-sm-2' : 'fields'}>
          <SelectField
            options={taskType.map((item: any) => {
              return {
                label: `${
                  item?.name
                    ? item?.name.charAt(0).toUpperCase() +
                      item?.name.slice(1).toLowerCase()
                    : ''
                }`,
                value: item?.uuid,
              };
            })}
            name='taskTypeUUID'
            placeholder='Task type'
            onSetItem={onItem}
            values={tasks}
            isFirst={true}
          />
          <div className='errorVal'>
            {error?.taskTypeUUID ? (
              <span className=''>{error.taskTypeUUID} </span>
            ) : null}
          </div>
        </div>
        <div className='fields col'>
          <InputField
            name='description'
            type='text'
            placeholder='Description'
            onSetItem={onItem}
            values={tasks}
            squareField={true}
            editTask={editTask ? editTask : false}
          />
          <div className='errorVal'>
            {error?.description ? (
              <span className=''>{error.description} </span>
            ) : null}
          </div>
        </div>
        <div className=' fields col'>
          <DateField
            name='dueDate'
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            endDate={endDate}
            startDate={startDate}
            placeholder='Due date'
            onSetItem={onItem}
            values={tasks}
            editTask={editTask ? editTask : false}
            minDate={new Date()}
          />
          <div className='errorVal'>
            {error?.dueDate ? <span className=''>{error.dueDate} </span> : null}
          </div>
        </div>
        <div className='col-sm-2 field'>
          <SelectField
            options={taskPriority.map((item: any) => {
              return {
                label: `${
                  item?.name
                    ? item?.name.charAt(0).toUpperCase() +
                      item?.name.slice(1).toLowerCase()
                    : ''
                }`,
                value: item?.uuid,
              };
            })}
            name='taskPriorityUUID'
            placeholder='Priority'
            onSetItem={onItem}
            values={tasks}
            isFirst={false}
            isLast={true}
          />
          <div className='errorVal'>
            {error?.taskPriorityUUID ? (
              <span className=''>{error.taskPriorityUUID} </span>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={`${
          !isMobile
            ? 'd-sm-flex justify-content-between'
            : 'd-flex align-items-center justify-content-between'
        } addTaskButton`}
      >
        <div className='d-flex align-items-center'>
          {editTask && setShowEdit && (
            <button
              className='cancelEdit h-100'
              type='button'
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </button>
          )}
          <div onClick={() => isUserSelect(!userSelect)} ref={dropRef}>
            <div className='addUsersinTask d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <div className='profile-pic d-flex align-items-center justify-content-center'>
                  {assignee?.User?.firstName?.charAt(0)}
                </div>
                {assignee?.User?.firstName}
              </div>
              <img src={vector} alt='' className='drop-icon' />
              {userSelect && (
                <div className='usersContainer '>
                  <ul className='usersListOrder'>
                    {producers.map((item: any) => (
                      <li
                        className='usersList'
                        onClick={() => {
                          setAssignee(item);
                          onItem('assigneeUUID', item.User?.uuid);
                        }}
                      >
                        <div className='profile-pic d-flex align-items-center justify-content-center'>
                          {item?.User?.firstName?.charAt(0)}
                        </div>
                        {item?.User?.firstName} {item?.User?.lastName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`ButtonsContainer d-flex align-items-center ${
            !isMobile ? 'justify-sm-content-end' : ''
          }`}
        >
          <span className='DocNameField'>
            {file?.name ? file?.name : tasks?.file?.name}
          </span>
          <button
            className='addDocument d-flex align-items-center justify-content-center  me-3'
            type='button'
            onClick={() => {
              setShowModal('open');
            }}
          >
            <img src={pin} alt='' className='' />
          </button>
          <button
            className='tertiaryButton '
            type='button'
            onClick={() => {
              let errorObj: {
                taskTypeUUID?: string;
                dueDate?: string;
                taskPriorityUUID?: string;
                description?: string;
              } = {};
              if (
                tasks.taskTypeUUID &&
                tasks.taskPriorityUUID &&
                tasks?.dueDate &&
                new Date(tasks?.dueDate) > new Date()
              ) {
                setError(errorObj);
                handleTaskSubmit();
              } else {
                {
                  if (tasks && !tasks.taskTypeUUID) {
                    errorObj.taskTypeUUID = 'Please select a task type';
                  }
                  if (tasks && !tasks?.dueDate) {
                    errorObj.dueDate = 'Please select a Due date';
                  }
                  if (tasks && !tasks.taskPriorityUUID) {
                    errorObj.taskPriorityUUID = 'Please select a Priority';
                  }
                  if (
                    tasks &&
                    tasks.dueDate &&
                    new Date(tasks.dueDate) < new Date()
                  ) {
                    errorObj.dueDate = 'Please select a Future Date';
                  }
                  setError(errorObj);
                }
              }
            }}
            disabled={addNoteLoading || ApiSuccess}
          >
            {editTask ? 'Edit' : 'Add'} Task
          </button>
        </div>
        {showModal !== 'closed' && (
          <DocumentAddModal
            show={showModal}
            closeModal={setShowModal}
            file={file}
            setFile={setFile}
            name='task'
            docTypeUUID={docTypeUUID}
            setDocTypeUUID={setDocTypeUUID}
          />
        )}
      </div>
    </>
  );
};

export default Task;

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledCard } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { IOpportunity } from '../../../../interfaces/Opportunity';
import EditOpportunityDetails from './../../../OpportunityDetails/EditOpportunityDetails';
import blackPlus from '../../../../assets/logo/blackplus.svg';

const OpportunityMobileCard = ({
  opportunity,
  setShowDeleteModal,
  setUUID,
  setName,
}: {
  opportunity: IOpportunity;
  setShowDeleteModal: Function;
  setUUID: Function;
  setName: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');

  const dropRef = useRef(null);
  const navigate = useNavigate();

  useOutsideClick(dropRef, setShowDropdown);

  return (
    <StyledCard>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 onClick={() => navigate(`/opportunities/${opportunity.uuid}`)}>
          {opportunity.name}
        </h6>
        <div className='status-div'>
          {opportunity.OpportunityStatus.name.charAt(0).toUpperCase() +
            opportunity.OpportunityStatus.name.slice(1)}
        </div>
      </div>
      <div>{opportunity.Account.name}</div>
      <div className='info-div d-flex'>
        <div className='type-div'>
          {opportunity?.Account.AccountType.name.charAt(0) +
            opportunity?.Account.AccountType.name.slice(1).toLowerCase()}
        </div>
        <div style={{ fontWeight: 600 }}>
          $
          {opportunity.leastPremium
            ? opportunity.leastPremium.toLocaleString('en-US')
            : 0}
        </div>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='toolDiv info d-flex align-items-center h-100'>
          {opportunity.Agent.User.avatar ? (
            <img
              src={opportunity.Agent.User.avatar}
              alt=''
              className='user-pic'
            />
          ) : (
            <div className='user-pic'>
              {opportunity?.Agent?.User?.firstName
                ?.substring(0, 1)
                .toUpperCase()}
              {opportunity?.Agent?.User?.lastName
                ?.substring(0, 1)
                .toUpperCase()}
            </div>
          )}
          <span className='tooltiptext tooltip-producer'>
            <span className='tooltipInnertext'>
              {opportunity?.Agent?.User?.firstName +
                ' ' +
                opportunity?.Agent?.User?.lastName}
            </span>
          </span>
        </div>
        <div className='d-flex'>
          <div className='actionButton'>Start Quoting</div>

          <div className='vector-div'>
            <div
              className='vector'
              onClick={() => setShowDropdown(!showDropdown)}
              ref={dropRef}
            >
              <img src={vector} alt='vector' />
            </div>
            {showDropdown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowEditModal('open');
                    setShowDropdown(false);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='editDiv'
                  style={{ paddingTop: '10px' }}
                  onClick={() =>
                    navigate(`/opportunities/${opportunity.uuid}/activities`)
                  }
                >
                  Add activity <img src={blackPlus} alt='plusIcon' />
                </div>
                <div
                  className='delete-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setUUID(opportunity?.uuid);
                    setShowDropdown(false);
                    setName(opportunity?.name);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
            {showEditModal !== 'closed' && (
              <EditOpportunityDetails
                show={showEditModal}
                closeModal={setShowEditModal}
                UUID={opportunity.uuid}
              />
            )}
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default OpportunityMobileCard;

import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCount } from '../../../store/accounts/accountsSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import DetailsPageTabMobile from '../../../components/DetailsPageTabMobile';
import DetailsPageTab from '../../../components/DetailsPageTab';
import Modal from '../../../components/Modal';
import Link from '../../../components/Link';
import DetailsDrawer from './AccountDetailsDrawer';
import { IAccount } from '../../../interfaces/Account';
import { MobileDetailsDiv } from '../../../styles/DetailsDiv';
import { StyledTab } from '../../../styles/StyledTab';
import ham from '../../../assets/logo/hamburger.png';
import cross from '../../../assets/logo/cross.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import plus from '../../../assets/logo/blackplus.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import { contacts } from '../../../assets/nav-icons';

const MobileDetails = ({
  accountDetails,
  setShowEditModal,
  setShowDeleteModal,
  setAddQuickOpportunity,
  setAddQuickPolicy,
  setAddQuickQuotes,
  setAddQuickDocument,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  accountDetails?: IAccount;
  setShowEditModal: Function;
  setShowDeleteModal: Function;
  setAddQuickOpportunity: Function;
  setAddQuickPolicy: Function;
  setAddQuickQuotes: Function;
  setAddQuickDocument: Function;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
}) => {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState('closed');
  const [showOpportunities, setShowOpportunities] = useState(false);
  const [activity, setActivity] = useState('activities');
  const [showDropdown, setShowDropdown] = useState(false);
  const [addModalDropDown, setAddModalDropDown] = useState(false);
  const [quickDrop, setQuickDrop] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef(null);
  const { uuid } = useParams();
  useOutsideClick(dropRef, setShowOpportunities);
  const activityRef = useRef(null);
  useOutsideClick(activityRef, setShowDropdown);
  const editRef = useRef(null);
  useOutsideClick(editRef, setQuickDrop);
  const addRef = useRef(null);
  useOutsideClick(addRef, setAddModalDropDown);
  const accountDetailsCount = useSelector(selectCount);
  return (
    <MobileDetailsDiv>
      <div className='mobile-topbar d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <button
            onClick={() => setShowDetailsDrawer('open')}
            className='secondaryButton'
          >
            <img alt='' src={ham} />
          </button>
          <div className='profile-pic d-none d-sm-flex align-items-center justify-content-center'>
            {accountDetails?.name
              ?.split(' ')
              .map((item: string) => item.substring(0, 1))
              .join('')}
          </div>
          <h2>{accountDetails?.name}</h2>
          {accountDetails && (
            <div
              className={`status ${
                accountDetailsCount?.policies > 0
                  ? 'statusActive'
                  : 'statusInvited'
              }`}
            >
              {accountDetailsCount?.policies > 0 ? 'Customer' : 'Prospect'}
            </div>
          )}
        </div>
        <div className='d-flex align-items-center'>
          <button
            className='primaryButton w-75 d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setAddModalDropDown(!addModalDropDown)}
            ref={addRef}
          >
            Add
            <img src={dropdown} alt='' />
            {addModalDropDown && (
              <div className='addDropdown-div'>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickOpportunity('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Opportunity <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickQuotes('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Quote
                  <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickPolicy('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Policy <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickDocument('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Document <img src={plus} alt='bin' />
                </div>
              </div>
            )}
          </button>
          <button
            className='dropDownButton d-none d-sm-block'
            onClick={() => setQuickDrop(!quickDrop)}
            ref={editRef}
          >
            <img src={dropdown} alt='' />
            {quickDrop && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowEditModal('open');
                    setQuickDrop(false);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setQuickDrop(false);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </button>
          <button
            onClick={() => {
              navigate('/mybook/accounts');
            }}
            className='secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </div>
      <StyledTab className='d-sm-none'>
        <div className='ActivityName' ref={activityRef}>
          <Link
            to={`/account/${uuid}/${activity}`}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {activity.toLowerCase()}
            {activity !== 'histories' && (
              <span className='count'>{accountDetailsCount[activity]}</span>
            )}
            <img src={dropdown} alt='' />
          </Link>
          {showDropdown && (
            <DetailsPageTabMobile
              name='Account'
              setActivity={setActivity}
              setShowDropdown={setShowDropdown}
            />
          )}
        </div>
      </StyledTab>

      <DetailsPageTab name='Account' isMobile={true} />

      {/* <button className='addButton d-sm-none '>
        <img src={plus} alt='add' />
      </button> */}
      <div className='mobile-bottombar d-flex d-sm-none align-items-center justify-content-between'>
        <button className='dropDownButton'>
          <img src={dropdown} alt='' />
        </button>
        <button className='primaryButton w-75 d-flex justify-content-between align-items-center'>
          Add
          <img src={dropdown} alt='' style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>

      {showDetailsDrawer !== 'closed' && (
        <Modal open={showDetailsDrawer} align='left'>
          <button
            onClick={() => {
              setShowDetailsDrawer('closing');
              setTimeout(() => {
                setShowDetailsDrawer('closed');
              }, 300);
            }}
            className='crossbutton secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div className='modal-content-div'>
            <div className='header-div d-flex align-items-center'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {accountDetails?.name
                  ?.split(' ')
                  .map((item: string) => item.substring(0, 1))
                  .join('')}
              </div>
              <div>
                <h2 className='mb-1'>{accountDetails?.name}</h2>
                <div className='header-info'>
                  {accountDetails?.AccountContacts && (
                    <span className='d-flex align-items-center'>
                      <img src={contacts} alt='' className='contact-img' />

                      <span
                        className='nav-decor'
                        onClick={() =>
                          navigate(
                            `/contacts/${accountDetails.AccountContacts[0].Contact.uuid}`
                          )
                        }
                      >
                        {accountDetails?.AccountContacts[0]?.Contact.firstName}{' '}
                        {accountDetails?.AccountContacts[0]?.Contact.lastName}
                      </span>
                      {accountDetails?.AccountContacts?.length > 1 && (
                        <span className='count-div d-none d-sm-block'>
                          + {accountDetails?.AccountContacts?.length - 1}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {accountDetails && accountDetails?.Opportunities?.length > 0 && (
              <div
                className='details-section d-flex justify-content-between position-relative'
                onClick={() => setShowOpportunities(!showOpportunities)}
                style={{ cursor: 'pointer' }}
                ref={dropRef}
              >
                <h5 className='section-title '>Opportunities</h5>
                <img
                  src={dropdown}
                  alt=''
                  className={showOpportunities ? 'rotate' : ''}
                />
                {showOpportunities && (
                  <div className='opportunity-list'>
                    {accountDetails?.Opportunities.map((item) => (
                      <div
                        key={item.uuid}
                        className='opportunity-item'
                        onClick={() => navigate(`/opportunities/${item.uuid}`)}
                      >
                        {item.name ? item.name : '----'}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className='details-section d-flex justify-content-between'>
              <h5 className='section-title '>Premium</h5>
              <span className='amount'>
                $
                {accountDetails?.premium
                  ? accountDetails?.premium.toLocaleString()
                  : 0}
              </span>{' '}
            </div>

            <DetailsDrawer
              accountDetails={accountDetails}
              setShowEditModal={setShowEditModal}
              setShowLinkContactModal={setShowLinkContactModal}
              setShowAddContactModal={setShowAddContactModal}
            />
          </div>
        </Modal>
      )}
    </MobileDetailsDiv>
  );
};

export default MobileDetails;

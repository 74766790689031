import axios, { AxiosRequestConfig } from 'axios';
import { env } from '../utils/env';
import LocalStorageService from '../utils/LocalStorageService';

const instance = axios.create({
  baseURL: env.app.baseUrl,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {};
    }
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    const ifRemember = LocalStorageService.checkRemember();
    if (error.response.status === 500) {
      localStorage.setItem('error', JSON.stringify(error.response));
      window.location.href = '/error/500';
      return Promise.reject(error);
    } else if (error.response.status === 502) {
      localStorage.setItem('error', JSON.stringify(error.response));
      window.location.href = '/error/502';
      return Promise.reject(error);
    } else if (error.response.status === 503) {
      localStorage.setItem('error', JSON.stringify(error.response));
      window.location.href = '/error/503';
      return Promise.reject(error);
    } else if (error.response.status === 504) {
      localStorage.setItem('error', JSON.stringify(error.response));
      window.location.href = '/error/504';
      return Promise.reject(error);
    } else if (error.response.status === 404) {
      window.location.href = '/error/404-resource';
      return Promise.reject(error);
    } else if (
      (error.response.status === 401 || error.response.status === 403) &&
      (originalRequest.url === `/auth/token` || !ifRemember)
    ) {
      LocalStorageService.clearToken();
      window.location.href = '/error/expired';
      return Promise.reject(error);
    }

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = LocalStorageService.getRefreshToken();
      return instance.post(`/auth/token`, { refreshToken }).then((res) => {
        if (res.status === 200) {
          const { access_token, refresh_token } = res.data;
          LocalStorageService.setToken(access_token, refresh_token);
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + LocalStorageService.getAccessToken();
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export default instance;

import axios from './axios';

const fetchJurisdiction = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  successCB?: Function
) => {
  return axios
    .get('/jurisdiction', {
      params: { sortBy, searchBy, orderBy },
    })
    .then((res) => {
      if (successCB) {
        successCB(res.data);
      }
      return res.data;
    });
};

const getJurisdictionDetails = (uuid: string, successCB: Function) => {
  return axios
    .get(`/jurisdiction/${uuid}`)

    .then((res) => {
      if (successCB) {
        successCB(res.data);
      }
      return res.data;
    });
};

export { fetchJurisdiction, getJurisdictionDetails };

import React, { useState, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { AppDispatch } from '../../store';
import {
  getPipelines,
  PipelinesSliceState,
  selectAllPipelines,
} from '../../store/pipelines/pipelineSlice';
import LocalStorageService from '../../utils/LocalStorageService';
import KanbanView from './components/KanbanView';
import KanbanMobileView from './components/KanbanMobile';
import Opportunity from './components/OpportunityView';
import AddOpportunityDrawer from '../../components/AddOpportunityDrawer';
import SideBar from '../../components/SideBar';
import Link from '../../components/Link';
import TopBar from '../../components/TopBar';
import MobileNav from '../../components/MobileTabNav';
import PipelineModal from '../../components/AddPipelineModal';
import EditPipelineDrawer from '../../components/EditPipelineDrawer';
import { IPipeline, IPipelineStage } from '../../interfaces/Opportunity';
import { PipelineDiv } from './elements/styles';
import Kanban from '../../assets/logo/kanban.svg';
import table from '../../assets/logo/Table.svg';
import filter from '../../assets/logo/filter.svg';
import filterMobile from '../../assets/logo/filter-mobile.svg';
import search from '../../assets/logo/search.svg';
import plus from '../../assets/logo/blackplus.svg';
import vector from '../../assets/logo/dropdown.svg';
import edit from '../../assets/logo/edit.svg';
import FilterMobileModal from '../../components/FilterMobileDrawer';
import FilterModal from '../../components/FilterDrawer';
import { IOption } from '../../interfaces/Opportunity';
import plusLight from '../../assets/logo/plus.svg';
import ErrorCard from './components/ErrorCard';
import closeIcon from '../../assets/logo/cross.svg';

const Pipeline = () => {
  const user = LocalStorageService.getUser();

  const pipelines = useSelector(selectAllPipelines);
  const location: any = useLocation();
  const dropRef = useRef(null);
  const [kanbanView, setKanbanView] = useState(true);
  const [showOpportunityModal, setShowOpportunityModal] = useState('closed');
  const [pipelineDropdown, setPipelineDropdown] = useState(false);
  const [pipelineValue, setPipelineValue] = useState<IPipeline>();
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showAddModal, setShowAddModal] = useState('closed');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [uuid, setUUID] = useState('');
  const [showFilter, setShowFilter] = useState('closed');
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [page, setPage] = useState(0);
  const [selectedOppStatus, setOppStatus] = useState<IOption[]>([]);
  const [selectedProducts, setProducts] = useState<IOption[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedActive, setActive] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const loadingStatus = useSelector(
    (state: { opportunities: PipelinesSliceState }) =>
      state.opportunities?.status
  );

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getPipelines({
          sortBy: 'createdAt',
          orderBy: 'desc',
          searchBy: '',
          productUUID: selectedProducts
            ?.filter((item: any) => item.type === 'PRODUCT')
            .map((item: any) => item.value),
          customProductUUID: selectedProducts
            ?.filter((item: any) => item.type === 'CUSTOM_PRODUCT')
            .map((item: any) => item.value),
          successCB: (data: IPipeline[]) => {
            if (location?.state?.uuid) {
              let notificationpipeline = data.find(
                (item) => item.uuid === location?.state?.uuid
              );
              notificationpipeline && onSetPipelineValue(notificationpipeline);
            } else {
              onSetPipelineValue(data[0]);
            }
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showOpportunityModal]);

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useOutsideClick(dropRef, setPipelineDropdown);

  const onSetPipelineValue = (item: IPipeline) => {
    const temp = item.PipelineStages.reduce((t: any, o) => {
      if (o.nextStageId) {
        t[o.nextStageId] = o;
      } else {
        t['last'] = o;
      }
      return t;
    }, {});

    let result: IPipelineStage[] = [];

    let p: any = 'last';

    while (temp[p]) {
      result.unshift(temp[p]);
      p = temp[p].id;
    }

    setPipelineValue({ ...item, PipelineStages: result });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 576);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/pipeline/kanban') {
      setKanbanView(true);
    } else {
      setKanbanView(false);
    }
  }, [location]);
  return (
    <PipelineDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <div className='d-none d-sm-block'>
        <TopBar>
          <h2 className='page-title d-none d-sm-block'>Sales Pipeline</h2>
        </TopBar>
      </div>
      {showSuccess && (
        <ErrorCard
          message={`<b>  ${
            pipelineValue?.name ? pipelineValue.name : 'Pipeline'
          }</b> has been <b> deleted</b> `}
          title={'Success deleting pipeline.'}
        />
      )}
      <div className='contents-div '>
        <div className='topButtonsDiv '>
          <h2 className='page-title d-block d-sm-none'>Sales Pipeline</h2>
          <div className='d-flex justify-content-sm-between'>
            <div className='d-none d-sm-flex  align-items-center'>
              <div className='toggleButtons'>
                <Link to='/pipeline/kanban'>
                  <button
                    onClick={() => {
                      setKanbanView(true);
                    }}
                    className={` secondaryButton navbuttons  ${
                      kanbanView ? 'activeNav' : ''
                    }`}
                  >
                    <img src={Kanban} alt='kanban' />
                  </button>
                </Link>
                <Link to='/pipeline/opportunities'>
                  <button
                    onClick={() => {
                      setKanbanView(false);
                    }}
                    className={` secondaryButton navbuttons  ${
                      kanbanView ? '' : 'activeNav'
                    }`}
                  >
                    <img src={table} alt='table' />
                  </button>
                </Link>
              </div>
              <button
                className='tertiaryButton'
                onClick={() => setShowOpportunityModal('open')}
              >
                Add opportunity
              </button>
            </div>
            <div className='d-flex justify-content-between justify-content-sm-start align-items-center pipeline-top-nav'>
              <button
                className='dropDownMenu'
                onClick={() => setPipelineDropdown(!pipelineDropdown)}
                ref={dropRef}
              >
                <div className='d-flex align-items-center menu-button'>
                  <span className='dropdownValue'>
                    {pipelineValue?.name ? pipelineValue.name : 'Pipeline'}
                  </span>
                  <div className='widgets'>
                    <img src={vector} alt='' />
                  </div>
                </div>

                {pipelineDropdown && (
                  <div className='pipelineDropDown'>
                    <div className='optionsDiv'>
                      <h6>PIPELINES</h6>
                      <div className='pipeline-options'>
                        {pipelines.map((item) => (
                          <div
                            key={item.uuid}
                            className={`option d-flex align-items-center justify-content-between ${
                              pipelineValue?.name === item.name && 'active'
                            }`}
                            onClick={() => {
                              setSearchBy('');
                              setPage(0);
                              onSetPipelineValue(item);
                            }}
                          >
                            {item.name}
                            <div>
                              {user.UserRole.name === 'PRINCIPAL_AGENT' &&
                                !item.default && (
                                  <img
                                    src={edit}
                                    alt=''
                                    className='ps-3 py-2 pipelineEditIcon'
                                    style={{ borderLeft: '1px solid #D1D6E5' }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUUID(item.uuid);
                                      setShowEditModal('open');
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {user.UserRole.name === 'PRINCIPAL_AGENT' && (
                      <div
                        className='addOptionButton'
                        onClick={() => setShowAddModal('open')}
                      >
                        <img className='mx-4' src={plus} alt='' />
                        Add new pipeline
                      </div>
                    )}
                  </div>
                )}
              </button>
              <span className='divider d-none d-sm-block' />
              <div className='d-flex align-items-center'>
                {isSearchClicked || searchBy ? (
                  <div
                    className='widgets searchActive d-flex align-items-center'
                    onBlur={() => setIsSearchClicked(false)}
                  >
                    <img src={search} alt='' className='search-button' />
                    <input
                      onChange={(e) => {
                        setPage(0);
                        setSearchBy(e.target.value);
                      }}
                      autoFocus
                      value={searchBy}
                    />
                    <img
                      src={closeIcon}
                      alt=''
                      className='close-button'
                      onClick={() => {
                        setSearchBy('');
                        setIsSearchClicked(false);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className='widgets'
                    onClick={() => setIsSearchClicked(true)}
                  >
                    <img src={search} alt='' />
                  </div>
                )}
                <span className={'divider'} />
                <div
                  className='widgets position-relative'
                  onClick={() => setShowFilter('open')}
                >
                  <img
                    className='d-none d-sm-block 
                  '
                    src={filter}
                    alt=''
                  />
                  <img
                    onClick={() => setShowMobileFilter(true)}
                    className={'d-block d-sm-none '}
                    src={filterMobile}
                    alt=''
                  />
                  {selectedOppStatus.length + selectedProducts.length > 0 && (
                    <span className='filter-counter'>
                      {selectedOppStatus.length + selectedProducts.length}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='pipelineBody'>
          <Routes>
            <Route
              path='/kanban'
              element={
                pipelineValue?.PipelineStages ? (
                  !isMobile ? (
                    <KanbanView
                      stages={pipelineValue?.PipelineStages.filter(
                        (item, index) =>
                          index < pipelineValue.PipelineStages.length - 2
                      )}
                      pipeline={pipelineValue}
                      xDateStageId={
                        pipelineValue?.PipelineStages[
                          pipelineValue.PipelineStages.length - 1
                        ]?.uuid
                      }
                      lostStageId={
                        pipelineValue?.PipelineStages[
                          pipelineValue.PipelineStages.length - 2
                        ]?.uuid
                      }
                      selectedProducts={selectedProducts}
                      pipeLineStage={selectedOppStatus}
                      searchBy={debouncedSearchTerm}
                      selectedActive={selectedActive}
                    />
                  ) : (
                    <>
                      <KanbanMobileView
                        stages={pipelineValue?.PipelineStages.filter(
                          (item, index) =>
                            index < pipelineValue.PipelineStages.length - 2
                        )}
                        pipeline={pipelineValue}
                        xDateStageId={
                          pipelineValue?.PipelineStages[
                            pipelineValue.PipelineStages.length - 1
                          ]?.uuid
                        }
                        lostStageId={
                          pipelineValue?.PipelineStages[
                            pipelineValue.PipelineStages.length - 2
                          ]?.uuid
                        }
                        selectedProducts={selectedProducts}
                        selectedOppStatus={selectedOppStatus}
                        selectedActive={selectedActive}
                        pipeLineStage={selectedOppStatus}
                      />
                      <button
                        className='addOppostunityButtonMobile'
                        onClick={() => setShowOpportunityModal('open')}
                      >
                        <img src={plusLight} alt='' />
                      </button>
                    </>
                  )
                ) : (
                  <></>
                )
              }
            />
            <Route
              path='/opportunities'
              element={
                pipelineValue ? (
                  <Opportunity
                    pipelineUUID={pipelineValue?.uuid}
                    selectedProducts={selectedProducts}
                    selectedOppStatus={selectedOppStatus}
                    searchBy={debouncedSearchTerm}
                    page={page}
                    setPage={setPage}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path='*'
              element={<Navigate to='/pipeline/kanban' replace />}
            />
          </Routes>
        </div>
      </div>
      {showOpportunityModal !== 'closed' && (
        <AddOpportunityDrawer
          show={showOpportunityModal}
          closeModal={setShowOpportunityModal}
        />
      )}
      {showAddModal !== 'closed' && (
        <PipelineModal show={showAddModal} closeModal={setShowAddModal} />
      )}
      {showEditModal !== 'closed' && (
        <EditPipelineDrawer
          show={showEditModal}
          closeModal={(status: string, value?: IPipeline) => {
            setShowEditModal(status);
            if (value) onSetPipelineValue(value);
          }}
          pipeline={pipelines.find((item) => item?.uuid === uuid)}
          setDeleteSuccess={setShowSuccess}
          deleteSuccess={showSuccess}
        />
      )}
      <div className='d-none d-sm-block'>
        {showFilter !== 'closed' && (
          <FilterModal
            show={showFilter}
            closeModal={setShowFilter}
            selectedOppStatus={selectedOppStatus}
            setProducts={setProducts}
            setOppStatus={setOppStatus}
            selectedProducts={selectedProducts}
            selectedActive={selectedActive}
            setActive={setActive}
            pipelineStages={pipelineValue}
          />
        )}
      </div>
      <div className='d-block d-sm-none'>
        {showMobileFilter ? (
          <FilterMobileModal
            show={showMobileFilter}
            closeModal={setShowMobileFilter}
            selectedOppStatus={selectedOppStatus}
            setProducts={setProducts}
            setOppStatus={setOppStatus}
            selectedProducts={selectedProducts}
            selectedActive={selectedActive}
            setActive={setActive}
            pipelineStages={pipelineValue}
          />
        ) : null}
      </div>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </PipelineDiv>
  );
};

export default Pipeline;

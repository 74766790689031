import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import DateField from '../../ActivityCustomeDateField';
import InputField from '../../CustomInputField';
import SelectField from '../../ActivityCustomSelectField';
import pin from '../../../assets/logo/pin.svg';
import plus from '../../../assets/logo/blackplus.svg';
import GuestListDropDown from '../../../pages/Activities/component/GuestListDropDown';
import { StyledGuestComponent } from '../styles';

const Events = ({
  guest,
  guestList,
  guests,
  onSetEvent,
  eventType,
  events,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  handleEventSubmit,
  ApiSuccess,
  setShowModal,
  file,
  setGuestUUID,
  guestUUID,
  setGuest,
  showGuestList,
  isEdit,
  setShowEdit,
}: {
  guest: any;
  guestList: any;
  guests: any;
  onSetEvent: any;
  eventType: any;
  events: any;
  setStartDate: Function;
  setEndDate: Function;
  endDate: any;
  startDate: Date;
  handleEventSubmit: Function;
  ApiSuccess: boolean;
  setShowModal: Function;
  file: any;
  setGuestUUID: Function;
  guestUUID: any;
  setGuest: Function;
  showGuestList: Function;
  isEdit?: boolean;
  setShowEdit?: Function;
}) => {
  const [guestManageDropDown, setGuestManageDropDown] = useState(false);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, showGuestList);
  const dropManageGuestRef = useRef(null);
  useOutsideClick(dropManageGuestRef, setGuestManageDropDown);

  const [eventError, setError] = useState<{
    eventTypeUUID?: string;
    period?: string;
    guests?: string;
    name?: string;
  }>();

  useEffect(() => {
    let errorObj: {
      eventTypeUUID?: string;
      period?: string;
      guests?: string;
      name?: string;
    } = {};
    if (
      eventError?.eventTypeUUID ||
      eventError?.period ||
      eventError?.guests ||
      eventError?.name
    ) {
      if (!events.name) {
        errorObj.name = 'Please enter the title.';
      }
      if (!events.eventTypeUUID) {
        errorObj.eventTypeUUID = 'Please select a type.';
      }
      if (guest.length === 0) {
        errorObj.guests = 'Please select the guests.';
      }
      if (!startDate || !endDate) {
        errorObj.period = 'Please select the period.';
      }
      setError(errorObj);
    }
  }, [events]);

  return (
    <>
      <div className='d-sm-flex justify-content-between tab-content-div'>
        <div className='fields col'>
          <InputField
            name='name'
            type='text'
            placeholder='Event Title'
            onSetItem={onSetEvent}
            values={events}
            firstField={true}
          />
          <div className='errorVal'>
            {eventError?.name ? (
              <span className=''>{eventError.name} </span>
            ) : null}
          </div>
        </div>

        <div className='fields col'>
          <SelectField
            options={eventType.map((item: any) => {
              return {
                label: `${
                  item?.name === 'CALENDAR'
                    ? 'Meeting'
                    : item.name
                    ? item?.name.charAt(0).toUpperCase() +
                      item?.name.slice(1).toLowerCase()
                    : ''
                }`,
                value: item?.uuid,
              };
            })}
            name='eventTypeUUID'
            placeholder='Event type'
            onSetItem={onSetEvent}
            values={events}
          />
          <div className='errorVal'>
            {eventError?.eventTypeUUID ? (
              <span className=''>{eventError.eventTypeUUID} </span>
            ) : null}
          </div>
        </div>
        <div className=' fields col'>
          <DateField
            name='scheduledDate'
            placeholder='Period'
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            endDate={endDate}
            startDate={startDate}
            onSetItem={onSetEvent}
            values={events}
            multiselect={true}
            minDate={new Date()}
          />
          <div className='errorVal'>
            {eventError?.period ? (
              <span className=''>{eventError.period} </span>
            ) : null}
          </div>
        </div>
        <div className='fields col'>
          <InputField
            name='description'
            type='text'
            placeholder='Description'
            onSetItem={onSetEvent}
            values={events}
            lastField={true}
            editTask={isEdit ? isEdit : false}
          />
        </div>
      </div>
      <div className='d-sm-flex justify-content-between addTaskButton guest-addContainer'>
        <div className='d-flex align-items-center'>
          {isEdit && setShowEdit && (
            <button
              className='cancelEdit'
              type='button'
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </button>
          )}

          <StyledGuestComponent isEdit={isEdit ? isEdit : false}>
            <div
              className='d-flex position-relative ms-sm-3 innertab-event-guest'
              style={{ color: '#262758' }}
              ref={dropRef}
            >
              <div
                className='addUser d-flex align-items-center justify-content-center'
                onClick={() => showGuestList(!guestList)}
              >
                <img src={plus} alt='' />
              </div>
              {guestList && (
                <GuestListDropDown
                  contactList={guests}
                  guestUUID={guestUUID}
                  setGuestUUID={setGuestUUID}
                  setGuests={setGuest}
                  guests={guest}
                />
              )}
              {guest?.length > 0 ? (
                <div className='d-flex align-items-center guestsContainer  col'>
                  {guest?.map((item: any, index: number) => (
                    <>
                      {index < 3 ? (
                        <div
                          className={`profile-pic d-flex align-items-center justify-content-center`}
                          key={index}
                        >
                          <div className='profile'>
                            {item?.firstName?.charAt(0)}{' '}
                            {item?.lastName?.charAt(0)}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                  {guest?.length > 3 ? (
                    <span className='Guestprofile-pic GuestprofileNumber d-flex align-items-center justify-content-center'>
                      +{guest.length - 3}
                    </span>
                  ) : null}
                </div>
              ) : (
                <>
                  <div className='d-flex align-items-center guestsContainer  col'>
                    Add Guest/s
                  </div>
                  <div
                    className='errorVal position-absolute'
                    style={{ top: 27 }}
                  >
                    {eventError?.guests ? (
                      <span className=''>{eventError.guests} </span>
                    ) : null}
                  </div>
                </>
              )}
            </div>

            {guest.length > 0 && (
              <>
                <button
                  type='button'
                  className='ManageGuestHead'
                  onClick={() => setGuestManageDropDown(true)}
                  ref={dropManageGuestRef}
                >
                  Manage Guests
                  {guestManageDropDown && (
                    <GuestListDropDown
                      contactList={guests}
                      guestUUID={guestUUID}
                      setGuestUUID={setGuestUUID}
                      setGuests={setGuest}
                      guests={guest}
                      Manage={true}
                    />
                  )}
                </button>
              </>
            )}
          </StyledGuestComponent>
        </div>

        <div className='ButtonsContainer d-flex align-items-center'>
          <span className='DocNameField'>
            {file?.name ? file.name : events.file?.name}
          </span>
          <button
            className='addDocument d-flex align-items-center justify-content-center  me-3'
            onClick={() => {
              setShowModal('open');
            }}
          >
            <img src={pin} alt='' className='' />
          </button>
          <button
            className='tertiaryButton '
            type='button'
            onClick={() => {
              let errorObj: {
                eventTypeUUID?: string;
                period?: string;
                guests?: string;
                name?: string;
              } = {};

              if (
                guest.length > 0 &&
                startDate &&
                endDate &&
                events.eventTypeUUID &&
                events.name
              ) {
                handleEventSubmit();
              } else {
                if (!events.name) {
                  errorObj.name = 'Please enter the title.';
                }
                if (!events.eventTypeUUID) {
                  errorObj.eventTypeUUID = 'Please select a type.';
                }
                if (guest.length === 0) {
                  errorObj.guests = 'Please select the guests.';
                }
                if (!startDate || !endDate) {
                  errorObj.period = 'Please select the period.';
                }
              }
              setError(errorObj);
            }}
            disabled={ApiSuccess}
          >
            {isEdit ? 'Edit' : 'Add'} Event
          </button>
        </div>
      </div>
    </>
  );
};

export default Events;

import styled from '@emotion/styled';

export const StyledEditQuoteModal = styled.div`
  position: relative;
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
  }

  .cross {
    width: 20px;
  }

  h2 {
    font-size: 20px;
  }

  .subhead {
    font-size: 14px;
    line-height: 20px;
    color: #5c698c;
    margin-top: 8px;
  }

  .contact-para {
    font-weight: 600;
    margin-top: 24px;
    color: #000000;
  }

  .agency-fields {
    width: 99%;
    margin: 16px auto;
    color: #262758;
    .search-group {
      .input-placeholder {
        left: 18px;
      }
      .input-field {
        padding: 18px 25px 10px 18px;
      }
    }
  }
  .agency-fields::placeholder {
    color: #262758;
  }

  .cancel-button {
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 88%;
    bottom: 0;
    left: 20px;
    background: #fff;
    z-index: 2;
    padding: 20px 0px;
  }
  .field-section {
    padding-right: 6px;
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 145px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 40px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .bottom-head {
    font-weight: 600;
    font-size: 16px;
  }
  .bigfields {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    margin-top: 18px;
    border: 2px dotted #e6e9f0;
    background: #ffffff;
  }
  .row {
    border-bottom: 1px solid #e6e9f0;
    margin-top: 16px;
  }
  .row-bottom {
    border-bottom: 1px solid #e6e9f0;
    position: fixed;
  }
  .documentDetails {
    margin-top: 24px;
    margin-bottom: 160px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 12px 24px;
  }
  .dropList {
    margin: 0 6px;
    padding: 2px 0px;
    border-bottom: 1px solid #e6e9f0;
  }
  .dropList:last-child {
    border: none;
  }
  .documentDropDown {
    position: relative;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px;
    border: none;
    width: 316px;
    height: 32px;
  }
  .documentTypeDrop {
    position: absolute;
    top: 38px;
    left: 0;
    background: #fff;
    width: 316px;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
    .listType {
      padding-top: 10px;
      border-bottom: 1px solid #e6e9f0;
      text-align: start;
    }
    .listType:last-child {
      border-bottom: none;
    }
  }
  .binImage {
    margin-left: 20px;
    cursor: pointer;
  }
  @media screen and (min-width: 576px) {
    width: 360px;
    .subhead {
      max-width: 300px;
    }
    .buttonsDiv {
      width: 81%;
      left: 41px;
      padding: 20px 2px;
    }
    .bottom-head {
      font-weight: 600;
      font-size: 20px;
    }
    .row {
      margin-top: 24px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 420px;
    .cross {
      width: 32px;
    }

    h2 {
      font-size: 24px;
    }

    .buttonsDiv {
      width: 84%;
      left: 40px;
      padding: 20px 16px;
    }
    .bottom-head {
      font-size: 16px;
    }
    .row-bottom {
      border-bottom: 1px solid #e6e9f0;
      padding-bottom: 45px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 576px;

    h2 {
      font-size: 28px;
    }

    .subhead {
      font-size: 16px;
      max-width: 450px;
    }

    .contact-para {
      font-size: 20px;
    }

    .agency-fields {
      margin: 20px auto;
    }

    .cancel-button {
      height: 64px;
      margin-right: 36px;
    }

    .agentButton {
      width: 200px;
      height: 64px;
    }

    .buttonsDiv {
      width: 85%;
      left: 44px;
      padding: 40px 8px;
    }
    .row {
      margin-top: 32px;
    }
    .documentDropDown {
      margin-left: -146px;
    }
  }
`;

import { StyledModal } from './styles';

function Modal({
  open,
  children,
  align,
  containerClassName,
}: {
  open: string;
  children: React.ReactNode;
  align: string;
  containerClassName?: string;
}) {
  return (
    <StyledModal className={`${open === 'open' ? 'open-drawer' : ''}`}>
      <div
        className={`drawer-container ${open} align-${align} ${containerClassName}`}
      >
        {children}
      </div>
    </StyledModal>
  );
}

export default Modal;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { selectAgency } from '../../store/agents/agentsSlice';
import TopBar from '../../components/TopBar';
import { SettingsDiv } from './styles';
import settings from '../../assets/data/settings.json';
import search from '../../assets/logo/search.svg';
import info from '../../assets/logo/info.svg';
import agencyLogo from '../../assets/logo/agencySettings.svg';
import userLogo from '../../assets/logo/userSetting.svg';
import goalLogo from '../../assets/logo/goalsetting.svg';
import leadLogo from '../../assets/logo/leadsetting.svg';
import pipelineLogo from '../../assets/logo/pipelinesetting.svg';
import policyLogo from '../../assets/logo/policysetting.svg';
import carrierLogo from '../../assets/logo/carriersetting.svg';
import customLogo from '../../assets/logo/customsetting.svg';
import LocalStorageService from '../../utils/LocalStorageService';

interface ISubSettings {
  title: string;
  parent: string;
  palocation: string;
  adminlocation: string;
}

const AgencySettings = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const agencyDetails = useSelector(selectAgency);
  const [searchResults, setSearchResults] = useState<ISubSettings[]>([]);
  const [selectedParents, setSelectedParents] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  const user = LocalStorageService.getUser();

  const settingCategories = [
    {
      title: 'Agency Settings',
      description: 'Update your agency information',
      logo: agencyLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/settings`
          : '/settings/Agencysettings/',
      tooltip: `Update your agency's general info such as Name, Logo, Contact details, Website, Locations, Social Media links`,
    },
    {
      title: 'User Settings',
      description: 'Manage Users',
      logo: userLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/user`
          : '/settings/user',
      tooltip: `Add/Deactivate users
      Add/Edit user details such as Role, Location & Contact info`,
    },
    {
      title: 'Goal Settings',
      description: 'Create and manage goals for users and agency',
      logo: goalLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/goal`
          : '/settings/goal',
      tooltip: `Add/Edit monthly goals for all users and agency and check the current & past achievement of goals`,
    },
    {
      title: 'Lead Settings',
      description: 'Manage lead sources and lost and X-Date reasons',
      logo: leadLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/leads`
          : '/settings/leads',
      tooltip: `Add/Edit/Remove lead sources & types, Add/Edit/Remove lost reasons, Add/Edit/Remove x-date reasons`,
    },
    {
      title: 'Pipeline Settings',
      description: 'Manage pipelines for your leads funnel ',
      logo: pipelineLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/pipeline`
          : '/settings/manage-pipeline',
      tooltip: 'Add/Edit/Deactivate pipelines',
    },
    {
      title: 'Policy Settings',
      description: 'Manage policy category and policy types',
      logo: policyLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/policy`
          : '/settings/policy',
      tooltip: 'Add/Edit/Remove policy types & categories',
    },
    {
      title: 'Carrier Settings',
      description: 'Manage carriers',
      logo: carrierLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/carrier`
          : '/settings/carrier',
      tooltip:
        'Add new carriers, Deactivate existing carriers, Link policies types available with each carrier for your agency',
    },
    {
      title: 'Custom Field Settings',
      description: 'Manage custom fields for forms',
      logo: customLogo,
      location:
        user.UserRole.name === 'ADMIN'
          ? `/admin/agency/${uuid}/customfield`
          : '/settings/customfield',
      tooltip:
        'Add/Edit custom fields for leads, opportunities, accounts, policies and contact which your agencies use',
    },
    {
      title: 'Billing Settings',
      description: 'Manage payments and billings',
      logo: '',
      location: '',
      disabled: true,
      tooltip: '',
    },
  ];

  const getHighlightedText = (text: string) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part?.toLowerCase() === searchText?.toLowerCase()
                ? { fontWeight: 700 }
                : {}
            }
          >
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  return (
    <>
      <SettingsDiv>
        <div className='content-div'>
          <TopBar
            backgroundColor={window.innerWidth < 576 ? '#fafafa' : '#fff'}
          >
            {user?.UserRole.name === 'ADMIN' ? (
              <div className='breadcrumb-div'>
                <span
                  onClick={() => navigate('/admin/agency')}
                  className='breadcrumb-item'
                >
                  Manage Agency /
                </span>
                {agencyDetails?.name} Team
              </div>
            ) : (
              <h2>Settings</h2>
            )}
          </TopBar>
          <div className={'search-div topButtonsDiv position-relative'}>
            <img src={search} className='search-icon' alt='search' />
            <input
              type='text'
              placeholder='Search settings'
              onChange={(e) => {
                if (e.target.value) {
                  setSearchResults(
                    settings.filter((item) =>
                      item.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                  setSelectedParents(
                    searchResults
                      .map((item) => item.parent)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )
                  );
                  setSearchText(e.target.value);
                } else {
                  setSearchResults([]);
                  setSelectedParents([]);
                  setSearchText('');
                }
              }}
            />
            {searchResults.length > 0 && (
              <div className='results-div'>
                {searchResults.map((item) => (
                  <button
                    onClick={() =>
                      navigate(
                        user.UserRole.name === 'ADMIN'
                          ? item.adminlocation.replace('${uuid}', `${uuid}`)
                          : item.palocation
                      )
                    }
                  >
                    {getHighlightedText(item.title)}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-between'>
            {settingCategories.map((item) => (
              <div
                className={`setting-card d-flex d-xl-block justify-content-between align-items-center ${
                  item.disabled ? 'disabled-card' : ''
                }  ${
                  selectedParents.includes(item.title)
                    ? 'active-card'
                    : selectedParents.length > 0
                    ? 'inactive-card'
                    : ''
                }`}
                onClick={() => navigate(item.location)}
              >
                <div className='d-flex d-xl-block'>
                  <div className='setting-logo'>
                    {item.logo && <img src={item.logo} alt='' />}
                  </div>
                  <div>
                    <h6>{item.title}</h6>
                    <div className='setting-description'>
                      {item.description}
                    </div>
                  </div>
                </div>{' '}
                <div className='info'>
                  <img src={info} alt='' />
                  <span className='tooltiptext'>
                    {item.tooltip
                      ? item.tooltip
                      : `Update your Agency's general info, Locations etc.`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SettingsDiv>
    </>
  );
};

export default AgencySettings;

import { useEffect, useRef, useState } from 'react';
import cross from '../../../assets/logo/cross.svg';
import { CancelButton } from '../../../components/DeleteModal/styles';
import rightArrow from '../../../assets/logo/right-arrow.svg';
import { fetchQuoteStages, updateQuoteDetails } from '../../../api/quote';
import { StyledModal } from './styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import SuccessCard from '../../../components/SuccessCard';
import {
  onUpdateQuotes,
  onUpdateQuotesStage,
} from '../../../store/quotes/quoteSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import Skeleton from 'react-loading-skeleton';

const ChangeQuoteStageModal = ({
  showModal,
  closeModal,
  uuid,
  currentStage,
}: {
  showModal: string;
  closeModal: Function;
  uuid: string;
  currentStage: string;
}) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal('closed');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [loading, setLoading] = useState('idle');

  const [selectedStage, setSelectedStage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const changeStageFun = () => {
    setLoading('loading');
    dispatch(
      onUpdateQuotesStage({
        data: { action: 'changeStage', quoteStageUUID: selectedStage },
        uuid: uuid,
        successCB: () => {
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            closeModal('closed');
            setShowSuccess(false);
            setLoading('succeeded');
          }, 2000);
        },
        errorCB: () => {
          setLoading('succeeded');
        },
      })
    );
  };
  const [quoteStage, setQuoteStage] = useState<any[]>([]);

  useEffect(() => {
    setLoading('loading');
    fetchQuoteStages((data: any[]) => {
      setQuoteStage(data);
      setLoading('succeeded');
    });
    setSelectedStage(currentStage);
  }, []);

  return (
    <StyledModal>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success updating quotes stage'}
            message={`<b>Quote stage has been updated</b>.`}
          />
          <button
            onClick={() => {
              setShowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <div className='modal-container' ref={wrapperRef}>
        <button
          onClick={() => {
            closeModal('closed');
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <h4>Change quote status to</h4>
        <p>Select a status</p>
        <div className='quote-stage-container'>
          {loading === 'loading' ? (
            <>
              <div className='fields my-2'>
                <Skeleton height={48} />
              </div>
              <div className='fields my-2'>
                <Skeleton height={48} />
              </div>
              <div className='fields my-2'>
                <Skeleton height={48} />
              </div>
              <div className='fields my-2'>
                <Skeleton height={48} />
              </div>
            </>
          ) : (
            quoteStage.map((item: any) => (
              <div className='fields'>
                <label
                  className={`radio-container ${
                    item.uuid === selectedStage ? 'activestage' : ''
                  }`}
                >
                  <input
                    type='radio'
                    className='form-check-input'
                    name='status'
                    value='quoteStageUUID'
                    onChange={() => setSelectedStage(item.uuid)}
                    defaultChecked={item.uuid === currentStage}
                  />
                  {item.name?.toLowerCase()}
                </label>
              </div>
            ))
          )}
        </div>

        <div className='border d-block d-sm-none'></div>
        <div className='d-flex button-container'>
          <CancelButton
            type='button'
            onClick={() => {
              closeModal('closed');
            }}
          >
            Cancel
          </CancelButton>
          <button
            type='submit'
            className='submitButton'
            onClick={() => changeStageFun()}
            disabled={loading === 'loading' || showModal === 'closing'}
          >
            Submit <img src={rightArrow} alt='' />
          </button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ChangeQuoteStageModal;

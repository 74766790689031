import { GlobalTCContainer } from '../styles';

const GlobalPrivacyStatement = () => {
  const companyName = 'Renegade insurance';
  return (
    <GlobalTCContainer>
      <div>
        <h2>Global Privacy Statement</h2>
        <div className='updated-date'>Updated on: 12 Sept, 2022</div>
      </div>
      <div className='content'>
        <h3 className='mt-0'>Our Customer Information</h3>

        <p>
          {companyName} is keen on data security as we are well aware that our
          users (you) care about how personal information is used. Hosted on
          high performing Google & Amazon clouds, we assure you that all our
          customer information is held confidential. We never sell our customer
          list or our customer information. All the customer information
          collected such as name, email address, phone number, website, social
          media handles, mailing address, billing information or any other
          information related to third party integrations is collected for the
          sole purpose of providing the best services to our customers and to
          update them on the improvements to our product and services.
        </p>
        <p>
          We will send product updates, special offers or promotional notices
          via mail, email or voice broadcast, from time to time, to our
          customers and prospects who have expressed interest and requested such
          information. The customer/ prospect can always opt out from receiving
          such offers/ notifications by following the opt out link on the
          specific communication or by contacting {companyName} directly.
        </p>
        <h3>Our Customer Lead Information</h3>

        <p>
          Our customer lead information is tantamount to our customer
          information for us. All data with relation to leads generated by our
          customers or any other information collected automatically by our
          customers is held in strict confidence. We never reach out to them,
          nor do we share customer lead information to any third party.
        </p>
        <h3>Our Integration Partner Information</h3>

        <p>
          {companyName} has ever increasing integrations with third party
          software and services. We promise high data security on all the
          information shared by our integration partners. We collect information
          for integration purposes, commission checks and for tax compliance.
        </p>
        <p>
          Apps’ use of information received and Apps’ transfer of information to
          any other app from Google APIs will adhere to Google's Limited Use
          Requirements.
        </p>

        <h3>Disclosure to Third Parties</h3>

        <p>
          We do not share or sell your personal information to third parties.
        </p>

        <span>We disclose information only in the following cases:</span>
        <ol>
          <li>
            As required by law, such as to comply with a subpoena or a similar
            legal process.
          </li>
          <li>
            When we believe in good faith that disclosure is necessary to
            protect our rights, protect your safety or the safety of others, to
            investigate fraud, or to respond to a government request.
          </li>
          <li>
            If we are involved in a merger, acquisition, or sale of all or a
            portion of its assets, you will be notified via email and/or a
            prominent notice through our app of any change in ownership or uses
            of your personal information, as well as any choices you may have
            regarding your personal information to any other third party with
            your prior consent to do so.
          </li>
        </ol>
        <h3>Security</h3>

        <p>
          The security of your Google Contacts and Google Apps personal
          information is important to us. We take commercially reasonable
          measures and follow generally accepted standards to protect the
          information you provide us, both during transmission and once we
          receive it. For example, the information you provide is transmitted
          via encryption using technologies such as secure socket layer
          technology (SSL).
        </p>
        <p>
          No method of transmission over the Internet, or method of electronic
          storage, is 100% secure. Therefore, we cannot guarantee the absolute
          security of your information.
        </p>

        <h3>Accessing Your Information</h3>

        <p>
          To review, correct, or update your customers' information, you can use
          the {companyName} tool or you can update this information directly
          from Google Apps. If you have chosen to synchronize your customer and
          contact data using two-way synchronization, then the data will be
          updated both in Agile CRM and Google Contacts no matter where you make
          the changes. You may also decide to have just one-way synchronization
          which limits the syncing process to only one medium - either{' '}
          {companyName} or Google Contacts.
        </p>
        <p>
          We will retain the synced/imported information for as long as your
          account is active or as needed to provide you with our services.
        </p>
        <p>
          If you wish to close your account or request that we no longer use
          your Google Contacts or Google Apps information you can either
          uninstall the Google Contacts App in the "Integrations" sections of
          our application, or if you want to delete all customer data that was
          imported to {companyName} simply delete your account in coordination
          with our support department.
        </p>

        <h3>Privacy Statement Updates</h3>

        <p>
          We may update this privacy statement to reflect changes to our
          business or this app as it pertains to the information collected from
          you and our use of it. If the change impacts how we use or handle
          information collected from you, we will email you and/or your
          company’s Google administrator or post a notice where you first access
          this app prior to the change becoming effective. We encourage you to
          periodically review this page for the latest information on our
          privacy practices. Apps’ use of information received from Gmail APIs
          will adhere to Google's Limited Use Requirements.
        </p>
      </div>
    </GlobalTCContainer>
  );
};

export default GlobalPrivacyStatement;

import { INotification } from '../interfaces/Notifications';
import axios from './axios';

const getNotifications = (
  offset: number,
  limit?: number,
  unread?: boolean,
  summary?: boolean
) => {
  return axios
    .get('/notification', {
      params: { limit, offset, unread, summary },
    })
    .then((res) => {
      return res;
    });
};

const UpdateNotification = (data: {
  notificationUUID?: string;
  action: string;
}) => {
  return axios.patch(`/notification`, data).then((res) => {
    return res;
  });
};

const UpdateNotificationSettings = (data: INotification) => {
  return axios.patch(`/notification/settings`, data).then((res) => {
    return res;
  });
};

export { getNotifications, UpdateNotification, UpdateNotificationSettings };

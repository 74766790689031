import styled from '@emotion/styled';
export const StyledEditDetailsPage = styled.div`
  .topdiv {
    width: 100%;
    border-bottom: 1px solid #e6e9f0;
    z-index: 2;
    height: 59px;
  }
  .entire-div {
    width: 100%;
    padding: 16px 0px;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 18%;
    top: 0;
    border: none;
    padding: 20px 22px;
  }
  .carrier-batch-tableContainer > div {
    height: unset;
    padding: 0 5px;
  }
  .errorVal {
    margin-top: 0px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    &.emailError {
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }

  .fields .searchValues-container {
    max-width: 200%;
    width: max-content;
    min-width: 100%;
  }
  .scroll-bar {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 145px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 25px;
  }

  .contact-info {
    left: 49px;
    top: 133.96px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;
  }
  .business-info {
    margin-top: 20px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
  }
  .fields {
    width: 80vw;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    color: #262758;
    .input-container,
    .input-field {
      border: none;
      &:hover {
        background: #f5f7fc !important;
        box-shadow: none;
      }
    }
    &.error-name {
      margin-bottom: 50px;
    }
  }
  .fields::placeholder {
    color: #262758;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 0;
    background: #fff;
    padding: 48px 110px 48px 0px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 20px;
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #c8cacf;
      border: none;
    }
  }
  .naVigatorbuttons {
    colour: #262758;
    background: none;
    border: none;
    margin: 1px 15px;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    font-size: 14px;
    border: none;
    margin: 0 1px;
    border-bottom: 3px solid #000000;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #000000;
  }
  .LeadSourcefield {
    font-size: 12px;
    width: 240px;
    height: 32px;
    margin-left: 1px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .select__control:hover {
    .select__dropdown-indicator {
      display: block;
    }
  }
  .select__clear-indicator {
    display: none;
  }
  .search {
    .search-group {
      .input-placeholder {
        font-size: 12px;
        color: #5a5f70;
      }
      .input-field {
        padding: 38px 25px 10px 10px;
      }
    }
  }
  .input-field {
    padding: 30px 50px 4px 10px;
  }
  .ValueContainer {
    padding: 16px 8px;
  }

  .carrier-batch-tableContainer {
    height: 40vh;
    padding-bottom: 5px;
    padding-top: 1px;
  }

  @media screen and (min-width: 576px) {
    .fields {
      width: 224px;
    }
    .drawer-container {
      width: 100%;
    }
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 150px;
      }
    }
    .small-div {
      width: 360px;
    }
    .topdiv {
      height: 65px;
    }
    .fields {
      width: 224px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .fields-xsmall {
      width: 104px;
    }
    .fields-medium {
      width: 240px;
    }
    .cross-btn {
      padding: 26.68px 46px;
      right: 7%;
    }
    .entire-div {
      padding: 0px 48px;
      width: 848px;
      margin: 0 auto;
    }
    .scroll-bar {
      height: 80vh;
      padding-bottom: 80px;
      padding-right: 12px;
    }

    .mailingAdressHead {
      margin-top: 20px;
    }
    .buttonsDiv {
      width: 848px;
      padding: 48px 208px 48px 0px;
    }
    .errorVal {
      font-size: 12px;
    }
    .carrier-batch-tableContainer > div {
      height: 100%;
      padding: 0;
    }
  }
  @media screen and (min-width: 1200px) {
    .fields {
      width: 184px;
      margin-top: 26px;
      margin-right: 48px;
    }
    .topdiv {
      height: 70px;
    }
    .entire-div {
      padding: 0px;
      width: 983px;
      margin: 0 auto;
    }

    .batchCariier-container {
      width: 1120px;
    }
    .small-div {
      width: 420px;
    }
    .fields-small {
      width: 200px;
    }
    .fields-medium {
      width: 298px;
      margin-top: 16px;
    }
    .fields-xsmall {
      width: 101px;
      margin-top: 16px;
    }
    .contact-info {
      margin-top: 24px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      padding: 24px 44px;
    }
    .cross {
      width: 32px;
    }
    .scroll-bar {
      height: 73vh;
    }
    .saveButton {
      width: 220px;
    }
    .buttonsDiv {
      width: 983px;
      padding: 48px 100px 48px 0px;
    }
    .search {
      .search-group {
        .input-field {
          padding: 22px 25px 10px 10px;
        }
      }
    }
    .ValueContainer {
      padding: 5px 8px;
    }
    .input-field {
      padding: 26px 50px 4px 10px;
    }
    .warning {
      width: 18px;
    }
    .errorVal {
      font-size: 10px;
    }
  }
  @media screen and (min-width: 1900px) {
    .cross-btn {
      right: 23%;
    }
    .topdiv {
      width: 100%;
      height: 70px;
    }
    .cross {
      width: 32px;
    }
    .personal {
      width: 56px;
    }
    .commercial {
      width: 80px;
      margin-left: 20px;
    }
    .contact-info {
      font-size: 20px;
      line-height: 28px;
      margin-top: 20px;
      margin-left: 15px;
    }
    .business-info {
      font-size: 40px;
      line-height: 28px;
    }
    .fields {
      width: 253px;
      margin-right: 20px;
      margin-bottom: 30px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .buttonsDiv {
      width: 1208px;
      height: 100px;
      padding: 18px 140px 20px 0px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
    }
    .entire-div {
      width: 1208px;
      margin: 0 auto;
    }
    .batchCariier-container {
      width: 1600px;
    }
    .scroll-bar {
      height: 85vh;
      padding-right: 12px;
    }
    .batch-drawer-table .scroll-bar {
      height: 80vh;
    }
    .editHeading {
      margin-top: 40px;
    }
    .mailingAdressHead {
      color: #191919;
      font-size: 16px;
      line-height: 24px;
      padding-left: 10px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .personalCountryField {
      width: 50%;
    }
    .bremoveBin {
      padding-top: 20px;
      margin-left: 10px;
      width: 35px;
    }
    .errorVal {
      font-size: 14px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileNav from '../../../components/MobileTabNav';
import TopBar from '../../../components/TopBar';
import { fetchCount } from '../../../api/admin';
import vector from '../../../assets/logo/dropdown.svg';
import logo from '../../../assets/logo/logo.svg';
import agency from '../../../assets/logo/AdminManage.svg';

const ManagePage = () => {
  const [agencyCount, setAgencyCount] = useState<Number>(0);
  const [agentCount, setAgentCount] = useState<Number>(0);

  useEffect(() => {
    fetchCount((agencyNum: Number, agentNum: Number) => {
      setAgencyCount(agencyNum);
      setAgentCount(agentNum);
    });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className='content-div'>
        <TopBar>
          <div className='d-xl-none'>
            <img src={logo} alt='' className='logo' />
          </div>
          <h2 className='page-title d-none d-xl-block'>Manage</h2>
        </TopBar>
        <h2 className='page-title d-xl-none'>Manage</h2>

        <div
          onClick={() => navigate('/admin/agency')}
          className='manage-card d-flex justify-content-between align-items-center'
        >
          <div className='d-flex'>
            <div className='roundfig'>
              <img src={agency} alt='admin-icon' />
            </div>
            <div>
              <h6>Manage Agencies</h6>
              <div className='agency-info'>{`${agencyCount} Agencies | ${agentCount} Users`}</div>
            </div>
          </div>
          <div>
            <img src={vector} alt='vector' className='arrow' />
          </div>
        </div>
      </div>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </>
  );
};

export default ManagePage;

import styled from '@emotion/styled';

export const TableDiv = styled.table`
  width: 100%;
  .sort {
    cursor: pointer;
  }
  thead {
    tr {
      border-radius: 16px;
    }
    th {
      background: #f5f7fc;
      font-weight: 500;
      color: #262758;
      font-size: 14px;
      padding: 14px 16px;
      border-left: 1px solid #e6e9f0;
    }

    th:first-of-type {
      border-radius: 10px 0 0 0;
      border-left: none;
    }

    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }

  tbody {
    background: #fff;
  }

  .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }

  .agencyNameHead {
    width: 24%;
  }
  .contactHead {
    width: 20%;
  }
  .contactTypeHead {
    width: 17%;
  }
  .accountsNameHead {
    width: 14%;
  }
  .accountTypeHead {
    width: 11.6%;
  }
  .accountActionHead {
    width: 16%;
  }
  .accountdateFieldHead {
    width: 12%;
  }
  .leadsNameHead {
    width: 14.4%;
  }
  .policyCarrierHead {
    width: 14.6%;
  }
  .policyTypeHead {
    width: 11%;
  }
  .statusContainerHead {
    width: 13.4%;
  }
  .dateFieldHead {
    width: 14%;
  }
  .userGroupFieldHead {
    width: 20%;
  }
  .actionFieldHead {
    width: 25%;
  }
  .roleFieldHead {
    width: 17%;
  }
  .contactFieldHead {
    width: 16%;
  }
  .leadsEmailFieldHead {
    width: 15%;
  }
  .leadsPhoneFieldHead {
    width: 13%;
  }
  .opportunityFieldHead {
    width: 18%;
  }
  .leadActionsFieldHead {
    width: 15%;
  }
  .documentSFieldHEad {
    width: 10%;
  }
  .customFieldTypeHead {
    width: 21.5%;
  }
  .customFieldNameHead {
    width: 25.7%;
  }
  .carrierNameHead {
    width: 33.6%;
  }
  .carrierStateHead {
    width: 16.7;
  }
  .customCarrierNameHead {
    width: 26.7%;
  }
  .customCarrierLinkHead {
    width: 13.2%;
  }
  .locationNameHead {
    width: 23.6%;
  }
  .customFieldActionsFieldHead {
    width: 10.8%;
  }
  .locationAddressHead {
    width: 20.4%;
  }
  .locationActionsHead {
    width: 8.7%;
  }
  .userNameHead {
    width: 16.6%;
  }
  .userActionNameHead {
    width: 17%;
  }
  @media screen and (min-width: 1200px) {
    .agencyNameHead {
      width: 20%;
    }
    .teamNameHead {
      width: 12%;
    }
    .teamActionsFieldHead {
      width: 10%;
    }
    .oppNameHead {
      width: 16.6%;
    }
    .oppAccountNameHead {
      width: 13.2%;
    }
    .oppActionsFieldHead {
      width: 18%;
    }
    .userGroupFieldHead {
      width: 20%;
    }
    .usersFieldHead {
      width: 15%;
    }
    .actionFieldHead {
      width: 20%;
    }
    .roleFieldHead {
      width: 14%;
    }
    .emailFieldHead {
      width: 17%;
    }
    .phoneFieldHead {
      width: 16%;
    }
    .producerFieldHead {
      width: 9.5%;
    }
    .documentSFieldHEad {
      width: 10%;
    }
  }

  @media screen and (min-width: 1900px) {
    thead {
      th {
        font-size: 14px;
        padding: 16px;
      }
    }
  }

  @media screen and (min-width: 2800px) {
    thead {
      th {
        font-size: 14px;
        padding: 32px;
      }
    }

    .form-check-input {
      width: 40px;
      height: 40px;
    }

    .sort {
      width: 40px;
    }

    .filter-icon {
      width: 21px;
    }
  }
`;

import axios from './axios';
import { IPolicy } from '../interfaces/Policy';

const fetchPolicies = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  producerUUID?: string,
  productCategoryUUID?: string[],
  hasMGA?: boolean,
  carrierUUID?: string,
  accountUUID?: string,
  customCarrierUUID?: string,
  productUUID?: any,
  customProductUUID?: any,
  successCB?: Function
) => {
  return axios
    .get('/policy', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        limit,
        offset,
        producerUUID,
        productCategoryUUID,
        hasMGA,
        carrierUUID,
        accountUUID,
        customCarrierUUID,
        productUUID,
        customProductUUID,
      },
    })
    .then((res) => {
      successCB && successCB(res.data);
      return res;
    });
};

const fetchPolicyDetails = (uuid?: string) => {
  return axios.get(`/policy/${uuid}`).then(
    (res) => res,
    (error) => error.response
  );
};

const addPolicy = (data: any, successCB: Function, errorCB: Function) => {
  return axios
    .post('/policy', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB('Please try again.');
        return error.res;
      }
    );
};

const updatePolicyDetails = (
  data: IPolicy,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/policy/${uuid}`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB('Please try again.');
      return error.res;
    }
  );
};

const getIssuer = (
  searchBy: string,
  carrierUUID: string,
  successCB: Function
) => {
  return axios
    .get('/issuer', { params: { searchBy, carrierUUID } })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const updatePolicyConatcts = (data: IPolicy[], uuid: string) => {
  return axios.patch(`/policy/${uuid}/contact`, data).then(
    (res) => res,
    (error) => error.response
  );
};

const fetchMGA = (searchBy: string, successCB: Function) => {
  return axios.get('/mga', { params: { searchBy } }).then((res) => {
    successCB(res.data);
    return res;
  });
};
const policyDetailsCount = (policyUUID: string) => {
  return axios
    .get(`/user/mybook/count`, { params: { policyUUID } })
    .then((res) => {
      return res;
    });
};

const deletePolicy = (uuid: String) => {
  return axios.delete(`/policy/${uuid}`);
};

const policyCategory = (agencyUUID: string) => {
  return axios
    .get(`/policyCategory`, { params: { agencyUUID } })
    .then((res) => {
      return res;
    });
};
export {
  fetchPolicies,
  addPolicy,
  getIssuer,
  fetchMGA,
  fetchPolicyDetails,
  updatePolicyDetails,
  policyDetailsCount,
  updatePolicyConatcts,
  deletePolicy,
  policyCategory,
};

import { FieldDiv } from './styles';

export interface IProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  register: Function;
  autoComplete?: boolean;
  prefix?: string;
  min?: number;
  month?: string;
  required?: boolean;
}

const InputField = ({
  name,
  type,
  placeholder,
  className,
  disabled,
  register,
  min,
  prefix,
  month,
  required,
}: IProps) => {
  return (
    <FieldDiv>
      <div
        className={`d-flex flex-column w-100 ${className} input-container ${
          prefix && 'input-style'
        }`}
      >
        {prefix && <span className='prefix-style'>{prefix}</span>}
        {month && <span className='monthStyle'>{month}</span>}
        <input
          id={name}
          name={name}
          className={`input-field ${type === 'email' ? 'text-lowercase' : ''}`}
          disabled={disabled}
          type={type}
          min={min}
          autoComplete='off'
          prefix={prefix}
          month='months'
          {...register(name, {
            setValueAs: (value: string | number) =>
              type === 'email' && typeof value === 'string'
                ? value.toLowerCase()
                : value,
          })}
        />
        <label htmlFor={name} className='input-placeholder'>
          {placeholder}
          {required ? <span className='requiredStar'>*</span> : null}
        </label>
      </div>
    </FieldDiv>
  );
};

export default InputField;

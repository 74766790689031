import { useEffect } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { useSelector } from 'react-redux';
import {
  getCustomFieldsType,
  selectAllCustomFieldsType,
} from '../../../../store/customFields/customFieldsSlice';

const CustomFieldsFilter = ({
  show,
  closeModal,
  appear,
  setAppear,
  fieldType,
  setType,
}: {
  show: string;
  closeModal: Function;
  appear: string;
  setAppear: Function;
  fieldType: any;
  setType: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const dispatch = useDispatch<AppDispatch>();

  const customFieldsType: any = useSelector(selectAllCustomFieldsType);

  const appearInForm = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  useEffect(() => {
    dispatch(getCustomFieldsType()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Type</h6>
        </div>
        <div className='agency-fields'>
          <FilterSelectField
            options={customFieldsType.map((item: any) => {
              return {
                label:
                  item.name?.charAt(0).toUpperCase() +
                  item.name?.split('_').join(' ').toLowerCase().slice(1),
                value: item.uuid,
              };
            })}
            placeholder='Select'
            onChange={(value: any) => {
              setType(value);
            }}
            isMulti={true}
            value={fieldType}
          />
        </div>
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Appear in form</h6>
        </div>
        <div className='agency-fields'>
          <FilterSelectField
            options={appearInForm}
            placeholder='Select'
            onChange={(value: any) => {
              setAppear(value);
            }}
            value={appear}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {fieldType.length + (appear ? 1 : 0)} filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setType([]);
                          setAppear('');
                        }}
                        disabled={fieldType.length + (appear ? 1 : 0) === 0}
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {fieldType.length + (appear ? 1 : 0)} filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setType([]);
                      setAppear('');
                    }}
                    disabled={fieldType.length + (appear ? 1 : 0) === 0}
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default CustomFieldsFilter;

import React from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { CardMobileDiv } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import { IAgency } from '../../../../interfaces/Agency';

function AgencyMobileCard({ agency }: { agency: IAgency }) {
  const navigate = useNavigate();
  return (
    <CardMobileDiv>
      <div className=' d-flex justify-content-between align-items-center'>
        <div>
          <h3 className='card-title'>{agency.name}</h3>
        </div>
        <div
          className={`status status${
            agency.AgencyStatus
              ? agency.AgencyStatus.name.charAt(0) +
                agency.AgencyStatus.name.slice(1).toLowerCase()
              : 'Invited'
          }`}
        >
          {agency.AgencyStatus
            ? agency.AgencyStatus.name.charAt(0) +
              agency.AgencyStatus.name.slice(1).toLowerCase()
            : 'Invited'}
        </div>
      </div>
      <div className='agency-details'>
        <b>{agency.AgencySubGroups?.AgencyGroup?.name}</b> /{' '}
        {agency.AgencySubGroups?.name}
        <br />
        Created on{' '}
        <b>
          <Moment format='MM/DD/YYYY'>{agency.createdAt}</Moment>
        </b>
      </div>
      <div className='d-flex justify-content-between'>
        <div
          className='actionButton'
          onClick={(e) => navigate(`/admin/agency/${agency.uuid}`)}
        >
          View Users
        </div>
        <div className='dropdownvector'>
          <img src={vector} alt='vector' className='vecctor' />
        </div>
      </div>
    </CardMobileDiv>
  );
}

export default AgencyMobileCard;

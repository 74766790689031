import styled from '@emotion/styled';

export const MobileFilterNavDiv = styled.div`
  .field-section {
    padding-bottom: 20px;
    height: 70vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .nav-logo {
    width: 26px;
    height: 26px;
    margin: 0 auto;
  }
  .avatar-img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .agency-fields {
    width: 100%;
    margin: 16px 0;
    color: #262758;
  }
  .agency-fields::placeholder {
    color: #262758;
  }
  .row {
    border: 1px solid #f5f5f5;
  }
  hr.filter-hr {
    border-bottom: 1px solid #f5f5f5;
    margin: 24px 0;
    opacity: 1;
    background: none;
  }

  .quickFormsDropDown {
    position: fixed;
    bottom: 60px;
    transform: translate(0);
    transition: all 0.5s ease-in-out;
    left: 0;
    animation-name: formDrawer;
    animation-duration: 0.4s;
    width: 100vw;
    height: 475px;
    padding: 32px;
    background: #fff;
    border-radius: 16px 16px 0px 0px;
    backdrop-filter: blur(10px);
    padding-right: 17px;
    @keyframes formDrawer {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0%);
      }
    }
    z-index: 5;

    h2 {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 20px;
      color: #262758;
    }

    .closed {
      animation-duration: 0.3s;
      transform: translateY(0%);
    }
  }

  .scroll-filter-mob {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-right: 15px;
  }

  button {
    border: none;
  }

  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    color: #cc0000;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding-left: 20px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    width: 100%;
    background: #fff;
    z-index: 2;
    padding: 20px;
    padding-top: 32px;
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
  }

  .head {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #262758;
    margin-top: 10px;
  }

  .drawerHandle {
    width: 88px;
    border: 4px solid #e6e9f0;
    margin: 0 auto;
  }
  .backgroundQuickAdd {
    left: 0;
    right: 0;
    bottom: 60px;
    top: 0;
    position: fixed;
    background: rgba(35, 42, 64, 0.6);
    z-index: 1;
  }
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 8px;
    top: 14px;
    border: none;
  }

  .cross {
    width: 20px;
  }
`;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addEvent,
  fetchEventDetails,
  updateEvent,
  deleteEvent,
} from '../../api/events';
export type EventSliceState = {
  status: string;
  Events: any[];
  eventDetails?: any;
  isLoading: boolean;
};

const initialState: EventSliceState = {
  status: 'idle',
  Events: [],
  isLoading: false,
};

export const createEvent = createAsyncThunk(
  'event/createEvent',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addEvent(data, successCB, errorCB);
    return response.data;
  }
);
export const getEventDetails = createAsyncThunk(
  'event/getEventDetails',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchEventDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

export const deleteAnEvent = createAsyncThunk(
  'event/deleteAnEvent',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteEvent(uuid, successCB);
    response.uuid = uuid;
    return response;
  }
);

export const updateEventDetails = createAsyncThunk(
  'event/updateEventDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await updateEvent(uuid, data);
    successCB(response.data);
    return response.data;
  }
);

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.Events.unshift(action.payload);
      })
      .addCase(getEventDetails.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEventDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.Events = action.payload;
      })
      .addCase(getEventDetails.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateEventDetails.fulfilled, (state, action) => {
        const index = state.Events.findIndex(
          (item: any) => item.uuid === action.payload.uuid
        );
        state.Events[index] = action.payload;
        state.eventDetails = action.payload;
      })
      .addCase(deleteAnEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.uuid) {
          const index = state.Events.findIndex(
            (item: any) => item?.data.uuid === action.payload.uuid
          );
          state.Events.splice(index, 1);
        }
      });
  },
});
export const { updateStatus } = eventSlice.actions;

export default eventSlice.reducer;

export const selectAllActivities = (state: { Events: EventSliceState }) => {
  return state.Events?.Events;
};

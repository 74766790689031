import Modal from '../../../../components/Modal';
import { StyledModal } from '../../elements/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from '../../../../components/InputField';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import { useState } from 'react';
import SelectField from '../../../../components/SelectField';
import { useSelector } from 'react-redux';
import {
  createCustomFields,
  selectAllCustomFieldsType,
} from '../../../../store/customFields/customFieldsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import blackplus from '../../../../assets/logo/blackplus.svg';
import { FieldDiv } from '../../../../components/InputField/styles';
import { PickerDiv } from '../../../../components/DateField/styles';
import calender from '../../../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import { YearMonthPicker } from '../../../../components/DateField';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Field Name is required')
    .max(20, 'The Field name cannot exceed 20 characters'),
  fieldType: Yup.object().shape({
    value: Yup.string().required('Please select a Field type'),
  }),
});
const AddCustomField = ({
  loadingStatus,
  addCustomField,
  closeModal,
  resourceName,
}: {
  loadingStatus: string;
  addCustomField: string;
  closeModal: Function;
  resourceName: String;
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const watchAllFields = watch();
  const dispatch = useDispatch<AppDispatch>();

  const [addToForm, setAddToForm] = useState(false);

  const [options, setOptions] = useState<any>([{}]);

  const [defaultOption, setDefaultOption] = useState<any>('');
  const { uuid } = useParams();
  const [showSuccess, setshowSuccess] = useState(false);
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [canSubmit, setCanSubmit] = useState(true);

  const onSubmit = (data: any) => {
    setCanSubmit(true);
    if (
      (customFieldsType.find((item: any) => item.uuid === data.fieldType.value)
        .name === 'MULTIPLE_CHOICE' ||
        customFieldsType.find((item: any) => item.uuid === data.fieldType.value)
          .name === 'SINGLE_CHOICE') &&
      options
        ?.filter((value: any) => Object.keys(value).length !== 0)
        ?.filter((el: any) => Object.keys(el?.value).length !== 0).length === 0
    ) {
      setCanSubmit(false);
    } else {
      data.resourceName = resourceName;
      data.typeUUID = data.fieldType.value;
      data.addToForm = addToForm;
      data.options = options
        ?.filter((value: any) => Object.keys(value).length !== 0)
        ?.filter((el: any) => Object.keys(el?.value).length !== 0);
      data.defaultValue = defaultOption;
      data.agencyUUID = uuid || '';
      dispatch(
        createCustomFields({
          data,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
        })
      );
    }
  };
  const customFieldsType: any = useSelector(selectAllCustomFieldsType);

  const addsNewOption = () => {
    options ? setOptions([...options, {}]) : setOptions([{}]);
  };

  const onItem = (index: number, value: any, isDefault?: boolean) => {
    let updatedOption = [...options];
    updatedOption[index].order = index;
    if (isDefault) {
      if (watchAllFields.fieldType.label.trim() === 'multiple choice') {
        updatedOption[index].default = value;
      } else {
        setDefaultOption(value);
      }
    } else if (!isDefault) {
      updatedOption[index].value = value;
    }
    if (updatedOption.hasOwnProperty('value')) {
      setOptions(updatedOption);
    }
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding custom field'}
            message={`<b>${getValues(
              'name'
            )}</b> has been added as custom field<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={addCustomField} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2 className='add-customField-heading'>Add a custom field</h2>
          </div>
          <div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='field-section'>
                  <div className='agency-fields'>
                    <InputField
                      name='name'
                      type='text'
                      placeholder='Field Name'
                      register={register}
                      className={`${errors.name && 'error-field'} ${
                        watchAllFields.name && 'filled'
                      }`}
                    />
                    {errors.name ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.name?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    <SelectField
                      options={customFieldsType.map((item: any) => {
                        return {
                          label: ` ${
                            item.name
                              ? item.name.split('_').join(' ').toLowerCase()
                              : ''
                          }`,
                          value: item.uuid,
                        };
                      })}
                      name='fieldType'
                      placeholder='Field Type'
                      className={`fieldType ${
                        errors.fieldType && 'error-field'
                      } ${watchAllFields.fieldType && 'filled'}`}
                      control={control}
                    />
                    {errors.fieldType ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.fieldType?.message
                          ? 'Please select a pipeline stage'
                          : errors.fieldType?.value?.message}
                      </div>
                    ) : null}
                  </div>
                  {(watchAllFields.fieldType?.label.trim() ===
                    'single choice' ||
                    watchAllFields.fieldType?.label.trim() ===
                      'multiple choice') && (
                    <>
                      <h5 className='options-subhead'>
                        Provide possible values to this field
                      </h5>
                      <div className='agency-fields'>
                        {options?.map((item: any, index: any) => (
                          <div>
                            <label className='radio-container'>
                              {watchAllFields.fieldType?.label.trim() ===
                              'multiple choice' ? (
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  name='options'
                                  value='default'
                                  onChange={(e) =>
                                    onItem(index, e.target.checked, true)
                                  }
                                />
                              ) : (
                                <input
                                  type='radio'
                                  className='form-check-input'
                                  name='options'
                                  value={index}
                                  onChange={(e) =>
                                    onItem(index, e.target.value, true)
                                  }
                                />
                              )}
                              <FieldDiv className='options-field'>
                                <div
                                  className={` w-100 ${
                                    item.value ? 'filled' : ''
                                  } input-container`}
                                >
                                  <input
                                    id={`option_${index}`}
                                    onChange={(e) =>
                                      onItem(index, e.target.value)
                                    }
                                    className='input-field'
                                    value={item.value}
                                    type='text'
                                    autoComplete='off'
                                    placeholder='Option'
                                  />
                                </div>
                              </FieldDiv>
                            </label>
                          </div>
                        ))}
                        {!canSubmit && (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />{' '}
                            Please add an option
                          </div>
                        )}
                      </div>

                      <button
                        className='align-items-center secondaryButton addOptionsButton'
                        type='button'
                        onClick={() => addsNewOption()}
                      >
                        <img
                          className='addAnotherBusiness'
                          src={blackplus}
                          alt='add'
                        />
                        Add another option
                      </button>
                    </>
                  )}
                  {watchAllFields.fieldType?.label.trim() === 'datepicker' ? (
                    <div>
                      <PickerDiv className='filled-picker'>
                        <div
                          className={`d-flex flex-column w-100 input-container filled`}
                        >
                          <DatePicker
                            className='date-input'
                            onChangeRaw={(e) => e.preventDefault()}
                            onChange={(date) => setDefaultOption(date)}
                            placeholderText='Provide default if any...'
                            dateFormat={'MM/dd/yyyy'}
                            selected={defaultOption}
                            name='date'
                            renderCustomHeader={({
                              date,
                              prevYearButtonDisabled,
                              nextYearButtonDisabled,
                              decreaseYear,
                              increaseYear,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <YearMonthPicker
                                date={date}
                                prevMonthButtonDisabled={
                                  prevMonthButtonDisabled
                                }
                                prevYearButtonDisabled={prevYearButtonDisabled}
                                nextMonthButtonDisabled={
                                  nextMonthButtonDisabled
                                }
                                nextYearButtonDisabled={nextYearButtonDisabled}
                                decreaseMonth={decreaseMonth}
                                decreaseYear={decreaseYear}
                                increaseMonth={increaseMonth}
                                increaseYear={increaseYear}
                              />
                            )}
                          />
                          <label htmlFor='date'>Estimated date</label>

                          <div className='CallenderImg'>
                            <img src={calender} alt='Calender' />
                          </div>
                        </div>
                      </PickerDiv>
                    </div>
                  ) : null}
                  <hr className='add-customField-hr' />

                  <div className='prior d-flex form-appear-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      name='addToForm'
                      onClick={() => setAddToForm(!addToForm)}
                    />
                    <label className='form-check-label'> Appear in form</label>
                  </div>
                </div>
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='agentButton'
                    disabled={
                      loadingStatus === 'loading' ||
                      addCustomField === 'closing'
                    }
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};
export default AddCustomField;

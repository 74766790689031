import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import AddLeadDrawer from '../../../components/AddLeadDrawer';
import LeadDetailModal from './components/LeadDetailModal';
import { AppDispatch } from '../../../store';
import {
  getLeads,
  LeadsSliceState,
  selectAllLeads,
  onQualifyLead,
  onJunkLead,
  onDeleteLead,
} from '../../../store/leads/leadsSlice';
import { getCount, updateCount } from '../../../store/book/bookSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import { FilterContainer, MyBookDiv } from '../elements/styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import Link from '../../../components/Link';
import { StyledTab } from '../../../styles/StyledTab';
import LeadMobileCard from './components/LeadMobileCard';
import EditLeadDrawer from '../../../components/EditLeadDrawer';
import SuccessCard from '../../../components/SuccessCard';
import { ILead } from '../../../interfaces/Lead';
import { selectCount } from '../../../store/book/bookSlice';
import MobileTab from '../../../components/MobileTab';
import LeadJunkModal from '../../../components/LeadJunkModal';
import QualifyLeadsModal from '../../../components/QualifyLeadsModal';
import Table from '../../../components/Table';
import plus from '../../../assets/logo/plus.svg';
import search from '../../../assets/logo/search.svg';
import filter from '../../../assets/logo/filter.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import close from '../../../assets/logo/cross.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import EmptyResults from '../components/Empty';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import phone from '../../../assets/logo/phone.svg';
import message from '../../../assets/logo/message-action.svg';
import mail from '../../../assets/logo/mail.svg';
import junk from '../../../assets/logo/junk.svg';
import PaginationComponent from '../../../components/PaginationComponent';
import LeadsFilter from './components/FilterDrawer';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import blackplus from '../../../assets/logo/blackplus.svg';

function getWidth(width: number) {
  if (window.innerWidth < 1900) return width;
  return (width * 70) / 56;
}

const Leads = () => {
  const tabRef = useRef(null);
  const searchRef = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const leads = useSelector(selectAllLeads);
  const count = useSelector(selectCount);
  const uuid = localStorage.getItem('leadUUID');
  const loadingStatus = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.status
  );
  const leadsCount = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.count
  );

  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.uuid || uuid) {
      setUUID(location?.state?.uuid || uuid);
      setShowDetailModal('open');
      localStorage.removeItem('leadUUID');
      navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showLead, setAddLead] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDetailModal, setShowDetailModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [emailCopied, setEmailCopied] = useState(false);
  const [qulifyMessage, setQulifyMessage] = useState('');
  const [showQualifyLead, setShowQualifyLead] = useState(false);
  const [showSuccedMessageOnQualify, setShowSuccedMessageOnQualify] =
    useState(false);
  const [loading, setLoading] = useState('idle');
  const [showJunkLead, setShowJunkLead] = useState(false);
  const [showJunkLeadSuccess, setShowJunkLeadSuccess] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [producer, setProducer] = useState('');
  const [accountType, setAccountType] = useState<any>([]);
  const [producerSearch, setProducerSearch] = useState('');
  const [sourceType, setSourceType] = useState([]);
  const [sourceStage, setSourceStage] = useState([]);
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getLeads({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          leadStageUUID: sourceStage.map((item: any) => item.value),
          producerUUID: producer,
          leadSourceUUID: sourceType.map((item: any) => item.value),
          accountType: accountType?.value,
          successCB: (data: any) => {
            dispatch(updateCount({ type: 'leadsCount', count: data.count }));
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    sortBy,
    orderBy,
    debouncedSearchTerm,
    limit,
    page,
    accountType,
    producer,
    sourceStage,
    sourceType,
  ]);

  useOutsideClick(tabRef, setShowDropdown, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const handleCopy = (e: any, value: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    setEmailCopied(true);
    setTimeout(() => {
      setEmailCopied(false);
    }, 2000);
  };

  const qualifyFunction = (uuid: string) => {
    // if (loading === 'idle' || loading === 'succeeded') {
    setLoading('loading');
    dispatch(
      onQualifyLead({
        uuid,
        successCB: (data: any) => {
          dispatch(getCount());
          setShowQualifyLead(false);
          setLoading('succeeded');
          setShowSuccedMessageOnQualify(true);
          setTimeout(() => {
            setShowSuccedMessageOnQualify(false);
          }, 2000);
        },
        errorCB: (err: any) => {
          setShowEditModal('open');
          setLoading('succeeded');
          setQulifyMessage(err);
          setShowQualifyLead(false);
        },
      })
    );
    // }
  };
  const junkFunction = (uuid: string, data: { junkReason: string }) => {
    if (loading === 'idle' || loading === 'succeeded') {
      setLoading('loading');
      dispatch(
        onJunkLead({
          uuid,
          data,
          successCB: () => {
            setShowJunkLead(false);
            setShowJunkLeadSuccess(true);
            dispatch(getCount());
            setLoading('succeeded');
            setTimeout(() => {
              setShowJunkLeadSuccess(false);
            }, 2000);
          },
          errorCB: (err: any) => {
            setLoading('succeeded');
            setQulifyMessage(err);
            setShowQualifyLead(false);
          },
        })
      );
    }
  };

  const [showFilter, setShowFilter] = useState('closed');

  const filterLeads = (
    leadStageUUID: any,
    producerUUID: string,
    leadSourceUUID: any,
    accountType: string
  ) => {
    // dispatch(
    //   getLeads({
    //     sortBy,
    //     orderBy,
    //     searchBy: debouncedSearchTerm,
    //     limit,
    //     offset: page * limit,
    //     leadStageUUID,
    //     producerUUID,
    //     leadSourceUUID,
    //     accountType,
    //     successCB: (data: any) => {
    //       dispatch(updateCount({ type: 'leadsCount', count: data.count }));
    //     },
    //   })
    // );
  };

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteLead({
        uuid,
        successCB: () => {
          if (leads.length === 1) {
            setPage(0);
          }
          dispatch(getCount());
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <MyBookDiv>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting lead.'}
        />
      )}
      <button
        onClick={() => setAddLead('open')}
        className='addButton d-sm-none'
      >
        <img src={plus} alt='add' />
      </button>
      <div className='d-sm-none mb-4'>
        <StyledTab ref={tabRef}>
          <Link
            to='/mybook/leads'
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            Leads<span className='count'>{count.leads}</span>
            <img src={dropdown} alt='' />
          </Link>{' '}
          {showDropdown && <MobileTab />}
        </StyledTab>
      </div>

      <div className='d-flex justify-content-between'>
        <button
          className='addButton d-none d-sm-block'
          onClick={() => setAddLead('open')}
          disabled={showLead !== 'closed'}
        >
          Add lead
        </button>
        <div className='book-filter d-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setPage(0);
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {(accountType?.value ? 1 : 0) +
              sourceStage.length +
              sourceType.length +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(accountType?.value ? 1 : 0) +
                  sourceStage.length +
                  sourceType.length +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {(accountType?.value ? 1 : 0) +
              sourceStage.length +
              sourceType.length +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(accountType?.value ? 1 : 0) +
                  sourceStage.length +
                  sourceType.length +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && leads.length > 0 ? (
            <>
              {leads.map((item: ILead) => (
                <LeadMobileCard
                  lead={item}
                  key={item.uuid}
                  setShowDetailModal={setShowDetailModal}
                  setUUID={setUUID}
                  setShowDeleteModal={setShowDeleteModal}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={leadsCount}
              />
            </>
          ) : (
            <EmptyResults name='lead' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            setScroll={() => {
              setScroll(!scroll);
            }}
            count={leadsCount}
            loadingStatus={loadingStatus}
            className='mybook-table'
            columns={[
              {
                accessor: 'firstName',
                resizable: true,
                sortable: true,
                minWidth: 160,
                Header: () => (
                  <div
                    onClick={() => handleSort('firstName')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Lead name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'firstName'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center text-overflow-container'>
                    <b
                      onClick={() => {
                        setShowDetailModal('open');
                        setUUID(row.original.uuid);
                      }}
                      className='overflowed-text'
                    >
                      {row.original.data.contact.firstName
                        ? row.original.data.contact.firstName +
                          ' ' +
                          row.original.data.contact.lastName
                        : row.original.data.contact.lastName}
                    </b>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.data.contact.firstName
                          ? row.original.data.contact.firstName +
                            ' ' +
                            row.original.data.contact.lastName
                          : '-'}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'status',
                Header: 'Status',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>{row.original.LeadStage?.name}</>
                ),
              },
              {
                accessor: 'type',
                Header: 'Type',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.data.accountInfo.accountType?.charAt(0) +
                      row.original.data.accountInfo.accountType
                        ?.slice(1)
                        .toLowerCase()}
                  </>
                ),
              },
              {
                accessor: 'createdAt',
                resizable: true,
                sortable: true,
                minWidth: 156,
                Header: () => (
                  <div
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Created date</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'createdAt'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <>
                    <Moment format='MM/DD/YYYY'>
                      {row.original.createdAt}
                    </Moment>
                  </>
                ),
              },
              {
                accessor: 'email',
                Header: 'Email',
                minWidth: 150,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div
                    className='row-name d-flex align-items-center text-overflow-container'
                    onClick={(event) => {
                      handleCopy(event, `${row.original.data.contact.email}`);
                    }}
                  >
                    <b className='overflowed-text'>
                      {row.original.data.contact.email}
                    </b>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.data.contact.email}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'phone',
                Header: 'Phone number',
                resizable: true,
                minWidth: 134,
                Cell: ({ row }: { row: any }) => (
                  <div
                    style={{ cursor: 'pointer' }}
                    className='phone-div row-name'
                  >
                    <b>{row.original.data.contact.phoneNumber}</b>
                    <div className='contact-tooltip'>
                      <div className='action-item'>
                        <img src={phone} alt='phone' />
                      </div>
                      <div className='action-item'>
                        <img src={mail} alt='mail' />
                      </div>
                      <div className='action-item'>
                        <img src={message} alt='message' />
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                width: getWidth(206),
                minWidth: getWidth(206),
                maxWidth: getWidth(206),
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <div
                      className='actionButton'
                      onClick={() => {
                        setShowQualifyLead(true);
                        setUUID(row.original.uuid);
                      }}
                    >
                      Qualify
                    </div>
                    <ActionsDropdown
                      uuid={row.original.uuid}
                      setUUID={setUUID}
                      setShowEditModal={setShowEditModal}
                      setJunkLead={setShowJunkLead}
                      setShowDeleteModal={setShowDeleteModal}
                      setName={setName}
                      scroll={scroll}
                      name={
                        row.original.data.contact.name
                          ? row.original.data.contact.name
                          : '-'
                      }
                      setShowDetailModal={setShowDetailModal}
                    />
                  </div>
                ),
              },
            ]}
            data={leads}
            name='lead'
          />
        </div>
      </div>
      {showQualifyLead ? (
        <QualifyLeadsModal
          open={showQualifyLead}
          closeModal={setShowQualifyLead}
          qualifyFunction={qualifyFunction}
          uuid={UUID}
          loading={loading}
        />
      ) : null}
      {showLead !== 'closed' && (
        <AddLeadDrawer show={showLead} closeModal={setAddLead} />
      )}
      {showDetailModal !== 'closed' && (
        <LeadDetailModal
          show={showDetailModal}
          closeModal={setShowDetailModal}
          UUID={UUID}
        />
      )}
      {showEditModal !== 'closed' && (
        <EditLeadDrawer
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={UUID}
          qualifyMessage={qulifyMessage}
          setQulifyMessage={setQulifyMessage}
        />
      )}
      {showJunkLead ? (
        <LeadJunkModal
          open={showJunkLead}
          closeModal={setShowJunkLead}
          junkFunction={junkFunction}
          uuid={UUID}
          loading={loadingStatus}
        />
      ) : null}
      {(emailCopied || showSuccedMessageOnQualify || showJunkLeadSuccess) && (
        <>
          <SuccessAlign>
            <div
              className={`success-container ${
                (emailCopied ||
                  showSuccedMessageOnQualify ||
                  showJunkLeadSuccess) &&
                'open-drawer'
              } `}
            >
              <SuccessCard
                title={
                  emailCopied
                    ? 'Success Copying Email'
                    : showSuccedMessageOnQualify
                    ? 'Lead qualified'
                    : showJunkLeadSuccess
                    ? 'Lead junked'
                    : ''
                }
                message={
                  emailCopied
                    ? `Email has been added to clipboard`
                    : showSuccedMessageOnQualify
                    ? 'Lead has been Qualified'
                    : showJunkLeadSuccess
                    ? 'Lead has been junked'
                    : ''
                }
              />
              <button
                onClick={() => {
                  setEmailCopied(false);
                  setShowSuccedMessageOnQualify(false);
                }}
                className='crossbutton'
              >
                <img src={close} className='cross' alt='cross-icon' />
              </button>
            </div>
          </SuccessAlign>
        </>
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='lead'
          name={name}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <LeadsFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterLeads}
            sourceType={sourceType}
            setSourceType={setSourceType}
            sourceStage={sourceStage}
            setSourceStage={setSourceStage}
            producer={producer}
            setProducer={setProducer}
            accountType={accountType}
            setAccountType={setAccountType}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Leads;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setJunkLead,
  setUUID,
  setShowDeleteModal,
  setName,
  scroll,
  name,
  setShowDetailModal,
}: {
  uuid: string;
  setShowEditModal: Function;
  setJunkLead: Function;
  setUUID: Function;
  setShowDeleteModal: Function;
  setName: Function;
  scroll: boolean;
  name: string;
  setShowDetailModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);

        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='action-dropContainer position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 170,
          }}
        >
          <div className='drop-div'>
            <div
              className='editdiv'
              onClick={() => {
                setShowEditModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
              }}
              tabIndex={0}
            >
              Edit <img src={edit} alt='bin' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='editdiv'
              style={{ paddingTop: '10px' }}
              onClick={() => setShowDetailModal('open')}
              tabIndex={1}
            >
              Add activity <img src={blackplus} alt='plusIcon' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='junkdiv '
              onClick={() => {
                setJunkLead(true);
                setUUID(uuid);
                setShowActionsDropdown(false);
              }}
              tabIndex={2}
            >
              Mark as Junked <img src={junk} alt='junk-icon' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='deletediv'
              onClick={() => {
                setShowDeleteModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
                setName(name);
              }}
              tabIndex={3}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

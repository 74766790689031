import React from 'react';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';

const { ValueContainer, Placeholder, Option } = components;

export const MultiOption = (props: any) => {
  return (
    <Option {...props} className='d-flex align-items-center'>
      <input
        type='checkbox'
        className='form-check-input me-2'
        style={{ border: props.isSelected && 'none' }}
        checked={props.isSelected}
        onChange={() => null}
      />{' '}
      <label>{props.label}</label>
    </Option>
  );
};

// @ts-ignore
export const CustomValueContainer = ({ children, ...props }) => {
  return (
    // @ts-ignore
    <ValueContainer {...props}>
      {/*
      // @ts-ignore */}
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

// @ts-ignore
export const CustomMultiValue = ({ ...props }) => {
  const { getValue, data } = props;

  const selectedOptions = getValue();
  const currentOptionIdx = selectedOptions.findIndex(
    (option: any) => option.value === data.value
  );

  return (
    <span style={{ fontWeight: 600 }}>
      {data.label}
      {currentOptionIdx === selectedOptions.length - 1 ? '' : ', '}
    </span>
  );
};

// @ts-ignore
export const CustomMultiValueContainer = ({ children, ...props }) => {
  return (
    // @ts-ignore
    <ValueContainer {...props} className='w-75'>
      {/*
      // @ts-ignore */}
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: '-4px 2px -8px',
        }}
      >
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </div>
    </ValueContainer>
  );
};

interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  options?: any[];
  isDisabled?: boolean;
  control: any;
  inlineField?: boolean;
  lastField?: boolean;
  disabled?: boolean;
  required?: boolean;
  ariaInvalid?: boolean;
}

export const customSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: state.isDisabled ? '#8990A3' : '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    margin: '4px 0',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
    borderRadius: '8px',
  }),
  menuList: (provided: object, state: any) => ({
    maxHeight: '220px',
    overflow: 'auto',
    paddingBottom: '14px',

    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#E6E9F0',
      borderRadius: '8px',
    },
    '@media only screen and (max-width: 575px)': {
      maxHeight: '148px',
      overflow: 'auto',
      paddingBottom: '14px',
    },
  }),

  menu: (provided: object, state: any) => ({
    ...provided,
    zIndex: '10',
    borderRadius: '8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isDesabled
      ? '1px solid #D1D6E5'
      : state.isSelected
      ? '#3c498a'
      : '#cad9f3',
    '@media only screen and (max-width: 575px)': {
      maxHeight: '154px',
    },
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
    marginRight: '3px',
  }),
  clearIndicator: () => ({
    marginRight: '-5px',
    padding: '2px',
    width: '30px',
    height: '30px',
    color: '#8990A3',
  }),

  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#F5F7FC' : '#FFF',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      border: state.selectProps?.['aria-invalid']
        ? '1px solid #cc0000'
        : '1px solid #cad9f3',
      boxShadow: state.selectProps?.['aria-invalid']
        ? 'none'
        : '0px 1px 4px rgba(35, 42, 64, 0.9)',
      borderRadius: '8px',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.selectProps?.['aria-invalid']
      ? '1px solid #cc0000'
      : state.isDisabled
      ? '1px solid #D1D6E5'
      : state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '8px',
    padding:
      ((!state.isFocused && state.hasValue) ||
        (state.isFocused && state.hasValue)) &&
      '8px',
    height: '48px',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '0',
      fontWeight: 600,
      '@media only screen and (min-width: 1900px)': {
        paddingTop: (state.hasValue || state.selectProps.inputValue) && '8px',
      },
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    paddingLeft: '2px',
    background: 'transparent',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '-10px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
    paddingLeft:
      state.hasValue || state.selectProps.inputValue ? '-2px' : '10px',
    textTransform: 'capitalize',
  }),
};

export const InlineSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    margin: '4px 0',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
    borderRadius: '8px 0px 0px 8px',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px 0px 0px 8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
  }),

  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#D1D6E5' : '#FFF',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      border: '1px solid #cad9f3',
      boxShadow: '0px 1px 4px rgba(35, 42, 64, 0.2)',
      borderRadius: '8px 0px 0px 8px',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '8px 0px 0px 8px',
    height: '48px',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '6px',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '-5px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
  }),
};

const SelectField = ({
  name,
  placeholder,
  options,
  control,
  disabled,
  inlineField,
  required,
  ariaInvalid,
  ...props
}: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          styles={!inlineField ? customSelectStyles : InlineSelectStyles}
          options={options}
          placeholder={
            required ? (
              <>
                {placeholder} {<span className='requiredStar'>*</span>}
              </>
            ) : (
              placeholder
            )
          }
          isDisabled={disabled}
          hideSelectedOptions={false}
          components={{
            ValueContainer: props.isMulti
              ? CustomMultiValueContainer
              : CustomValueContainer,
            MultiValue: CustomMultiValue,
            Option: props.isMulti ? MultiOption : Option,
          }}
          isClearable
          aria-invalid={ariaInvalid}
          closeMenuOnSelect={props.isMulti ? false : true}
          {...props}
          {...field}
        />
      )}
    />
  );
};

export default SelectField;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import LocalStorageService from '../../../../utils/LocalStorageService';
import SuccessCard from '../../../../components/SuccessCard';
import Modal from '../../../../components/Modal';
import { AppDispatch } from '../../../../store';
import {
  updateLocationDetails,
  LocationSliceState,
  getLocation,
  PrimaryLocations,
} from '../../../../store/location/locationSlice';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import { ILocation } from '../../../../interfaces/Location';
import ChangePrimaryLocationModal from './ChangePrimaryLocationModal';
import Skeleton from 'react-loading-skeleton';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
const urlRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const AgentSchema = Yup.object().shape(
  {
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Entered phone number is not valid.')
      .required('Please enter your phone number'),
    faxNumber: Yup.string()
      .nullable()
      .notRequired()
      .when('faxNumber', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(phoneRegExp, 'Entered phone Fax number is not valid.'),
      }),
    name: Yup.string().required('Please enter your LocationName '),
    websiteUrl: Yup.string()
      .nullable()
      .notRequired()
      .when('websiteUrl', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(urlRegex, 'Entered url is not valid.'),
      }),
  },
  [
    ['faxNumber', 'faxNumber'],
    ['websiteUrl', 'websiteUrl'],
  ]
);

const EditLocationModal = ({
  show,
  closeModal,
  uuid,
  agencyUUID,
  isThisprimaryLocation,
  setChangedValue,
  changedValue,
}: {
  show: string;
  closeModal: Function;
  uuid: string;
  agencyUUID?: string;
  isThisprimaryLocation: boolean;
  setChangedValue?: Function;
  changedValue?: { location: ILocation; primaryLocation: ILocation };
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [success, setSuccess] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState(false);
  const [primary, setPrimary] = useState(false);
  const [addressObject, setAddressObject] = useState<any>({});
  const [isManual, setIsManual] = useState(false);
  const [primaryLocationExist, isPrimaryLocationExist] = useState(false);
  const [changePrimaryLocation, setChangePrimaryLocation] = useState(false);
  const [primaryLocationData, setPrimaryLocationData] = useState<ILocation>();
  const [Submitdata, setSubmitdata] = useState<ILocation>();
  const [submit, isSubmit] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const user = LocalStorageService.getUser();
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgentSchema),
  });
  const loadingStatus = useSelector(
    (state: { locations: LocationSliceState }) => state.locations?.status
  );
  const watchAllFields = watch();
  useEffect(() => {
    if (user?.UserRole.name === 'ADMIN' && agencyUUID) {
      dispatch(
        getLocation({
          uuid,
          agencyUUID,
          successCB: (data: ILocation) => {
            setValue('name', data?.name);
            setValue('phoneNumber', data?.phoneNumber);
            setValue('faxNumber', data?.faxNumber);
            setValue('websiteUrl', data?.websiteUrl);
            if (data?.isPrimary) isPrimaryLocationExist(true);
            if (data?.isManuallyAdded) {
              setIsManual(true);
              setValue('address', data?.address);
              setValue('zipCode', data?.zipCode);
              setValue('city', data?.city);
              setValue('state', data?.state);
              setValue('country', data?.country);
              setValue('apartment', data?.street);
            } else {
              setAddressObject({
                address: data?.address,
                city: data?.city,
                state: data?.state,
                country: data?.country,
                zipCode: data?.zipCode,
              });
            }
            setAddress(data?.address + '');
          },
        })
      );
    } else {
      dispatch(
        getLocation({
          uuid,
          successCB: (data: ILocation) => {
            setValue('name', data?.name);
            setValue('phoneNumber', data?.phoneNumber);
            setValue('faxNumber', data?.faxNumber);
            setValue('websiteUrl', data?.websiteUrl);
            if (data?.isPrimary) {
              isPrimaryLocationExist(true);
            }
            if (data?.isManuallyAdded) {
              setIsManual(true);
              setValue('address', data?.address);
              setValue('zipCode', data?.zipCode);
              setValue('city', data?.city);
              setValue('state', data?.state);
              setValue('country', data?.country);
              setValue('apartment', data?.street);
            } else {
              setAddressObject({
                address: data?.address,
                city: data?.city,
                state: data?.state,
                country: data?.country,
                zipCode: data?.zipCode,
              });
            }
            setAddress(data?.address + '');
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.UserRole.name === 'ADMIN' && agencyUUID) {
      dispatch(
        PrimaryLocations({
          agencyUUID,
          successCB: (data: any) => {
            setPrimaryLocation(true);
            setPrimaryLocationData(data);
          },
        })
      );
    } else {
      dispatch(
        PrimaryLocations({
          successCB: (data: any) => {
            setPrimaryLocation(true);
            setPrimaryLocationData(data);
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyUUID]);
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const loading = useSelector(
    (state: { locations: LocationSliceState }) => state.locations.isLoading
  );

  const [locationExistError, setLocationExistError] = useState('');

  const onSubmit = (data: any) => {
    if (Object.keys(addressObject).length <= 0 && !isManual) {
      return isSubmit(true);
    } else {
      isSubmit(false);
    }
    if (primaryLocation && primary) {
      setSubmitdata(data);
      setPrimaryLocation(false);
      return setChangePrimaryLocation(true);
    }
    if (
      isManual &&
      (!watchAllFields.address ||
        !watchAllFields.city ||
        !watchAllFields.state ||
        !watchAllFields.country ||
        !watchAllFields.zipCode ||
        !watchAllFields.apartment)
    ) {
      return setAddressError('field cannot be empty');
    }
    data.agencyUUID = agencyUUID;
    data.isPrimary = primaryLocationExist ? true : primary;
    data.isManuallyAdded = isManual;
    if (!isManual) {
      data = Object.assign(data, addressObject);
    }
    dispatch(
      updateLocationDetails({
        data,
        uuid,
        successCB: (data: any) => {
          if (setChangedValue) setChangedValue(data);
          closeModal('closing');
          setTimeout(() => {
            setSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 2000);
        },
        errorCB: (error: any) => {
          if (error.data.error) {
            setLocationExistError(error.data.error);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (locationExistError) {
      setLocationExistError('');
    }
  }, [
    watchAllFields.name,
    watchAllFields.address,
    watchAllFields.city,
    watchAllFields.state,
    watchAllFields.country,
    watchAllFields.zipCode,
    addressObject,
  ]);
  return (
    <>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'Success editing location'}
            message={`<b>${getValues('name')}</b> has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div>
            <h2>Edit location</h2>
          </div>
          <div>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      {loadingStatus === 'loading' ? (
                        <Skeleton height={48} />
                      ) : (
                        <InputField
                          register={register}
                          name='name'
                          type='text'
                          required
                          placeholder='Location name'
                          className={`${
                            (errors.name || locationExistError) && 'error-field'
                          } ${watchAllFields.name && 'filled'}`}
                        />
                      )}
                      {errors.name ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.name?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      {loadingStatus === 'loading' ? (
                        <Skeleton height={48} />
                      ) : (
                        <>
                          <input
                            type='checkbox'
                            name='isPrimary'
                            disabled={isThisprimaryLocation}
                            defaultChecked={isThisprimaryLocation}
                            className='form-check-input'
                            onClick={() => setPrimary(!primary)}
                          />
                          <span
                            className={
                              primaryLocationExist
                                ? 'disabledCheckboxText'
                                : 'CheckboxText'
                            }
                          >
                            Make this location primary
                          </span>
                        </>
                      )}
                    </div>
                    <div className='agency-fields'>
                      {loadingStatus === 'loading' ? (
                        <Skeleton height={48} />
                      ) : (
                        <PhoneNumberInput
                          control={control}
                          name='phoneNumber'
                          required
                          type='tel'
                          placeholder='Phone Number'
                          className={`${errors.phoneNumber && 'error-field'} ${
                            watchAllFields.phoneNumber && 'filled'
                          }`}
                        />
                      )}
                      {errors.phoneNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      {loadingStatus === 'loading' ? (
                        <Skeleton height={48} />
                      ) : (
                        <PhoneNumberInput
                          control={control}
                          name='faxNumber'
                          type='tel'
                          placeholder='Fax number'
                          className={`${errors.faxNumber && 'error-field'} ${
                            watchAllFields.faxNumber && 'filled'
                          }`}
                        />
                      )}
                      {errors.faxNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.faxNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      {loadingStatus === 'loading' ? (
                        <Skeleton height={48} />
                      ) : (
                        <InputField
                          register={register}
                          name='websiteUrl'
                          type='text'
                          placeholder='Website'
                          className={`${errors.websiteUrl && 'error-field'} ${
                            watchAllFields.websiteUrl && 'filled'
                          }`}
                        />
                      )}
                      {errors.websiteUrl ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.websiteUrl?.message}
                        </div>
                      ) : null}
                    </div>{' '}
                    {!isManual ? (
                      <div className='agency-fields'>
                        {loadingStatus === 'loading' ? (
                          <Skeleton height={48} />
                        ) : (
                          <GoogleSearchInput
                            placeholder='Address'
                            name='address'
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            inputType='PERSONAL'
                            initialValue={address}
                            required
                            className={`${
                              (errors.address || locationExistError) &&
                              'error-field'
                            } ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        )}

                        {submit && Object.keys(addressObject).length < 1 ? (
                          <div className='error-div'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter an address
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        <h2 className='business-info'> Address</h2>

                        <div className='agency-fields'>
                          {loadingStatus === 'loading' ? (
                            <Skeleton height={48} />
                          ) : (
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${
                                (errors.address || locationExistError) &&
                                'error-field'
                              } ${watchAllFields.address && 'filled'}`}
                            />
                          )}
                          {!watchAllFields.address && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='zipCode'
                            type='number'
                            placeholder='Zipcode'
                            register={register}
                            className={`${
                              (errors.zipCode || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.zipCode && 'filled'}`}
                          />
                          {!watchAllFields.zipCode && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>

                        <div className='agency-fields'>
                          <InputField
                            name='city'
                            type='text'
                            placeholder='City'
                            register={register}
                            className={`${
                              (errors.city || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.city && 'filled'}`}
                          />{' '}
                          {!watchAllFields.city && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='state'
                            type='text'
                            placeholder='State'
                            register={register}
                            className={`${
                              (errors.state || locationExistError) &&
                              'error-field'
                            } ${watchAllFields.state && 'filled'}`}
                          />
                          {!watchAllFields.state && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>

                        <div className='agency-fields'>
                          <InputField
                            name='country'
                            type='text'
                            placeholder='County'
                            register={register}
                            className={`${errors.country && 'error-field'} ${
                              watchAllFields.country && 'filled'
                            }`}
                          />
                          {!watchAllFields.country && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                        <div className='agency-fields'>
                          <InputField
                            name='apartment'
                            type='text'
                            placeholder='Apt'
                            register={register}
                            className={`${errors.apartment && 'error-field'} ${
                              watchAllFields.apartment && 'filled'
                            }`}
                          />
                          {!watchAllFields.apartment && addressError ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {addressError}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                    {locationExistError ? (
                      <div className='error-div'>
                        <img src={warning} alt='warning' className='warning' />
                        {locationExistError}
                      </div>
                    ) : null}
                  </div>

                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      disabled={loading || show === 'closing'}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
              {changePrimaryLocation && primaryLocationData && Submitdata && (
                <ChangePrimaryLocationModal
                  showModal={changePrimaryLocation}
                  closeTheModal={setChangePrimaryLocation}
                  deletingLocation={primaryLocationData}
                  name={watchAllFields.name}
                  onSubmit={onSubmit}
                  setPrimaryLocation={setPrimaryLocation}
                  data={Submitdata}
                />
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default EditLocationModal;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addLead,
  fetchLeads,
  updateLeadDetails,
  getLead,
  qualifyLead,
  junkLead,
  LeadDetailsCount,
  getLeadSourceTypes,
  createSourceType,
  editSourceType,
  createSource,
  editSource,
  deleteSource,
  deleteSourceType,
  deleteLead,
} from '../../api/lead';
import { ILead, ILeadSourceType } from '../../interfaces/Lead';

export type LeadsSliceState = {
  status: string;
  leadDetailsStatus: string;
  leads: ILead[];
  leadDetails?: ILead;
  isLoading: boolean;
  count: number;
  activitiesCount: number;
  documentsCount: number;
  leadSourceTypes: ILeadSourceType[];
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  leadStageUUID?: any;
  producerUUID?: string;
  leadSourceUUID?: any;
  accountType?: string;
  successCB?: Function;
}

const initialState: LeadsSliceState = {
  status: 'idle',
  leads: [],
  count: 0,
  documentsCount: 0,
  activitiesCount: 0,
  leadDetailsStatus: 'idle',
  isLoading: false,
  leadSourceTypes: [],
};

export const getLeads = createAsyncThunk(
  'leads/getLeads',
  async ({
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    leadStageUUID,
    producerUUID,
    leadSourceUUID,
    accountType,
    successCB,
  }: IProps) => {
    const response = await fetchLeads(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      leadStageUUID,
      producerUUID,
      leadSourceUUID,
      accountType
    );
    if (successCB) successCB(response.data);
    return response.data;
  }
);

export const fetchLead = createAsyncThunk(
  'leads/fetchLead',
  async ({ UUID, successCB }: { UUID?: string; successCB: Function }) => {
    const response = await getLead(UUID);
    successCB(response.data);
    return response.data;
  }
);
export const onQualifyLead = createAsyncThunk(
  'leads/onQualifyLead',
  async ({
    uuid,
    successCB,
    errorCB,
  }: {
    uuid: string;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await qualifyLead(uuid, successCB, errorCB);
    if (response.data.uuid) successCB();
    return response.data;
  }
);

export const onJunkLead = createAsyncThunk(
  'leads/onJunkLead',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: { junkReason: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await junkLead(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const onUpdateLeadDetails = createAsyncThunk(
  'leads/onUpdateLeadDetails',
  async ({
    data,
    successCB,
    errorCB,
    UUID,
  }: {
    data: ILead;
    successCB: Function;
    errorCB: Function;
    UUID: string;
  }) => {
    const response = await updateLeadDetails(data, UUID, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const createLead = createAsyncThunk(
  'leads/createLead',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: ILead;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addLead(data, successCB, errorCB);
    return response.data;
  }
);

export const getLeadCount = createAsyncThunk(
  'leads/getCount',
  async ({ uuid }: { uuid: string }) => {
    const response = await LeadDetailsCount(uuid);
    return response.data;
  }
);

export const fetchLeadSourceTypes = createAsyncThunk(
  'leads/fetchLeadSourceTypes',
  async ({ uuid, searchBy }: { uuid?: string; searchBy: string }) => {
    const response = await getLeadSourceTypes(() => {}, uuid, searchBy);
    return response.data;
  }
);

export const createLeadSourceType = createAsyncThunk(
  'leads/ceateLeadSourceType',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await createSourceType(data, successCB, errorCB);
    return response.data;
  }
);

export const createLeadSource = createAsyncThunk(
  'leads/ceateLeadSource',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: { agencyUUID?: string; name: string; typeUUID: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await createSource(data, successCB, errorCB);
    return response.data;
  }
);

export const onEditLeadSourceType = createAsyncThunk(
  'leads/onEditLeadSourceType',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await editSourceType(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const onDeleteLeadSourceType = createAsyncThunk(
  'leads/onDeleteLeadSourceType',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; destinationUUID: string };
    successCB: Function;
  }) => {
    const response = await deleteSourceType(uuid, data);
    successCB();
    return response.data;
  }
);

export const onDeleteLead = createAsyncThunk(
  'leads/onDeleteLead',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteLead(uuid);
    successCB();
    return response.data;
  }
);

export const onEditLeadSource = createAsyncThunk(
  'leads/onEditLeadSource',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; name: string; typeUUID: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await editSource(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const onDeleteLeadSource = createAsyncThunk(
  'leads/onDeleteLeadSource',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; destinationUUID: string; typeUUID: string };
    successCB: Function;
  }) => {
    const response = await deleteSource(uuid, data);
    successCB();
    return response.data;
  }
);

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLeads.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.leads = action.payload.leads;
        state.count = action.payload.count;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchLead.pending, (state, action) => {
        state.leadDetailsStatus = 'loading';
      })
      .addCase(fetchLead.fulfilled, (state, action) => {
        state.leadDetailsStatus = 'succeeded';
        state.leadDetails = action.payload;
      })
      .addCase(fetchLead.rejected, (state, action) => {
        state.leadDetailsStatus = 'failed';
      })
      .addCase(createLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createLead.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads.unshift(action.payload);
      })
      .addCase(onUpdateLeadDetails.fulfilled, (state, action) => {
        const index = state.leads.findIndex(
          (item: ILead) => item.uuid === action.payload.uuid
        );
        state.leads[index] = action.payload;
        state.leadDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(onUpdateLeadDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateLeadDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onQualifyLead.fulfilled, (state, action) => {
        if (action.payload.uuid) {
          const index = state.leads.findIndex(
            (item: ILead) => item?.uuid === action.payload.uuid
          );
          state.leads.splice(index, 1);
          state.leadDetails = action.payload;
        }
      })
      .addCase(onJunkLead.fulfilled, (state, action) => {
        if (action.payload.uuid) {
          const index = state.leads.findIndex(
            (item: ILead) => item?.uuid === action.payload.uuid
          );
          state.leads.splice(index, 1);
          state.leadDetails = action.payload;
        }
      })
      .addCase(getLeadCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentsCount = action.payload.documents;
        state.activitiesCount = action.payload.activities;
      })
      .addCase(fetchLeadSourceTypes.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchLeadSourceTypes.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchLeadSourceTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.leadSourceTypes = action.payload;
      })
      .addCase(createLeadSourceType.fulfilled, (state, action) => {
        state.leadSourceTypes.push(action.payload);
      })
      .addCase(onEditLeadSourceType.fulfilled, (state, action) => {
        const index = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.leadSourceTypes[index] = action.payload;
      })
      .addCase(createLeadSource.fulfilled, (state, action) => {
        const index = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.SourceType.uuid
        );
        state.leadSourceTypes[index].LeadSources.push(action.payload);
      })
      .addCase(onEditLeadSource.fulfilled, (state, action) => {
        const index = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.SourceType.uuid
        );
        const sourceIndex = state.leadSourceTypes[index].LeadSources.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        const updatedSourceList = [...state.leadSourceTypes[index].LeadSources];
        updatedSourceList.splice(sourceIndex, 1, action.payload);
        state.leadSourceTypes[index].LeadSources = updatedSourceList;
      })
      .addCase(onDeleteLeadSource.fulfilled, (state, action) => {
        const index = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.SourceType.uuid
        );
        const sourceIndex = state.leadSourceTypes[index].LeadSources.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        const updatedSourceList = [...state.leadSourceTypes[index].LeadSources];
        updatedSourceList.splice(sourceIndex, 1);
        state.leadSourceTypes[index].LeadSources = updatedSourceList;
      })
      .addCase(onDeleteLeadSourceType.fulfilled, (state, action) => {
        const index = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.sourceType.uuid
        );
        const destIndex = state.leadSourceTypes.findIndex(
          (item) => item.uuid === action.payload.destination.uuid
        );
        state.leadSourceTypes[destIndex].LeadSources.push(
          ...state.leadSourceTypes[index].LeadSources
        );
        state.leadSourceTypes.splice(index, 1);
      })
      .addCase(onDeleteLead.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteLead.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.leads.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.leads.splice(index, 1);
      })
      .addCase(onDeleteLead.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus } = leadsSlice.actions;

export default leadsSlice.reducer;

export const selectAllLeads = (state: { leads: LeadsSliceState }) =>
  state.leads.leads;

export const selectLeadDetails = (state: { leads: LeadsSliceState }) =>
  state.leads.leadDetails;

export const selectCount = (state: { leads: LeadsSliceState }) => {
  const objectCount: { [index: string]: any } = {
    document: state.leads.documentsCount,
    activities: state.leads.activitiesCount,
  };
  return objectCount;
};

export const selectLeadSourceTypes = (state: { leads: LeadsSliceState }) =>
  state.leads.leadSourceTypes;

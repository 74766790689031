import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchPolicies,
  fetchPolicyDetails,
  addPolicy,
  updatePolicyDetails,
  policyDetailsCount,
  updatePolicyConatcts,
  deletePolicy,
  policyCategory,
} from '../../api/policy';
import { IPolicy } from '../../interfaces/Policy';

export type PoliciesSliceState = {
  status: string;
  deailsStatus: string;
  policies: IPolicy[];
  policyDetails?: IPolicy;
  isLoading: boolean;
  count: number;
  activitiesCount: number;
  documentsCount: number;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  producerUUID?: string;
  productCategoryUUID?: string[];
  hasMGA?: boolean;
  carrierUUID?: string;
  accountUUID?: string;
  customCarrierUUID?: string;
  productUUID?: string[];
  customProductUUID?: string[];
  successCB?: Function;
}

const initialState: PoliciesSliceState = {
  status: 'idle',
  deailsStatus: 'idle',
  policies: [],
  isLoading: false,
  count: 0,
  activitiesCount: 0,
  documentsCount: 0,
};

export const getPolicies = createAsyncThunk(
  'policies/getPolicies',
  async ({
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    producerUUID,
    productCategoryUUID,
    hasMGA,
    carrierUUID,
    accountUUID,
    customCarrierUUID,
    productUUID,
    customProductUUID,
    successCB,
  }: IProps) => {
    const response = await fetchPolicies(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      producerUUID,
      productCategoryUUID,
      hasMGA,
      carrierUUID,
      accountUUID,
      customCarrierUUID,
      productUUID,
      customProductUUID
    );
    if (successCB) successCB(response.data);
    return response.data;
  }
);

export const getPolicyDetails = createAsyncThunk(
  'policies/getPolicyDetails',
  async ({ uuid, successCB }: { uuid?: string; successCB?: Function }) => {
    const response = await fetchPolicyDetails(uuid);
    if (successCB) {
      successCB(response.data);
    }
    return response.data;
  }
);

export const createPolicy = createAsyncThunk(
  'policies/createPolicy',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addPolicy(data, successCB, errorCB);
    return response.data?.policyDetails;
  }
);

export const onUpdatePolicyDetails = createAsyncThunk(
  'policies/onUpdatePolicyDetails',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: IPolicy;
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await updatePolicyDetails(data, uuid, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const onUpdatePolicyContact = createAsyncThunk(
  'opportunities/onUpdatePolicyContact',
  async ({
    data,
    successCB,
    uuid,
  }: {
    data: IPolicy[];
    successCB: Function;

    uuid: string;
  }) => {
    const response = await updatePolicyConatcts(data, uuid);
    successCB(response.data);
    return response.data;
  }
);

export const getCount = createAsyncThunk(
  'policies/getCount',
  async ({ uuid }: { uuid: string }) => {
    const response = await policyDetailsCount(uuid);
    return response.data;
  }
);

export const onDeletePolicy = createAsyncThunk(
  'policies/onDeletePolicy',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deletePolicy(uuid);
    successCB();
    return response.data;
  }
);

export const getPolicyCategory = createAsyncThunk(
  'policies/getPolicyCategory',
  async ({ uuid }: { uuid: string }) => {
    const response = await policyCategory(uuid);
    return response.data;
  }
);
const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    addPolicyDetails: (state, action) => {
      if (state.policyDetails) {
        state.policyDetails.Contacts = [
          ...state.policyDetails.Contacts,
          ...action.payload.PolicyContact.contacts,
        ];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPolicies.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.policies = action.payload.policies;
        state.count = action.payload.count;
        state.isLoading = false;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.status = 'failed';
        state.isLoading = false;
      })
      .addCase(getPolicyDetails.pending, (state, action) => {
        state.deailsStatus = 'loading';
      })
      .addCase(getPolicyDetails.fulfilled, (state, action) => {
        state.deailsStatus = 'succeeded';
        state.policyDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(createPolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.policies.unshift(action.payload[0]);
      })
      .addCase(createPolicy.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPolicy.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdatePolicyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.policies.findIndex(
          (item: IPolicy) => item.uuid === action.payload.uuid
        );
        state.policies[index] = action.payload;
        state.policyDetails = action.payload;
      })
      .addCase(onUpdatePolicyDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdatePolicyDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activitiesCount = action.payload.activities;
        state.documentsCount = action.payload.documents;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onUpdatePolicyContact.fulfilled, (state, action) => {
        if (state.policyDetails) {
          state.policyDetails.Contacts = [
            ...state.policyDetails.Contacts,
            ...action.payload.contacts,
          ];
        }

        state.isLoading = false;
      })
      .addCase(onUpdatePolicyContact.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdatePolicyContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onDeletePolicy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeletePolicy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.policies.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.policies.splice(index, 1);
      })
      .addCase(onDeletePolicy.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, addPolicyDetails } = policiesSlice.actions;

export default policiesSlice.reducer;

export const selectAllPolicies = (state: { policies: PoliciesSliceState }) =>
  state.policies?.policies;

export const selectPolicy = (state: { policies: PoliciesSliceState }) =>
  state.policies.policyDetails;

export const selectPolicyCount = (state: { policies: PoliciesSliceState }) => {
  const objectCount: { [index: string]: any } = {
    documents: state.policies.documentsCount,
    activities: state.policies.activitiesCount,
  };
  return objectCount;
};

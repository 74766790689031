import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import HistoryTab from '../../../../components/HistoryInnerTab';
import { LeadMobileInnerTabStyle } from '../../../../styles/StyledTab';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import vector from '../../../../assets/logo/dropdown.svg';
import Documents from '../../../../components/DocumentsInnerTab';
import NotesMobile from '../../../../components/ActivitiesInnerTab/components/NotesMobile';
import ActionsDropdown from '../../../../components/ActivitiesInnerTab/components/dropdown';
import TaskDetail from '../../../../components/ActivitiesInnerTab/components/TaskDetails';
import {
  ActivitiesStyledDiv,
  FieldComponents,
} from '../../../../components/ActivitiesInnerTab/styles';
import moment from 'moment';
import MarkdownEditor from '../../../../components/MarkdownEditor';
import search from '../../../../assets/logo/search.svg';
import filter from '../../../../assets/logo/filter.svg';
import plus from '../../../../assets/logo/blackplus.svg';
import whitePlus from '../../../../assets/logo/plus.svg';
import { useSelector } from 'react-redux';
import {
  selectAllActivities,
  getActivities,
  TaskSliceState,
  deleteAnEvent,
  deleteATask,
  deleteNote,
} from '../../../../store/tasks/tasksSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { IProducer } from '../../../../interfaces/Agent';
import { fetchAgents } from '../../../../api/agents';
import {
  fetchTaskPriority,
  fetchTaskStatus,
  fetchTaskType,
} from '../../../../api/task';
import { fetchContacts } from '../../../../api/contact';
import { fetchEventType } from '../../../../api/events';
import { LeadsSliceState } from '../../../../store/leads/leadsSlice';
import NotesContainer from '../../../../components/NotesContainer';
import EventDetails from '../../../../components/ActivitiesInnerTab/components/EventDetails';
import DocumentAddModal from '../../../../components/ActivitiesInnerTab/components/DocumentAddModal';

function LeadInnerMobileTab({
  leadUUID,
  LeadDocumentCount,
  noteAdded,
  updateNote,
  addNote,
  addNoteLoading,
  addTask,
  addEvent,
  ApiSuccess,
  setAdded,
  setEdited,
  edited,
  added,
  setDeleted,
}: {
  leadUUID: string;
  LeadDocumentCount: any;
  noteAdded: boolean;
  updateNote: Function;
  ApiSuccess: boolean;
  addNote: Function;
  addNoteLoading: boolean;
  addTask: Function;
  addEvent: Function;
  setAdded: Function;
  setEdited: Function;
  edited: string;
  added: string;
  setDeleted: Function;
}) {
  const [activities, setActivities] = useState(true);
  const [document, setDocument] = useState(false);
  const [history, setHistory] = useState(false);
  const [opportunities, setOpportunities] = useState(false);
  const [heading, setHeading] = useState('Activities');
  const [showDropdown, setShowDropdown] = useState(false);
  const allActivities = useSelector(selectAllActivities);
  const dropRef = useRef(null);
  const [taskType, setTaskType] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [taskStatusUUID, setTaskStatusUUID] = useState('');
  const [taskPriority, setTaskPriority] = useState([]);
  const [assignee, setAssignee] = useState<any>({});
  const [guest, setGuest] = useState<any>([]);
  const [guests, setGuests] = useState<any[]>([]);
  const [addNotesMobile, setAddNoteMobile] = useState(false);
  const [addButton, setaddButton] = useState(false);
  const [isTaskMobile, setIsTaskMobile] = useState(false);
  const [isEventMobile, setIsEventMobile] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [note, setNote] = useState('');
  const [tasks, setTasks] = useState<any>({});
  const [showModal, setShowModal] = useState('closed');
  const [file, setFile] = useState<any>();
  const [docTypeUUID, setDocTypeUUID] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const notesOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.notesOffset
  );
  const tasksOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.tasksOffset
  );
  const eventsOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.eventsOffset
  );

  useEffect(() => {
    fetchAgents(
      (data: IProducer[]) => {
        setProducers(data);
        setAssignee(data[0]);
      },
      'id',
      'asc',
      '',
      'True'
    );
    fetchTaskType((data: any) => {
      setTaskType(data);
    });
    fetchTaskPriority((data: any) => {
      setTaskPriority(data);
    });
    fetchTaskStatus((data: any) => {
      const status = data.find((item: any) => item.name === 'OPEN');
      setTaskStatusUUID(status.uuid);
    });
    fetchContacts('id', 'asc', '', 25, 0, 'True', (data: IProducer[]) => {
      setGuests(data);
    });
    fetchEventType((data: any) => {
      setEventType(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNote('');
    setTasks({ text: '' });
    setGuests([]);
    setGuest([]);
    setEventType([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteAdded]);

  useOutsideClick(dropRef, setShowDropdown);

  const loadingStatus = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.leadDetailsStatus
  );

  const onScrollActivities = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingStatus !== 'loading' &&
      leadUUID
    ) {
      const uuidName = `leadUUID`;

      dispatch(
        getActivities({
          uuidName,
          uuid: leadUUID,
          showAll: 'true',
          successCB: () => {},
          tasksOffset,
          notesOffset,
          eventsOffset,
        })
      );
    }
  };

  const handleDeleteNote = (uuid: string) => {
    setDeleted('Note');
    dispatch(
      deleteNote({
        uuid: uuid,
        successCB: () => {
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const handleDeleteTask = (uuid: string) => {
    setDeleted('Task');
    dispatch(
      deleteATask({
        uuid: uuid,
        successCB: () => {
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const handleTaskSubmit = () => {
    tasks.taskStatusUUID = taskStatusUUID;
    if (
      tasks.taskTypeUUID &&
      tasks.taskPriorityUUID &&
      tasks.dueDate &&
      tasks.text
    ) {
      addTask(tasks);
    }
  };

  const handleEditNotes = (uuid: string, index: any) => {
    setEditIndex(index);
  };

  const handleDelete = (uuid: string) => {
    setDeleted('Event');
    dispatch(
      deleteAnEvent({
        uuid: uuid,
        successCB: () => {
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const onItem = (name: string, value: string) => {
    let updatedTask = { ...tasks };
    updatedTask[name] = value;
    setTasks(updatedTask);
  };

  return (
    <>
      <LeadMobileInnerTabStyle>
        <div
          className='leadInnerMob-Head
        '
          onClick={() => setShowDropdown(!showDropdown)}
          ref={dropRef}
        >
          <div className='lead-headtext d-flex'>
            {heading}
            {heading === 'Activities' ? (
              <span className='count'>{LeadDocumentCount.activities}</span>
            ) : null}
            {heading === 'Documents' ? (
              <span className='count'>{LeadDocumentCount.document}</span>
            ) : null}
            <b>
              <img className='vector-style' src={vector} alt='vector' />
            </b>
          </div>
        </div>
        <div className='active-row'></div>
        <div className='row'></div>
        {showDropdown && (
          <div className='mobile-tab'>
            <span
              className={activities ? `navlink active` : 'navlink'}
              onClick={() => {
                setActivities(true);
                setDocument(false);
                setHistory(false);
                setOpportunities(false);
                setHeading('Activities');
              }}
            >
              Activities{' '}
              <span className='count'>{LeadDocumentCount.activities}</span>
            </span>

            <span
              className={document ? `navlink active` : 'navlink'}
              onClick={() => {
                setDocument(true);
                setOpportunities(false);
                setActivities(false);
                setHistory(false);
                setHeading('Documents');
              }}
            >
              Documents{' '}
              <span className='count'>{LeadDocumentCount.document}</span>
            </span>

            <span
              className={history ? `navlink active` : 'navlink'}
              onClick={() => {
                setHistory(true);
                setActivities(false);
                setDocument(false);
                setOpportunities(false);
                setHeading('Histories');
              }}
            >
              History
            </span>
          </div>
        )}
      </LeadMobileInnerTabStyle>
      {activities && (
        <ActivitiesStyledDiv>
          <div className='timeline'>
            <div className=''>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='titleHead'>All Activities</h2>
                <div className='d-none d-sm-flex justify-content-between'>
                  <div className='book-filter d-flex'>
                    <div className=' d-flex align-items-center navbar-button justify-content-center'>
                      <img src={search} alt='' />
                    </div>
                    <div className='filter-button d-flex align-items-center justify-content-center'>
                      Filter by: No filter
                    </div>
                  </div>
                </div>
                <div className='d-flex d-sm-none'>
                  <img src={search} alt='' className='search' />
                  <span className={`divider`} />
                  <div className='d-flex align-items-center'>
                    <img src={filter} alt='' className='filter' />
                  </div>
                </div>
              </div>
              {addButton && <div className='addButton-background' />}
              <button
                onClick={() => setaddButton(!addButton)}
                className={`addbutton-mob d-sm-none ${
                  addButton ? 'dropOpen' : ''
                }`}
              >
                {addButton && (
                  <div className='dropUp '>
                    <ul
                      className='tasks d-flex justify-content-between w-100 '
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                      }}
                    >
                      Add Notes <img src={plus} alt='' />
                    </ul>
                    <ul
                      className='tasks d-flex justify-content-between w-100 '
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                        setIsTaskMobile(true);
                        setIsEventMobile(false);
                      }}
                    >
                      Add Task <img src={plus} alt='' />
                    </ul>
                    <ul
                      className='tasks d-flex  justify-content-between w-100'
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                        setIsEventMobile(true);
                        setIsTaskMobile(false);
                      }}
                    >
                      Add Event <img src={plus} alt='' />
                    </ul>
                  </div>
                )}
                <img src={whitePlus} alt='add' className='addButton-img' />
              </button>
            </div>

            <div className='activity-scroll' onScroll={onScrollActivities}>
              {allActivities?.map((item, index) => (
                <div className='card' key={index}>
                  <div className='outer'> </div>

                  {item?.type === 'NOTE' ? (
                    <div className='info '>
                      <div className='d-flex imageCard align-items-center '>
                        <div
                          className={`profile-pic d-none  d-sm-flex align-items-center justify-content-center`}
                        >
                          <div className='profile'>
                            {item?.data?.CreatedBy?.firstName.charAt(0)}
                          </div>
                        </div>

                        <span className=' d-none d-sm-flex'>
                          <h2 className='title Heading '>
                            {item?.data?.CreatedBy?.firstName}{' '}
                            {item?.data?.CreatedBy?.lastName}
                          </h2>
                          <h2 className=' title added '>added a Note</h2>
                          <h6 className=' title dateFont '>
                            on{' '}
                            {moment(item?.data?.createdAt).format(
                              'Do MMMM YYYY, h:mm:ss a'
                            )}
                          </h6>
                        </span>
                        <p className=' d-sm-none'>
                          {' '}
                          <b className='title'>
                            {item?.data?.CreatedBy?.firstName}{' '}
                            {item?.data?.CreatedBy?.lastName}
                          </b>
                          <span className=' title added '>
                            added a Note on{' '}
                          </span>
                          <span className=' title dateFont '>
                            {' '}
                            {moment(item?.data?.createdAt).format(
                              'Do MMMM YYYY, h:mm:ss a'
                            )}{' '}
                          </span>
                        </p>
                      </div>
                      {/* {item.data.text = item.data.text} */}
                      {editIndex !== index ? (
                        <div className='contentDiv d-flex justify-content-between'>
                          <NotesContainer text={item.data?.text} />
                          <div className='d-flex event-button-container'>
                            <ActionsDropdown
                              uuid={item.data.uuid}
                              handleDelete={handleDeleteNote}
                              handleEdit={handleEditNotes}
                              index={index}
                            />
                          </div>
                        </div>
                      ) : (
                        <FieldComponents>
                          <>
                            <div className='tab-content-div'>
                              <div className='fields'>
                                <MarkdownEditor
                                  placeholder='Write note here...'
                                  note={item?.data?.text}
                                  setNote={setNote}
                                  onBlur={() => setEditIndex(-1)}
                                />
                              </div>
                            </div>
                            <div className='addNoteButtonContainer'>
                              <button
                                className='tertiaryButton'
                                type='button'
                                disabled={
                                  item?.data?.text === note ||
                                  note.trim() === ''
                                }
                                onClick={() => {
                                  updateNote(
                                    item?.data?.uuid,
                                    {
                                      text: note.slice(0, -1),
                                    },
                                    leadUUID
                                  );
                                  setEditIndex(-1);
                                }}
                              >
                                Edit Note
                              </button>
                            </div>
                          </>
                        </FieldComponents>
                      )}
                    </div>
                  ) : null}
                  {item?.type === 'TASK' ? (
                    <TaskDetail
                      item={item}
                      handleDeleteTask={handleDeleteTask}
                      taskType={taskType}
                      taskPriority={taskPriority}
                      producers={producers}
                      isMobile={true}
                      ApiSuccess={ApiSuccess}
                      setEdited={setEdited}
                    />
                  ) : null}
                  {item.type === 'EVENT' ? (
                    <>
                      <EventDetails
                        item={item}
                        handleDelete={handleDelete}
                        eventType={eventType}
                        guests={guests}
                        ApiSuccess={ApiSuccess}
                        setShowModal={setShowModal}
                        file={file}
                        setEdited={setEdited}
                      />
                      {showModal !== 'closed' && (
                        <DocumentAddModal
                          show={showModal}
                          closeModal={setShowModal}
                          file={file}
                          setFile={setFile}
                          name='task'
                          docTypeUUID={docTypeUUID}
                          setDocTypeUUID={setDocTypeUUID}
                        />
                      )}
                    </>
                  ) : null}
                </div>
              ))}
            </div>
            {addNotesMobile && leadUUID && (
              <div className='d-sm-none'>
                <NotesMobile
                  addNote={addNote}
                  handleTaskSubmit={handleTaskSubmit}
                  addTask={addTask}
                  taskType={taskType}
                  producers={producers}
                  setAssignee={setAssignee}
                  taskPriority={taskPriority}
                  assignee={assignee}
                  addEvent={addEvent}
                  onItem={onItem}
                  type={isTaskMobile ? 'task' : isEventMobile ? 'event' : ''}
                  uuid={leadUUID}
                  tasks={tasks}
                  ApiSuccess={ApiSuccess}
                  show={addNotesMobile}
                  closeModal={setAddNoteMobile}
                  addNoteLoading={addNoteLoading}
                />
              </div>
            )}
          </div>
        </ActivitiesStyledDiv>
      )}

      <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 0 }}>
        {document && (
          <div>
            <Documents name={'lead'} uuid={leadUUID} />{' '}
          </div>
        )}
      </div>

      <div style={{ marginLeft: 15 }}>
        {history && <HistoryTab resourceName='lead' rUUID={leadUUID} />}
      </div>
    </>
  );
}

export default LeadInnerMobileTab;

import moment from 'moment';
import { useState, useRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import {
  IOpportunity,
  IPipelineStage,
  IStageData,
} from '../../../interfaces/Opportunity';
import right from '../../../assets/logo/rightvector.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import plus from '../../../assets/logo/blackplus.svg';
import arrow from '../../../assets/logo/blackRightArrow.svg';
import AddQuoteModal from '../../../components/AddQuoteModal';
import EditOpportunityDetails from '../../OpportunityDetails/EditOpportunityDetails';
import blackPlus from '../../../assets/logo/blackplus.svg';

const Stage = ({
  stage,
  isNotEnd,
  stageData,
  index,
  setShowActions,
  getMoreItems,
  setShowDeleteModal,
  setName,
  setUUID,
  UUID,
  setEdit,
  edit,
}: {
  stage: IPipelineStage;
  isNotEnd: boolean;
  stageData: IStageData;
  index: number;
  setShowActions: Function;
  getMoreItems: Function;
  setShowDeleteModal: Function;
  setName: Function;
  setUUID: Function;
  setEdit: Function;
  edit: boolean;
  UUID: string;
}) => {
  const navigate = useNavigate();

  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const [showEditModal, setShowEditModal] = useState('closed');

  return (
    <div className='col' key={stage.uuid}>
      <Droppable droppableId={`${index}`}>
        {(provided, snapshot) => {
          return (
            <>
              <div
                className='d-flex stages-div'
                key={stage.uuid}
                style={{ height: 66 }}
              >
                <div className='w-100 stage-header'>
                  <h5 className='card-title' style={{ textDecoration: 'none' }}>
                    {stage.name}
                    <span className='count-div'>{stageData?.count}</span>
                  </h5>
                  est. value $
                  {stageData?.sum
                    ? (
                        (stageData.sum * stage.stageProbability) /
                        100
                      ).toLocaleString('en-US')
                    : 0}
                </div>
                {isNotEnd && <img src={right} alt='' className='stage-item' />}
              </div>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='scroll-div'
              >
                {stageData?.opportunities?.map((opportunity, index) => (
                  <Draggable
                    draggableId={opportunity.uuid}
                    index={index}
                    key={opportunity.uuid}
                    isDragDisabled={stage.name === 'Won'}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          onMouseLeave={(e) => setShowActions(false)}
                          className='draggable'
                          onClick={() => {
                            navigate(`/opportunities/${opportunity.uuid}`);
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <StageCard
                            opportunity={opportunity}
                            setShowActions={setShowActions}
                            setShowDeleteModal={setShowDeleteModal}
                            setName={setName}
                            setUUID={setUUID}
                            setShowQuoteModal={setShowQuoteModal}
                            setShowEditModal={setShowEditModal}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {stageData?.opportunities && (
                  <>
                    {stageData.opportunities?.length < stageData.count && (
                      <button
                        className='showButton'
                        onClick={() =>
                          getMoreItems(
                            stageData.opportunities.length,
                            stage.uuid,
                            index
                          )
                        }
                      >
                        Show more items
                      </button>
                    )}
                  </>
                )}
                {provided.placeholder}
              </div>
            </>
          );
        }}
      </Droppable>
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Opportunity'
          opportunityUUID={UUID}
        />
      )}
      {showEditModal !== 'closed' && (
        <EditOpportunityDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={UUID}
          setEdit={setEdit}
          edit={edit}
        />
      )}
    </div>
  );
};

export default Stage;

const StageCard = ({
  opportunity,
  setShowActions,
  setShowDeleteModal,
  setName,
  setUUID,
  setShowQuoteModal,
  setShowEditModal,
}: {
  opportunity: IOpportunity;
  setShowActions: Function;
  setShowDeleteModal: Function;
  setName: Function;
  setUUID: Function;
  setShowQuoteModal: Function;
  setShowEditModal: Function;
}) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowDropdown);

  return (
    <>
      <div className='d-flex'>
        <h5 className='card-title'>
          {opportunity.name ? opportunity.name : '--'}
        </h5>
        <span className='amount'>
          $
          {opportunity.leastPremium
            ? opportunity.leastPremium.toLocaleString('en-US')
            : 0}
        </span>
      </div>
      <div
        className='mb-2'
        style={{
          whiteSpace: 'nowrap',
          width: '80%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {opportunity.Account.name ? opportunity.Account.name : '--'}
      </div>
      <div className='lastupdate d-flex justify-content-between'>
        <div>
          Last updated: {moment(opportunity.updatedAt).format('MM/DD/YY')}
        </div>
        <button
          className='vector d-flex align-items-center justify-content-center position-relative'
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(!showDropdown);
            setUUID(opportunity.uuid);
            setShowActions(false);
            if (dropRef.current) {
              dropRef.current.focus();
            }
          }}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setShowDropdown(false);
            }
          }}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showDropdown && (
            <div
              className={`dropdown-div ${
                opportunity?.PipelineStage?.name === 'Won' && 'Wonopportunity'
              }`}
            >
              {opportunity?.PipelineStage?.name !== 'Won' && (
                <>
                  <div
                    className='quotediv'
                    onClick={() => setShowQuoteModal('open')}
                    tabIndex={0}
                  >
                    Add Quote <img src={plus} alt='plus' />
                  </div>
                </>
              )}
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setShowDropdown(false);
                }}
                tabIndex={1}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div
                className='edit-div'
                style={{ paddingTop: '10px' }}
                onClick={() =>
                  navigate(`/opportunities/${opportunity.uuid}/activities`)
                }
                tabIndex={2}
              >
                Add activity <img src={blackPlus} alt='plusIcon' />
              </div>
              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setUUID(opportunity.uuid);
                  setShowDropdown(false);
                  setName(opportunity.name);
                }}
                tabIndex={3}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

import styled from '@emotion/styled';

export const CardDiv = styled.tr`
  border-bottom: 1px solid #f5f5f5;

  td {
    padding: 0 16px;
    box-shadow: inset -1px 0px 0px #f5f5f5;
    height: 56px;
  }

  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 40px;
      left: 0;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  td:last-child {
    box-shadow: none;
  }

  .agencyName {
    font-weight: 600;
    font-size: 14px;
  }

  .row-name:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #3c498a;
    text-decoration-thickness: 2px;
  }

  .name-td {
    display: flex;
    align-items: center;

    b {
      width: calc(100% - 30px);
    }
  }
  .name-text {
    font-weight: 600;
    font-size: 16px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .leader-text {
    font-weight: 600;
    font-size: 16px;
  }

  .status {
    width: 102px;
    font-size: 12px;
    text-align: center;
    border-radius: 16px;
    padding: 4px 0;
    font-weight: 600;
    margin: 0 auto;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    text-align: center;
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }
  .statusPolicy {
    background: #d4fad4;
    color: #0a5e0e;
    margin-top: -6px;
  }

  .dateField {
    font-size: 14px;
  }

  .userGroup {
    font-size: 14px;
  }

  .subGroup {
    font-size: 12px;
    color: #8990a3;
  }

  .actionButton {
    background: #ecf4f9;
    text-align: center;
    border-radius: 8px;
    padding: 8px 30px;
    width: max-content;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: 0;
  }

  .action-item {
    width: 32px;
    height: 32px;
    background: #ecf4f9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .actionField {
    position: relative;

    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 16px;
      bottom: -55px;
      font-size: 14px;
      z-index: 1;
      width: 144px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .delete-div {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }

      .edit-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .action-dropContainer {
      padding-bottom: 3px;
      position: absolute;
      border-radius: 8px;
      right: 0px;
      z-index: 1;
      top: 35px;
      .dropdown-div {
        position: static;
        right: unset;
        bottom: unset;
      }
    }
  }

  .user-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #262758;
    color: #f5f7fc;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .opportunity-div {
    font-size: 12px;
    color: #8990a3;
  }
  .opp-num {
    padding: 4px 16px;
    color: #262758;
    font-weight: 600;
    background: #dfe6f5;
    border-radius: 16px;
    margin-right: 8px;
  }

  .phone-div {
    position: relative;
    .contact-tooltip {
      display: none;
      position: absolute;
      padding: 20px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 4px 16px 0px #26275833;
      top: 30px;

      .action-item {
        margin-left: 16px;
      }

      .action-item:first-of-type {
        margin: 0;
      }
    }
    :hover {
      .contact-tooltip {
        display: flex;
        z-index: 1;

        :after {
          content: ' ';
          position: absolute;
          top: -10px;
          left: 10%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent white transparent;
        }
      }
    }
  }

  .txt:hover {
    text-decoration: underline;
  }

  .count-div {
    padding: 8px 20px;
    background: #f5f7fc;
    font-size: 14px;
    font-weight: 600;
    border-radius: 32px;
    margin-left: 16px;
  }
  .clip-hover {
  }
  @media screen and (min-width: 1200px) {
    .actionButton {
      padding: 8px 40px;
    }
  }
  @media screen and (min-width: 1900px) {
    td {
      height: 64px;
    }

    .agencyName {
      font-size: 16px;
    }

    .status {
      width: 129px;
    }

    .dateField {
      font-size: 16px;
    }

    .userGroup {
      font-size: 16px;
    }

    .actionButton {
      padding: 8px 60px;
    }
  }

  @media screen and (min-width: 2800px) {
    td {
      height: 128px;
      font-size: 32px;
      padding: 0 32px;
    }

    .agencyName {
      font-size: 32px;
    }

    .status {
      width: 258px;
      border-radius: 32px;
      font-size: 24px;
      padding: 8px 0;
    }

    .dateField {
      font-size: 32px;
    }

    .userGroup {
      font-size: 32px;
    }

    .subGroup {
      font-size: 24px;
    }

    .actionButton {
      padding: 16px 120px;
      font-size: 28px;
      border-radius: 16px;
    }

    .vector {
      width: 64px;
      height: 64px;
      img {
        width: 20px;
      }
    }
  }
`;

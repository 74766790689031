import axios from './axios';
import { IContact } from '../interfaces/Contact';

const fetchAccounts = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  type?: string[] | string,
  successCB?: Function,
  minimal?: string,
  contactUUID?: string,
  opportunityUUID?: string,
  accountStatusUUID?: string[],
  hasPolicy?: string,
  producerUUID?: string
) => {
  return axios
    .get('/account', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        type,
        minimal,
        contactUUID,
        limit,
        offset,
        accountStatusUUID,
        hasPolicy,
        producerUUID,
      },
    })
    .then((res) => {
      if (successCB) {
        successCB(res.data?.accounts);
      }
      return res;
    });
};

const fetchAccountDetails = (uuid?: string) => {
  return axios.get(`/account/${uuid}`).then(
    (res) => res,
    (error) => error.response
  );
};

const UpdateAccountContacts = (
  data: IContact[],
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/account/${uuid}/contact`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response.data.error);
      throw new Error(error.response.data.error);
    }
  );
};

const updateAccountDetail = (data: any, uuid: string, errorCB?: Function) => {
  return axios.patch(`/account/${uuid}`, data).then(
    (res) => {
      return res;
    },
    (error) => {
      if (errorCB) errorCB(error.response.data.error);
      return error.res;
    }
  );
};

const getAccountStatus = (successCB: Function) => {
  return axios.get(`/account/status`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const addAccount = (data: IContact, successCB: Function, errorCB: Function) => {
  return axios.post('/account', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response?.data.error);
      return error.res;
    }
  );
};

const fetchAccountOpportunity = (
  accountUUID: string,
  offset: number,
  getQuote?: string,
  pipelineUUID?: string[],
  opportunityStatusUUID?: string[],
  productCategoryUUID?: string[],
  producerUUID?: string,
  productUUID?: string[],
  customProductUUID?: string[],
  quoteTypeUUID?: string,
  quoteStageUUID?: string[],
  quoteStatusUUID?: string,
  coverageTypeUUID?: string[]
) => {
  return axios
    .get(`/opportunities/`, {
      params: {
        accountUUID,
        limit: 4,
        offset,
        getQuote,
        pipelineUUID,
        opportunityStatusUUID,
        productCategoryUUID,
        producerUUID,
        productUUID,
        customProductUUID,
        quoteTypeUUID,
        quoteStageUUID,
        quoteStatusUUID,
        coverageTypeUUID,
      },
    })
    .then((res) => {
      return res;
    });
};

const fetchAccountPolicies = (
  accountUUID: string,
  offset: number,
  producerUUID?: string,
  productCategoryUUID?: string[],
  hasMGA?: boolean,
  carrierUUID?: string,
  customCarrierUUID?: string,
  productUUID?: string[],
  customProductUUID?: string[]
) => {
  return axios
    .get(`/policy/`, {
      params: {
        accountUUID,
        offset,
        limit: 4,
        producerUUID,
        productCategoryUUID,
        hasMGA,
        carrierUUID,
        customCarrierUUID,
        productUUID,
        customProductUUID,
      },
    })
    .then((res) => {
      return res;
    });
};

const AccountDetailsCount = (accountUUID: string) => {
  return axios
    .get(`/user/mybook/count`, { params: { accountUUID } })
    .then((res) => {
      return res;
    });
};

const getAccountType = (successCB: Function) => {
  return axios.get(`/account/type`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const deleteAccount = (uuid: String) => {
  return axios.delete(`/account/${uuid}`);
};

export {
  fetchAccounts,
  fetchAccountDetails,
  updateAccountDetail,
  getAccountStatus,
  addAccount,
  fetchAccountOpportunity,
  fetchAccountPolicies,
  AccountDetailsCount,
  getAccountType,
  UpdateAccountContacts,
  deleteAccount,
};

import styled from '@emotion/styled';

export const StyledModal = styled.div`
  position: relative;
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
  }

  .cross {
    width: 20px;
  }

  h2 {
    font-size: 20px;
  }
  .contact-para {
    font-weight: 600;
    margin-top: 24px;
    color: #000000;
  }

  .agency-fields {
    width: 99%;
    margin: 16px auto;
    color: #262758;
  }
  .agency-fields::placeholder {
    color: #262758;
  }

  .cancel-button {
    height: 48px;
    background: none;
    color: #5a5f70;
    font-weight: 400;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    color: #cc0000;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    padding: 20px;
    padding-top: 32px;
  }
  .field-section {
    padding-bottom: 20px;
    height: 70vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .row {
    border: 1px solid #f5f5f5;
  }
  .head {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #262758;
    margin-top: 10px;
  }
  hr.filter-hr {
    border-bottom: 1px solid #f5f5f5;
    margin: 24px 0;
    opacity: 1;
    background: none;
  }

  .buttonsDiv.filterButtonContainer {
    position: static;
    padding-left: 0;
    padding-right: 0;
    .agentButton {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 576px) {
    width: 360px;
    .subhead {
      max-width: 300px;
    }
    .buttonsDiv {
      padding: 20px 48px;
    }
    .cancel-button {
      margin-right: 80px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 360px;

    .cross {
      width: 16px;
    }

    h2 {
      font-size: 24px;
    }

    .buttonsDiv {
      border: none;
      border-top: 1px solid #e6e9f0;
    }
    .cancel-button {
      margin-right: 79px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 506px;
    h2 {
      font-size: 28px;
    }

    .subhead {
      font-size: 16px;
      max-width: 450px;
    }

    .contact-para {
      font-size: 20px;
    }

    .agency-fields {
      margin: 20px auto;
    }

    .cancel-button {
      font-weight: 400;
      font-size: 16px;
      margin-right: 170px;
    }

    .agentButton {
      font-weight: 600;
      font-size: 16px;
    }

    .buttonsDiv {
      border-top: 1px solid #e6e9f0;
      margin-top: 20px;
      padding: 40px 60px;
      padding-top: 32px;
    }
    .cross {
      width: 20px;
    }
  }
`;

import { Navigate, Route, Routes } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import { AdminDiv } from '../../styles/AdminManage';
import AgencySettings from '../AgencySettings';
import PipelineMange from '../Admin/PipelineMange';
import AgencyDetails from '../Admin/AgencyDetails';
import MobileNav from '../../components/MobileTabNav';
import Carrier from '../Admin/Carrier';
import AgencySettingsPage from '../Admin/AgencySettingsPage';
import LocationSettings from '../Admin/LocationSettings';
import LeadSettings from '../LeadSettings';
import GoalSettings from '../Admin/GoalSettings';
import CustomFields from '../Admin/CustomFieldSettings';
import PolicySettings from '../Admin/PolicySettings';

function Settings() {
  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <Routes>
        <Route path='/pipeline' element={<PipelineMange />} />
        <Route path='/user' element={<AgencyDetails />} />
        <Route path='/manage-pipeline' element={<PipelineMange />} />
        <Route path='/goal' element={<GoalSettings />} />
        <Route path='/leads/*' element={<LeadSettings />} />
        <Route path='/carrier/*' element={<Carrier />} />
        <Route path='/policy' element={<PolicySettings />} />
        <Route path='/Agencysettings/location' element={<LocationSettings />} />
        <Route path='/Agencysettings' element={<AgencySettingsPage />} />
        <Route path='/customfield/*' element={<CustomFields />} />
        {/* <Route path='/customfield/leads' element={<CustomFields />} /> */}

        <Route path='/' element={<AgencySettings />} />
        <Route path='*' element={<Navigate to='/settings' replace />} />
      </Routes>
      <div className='d-xl-none'>
        <MobileNav />
      </div>
    </AdminDiv>
  );
}

export default Settings;

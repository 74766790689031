import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { AppDispatch } from '../../store';
import {
  getContact,
  selectContact,
  ContactsSliceState,
  getCount,
  onDeleteContact,
} from '../../store/contacts/contactsSlice';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import ActivitiesTab from '../../components/DetailsPageTab';
import MobileContactDetails from './components/MobileContactDetails';
import QuickAccountDrawer from '../MyBook/Accounts/components/QuickAddDrawer';
import DeleteModal from '../../components/DeleteModal';
import ErrorCard from '../Pipeline/components/ErrorCard';
import QuickAddOpportunityModal from '../../components/QuickAddOpportunity';
import AddQuoteModal from '../../components/AddQuoteModal';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import AddDocumentsModal from '../../components/DocumentsInnerTab/DocumentTable/components/AddDocumentsModal';
import AddOpportunityDrawer from '../../components/AddOpportunityDrawer';
import ContactDetailsDrawer from './components/ContactDetailsDrawer';
import { DetailsDiv } from '../../styles/DetailsDiv';
import dropdown from '../../assets/logo/dropdown.svg';
import EditContactDetails from './EditContactDetails';
import phone from '../../assets/logo/phone.svg';
import message from '../../assets/logo/message-action.svg';
import mail from '../../assets/logo/mail.svg';
import bin from '../../assets/logo/bin.svg';
import edit from '../../assets/logo/edit.svg';
import { contacts } from '../../assets/nav-icons';

const ContactDetails = () => {
  const dropRef = useRef(null);
  const addref = useRef(null);
  const [showOpportunities, setshowOpportunities] = useState(false);
  const [showAccounts, setshowAccounts] = useState(false);
  const [addQuickAccount, setAddQuickAccount] = useState('closed');
  const [quickDrop, setQuickDrop] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [addModalDropDown, setAddModalDropDown] = useState(false);
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const [addQuickPolicy, setAddQuickPolicy] = useState('closed');
  const [addQuickQuotes, setAddQuickQuotes] = useState('closed');
  const [addQuickDocument, setAddQuickDocument] = useState('closed');
  const [showTooltip, setShowToolTip] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [longOpportunityForm, setLongOpportunityForm] = useState('closed');

  useOutsideClick(dropRef, setshowOpportunities);
  const accountRef = useRef(null);
  useOutsideClick(accountRef, setshowAccounts);
  const editRef = useRef(null);
  useOutsideClick(editRef, setQuickDrop);
  useOutsideClick(addref, setAddModalDropDown);
  const dispatch = useDispatch<AppDispatch>();

  const loadingStatus = useSelector(
    (state: { opportunities: ContactsSliceState }) => state.opportunities.status
  );
  const contactDetails = useSelector(selectContact);
  const { uuid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getContact({
          uuid: uuid || '',
          successCB: () => {},
        })
      );
    }
    if (uuid) dispatch(getCount({ uuid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteContact({
        uuid,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
            navigate('/mybook/contacts');
          }, 2000);
        },
      })
    );
  };

  return (
    <DetailsDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <div className='content-section'>
        <div className='content-div d-none d-xl-block'>
          <TopBar>
            <div className='breadcrumb-div'>
              <span
                onClick={() => navigate('/mybook/contacts')}
                className='breadcrumb-item'
              >
                Contacts /
              </span>
              {contactDetails?.firstName} {contactDetails?.lastName}
            </div>
          </TopBar>

          <div className='header-div d-flex justify-content-between'>
            {showSuccess && (
              <ErrorCard
                message={`<b>${contactDetails?.firstName}</b> has been <b> deleted</b> `}
                title={'Success deleting contact.'}
              />
            )}
            <div className='d-flex align-items-center'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {contactDetails?.firstName?.charAt(0)}
                {contactDetails?.lastName?.charAt(0)}
              </div>
              <div>
                <div className=' text-overflow-container'>
                  <h2 className='mb-2'>
                    {contactDetails?.firstName} {contactDetails?.lastName}
                  </h2>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {contactDetails?.firstName} {contactDetails?.lastName}
                    </span>
                  </span>
                </div>
                <div className='header-info d-flex'>
                  <span
                    className='opportunities-div'
                    onClick={() => setshowOpportunities(!showOpportunities)}
                    ref={dropRef}
                  >
                    {contactDetails &&
                    contactDetails?.AccountContacts[0]?.Account?.Opportunities
                      .length > 0
                      ? contactDetails?.AccountContacts[0]?.Account
                          ?.Opportunities.length
                      : '0'}{' '}
                    Opportunities
                    {showOpportunities &&
                      contactDetails?.AccountContacts[0]?.Account
                        ?.Opportunities &&
                      contactDetails?.AccountContacts[0]?.Account?.Opportunities
                        ?.length > 0 && (
                        <span className='opportunties-dropdown'>
                          <span className='opportunity-count'>
                            {
                              contactDetails?.AccountContacts[0]?.Account
                                ?.Opportunities?.length
                            }{' '}
                            OPPORTUNITIES
                          </span>
                          {contactDetails?.AccountContacts[0]?.Account?.Opportunities.map(
                            (item) => (
                              <div
                                className='opportunity-item'
                                key={item.uuid}
                                onClick={() =>
                                  navigate(`/opportunities/${item.uuid}`)
                                }
                              >
                                {item.name ? item.name : '---'}
                              </div>
                            )
                          )}
                        </span>
                      )}
                  </span>
                  <span className='opportunities-div d-flex'>
                    {contactDetails &&
                      contactDetails?.AccountContacts?.length > 0 && (
                        <span className='d-flex align-items-center  text-overflow-container'>
                          <img src={contacts} alt='' className='contact-img' />
                          Account:{' '}
                          <span
                            className='nav-decor '
                            onClick={() =>
                              navigate(
                                `/account/${contactDetails.AccountContacts[0].Account.uuid}`
                              )
                            }
                          >
                            {contactDetails?.AccountContacts[0]?.Account?.name}
                          </span>
                          <span className='tooltiptext'>
                            <span className='tooltipInnertext'>
                              {
                                contactDetails?.AccountContacts[0]?.Account
                                  ?.name
                              }
                            </span>
                          </span>
                          {contactDetails?.AccountContacts.length > 1 && (
                            <span
                              className='count-div'
                              onClick={() => setshowAccounts(!showAccounts)}
                              ref={accountRef}
                            >
                              +{contactDetails?.AccountContacts.length - 1}
                            </span>
                          )}
                        </span>
                      )}
                    {contactDetails &&
                      contactDetails?.AccountContacts?.length <= 0 && (
                        <span className='d-flex align-items-center text-overflow-container'>
                          <img src={contacts} alt='' className='contact-img' />
                          Account:{' '}
                          <span
                            className='nav-decor '
                            onClick={() =>
                              navigate(`/contacts/${uuid}/accounts`)
                            }
                          >
                            {'No account connected'}
                          </span>
                          <span className='tooltiptext'>
                            <span className='tooltipInnertext'>
                              {'Click here to add account'}
                            </span>
                          </span>
                        </span>
                      )}
                    {showAccounts &&
                      contactDetails &&
                      contactDetails?.AccountContacts?.length > 1 && (
                        <span className='opportunties-dropdown'>
                          <span className='opportunity-count'>
                            {contactDetails?.AccountContacts.length - 1}
                            Accounts
                          </span>
                          {contactDetails?.AccountContacts.slice(1).map(
                            (item) => (
                              <div
                                className='opportunity-item'
                                key={item.Account?.uuid}
                                onClick={() =>
                                  navigate(`/account/${item.Account?.uuid}`)
                                }
                              >
                                {item.Account?.name
                                  ? item.Account?.name
                                  : '---'}
                              </div>
                            )
                          )}
                        </span>
                      )}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='text-overflow-container'>
                  <div
                    className='action-item'
                    onClick={() => {
                      if (contactDetails) {
                        navigator.clipboard.writeText(
                          contactDetails.ContactDetails.find(
                            (item: any) => item.type === 'PHONENUMBER'
                          )?.value || ''
                        );
                        setShowToolTip(true);
                        setTimeout(() => {
                          setShowToolTip(false);
                        }, 500);
                      }
                    }}
                  >
                    <img src={phone} alt='phone' />
                  </div>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {contactDetails?.ContactDetails.find(
                        (item: any) => item.type === 'PHONENUMBER'
                      )?.value || ''}
                    </span>
                  </span>
                </span>
                <span className='text-overflow-container'>
                  <div
                    className='action-item'
                    onClick={() => {
                      if (contactDetails) {
                        navigator.clipboard.writeText(
                          contactDetails.ContactDetails.find(
                            (item: any) => item.type === 'EMAIL'
                          )?.value || ''
                        );
                        setShowToolTip(true);
                        setTimeout(() => {
                          setShowToolTip(false);
                        }, 500);
                      }
                    }}
                  >
                    <img src={mail} alt='mail' />
                  </div>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {contactDetails?.ContactDetails.find(
                        (item: any) => item.type === 'EMAIL'
                      )?.value || ''}
                    </span>
                  </span>
                </span>
                <span className='text-overflow-container'>
                  <div
                    className='action-item'
                    onClick={() => {
                      if (contactDetails) {
                        navigator.clipboard.writeText(
                          contactDetails.ContactDetails.find(
                            (item: any) => item.type === 'PHONENUMBER'
                          )?.value || ''
                        );
                        setShowToolTip(true);
                        setTimeout(() => {
                          setShowToolTip(false);
                        }, 500);
                      }
                    }}
                  >
                    <img src={message} alt='message' />
                    {showTooltip && (
                      <span className='tooltiptext'>
                        {' '}
                        <b>copied to clipboard</b>
                      </span>
                    )}
                  </div>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {contactDetails?.ContactDetails.find(
                        (item: any) => item.type === 'PHONENUMBER'
                      )?.value || ''}
                    </span>
                  </span>
                </span>
                <button
                  className='primaryButton'
                  onClick={() => setAddModalDropDown(!addModalDropDown)}
                  ref={addref}
                >
                  Add
                  <img className='dropImg' src={dropdown} alt='' />
                  {addModalDropDown && (
                    <div className='addDropdown-div'>
                      <div
                        className='Add-div'
                        onClick={() => {
                          setAddQuickOpportunity('open');
                          setAddModalDropDown(false);
                        }}
                      >
                        Add Opportunity
                      </div>
                      <div
                        className='Add-div'
                        onClick={() => {
                          setAddQuickQuotes('open');
                          setAddModalDropDown(false);
                        }}
                      >
                        Add Quote
                      </div>
                      <div
                        className='Add-div'
                        onClick={() => {
                          setAddQuickPolicy('open');
                          setAddModalDropDown(false);
                        }}
                      >
                        Add Policy
                      </div>
                      <div
                        className='Add-div'
                        onClick={() => {
                          setAddQuickDocument('open');
                          setAddModalDropDown(false);
                        }}
                      >
                        Add Document
                      </div>
                      <div
                        className='Add-div'
                        onClick={() => {
                          setAddQuickAccount('open');
                          setAddModalDropDown(false);
                        }}
                      >
                        Add Account
                      </div>
                    </div>
                  )}
                </button>
                <button
                  className='dropDownButton'
                  onClick={() => setQuickDrop(!quickDrop)}
                  ref={editRef}
                >
                  <img src={dropdown} alt='' />
                  {quickDrop && (
                    <div className='dropdown-div'>
                      <div
                        className='edit-div'
                        onClick={() => {
                          setShowEditModal('open');
                          setQuickDrop(false);
                        }}
                      >
                        Edit <img src={edit} alt='bin' />
                      </div>
                      <div className='borderDiv'></div>
                      <div
                        className='edit-div'
                        onClick={() => {
                          setShowDeleteModal('open');
                          setQuickDrop(false);
                        }}
                      >
                        Delete <img src={bin} alt='bin' />
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className='d-flex' style={{ borderBottom: '1px solid #E6E9F0' }}>
            {contactDetails && (
              <ContactDetailsDrawer
                contactDetails={contactDetails}
                setShowEditModal={setShowEditModal}
              />
            )}
            <div className='options-div'>
              <ActivitiesTab name='Contact' />
            </div>
          </div>
        </div>
        {contactDetails && showEditModal !== 'closed' && (
          <EditContactDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={contactDetails?.uuid}
          />
        )}
        {addQuickAccount !== 'closed' && (
          <QuickAccountDrawer
            show={addQuickAccount}
            closeModal={setAddQuickAccount}
            InnerTab={true}
            contactDetails={contactDetails}
            primaryExist={
              contactDetails?.AccountContacts?.find(
                (item) => item?.Account?.AccountType?.name === 'PERSONAL'
              )
                ? true
                : false
            }
          />
        )}
        {showDeleteModal !== 'closed' && contactDetails && (
          <DeleteModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deleteFunction={onDelete}
            uuid={contactDetails.uuid}
            loading={loadingStatus}
            resource='contact'
            name={
              contactDetails?.firstName ? contactDetails?.firstName : 'unknown'
            }
          />
        )}
        {addQuickOpportunity !== 'closed' && (
          <QuickAddOpportunityModal
            show={addQuickOpportunity}
            closeModal={setAddQuickOpportunity}
            isInnerTab={true}
            name={'Contact'}
            contactDetails={contactDetails}
            setLongOpportunityForm={setLongOpportunityForm}
          />
        )}
        {longOpportunityForm !== 'closed' && (
          <AddOpportunityDrawer
            show={longOpportunityForm}
            closeModal={setLongOpportunityForm}
          />
        )}
        {addQuickQuotes !== 'closed' && (
          <AddQuoteModal
            show={addQuickQuotes}
            closeModal={setAddQuickQuotes}
            type={'Account'}
          />
        )}
        {addQuickPolicy !== 'closed' && contactDetails && (
          <PolicyModal
            show={addQuickPolicy}
            closeModal={setAddQuickPolicy}
            innerTabName={'Contact'}
            name={contactDetails?.firstName + ' ' + contactDetails?.lastName}
            contactDetails={contactDetails}
          />
        )}
        {addQuickDocument !== 'closed' && contactDetails && (
          <AddDocumentsModal
            show={addQuickDocument}
            closeModal={setAddQuickDocument}
            uuid={contactDetails?.uuid}
            name={'Contact'}
          />
        )}
        <div className='d-xl-none'>
          <MobileContactDetails
            setShowEditModal={setShowEditModal}
            contactDetails={contactDetails}
            setAddQuickAccount={setAddQuickAccount}
            setShowDeleteModal={setShowDeleteModal}
            setAddQuickOpportunity={setAddQuickOpportunity}
            setAddQuickPolicy={setAddQuickPolicy}
            setAddQuickQuotes={setAddQuickQuotes}
            setAddQuickDocument={setAddQuickDocument}
          />
        </div>
      </div>
    </DetailsDiv>
  );
};

export default ContactDetails;

export const transformAgentDetailResponse = (data: any) => {
  const agentData = data?.agency;
  if (!agentData) {
    return {};
  }
  return {
    name: agentData.name ?? '',
    email: agentData.email ?? '',
    officePhoneNumber: agentData.phoneNumber ?? '',
    mobilePhoneNumber: agentData.mobilePhoneNumber ?? '',
    agencyType: agentData.AgencyType
      ? {
          label: agentData.AgencyType?.name,
          value: agentData.AgencyType?.uuid,
        }
      : null,
    websiteUrl: agentData.websiteUrl ?? '',
    youtubeUrl: agentData.youtubeUrl ?? '',
    instagramUrl: agentData.instagramUrl ?? '',
    facebookUrl: agentData.facebookUrl ?? '',
    twitterUrl: agentData.twitterUrl ?? '',
    linkedInUrl: agentData.linkedInUrl ?? '',
    faxNumber: agentData.faxNumber ?? '',
  };
};

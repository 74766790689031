import { IGoals } from '../interfaces/Goals';
import axios from './axios';

const getGoals = (
  selectedMonthDate: string,
  agencyUUID?: string,
  agentUUID?: string
) => {
  return axios
    .get(`/goal/selectedMonth`, {
      params: {
        selectedMonthDate,
        agencyUUID,
        agentUUID,
      },
    })
    .then((res) => {
      return res;
    });
};

const getAgencyGoal = (
  goalType: string,
  duration: string,
  agencyUUID?: string
) => {
  return axios
    .get(`/goal/dashboard/agencyGoal`, {
      params: {
        goalType,
        duration,
        agencyUUID,
      },
    })
    .then((res) => {
      return res;
    });
};

const getUserGoals = (
  limit: number,
  offset: number,
  duration: string,
  agencyUUID?: string,
  sortBy?: string,
  orderBy?: string
) => {
  return axios
    .get(`/goal/dashboard/leaderboard`, {
      params: {
        limit,
        offset,
        duration,
        agencyUUID,
        sortBy,
        orderBy,
      },
    })
    .then((res) => {
      return res;
    });
};
const getDefaultGoals = (successCB: Function) => {
  return axios.get(`/goal/defaultGoal`, {}).then((res) => {
    successCB(res.data);
    return res;
  });
};

const updateGoals = (data: IGoals) => {
  return axios.patch(`goal/selectedMonth`, data).then((res) => {
    return res;
  });
};

export { getGoals, updateGoals, getDefaultGoals, getAgencyGoal, getUserGoals };

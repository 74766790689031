import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { StyledLicensedStates } from '../styles';
import { FormModal } from '../../../../styles/FormModal';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import { fetchJurisdiction } from '../../../../api/jurisdiction';
import { IJurisdiction } from '../../../../interfaces/Jurisdiction';
import { updateAgencyInfo } from '../../../../store/agencies/agenciesSlice';
import { AppDispatch } from '../../../../store';
import search from '../../../../assets/logo/search.svg';

const LicensedStateModal = ({
  show,
  closeModal,
  selectedJurisdiction,
  setSelectedJurisdiction,
  UUID,
}: {
  show: string;
  closeModal: Function;
  selectedJurisdiction: any;
  setSelectedJurisdiction: any;
  UUID: string;
}) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [searchBy, setSearchBy] = useState('');

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = (value: any) => {
    let data = {
      action: 'updateLicensedStates',
      jurisdictionUUID: selectedJurisdiction.map((item: any) => {
        return { uuid: item.uuid };
      }),
    };
    dispatch(
      updateAgencyInfo({
        uuid: UUID,
        data: data,
        successCB: () => {
          closeModal('closing');
          setTimeout(() => {}, 300);
          setTimeout(() => {
            reset();
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 4000);
        },
        errorCB: () => {},
      })
    );
  };

  useEffect(() => {
    fetchJurisdiction('', '', searchBy, (data: IJurisdiction[]) => {
      setLicensedState(data);
    });
  }, [searchBy]);

  const handleCheck = (e: any, jurisdiction: IJurisdiction) => {
    let updatedJurisdictions = [...selectedJurisdiction];
    if (e.target.checked) {
      updatedJurisdictions.push(jurisdiction);
    } else {
      let tempVar = updatedJurisdictions.findIndex(
        (item) => item.uuid === jurisdiction.uuid
      );

      updatedJurisdictions.splice(tempVar, 1);
    }
    setSelectedJurisdiction(updatedJurisdictions);
  };

  return (
    <StyledLicensedStates>
      <FormModal open={show} align='right'>
        <div className='entireDiv'>
          <div className='topDiv'>
            <div>
              <h2>Add licensed states</h2>
            </div>
            <div>
              <button className='crossbtn'>
                <img
                  className='cross'
                  src={cross}
                  alt='cross-icon'
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                />
              </button>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='scrollBar'>
              <div className='d-flex '>
                <div className=' mobilecardDiv'>
                  <h2 className='HeadNavigator'></h2>
                  <div className='d-flex'>
                    <div className='d-flex search-Field '>
                      <img className='mx-2' src={search} alt='' />
                      <input
                        className='inputSearchField mx-2'
                        type='text'
                        placeholder='Search and select states'
                        onChange={(e) => {
                          // setPage(0);
                          setSearchBy(e.target.value);
                        }}
                        autoFocus
                        value={searchBy}
                      />
                    </div>
                  </div>
                  <div className='d-block d-sm-none selectStatus'>
                    <b>
                      {selectedJurisdiction.length} of {licensedState.length}
                    </b>{' '}
                    States selected
                  </div>
                </div>
                <div className='d-none d-sm-block selecteStatus'>
                  <b>
                    {selectedJurisdiction.length} of {licensedState.length}
                  </b>{' '}
                  States selected
                </div>
              </div>
              <div className='d-flex flex-wrap licensedDiv'>
                {licensedState?.map((item: any) => {
                  return (
                    <div className='fields'>
                      <label className='radio-container location-input-container'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          name='licensedStates'
                          checked={selectedJurisdiction
                            .map(
                              (jurisdiction: IJurisdiction) => jurisdiction.uuid
                            )
                            .includes(item.uuid)}
                          onChange={(e) => handleCheck(e, item)}
                        />
                        <span>{item.name}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='buttonsdiv d-flex align-items-center '>
              <button
                onClick={() => {
                  reset();
                }}
                className='cancel-button'
                type='button'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='agentButton'
                // onClick={() => setSubmit(true)}
              >
                Save <img className='tick' src={tick} alt='tick-sign' />
              </button>
            </div>
          </form>
        </div>
      </FormModal>
    </StyledLicensedStates>
  );
};

export default LicensedStateModal;

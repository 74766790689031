import styled from '@emotion/styled';

export const FieldDiv = styled.div`
  display: flex;
  height: 60px;
  position: relative;

  .input-container {
    position: relative;
    border-radius: 8px;
    background: #ffffff;

    label {
      position: absolute;
      color: #5a5f70;
      pointer-events: none;
      font-size: 12px;
      left: 10px;
      top: 4px;
      text-transform: capitalize;
    }
    .editIcon {
      opacity: 0;
      position: absolute;
      color: #191919;
      pointer-events: none;
      right: 16px;
      padding: 25px 10px;
    }
    .prefix-style {
      position: absolute;
      color: #8990a3;
      left: 16px;
      pointer-events: none;
      bottom: 11px;
    }
    .monthStyle {
      display: block;
      position: absolute;
      right: 68px;
      pointer-events: none;
      font-weight: 600;
      color: #000;
      top: 28px;
    }
  }
  .input-style {
    .input-field {
      padding: 28px 16px 16px 28px;
    }
  }
  .input-field {
    width: 100%;
    height: 100%;
    padding: 26px 50px 4px 10px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 600;
    :disabled {
      background: #f5f7fc !important;
    }
  }

  :focus-within {
    .input-field {
      background: #f5f7fc !important;
      border: none;
    }
    .editIcon {
      display: none;
    }
    .monthStyle {
      top: 28px;
      right: 65px;
    }
  }
  .input-field:hover {
    background: #f5f7fc !important;
  }
  .input-container:hover {
    .editIcon {
      opacity: 1;
    }
  }
  label {
    font-size: 12px;
  }
  .error-field {
    border-radius: 8px;
    border: 1px solid #cc0000;
  }
  @media screen and (min-width: 1900px) {
    height: 64px;
  }

  @media screen and (min-width: 2800px) {
    height: 128px;
    .input-container {
      border-radius: 16px;
    }
    label {
      left: 40px;
      top: 8px;
      font-size: 24px;
    }
  }
`;

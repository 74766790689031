import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateNotificationSettings } from '../../api/notifications';
import {
  getUserDetails,
  getAgentDetails,
  updateAgentProfile,
  updateAgentPassword,
} from '../../api/user';
import { INotification } from '../../interfaces/Notifications';
import { IUser } from '../../interfaces/User';
import LocalStorageService from '../../utils/LocalStorageService';

export type SliceState = {
  status: string;
  user?: IUser;
  agent?: IUser;
};

const initialState: SliceState = {
  status: 'idle',
  user: undefined,
  agent: undefined,
};

export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ successCB }: { successCB: Function }) => {
    const response = await getUserDetails();
    if (successCB) {
      successCB(response.data.user);
    }
    return response.data;
  }
);

export const fetchAnAgent = createAsyncThunk(
  'agents/fetchAnAgent',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await getAgentDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

export const agentProfileUpdate = createAsyncThunk(
  'agents/agentProfileUpdate',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
    action,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
    errorCB: Function;
    action: string;
  }) => {
    const response = await updateAgentProfile(
      uuid,
      data,
      successCB,
      errorCB,
      action
    );
    return response.data;
  }
);

export const agentProfilePasswordUpdate = createAsyncThunk(
  'agents/agentProfilePasswordUpdate',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await updateAgentPassword(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const notificationSettingsUpdate = createAsyncThunk(
  'notification/notificationSettingsUpdate',
  async ({ data, successCB }: { data: INotification; successCB: Function }) => {
    const response = await UpdateNotificationSettings(data);
    successCB(response.data);
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    clearUser: (state) => {
      state.user = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        LocalStorageService.setUser(JSON.stringify(state.user));
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchAnAgent.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchAnAgent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAnAgent.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(agentProfileUpdate.fulfilled, (state, action) => {
        state.agent = action.payload;
        if (state.user && action.payload.User.avatar) {
          state.user = { ...state.user, avatar: action.payload.User.avatar };
          LocalStorageService.setUser(JSON.stringify(state.user));
        }
        state.status = 'succeeded';
      })
      .addCase(agentProfileUpdate.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(agentProfileUpdate.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(agentProfilePasswordUpdate.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.status = 'succeeded';
      })
      .addCase(agentProfilePasswordUpdate.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(agentProfilePasswordUpdate.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(notificationSettingsUpdate.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(notificationSettingsUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.user && action.payload) {
          state.user = {
            ...state.user,
            NotificationSettings: action.payload.notificationSettings,
          };
          LocalStorageService.setUser(JSON.stringify(state.user));
        }
      })
      .addCase(notificationSettingsUpdate.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, clearUser } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: { user: SliceState }) => {
  return state.user.user;
};
export const selectAgent = (state: { user: SliceState }) => {
  return state.user.agent;
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSearchResuls } from '../../api/search';
import { ISearchResult } from '../../interfaces/SearchResult';

export type SearchSliceState = {
  status: string;
  results: ISearchResult[];
  allResults: {
    lead: [];
    contact: [];
    account: [];
    opportunity: [];
    policy: [];
    task: [];
  };
};

interface IProps {
  types: string[];
  searchBy: string;
  limit: number;
  offset: number;
}

const initialState: SearchSliceState = {
  status: 'idle',
  results: [],
  allResults: {
    contact: [],
    account: [],
    opportunity: [],
    policy: [],
    task: [],
    lead: [],
  },
};

export const getSearchResults = createAsyncThunk(
  'search/getSearchResults',
  async ({ types, searchBy, limit, offset }: IProps) => {
    const response = await fetchSearchResuls(types, searchBy, limit, offset);
    return response.data;
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    clearSearchResults: (state) => {
      state.results = [];
      state.allResults = {
        contact: [],
        account: [],
        opportunity: [],
        policy: [],
        task: [],
        lead: [],
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSearchResults.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSearchResults.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.results = action.payload.reduce((t: any, o: any) => {
          t = [...t, ...o.hits];
          return t;
        }, []);
        state.allResults = action.payload.reduce((t: any, o: any) => {
          t[o.model] = o.hits;
          return t;
        }, {});
      })
      .addCase(getSearchResults.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, clearSearchResults } = searchSlice.actions;

export default searchSlice.reducer;

export const selectResults = (state: { search: SearchSliceState }) =>
  state.search?.results;

export const selectAllResults = (state: { search: SearchSliceState }) =>
  state.search?.allResults;

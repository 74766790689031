import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getOpportunities,
  onDeleteOpportunity,
  OpportunitiesSliceState,
  selectAllOpportunities,
} from '../../../store/opportunities/opportunitiesSlice';
import {
  getCount,
  selectCount,
  updateCount,
} from '../../../store/book/bookSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import Link from '../../../components/Link';
import AddOpportunityDrawer from '../../../components/AddOpportunityDrawer';
import EmptyResults from '../components/Empty';
import { StyledTab } from '../../../styles/StyledTab';
import OpportunityMobileCard from './components/OpportunityMobileCard';
import PaginationComponent from '../../../components/PaginationComponent';
import MobileTab from '../../../components/MobileTab';
import Table from '../../../components/Table';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { IOption } from '../../../interfaces/Opportunity';
import { FilterContainer, MyBookDiv } from '../elements/styles';
import plus from '../../../assets/logo/plus.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import search from '../../../assets/logo/search.svg';
import filter from '../../../assets/logo/filter.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import close from '../../../assets/logo/cross.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import AddQuoteModal from '../../../components/AddQuoteModal';
import OpportunityFilter from './components/FilterDrawer';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import EditOpportunityDetails from '../../OpportunityDetails/EditOpportunityDetails';
import blackplus from '../../../assets/logo/blackplus.svg';

const Opportunities = () => {
  const tabRef = useRef(null);
  const searchRef = useRef(null);

  function getWidth(width: number) {
    if (window.innerWidth < 1900) return width;
    return (width * 70) / 56;
  }

  const dispatch = useDispatch<AppDispatch>();
  const opportunities = useSelector(selectAllOpportunities);

  const count = useSelector(selectCount);
  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.status
  );
  const oppCount = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.count
  );

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showOpportunity, setShowOpportunity] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [showFilter, setShowFilter] = useState('closed');
  const [selectedOppStatus, setOppStatus] = useState<IOption[]>([]);
  const [selectedProducts, setProducts] = useState<IOption[]>([]);
  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const [scroll, setScroll] = useState(false);
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const [producer, setProducer] = useState('');
  const [pipeline, setPipeline] = useState<IOption[]>([]);
  const [account, setAccount] = useState('');

  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getOpportunities({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          pipelineUUID: pipeline?.map((item) => item.value),
          opportunityStatusUUID: selectedOppStatus?.map((item) => item.value),
          productCategoryUUID: '',
          pipelineStageUUID: [],
          accountUUID: account,
          producerUUID: producer,
          productUUID: selectedProducts
            ?.filter((item) => item.type === 'PRODUCT')
            .map((item: any) => item.value),
          customProductUUID: selectedProducts
            ?.filter((item) => item.type === 'CUSTOM_PRODUCT')
            .map((item: any) => item.value),
          successCB: (data: any) => {
            dispatch(
              updateCount({ type: 'opportunitiesCount', count: data.count })
            );
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    sortBy,
    orderBy,
    debouncedSearchTerm,
    page,
    limit,
    selectedProducts,
    producer,
    selectedOppStatus,
    pipeline,
    account,
  ]);

  useOutsideClick(tabRef, setShowDropdown, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const [producerSearch, setProducerSearch] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteOpportunity({
        uuid,
        successCB: () => {
          if (opportunities.length === 1) {
            setPage(0);
          }
          dispatch(getCount());
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <MyBookDiv>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting opportunity.'}
        />
      )}
      <button
        onClick={() => setShowOpportunity('open')}
        className='addButton d-sm-none'
      >
        <img src={plus} alt='add' />
      </button>
      <div className='d-sm-none mb-4'>
        <StyledTab ref={tabRef}>
          <Link
            to='/mybook/opportunities'
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            Opportunities<span className='count'>{count.opportunities}</span>
            <img src={dropdown} alt='' />
          </Link>{' '}
          {showDropdown && <MobileTab />}
        </StyledTab>
      </div>

      <div className='d-flex justify-content-between'>
        <button
          className='addButton d-none d-sm-block'
          onClick={() => setShowOpportunity('open')}
          disabled={showOpportunity !== 'closed'}
        >
          Add opportunity
        </button>
        <div className='book-filter d-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setPage(0);
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {(producer ? 1 : 0) +
              pipeline.length +
              selectedProducts.length +
              selectedOppStatus.length +
              (account ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(producer ? 1 : 0) +
                  pipeline.length +
                  selectedProducts.length +
                  selectedOppStatus.length +
                  (account ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {(producer ? 1 : 0) +
              pipeline.length +
              selectedProducts.length +
              selectedOppStatus.length +
              (account ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(producer ? 1 : 0) +
                  pipeline.length +
                  selectedProducts.length +
                  selectedOppStatus.length +
                  (account ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && opportunities.length > 0 ? (
            <>
              {opportunities?.map((item: IOpportunity) => (
                <OpportunityMobileCard
                  opportunity={item}
                  key={item?.uuid}
                  setShowDeleteModal={setShowDeleteModal}
                  setUUID={setUUID}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={oppCount}
              />
            </>
          ) : (
            <EmptyResults name='opportunity' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            setScroll={() => {
              setScroll(!scroll);
            }}
            page={page}
            setPage={setPage}
            count={oppCount}
            className='mybook-table'
            loadingStatus={loadingStatus}
            name='opportunity'
            columns={[
              {
                accessor: 'name',
                minWidth: 188,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Opportunity name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: any) => {
                  return (
                    <div className='row-name d-flex align-items-center text-overflow-container'>
                      <b
                        onClick={() => {
                          navigate(`/opportunities/${row.original.uuid}`);
                        }}
                        className='overflowed-text'
                      >
                        {row.original.name ? row.original.name : '--'}
                      </b>
                      <span className='tooltiptext tooltip-producer'>
                        <span className='tooltipInnertext'>
                          {row.original.name ? row.original.name : '--'}
                        </span>
                      </span>
                    </div>
                  );
                },
              },
              {
                accessor: 'account',
                Header: 'Account name',
                minWidth: 135,
                enableResizing: true,
                Cell: ({ row }: { row: any }) => (
                  <div className='text-overflow-container'>
                    <div className='overflowed-text '>
                      {row.original.Account.name}
                    </div>
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.Account.name}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'type',
                Header: 'Type',
                minWidth: 100,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row?.original.Account.AccountType.name.charAt(0) +
                      row?.original.Account.AccountType.name
                        .slice(1)
                        .toLowerCase()}
                  </>
                ),
              },
              {
                accessor: 'stage',
                Header: 'Stage',
                minWidth: 140,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.OpportunityStatus?.name
                      ?.charAt(0)
                      .toUpperCase() +
                      row.original.OpportunityStatus?.name?.slice(1)}
                  </>
                ),
              },
              {
                Header: 'Producer',
                id: 'producer',
                minWidth: 100,
                Cell: ({ row }: any) => {
                  return (
                    <div className='toolDiv info d-flex align-items-center h-100'>
                      {row.original.Agent?.User?.avatar ? (
                        <img
                          src={row.original.Agent?.User?.avatar}
                          alt=''
                          className='user-pic'
                        />
                      ) : (
                        <div className='user-pic'>
                          {row.original.Agent?.User?.firstName
                            ?.substring(0, 1)
                            .toUpperCase()}
                          {row.original.Agent?.User?.lastName
                            ?.substring(0, 1)
                            .toUpperCase()}
                        </div>
                      )}
                      <span className='tooltiptext tooltip-producer'>
                        <span className='tooltipInnertext'>
                          {row.original.Agent?.User?.firstName +
                            ' ' +
                            row.original.Agent?.User?.lastName}
                        </span>
                      </span>
                    </div>
                  );
                },
              },
              {
                Header: () => (
                  <div
                    onClick={() => handleSort('premium')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Premium</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'premium'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                accessor: 'premium',
                minWidth: 90,
                Cell: ({ row }: any) => {
                  return (
                    <b>
                      $
                      {row.original.leastPremium
                        ? row.original.leastPremium.toLocaleString('en-US')
                        : 0}
                    </b>
                  );
                },
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                sticky: 'right',
                width: getWidth(206),
                minWidth: getWidth(206),
                maxWidth: getWidth(206),
                Cell: ({ row }: any) => {
                  return (
                    <div className='d-flex align-items-center justify-content-between h-100'>
                      <div
                        className='actionButton'
                        onClick={() => {
                          setUUID(row.original.uuid);
                          setShowQuoteModal('open');
                        }}
                      >
                        Add quote
                      </div>
                      <ActionsDropdown
                        uuid={row.original.uuid}
                        setUUID={setUUID}
                        setShowEditModal={setShowEditModal}
                        setShowDeleteModal={setShowDeleteModal}
                        setName={setName}
                        scroll={scroll}
                        name={row.original.name}
                      />
                    </div>
                  );
                },
              },
            ]}
            data={opportunities}
          />
        </div>
      </div>
      {showOpportunity !== 'closed' && (
        <AddOpportunityDrawer
          show={showOpportunity}
          closeModal={setShowOpportunity}
        />
      )}
      {showEditModal !== 'closed' && (
        <EditOpportunityDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={UUID}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='opportunity'
          name={name}
        />
      )}
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Opportunity'
          opportunityUUID={UUID}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <OpportunityFilter
            show={showFilter}
            closeModal={setShowFilter}
            selectedOppStatus={selectedOppStatus}
            setProducts={setProducts}
            setOppStatus={setOppStatus}
            selectedProducts={selectedProducts}
            producer={producer}
            setProducer={setProducer}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            setPipeline={setPipeline}
            pipeline={pipeline}
            account={account}
            setAccount={setAccount}
            accountSearch={accountSearch}
            setAccountSearch={setAccountSearch}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Opportunities;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  setShowDeleteModal,
  setName,
  scroll,
  name,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  setShowDeleteModal: Function;
  setName: Function;
  scroll: boolean;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef<HTMLButtonElement>(null);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='action-dropContainer position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 130,
          }}
        >
          <div className='dropdown-div'>
            <div
              className='edit-div'
              onClick={() => {
                setShowEditModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
              }}
              tabIndex={0}
            >
              Edit <img src={edit} alt='bin' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='edit-div'
              style={{ paddingTop: '10px' }}
              onClick={() => navigate(`/opportunities/${uuid}/activities`)}
              tabIndex={1}
            >
              Add activity <img src={blackplus} alt='plusIcon' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
                setName(name);
              }}
              tabIndex={2}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

import Link from '../Link';
import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectCount } from '../../store/accounts/accountsSlice';
import { selectOpportunitiesCount } from '../../store/opportunities/opportunitiesSlice';
import { selectContactCount } from '../../store/contacts/contactsSlice';
import { selectPolicyCount } from '../../store/policies/policiesSlice';
const DetailsPageTabMobile = ({
  name,
  setActivity,
  setShowDropdown,
}: {
  name: string;
  setActivity: Function;
  setShowDropdown: Function;
}) => {
  const accountDetailsCount = useSelector(selectCount);
  const contactDetailsCount = useSelector(selectContactCount);
  const opportunityDetailsCount = useSelector(selectOpportunitiesCount);
  const PolicyCount = useSelector(selectPolicyCount);
  const { uuid } = useParams();
  return (
    <div className='mobile-tab '>
      {name === 'Contact' && (
        <>
          <Link
            to={`/contacts/${uuid}/activities`}
            onClick={() => {
              setActivity('activities');
              setShowDropdown(false);
            }}
          >
            Activities
            <span className='count'>{contactDetailsCount.activities}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/opportunities`}
            onClick={() => {
              setActivity('opportunities');
              setShowDropdown(false);
            }}
          >
            Opportunities
            <span className='count'>{contactDetailsCount.opportunities}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/accounts`}
            onClick={() => {
              setActivity('accounts');
              setShowDropdown(false);
            }}
          >
            Accounts
            <span className='count'>{contactDetailsCount.accounts}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/quotes`}
            onClick={() => {
              setActivity('quotes');
              setShowDropdown(false);
            }}
          >
            Quotes
            <span className='count'>{contactDetailsCount.quotes}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/policies`}
            onClick={() => {
              setActivity('policies');
              setShowDropdown(false);
            }}
          >
            Policies
            <span className='count'>{contactDetailsCount.policies}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/documents`}
            onClick={() => {
              setActivity('documents');
              setShowDropdown(false);
            }}
          >
            Documents
            <span className='count'>{contactDetailsCount.documents}</span>
          </Link>
          <Link
            to={`/contacts/${uuid}/histories`}
            onClick={() => {
              setActivity('histories');
              setShowDropdown(false);
            }}
          >
            History
          </Link>
        </>
      )}
      {name === 'Account' && (
        <>
          <Link
            to={`/account/${uuid}/activities`}
            onClick={() => {
              setActivity('activities');
              setShowDropdown(false);
            }}
          >
            Activities
            <span className='count'>{accountDetailsCount.activities}</span>
          </Link>
          <Link
            to={`/account/${uuid}/opportunities`}
            onClick={() => {
              setActivity('opportunities');
              setShowDropdown(false);
            }}
          >
            Opportunities
            <span className='count'>{accountDetailsCount.opportunities}</span>
          </Link>
          <Link
            to={`/account/${uuid}/quotes`}
            onClick={() => {
              setActivity('quotes');
              setShowDropdown(false);
            }}
          >
            Quotes
            <span className='count'>{accountDetailsCount.quotes}</span>
          </Link>
          <Link
            to={`/account/${uuid}/policies`}
            onClick={() => {
              setActivity('policies');
              setShowDropdown(false);
            }}
          >
            Policies
            <span className='count'>{accountDetailsCount.policies}</span>
          </Link>
          <Link
            to={`/account/${uuid}/documents`}
            onClick={() => {
              setActivity('documents');
              setShowDropdown(false);
            }}
          >
            Documents
            <span className='count'>{accountDetailsCount.documents}</span>
          </Link>
          <Link
            to={`/account/${uuid}/histories`}
            onClick={() => {
              setActivity('histories');
              setShowDropdown(false);
            }}
          >
            History
          </Link>
        </>
      )}
      {name === 'Opportunity' && (
        <>
          <Link
            to={`/opportunities/${uuid}/activities`}
            onClick={() => {
              setActivity('activities');
              setShowDropdown(false);
            }}
          >
            Activities
            <span className='count'>{opportunityDetailsCount.activities}</span>
          </Link>

          <Link
            to={`/opportunities/${uuid}/quotes`}
            onClick={() => {
              setActivity('quotes');
              setShowDropdown(false);
            }}
          >
            Quotes
            <span className='count'>{opportunityDetailsCount.quotes}</span>
          </Link>

          <Link
            to={`/opportunities/${uuid}/documents`}
            onClick={() => {
              setActivity('documents');
              setShowDropdown(false);
            }}
          >
            Documents
            <span className='count'>{opportunityDetailsCount.documents}</span>
          </Link>
          <Link
            to={`/opportunities/${uuid}/histories`}
            onClick={() => {
              setActivity('histories');
              setShowDropdown(false);
            }}
          >
            History
          </Link>
        </>
      )}
      {name === 'Policy' && (
        <>
          <Link
            to={`/policy/${uuid}/activities`}
            onClick={() => {
              setActivity('activities');
              setShowDropdown(false);
            }}
          >
            Activities
            <span className='count'>{PolicyCount.activities}</span>
          </Link>
          <Link
            to={`/policy/${uuid}/documents`}
            onClick={() => {
              setActivity('documents');
              setShowDropdown(false);
            }}
          >
            Documents
            <span className='count'>{PolicyCount.documents}</span>
          </Link>
          <Link
            to={`/policy/${uuid}/histories`}
            onClick={() => {
              setActivity('histories');
              setShowDropdown(false);
            }}
          >
            History
          </Link>
        </>
      )}
    </div>
  );
};

export default DetailsPageTabMobile;

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { YearMonthPicker } from '../DateField';
import { PickerDiv } from '../DateField/styles';
import calender from '../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';

export interface IProps {
  name: string;
  placeholder: string;
  values: any;
  onSetItem: Function;
  readOnly?: boolean;
  [x: string]: any;
  xdate?: boolean;
  required?: boolean;
}

const DateField = ({
  name,
  placeholder,
  values,
  onSetItem,
  xdate,
  readOnly,
  required,
  ...props
}: IProps) => {
  return (
    <div>
      <PickerDiv>
        <div
          className={`d-flex flex-column w-100 input-container ${
            values[name] && 'filled'
          } `}
        >
          <>
            {props.isTime ? (
              <DatePicker
                name={name}
                className='date-input'
                onChange={(date) => onSetItem(name, date)}
                selected={values[name] ? moment(values[name]).toDate() : null}
                dateFormat='h:mm aa'
                {...props}
                showTimeSelect
                onChangeRaw={(e) => e.preventDefault()}
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                {...props}
              />
            ) : (
              <DatePicker
                name={name}
                className='date-input'
                renderCustomHeader={({
                  date,
                  prevYearButtonDisabled,
                  nextYearButtonDisabled,
                  decreaseYear,
                  increaseYear,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <YearMonthPicker
                    date={date}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    prevYearButtonDisabled={prevYearButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                    nextYearButtonDisabled={nextYearButtonDisabled}
                    decreaseMonth={decreaseMonth}
                    decreaseYear={decreaseYear}
                    increaseMonth={increaseMonth}
                    increaseYear={increaseYear}
                  />
                )}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  if (date) {
                    onSetItem(
                      name,
                      new Date(
                        date?.getTime() - date?.getTimezoneOffset() * 60000
                      )
                    );
                  } else {
                    onSetItem(name, date);
                  }
                }}
                selected={values[name] ? moment(values[name]).toDate() : null}
                dateFormat={'MM/dd/yyyy'}
                readOnly={readOnly}
                {...props}
              />
            )}
          </>

          <label htmlFor={name} className='date-placeholder '>
            {placeholder}
            {required ? <span className='requiredStar'>*</span> : null}
          </label>

          <div className='CallenderImg'>
            <img src={calender} alt='Calender' />
          </div>
        </div>
      </PickerDiv>
    </div>
  );
};

export default DateField;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import {
  AccountsSliceState,
  getAccountDetails,
  selectAccount,
  getCount,
  onDeleteAccount,
} from '../../store/accounts/accountsSlice';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import DetailsDrawer from './components/AccountDetailsDrawer';
import MobileDetails from './components/MobileAccountDetails';
import DetailsPageTab from '../../components/DetailsPageTab';
import EditAccountDetails from './EditAccountDetails';
import DeleteModal from '../../components/DeleteModal';
import ErrorCard from '../Pipeline/components/ErrorCard';
import QuickAddOpportunityModal from '../../components/QuickAddOpportunity';
import AddQuoteModal from '../../components/AddQuoteModal';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import AddDocumentsModal from '../../components/DocumentsInnerTab/DocumentTable/components/AddDocumentsModal';
import OpportunityContactModal from '../../components/OppoortunityContactModal';
import ContactForm from '../../components/ContactModalDetailsPage/ContactModal';
import { DetailsDiv } from '../../styles/DetailsDiv';
import dropdown from '../../assets/logo/dropdown.svg';
import bin from '../../assets/logo/bin.svg';
import edit from '../../assets/logo/edit.svg';
import { contacts } from '../../assets/nav-icons';

const AccountDetails = () => {
  const dropRef = useRef(null);
  const editRef = useRef(null);
  const addref = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.status
  );
  const accountDetails = useSelector(selectAccount);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState('closed');
  const [addModalDropDown, setAddModalDropDown] = useState(false);
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const [addQuickPolicy, setAddQuickPolicy] = useState('closed');
  const [addQuickQuotes, setAddQuickQuotes] = useState('closed');
  const [addQuickDocument, setAddQuickDocument] = useState('closed');
  const [showOpportunities, setshowOpportunities] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showLinkcontactModal, setShowLinkContactModal] = useState('closed');
  const [showAddcontactModal, setShowAddContactModal] = useState('closed');
  const [quickDrop, setQuickDrop] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const isCustomer = !!accountDetails?.Policies?.length;

  useOutsideClick(editRef, setQuickDrop);
  useOutsideClick(addref, setAddModalDropDown);
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(getAccountDetails({ uuid, successCB() {} }));
      if (uuid) dispatch(getCount({ uuid }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useOutsideClick(dropRef, setshowOpportunities);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteAccount({
        uuid,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
            navigate('/mybook/accounts');
          }, 2000);
        },
      })
    );
  };

  return (
    <DetailsDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <div className='content-section'>
        <div className='content-div d-none d-xl-block'>
          <TopBar>
            <div className='breadcrumb-div'>
              <span
                onClick={() => navigate('/mybook/accounts')}
                className='breadcrumb-item'
              >
                Accounts /
              </span>
              {accountDetails?.name}
            </div>
          </TopBar>

          <div className='header-div d-flex justify-content-between'>
            {showSuccess && (
              <ErrorCard
                message={`<b>${accountDetails?.name}</b> has been <b> deleted</b> `}
                title={'Success deleting account.'}
              />
            )}
            <div className='d-flex align-items-center'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {accountDetails?.name
                  ?.split(' ')
                  .map((item: string) => item.substring(0, 1))
                  .join('')}
              </div>
              <div>
                <div className='d-flex align-items-center mb-2 text-overflow-container'>
                  <h2>{accountDetails?.name}</h2>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {accountDetails?.name}
                    </span>
                  </span>
                  {accountDetails && (
                    <div
                      className={`status ${
                        isCustomer ? 'statusActive' : 'statusInvited'
                      }`}
                    >
                      {isCustomer ? 'Customer' : 'Prospect'}
                    </div>
                  )}
                </div>

                <div className='header-info d-flex'>
                  <span
                    className='opportunities-div '
                    onClick={() => setshowOpportunities(!showOpportunities)}
                    ref={dropRef}
                  >
                    {accountDetails?.Opportunities?.length} Opportunities
                    {showOpportunities &&
                      accountDetails?.Opportunities &&
                      accountDetails?.Opportunities?.length > 0 && (
                        <span className='opportunties-dropdown'>
                          <span className='opportunity-count'>
                            {accountDetails?.Opportunities?.length}{' '}
                            OPPORTUNITIES
                          </span>
                          {accountDetails?.Opportunities.map((item) => (
                            <div
                              className='opportunity-item'
                              key={item.uuid}
                              onClick={() =>
                                navigate(`/opportunities/${item.uuid}`)
                              }
                            >
                              {item.name ? item.name : '---'}
                            </div>
                          ))}
                        </span>
                      )}
                  </span>
                  <span className='amount '>
                    $
                    {accountDetails?.premium
                      ? accountDetails?.premium.toLocaleString()
                      : 0}
                  </span>{' '}
                  {accountDetails?.AccountContacts && (
                    <span
                      className='d-flex align-items-center'
                      onClick={() =>
                        navigate(
                          `/contacts/${accountDetails.AccountContacts[0].Contact.uuid}`
                        )
                      }
                    >
                      <img src={contacts} alt='' className='contact-img' />
                      Contact:{' '}
                      <span className='text-overflow-container'>
                        <span className='nav-decor'>
                          {
                            accountDetails?.AccountContacts[0]?.Contact
                              .firstName
                          }{' '}
                          {accountDetails?.AccountContacts[0]?.Contact.lastName}
                        </span>
                        <span className='tooltiptext tooltip-producer'>
                          <span className='tooltipInnertext'>
                            {
                              accountDetails?.AccountContacts[0]?.Contact
                                .firstName
                            }{' '}
                            {
                              accountDetails?.AccountContacts[0]?.Contact
                                .lastName
                            }
                          </span>
                        </span>
                      </span>
                      {accountDetails?.AccountContacts.length > 1 && (
                        <span className='count-div'>
                          + {accountDetails?.AccountContacts.length - 1}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                className='primaryButton'
                onClick={() => setAddModalDropDown(!addModalDropDown)}
                ref={addref}
              >
                Add
                <img className='dropImg' src={dropdown} alt='' />
                {addModalDropDown && (
                  <div className='addDropdown-div'>
                    <div
                      className='Add-div'
                      onClick={() => {
                        setAddQuickOpportunity('open');
                        setAddModalDropDown(false);
                      }}
                    >
                      Add Opportunity
                    </div>
                    <div
                      className='Add-div'
                      onClick={() => {
                        setAddQuickQuotes('open');
                        setAddModalDropDown(false);
                      }}
                    >
                      Add Quote
                    </div>
                    <div
                      className='Add-div'
                      onClick={() => {
                        setAddQuickPolicy('open');
                        setAddModalDropDown(false);
                      }}
                    >
                      Add Policy
                    </div>
                    <div
                      className='Add-div'
                      onClick={() => {
                        setAddQuickDocument('open');
                        setAddModalDropDown(false);
                      }}
                    >
                      Add Document
                    </div>
                  </div>
                )}
              </button>

              <button
                className='dropDownButton'
                onClick={() => setQuickDrop(!quickDrop)}
                ref={editRef}
              >
                <img src={dropdown} alt='' />
                {quickDrop && (
                  <div className='dropdown-div'>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowEditModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Edit <img src={edit} alt='bin' />
                    </div>
                    <div className='borderDiv'></div>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowDeleteModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Delete <img src={bin} alt='bin' />
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className='d-flex' style={{ borderBottom: '1px solid #E6E9F0' }}>
            {accountDetails && (
              <DetailsDrawer
                accountDetails={accountDetails}
                setShowEditModal={setShowEditModal}
                setShowLinkContactModal={setShowLinkContactModal}
                setShowAddContactModal={setShowAddContactModal}
              />
            )}
            <div className='options-div'>
              <DetailsPageTab name='Account' />
            </div>
          </div>
        </div>
        <div className='d-xl-none'>
          <MobileDetails
            setShowEditModal={setShowEditModal}
            accountDetails={accountDetails}
            setShowDeleteModal={setShowDeleteModal}
            setAddQuickOpportunity={setAddQuickOpportunity}
            setAddQuickPolicy={setAddQuickPolicy}
            setAddQuickQuotes={setAddQuickQuotes}
            setAddQuickDocument={setAddQuickDocument}
            setShowLinkContactModal={setShowLinkContactModal}
            setShowAddContactModal={setShowAddContactModal}
          />
        </div>
        {accountDetails && showEditModal !== 'closed' && (
          <EditAccountDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={accountDetails.uuid}
          />
        )}
        {showDeleteModal !== 'closed' && accountDetails && (
          <DeleteModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deleteFunction={onDelete}
            uuid={accountDetails.uuid}
            loading={loadingStatus}
            resource='contact'
            name={accountDetails?.name ? accountDetails?.name : 'unknown'}
          />
        )}
        {addQuickOpportunity !== 'closed' && (
          <QuickAddOpportunityModal
            show={addQuickOpportunity}
            closeModal={setAddQuickOpportunity}
            isInnerTab={true}
            name={'Account'}
            accountType={accountDetails?.AccountType?.name}
            assosiated={accountDetails}
            contactDetails={accountDetails?.AccountContacts[0]?.Contact}
            setLongOpportunityForm={() => {}}
          />
        )}
        {addQuickQuotes !== 'closed' && (
          <AddQuoteModal
            show={addQuickQuotes}
            closeModal={setAddQuickQuotes}
            type={'Account'}
          />
        )}
        {addQuickPolicy !== 'closed' && accountDetails && (
          <PolicyModal
            show={addQuickPolicy}
            closeModal={setAddQuickPolicy}
            account={accountDetails}
            name={accountDetails?.name}
            accountType={accountDetails?.AccountType?.name}
            innerTabName={'Account'}
            primaryContact={
              accountDetails?.AccountContacts.filter(
                (item) => item?.primaryContact
              )[0]
            }
          />
        )}
        {addQuickDocument !== 'closed' && accountDetails && (
          <AddDocumentsModal
            show={addQuickDocument}
            closeModal={setAddQuickDocument}
            uuid={accountDetails?.uuid}
            name={'Account'}
          />
        )}
        {showAddcontactModal !== 'closed' && accountDetails && (
          <ContactForm
            uuid={accountDetails?.uuid}
            resource='Account'
            show={showAddcontactModal}
            closeModal={setShowAddContactModal}
          />
        )}
        {showLinkcontactModal !== 'closed' && accountDetails && (
          <OpportunityContactModal
            name='account'
            uuid={accountDetails?.uuid}
            accountUUID={accountDetails?.uuid}
            show={showLinkcontactModal}
            closeModal={setShowLinkContactModal}
          />
        )}
      </div>
    </DetailsDiv>
  );
};

export default AccountDetails;

import { useEffect, useState } from 'react';
import cross from '../../assets/logo/cross.svg';
import { StyledModal } from '../FilterDrawer/styles';
import { MobileFilterNavDiv } from '../FilterMobileDrawer/styles';
import Skeleton from 'react-loading-skeleton';
import FilterSelectField from '../FilterSelectField';
import Modal from '../Modal';
import { getDocumentType } from '../../api/documents';

const DocumentFilter = ({
  show,
  closeModal,
  documentType,
  setDocumentType,
}: {
  show: string;
  closeModal: Function;
  documentType: any;
  setDocumentType: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [type, setType] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  useEffect(() => {
    setLoadingStatus('loading');
    getDocumentType((data: any) => {
      setType(data);
      setLoadingStatus('succeeded');
    });
  }, []);
  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Document Type</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={type.map((item: any) => {
                return {
                  label:
                    item.name === 'RCE'
                      ? 'RCE'
                      : item.name?.charAt(0).toUpperCase() +
                        item.name?.toLowerCase().slice(1),
                  value: item.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setDocumentType(value);
              }}
              isMulti={true}
              value={documentType}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {documentType.length} filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setDocumentType([]);
                        }}
                        disabled={documentType.length === 0}
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {documentType.length} filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setDocumentType([]);
                    }}
                    disabled={documentType.length === 0}
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default DocumentFilter;

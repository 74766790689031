import styled from '@emotion/styled';

export const StyledTable = styled.div`
  border-radius: 8px;
  border: none;
  color: #262758;
  width: 100%;
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 45px;
      left: 0;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .table {
    display: inline-block;
    border-spacing: 0;
    margin-bottom: 0;
    background: #fff;
    padding-bottom: 50px;

    > :not(:first-of-type) {
      border-top: 0;
    }

    b {
      font-weight: 700;
    }

    .th,
    .td {
      position: relative;
      background: #fff;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 2px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #e6e9f0;
      }
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        width: 100%;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: inset 2px 0px 0px #f5f5f5;
      }
    }
  }
  .leadTableStyle {
    padding-bottom: 140px;
  }
  .nonleadDrop {
    padding-bottom: 100px;
  }
  .largeDropPadding {
    padding-bottom: 180px;
  }
  .carrierbatch-table {
    height: calc(100% - 155px);
  }

  .sort {
    cursor: pointer;
  }

  .tbody {
    background: #fff;
    max-height: 45vh;
    @media screen and (min-width: 1200px) {
      max-height: 52vh;
    }
    @media screen and (min-width: 1900px) {
      max-height: 60vh;
    }
  }

  .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }

  .thead {
    background: #f5f7fc;
    font-weight: 400;
    color: #262758;
    .tr {
      border-radius: 16px;
    }
    .th {
      background: #f5f7fc;
      font-weight: 500;
      color: #262758;
      font-size: 14px;
      padding: 14px 16px;
      box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
      height: 48px;

      :first-of-type {
        padding-right: 0;
        border-radius: 10px 0 0 0;
        box-shadow: none;
      }
    }
    .BorderedFirstColumnHead {
      box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
    }
    .carrierName {
      :first-of-type {
        box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
        padding-right: 16px;
      }
    }

    .th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
  .mybook-table .thead {
    .th {
      background: #f5f7fc;
      font-weight: 500;
      color: #262758;
      font-size: 14px;
      padding: 14px 16px;
      box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
      height: 48px;

      :first-of-type {
        padding: 14px 16px;
        box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
      }
    }
  }

  .td {
    padding: 0px 16px;
    outline: none;
    box-shadow: -1px -1px 0px 0px #f5f5f5 inset;
    border: 0;
    border-bottom: 1px solid #f5f5f5;
    height: 56px;
    line-height: 56px;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;

    :first-of-type {
      box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
      padding-right: 0;
    }
  }
  .skeletonLoading-td {
    line-height: 45px;
  }
  .mybook-table {
    .td:first-of-type {
      box-shadow: -1px -1px 0px 0px #f5f5f5 inset;
      padding: 0px 16px;
    }
    .td {
      overflow: unset;
    }
  }

  .actions {
    overflow: initial;

    :focus-within,
    :focus {
      z-index: 4 !important;
    }
  }

  .BorderedFirstColumn {
    padding: 0px 20px;
    box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
    border-bottom: 1px solid #e6e9f0;
  }
  .carrierName {
    :first-of-type {
      box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
      padding-right: 16px;
    }
  }
  .row-name {
    b {
      font-weight: 600;
      font-size: 14px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .row-name:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #3c498a;
    text-decoration-thickness: 2px;
  }
  .user-nameText:hover {
    text-decoration: none;
    cursor: initial;
  }

  .status {
    width: 102px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    border-radius: 16px;
    padding: 4px 20px;
    font-weight: 600;
    margin: 0 auto;
    line-height: 18px;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    text-align: center;
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .statusInactive {
    background: #e6e9f0;
    color: #191919;
  }
  .InactiveHead {
    color: #8990a3;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }
  .primaryName {
    margin-left: 8px;
  }
  .actionButton {
    background: #ecf4f9;
    text-align: center;
    border-radius: 8px;
    padding: 8px 0px;
    width: 128px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    line-height: 21px;
  }
  .activeActionButton {
    background: #e6e9f0;
    text-align: center;
    border-radius: 8px;
    color: #262758;
    border: none;
    padding: 8px 0px;
    width: 131px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    line-height: 21px;
    margin: 12px 16px;
  }
  .disabledActionButton {
    background: #ecf4f9;
    text-align: center;
    border-radius: 8px;
    color: #262758;
    border: none;
    padding: 8px 0px;
    width: 131px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    line-height: 21px;
    margin: 12px 16px;
  }
  .activeActionButton:disabled {
    background: #f5f7fc;
    color: #5c698c;
  }
  .Inactivebtn {
    background: #e6e9f0;
  }
  .Activebtn {
    background: #ecf4f9;
  }

  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    outline: none !important;
    border: 0;
  }

  .action-item {
    width: 32px;
    height: 32px;
    background: #ecf4f9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 10px;
    right: 0px;
    top: 30px;
    z-index: 4;
    width: 160px;
    cursor: pointer;
    line-height: 14px;
    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      padding: 2px 10px;
      display: flex;
      margin-top: 4px;
      justify-content: space-between;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .borderDiv {
      margin-left: 10px;
      width: 85%;
      padding: 0;
      border-top: 1px solid #e6e9f0;
    }
    .borderdiv {
      margin-left: 10px;
      width: 85%;
      padding: 0;
      border-top: 1px solid #e6e9f0;
      margin-bottom: 2px;
    }
    .edit-div {
      display: flex;
      justify-content: space-between;
      padding: 2px 10px;
      margin-bottom: 4px;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .junk-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  .onlyEdit-dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 8px 16px;
    right: 0px;
    bottom: -30px;
    z-index: 4;
    width: 144px;
    height: 30px;
    cursor: pointer;
    line-height: 14px;
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .drop-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 4px;
    right: 0px;
    top: 30px;
    z-index: 1;
    width: 194px;
    cursor: pointer;
    line-height: 14px;

    .deletediv {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-top: 2px;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }

    .editdiv {
      display: flex;
      justify-content: space-between;
      padding: 4px 10px;
      margin-bottom: 4px;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .borderDiv {
      margin-left: 8px;
      width: 90%;
      border-top: 1px solid #e6e9f0;
    }
    .junkdiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0px;
      padding: 4px 10px;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
  }

  .drop-Div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0px;
    top: 30px;
    z-index: 1;
    width: 200px;
    cursor: pointer;
    line-height: 14px;

    .agent-drop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-bottom: 4px;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .borderDiv {
      margin-left: 8px;
      width: 90%;
      border-top: 1px solid #e6e9f0;
    }

    .active-drop {
      color: #3d8a68;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-top: 4px;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .inactive-drop {
      color: #cc0000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-top: 4px;
      align-items: center;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
    .disableStyle {
      background: #f5f7fc;
      color: #5c698c;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-top: 4px;
      align-items: center;
      border-radius: 8px;
      cursor: not-allowed;
    }
  }

  .action-dropContainer {
    padding-bottom: 3px;
    position: absolute;
    border-radius: 8px;
    right: 0px;
    z-index: 1;
    top: 35px;
    .dropdown-div,
    .drop-div,
    .drop-Div {
      position: static;
      right: unset;
      bottom: unset;
    }
  }

  .user-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #262758;
    color: #f5f7fc;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .opportunity-div {
    font-size: 12px;
    color: #8990a3;
  }

  .opp-num {
    padding: 4px 16px;
    color: #262758;
    font-weight: 600;
    background: #dfe6f5;
    border-radius: 16px;
    margin-right: 8px;
  }

  .phone-div {
    position: relative;
    .contact-tooltip {
      display: none;
      position: absolute;
      padding: 20px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 4px 16px 0px #26275833;
      top: 43px;

      .action-item {
        margin-left: 16px;
      }

      .action-item:first-of-type {
        margin: 0;
      }
    }
    :hover {
      .contact-tooltip {
        display: flex;
        z-index: 1;

        :after {
          content: ' ';
          position: absolute;
          top: -10px;
          left: 10%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent white transparent;
        }
      }
    }
  }

  .count-div {
    padding: 8px 20px;
    background: #f5f7fc;
    font-size: 14px;
    font-weight: 600;
    border-radius: 32px;
    margin-left: 16px;
    line-height: 16px;
  }

  .userGroup {
    line-height: 20px;
  }

  .subGroup {
    font-size: 12px;
    color: #8990a3;
    line-height: 16px;
  }
  .progress-bar {
    height: 4px;
    width: 145px;
    position: relative;
    z-index: 0;
    top: 14px;
    background-color: #e6e9f0;
    overflow: hidden;
    border-radius: 63px;
  }

  .progress-bar > span {
    z-index: 50;
    display: block;
    height: 100%;
    background: #58c070;
    position: relative;
    overflow: hidden;
  }
  .status-Active {
    background: #d4fad4;
    color: #3d8a68;
  }

  .pagination {
    font-size: 14px;
    padding-top: 15px;
    button {
      background: none;
      border-radius: 8px;
      border: none;
      height: 32px;
      width: 32px;
      margin-right: 10px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        background: #ecf4f9;
      }
    }
    .nextButton {
      img {
        z-index: -1;
      }
      :hover {
        img {
          z-index: 1;
        }
      }
    }
    .active {
      background: #c1dbeb;
    }
  }

  .form-select {
    background-color: #e6e9f0;
    font-size: 14px;
    position: relative;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    margin-left: 10px;
    span {
      font-weight: 600;
    }
    .dropDownContainer {
      border-radius: 8px;
      box-shadow: 0px 1px 4px 0px #26275833;
      right: 1px;
      bottom: 35px;
      background: #fff;
      width: 96px;
      .spacingDiv {
        padding: 2px;
      }
      .options {
        margin: 0;
        padding: 4px 10px;
        cursor: pointer;
        :hover {
          border-radius: 8px;
          background: #f5f7fc;
        }
      }
    }
  }
  .document-table {
    height: 550px;
  }
  .QuoteName {
    cursor: pointer !important;
  }

  .active-limit {
    background: #ecf4f9;
    border-radius: 8px;
    font-weight: 600;
  }
  @media screen and (min-width: 1900px) {
    font-size: 16px;

    td {
      height: 64px;
    }
    .row-name {
      b {
        font-size: 16px;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 12px;
    }

    thead {
      th {
        font-size: 16px;
        padding: 16px;
        height: 56px;
      }
    }

    .status {
      width: 129px;
    }

    .actionButton {
      width: 175px;
    }

    .opportunity-div {
      font-size: 14px;
    }

    .opp-num {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1200px) {
    .carrierbatch-table {
      height: calc(100% - 70px);
    }
  }
`;

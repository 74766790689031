import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../store';
import {
  fetchAnAgent,
  agentProfileUpdate,
  SliceState,
  selectAgent,
} from '../../../store/user/userSlice';
import { getAllLocations } from '../../../store/location/locationSlice';
import LocalStorageService from '../../../utils/LocalStorageService';
import SelectField from '../../../components/SelectField';
import PhoneNumberInput from '../../../components/PhoneNumberFormat';
import InputField from '../../../components/InputField';
import SuccessCard from '../../../components/SuccessCard';
import { ILocation } from '../../../interfaces/Location';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import camera from '../../../assets/logo/camera.svg';
import plus from '../../../assets/logo/blackplus.svg';
import facebook from '../../../assets/logo/facebook.svg';
import twitter from '../../../assets/logo/twitter.svg';
import globe from '../../../assets/logo/globe.svg';
import linkedin from '../../../assets/logo/linkedin.svg';
import youtube from '../../../assets/logo/youtube.svg';
import instagram from '../../../assets/logo/instagram.svg';
import warning from '../../../assets/logo/warning.svg';
import tick from '../../../assets/logo/tick.svg';
import copyClip from '../../../assets/logo/copyClip.svg';
import cross from '../../../assets/logo/cross.svg';

const ProfileSttings = () => {
  const AgencySchema = Yup.object().shape({
    fullName: Yup.string().required('Please enter the name'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(
        /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
        'Phone number is not valid'
      ),
    personalPhoneNumber: Yup.string().matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      { message: 'Phone number is not valid', excludeEmptyString: true }
    ),
  });

  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [AgentDetails, setAgentDetails] = useState<any>();
  const [locations, setLocations] = useState([]);
  const [avatar, setAvatar] = useState();
  const [showSuccess, setshowSuccess] = useState(false);
  const [profilePicError, setProfilePicError] = useState('');
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgencySchema),
  });
  const watchAllFields = watch();
  const user = LocalStorageService.getUser();
  const options = [
    // { label: 'Admin', value: 'ADMIN', disabled: false },
    { label: 'Agent', value: 'AGENT', disabled: false },
    { label: 'Principal Agent', value: 'PRINCIPAL_AGENT', disabled: false },
  ];
  useEffect(() => {
    dispatch(
      fetchAnAgent({
        uuid: user.Agent?.uuid,
        successCB: (agentDetails: any) => {
          settingValue(agentDetails);
        },
      })
    );
    if (user?.UserRole?.name !== 'AGENT') {
      dispatch(
        getAllLocations({
          minimal: 'True',
          successCB: (data: any) => {
            setLocations(data.data.location);
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingValue = (agentDetails: any) => {
    setAgentDetails(agentDetails);
    setValue(
      'fullName',
      agentDetails?.User?.firstName + ' ' + agentDetails?.User?.lastName
    );
    setValue('title', agentDetails?.title);
    setValue('phoneNumber', agentDetails?.User?.phoneNumber);
    setValue(
      'personalPhoneNumber',
      agentDetails?.User?.personalPhoneNumber
        ? agentDetails?.User?.personalPhoneNumber
        : ''
    );
    setValue('email', agentDetails?.User?.email);
    setValue('location', {
      value: agentDetails?.Location?.uuid,
      label:
        agentDetails?.Location?.name + ', ' + agentDetails?.Location?.state,
    });
    setValue('userRole', {
      value: agentDetails?.User?.UserRole?.name,
      label:
        agentDetails?.User?.UserRole?.name === 'PRINCIPAL_AGENT'
          ? agentDetails?.User?.UserRole?.name
              .toLowerCase()
              .split('_')
              .map((word: string) => word[0].toUpperCase() + word.substring(1))
              .join(' ')
          : agentDetails?.User?.UserRole?.name
              .toLowerCase()
              .charAt(0)
              .toUpperCase() +
            agentDetails?.User?.UserRole?.name.toLowerCase().slice(1),
    });
    setAvatar(agentDetails?.User?.avatar);
  };
  const allDetails = useSelector(selectAgent);
  const loadingStatusState = useSelector(
    (state: { user: SliceState }) => state.user.status
  );

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      return inputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    let fileObj = event.target.files[0];
    let size = fileObj.size >= 2e6;
    if (!fileObj) {
      return;
    } else if (
      (fileObj.type.split('/')[1] === 'jpg' ||
        fileObj.type.split('/')[1] === 'png' ||
        fileObj.type.split('/')[1] === 'jpeg') &&
      fileObj.size < 2e6
    ) {
      setProfilePicError(``);
      onSubmit(fileObj);
    } else {
      fileObj = {};
      if (size) {
        return setProfilePicError(`Image size too large`);
      } else {
        return setProfilePicError(`Invalid file format`);
      }
    }
  };

  const onSubmit = (value: any) => {
    let formData = new FormData();
    if (value.type || value.type === '') {
      formData.append('action', 'updateLogo');
      formData.append('avatarLogo', value);
    } else {
      const splitName = value.fullName.split(' ');
      value.action = 'updateProfile';
      value.firstName = splitName[0];
      value.lastName = splitName.pop();
    }
    dispatch(
      agentProfileUpdate({
        uuid: user.Agent?.uuid,
        data: value.size ? formData : value,
        successCB: (data: any) => {
          if (value.size) setAvatar(data.User.avatar);
          setshowSuccess(true);
          setTimeout(() => {
            setshowSuccess(false);
          }, 2000);
        },
        errorCB: (data: any) => {
          setProfilePicError(data.error);
        },
        action: value.size ? 'updateLogo' : 'updateProfile',
      })
    );
  };
  return (
    <div className='fieldContainer scroll-bar'>
      <div className='subText d-none d-xl-block'>My Profile</div>
      <div className='d-flex'>
        {avatar && loadingStatusState === 'loading' ? (
          <div className='uploadedImg'>
            <div className='logo-spinner'>
              <Spinner
                className='spinnerStyle'
                animation='border'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : avatar ? (
          <img className='uploadedImg' src={avatar} alt='img' />
        ) : (
          <div className='cameraDiv'>
            <img className='' src={camera} alt='cameraIcon' />
          </div>
        )}
        <div className='d-block'>
          <button
            className='logoButton'
            onClick={() => {
              handleClick();
            }}
          >
            Upload new photo{' '}
            <img className='plusLogo' src={plus} alt='plus-icon' />
          </button>
          <h6 className='instructionText'>
            {`*Please upload .jpg,.png,.jpeg files <${2} MB`}
          </h6>
          {profilePicError ? (
            <div className='errorValImge d-flex align-items-center'>
              <img src={warning} alt='warning' className='warning' />
              <span style={{ fontSize: 14, fontWeight: 600, color: '#cc0000' }}>
                {profilePicError}
              </span>
            </div>
          ) : null}
          <input
            className=''
            style={{ display: 'none' }}
            ref={inputRef}
            type='file'
            onChange={handleFileChange}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className='personalInfoDiv'>Personal Information</h2>

        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <InputField
              name='fullName'
              type='text'
              placeholder='Full name'
              register={register}
              required
              className={`${errors.fullName && 'error-field'} ${
                watchAllFields.fullName && 'filled'
              }`}
            />
            {errors.fullName ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                {errors.fullName?.message}
              </div>
            ) : null}
          </div>
          <div className='fields secondField'>
            <InputField
              name='title'
              type='text'
              placeholder='Title'
              register={register}
              className={`${errors.title && 'error-field'} ${
                watchAllFields.title && 'filled'
              }`}
            />
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <PhoneNumberInput
              control={control}
              name='phoneNumber'
              required
              type='tel'
              placeholder='Phone number (office)'
              className={`${errors.phoneNumber && 'error-field'} ${
                watchAllFields.phoneNumber && 'filled'
              }`}
            />
            {errors.phoneNumber ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                {errors.phoneNumber?.message}
              </div>
            ) : null}
          </div>
          <div className='fields secondField'>
            <PhoneNumberInput
              control={control}
              name='personalPhoneNumber'
              type='tel'
              placeholder='Phone number (cell)'
              className={`${errors.personalPhoneNumber && 'error-field'} ${
                watchAllFields.personalPhoneNumber && 'filled'
              }`}
            />
            {errors.personalPhoneNumber ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                {errors.personalPhoneNumber?.message}
              </div>
            ) : null}
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <InputField
              name='email'
              type='text'
              placeholder='Email'
              disabled
              register={register}
              className={`${errors.email && 'error-field'} ${
                watchAllFields.email && 'filled'
              }`}
            />
          </div>
          <div className='fields secondField'>
            <SelectField
              options={locations.map((item: ILocation) => {
                return {
                  label: item?.name,
                  value: item.uuid,
                };
              })}
              disabled
              name='location'
              placeholder='Location'
              control={control}
            />
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <SelectField
              options={options}
              disabled
              name='userRole'
              placeholder='Role'
              control={control}
            />
          </div>
        </div>
        <h2 className='personalInfoDiv'>Agency Links</h2>
        <div className='d-flex flex-wrap'>
          <div className='fields '>
            <div className='fields'>
              <AgnecyLink
                agenciesUrl={
                  AgentDetails?.Agencies?.facebookUrl
                    ? AgentDetails?.Agencies?.facebookUrl
                    : ''
                }
                image={facebook}
              />
            </div>
          </div>
          <div className='fields secondField'>
            <div className='fields'>
              <AgnecyLink
                agenciesUrl={
                  AgentDetails?.Agencies?.twitterUrl
                    ? AgentDetails?.Agencies?.twitterUrl
                    : ''
                }
                image={twitter}
              />
            </div>
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <div className='fields'>
              <AgnecyLink
                agenciesUrl={
                  AgentDetails?.Agencies?.youtubeUrl
                    ? AgentDetails?.Agencies?.youtubeUrl
                    : ''
                }
                image={youtube}
              />
            </div>
          </div>
          <div className='fields secondField'>
            <div className='fields'>
              <AgnecyLink
                agenciesUrl={
                  AgentDetails?.Agencies?.instagramUrl
                    ? AgentDetails?.Agencies?.instagramUrl
                    : ''
                }
                image={instagram}
              />
            </div>
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='fields'>
            <AgnecyLink
              agenciesUrl={
                AgentDetails?.Agencies?.linkedInUrl
                  ? AgentDetails?.Agencies?.linkedInUrl
                  : ''
              }
              image={linkedin}
            />
          </div>
          <div className='fields secondField'>
            <AgnecyLink
              agenciesUrl={
                AgentDetails?.Agencies?.websiteUrl
                  ? AgentDetails?.Agencies?.websiteUrl
                  : ''
              }
              image={globe}
            />
          </div>
        </div>
        <div className='d-sm-flex flex-wrap'>
          <div className='buttonDiv d-flex align-items-center '>
            <button
              onClick={() => {
                settingValue(allDetails);
              }}
              className='cancel-button'
              type='button'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='agentButton'
              disabled={
                watchAllFields?.title === allDetails?.title &&
                watchAllFields?.phoneNumber === allDetails?.User?.phoneNumber &&
                watchAllFields?.personalPhoneNumber ===
                  allDetails?.User?.personalPhoneNumber &&
                watchAllFields?.fullName ===
                  allDetails?.User?.firstName + ' ' + allDetails?.User?.lastName
              }
            >
              Save <img className='tick' src={tick} alt='tick-sign' />
            </button>
          </div>
        </div>
      </form>
      {showSuccess && (
        <SuccessAlign>
          <div className={`success-container ${showSuccess && 'open-drawer'} `}>
            <SuccessCard
              title={'Success updated'}
              message={`<b>Your profile</b> has been updated`}
            />
            <button
              onClick={() => {
                setshowSuccess(false);
              }}
              className='crossbutton'
            >
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
      )}
    </div>
  );
};

export default ProfileSttings;

const AgnecyLink = ({
  agenciesUrl,
  image,
}: {
  agenciesUrl: any;
  image: string;
}) => {
  const [showTooltip, setShowToolTip] = useState(false);
  return (
    <div className='linkField d-flex align-items-center justify-content-between'>
      <div className='linkText'>
        <img src={image} alt='' className='mediaImage' />
        {agenciesUrl ? agenciesUrl : '--'}
      </div>
      <button
        type='button'
        className='copyclipButton info d-flex align-items-center'
        onClick={() => {
          navigator.clipboard.writeText(agenciesUrl);
          setShowToolTip(true);
          setTimeout(() => {
            setShowToolTip(false);
          }, 500);
        }}
      >
        <img src={copyClip} alt='' className='mediaImage' />
        {showTooltip && (
          <span className='tooltiptext'>
            {' '}
            <b>copied to clipboard</b>
          </span>
        )}
      </button>
    </div>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteEvent, updateEvent, fetchEventDetails } from '../../api/events';
import { addNote, addEvent, noteDelete, noteUpdate } from '../../api/note';
import { getAllActivties, getActivtiesCount } from '../../api/activites';
import {
  addTask,
  fetchTaskDetails,
  fetchActivities,
  deleteTask,
  updateTask,
} from '../../api/task';
import { INote } from '../../interfaces/Note';
import { ITask } from '../../interfaces/Task';
import { IEvent } from '../../interfaces/Event';

export type TaskSliceState = {
  status: string;
  isLoading: boolean;
  activities: (
    | { type: 'TASK' | 'NOTE'; data: INote | ITask }
    | { type: 'EVENT'; data: IEvent }
  )[];
  allCount: { taskCount: number; eventCount: number };
  tasksOffset: number;
  notesOffset: number;
  eventsOffset: number;
  currentTab: string;
};

const initialState: TaskSliceState = {
  status: 'idle',
  allCount: { taskCount: 0, eventCount: 0 },
  isLoading: false,
  activities: [],
  tasksOffset: 0,
  notesOffset: 0,
  eventsOffset: 0,
  currentTab: 'All',
};

export const createNote = createAsyncThunk(
  'tasks/createNote',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: INote;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addNote(data, successCB, errorCB);
    return response.data;
  }
);
export const deleteNote = createAsyncThunk(
  'task/deleteNote',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await noteDelete(uuid, successCB);
    successCB(response.data);
    return response.data;
  }
);

export const getActivties = createAsyncThunk(
  'tasks/getActivties',
  async ({
    sortBy,
    orderBy,
    type,
    limit,
    tasksOffset,
    eventsOffset,
    eventType,
    taskType,
    taskStatus,
    taskPriority,
    assignee,
    searchBy,
  }: {
    sortBy: string;
    orderBy: string;
    type: string;
    limit: number;
    tasksOffset: number;
    eventsOffset: number;
    eventType?: string[];
    taskType?: string[];
    taskStatus?: string[];
    taskPriority?: string[];
    assignee?: string;
    searchBy?: string;
  }) => {
    const response = await getAllActivties(
      sortBy,
      orderBy,
      undefined,
      undefined,
      type,
      limit,
      tasksOffset,
      eventsOffset,
      eventType,
      taskType,
      taskStatus,
      taskPriority,
      assignee,
      searchBy
    );
    return response.data;
  }
);

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addTask(data, successCB, errorCB);
    return response.data;
  }
);

export const getAllActivtiesCount = createAsyncThunk(
  'tasks/getAllActivtiesCount',
  async ({
    eventType,
    taskType,
    taskStatus,
    taskPriority,
    assignee,
    searchBy,
  }: {
    eventType?: string[];
    taskType?: string[];
    taskStatus?: string[];
    taskPriority?: string[];
    assignee?: string;
    searchBy?: string;
  }) => {
    const response = await getActivtiesCount(
      eventType,
      taskType,
      taskStatus,
      taskPriority,
      assignee,
      searchBy
    );
    return response.data;
  }
);

export const getTasksDetails = createAsyncThunk(
  'tasks/getTasksDetails',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchTaskDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

export const getActivities = createAsyncThunk(
  'tasks/getActivities',
  async ({
    uuidName,
    uuid,
    showAll,
    successCB,
    tasksOffset,
    eventsOffset,
    notesOffset,
    eventType,
    taskType,
    taskStatus,
    taskPriority,
    assignee,
    searchBy,
  }: {
    uuidName: string;
    uuid: string;
    showAll: string;
    successCB: Function;
    tasksOffset: number;
    eventsOffset: number;
    notesOffset: number;
    eventType?: string[];
    taskType?: string[];
    taskStatus?: string[];
    taskPriority?: string[];
    assignee?: string;
    searchBy?: string;
  }) => {
    const response = await fetchActivities(
      uuidName,
      uuid,
      showAll,
      successCB,
      tasksOffset,
      eventsOffset,
      notesOffset,
      eventType,
      taskType,
      taskStatus,
      taskPriority,
      assignee,
      searchBy
    );
    return response;
  }
);

export const updateTaskDetails = createAsyncThunk(
  'tasks/updateTaskDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await updateTask(uuid, data);
    successCB(response.data);
    return response.data;
  }
);

export const updateNoteDetails = createAsyncThunk(
  'tasks/updateNoteDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await noteUpdate(uuid, data);
    successCB(response.data);
    return response.data;
  }
);

export const createEvent = createAsyncThunk(
  'tasks/createEvent',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addEvent(data, successCB, errorCB);
    return response.data;
  }
);

export const deleteAnEvent = createAsyncThunk(
  'tasks/deleteAnEvent',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteEvent(uuid, successCB);
    response.uuid = uuid;
    return response;
  }
);

export const deleteATask = createAsyncThunk(
  'tasks/deleteATask',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteTask(uuid, successCB);
    response.uuid = uuid;
    successCB(response.data);
    return response;
  }
);
export const updateEventDetails = createAsyncThunk(
  'tasks/updateEventDetails',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await updateEvent(uuid, data);
    successCB(response.data);
    return response.data;
  }
);
export const getEventDetails = createAsyncThunk(
  'tasks/getEventDetails',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchEventDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
      state.activities = [];
    },
    clearActivities: (state) => {
      state.activities = [];
    },
    currentTab: (state, action) => {
      state.currentTab = action.payload?.currentTab;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createTask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasksOffset = state.tasksOffset + 1;
        state.activities.unshift({ type: 'TASK', data: action.payload });
      })
      .addCase(getTasksDetails.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTasksDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(getTasksDetails.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getEventDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notesOffset = state.notesOffset + 1;
        state.activities.unshift({ type: 'NOTE', data: action.payload });
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.eventsOffset = state.eventsOffset + 1;
        state.activities.unshift({ type: 'EVENT', data: action.payload });
      })
      .addCase(getActivities.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasksOffset = action.payload.tasksOffset;
        state.eventsOffset = action.payload.eventsOffset;
        state.notesOffset = action.payload.notesOffset;
        state.activities = [...state.activities, ...action.payload.activities];
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(deleteAnEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.activities.findIndex(
          (item) => item?.data.uuid === action.payload.uuid
        );
        state.activities.splice(index, 1);
      })
      .addCase(deleteATask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.activities.findIndex(
          (item) => item?.data.uuid === action.payload.uuid
        );
        state.activities.splice(index, 1);
      })
      .addCase(updateTaskDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const activityIndex = state.activities.findIndex(
          (item) => item?.data.uuid === action.payload.uuid
        );
        if (action.payload.TaskStatus?.name === 'COMPLETED') {
          state.activities.splice(activityIndex, 1);
        } else {
          state.activities[activityIndex].data = action.payload;
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.activities.findIndex(
          (item) => item?.data?.uuid === action.payload.uuid
        );
        if (!action.payload.TaskId && !action.payload.EventId)
          state.activities.splice(index, 1);
      })
      .addCase(updateEventDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const activityIndex = state.activities.findIndex(
          (item) => item?.data.uuid === action.payload.uuid
        );
        state.activities[activityIndex].data = action.payload;
      })
      .addCase(updateNoteDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const activityIndex = state.activities.findIndex(
          (item) => item?.data?.uuid === action.payload?.uuid
        );
        state.activities[activityIndex] = {
          type: 'NOTE',
          data: action.payload,
        };
      })
      .addCase(getActivties.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getActivties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasksOffset = action.payload.tasksOffset;
        state.eventsOffset = action.payload.eventsOffset;
        state.activities = [...state.activities, ...action.payload.activities];
      })
      .addCase(getActivties.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getAllActivtiesCount.fulfilled, (state, action) => {
        state.allCount = action.payload;
      });
  },
});
export const { updateStatus, clearActivities, currentTab } = taskSlice.actions;

export default taskSlice.reducer;

export const selectAllActivities = (state: { tasks: TaskSliceState }) =>
  state.tasks?.activities;

export const selectAllActivitiesCount = (state: { tasks: TaskSliceState }) =>
  state.tasks?.allCount;

import styled from '@emotion/styled';

export const SettingsDiv = styled.div`
  .setting-card {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 12px;
    position: relative;

    .setting-logo {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px 0 0;
      background: #fafafa;

      img {
        width: 28px;
      }
    }
    h6 {
      font-weight: 600;
      margin-bottom: 4px;
    }

    .setting-description {
      font-size: 12px;
      color: #8990a3;
    }

    :hover {
      box-shadow: 0px 6px 24px 0px #26275833;
      cursor: pointer;
    }
  }

  .active-card {
    box-shadow: 0px 6px 24px 0px #26275833;
  }

  .inactive-card {
    opacity: 0.7;
  }

  .disabled-card,
  .disabled-card:hover {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
  }

  .results-div {
    position: absolute;
    top: 48px;
    box-shadow: 0px 2px 8px 0px #26275833;
    background: #fff;
    z-index: 1;
    border-radius: 8px;
    padding: 4px;
    width: 100%;
    left: 0;

    button {
      background: #fff;
      border: 0;
      padding: 8px 12px;
      width: 100%;
      border-radius: 8px;
      text-align: left;

      :hover {
        background: #f5f7fc;
      }
    }
  }

  .info {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      width: 240px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      right: -8px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .setting-card {
      width: 49.1%;
      padding: 16px;
      margin-bottom: 16px;

      .setting-logo {
        margin: 0 16px 0 0;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .setting-card {
      width: 32.4%;
      padding: 16px 12px;
      text-align: center;

      .setting-logo {
        width: 52px;
        height: 52px;
        margin: 0 auto 16px auto;
        img {
          width: 32px;
        }
      }

      h6 {
        margin-bottom: 8px;
      }

      .setting-description {
        font-size: 14px;
      }
    }

    .info {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  @media screen and (min-width: 1900px) {
    .setting-card {
      width: 24.1%;
      padding: 20px 16px;
      margin-bottom: 20px;

      .setting-logo {
        width: 68px;
        height: 68px;
        margin-bottom: 20px;

        img {
          width: 40px;
        }
      }

      h6 {
        font-size: 20px;
      }
    }

    .info {
      top: 16px;
      right: 16px;
      img {
        width: 28px;
      }

      .tooltiptext {
        top: 35px;
        right: -5px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .content-div {
      height: 100vh;
      background: #fafafa;

      .setting-description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 196px;
      }
    }

    .inner-content-div {
      background: #fff;
    }

    .search-div {
      max-width: 100%;
      width: 100%;
    }
  }
`;

import styled from '@emotion/styled';
export const Notifications = styled.div`
  .backArrow {
    margin-right: 10px;
  }
  .goackButton {
    cursor: pointer;
    :hover {
      border-bottom: 1px solid #3c498a;
    }
  }
  .paddingDiv {
    overflow: hidden;
    padding: 84px 88px 0px 88px;
  }
  .NotificationContainer {
    width: 960px;
    height: calc(100vh - 102px);
    background: #fff;
    margin: 16px auto;
    border-radius: 16px 16px 0px 0px;
  }

  .navbar-button {
    margin-right: 16px;
    background: #e6e9f0;
    position: relative;
    border-radius: 8px;
    border: none;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
  }
  .NotificationDropDown {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    width: 432px;
    top: 55px;
    right: 40px;
    height: 667px;
    background: #fff;
    border-radius: 8px;
    .topNotificationContainer {
      padding: 16px 24px 22px 28px;
      h2 {
        text-align: start;
        font-size: 24px;
      }
    }
  }
  .NoticationContent {
    padding: 24px 56px 20px 72px;
    h2 {
      text-align: start;
      font-size: 24px;
    }
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 40px;
    border: none;
    height: 40px;
    text-align: center;
    position: relative;
    cursor: pointer;
    .NotificationDrop {
      top: 38px;
      right: 0;
      padding: 4px 14px 4px 10px;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      width: 200px;
      border-radius: 8px;
      height: 82px;
      background: #fff;
    }

    .dropWord {
      padding: 3px 0px;
      margin: 2px;
      h6 {
        font-weight: 400;
        color: #262758;
      }
      :hover {
        h6 {
          color: #000000;
        }
      }
    }
    .ticklogo {
      width: 20px;
      padding-bottom: 5px;
    }
    .settinglogo {
      width: 25px;
      padding-bottom: 5px;
    }
    .marginDiv {
      border-bottom: 1px solid #e6e9f0;
    }
  }
  .togglebuttons {
    margin-top: 0px;
    border-bottom: 1px solid #e6e9f0;
    .count {
      color: #191919;
      width: 40px;
      font-size: 12px;
      margin-left: 5px;
      background: #ecf4f9;
      padding: 1px 10px;
      border-radius: 16px;
    }
  }
  .naVigatorbuttons {
    padding-bottom: 7px;
    colour: #262758;
    background: #fff;
    border: none;
    cursor: pointer;
    font-weight: 400;
  }
  .secondButton {
    margin-left: 30px;
  }
  .naVigatorbuttonsActive {
    font-weight: 600;
    border-bottom: 3px solid #262758;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
    font-weight: 600;
  }
  .notificationTitle {
    font-size: 14px;
    color: #262758;
  }
  .container {
    padding: 16px 20px 16px 8px;
    border-radius: 8px;
    cursor: pointer;

    .unreadDot {
      position: relative;
      background: #3c498a;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      .smallDropDown {
        background: #fff;
        position: absolute;
        top: 32px;
        right: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
        border-radius: 8px;
        padding: 6px;
        width: 110px;
        cursor: pointer;
        :hover {
          background: #e6e9f0;
          color: #262758;
        }
      }
    }
    .vectorInside {
      visibility: hidden;
    }
  }
  .fistchild {
    margin-top: 18px;
  }

  .image-container {
    margin-right: 16px;
    border-radius: 50%;
    background: #e6e9f0;
    min-width: 52px;
    height: 52px;
  }
  .Iconimage {
    width: 25px;
  }
  .dateField {
    padding-left: 5px;
    margin-top: 31px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
  }
  .notificationTitle {
    color: #262758;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
  }
  .notificationSub {
    font-size: 14px;
    color: #262758;
  }
  .notificationTime {
    margin-left: 12px;
    font-size: 12px;
    color: #8990a3;
  }
  .container:hover {
    position: relative;
    background: #f5f7fc;

    .vectorInside {
      visibility: visible;
      background: #f5f7fc;
      border-radius: 8px;
      padding: 20px;
      border: none;
      right: 35px;
      bottom: 18px;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }
    .vectorInside:hover {
      background: #e6e9f0;
    }
  }

  .scrollContainer {
    overflow: auto;
    max-height: 489px;
    &::-webkit-scrollbar {
      width: 1px;
      height: 59px;
    }
  }
  .pageScrollContainer {
    overflow: auto;
    max-height: calc(100vh - 282px);
    &::-webkit-scrollbar {
      width: 1px;
      height: 59px;
    }
  }
  .showMoreButton {
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: #262758;
    background: #fff;
    .arowimg {
      margin-left: 12px;
    }
  }
  .shwBtnDiv {
    position: absolute;
    width: 82%;
    left: 10%;
    border-top: 1px solid #d1d6e5;
    padding-top: 15px;
    bottom: 10px;
  }
  .Skeleton {
    border-radius: 16px;
    padding: 12px 0;
  }
  @media (min-width: 1900px) {
    .NotificationDropDown {
      width: 487px;
      height: 754px;
      .topNotificationContainer {
        padding: 16px 24px 10px 28px;
        h2 {
          text-align: start;
          font-size: 24px;
        }
      }
      .togglebuttons {
        margin-top: 32px;
      }
      .scrollContainer {
        max-height: 580px;
      }
      .shwBtnDiv {
        padding-top: 11px;
      }
    }
    .togglebuttons {
      margin-top: 32px;
    }
  }
  @media (max-width: 1900px) {
    .NotificationContainer {
      width: 694px;
      height: calc(100vh - 95px);
      margin: 8px auto;
    }
  }
  @media (max-width: 1200px) {
    .NotificationContainer {
      width: 700px;
      height: calc(100vh - 95px);
      background: #fff;
      margin: 8px auto;
    }
    .pageScrollContainer {
      max-height: calc(100vh - 282px);
    }
    .goackButtonMobile {
      margin-left: 110px;
    }
  }
  @media (max-width: 576px) {
    .paddingDiv {
      padding: 2px;
    }
    .NotificationContainer {
      width: 100vw;
      height: calc(100vh - 95px);
      margin: 0;
    }
    .pageScrollContainer {
      max-height: calc(100vh - 222px);
    }
    .NoticationContent {
      padding: 20px;
      h2 {
        text-align: start;
        font-size: 24px;
      }
    }
    .goackButtonMobile {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  @media (min-width: 1200px) {
    .togglebuttons {
      margin-top: 32px;
    }
  }
`;

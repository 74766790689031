import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { removePipeline } from '../../../../store/pipelines/pipelineSlice';
import { MobilePipelineDeleteStyle } from '../../elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import deleteImg from '../../../../assets/illustrations/delete.svg';

const MobileDeletePipelineModal = ({
  showModal,
  onCloseModal,
  onDelete,
  UUID,
  setDeleteSuccess,
}: {
  showModal: boolean;
  onCloseModal: Function;
  onDelete: Function;
  UUID?: string;
  setDeleteSuccess: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { uuid } = useParams();

  const DeletePipeLine = () => {
    if (UUID) {
      return dispatch(
        removePipeline({
          uuid: UUID,
          agencyUUID: uuid,
          successCB: () => {
            if (!uuid) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          },
        })
      );
    }
  };
  return (
    <MobilePipelineDeleteStyle>
      <div className='backgroundQuickAdd'>
        <div className={`quickFormsDropDown closed`}>
          <div className='d-flex  drawerHandle'></div>

          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => onCloseModal(false)}
          />
          <img src={deleteImg} alt='delete' className='delete-img' />

          <h2>Delete this pipeline?</h2>
          <div className='sub-heading'>
            There are no any opportunities in this pipeline. Do you want to
            delete this pipeline?
          </div>
          <div className='buttonsDiv d-flex align-items-center'>
            <button
              onClick={() => {
                onCloseModal();
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='deleteButton d-flex align-items-center justify-content-between'
              onClick={() => {
                onDelete(true);
                onCloseModal(false);
                DeletePipeLine();
                setDeleteSuccess('success');
              }}
            >
              Delete
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </div>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => onCloseModal(false)}
      />
      <h2>Delete this pipeline?</h2>
      <div className='sub-heading'>
        There are no any opportunities in this pipeline. Do you want to delete
        this pipeline?
      </div>
      <div className='buttonsDiv d-flex align-items-center justify-content-between'>
        <button
          onClick={() => {
            onCloseModal();
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='deleteButton d-flex align-items-center justify-content-between'
          onClick={() => {
            onDelete(true);
            onCloseModal(false);
            DeletePipeLine();
            setDeleteSuccess('success');
          }}
        >
          Delete
          <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </MobilePipelineDeleteStyle>
  );
};

export default MobileDeletePipelineModal;

import styled from '@emotion/styled';

export const DetailsDiv = styled.div`
  .content-section {
    padding: 0;
    height: 100vh;
    background: #fff;
  }

  .name-text-overflow {
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 300px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 0;
      overflow-wrap: break-word;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
    .tooltiptextPolicy {
      visibility: hidden;
      min-width: 176px;
      max-width: 300px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 34px;
      left: -141px;
      overflow-wrap: break-word;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: 126px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptextPolicy {
        visibility: visible;
      }
    }
  }

  .header-div {
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e9f0;
    h2 {
      font-size: 16px;
      margin: 0 13px 0 0;
      max-width: 280px;
      display: inline-block;
      white-space: nowrap;
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .profile-pic {
    background: #262758;
    height: 56px;
    width: 56px;
    border-radius: 46px;
    margin-right: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
  .profilePicDiv {
    background: #262758;
    height: 56px;
    width: 56px;
    border-radius: 46px;
    margin-right: 12px;
    color: #fff;
    font-weight: 600;
  }
  .profile-picdiv {
    background: #ecf4f9;
    height: 56px;
    width: 56px;
    border-radius: 46px;
    margin-right: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px;
    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .info-div {
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    padding: 24px 0 50px 5px;
    font-size: 14px;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;

      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  &.leads-detail .info-div {
    max-height: calc(100vh - 100px);
  }

  .section-title {
    font-size: 16px;
    font-weight: 600;
  }

  .contacts-div {
    font-weight: 600;
    padding-top: 20px;

    .section-title {
      border-bottom: 1px solid #e6e9f0;
      border-top: 1px solid #e6e9f0;
      padding: 10px 0;
      margin-bottom: 15px;
    }
  }

  .sub-section {
    line-height: 28px;
  }

  .subsection-title {
    font-weight: 600;
  }
  .primaryButton {
    padding: 8px 20px;
    position: relative;
    .addDropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      right: 0px;
      top: 50px;
      z-index: 1;
      color: #000;
      font-size: 14px;
      width: 220px;
      cursor: pointer;
      .Add-div {
        display: flex;
        width: 90%;
        border-radius: 8px;
        justify-content: space-between;
        font-weight: 400;
        color: #262758;
        padding: 10px 20px 8px 15px;
        margin: 2px 0px 2px 10px;
        :hover {
          background: #f5f7fc;
          color: #262758;
        }
        :last-of-type {
          margin-bottom: 4px;
        }
      }
    }
    .dropImg {
      filter: brightness(0) saturate(100%) invert(92%) sepia(1%) saturate(2930%)
        hue-rotate(198deg) brightness(107%) contrast(98%);
      margin-left: 30px;
    }
  }

  .tertiaryButton {
    padding: 8px 30px;
    font-size: 14px;
  }

  .status {
    width: 102px;
    font-size: 12px;
    text-align: center;
    border-radius: 16px;
    padding: 4px 0;
    font-weight: 600;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .statusPolicy {
    background: #d4fad4;
    color: #0a5e0e;
    margin-top: -6px;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }

  .dropDownButton {
    height: 32px;
    width: 32px;
    background: #f5f7fc;
    border: 0;
    margin: 0;
    border-radius: 8px;
    position: relative;
    img {
      transform: rotate(180deg);
    }
    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 10px;
      right: 0px;
      bottom: -70px;
      z-index: 1;
      width: 144px;
      cursor: pointer;
      line-height: 10px;
      .edit-div {
        display: flex;
        padding: 2px 10px;
        margin: 2px 0;
        justify-content: space-between;
        align-items: center;
        :hover {
          border-radius: 8px;
          background: #f5f7fc;
        }
      }
      .borderDiv {
        margin-left: 8px;
        width: 90%;
        border-top: 1px solid #e6e9f0;
      }
      .junk-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
    }
    .drop-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 0px;
      bottom: -81px;
      z-index: 1;
      width: 194px;
      cursor: pointer;
      line-height: 14px;
      .editdiv {
        display: flex;
        justify-content: space-between;
        padding: 4px 10px;
        margin-bottom: 4px;
        align-items: center;
        :hover {
          border-radius: 8px;
          background: #f5f7fc;
        }
        align-items: center;
      }
      .borderDiv {
        margin-left: 8px;
        width: 90%;
        border-top: 1px solid #e6e9f0;
      }
      .junkdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px;
        margin-top: 4px;
        align-items: center;
        :hover {
          border-radius: 8px;
          background: #f5f7fc;
        }
      }
    }
  }

  .header-info {
    font-weight: 600;
    font-size: 14px;
  }

  .amount {
    color: #3d8a68;
    font-size: 16px;
    font-weight: 600;
  }

  .count-div {
    padding: 2px 10px;
    color: #191919;
    background: #e6e9f0;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    margin-left: 4px;
  }

  .contact-img {
    margin-right: 8px;
    width: 20px;
  }

  .input-field {
    border: 1px solid #cad9f3;
    border-radius: 8px;
    font-size: 16px;
    padding: 12px 16px;
    outline: none;
    :focus {
      border: 1px solid #3c498a;
    }
  }

  .nav-decor {
    max-width: 180px;
    display: inline-block;
    white-space: nowrap;
    margin-left: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    cursor: pointer;
    vertical-align: middle;
  }

  .disabled-decor {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }

  .action-item {
    width: 32px;
    height: 32px;
    padding: 4px 7.5px;
    background: #ecf4f9;
    border-radius: 8px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    img {
      width: 17px;
    }
    .tooltiptext {
      width: 166px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 15px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 50px;
      right: 0px;

      // ::after {
      //   content: ' ';
      //   position: absolute;
      //   top: -10px;
      //   right: 12px;
      //   margin-left: -5px;
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color: transparent transparent #191919 transparent;
      // }
    }
  }

  .plus-button {
    background: #e6e9f0;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    border: 0;
    margin-left: auto;
    img {
      width: 12px;
    }
  }

  .editIcon {
    padding: 0 0px 10px 10px;
    cursor: pointer;
  }

  .addButton {
    position: absolute;
    right: 20px;
    bottom: 84px;
    background: #262758;
    border-radius: 32px;
    height: 48px;
    width: 48px;
  }

  .mobile-bottombar {
    position: fixed;
    z-index: 1;
    background: #ffffff;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 20px;
    border-top: 1px solid #e6e9f0;
  }
  .LinkContactDiv {
    margin-left: 4px;
    cursor: pointer;
    :hover {
      padding-bottom: 4px;
      border-bottom: 2px solid #bba077;
    }
    .iconImage {
      margin-right: 6px;
    }
  }
  &.leads-detail .content-div .timeline .activity-scroll {
    max-height: calc(100vh - 290px);
  }
  @media screen and (min-width: 576px) {
    .dropDownButton {
      img {
        transform: rotate(0deg);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .content-section {
      padding: 72px 0 0 84px;
    }

    .content-div {
      border-left: 1px solid #e6e9f0;
      height: 100%;
    }

    &.leads-detail .content-div .timeline .activity-scroll {
      max-height: calc(100vh - 550px);
    }

    .header-div {
      padding: 27px 46px;

      h2 {
        font-size: 20px;
      }
    }

    .profile-pic {
      margin-right: 16px;
    }

    .info-div {
      width: 24%;
      border-right: 1px solid #e6e9f0;
      padding: 40px 32px 35px 46px;
    }

    .options-div {
      width: 76%;
      padding: 40px 43px 0 54px;
    }

    .tertiaryButton {
      padding: 8px 50px;
      font-size: 16px;
    }

    .dropDownButton {
      height: 40px;
      width: 40px;
      margin-left: 19px;
    }

    .amount {
      font-size: 14px;
      font-weight: 700;
      border-right: 1px solid #e6e9f0;
      padding: 0 13px 0 0;
      margin: 0 12px 0 0;
    }

    .count-div {
      padding: 2px 16px;
      margin-left: 8px;
    }

    .opportunities-div {
      position: relative;
      cursor: pointer;
      text-decoration: underline;
      border-right: 1px solid #e6e9f0;
      margin-right: 13px;
      padding-right: 12px;

      .opportunties-dropdown {
        position: absolute;
        background: #fff;
        z-index: 1;
        box-shadow: 0px 4px 16px 0px #26275833;
        border-radius: 8px;
        width: 300px;
        padding: 16px 8px;
        overflow: hidden;

        left: 0;
        top: 30px;

        .opportunity-count {
          font-size: 12px;
          font-weight: 600;
          color: #5c698c;
          letter-spacing: 2px;
          padding: 0 12px;
        }

        .opportunity-item {
          padding: 8px 12px;
          font-size: 16px;
          width: 99%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          :hover {
            background: #ecf4f9;
            border-radius: 8px;
          }
        }
      }
    }

    .action-item {
      width: 40px;
      height: 40px;

      img {
        width: 25px;
      }
    }
    .policy-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 16px;
      font-weight: 600;
      width: 220px;
      height: 45px;
    }
    .addressField {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (min-width: 1900px) {
    .content-section {
      padding: 84px 0 0 88px;
    }

    .header-div {
      padding: 27px 120px;
    }

    .options-div {
      padding: 48px 120px 0 80px;
    }

    .section-title {
      font-size: 20px;
    }

    .info-div {
      padding: 44px 56px 32px 120px;
      font-size: 16px;
      max-height: calc(100vh - 200px);
    }
    &.leads-detail .content-div .timeline .activity-scroll {
      max-height: calc(100vh - 550px);
    }

    .contacts-div {
      padding-top: 32px;
      .section-title {
        padding: 16px 0;
        margin-bottom: 20px;
      }
    }

    .sub-section {
      line-height: 32px;
    }

    .primaryButton {
      padding: 12px 20px;
    }
    .tertiaryButton {
      padding: 12px 50px;
    }

    .dropDownButton {
      height: 48px;
      width: 48px;
      margin-left: 20px;
    }

    .amount {
      padding: 0 16px 0 0;
      margin: 0 13px 0 0;
    }

    .opportunities-div {
      margin-right: 16px;
      padding-right: 13px;
    }

    .count-div {
      margin-left: 11px;
    }

    .contact-img {
      margin-right: 10px;
    }

    .input-field {
      padding: 20px;
    }

    .policy-button {
      width: 232px;
      height: 54px;
    }
  }
`;

export const MobileDetailsDiv = styled.div`
  padding: 76px 20px;
  position: relative;
  height: 100vh;

  .opportunity-list {
    position: absolute;
    background: #fff;
    top: 40px;
    width: 100%;
    box-shadow: 0px 2px 8px 0px #26275833;
    border-radius: 8px;
    padding: 12px 0;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .opportunity-item {
    padding: 8px 20px;
    :hover {
      background: #ecf4f9;
      border-radius: 8px;
    }
  }

  h2 {
    font-size: 16px;
    margin: 0 8px 0 13px;
  }

  .mobile-topbar {
    padding: 18px 20px;
    background: #ffffff;
    border-bottom: 1px solid #e6e9f0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    left: -5px;
    h2 {
      max-width: 110px;
      display: inline-block;
      white-space: nowrap;
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .crossbutton {
    position: absolute;
    right: 24px;
  }

  .details-section {
    padding: 16px 0;
    border-bottom: 1px solid #e6e9f0;

    .section-title {
      margin: 0;
    }
  }
  .primaryButton {
    width: 143px;
    height: 32px;
    font-size: 14px;
  }
  .action-item {
    margin-right: 2px;
  }
  .count-div {
    padding: 2px 13px;
    color: #191919;
    background: #e6e9f0;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    margin-left: 6px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 300px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 0;
      overflow-wrap: break-word;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    padding: 100px 31px;
    .primaryButton {
      width: 143px;
      height: 38px;
      font-size: 14px;
    }
    .action-item {
      padding: 18px;
      .tooltiptext {
        width: 146px;
        background: #191919;
        color: #d1d6e5;
        padding: 12px 6px;
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        position: absolute;
        z-index: 1;
        top: 50px;
        right: -10px;

        // ::after {
        //   content: ' ';
        //   position: absolute;
        //   top: -10px;
        //   right: 12px;
        //   margin-left: -5px;
        //   border-width: 5px;
        //   border-style: solid;
        //   border-color: transparent transparent #191919 transparent;
        // }
      }
    }
    .modal-content-div {
      width: 360px;
    }

    .mobile-topbar {
      padding: 24px 32px;

      .profile-pic {
        width: 32px;
        height: 32px;
        font-size: 12px;
        margin-left: 25px;
      }
      .profile-picdiv {
        width: 32px;
        height: 32px;
        font-size: 12px;
        margin-left: 25px;
      }

      h2 {
        max-width: 280px;
        margin: 0 8px 0 0;
      }

      .dropDownButton {
        height: 32px;
        width: 32px;
        margin: 0 13px 0 16px;
      }
    }
    .policy-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 14px;
      font-weight: 600;
      width: 202px;
      height: 45px;
    }
    .home-img {
      width: 20px;
    }
    .quote-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 16px;
      font-weight: 600;
      width: 120px;
      height: 32px;
    }
    .policy-button {
      height: 32px;
    }
  }
`;

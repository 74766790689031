import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import Skeleton from 'react-loading-skeleton';
import { fetchLineOfBusiness } from '../../../../api/lineOfBusiness';
import { ILineOfBusiness } from '../../../../interfaces/ProductCategory';

const PolicyFilter = ({
  show,
  closeModal,
  setLOB,
  LOB,
  categoryTypes,
  selectedCategoryTypes,
}: {
  show: string;
  closeModal: Function;
  setLOB: Function;
  LOB: any;
  categoryTypes: any;
  selectedCategoryTypes: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [lineOfBusiness, setLineOfBusiness] = useState<ILineOfBusiness[]>([]);

  const categoryOption = [
    { label: 'Custom', value: 'custom' },
    { label: 'Default', value: 'default' },
  ];

  useEffect(() => {
    fetchLineOfBusiness((data: ILineOfBusiness[]) => setLineOfBusiness(data));
  }, []);
  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Product Category Type</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={categoryOption}
              placeholder='Select'
              onChange={(value: any) => {
                selectedCategoryTypes(value);
              }}
              value={categoryTypes}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Line of business</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={lineOfBusiness.map((type) => {
                return {
                  label:
                    type.name[0].toUpperCase() +
                    type.name.slice(1).toLowerCase(),
                  value: type.uuid,
                  type: type.name,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setLOB(value);
              }}
              value={LOB}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(LOB?.value ? 1 : 0) + (categoryTypes?.value ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setLOB('');
                          selectedCategoryTypes('');
                        }}
                        disabled={
                          (LOB?.value ? 1 : 0) +
                            (categoryTypes?.value ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(LOB?.value ? 1 : 0) + (categoryTypes?.value ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setLOB('');
                      selectedCategoryTypes('');
                    }}
                    disabled={
                      (LOB?.value ? 1 : 0) + (categoryTypes?.value ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default PolicyFilter;

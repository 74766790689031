import { useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
  fetchQuoteDetails,
  QuoteSliceState,
  selectQuote,
} from '../../../store/quotes/quoteSlice';
import Modal from '../../../components/Modal';
import { StyledQuoteModal } from './styles';
import cross from '../../../assets/logo/cross.svg';
import Skeleton from 'react-loading-skeleton';

const QuoteModal = ({
  setShowModal,
  uuid,
  showModal,
}: {
  setShowModal: Function;
  showModal: string;
  uuid: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const details = useSelector(selectQuote);
  const loadingStatus = useSelector(
    (state: { quotes: QuoteSliceState }) => state.quotes.isLoading
  );

  useEffect(() => {
    dispatch(
      fetchQuoteDetails({
        uuid,
        successCB: () => {},
      })
    );
  }, []);

  return (
    <StyledQuoteModal>
      <Modal open={showModal} align='right'>
        <button
          onClick={() => {
            setShowModal('closed');
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <h4>View quote</h4>
        {loadingStatus ? (
          <div className='quote-content-div d-sm-flex flex-wrap'>
            {[1, 2, 3, 4, 5].map((index) => (
              <div className='fields pe-5' key={index}>
                <div className='label me-5' style={{ maxWidth: 100 }}>
                  <Skeleton height={10}></Skeleton>
                </div>
                <div className='value' style={{ maxWidth: 200 }}>
                  <Skeleton height={20}></Skeleton>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='quote-content-div d-sm-flex flex-wrap'>
            <div className='fields'>
              <div className='label'>Associated account</div>
              <div className='value'>
                {details?.QuoteAccountContact[0].AccountContact.Account.name}
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Associated contact</div>
              <div className='value'>
                {
                  details?.QuoteAccountContact[0].AccountContact.Contact
                    .firstName
                }{' '}
                {
                  details?.QuoteAccountContact[0].AccountContact.Contact
                    .lastName
                }
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Carrier</div>
              <div className='value'>
                {details?.Carrier
                  ? details.Carrier.name.toLowerCase()
                  : details?.CustomCarrier.name.toLowerCase()}
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Policy term</div>
              <div className='value'>{details?.policyTerm}</div>
            </div>
            <div className='fields'>
              <div className='label'>Coverage type</div>
              <div className='value'>
                {details?.CoverageType.name.toLowerCase().replace('-', ' ')}
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Quote stage</div>
              <div className='value terminal-value'>
                {details?.QuoteStage.name.toLowerCase()}
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Quote type</div>
              <div className='value'>
                {details?.QuoteType.name.toLowerCase().replace('-', ' ')}
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Effective date</div>
              <div className='value'>
                <Moment format='MM/DD/YYYY'>{details?.effectiveDate}</Moment>
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Quote presented date</div>
              <div className='value'>
                <Moment format='MM/DD/YYYY'>
                  {details?.quotePresentedDate}
                </Moment>
              </div>
            </div>
            <div className='fields'>
              <div className='label'>Premium</div>
              <div className='value'>
                ${details?.premium.toLocaleString('en-US')}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </StyledQuoteModal>
  );
};

export default QuoteModal;

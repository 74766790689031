import styled from '@emotion/styled';
export const DocumentsModalDiv = styled.div`
  width: 100%;
  z-index: 5;
  padding-bottom: 300px;
  height: 99vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .topHead {
    height: 84px;
    border-bottom: 2px solid #e6e9f0;
  }

  .drawer-content {
    padding-top: 24px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 90%;
    bottom: 0;
    right: 20px;
    background: #fff;
    z-index: 1;
    padding: 10px;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 200px;
    height: 48px;
    border: none;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .cancel-button {
    height: 48px;
    background: none;
    border: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  .documentDetails {
    margin: 24px 2px 0 2px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 12px 24px;
  }
  .itemsDiv {
    margin-top: 13px;
  }
  .docName {
    margin-left: 12px;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .documentDropDown {
    position: relative;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px;
    border: none;
    width: 316px;
    height: 32px;
  }
  .documentTypeDrop {
    position: absolute;
    top: 38px;
    left: 0;
    background: #fff;
    width: 316px;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
    .listType {
      padding-top: 10px;
      border-bottom: 1px solid #e6e9f0;
      text-align: start;
    }
    .listType:last-child {
      border-bottom: none;
    }
  }
  .crossbutton {
    border: none;
    background: none;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
  }
  .errorDIv {
    margin-top: 10px;
  }
  .erricon {
    margin-right: 5px;
  }
  .errorVal {
    color: #cc0000;
  }
  .binImage {
    margin-left: 20px;
  }
  .topHeadTitle {
    font-size: 20px;
    font-weight: 600;
  }

  @media screen and (min-width: 570px) {
    .drawer-container {
      width: 360px;
    }
    .topHeadTitle {
      font-size: 28px;
      font-weight: 600;
    }
    .documentTypeDrop {
      width: 270px;
    }
    width: 360px;
    .crossbutton {
      top: 29px;
      right: 48px;
    }
    .buttonsDiv {
      right: 38px;
    }
  }
  @media screen and (min-width: 1200px) {
    .drawer-container {
      width: 420px;
    }
    .documentTypeDrop {
      width: 314px;
    }
    width: 420px;
    .crossbutton {
      top: 26px;
    }
    .buttonsDiv {
      right: 35px;
    }
  }
  @media screen and (min-width: 1900px) {
    .documentDropDown {
      width: 100%;
      height: 32px;
    }
    .documentTypeDrop {
      width: 100%;
    }
    .crossbutton {
      top: 38px;
    }
    .buttonsDiv {
      right: 43px;
    }
    width: 600px;
  }
`;

export const GreenCardStyle = styled.div`
  @keyframes rightTransit {
    0% {
      transform: translate(100%);
    }
    40% {
      transform: translate(0px);
    }
    60% {
      transform: translate(0px);
    }
    100% {
      transform: translate(100%);
    }
  }

  position: fixed;
  top: 180px;
  right: 0;
  padding: 18px 18px 18px 26px;
  background: #d4fad4;
  border-radius: 8px 0px 0px 8px;
  font-size: 14px;
  color: #212121;
  z-index: 3;
  animation-duration: 3s;
  animation-name: rightTransit;

  .border {
    position: absolute;
    width: 10px;
    background: #58c070;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  img {
    margin-right: 12px;
  }
`;

export const DocumentsMobileModalDiv = styled.div`
  width: 100%;
  .topHead {
    height: 84px;
    border-bottom: 2px solid #e6e9f0;
  }
  .topHeadTitle {
    font-size: 20px;
  }
  .drawer-content {
    padding-top: 24px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    padding: 10px;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 200px;
    height: 48px;
    border: none;
    padding: 0 20px;
  }
  .cancel-button {
    height: 48px;
    background: none;
    border: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  .documentDetails {
    margin-top: 24px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 12px 24px;
  }
  .itemsDiv {
    margin-top: 13px;
  }
  .docName {
    margin-left: 12px;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .documentDropDown {
    position: relative;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px;
    border: none;
    width: 316px;
    height: 32px;
  }
  .documentTypeDrop {
    position: absolute;
    top: 38px;
    left: -20px;
    background: #fff;
    width: 316px;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
    .listType {
      padding-top: 10px;
      border-bottom: 1px solid #e6e9f0;
      text-align: start;
    }
    .listType:last-child {
      border-bottom: none;
    }
  }
  .crossbutton {
    border: none;
    background: none;
    position: fixed;
    top: 28px;
    right: 30px;
    width: 30px;
  }
  .errorDIv {
    margin-top: 10px;
  }
  .erricon {
    margin-right: 5px;
  }
  .errorVal {
    color: #cc0000;
  }
  .binImage {
    margin-left: 20px;
    cursor: pointer;
  }

  @media screen and (min-width: 570px) {
    .drawer-container {
      width: 360px;
    }
    width: 360px;
  }
  @media screen and (min-width: 1200px) {
    .drawer-container {
      width: 420px;
    }
    width: 420px;
  }
  @media screen and (min-width: 1900px) {
    .documentDropDown {
      width: 100%;
      height: 32px;
    }
    .documentTypeDrop {
      width: 100%;
    }
    width: 600px;
  }
`;

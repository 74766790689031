import styled from '@emotion/styled';
import tick from '../../../assets/logo/tick.svg';
export const StyledAgencySettings = styled.div`
  .agencySettingsContent {
    padding: 28px 0px 0 20px;
    background: #fff;
  }
  .buttonDiv {
    width: 100%;
    bottom: 50px;
    left: 0;
    z-index: 3;
    padding: 20px;
    padding-left: 52px;
    margin-top: 70px;
    position: fixed;
  }
  .topDiv {
    width: 94vw;
    border-bottom: 1px solid #e6e9f0;
    z-index: 4;
    height: 25px;
  }
  .locationText {
    margin-left: 24px;
    font-weight: 400;
  }
  .naVigatorbuttons {
    colour: #262758;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 400;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    border-bottom: 3px solid #262758;
    cursor: pointer;
    font-size: 14px;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
    font-weight: 600;
  }
  .subText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-top: 25px;
  }
  .CameraLogoDiv {
    margin-top: 16px;
    position: relative;
    margin-bottom: 12px;
  }
  .logo-spinner {
    position: absolute;
    width: 91px;
    height: 91px;
    backdrop-filter: contrast(0.5);
    border-radius: 50px;
    .spinner-border {
      top: calc(50% - 20px);
      left: 29px;
      position: absolute;
    }
  }
  .cameraDiv {
    background: #ecf4f9;
    border-radius: 40px;
    width: 80px;
    height: 80px;
    padding: 31px;
  }
  .logoButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e6e9f0;
    border-radius: 8px;
    width: 169px;
    height: 40px;
    border: none;
    margin-left: 24px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #262758;
    padding: 12px 20px;
  }
  .instructionText {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #8990a3;
    margin-top: auto;
    margin-left: -171px;
  }
  .subText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .formContent .subText {
    margin-bottom: 4px;
  }
  .fields {
    width: 90vw;
    margin-top: 16px;
  }
  .cancel-button {
    width: 140px;
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
    }
  }
  .errorValImge {
    color: #cc0000;
    margin-left: 110px;
    align-items: center;
    font-size: 14px;
    .warning {
      margin-right: 5px;
    }
  }
  .scrollBar {
    height: 72vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 120px;
    padding-right: 20px;
    margin-right: 6px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .addLicenseButton {
    background: #ecf4f9;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .licensedList {
    width: 80px;
    height: 30px;
    border-radius: 8px;
    background: #e6e9f0;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  .licenseCross {
    margin-left: 12px;
  }
  .uploadedImg {
    border-radius: 50%;
    width: 91px;
    height: 91px;
  }
  .locationFields {
    width: 90vw;
    position: relative;
    margin-top: 16px;
    .editBtn {
      top: 20px;
      position: absolute;
      right: 15px;
      width: 15px;
    }
    .binBtn {
      right: 22px;
      position: absolute;
      top: 18px;
    }
    .dividerBtn {
      top: 18px;
      height: 20px;
      position: absolute;
      right: 53px;
      border-left: 1px solid #d1d6e5;
    }
    .licenseDiv {
      width: 90vw;
      overflow: hidden;
    }
  }

  @media screen and (min-width: 576px) {
    .agencySettingsContent {
      padding: 88px 24px 0 24px;
      background: #fafafa;
    }
    .locationSettingsContent {
      padding: 88px 24px 0 24px;
    }
    .fields {
      width: 431px;
    }
    .formContent {
      width: 888px;
    }
    .subText {
      padding-top: 20px;
    }
    .buttonDiv {
      position: fixed;
      width: 85%;
      left: 20px;
      padding-bottom: 80px;
      margin-left: 80px;
      bottom: 10px;
    }
    .scrollBar {
      height: 48vh;
    }
    .locationFields {
      width: 100%;
      .editBtn {
        top: 15px;
        right: 20px;
        width: 20px;
      }
      .binBtn {
        right: 22px;
        top: 18px;
      }
      .dividerBtn {
        top: 20px;
        height: 20px;
        right: 53px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .agencySettingsContent {
      padding: 94px 48px 0 144px;
    }
    .subText {
      padding-top: 25px;
    }
    .locationSettingsContent {
      padding: 94px 24px 0 130px;
    }
    .fields {
      width: 420px;
    }
    .formContent {
      width: 882px;
    }
    .locationFields {
      width: 882px;
    }
    .buttonDiv {
      position: fixed;
      width: 79%;
      left: -38px;
      padding-bottom: 40px;
      background: #fafafa;
    }
    .scrollBar {
      height: 72vh;
    }
    .agentButton {
      width: 220px;
      height: 48px;
    }
    .licensedDiv {
      width: 80%;
      margin-right: 20px;
    }
    .uploadedImg {
      border-radius: 50%;
      width: 91px;
      height: 91px;
      // margin-left:-12px;
    }
    // .selecteStatus {
    //   margin-left: 450px;
    // }
    .LocationContent {
      width: 88vw;
      z-index: 4;
      height: 27px;
    }
    .topDiv {
      width: 80vw;
    }
  }
  @media screen and (min-width: 1900px) {
    .agencySettingsContent {
      padding: 116px 120px 0 208px;
    }
    .LocationContent {
      width: 88vw;
      z-index: 4;
      height: 27px;
    }
    .fields {
      width: 576px;
    }
    .formContent {
      width: 1200px;
    }

    .locationFields {
      width: 1200px;
      .editBtn {
        top: 25px;
        right: 30px;
        width: 20px;
      }
      .binBtn {
        right: 22px;
        top: 26px;
      }
      .dividerBtn {
        top: 26px;
        height: 20px;
        right: 53px;
      }
    }
    .subText {
      margin-top: 15px;
    }
    .CameraLogoDiv {
      margin-bottom: 0;
    }

    .buttonDiv {
      width: 65%;
      left: 110px;
    }
    .licensedDiv {
      width: 65%;
    }
    .topDiv {
      width: 83vw;
    }
  }
`;
export const StyledLicensedStates = styled.div`
  // width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  padding: 20px;

  .crossbtn {
    position: absolute;
    background: #ffffff;
    right: 14px;
    top: 20px;
    border: none;
  }
  .cross {
    width: 20px;
  }
  .topDiv {
    border-bottom: 1px solid #e6e9f0;
    margin-bottom: 8px;
    height: 36px;
  }
  .radio-container {
    padding: 19px 15px;

    background: #ffffff;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    width: 100%;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }

    .form-check-input {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .form-check-input:checked[type='radio'] {
      background-color: #fff;
      box-shadow: none;
    }
    .form-check-input[type='checkbox'] {
      border: 2px solid #8f9fc5;
    }
    .form-check-input:checked[type='checkbox'] {
      background-image: url(${tick});
      background-size: 14px;
      border: 1px solid #000;
      border-radius: 4px;
    }
  }

  .location-input-container {
    display: flex;
    .form-check-input {
      flex-shrink: 0;
    }
    span {
      display: block;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    float: right;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .HeadNavigator {
    position: fixed;
    top: 16px;
  }
  .search-Field {
    width: 87vw;
    padding: 6px;
    margin: 10px 0px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .selectStatus {
    margin-top: 15px;
  }
  .inputSearchField {
    outline: none;
    border: none;
    background: #e6e9f0;
  }

  input:checked + .slider {
    background-color: #58c070;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #58c070;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .buttonsdiv {
    width: 100%;
    border-top: 1px solid #e6e9f0;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 1;
    padding: 20px 90px 20px 30px;
  }
  .cancel-button {
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
  }
  .scrollBar {
    height: 88vh;
    width: 92vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 145px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  @media screen and (min-width: 576px) {
    .entireDiv {
      width: 100%;
    }
    .fields {
      width: 240px;
      margin-right: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .buttonsdiv {
      width: 100%;
    }
    .selecteStatus {
      margin-left: 350px;
      margin-top: 15px;
    }
    .search-Field {
      width: 320px;
    }
  }
  @media screen and (min-width: 1200px) {
    .entireDiv {
      width: 979px;
      padding-left: 5px;
    }
    .cross {
      width: 30px;
    }
    .fields {
      width: 205px;
      margin-right: 20px;
    }
    .scrollBar {
      height: 90vh;
    }
    .selecteStatus {
      margin-left: 342px;
      margin-top: 15px;
    }
    .search-Field {
      width: 401px;
    }
  }
  @media screen and (min-width: 1900px) {
    .entireDiv {
      width: 1320px;
      // padding-left: 10px;
      // padding-right: 15px;
    }
    .cross {
      width: 32px;
    }
    .fields {
      width: 240px;
      margin-right: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .scrollBar {
      height: 85vh;
    }
    .selecteStatus {
      margin-left: 629px;
    }
  }
`;

import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getOpportunities,
  onDeleteOpportunity,
  OpportunitiesSliceState,
  selectAllOpportunities,
} from '../../../store/opportunities/opportunitiesSlice';
import { getCount } from '../../../store/book/bookSlice';
import AddOpportunityDrawer from '../../../components/AddOpportunityDrawer';
import EmptyResults from '../../MyBook/components/Empty';
import PaginationComponent from '../../../components/PaginationComponent';
import OpportunityMobileCard from '../../MyBook/Opportunities/components/OpportunityMobileCard';
import Table from '../../../components/Table';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from './ErrorCard';
import EditOpportunityDetails from '../../OpportunityDetails/EditOpportunityDetails';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { PipeLineOpportunity } from '../elements/styles';
import plus from '../../../assets/logo/plus.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import blackPlus from '../../../assets/logo/blackplus.svg';

const Opportunities = ({
  pipelineUUID,
  selectedProducts,
  selectedOppStatus,
  page,
  setPage,
  searchBy,
}: {
  pipelineUUID: string;
  selectedProducts: any;
  selectedOppStatus: any;
  page: number;
  setPage: Function;
  searchBy: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const opportunities = useSelector(selectAllOpportunities);
  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.status
  );
  const oppCount = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.count
  );

  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [showOpportunity, setShowOpportunity] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [limit, setLimit] = useState(25);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  useEffect(() => {
    dispatch(
      getOpportunities({
        sortBy,
        searchBy,
        orderBy,
        pipelineUUID,
        opportunityStatusUUID: selectedOppStatus?.map(
          (item: any) => item.value
        ),
        productCategoryUUID: selectedProducts?.map((item: any) => item.value),
        limit,
        offset: page * limit,
        pipelineStageUUID: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    pipelineUUID,
    orderBy,
    selectedOppStatus,
    selectedProducts,
    page,
    limit,
    searchBy,
  ]);

  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteOpportunity({
        uuid,
        successCB: () => {
          dispatch(getCount());
          setPage(0);
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [name, setName] = useState('');

  return (
    <div>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting opportunity.'}
        />
      )}
      <PipeLineOpportunity>
        <button
          onClick={() => setShowOpportunity('open')}
          className='addButton d-sm-none'
        >
          <img src={plus} alt='add' />
        </button>
        <div className='d-sm-none mb-4'>{/* TODO: Dropdown in mobile */}</div>
      </PipeLineOpportunity>
      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && opportunities.length > 0 ? (
            <>
              {opportunities.map((item: IOpportunity) => (
                <OpportunityMobileCard
                  opportunity={item}
                  key={item.uuid}
                  setShowDeleteModal={setShowDeleteModal}
                  setUUID={setUUID}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={oppCount}
              />
            </>
          ) : (
            <EmptyResults name='opportunity' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            loadingStatus={loadingStatus}
            setPage={setPage}
            setScroll={() => {
              setScroll(!scroll);
            }}
            count={oppCount}
            name='opportunity'
            columns={[
              // {
              //   Header: () => (
              //     <div className='d-flex align-items-center  h-100'>
              //       <input type='checkbox' className='form-check-input' />
              //     </div>
              //   ),
              //   accessor: 'checkbox',
              //   width: 60,
              //   minWidth: 60,
              //   maxWidth: 60,
              //   Cell: () => (
              //     <div className='d-flex align-items-center  h-100'>
              //       <input type='checkbox' className='form-check-input' />
              //     </div>
              //   ),
              // },
              {
                accessor: 'name',
                resizable: true,
                sortable: true,
                minWidth: 193,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Opportunity name</span>
                    <img
                      className='sort mx-3'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center'>
                    <b
                      onClick={() => {
                        navigate(`/opportunities/${row.original.uuid}`);
                      }}
                    >
                      {row.original.name ? row.original.name : '--'}
                    </b>
                  </div>
                ),
              },
              {
                accessor: 'account',
                Header: 'Account name',
                resizable: true,
                minWidth: 140,
                Cell: ({ row }: { row: any }) => (
                  <>{row.original.Account.name}</>
                ),
              },
              {
                accessor: 'type',
                Header: 'Type',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.Account.AccountType.name.charAt(0) +
                      row.original.Account.AccountType.name
                        .slice(1)
                        .toLowerCase()}
                  </>
                ),
              },
              {
                accessor: 'stage',
                Header: 'Stage',
                minWidth: 140,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.OpportunityStatus.name
                      .charAt(0)
                      .toUpperCase() +
                      row.original.OpportunityStatus.name.slice(1)}
                  </>
                ),
              },

              {
                accessor: 'producer',
                Header: 'Producer',
                resizable: true,
                minWidth: 100,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center h-100'>
                    {row.original.Agent.User.avatar ? (
                      <img
                        src={row.original.Agent.User.avatar}
                        alt=''
                        className='user-pic'
                      />
                    ) : (
                      <div className='user-pic'>
                        {row.original.Agent.User.firstName
                          ?.substring(0, 1)
                          .toUpperCase()}
                        {row.original.Agent.User.lastName
                          ?.substring(0, 1)
                          .toUpperCase()}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                accessor: 'premium',
                Header: 'Premium',
                resizable: true,
                minWidth: 90,
                Cell: ({ row }: { row: any }) => (
                  <b>
                    $
                    {row.original.leastPremium
                      ? row.original.leastPremium.toLocaleString('en-US')
                      : 0}
                  </b>
                ),
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                width: window.innerWidth > 1900 ? 245 : 206,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <div className='actionButton'>Add quote</div>
                    <ActionsDropdown
                      uuid={row.original.uuid}
                      setUUID={setUUID}
                      setShowEditModal={setShowEditModal}
                      setShowDeleteModal={setShowDeleteModal}
                      setName={setName}
                      name={row.original.name}
                      scroll={scroll}
                    />
                  </div>
                ),
              },
            ]}
            data={opportunities}
          />
        </div>
      </div>
      {showOpportunity !== 'closed' && (
        <AddOpportunityDrawer
          show={showOpportunity}
          closeModal={setShowOpportunity}
        />
      )}
      {showEditModal !== 'closed' && (
        <EditOpportunityDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={UUID}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='opportunity'
          name={name}
        />
      )}
    </div>
  );
};

export default Opportunities;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  setShowDeleteModal,
  setName,
  scroll,
  name,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  setShowDeleteModal: Function;
  scroll: boolean;
  setName: Function;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef<HTMLButtonElement>(null);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='dropdown-div  position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 170,
          }}
        >
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
            }}
            tabIndex={0}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div className='borderDiv'></div>
          <div
            className='edit-div'
            style={{ paddingTop: '10px' }}
            onClick={() => navigate(`/opportunities/${uuid}/activities`)}
            tabIndex={1}
          >
            Add activity <img src={blackPlus} alt='plusIcon' />
          </div>
          <div className='borderDiv'></div>
          <div
            className='delete-div'
            onClick={() => {
              setShowDeleteModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
              setName(name);
            }}
            tabIndex={2}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};

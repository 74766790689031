import styled from '@emotion/styled';

export const CustomFieldsContent = styled.div`
  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
    margin-left: 0;
  }
  .editButton {
    padding: 8px 35px;
    background: #ecf4f9;
    border-radius: 8px;
    border: 0;
    font-weight: 600;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .vector .dropdown-div {
    top: 33px;
    bottom: unset;
    .delete-div {
      border-top: none;
    }
  }
  .field-section .agency-fields .options-field {
    width: calc(100% - 50px);
    margin-top: 1px;
    margin-bottom: 2px;
    .filled.input-container {
      border: none;
      &:hover {
        box-shadow: none;
      }
    }
    .input-container {
      border: none;
      &:hover {
        box-shadow: none;
      }
    }
    &:hover {
      box-shadow: none;
    }
  }

  .crossbutton {
    border: none;
    background: none;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
  }

  .add-field-mobile img {
    filter: invert(1);
  }
  .table {
    padding-bottom: 25px;
  }
  .fieldType {
    text-transform: capitalize;
  }
  .drag-icon {
    margin-left: -15px;
    margin-right: 15px;
  }
  .addOptionsButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    img {
      margin-right: 14px;
    }
  }
  .add-customField-hr {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .addButton.add-customField-button {
    background: #262758;
    border-radius: 8px;
    width: 264px;
    display: flex;
    justify-content: space-between;
    color: #f5f7fc;
    align-items: center;
    img {
      filter: invert(1);
    }
  }
  .form-appear-check {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .radio-container {
    display: flex;
    position: relative;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    background: #ffffff;
    padding: 0 16px;
    align-items: center;
    margin-bottom: 16px;
    .input-field {
      padding: 0;
      &::hover {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .input-container {
      border: none;
      &::hover {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .options-field::hover {
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .addOptionsButton {
      margin-bottom: 4px;
    }
  }
`;

export const CustomMobileCard = styled.div`
  color: #5a5f70;

  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: none;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0px;
    bottom: -65px;
    font-size: 14px;
    z-index: 1;
    width: 144px;
    cursor: pointer;
  }
  .edit-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delete-div {
    color: #9a0a0d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e6e9f0;
  }
  .appear-choice {
    // color: #191919;
    font-weight: 600;
  }
  .appear-form-container {
    border-right: 1px solid #d1d6e5;
    padding-right: 16px;
    margin-right: 15px;
  }
  .actionsButton {
    padding: 0;
  }
  .buttonsDiv {
    margin-bottom: 0;
  }
  .mobile-card-customField {
    padding-top: 0;
  }
`;

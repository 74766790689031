import { SuccessCardStyle } from '../elements/styles';
import success from '../../../assets/logo/success.svg';
interface IProps {
  message: string;
  show: string;
}

const SuccessCard = ({ message, show }: IProps) => {
  return (
    <SuccessCardStyle>
      <div className='border'></div>
      <div className='message d-flex align-items-start'>
        <img src={success} alt='' className='error-icon' />
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </SuccessCardStyle>
  );
};

export default SuccessCard;

import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { AppDispatch } from '../../../../store';
import {
  LeadsSliceState,
  fetchLead,
  selectLeadDetails,
  onQualifyLead,
  onJunkLead,
  selectCount,
  getLeadCount,
} from '../../../../store/leads/leadsSlice';
import { getCount } from '../../../../store/book/bookSlice';
import { selectUser } from '../../../../store/user/userSlice';
import {
  createEvent,
  createNote,
  createTask,
  updateNoteDetails,
} from '../../../../store/tasks/tasksSlice';
import LeadInnerMobileTab from './LeadInnerMobileTab';
import SuccessCard from '../../../../components/SuccessCard';
import WarningCard from '../../../Pipeline/components/WarningCard';
import GreenCard from '../../../Pipeline/components/SuccessCard';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import Modal from '../../../../components/Modal';
import EditLeadDrawer from '../../../../components/EditLeadDrawer';
import LeadsInnerTab from './LeadsInnerTab';
import MobileNav from '../../../../components/MobileTabNav';
import QualifyLeadsModal from '../../../../components/QualifyLeadsModal';
import LeadJunkModal from '../../../../components/LeadJunkModal';
import LeadDetailsDrawer from './LeadDetailsDrawer';
import { LeadDetailsDiv } from '../../elements/styles';
import { DetailsDiv } from '../../../../styles/DetailsDiv';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import { contacts } from '../../../../assets/nav-icons';
import dropdown from '../../../../assets/logo/dropdown.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import rightArrow from '../../../../assets/logo/right-arrow.svg';
import cross from '../../../../assets/logo/cross.svg';
import edit from '../../../../assets/logo/edit.svg';
import ham from '../../../../assets/logo/hamburger.png';
import junk from '../../../../assets/logo/junk.svg';

const LeadDetailModal = ({
  show,
  closeModal,
  UUID,
}: {
  show: string;
  closeModal: Function;
  UUID: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [showDetailsDrawer, setShowDetailsDrawer] = useState('closed');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDropdown, setShowDropdown] = useState(false);
  const [qulifyMessage, setQulifyMessage] = useState('');
  const [showQualifyLead, setShowQualifyLead] = useState(false);
  const [loading, setLoading] = useState('idle');
  const [showJunkLead, setShowJunkLead] = useState(false);
  const [file, setFile] = useState<any>();
  const [ApiSuccess, setApiSuccess] = useState(false);
  const [docTypeUUID, setDocTypeUUID] = useState('');
  const [showDrop, setShowDrop] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [noteAdded, setNoteAdded] = useState(false);
  const [added, setAdded] = useState('');
  const [edited, setEdited] = useState('');
  const [deleted, setDeleted] = useState('');
  const dropRef = useRef(null);
  const dropDownRef = useRef(null);

  const user = useSelector(selectUser);
  const LeadCount = useSelector(selectCount);
  const loadingStatus = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.leadDetailsStatus
  );
  const leadDetails = useSelector(selectLeadDetails);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(fetchLead({ UUID: UUID, successCB: () => {} }));
      if (UUID) dispatch(getLeadCount({ uuid: UUID }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UUID]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  useOutsideClick(dropRef, setShowDropdown);
  useOutsideClick(dropDownRef, setShowDrop);

  const [showSuccedMessageOnQualify, setShowSuccedMessageOnQualify] =
    useState(false);

  const [showJunkLeadSuccess, setShowJunkLeadSuccess] = useState(false);

  const qualifyFunction = (uuid: string) => {
    if (loading === 'idle' || loading === 'succeeded') {
      setLoading('loading');
      dispatch(
        onQualifyLead({
          uuid,
          successCB: () => {
            dispatch(getCount());
            setShowQualifyLead(false);
            setLoading('succeeded');
            setShowSuccedMessageOnQualify(true);
            setTimeout(() => {
              setShowSuccedMessageOnQualify(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: (err: any) => {
            setShowEditModal('open');
            setLoading('succeeded');
            setQulifyMessage(err);
            setShowQualifyLead(false);
          },
        })
      );
    }
  };

  const junkFunction = (uuid: string, data: { junkReason: string }) => {
    if (loading === 'idle' || loading === 'succeeded') {
      setLoading('loading');
      dispatch(
        onJunkLead({
          uuid,
          data,
          successCB: () => {
            dispatch(getCount());
            setShowJunkLead(false);
            setShowJunkLeadSuccess(true);
            setLoading('succeeded');
            setTimeout(() => {
              setShowJunkLeadSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: (err: any) => {
            setLoading('succeeded');
            setQulifyMessage(err);
            setShowQualifyLead(false);
          },
        })
      );
    }
  };

  const handleAddNote = (value: any) => {
    setNoteAdded(false);

    value.leadUUID = UUID;
    setAddNoteLoading(true);
    dispatch(
      createNote({
        data: value,
        successCB: () => {
          dispatch(getLeadCount({ uuid: UUID }));
          setAddNoteLoading(false);
          setNoteAdded(true);
          setAdded('Note');
          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: () => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const handleAddTask = (value: any) => {
    setNoteAdded(!noteAdded);
    setApiSuccess(true);

    value.leadUUID = UUID;
    setAddNoteLoading(true);
    let formData = new FormData();
    formData.append('leadUUID', value.leadUUID);
    formData.append('docFile', file);
    formData.append('assigneeUUID', value.assigneeUUID);
    formData.append('taskStatusUUID', value.taskStatusUUID);
    formData.append('taskTypeUUID', value.taskTypeUUID);
    formData.append('dueDate', value.dueDate);
    formData.append('description', value.description);
    formData.append('documentTypeUUID', docTypeUUID);
    formData.append('taskPriorityUUID', value.taskPriorityUUID);
    dispatch(
      createTask({
        data: formData,
        successCB: () => {
          dispatch(getLeadCount({ uuid: UUID }));
          setApiSuccess(false);
          setAddNoteLoading(false);
          setNoteAdded(true);
          setAdded('Task');
          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: () => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const handleAddEvent = (value: any) => {
    setNoteAdded(!noteAdded);
    setApiSuccess(true);

    setAddNoteLoading(true);
    let formData = new FormData();
    formData.append('leadUUID', UUID);
    formData.append('docFile', file);
    formData.append('documentTypeUUID', docTypeUUID);
    for (let key in value) {
      if (key === 'guests') {
        formData.append(key, JSON.stringify(value[key]));
      } else {
        formData.append(key, value[key]);
      }
    }

    dispatch(
      createEvent({
        data: formData,
        successCB: () => {
          dispatch(getLeadCount({ uuid: UUID }));
          setApiSuccess(false);
          setAddNoteLoading(false);
          setNoteAdded(!noteAdded);
          setAdded('Event');
          setTimeout(() => {
            setAdded('');
          }, 2700);
        },
        errorCB: () => {
          setAddNoteLoading(false);
          setAdded('');
        },
      })
    );
  };

  const handleUpdateNote = (UUID: any, data: any, uuid: string) => {
    data.leadUUID = uuid;
    setNoteAdded(false);
    setAddNoteLoading(true);
    dispatch(
      updateNoteDetails({
        uuid: UUID,
        data: data,
        successCB: () => {
          setAddNoteLoading(false);
          setNoteAdded(true);
        },
      })
    );
  };

  return (
    <>
      <LeadDetailsDiv>
        <DetailsDiv className='details-modal leads-detail'>
          <Modal open={show} align='right'>
            {(showSuccedMessageOnQualify || showJunkLeadSuccess) && (
              <>
                <SuccessAlign>
                  <div
                    className={`success-container ${
                      (showSuccedMessageOnQualify || showJunkLeadSuccess) &&
                      'open-drawer'
                    } `}
                  >
                    <SuccessCard
                      title={
                        showSuccedMessageOnQualify
                          ? 'Lead qualified'
                          : showJunkLeadSuccess
                          ? 'Lead junked'
                          : ''
                      }
                      message={
                        showSuccedMessageOnQualify
                          ? 'Lead has been Qualified'
                          : showJunkLeadSuccess
                          ? 'Lead has been junked'
                          : ''
                      }
                    />
                    <button
                      onClick={() => {
                        setShowSuccedMessageOnQualify(false);
                      }}
                      className='crossbutton'
                    >
                      <img src={cross} className='cross' alt='cross-icon' />
                    </button>
                  </div>
                </SuccessAlign>
              </>
            )}
            {loadingStatus === 'idle' ? (
              <Spinner animation='border' variant='info' />
            ) : (
              <div className='content-div'>
                {added && (
                  <GreenCard message={`<b>${added}</b>  Added `} show='open' />
                )}
                {edited && (
                  <WarningCard
                    message={`<b>${edited}</b>  Updated `}
                    title={`Success updating lead.`}
                  />
                )}
                {deleted && (
                  <ErrorCard
                    message={`<b>${deleted}</b>has been deleted `}
                    title={'Success deleting lead.'}
                  />
                )}
                <div className='header-div d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center'>
                    <button
                      onClick={() => setShowDetailsDrawer('open')}
                      className='secondaryButton me-3 d-block d-xl-none'
                    >
                      <img alt='' src={ham} />
                    </button>
                    <div className='profile-pic d-none d-sm-flex align-items-center justify-content-center'>
                      {user?.firstName || user?.lastName
                        ? user?.firstName?.substring(0, 1).toUpperCase() +
                          user?.lastName?.substring(0, 1).toUpperCase()
                        : user?.email[0]?.substring(0, 1).toUpperCase()}
                    </div>
                    <div>
                      <div className=' d-flex align-items-center mb-xl-2 text-overflow-container'>
                        <h2>
                          {leadDetails?.data?.contact.firstName +
                            ' ' +
                            leadDetails?.data?.contact.lastName}
                        </h2>
                        <span className='tooltiptext tooltip-producer'>
                          <span className='tooltipInnertext'>
                            {leadDetails?.data?.contact.firstName
                              ? leadDetails?.data?.contact.firstName +
                                ' ' +
                                leadDetails?.data?.contact.lastName
                              : '--'}
                          </span>
                        </span>
                        <span className='status statusInactive'>
                          {leadDetails?.LeadStage?.name}
                        </span>
                      </div>

                      <div className='header-info d-none d-xl-block'>
                        <img src={contacts} alt='' className='contact-img' />
                        {leadDetails?.data?.accountInfo?.accountType ===
                        'PERSONAL' ? (
                          <span className='text-overflow-container'>
                            <span className='name-text-overflow'>
                              {leadDetails?.data?.contact.firstName
                                ? leadDetails?.data?.contact.firstName +
                                  ' ' +
                                  leadDetails?.data?.contact.lastName
                                : '--'}
                            </span>
                            <span className='tooltiptext tooltip-producer'>
                              <span className='tooltipInnertext'>
                                {leadDetails?.data?.contact.firstName
                                  ? leadDetails?.data?.contact.firstName +
                                    ' ' +
                                    leadDetails?.data?.contact.lastName
                                  : '--'}
                              </span>
                            </span>
                          </span>
                        ) : (
                          <span className='text-overflow-container'>
                            <span className='name-text-overflow'>
                              {leadDetails?.data?.business.businessName
                                ? leadDetails?.data?.business.businessName
                                : '--'}
                            </span>
                            <span className='tooltiptext tooltip-producer'>
                              <span className='tooltipInnertext'>
                                {leadDetails?.data?.business.businessName
                                  ? leadDetails?.data?.business.businessName
                                  : '--'}
                              </span>
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='d-none d-sm-flex align-items-center'>
                    <button
                      className='primaryButton me-4'
                      onClick={() => setShowQualifyLead(true)}
                    >
                      Qualify lead
                      <img className='qualifyArrow' src={rightArrow} alt='' />
                    </button>

                    <button
                      className='dropDownButton'
                      onClick={() => setShowDrop(!showDrop)}
                      ref={dropDownRef}
                    >
                      <img src={dropdown} alt='' />
                      {showDrop && (
                        <div className='drop-div'>
                          <div
                            className='editdiv'
                            onClick={() => {
                              setShowEditModal('open');
                              setShowDrop(false);
                            }}
                          >
                            Edit <img src={edit} alt='edit' />
                          </div>
                          <div className='borderDiv'></div>
                          <div
                            className='junkdiv'
                            onClick={() => {
                              setShowJunkLead(true);
                              setShowDrop(false);
                            }}
                          >
                            Mark as Junked <img src={junk} alt='junk-icon' />
                          </div>
                        </div>
                      )}
                    </button>

                    <button
                      onClick={() => {
                        onCloseModal();
                      }}
                      className='crossbutton'
                    >
                      <img src={cross} className='cross' alt='cross-icon' />
                    </button>
                  </div>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='crossbutton d-sm-none'
                  >
                    <img src={cross} className='cross' alt='cross-icon' />
                  </button>
                </div>
                <div
                  className='d-flex '
                  style={{ borderBottom: '1px solid #E6E9F0' }}
                >
                  <div className='info-div d-none d-xl-block'>
                    {leadDetails && (
                      <LeadDetailsDrawer
                        leadDetails={leadDetails}
                        setShowEditModal={setShowEditModal}
                      />
                    )}
                  </div>
                  <div className='d-none d-sm-block options-div'>
                    <LeadsInnerTab
                      leadUUID={UUID}
                      setFile={setFile}
                      file={file}
                      setDocTypeUUID={setDocTypeUUID}
                      docTypeUUID={docTypeUUID}
                      addNote={handleAddNote}
                      LeadDocumentCount={LeadCount}
                      addNoteLoading={addNoteLoading}
                      ApiSuccess={ApiSuccess}
                      handleAddTask={handleAddTask}
                      handleAddEvent={handleAddEvent}
                      setAdded={setAdded}
                      setEdited={setEdited}
                      edited={edited}
                      added={added}
                    />
                  </div>
                </div>
                <div className='d-block d-sm-none'>
                  <LeadInnerMobileTab
                    leadUUID={UUID}
                    ApiSuccess={ApiSuccess}
                    LeadDocumentCount={LeadCount}
                    noteAdded={noteAdded}
                    updateNote={handleUpdateNote}
                    addNote={handleAddNote}
                    addNoteLoading={addNoteLoading}
                    addTask={handleAddTask}
                    addEvent={handleAddEvent}
                    setAdded={setAdded}
                    setEdited={setEdited}
                    edited={edited}
                    added={added}
                    setDeleted={setDeleted}
                  />
                </div>
                {/* <button className='addButton d-sm-none'>
                  <img src={plus} alt='add' />
                </button> */}
                <div className='mobile-bottombar d-flex d-sm-none align-items-center justify-content-between'>
                  <button
                    className='dropDownButton'
                    onClick={() => setShowDropdown(!showDropdown)}
                    ref={dropRef}
                  >
                    <img src={dropdown} alt='' />
                  </button>
                  <button
                    className='primaryButton leadQualifyMobButton  d-flex justify-content-between align-items-center'
                    onClick={() => setShowQualifyLead(true)}
                  >
                    Qualify lead
                    <img src={arrow} alt='' />
                  </button>
                </div>

                {showQualifyLead ? (
                  <QualifyLeadsModal
                    open={showQualifyLead}
                    closeModal={setShowQualifyLead}
                    qualifyFunction={qualifyFunction}
                    uuid={UUID}
                    loading={loading}
                  />
                ) : null}
                {showJunkLead ? (
                  <LeadJunkModal
                    open={showJunkLead}
                    closeModal={setShowJunkLead}
                    junkFunction={junkFunction}
                    uuid={UUID}
                    loading={loadingStatus}
                  />
                ) : null}
                {showDetailsDrawer !== 'closed' && (
                  <Modal open={showDetailsDrawer} align='left'>
                    <div className='modal-content-div'>
                      <button
                        onClick={() => {
                          setShowDetailsDrawer('closing');
                          setTimeout(() => {
                            setShowDetailsDrawer('closed');
                          }, 300);
                        }}
                        className='crossbutton secondaryButton'
                      >
                        <img src={cross} className='cross' alt='cross-icon' />
                      </button>
                      <div className='header-div d-flex align-items-center'>
                        <div className='profile-pic d-flex align-items-center justify-content-center'>
                          {user?.firstName || user?.lastName
                            ? user?.firstName?.substring(0, 1).toUpperCase() +
                              user?.lastName?.substring(0, 1).toUpperCase()
                            : user?.email[0]?.substring(0, 1).toUpperCase()}
                        </div>
                        <div>
                          <h2 className='mb-1'>
                            {leadDetails?.data?.contact.firstName +
                              ' ' +
                              leadDetails?.data?.contact.lastName}
                          </h2>
                          <div className='header-info'>
                            <span className='d-flex align-items-center'>
                              <img
                                src={contacts}
                                alt=''
                                className='contact-img'
                              />
                              {leadDetails?.data?.contact.firstName +
                                ' ' +
                                leadDetails?.data?.contact.lastName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='info-div'>
                        {leadDetails && (
                          <LeadDetailsDrawer
                            leadDetails={leadDetails}
                            setShowEditModal={setShowEditModal}
                          />
                        )}
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
            )}
            {showEditModal !== 'closed' && (
              <EditLeadDrawer
                show={showEditModal}
                closeModal={setShowEditModal}
                qualifyMessage={qulifyMessage}
                setQulifyMessage={setQulifyMessage}
                UUID={UUID}
              />
            )}
          </Modal>
        </DetailsDiv>
      </LeadDetailsDiv>

      <div className='d-xl-none'>
        <MobileNav />
      </div>
    </>
  );
};

export default LeadDetailModal;

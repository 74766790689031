import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { AppDispatch } from '../../../store';
import { createTask, createEvent } from '../../../store/tasks/tasksSlice';
import { fetchAgents } from '../../../api/agents';
import { fetchContacts } from '../../../api/contact';
import { fetchAccounts } from '../../../api/account';
import { fetchOpportunities } from '../../../api/opportunities';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  fetchTaskType,
  fetchTaskPriority,
  fetchTaskStatus,
} from '../../../api/task';
import { fetchEventType } from '../../../api/events';
import { getDocumentType } from '../../../api/documents';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import SuccessCard from '../../../components/SuccessCard';
import CustomInputField from './CustomInputField';
import CustomDateField from './CustomDateField';
import DocumentsField from '../../../components/DocumentsField';
import MarkdownEditor from '../../../components/MarkdownEditor';
import Modal from '../../../components/Modal';
import { IProducer } from '../../../interfaces/Agent';
import { IContact } from '../../../interfaces/Contact';
import { IAccount } from '../../../interfaces/Account';
import { StyledAddModal } from '../elements/styles';
import LocalStorageService from '../../../utils/LocalStorageService';

import TimePicker from '../../../components/TimePicker';
import GuestListDropDown from './GuestListDropDown';
import TextAreaField from './TextAreaField';
import Skeleton from 'react-loading-skeleton';
import LinkToField from './LinkToField';
import { fetchLeads } from '../../../api/lead';
import { fetchPolicies } from '../../../api/policy';
import blackplus from '../../../assets/logo/blackplus.svg';
import tick from '../../../assets/logo/tick.svg';
import phone from '../../../assets/logo/Dent/phone.png';
import mail from '../../../assets/logo/Dent/mail.png';
import calender from '../../../assets/logo/Dent/calendar.png';
import others from '../../../assets/logo/Dent/others.png';
import othersvector from '../../../assets/logo/Dent/others-active.png';
import warning from '../../../assets/logo/warning.svg';
import redVector from '../../../assets/logo/redVector.svg';
import edit from '../../../assets/logo/edit.svg';
import document from '../../../assets/logo/document.svg';
import bin from '../../../assets/logo/bin.svg';
import message from '../../../assets/logo/Dent/message.png';
import cross from '../../../assets/logo/cross.svg';
import vector from '../../../assets/logo/dropdown.svg';
import messagevector from '../../../assets/logo/Dent/message-active.png';
import phonevector from '../../../assets/logo/Dent/phone-active.png';
import calendarvector from '../../../assets/logo/Dent/calendar-active.png';
import emailvector from '../../../assets/logo/Dent/mail-active.png';

const AddTaskEventModal = ({
  show,
  closeModal,
  activityName,
  setAction,
}: {
  show: string;
  activityName?: string;
  closeModal: Function;
  setAction: Function;
}) => {
  const [accountList, setAccounts] = useState<any[]>([]);
  const [accountUUID, setAccountUUID] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  ///////////////////

  const [contactList, setContacts] = useState<any[]>([]);
  const [contactUUID, setContactUUID] = useState('');
  const [contactSearch, setContactSearch] = useState('');

  /////////////

  const [opportunityList, setOpportunity] = useState<any[]>([]);
  const [opportunityUUID, setOpportunityUUID] = useState('');
  const [opportunitySearch, setOpportunitySearch] = useState('');

  /////////////
  const [leadList, setLeads] = useState<any[]>([]);
  const [leadUUID, setLeadUUID] = useState('');
  const [leadSearch, setLeadSearch] = useState('');

  /////////////
  const [policyList, setPolicies] = useState<any[]>([]);
  const [policyUUID, setPolicyUUID] = useState('');
  const [policySearch, setPolicySearch] = useState('');

  /////////////

  const [showSuccess, setShowSuccess] = useState(false);
  const [task, showTask] = useState(true);
  const [assignees, setAssignees] = useState<IProducer[]>([]);
  const [assignee, setAssignee] = useState('');
  const [assigneesList, setAssigneesList] = useState(false);
  const [priorityList, setPriorityList] = useState(false);
  const [assigneeUUID, setAssigneeUUID] = useState('');
  const [taskType, setTaskType] = useState([]);
  const [tastPriorityList, setTastPriorityList] = useState([]);
  const [tastPriority, setTastPriority] = useState('');
  const [priorityUUID, setPriorityUUID] = useState('');
  const [linkedTo, setLinkedTo] = useState('Contact');
  const [linkToDropDown, setLinkToDropDown] = useState(false);
  const [description, setDescription] = useState('');
  const [statusUUID, setStatusUUID] = useState('');
  const [dueDate, setDueDate] = useState<any>({});
  const [typeUUID, setTypeUUID] = useState('');
  const [eventTypeUUID, setEventTypeUUID] = useState('');
  const [eventTypeName, setEventTypeName] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [note, setNote] = useState('');
  const [guest, setGuest] = useState<any>([]);
  const [guestList, showGuestList] = useState(false);
  const [guestManageDropDown, setGuestManageDropDown] = useState(false);
  const [guestUUID, setGuestUUID] = useState<any>([]);
  const [eventTitle, setEventTitle] = useState('');
  const [scheduledDate, setScheduledDate] = useState<any>({});
  const [eventTypes, setEventType] = useState([{}]);
  const [file, setFile] = useState<any>();
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [DoctypeUUID, setDocTypeUUID] = useState('');
  const [docType, setDocType] = useState('');
  const [HoverUUID, setHoverUUID] = useState('');

  ////////
  const [DueTimeModal, setDueTimeModal] = useState(false);
  const [EventSheduleTime, setEventSheduleTime] = useState(false);
  const [duetimeVal, setDueTimeVal] = useState('');
  const [scheduledTimeVal, setScheduledTimeVal] = useState('');
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setDueTimeModal);
  const dropPriorityRef = useRef(null);
  useOutsideClick(dropPriorityRef, setPriorityList);
  const dropEventRef = useRef(null);
  useOutsideClick(dropEventRef, setEventSheduleTime);
  const dropManageGuestRef = useRef(null);
  useOutsideClick(dropManageGuestRef, setGuestManageDropDown);
  const dropGuestRef = useRef(null);
  useOutsideClick(dropGuestRef, showGuestList);
  ///////////error//////////
  const [linkToMsg, setLinktoMsg] = useState('');
  const [typeMsg, setTypeMsg] = useState('');
  const [dueDateMsg, setDueDateMsg] = useState('');
  const [eventtitleMsg, setEventTitleMsg] = useState('');
  const [eventTypeMsg, setEventTypeMsg] = useState('');
  const [eventGuestMsg, setEventGuestMsg] = useState('');
  const [eventScheduledDateMsg, setEventScheduledDateMsg] = useState('');
  const [dropDown, setDropDown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [fileError, setFileError] = useState('');
  const [descriptionError, setdescriptionError] = useState('');
  const [noteError, setNoteError] = useState('');
  const [textCheck, setTextCheck] = useState('');
  const [titleError, setTitleError] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  var x = 15;
  let Times = [];
  var tt = 0;
  var ap = ['AM', 'PM'];

  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = tt % 60;
    Times[i] =
      ('' + (hh === 12 ? 12 : hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)];
    tt = tt + x;
  }
  let user = LocalStorageService.getUser();
  const { handleSubmit, register, control, reset } = useForm({
    //     resolver: yupResolver(PipelineSchema),
  });
  useEffect(() => {
    if (activityName === 'Events') {
      showTask(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityName]);
  useEffect(() => {
    if (user?.UserRole.name === 'AGENT') {
      setAssignee(user.firstName + ' ' + user.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setFile('');
    setDocTypeUUID('');
    setDocType('');
    setFileError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (user?.UserRole.name !== 'AGENT') {
      fetchAgents(
        (data: IProducer[]) => {
          setAssignees(data);
          const user = data.find(
            (data: any) =>
              data.User?.uuid === LocalStorageService.getUser()?.uuid
          );
          let fullName = user?.User?.firstName + ' ' + user?.User?.lastName;
          setAssignee(fullName);
          user && setAssigneeUUID(user?.User?.uuid);
        },
        'id',
        'asc',
        '',
        'True'
      );
    }

    Promise.all([
      fetchTaskType((data: any) => {
        setTaskType(data);
      }),
      fetchEventType((data: any) => {
        setEventType(data);
      }),
      fetchTaskPriority((data: any) => {
        setTastPriorityList(data);
        setTastPriority(data.find((el: any) => el.name === 'LOW').name);
        setPriorityUUID(data.find((el: any) => el.name === 'LOW').uuid);
      }),
      fetchTaskStatus((data: any) => {
        let opendata = data.find((el: any) => el.name === 'OPEN');
        setStatusUUID(opendata?.uuid);
      }),
      setLinktoMsg(''),
    ]).then(() => setIsLoaded(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    setAccounts([]);
    setContacts([]);
    setOpportunity([]);
    setLinktoMsg('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedTo]);

  useEffect(() => {
    if (guestList) {
      setLoadingStatus('loading');
      fetchContacts(
        'firstName',
        'asc',
        '',
        25,
        0,
        'True',
        (data: IContact[]) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        'PERSONAL'
      );
    }
  }, [linkedTo, guestList]);

  const loadOpportunity = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded')
      fetchOpportunities(
        'name',
        'asc',
        value,

        '',
        '',
        (data: any) => {
          setOpportunity(data?.opportunities);
          setLoadingStatus('succeeded');
        },
        '',
        '',
        25,
        0
      );
    setLinktoMsg('');
  };

  const loadLead = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchLeads('name', 'asc', value, 25, 0, '', '', '', '', (data: any) => {
        setLeads(data.leads);
        setLoadingStatus('succeeded');
      });
      setLinktoMsg('');
    }
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
        },
        'True'
      );
    }
  };

  const loadContact = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchContacts(
        'firstName',
        'asc',
        value,
        25,
        0,
        'True',
        (data: IContact[]) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        'PERSONAL'
      );
    }
  };

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchPolicies(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        [],
        false,
        '',
        '',
        '',
        '',
        '',
        (data: any) => {
          setPolicies(data?.policies);
          setLoadingStatus('succeeded');
        }
      );
      setLinktoMsg('');
    }
  };

  const onSet = (name: string, value: string) => {
    task
      ? setDescription(value)
      : name === 'name'
      ? setEventTitle(value)
      : setDescription(value);
  };
  const onSetDate = (name: string, value: string) => {
    task
      ? setDueDate({ dueDate: value })
      : name === 'scheduledDate'
      ? setScheduledDate({ scheduledDate: value })
      : setDueDate({ dueDate: value });
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const dueDateTime = moment(
    moment(dueDate.dueDate).format('YYYY-MM-DD') +
      moment(duetimeVal && duetimeVal).format('hh:mm A'),
    'YYYY-MM-DDLT'
  ).toDate();
  const sheduleDateTime = moment(
    moment(scheduledDate.scheduledDate).format('YYYY-MM-DD') +
      moment(scheduledTimeVal).format('hh:mm A'),
    'YYYY-MM-DDLT'
  ).toDate();

  const onSubmit = () => {
    if (task && linkedTo === 'Contact' && !contactUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (task && linkedTo === 'Account' && !accountUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (task && linkedTo === 'Opportunity' && !opportunityUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (task && linkedTo === 'Lead' && !leadUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (task && linkedTo === 'Policy' && !policyUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (task && !typeUUID) {
      setTypeMsg('Please select a task type');
      return;
    } else if (task && !dueDate.dueDate) {
      setDueDateMsg('Please select a Due date');
      return;
    } else if (task && !duetimeVal) {
      setDueDateMsg('Please select the time');
      return;
    } else if (task && dueDateTime < new Date()) {
      setDueDateMsg('Please select a future time');
      return;
    }
    if (task) {
      if (file && !DoctypeUUID) {
        setError('Please provide a document type');
        return;
      }
      let formData = new FormData();
      formData.append('docFile', file);
      formData.append('documentTypeUUID', DoctypeUUID);
      if (linkedTo === 'Opportunity') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', opportunityUUID);
        formData.append('policyUUID', '');
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Account') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', accountUUID);
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Contact') {
        formData.append('contactUUID', contactUUID);
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Policy') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', policyUUID);
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Lead') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('leadUUID', leadUUID);
      }
      formData.append('assigneeUUID', assigneeUUID);
      formData.append('taskPriorityUUID', priorityUUID);
      formData.append('dueDate', dueDateTime.toUTCString());
      formData.append('taskStatusUUID', statusUUID);
      formData.append('taskTypeUUID', typeUUID);
      let canSubmit = true;
      if (description) {
        if (!description.trim()) {
          canSubmit = false;
          setdescriptionError('Description cannot be blank space');
        }
      }

      formData.append('description', description);
      if (note) {
        if (!textCheck.trim()) {
          canSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      formData.append('notes', JSON.stringify([{ text: note }]));
      if (canSubmit) {
        dispatch(
          createTask({
            data: formData,
            successCB: () => {
              setAction(true);
              setDisabled(true);
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
              closeModal('closing');
              setTimeout(() => {
                reset();
                setShowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: () => {
              setDisabled(false);
            },
          })
        );
      }
    } else {
      if (!task && linkedTo === 'Contact' && !contactUUID) {
        setLinktoMsg(`Please select a ${linkedTo}`);
        return;
      } else if (!task && linkedTo === 'Account' && !accountUUID) {
        setLinktoMsg(`Please select a ${linkedTo}`);
        return;
      } else if (!task && linkedTo === 'Opportunity' && !opportunityUUID) {
        setLinktoMsg(`Please select a ${linkedTo}`);
        return;
      } else if (!task && linkedTo === 'Policy' && !policyUUID) {
        setLinktoMsg(`Please select a ${linkedTo}`);
        return;
      } else if (!task && linkedTo === 'Lead' && !leadUUID) {
        setLinktoMsg(`Please select a ${linkedTo}`);
        return;
      } else if (!task && !eventTitle) {
        setEventTitleMsg(`Please enter a title `);
        return;
      } else if (!task && !eventTypeUUID) {
        setEventTypeMsg(`Please select a  Type `);
        return;
      } else if (!task && !scheduledDate.scheduledDate) {
        setEventScheduledDateMsg(`Please select a start date`);
        return;
      } else if (!task && !scheduledTimeVal) {
        setEventScheduledDateMsg(`Please select the time.`);
        return;
      } else if (!task && !dueDate.dueDate) {
        setDueDateMsg(`Please select a end date `);
        return;
      } else if (!task && !duetimeVal) {
        setDueDateMsg(`Please select the end time.`);
        return;
      } else if (!task && guest.length < 1) {
        setEventGuestMsg(`Please  add Guest `);
        return;
      } else if (!task && sheduleDateTime < new Date()) {
        setEventScheduledDateMsg('Please Select a valid Time');
        return;
      } else if (!task && dueDateTime < sheduleDateTime) {
        setDueDateMsg('Invalid Time.');
        return;
      }
      if (file && !DoctypeUUID) {
        setError('Please provide a document type');
        return;
      }
      let formData = new FormData();
      let guests = guestUUID.map((item: any) => ({ guestUUID: item }));

      formData.append('docFile', file);
      formData.append('documentTypeUUID', DoctypeUUID);
      if (linkedTo === 'Opportunity') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', opportunityUUID);
        formData.append('policyUUID', '');
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Account') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', accountUUID);
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Contact') {
        formData.append('contactUUID', contactUUID);
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('loeadUUID', '');
      }
      if (linkedTo === 'Policy') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', policyUUID);
        formData.append('leadUUID', '');
      }
      if (linkedTo === 'Lead') {
        formData.append('contactUUID', '');
        formData.append('accountUUID', '');
        formData.append('opportunityUUID', '');
        formData.append('policyUUID', '');
        formData.append('leadUUID', leadUUID);
      }
      formData.append('name', eventTitle);
      formData.append('scheduledDate', sheduleDateTime.toUTCString());
      formData.append('dueDate', dueDateTime.toUTCString());
      formData.append('eventTypeUUID', eventTypeUUID);
      formData.append('eventTypeName', eventTypeName);
      let canSubmit = true;
      if (description) {
        if (!description.trim()) {
          canSubmit = false;
          setdescriptionError('Description cannot be blank space');
        }
      }
      if (eventTitle) {
        if (!eventTitle.trim()) {
          canSubmit = false;
          setTitleError('Title cannot be blank space');
        }
      }
      formData.append('description', description);
      formData.append('guests', JSON.stringify(guests));
      if (note) {
        if (!textCheck.trim()) {
          canSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      formData.append('notes', JSON.stringify([{ text: note }]));
      if (canSubmit) {
        dispatch(
          createEvent({
            data: formData,
            successCB: () => {
              setAction(true);
              setDisabled(true);
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
              closeModal('closing');
              setTimeout(() => {
                reset();
                setShowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: () => {
              setDisabled(false);
            },
          })
        );
      }
    }
  };
  let callUUID: any = taskType.find((item: any) => item.name === 'CALL');
  let smsUUID: any = taskType.find((item: any) => item.name === 'SMS');
  let mailUUID: any = taskType.find((item: any) => item.name === 'EMAIL');
  let meetingUUID: any = taskType.find((item: any) => item.name === 'MEETING');
  let othersUUID: any = taskType.find((item: any) => item.name === 'OTHERS');

  let PhoneUUID: any = eventTypes.find((item: any) => item.name === 'PHONE');
  let EmailUUID: any = eventTypes.find((item: any) => item.name === 'EMAIL');
  let CalendarUUID: any = eventTypes.find(
    (item: any) => item.name === 'CALENDAR'
  );
  let OthersUUID: any = eventTypes.find((item: any) => item.name === 'OTHERS');

  useEffect(() => {
    if (task) setTypeUUID(callUUID?.uuid);
    else {
      setEventTypeUUID(PhoneUUID?.uuid);
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, callUUID, PhoneUUID]);

  useEffect(() => {
    if (description) {
      setdescriptionError('');
    }
  }, [description]);

  useEffect(() => {
    if (note) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [note]);

  useEffect(() => {
    if (eventTitle) {
      setTitleError('');
    }
  }, [eventTitle]);

  return (
    <>
      {showSuccess && (
        <SuccessAlign>
          <div className={`success-container ${showSuccess && 'open-drawer'} `}>
            <SuccessCard
              title={`Success adding ${task ? 'Task' : 'Event'} `}
              message={`${
                task ? 'A <b>Task</b>' : 'An <b>Event</b>'
              }  has  added.<b>`}
            />
            <button
              onClick={() => {
                setShowSuccess(false);
              }}
              className='crossbutton'
            >
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
      )}
      <StyledAddModal>
        <Modal open={show} align='right'>
          <div className='drawer-content position-relative h-100'>
            <div className='top-div d-flex justify-content-between align-items-center'>
              <h2>Add new</h2>
              <button className='cross-btn'>
                <img
                  className='cross'
                  src={cross}
                  alt=''
                  onClick={() => {
                    onCloseModal();
                  }}
                />
              </button>
            </div>
            <div className='NavButtons'>
              <span
                className={`${task && 'active'}`}
                onClick={() => {
                  showTask(true);
                }}
              >
                Task
              </span>
              <span
                className={` navButtons ${!task && 'active'}`}
                onClick={() => {
                  {
                    showTask(false);
                  }
                }}
              >
                Event
              </span>
            </div>
            {isLoaded ? (
              <form className='pipeline-form' onSubmit={handleSubmit(onSubmit)}>
                {/* task  todo   bug fixes*/}
                {task && (
                  <div className='taskModalContent'>
                    <div className='taskContainer '>
                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Task type</h2>
                        <div>
                          <div className='d-flex align-items-center imagesContainer '>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  typeUUID === callUUID?.uuid && 'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(callUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  typeUUID === callUUID?.uuid ||
                                  HoverUUID === callUUID?.uuid
                                    ? phonevector
                                    : phone
                                }
                                alt=''
                                onClick={() => {
                                  callUUID && setTypeUUID(callUUID.uuid);
                                }}
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Phone</span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  typeUUID === smsUUID?.uuid && 'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(smsUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  typeUUID === smsUUID?.uuid ||
                                  HoverUUID === smsUUID?.uuid
                                    ? messagevector
                                    : message
                                }
                                alt=''
                                onClick={() =>
                                  smsUUID && setTypeUUID(smsUUID.uuid)
                                }
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>
                                  Message
                                </span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  typeUUID === mailUUID?.uuid && 'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(mailUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  typeUUID === mailUUID?.uuid ||
                                  HoverUUID === mailUUID?.uuid
                                    ? emailvector
                                    : mail
                                }
                                alt=''
                                onClick={() =>
                                  mailUUID && setTypeUUID(mailUUID.uuid)
                                }
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Email</span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  typeUUID === meetingUUID?.uuid && 'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(meetingUUID?.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  typeUUID === meetingUUID?.uuid ||
                                  HoverUUID === meetingUUID?.uuid
                                    ? calendarvector
                                    : calender
                                }
                                alt=''
                                onClick={() =>
                                  meetingUUID && setTypeUUID(meetingUUID.uuid)
                                }
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>
                                  Meeting
                                </span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  typeUUID === othersUUID?.uuid && 'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(othersUUID?.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  typeUUID === othersUUID?.uuid ||
                                  HoverUUID === othersUUID?.uuid
                                    ? othersvector
                                    : others
                                }
                                alt=''
                                onClick={() =>
                                  othersUUID && setTypeUUID(othersUUID.uuid)
                                }
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Others</span>
                              </span>
                            </div>
                          </div>

                          <div className='errormessage'>
                            {task && typeMsg && !typeUUID ? (
                              <span className='error-field'>{typeMsg} </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Priority</h2>
                        <div className='d-flex align-items-center  col'>
                          <div
                            className={`${
                              tastPriority === 'LOW'
                                ? 'lowPriority'
                                : tastPriority === 'HIGH'
                                ? 'highPriority'
                                : tastPriority === 'MEDIUM'
                                ? 'mediumPriority'
                                : ''
                            }   userpriorityDiv d-flex justify-content-between align-items-center`}
                            onClick={() => setPriorityList(!priorityList)}
                            ref={dropPriorityRef}
                          >
                            <h2 className={`priorityLabel `}>
                              {tastPriority.toLowerCase()}
                            </h2>
                            {priorityList && (
                              <div className='priorityDropdown d-flex flex-column justify-content-center'>
                                {tastPriorityList &&
                                  tastPriorityList.map((item: any) => (
                                    <ul
                                      className={`priorityList ${
                                        item?.name === 'LOW'
                                          ? 'lowPriority'
                                          : item?.name === 'HIGH'
                                          ? 'highPriority'
                                          : item?.name === 'MEDIUM'
                                          ? 'mediumPriority'
                                          : ''
                                      }`}
                                      onClick={() => {
                                        setTastPriority(item?.name);
                                        setPriorityUUID(item?.uuid);
                                      }}
                                    >
                                      {item?.name.toLowerCase()}
                                    </ul>
                                  ))}
                              </div>
                            )}
                            <img src={vector} alt='' />
                          </div>
                        </div>
                      </div>
                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Due</h2>
                        <div>
                          <div className='d-flex align-items-center  col'>
                            <div className='smallDateField'>
                              <CustomDateField
                                name='dueDate'
                                onSetItem={onSetDate}
                                values={dueDate}
                                placeholder='Date'
                                minDate={new Date()}
                                required
                              />
                            </div>
                            <div
                              className='smallDateFielddateOnly position-relative'
                              onClick={() => task && setDueTimeModal(true)}
                              ref={dropRef}
                            >
                              <span>
                                {duetimeVal
                                  ? moment(duetimeVal.toString()).format(
                                      'hh:mm A'
                                    )
                                  : 'Time'}
                                <span className='requiredStar'>*</span>
                              </span>
                              {DueTimeModal && (
                                <TimePicker
                                  setTaskDueTime={setDueTimeModal}
                                  setDueTime={setDueTimeVal}
                                  dueTime={duetimeVal}
                                />
                              )}
                            </div>
                          </div>
                          {task &&
                          (!dueDate.dueDate ||
                            dueDateTime < new Date() ||
                            !duetimeVal) &&
                          dueDateMsg ? (
                            <span className='error-field'>{dueDateMsg} </span>
                          ) : null}
                        </div>
                      </div>

                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Task stage</h2>
                        <div className='d-flex align-items-center  col'>
                          <div className='taskStageDiv align-items-center justify-content-between d-flex'>
                            <h2 className='priorityLabel  '>To-Do</h2>
                            <img src={redVector} alt='' className='redVector' />
                          </div>
                        </div>
                      </div>
                      <div className='d-sm-flex  taskTypeDiv'>
                        <h2 className='titleLabel LinktoDiv col-4'>Link to</h2>
                        <div className='d-flex d-sm-block col'>
                          <button
                            type='button'
                            className='linkDiv d-flex  justify-content-between align-items-center'
                            onClick={() => setLinkToDropDown(!linkToDropDown)}
                            onBlur={() => setLinkToDropDown(false)}
                          >
                            <h6 className='bookLabel '>{linkedTo}</h6>
                            {linkToDropDown && (
                              <div className='assigneeDropDown linkToDropDown'>
                                {[
                                  'Contact',
                                  'Account',
                                  'Opportunity',
                                  'Lead',
                                  'Policy',
                                ]?.map((item: any, index: number) => (
                                  <div className='dropList'>
                                    <ul
                                      className={`usersList ${
                                        linkedTo === item && 'activeLink'
                                      }`}
                                      onClick={() => {
                                        setLinkedTo(item);
                                      }}
                                    >
                                      {item}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            )}
                            <img src={vector} alt='' />
                          </button>
                          <div className='Linkedfields '>
                            {linkedTo === 'Contact' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={contactSearch}
                                resourceList={contactList}
                                setResourceSearch={setContactSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadContact}
                                setResourceUUID={setContactUUID}
                              />
                            )}
                            {linkedTo === 'Opportunity' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={opportunitySearch}
                                resourceList={opportunityList}
                                setResourceSearch={setOpportunitySearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadOpportunity}
                                setResourceUUID={setOpportunityUUID}
                              />
                            )}
                            {linkedTo === 'Account' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={accountSearch}
                                resourceList={accountList}
                                setResourceSearch={setAccountSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadAccount}
                                setResourceUUID={setAccountUUID}
                              />
                            )}
                            {linkedTo === 'Policy' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={policySearch}
                                resourceList={policyList}
                                setResourceSearch={setPolicySearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadPolicy}
                                setResourceUUID={setPolicyUUID}
                              />
                            )}
                            {linkedTo === 'Lead' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={leadSearch}
                                resourceList={leadList}
                                setResourceSearch={setLeadSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadLead}
                                setResourceUUID={setLeadUUID}
                              />
                            )}
                            <div className='taskTypeDiv d-none d-sm-block'>
                              {task &&
                              linkedTo === 'Contact' &&
                              !contactUUID &&
                              linkToMsg ? (
                                <span className='error-field'>
                                  {linkToMsg}{' '}
                                </span>
                              ) : null}
                              {task &&
                              linkedTo === 'Account' &&
                              !accountUUID &&
                              linkToMsg ? (
                                <span className='error-field'>
                                  {linkToMsg}{' '}
                                </span>
                              ) : null}
                              {task &&
                              linkedTo === 'Opportunity' &&
                              !opportunityUUID &&
                              linkToMsg ? (
                                <span className='error-field'>
                                  {linkToMsg}{' '}
                                </span>
                              ) : null}
                              {task &&
                              linkedTo === 'Lead' &&
                              !leadUUID &&
                              linkToMsg ? (
                                <span className='error-field'>
                                  {linkToMsg}{' '}
                                </span>
                              ) : null}
                              {task &&
                              linkedTo === 'Policy' &&
                              !policyUUID &&
                              linkToMsg ? (
                                <span className='error-field'>
                                  {linkToMsg}{' '}
                                </span>
                              ) : null}
                            </div>
                          </div>{' '}
                        </div>
                        <div className='taskTypeDiv d-sm-none'>
                          {task &&
                          linkedTo === 'Contact' &&
                          !contactUUID &&
                          linkToMsg ? (
                            <span className='error-field'>{linkToMsg} </span>
                          ) : null}
                          {task &&
                          linkedTo === 'Account' &&
                          !contactUUID &&
                          linkToMsg ? (
                            <span className='error-field'>{linkToMsg} </span>
                          ) : null}
                          {task &&
                          linkedTo === 'Opportunity' &&
                          !contactUUID &&
                          linkToMsg ? (
                            <span className='error-field'>{linkToMsg} </span>
                          ) : null}
                          {task &&
                          linkedTo === 'Lead' &&
                          !leadUUID &&
                          linkToMsg ? (
                            <span className='error-field'>{linkToMsg} </span>
                          ) : null}
                          {task &&
                          linkedTo === 'Policy' &&
                          !policyUUID &&
                          linkToMsg ? (
                            <span className='error-field'>{linkToMsg} </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Assignee</h2>
                        <div className='d-flex align-items-center  col'>
                          <button
                            type='button'
                            className='userAssigneeDiv d-flex justify-content-between align-items-center'
                            onClick={() => setAssigneesList(!assigneesList)}
                            onBlur={() => setAssigneesList(false)}
                          >
                            <h2 className='titleLabel taskAssigneeLabel d-flex align-items-center '>
                              <div className='Assigneeprofile-pic d-flex align-items-center justify-content-center'>
                                <div className='profile'>
                                  {assignee?.charAt(0)} {assignee?.charAt(5)}
                                </div>
                              </div>
                              <span className='assigneeName'>{assignee}</span>
                            </h2>
                            {assigneesList && (
                              <div className='assigneeDropDown '>
                                {assignees &&
                                  assignees?.map((item: any, index: number) => (
                                    <div
                                      className='AssigneeCard d-flex align-items-center'
                                      onClick={() => {
                                        setAssignee(
                                          item?.User?.firstName +
                                            ' ' +
                                            item.User?.lastName
                                        );
                                        setAssigneeUUID(item?.User?.uuid);
                                      }}
                                    >
                                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                                        {item?.User?.firstName.charAt(0)}{' '}
                                        {item?.User?.lastName.charAt(0)}
                                      </div>
                                      <div>
                                        <h6>{`${item?.User?.firstName} ${item?.User?.lastName}`}</h6>
                                        <ul className='user-details'>
                                          <li>
                                            {item?.User?.email
                                              ? item?.User?.email
                                              : '------'}
                                          </li>
                                          <li>
                                            {item?.User?.phoneNumber
                                              ? item?.User?.phoneNumber
                                              : '------'}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                            <img src={vector} alt='' />
                          </button>
                        </div>
                      </div>
                      <div className='d-sm-flex taskTypeDiv  descripionDiv'>
                        <h2 className='titleLabel col-4'>Description</h2>
                        <div className='d-flex align-items-center descriptionField  col'>
                          <TextAreaField
                            name='description'
                            placeholder='Add description'
                            values={description}
                            onSetItem={onSet}
                          />

                          <img src={edit} alt='' className='editDesc' />
                        </div>
                      </div>
                      {descriptionError && (
                        <div className='errorDIvDesc d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {descriptionError}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className=' notesDiv'>
                      <h2 className='titleLabel'>Note</h2>
                      <div className='d-flex align-items-center descriptionField  col'>
                        <div className='textarea'>
                          <MarkdownEditor
                            placeholder='Add notes here'
                            note={note}
                            setNote={setNote}
                            textCheckFunction={setTextCheck}
                          />
                        </div>
                      </div>
                    </div>
                    {noteError && (
                      <div className='errorNoteDiv d-flex align-items-center'>
                        <span className='errorVal'>
                          <img className='erricon' src={warning} alt='' />
                          {noteError}
                        </span>
                      </div>
                    )}
                    <div className=' DocumentsDiv'>
                      <h2 className='titleLabel'>Documents (optional)</h2>
                      <DocumentsField
                        file={file}
                        setFile={setFile}
                        setFileError={setFileError}
                        colorless={true}
                      />
                      {file && task && (
                        <div className='documentDetails '>
                          {' '}
                          <div className='d-flex align-items-center mb-3'>
                            <img src={document} alt='' />
                            <span className='docName'>{file?.name}</span>
                          </div>
                          <div className='itemsDiv d-flex justify-content-between align-items-center'>
                            <button
                              className='documentDropDown d-flex justify-content-between align-items-center'
                              onClick={() => setDropDown(!dropDown)}
                              type='button'
                            >
                              {docType ? docType : 'Document type'}
                              <img src={vector} alt='' />
                              {dropDown && (
                                <div className='documentTypeDrop'>
                                  {' '}
                                  {DocumentTypes &&
                                    DocumentTypes?.map((item: any) => (
                                      <div
                                        className='listType d-flex align-items-center '
                                        onClick={() => {
                                          setDropDown(false);
                                          setDocType(item?.name);
                                          setDocTypeUUID(item.uuid);
                                        }}
                                      >
                                        <ul>{item?.name}</ul>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </button>
                            <img className='binImage' src={bin} alt='' />
                          </div>
                          {error && !DoctypeUUID && file && (
                            <div className='errorDIv d-flex align-items-center'>
                              <span className='errorVal'>
                                <img className='erricon' src={warning} alt='' />
                                {error}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {fileError && (
                        <div className='errorDIv d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {fileError}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!task && (
                  <div className='taskModalContent'>
                    <div className='taskContainer '>
                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Event title</h2>
                        <div className='d-flex align-items-center w-100'>
                          <div className='w-100'>
                            <div className='d-flex align-items-center taskTypeDiv  descriptionField mb-0  w-100'>
                              <CustomInputField
                                type='text'
                                name='name'
                                values={eventTitle}
                                placeholder='Add title'
                                onSetItem={onSet}
                              />
                              <img src={edit} alt='' className='editDesc' />
                            </div>
                            {(!task && eventtitleMsg && !eventTitle) ||
                            titleError ? (
                              <span className='error-field'>
                                {eventtitleMsg || titleError}{' '}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Type</h2>
                        <div>
                          <div className='d-flex align-items-center imagesContainerEvent '>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  eventTypeUUID === PhoneUUID?.uuid &&
                                  'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(PhoneUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  eventTypeUUID === PhoneUUID?.uuid ||
                                  HoverUUID === PhoneUUID?.uuid
                                    ? phonevector
                                    : phone
                                }
                                alt=''
                                onClick={() => {
                                  if (PhoneUUID) {
                                    setEventTypeUUID(PhoneUUID.uuid);
                                    setEventTypeName(PhoneUUID.name);
                                  }
                                }}
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Phone</span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  eventTypeUUID === EmailUUID?.uuid &&
                                  'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(EmailUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  eventTypeUUID === EmailUUID?.uuid ||
                                  HoverUUID === EmailUUID?.uuid
                                    ? emailvector
                                    : mail
                                }
                                alt=''
                                onClick={() => {
                                  if (EmailUUID) {
                                    setEventTypeUUID(EmailUUID.uuid);
                                    setEventTypeName(EmailUUID.name);
                                  }
                                }}
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Email</span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  eventTypeUUID === CalendarUUID?.uuid &&
                                  'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(CalendarUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  eventTypeUUID === CalendarUUID?.uuid ||
                                  HoverUUID === CalendarUUID?.uuid
                                    ? calendarvector
                                    : calender
                                }
                                alt=''
                                onClick={() => {
                                  if (CalendarUUID) {
                                    setEventTypeUUID(CalendarUUID.uuid);
                                    setEventTypeName(CalendarUUID.name);
                                  }
                                }}
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>
                                  Meeting
                                </span>
                              </span>
                            </div>
                            <div className='text-overflow-container'>
                              <img
                                className={`taskImage ${
                                  eventTypeUUID === OthersUUID?.uuid &&
                                  'activeType'
                                }`}
                                onMouseEnter={() => {
                                  setHoverUUID(OthersUUID.uuid);
                                }}
                                onMouseLeave={() => {
                                  setHoverUUID('');
                                }}
                                src={
                                  eventTypeUUID === OthersUUID?.uuid ||
                                  HoverUUID === OthersUUID?.uuid
                                    ? othersvector
                                    : others
                                }
                                alt=''
                                onClick={() => {
                                  if (OthersUUID) {
                                    setEventTypeUUID(OthersUUID.uuid);
                                    setEventTypeName(OthersUUID.name);
                                  }
                                }}
                              />
                              <span className='tooltiptext'>
                                <span className='tooltipInnertext'>Others</span>
                              </span>
                            </div>
                          </div>

                          {!task && eventTypeMsg && !eventTypeUUID ? (
                            <span className='error-field'>{eventTypeMsg} </span>
                          ) : null}
                        </div>
                      </div>

                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Period</h2>
                        <div className='d-sm-flex flex-column datePeriodDiv'>
                          <div className='dropIndicator'> </div>
                          <div className='PointerDiv'></div>
                          <div className='d-flex align-items-center  col'>
                            <div className='smallDateField'>
                              <CustomDateField
                                name='scheduledDate'
                                onSetItem={onSetDate}
                                values={scheduledDate}
                                placeholder='Start'
                                minDate={new Date()}
                                required
                              />
                            </div>
                            <div
                              className='smallDateFielddateOnly dateOnly'
                              onClick={() => !task && setEventSheduleTime(true)}
                              ref={dropEventRef}
                            >
                              <span>
                                {scheduledTimeVal
                                  ? moment(scheduledTimeVal.toString()).format(
                                      'hh:mm A'
                                    )
                                  : 'Time'}
                                <span className='requiredStar'>*</span>
                              </span>
                              {EventSheduleTime && (
                                <TimePicker
                                  setTaskDueTime={setEventSheduleTime}
                                  setDueTime={setScheduledTimeVal}
                                  dueTime={scheduledTimeVal}
                                />
                              )}
                            </div>
                          </div>
                          {!task &&
                          eventScheduledDateMsg &&
                          (!scheduledDate.scheduledDate ||
                            sheduleDateTime < new Date()) ? (
                            <span className='error-field'>
                              {eventScheduledDateMsg}{' '}
                            </span>
                          ) : null}

                          <div className='d-flex align-items-center  col'>
                            <i className='arrow-down'></i>
                            <div className='secondPointerDiv'></div>
                            <div className='smallDateField'>
                              <CustomDateField
                                name='dueDate'
                                onSetItem={onSetDate}
                                values={dueDate}
                                placeholder='End'
                                required
                                minDate={scheduledDate.scheduledDate}
                              />
                            </div>

                            <div
                              className='smallDateFielddateOnly  position-relative '
                              onClick={() => !task && setDueTimeModal(true)}
                              ref={dropRef}
                            >
                              <span>
                                {duetimeVal
                                  ? moment(duetimeVal.toString()).format(
                                      'hh:mm A'
                                    )
                                  : 'Time'}
                                <span className='requiredStar'>*</span>
                              </span>
                              {DueTimeModal && (
                                <TimePicker
                                  setTaskDueTime={setDueTimeModal}
                                  setDueTime={setDueTimeVal}
                                  dueTime={duetimeVal}
                                />
                              )}
                            </div>
                          </div>
                          {!task &&
                          (!dueDate.dueDate || dueDateTime < sheduleDateTime) &&
                          dueDateMsg ? (
                            <span className='error-field'>{dueDateMsg} </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='d-sm-flex  taskTypeDiv '>
                        <h2 className='titleLabel LinktoDiv  col-4'>Link to</h2>
                        <div className='d-flex d-sm-block col'>
                          <button
                            type='button'
                            className='linkDiv d-flex justify-content-between align-items-center'
                            onClick={() => setLinkToDropDown(!linkToDropDown)}
                            onBlur={() => setLinkToDropDown(false)}
                          >
                            <h6 className='bookLabel '>{linkedTo}</h6>
                            {linkToDropDown && (
                              <div className='assigneeDropDown linkToDropDown d-flex flex-column justify-content-center'>
                                {[
                                  'Contact',
                                  'Account',
                                  'Opportunity',
                                  'Lead',
                                  'Policy',
                                ].map((item: any, index: number) => (
                                  <div className='dropList'>
                                    <ul
                                      className={`usersList ${
                                        linkedTo === item && 'activeLink'
                                      }`}
                                      onClick={() => {
                                        setLinkedTo(item);
                                      }}
                                    >
                                      {item}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            )}
                            <img src={vector} alt='' />
                          </button>
                          <div className='Linkedfields'>
                            {linkedTo === 'Contact' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={contactSearch}
                                resourceList={contactList}
                                setResourceSearch={setContactSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadContact}
                                setResourceUUID={setContactUUID}
                              />
                            )}
                            {linkedTo === 'Opportunity' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={opportunitySearch}
                                resourceList={opportunityList}
                                setResourceSearch={setOpportunitySearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadOpportunity}
                                setResourceUUID={setOpportunityUUID}
                              />
                            )}
                            {linkedTo === 'Account' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={accountSearch}
                                resourceList={accountList}
                                setResourceSearch={setAccountSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadAccount}
                                setResourceUUID={setAccountUUID}
                              />
                            )}
                            {linkedTo === 'Policy' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={policySearch}
                                resourceList={policyList}
                                setResourceSearch={setPolicySearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadPolicy}
                                setResourceUUID={setPolicyUUID}
                              />
                            )}
                            {linkedTo === 'Lead' && (
                              <LinkToField
                                resource={linkedTo}
                                resourceSearch={leadSearch}
                                resourceList={leadList}
                                setResourceSearch={setLeadSearch}
                                loadingStatus={loadingStatus}
                                register={register}
                                control={control}
                                loadResource={loadLead}
                                setResourceUUID={setLeadUUID}
                              />
                            )}
                          </div>
                          <div className='taskTypeDiv d-none d-sm-block'>
                            {!task &&
                            linkedTo === 'Contact' &&
                            !contactUUID &&
                            linkToMsg ? (
                              <span className='error-field'>{linkToMsg} </span>
                            ) : null}
                            {!task &&
                            linkedTo === 'Account' &&
                            !accountUUID &&
                            linkToMsg ? (
                              <span className='error-field'>{linkToMsg} </span>
                            ) : null}
                            {!task &&
                            linkedTo === 'Opportunity' &&
                            !opportunityUUID &&
                            linkToMsg ? (
                              <span className='error-field'>{linkToMsg} </span>
                            ) : null}
                            {!task &&
                            linkedTo === 'Lead' &&
                            !leadUUID &&
                            linkToMsg ? (
                              <span className='error-field'>{linkToMsg} </span>
                            ) : null}
                            {!task &&
                            linkedTo === 'Policy' &&
                            !policyUUID &&
                            linkToMsg ? (
                              <span className='error-field'>{linkToMsg} </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className='taskTypeDiv d-sm-none'>
                        {!task &&
                        linkedTo === 'Contact' &&
                        !contactUUID &&
                        linkToMsg ? (
                          <span className='error-field'>{linkToMsg} </span>
                        ) : null}
                        {!task &&
                        linkedTo === 'Account' &&
                        !accountUUID &&
                        linkToMsg ? (
                          <span className='error-field'>{linkToMsg} </span>
                        ) : null}
                        {!task &&
                        linkedTo === 'Opportunity' &&
                        !opportunityUUID &&
                        linkToMsg ? (
                          <span className='error-field'>{linkToMsg} </span>
                        ) : null}
                        {!task &&
                        linkedTo === 'Lead' &&
                        !leadUUID &&
                        linkToMsg ? (
                          <span className='error-field'>{linkToMsg} </span>
                        ) : null}
                        {!task &&
                        linkedTo === 'Policy' &&
                        !policyUUID &&
                        linkToMsg ? (
                          <span className='error-field'>{linkToMsg} </span>
                        ) : null}
                      </div>
                      <div className='d-sm-flex align-items-center taskTypeDiv'>
                        <h2 className='titleLabel col-4'>Guest</h2>
                        <div className=' guestsContainer  col'>
                          <button
                            type='button'
                            className='GuestDiv d-flex justify-content-between align-items-center'
                            onClick={() => showGuestList(true)}
                            ref={dropGuestRef}
                          >
                            {' '}
                            <img
                              className='addguestbutton'
                              src={blackplus}
                              alt=''
                            />
                            {guestList && (
                              <GuestListDropDown
                                contactList={contactList}
                                guestUUID={guestUUID}
                                setGuestUUID={setGuestUUID}
                                setGuests={setGuest}
                                guests={guest}
                              />
                            )}
                            {guest.length > 0 ? (
                              guest.map(
                                (item: any, index: number) =>
                                  guest &&
                                  index < 4 && (
                                    <li
                                      className='ProfileList'
                                      style={{ zIndex: guest.length - index }}
                                    >
                                      {' '}
                                      <div className='Guestprofile-pic d-flex align-items-center justify-content-center'>
                                        <div className='profile'>
                                          {item?.firstName?.charAt(0)}{' '}
                                          {item?.lastName?.charAt(0)}
                                        </div>
                                      </div>
                                    </li>
                                  )
                              )
                            ) : (
                              <li className='ProfileList'>
                                Add guest/s
                                <span className='requiredStar'>*</span>
                              </li>
                            )}
                            {guest.length > 4 && (
                              <span className='Guestprofile-pic GuestprofileNumber d-flex align-items-center justify-content-center '>
                                + {guest.length - 4}
                              </span>
                            )}
                          </button>
                          {guest.length > 0 && (
                            <>
                              <button
                                type='button'
                                className='ManageGuestHead'
                                onClick={() => setGuestManageDropDown(true)}
                                ref={dropManageGuestRef}
                              >
                                Manage Guests
                                {guestManageDropDown && (
                                  <GuestListDropDown
                                    contactList={contactList}
                                    guestUUID={guestUUID}
                                    setGuestUUID={setGuestUUID}
                                    setGuests={setGuest}
                                    guests={guest}
                                    Manage={true}
                                  />
                                )}
                              </button>
                            </>
                          )}
                          {!task && guestUUID.length < 1 && eventGuestMsg ? (
                            <span className='error-field'>
                              {eventGuestMsg}{' '}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className='d-sm-flex taskTypeDiv descripionDiv '>
                        <h2 className='titleLabel  col-4'>Description</h2>
                        <div className='d-flex align-items-center descriptionField w-100 '>
                          <TextAreaField
                            name='description'
                            placeholder='Add description'
                            values={description}
                            onSetItem={onSet}
                          />
                          <img src={edit} alt='' className='editDesc' />
                        </div>
                      </div>
                      {descriptionError && (
                        <div className='errorDIvDesc d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {descriptionError}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className=' notesDiv'>
                      <h2 className='titleLabel'>Note</h2>
                      <div className='d-flex align-items-center descriptionField  col'>
                        <div className='textarea'>
                          <MarkdownEditor
                            placeholder='Add notes here'
                            note={note}
                            setNote={setNote}
                            textCheckFunction={setTextCheck}
                          />
                        </div>
                      </div>
                    </div>
                    {noteError && (
                      <div className='errorNoteDiv d-flex align-items-center'>
                        <span className='errorVal'>
                          <img className='erricon' src={warning} alt='' />
                          {noteError}
                        </span>
                      </div>
                    )}
                    <div className=' DocumentsDiv'>
                      <h2 className='titleLabel'>Documents (optional)</h2>
                      <DocumentsField
                        file={file}
                        setFile={setFile}
                        setFileError={setFileError}
                        colorless={true}
                      />
                      {file && !task && (
                        <div className='documentDetails'>
                          {' '}
                          <img src={document} alt='' />
                          <span className='docName'>{file?.name}</span>
                          <div className='itemsDiv d-flex justify-content-between align-items-center'>
                            <button
                              className='documentDropDown d-flex justify-content-between align-items-center'
                              onClick={() => setDropDown(!dropDown)}
                              type='button'
                            >
                              {docType ? docType : 'Document type'}
                              <img src={vector} alt='' />
                              {dropDown && (
                                <div className='documentTypeDrop'>
                                  {' '}
                                  {DocumentTypes &&
                                    DocumentTypes?.map((item: any) => (
                                      <div
                                        className='listType d-flex align-items-center '
                                        onClick={() => {
                                          setDocType(item?.name);
                                          setDocTypeUUID(item.uuid);
                                          setDropDown(false);
                                        }}
                                      >
                                        <ul>{item?.name}</ul>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </button>
                            <img className='binImage' src={bin} alt='' />
                          </div>
                          {error && !DoctypeUUID && file && (
                            <div className='errorDIv d-flex align-items-center'>
                              <span className='errorVal'>
                                <img className='erricon' src={warning} alt='' />
                                {error}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {fileError && (
                        <div className='errorDIv d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {fileError}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    disabled={disabled}
                  >
                    Save
                    <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            ) : (
              <>
                <Skeleton height={30} className='mb-3 mt-5' />
                <Skeleton height={50} className='my-3' />
                <Skeleton height={50} className='my-3' />
                <Skeleton height={50} className='my-3' />
              </>
            )}
          </div>
        </Modal>
      </StyledAddModal>
    </>
  );
};

export default AddTaskEventModal;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SelectField from '../../../../components/CustomSelectField';
import {
  StyledModal,
  StyledModalMobile,
  // StyledModalMobile,
} from '../../../Pipeline/elements/styles';
import { ILocation } from '../../../../interfaces/Location';
import { AppDispatch } from '../../../../store';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import {
  selectAllLocations,
  // LocationSliceState,
  deleteALocation,
} from '../../../../store/location/locationSlice';
import LocalStorageService from '../../../../utils/LocalStorageService';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
const DeleteLocationModal = ({
  showModal,
  closeModal,
  deletingLocation,
  agencyUUID,
  agencyUpdate,
}: {
  showModal: boolean;
  closeModal: Function;
  deletingLocation: ILocation;
  agencyUUID?: string;
  agencyUpdate?: boolean;
}) => {
  const [locationUUID, setLocationUUID] = useState<any>({ locationUUID: '' });
  const dispatch = useDispatch<AppDispatch>();
  const [showSuccess, setShowSuccess] = useState(false);
  const locations = useSelector(selectAllLocations);
  const [errorMessege, setErrorMessege] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  // const loadingStatus = useSelector(
  //   (state: { locations: LocationSliceState }) => state.locations.status
  // );
  let userName = {
    deleteLocation: deletingLocation?.uuid,
  };

  const user = LocalStorageService.getUser();

  const onSetItem = (name: string, value: string) => {
    setLocationUUID({ locationUUID: value });
    setIsValidated(true);
    setErrorMessege('');
  };
  const updateStatement = (
    uuid: string,
    locationUUID: string,
    agencyUUID?: string
  ) => {
    if (isValidated && locationUUID) {
      if (user?.UserRole.name === 'ADMIN' && agencyUUID) {
        if (agencyUpdate && agencyUUID) {
          dispatch(
            deleteALocation({
              uuid,
              locationUUID,
              agencyUUID,
              agencyUpdate,
              successCB: () => {
                setTimeout(() => {
                  setHideModal(true);
                  setShowSuccess(true);
                }, 100);
                setTimeout(() => {
                  closeModal(false);
                  setShowSuccess(false);
                }, 2000);
              },
            })
          );
        } else if (agencyUpdate) {
          dispatch(
            deleteALocation({
              uuid,
              locationUUID,
              agencyUpdate,
              successCB: () => {
                setTimeout(() => {
                  setHideModal(true);
                  setShowSuccess(true);
                }, 100);
                setTimeout(() => {
                  closeModal(false);
                  setShowSuccess(false);
                }, 2000);
              },
            })
          );
        }
        if (!agencyUpdate && agencyUUID) {
          dispatch(
            deleteALocation({
              uuid,
              locationUUID,
              agencyUUID,
              successCB: () => {
                setTimeout(() => {
                  setHideModal(true);
                  setShowSuccess(true);
                }, 100);
                setTimeout(() => {
                  closeModal(false);
                  setShowSuccess(false);
                }, 2000);
              },
            })
          );
        }
      } else {
        dispatch(
          deleteALocation({
            uuid,
            locationUUID,
            successCB: () => {
              setTimeout(() => {
                setHideModal(true);
                setShowSuccess(true);
              }, 100);
              setTimeout(() => {
                closeModal(false);
                setShowSuccess(false);
              }, 2000);
            },
          })
        );
      }
    } else {
      setErrorMessege('please select a location to update');
    }
  };
  return (
    <div>
      {showSuccess && (
        <ErrorCard
          message={`<b>${deletingLocation.name}</b> has been <b> deleted</b> `}
          title={'Success deleting location.'}
        />
      )}{' '}
      <StyledModal
        show={showModal && !hideModal}
        centered
        dialogClassName='DeleteModalContainer'
        className='d-none d-sm-block'
      >
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => closeModal(false)}
          />
          <h2>Delete this location?</h2>
          <div className='sub-heading'>
            There are some users in this location. Do you want to move these
            users to a different location?
          </div>
          <div className='fields'>
            <SelectField
              options={locations.map((item) => {
                return {
                  value: item?.uuid,
                  label: item?.name,
                };
              })}
              placeholder='From Location'
              name='deleteLocation'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <SelectField
              options={locations
                .filter((item) => item?.uuid !== deletingLocation?.uuid)
                .map((item) => {
                  return {
                    value: item?.uuid,
                    label: item?.name,
                  };
                })}
              placeholder='To location'
              name='locationUUID'
              values={locationUUID}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeModal(false);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='DeleteButton'
              onClick={() => {
                updateStatement(
                  deletingLocation?.uuid,
                  locationUUID.locationUUID,
                  agencyUUID
                );
              }}
            >
              Move and Delete
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModal>
      <StyledModalMobile show={showModal && !hideModal} className=' d-sm-none'>
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => closeModal(false)}
          />
          <h2>Delete this location?</h2>
          <div className='sub-heading'>
            There are some users in this location. Do you want to move these
            users to a different location?
          </div>
          <div className='fields'>
            <SelectField
              options={locations.map((item) => {
                return {
                  value: item?.uuid,
                  label: item?.name,
                };
              })}
              placeholder='From Location'
              name='deleteLocation'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <SelectField
              options={locations.map((item) => {
                return {
                  value: item?.uuid,
                  label: item?.name,
                };
              })}
              placeholder='To location'
              // menuIsOpen={true}
              name='locationUUID'
              values={locationUUID}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeModal(false);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='DeleteButton'
              onClick={() => {
                updateStatement(
                  deletingLocation?.uuid,
                  locationUUID.locationUUID,
                  agencyUUID
                );
              }}
            >
              Move and Delete
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModalMobile>
    </div>
  );
};

export default DeleteLocationModal;

import { useRef, useEffect } from 'react';
import {
  CancelButton,
  ProceedButton,
  StyledModal,
} from '../../../../components/DeleteModal/styles';
import cross from '../../../../assets/logo/cross.svg';
import rightArrow from '../../../../assets/logo/right-arrow.svg';

const ActivateModal = ({
  showModal,
  closeModal,
  submitFunction,
  uuid,
  loading,
  resource,
  name,
  content,
  isActive,
}: {
  showModal: string;
  closeModal: Function;
  submitFunction: Function;
  uuid: string;
  loading: string;
  resource: string;
  name: string;
  content?: any;
  isActive: boolean;
}) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onSubmit = () => {
    submitFunction(uuid);
  };

  return (
    <StyledModal>
      <div className='modal-container' ref={wrapperRef}>
        <button
          onClick={() => {
            closeModal('closed');
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <h4>
          {isActive ? 'De-activate' : 'Activate'} {resource}
        </h4>
        {content ? (
          content
        ) : (
          <p>
            Are you sure you want to {isActive ? 'De-activate' : 'Activate'} the{' '}
            {name} {resource}?
          </p>
        )}

        <div className='border d-block d-sm-none'></div>
        <div className='d-flex button-container'>
          <CancelButton
            type='button'
            onClick={() => {
              closeModal('closed');
            }}
          >
            Cancel
          </CancelButton>
          <ProceedButton
            className='activate-submit-btn'
            type='button'
            disabled={loading === 'loading' || showModal === 'closing'}
            onClick={() => onSubmit()}
          >
            {isActive ? 'De-activate' : 'Activate'}
            <img src={rightArrow} alt='' />
          </ProceedButton>
        </div>
      </div>
    </StyledModal>
  );
};

export default ActivateModal;

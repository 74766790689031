import Select, { components } from 'react-select';
import {
  CustomMultiValue,
  CustomMultiValueContainer,
  customSelectStyles,
  CustomValueContainer,
  MultiOption,
} from '../SelectField';

interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  options: any[];
  values: any;
  disbaled?: boolean;
  onSetItem: Function;
  timeField?: boolean;
  required?: boolean;
}

const { Option } = components;

const SelectField = ({
  name,
  placeholder,
  options,
  values,
  disabled,
  onSetItem,
  timeField,
  required,
  ...props
}: IProps) => {
  return (
    <Select
      id={name}
      name={name}
      styles={customSelectStyles}
      options={options}
      placeholder={
        required ? (
          <>
            {placeholder} {<span className='requiredStar'>*</span>}
          </>
        ) : (
          placeholder
        )
      }
      components={{
        ValueContainer: props.isMulti
          ? CustomMultiValueContainer
          : CustomValueContainer,
        MultiValue: CustomMultiValue,
        Option: props.isMulti ? MultiOption : Option,
      }}
      isClearable
      isDisabled={disabled}
      isOptionDisabled={(option: any) => option.isDisabled}
      {...props}
      value={
        options.find(
          (option: { value: string; label: string }) =>
            option.value === values[name]
        )
          ? options.find(
              (option: { value: string; label: string }) =>
                option.value === values[name]
            )
          : ''
      }
      onChange={(val) => {
        onSetItem(name, val?.value);
      }}
    />
  );
};

export default SelectField;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../../store';
import {
  fetchGoals,
  GoalSliceState,
  selectAllGoals,
  updateGoalsDetails,
} from '../../../../store/goals/goalSlice';
import { getDefaultGoals } from '../../../../api/goals';
import SelectField from '../../../../components/CustomSelectField';
import SuccessCard from '../../../../components/SuccessCard';
import Modal from '../../../../components/Modal';
import GoalDetailsEditCard from './GoalDetailsEditCard';
import DefaultGoalModal from './DefaultGoalModal';
import { LargeStyledModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import info from '../../../../assets/logo/info.svg';
import warning from '../../../../assets/logo/warning.svg';

const AgentSchema = Yup.object().shape({});

const GoalEditModal = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();
  const [success, setSuccess] = useState(false);
  const [thisMonth, setthisMonth] = useState<any>();
  const [monthSelected, setMonthSelected] = useState<any>({});
  const [updatedGoals, setUptedGoals] = useState<any>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [defaultGoalValue, setDefaultGoalValue] = useState<any>();
  const [defaultGoalModal, setShowDefaultGoalModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');

  const primaryGoal = {
    primaryGoal: 'Premium Value',
  };
  const secondaryGoal = {
    secondaryGoal: 'Policy Count',
  };
  const Goals = useSelector(selectAllGoals);

  const loadingStatus = useSelector(
    (state: { goals: GoalSliceState }) => state.goals?.status
  );

  const selectPolicyCount = useSelector(
    (state: { goals: GoalSliceState }) => state.goals?.goalPolicyCount
  );

  const selectgoalPremiumSum = useSelector(
    (state: { goals: GoalSliceState }) => state.goals?.goalPremiumSum
  );

  useEffect(() => {
    setthisMonth(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        15
      ).toUTCString()
    );
    setMonthSelected({ monthSelected: thisMonth });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisMonth]);

  useEffect(() => {
    getDefaultGoals((value: any) => {
      setDefaultGoalValue(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monthSelected.monthSelected) {
      if (uuid) {
        dispatch(
          fetchGoals({
            selectedMonthDate: monthSelected.monthSelected,
            agencyUUID: uuid,
            successCB: () => {},
          })
        );
      } else {
        dispatch(
          fetchGoals({
            selectedMonthDate: monthSelected.monthSelected,
            successCB: () => {},
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelected]);

  const { handleSubmit, reset } = useForm({
    resolver: yupResolver(AgentSchema),
  });

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const onSetItem = (name: string, value: string) => {
    setMonthSelected({ monthSelected: value });
  };
  const onSubmit = (data: any) => {
    data.agents = updatedGoals;
    data.selectedMonthDate = monthSelected.monthSelected;
    if (uuid) {
      data.agencyUUID = uuid;
    }

    let index = -1;
    if (
      new Date().getMonth() === new Date(monthSelected.monthSelected).getMonth()
    ) {
      index = updatedGoals.findIndex(
        (item: any) =>
          (item.premiumGoalUUID && !item.premiumGoalAmount) ||
          (item.policyGoalUUID && !item.policyGoalCount)
      );
    }

    if (index === -1) {
      setError('');
      dispatch(
        updateGoalsDetails({
          data,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {}, 300);
            }, 2000);
          },
        })
      );
    } else {
      setError(`Goals can't be set empty for current month`);
    }
  };

  const options = [
    { label: 'Premium Value', value: 'Premium Value', isDisabled: true },
    { label: 'Policy Count', value: 'Policy Count', isDisabled: true },
  ];
  const monthNames = [
    {
      label: 'January',
      value: new Date(new Date().getFullYear(), 0, 15).toUTCString(),
      isDisabled: 0 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'February',
      value: new Date(new Date().getFullYear(), 1, 15).toUTCString(),
      isDisabled: 1 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'March',
      value: new Date(new Date().getFullYear(), 2, 15).toUTCString(),
      isDisabled: 2 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'April',
      value: new Date(new Date().getFullYear(), 3, 15).toUTCString(),
      isDisabled: 3 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'May',
      value: new Date(new Date().getFullYear(), 4, 15).toUTCString(),
      isDisabled: 4 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'June',
      value: new Date(new Date().getFullYear(), 5, 15).toUTCString(),
      isDisabled: 5 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'July',
      value: new Date(new Date().getFullYear(), 6, 15).toUTCString(),
      isDisabled: 6 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'August',
      value: new Date(new Date().getFullYear(), 7, 15).toUTCString(),
      isDisabled: 7 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'September',
      value: new Date(new Date().getFullYear(), 8, 15).toUTCString(),
      isDisabled: 8 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'October',
      value: new Date(new Date().getFullYear(), 9, 15).toUTCString(),
      isDisabled: 9 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'November',
      value: new Date(new Date().getFullYear(), 10, 15).toUTCString(),
      isDisabled: 10 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'December',
      value: new Date(new Date().getFullYear(), 11, 15).toUTCString(),
      isDisabled: 11 < new Date(thisMonth).getMonth(),
    },
  ];
  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success updating goal'}
            message={`<b>Goals</b> has been Updated.`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <LargeStyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div className='modalHeading edit-goal-modalHeading'>
            <h2 className='mb-0'>Edit monthly goals</h2>
          </div>
          <div>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='error-container position-relative'>
                    {error && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        <div>{error}</div>
                      </div>
                    )}
                  </div>
                  <div className='d-block d-sm-flex justify-content-between firstFields'>
                    <div className=' Goal-field'>
                      <SelectField
                        options={options}
                        placeholder='Primary metrics for goal'
                        name='primaryGoal'
                        disabled
                        values={primaryGoal}
                        onSetItem={onSetItem}
                      />
                    </div>

                    <div className='Goal-field'>
                      <SelectField
                        options={options}
                        placeholder='Secondary metrics for goal'
                        name='secondaryGoal'
                        disabled
                        values={secondaryGoal}
                        onSetItem={onSetItem}
                      />
                    </div>
                  </div>
                  <div className=' Goal-field'>
                    <SelectField
                      options={monthNames}
                      placeholder='Apply goal to '
                      name='monthSelected'
                      values={monthSelected}
                      onSetItem={onSetItem}
                    />
                  </div>
                  <div className='checkboxDiv edit-checkboxDiv d-flex'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        className='inputCheckbox'
                        checked={isChecked}
                        onClick={() => {
                          setIsChecked(true);
                          setShowDefaultGoalModal(!defaultGoalModal);
                        }}
                      />
                      <span className='slider' />
                    </label>
                    <span className='mx-3'> Use default monthly goals</span>
                    <div className='info d-flex'>
                      <img src={info} alt='info' />{' '}
                      <span className='tooltiptext'>
                        Default values are $
                        <b>{defaultGoalValue?.defaultPremiumValue}</b> for
                        Premium Goals and{' '}
                        <b>{defaultGoalValue?.defaultPolicyCount}</b> for Policy
                        Goals
                      </span>
                    </div>
                  </div>

                  <div className='AgencyListContainer'>
                    <div className='ListHeading d-flex align-items-center justify-content-between'>
                      <span className=''>User’s Name</span>{' '}
                      <span className=''>Premium Goal Value</span>{' '}
                      <span className=''>Policy Count</span>
                    </div>
                    <div className='detailsContainer'>
                      {loadingStatus !== 'loading' && Goals
                        ? Goals.map((item, index) => (
                            <GoalDetailsEditCard
                              agent={item}
                              index={index}
                              setUptedGoals={setUptedGoals}
                              updatedGoals={updatedGoals}
                            />
                          ))
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                            <Skeleton height={54} key={item} />
                          ))}
                    </div>
                    {loadingStatus !== 'loading' ? (
                      <div className='TotalHeading d-flex align-items-center justify-content-between'>
                        <span className=''>Agency Total</span>
                        <span className=' totalPremiumDiv'>
                          ${' '}
                          {selectgoalPremiumSum ? selectgoalPremiumSum : '   -'}
                        </span>
                        <span className=''>
                          {selectPolicyCount ? selectPolicyCount : '   -'}
                        </span>
                      </div>
                    ) : (
                      [1].map((item) => <Skeleton height={54} key={item} />)
                    )}
                  </div>
                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      //   disabled={loading || show === 'closing'}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          {defaultGoalModal && (
            <DefaultGoalModal
              showModal={defaultGoalModal}
              setShowModal={setShowDefaultGoalModal}
              setShowSuccess={setShowSuccess}
              month={monthSelected}
              setCheckbox={setIsChecked}
            />
          )}
        </LargeStyledModal>
      </Modal>
    </>
  );
};

export default GoalEditModal;

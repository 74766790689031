import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../store';
import {
  clearContactAccount,
  ContactsSliceState,
  getContactAccount,
  selectContact,
  selectContactAccount,
  selectContactCount,
  updateContactDetailAccount,
} from '../../store/contacts/contactsSlice';
import DetailsCardMobile from './components/DetailsCardMobile';
import { InnerFieldStyle } from '../../styles/InnerFieldStyles';
import plus from '../../assets/logo/blackplus.svg';
import empty from '../../assets/logo/empty.svg';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import AccountsFilter from '../../pages/MyBook/Accounts/components/FilterDrawer';
import QuickAccountDrawer from '../../pages/MyBook/Accounts/components/QuickAddDrawer';
import ErrorCard from '../../pages/Pipeline/components/ErrorCard';
import DeleteModal from '../DeleteModal';
import {
  AccountsSliceState,
  onDeleteAccount,
} from '../../store/accounts/accountsSlice';
import Skeleton from 'react-loading-skeleton';
import DetailsCard from './components/DetailsCard';
import { IOption } from '../../interfaces/Opportunity';

const AccountsInnertab = ({
  getCount,
  isMobile,
}: {
  isMobile?: boolean;
  getCount: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const contactAccount = useSelector(selectContactAccount);
  const contactDetails = useSelector(selectContact);
  const contactDetailsCount = useSelector(selectContactCount);
  const [addQuickAccount, setAddQuickAccount] = useState('closed');
  const [producer, setProducer] = useState('');
  const [accountType, setAccountType] = useState<IOption[]>([]);
  const [accountStatus, setAccountStatus] = useState<IOption[]>([]);
  const [hasPolicy, setHasPolicy] = useState<any>([]);
  const { uuid } = useParams();
  const loadingContactstatus = useSelector(
    (state: { contacts: ContactsSliceState }) =>
      state.contacts.accountContactStatus
  );

  useEffect(
    () => {
      if (uuid && !isMobile) {
        dispatch(clearContactAccount());
        dispatch(
          getContactAccount({
            uuid: uuid,
            accountStatusUUID: accountStatus.map((item: any) => item.value),
            hasPolicy: hasPolicy?.value,
            producerUUID: producer,
            type: accountType.map((item: any) => item.value),
            limit: 4,
            offset: 0,
          })
        );
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid, hasPolicy, accountStatus, accountType, producer]
  );

  const [showFilter, setShowFilter] = useState('closed');

  const scrollAccountContact = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingContactstatus !== 'loading' &&
      uuid
    ) {
      if (contactDetailsCount.accounts !== contactAccount.length) {
        dispatch(
          getContactAccount({
            uuid,
            offset: contactAccount.length,
            limit: 4,
          })
        );
      }
    }
  };

  const [producerSearch, setProducerSearch] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const [detailName, setDetailName] = useState('');
  const [UUID, setUUID] = useState('');
  const loadingStatus = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.status
  );

  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setDetailName(name);
  };
  const onDelete = () => {
    dispatch(
      onDeleteAccount({
        uuid: UUID || '',
        successCB: () => {
          getCount();
          dispatch(updateContactDetailAccount({ uuid: UUID, type: 'delete' }));
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };
  return (
    <InnerFieldStyle>
      {showSuccess && (
        <ErrorCard
          title={`<b>Success deleting account.</b>`}
          message={`<b>${detailName}</b> has been <b> deleted</b>`}
        />
      )}
      <div className='taskDiv'>
        <div className='taskHead d-flex justify-content-between'>
          <button
            className='tertiaryButton d-none d-sm-block'
            onClick={() => setAddQuickAccount('open')}
          >
            Add Accounts <img className='addIcon' src={plus} alt='' />
          </button>
          <button
            className='tertiaryButton filterbutton'
            onClick={() => setShowFilter('open')}
          >
            Filter by:{' '}
            <b>
              {accountType.length +
                accountStatus.length +
                (hasPolicy?.value ? 1 : 0) +
                (producer ? 1 : 0) ===
              0
                ? 'No'
                : accountType.length +
                  accountStatus.length +
                  (hasPolicy?.value ? 1 : 0) +
                  (producer ? 1 : 0)}
              filter
            </b>
          </button>
        </div>
        <div
          className='opportunityDiv d-none d-sm-block'
          onScroll={scrollAccountContact}
        >
          {contactAccount.length > 0
            ? contactAccount.map((item: any, index: number) => (
                <DetailsCard
                  name={'Account'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : loadingContactstatus !== 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {loadingContactstatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
        </div>
        <div
          className='opportunityDiv d-sm-none'
          onScroll={scrollAccountContact}
        >
          {contactAccount.length > 0
            ? contactAccount.map((item: any, index: number) => (
                <DetailsCardMobile
                  name={'Account'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : loadingContactstatus !== 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {loadingContactstatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
        </div>
        {addQuickAccount !== 'closed' && (
          <QuickAccountDrawer
            show={addQuickAccount}
            closeModal={setAddQuickAccount}
            InnerTab={true}
            contactDetails={contactDetails}
            primaryExist={
              contactAccount?.find(
                (item) => item?.AccountType?.name === 'PERSONAL'
              )
                ? true
                : false
            }
          />
        )}
      </div>
      <FilterContainer>
        {showFilter !== 'closed' && (
          <AccountsFilter
            show={showFilter}
            closeModal={setShowFilter}
            producer={producer}
            setProducer={setProducer}
            accountType={accountType}
            setAccountType={setAccountType}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            accountStatus={accountStatus}
            setAccountStatus={setAccountStatus}
            hasPolicy={hasPolicy}
            setHasPolicy={setHasPolicy}
          />
        )}
      </FilterContainer>
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid=''
          loading={loadingStatus || 'idle'}
          resource='account'
          name={detailName}
        />
      )}
    </InnerFieldStyle>
  );
};

export default AccountsInnertab;

import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AppDispatch } from '../../../store';
import { fetchAgents } from '../../../api/agents';
import { fetchContacts } from '../../../api/contact';
import { fetchAccounts } from '../../../api/account';
import { fetchOpportunities } from '../../../api/opportunities';
import { addDocument } from '../../../store/documents/documentsSlice';
import {
  fetchTaskType,
  fetchTaskPriority,
  fetchTaskStatus,
} from '../../../api/task';
import {
  getTasksDetails,
  deleteATask,
  updateTaskDetails,
  TaskSliceState,
  deleteNote,
  getAllActivtiesCount,
} from '../../../store/tasks/tasksSlice';
import {
  updateDocumentDetails,
  reaploadDocumentDetails,
} from '../../../store/documents/documentsSlice';
import { fetchLeads } from '../../../api/lead';
import { fetchPolicies } from '../../../api/policy';
import { getDocumentType } from '../../../api/documents';

import MarkdownEditor from '../../../components/MarkdownEditor';
import DocumentsField from '../../../components/DocumentsField';
import TimePicker from '../../../components/TimePicker';
import TextAreaField from './TextAreaField';
import NotesContainer from '../../../components/NotesContainer';
import LinkToField from './LinkToField';
import Modal from '../../../components/Modal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import WarningCard from '../../Pipeline/components/WarningCard';
import { IProducer } from '../../../interfaces/Agent';
import { IContact } from '../../../interfaces/Contact';
import { IAccount } from '../../../interfaces/Account';

import LocalStorageService from '../../../utils/LocalStorageService';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { StyledAddModal } from '../elements/styles';

import cross from '../../../assets/logo/cross.svg';
import plus from '../../../assets/logo/blackplus.svg';
import vector from '../../../assets/logo/dropdown.svg';
import CustomDateField from './CustomDateField';
import bin from '../../../assets/logo/bin.svg';
import message from '../../../assets/logo/Dent/message.png';
import phone from '../../../assets/logo/Dent/phone.png';
import mail from '../../../assets/logo/Dent/mail.png';
import calender from '../../../assets/logo/Dent/calendar.png';
import tick from '../../../assets/logo/tick.svg';
import edit from '../../../assets/logo/edit.svg';
import warning from '../../../assets/logo/warning.svg';
import document from '../../../assets/logo/document.svg';
import others from '../../../assets/logo/Dent/others.png';
import othersvector from '../../../assets/logo/Dent/others-active.png';
import messagevector from '../../../assets/logo/Dent/message-active.png';
import phonevector from '../../../assets/logo/Dent/phone-active.png';
import calendarvector from '../../../assets/logo/Dent/calendar-active.png';
import emailvector from '../../../assets/logo/Dent/mail-active.png';
import redVector from '../../../assets/logo/redVector.svg';

const DetailsTaskModal = ({
  show,
  closeModal,
  uuid,
  setAction,
  setCloseAllTabs,
}: {
  show: string;
  closeModal: Function;
  uuid: string;
  setAction: Function;
  setCloseAllTabs: Function;
}) => {
  const [accountList, setAccounts] = useState<any[]>([]);
  const [accountUUID, setAccountUUID] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [linkedClicked, setLinkedClicked] = useState(true);

  ///////////////////

  const [contactList, setContacts] = useState<any[]>([]);
  const [contactUUID, setContactUUID] = useState('');
  const [contactSearch, setContactSearch] = useState('');

  /////////////

  const [opportunityList, setOpportunity] = useState<any[]>([]);
  const [opportunityUUID, setOpportunityUUID] = useState('');
  const [opportunitySearch, setOpportunitySearch] = useState('');

  /////////////
  const [leadList, setLeads] = useState<any[]>([]);
  const [leadUUID, setLeadUUID] = useState('');
  const [leadSearch, setLeadSearch] = useState('');

  /////////////
  const [policyList, setPolicies] = useState<any[]>([]);
  const [policyUUID, setPolicyUUID] = useState('');
  const [policySearch, setPolicySearch] = useState('');

  ///////////
  const [DueTimeModal, setDueTimeModal] = useState(false);
  const [duetimeVal, setDueTimeVal] = useState('');

  ///////////
  const [linkToMsg, setLinktoMsg] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [statusUUID, setStatusUUID] = useState('');
  const [assignees, setAssignees] = useState<IProducer[]>([]);
  const [assignee, setAssignee] = useState('');
  const [assigneesList, setAssigneesList] = useState(false);
  const [priorityList, setPriorityList] = useState(false);
  const [assigneeUUID, setAssigneeUUID] = useState('');
  const [taskType, setTaskType] = useState([]);
  const [tastPriorityList, setTastPriorityList] = useState([]);
  const [tastPriority, setTastPriority] = useState('');
  const [priorityUUID, setPriorityUUID] = useState('');
  const [linkedTo, setLinkedTo] = useState('Contact');
  const [linkToDropDown, setLinkToDropDown] = useState(false);
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState<any>({});
  const [pastTimeError, setPastTimeError] = useState('');
  const [typeUUID, setTypeUUID] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [notes, setNotes] = useState<any>();
  const [taskDetails, setTaskDetails] = useState<any>({});
  const [onDelete, setDelete] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState(false);
  const [file, setFile] = useState<any>();
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [DoctypeUUID, setDocTypeUUID] = useState('');
  const [docType, setDocType] = useState('');
  const [error, setError] = useState('');
  const [dropDown, setDropDown] = useState(false);
  const [docUUID, setDocUUID] = useState('');
  const [notesError, setNotesError] = useState('');
  const [reupload, setReupload] = useState(false);
  const [markAsDone, setMarkAsDone] = useState(false);
  const [HoverUUID, setHoverUUID] = useState('');
  const [oldNotes, setOldNotes] = useState<any>([{}]);
  const [makeEditNote, setMakeEditNote] = useState(false);
  const [noteIndex, setNoteIndex] = useState(0);
  const [noteEditDropdown, setNoteEditDropdown] = useState(false);
  const [isdeleted, setIsdeleted] = useState(false);
  const [fileExist, setFileExist] = useState(false);
  const [fileError, setFileError] = useState('');
  const dropRef = useRef<HTMLButtonElement>(null);
  const [descriptionError, setdescriptionError] = useState('');
  const dropTimeRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  useOutsideClick(dropTimeRef, setDueTimeModal);
  useOutsideClick(dropRef, setMakeEditNote);
  const [noteCheck, setNoteCheck] = useState(true);
  const [textCheck, setTextCheck] = useState('');

  var x = 15;
  let Times = [];
  var tt = 0;
  var ap = ['AM', 'PM'];

  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = tt % 60;
    Times[i] =
      ('' + (hh === 12 ? 12 : hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)];
    tt = tt + x;
  }

  const { register, control, handleSubmit } = useForm({
    //     resolver: yupResolver(PipelineSchema),
  });

  const addsNotes = () => {
    let settingNotes = [...notes];
    setNotes([]);
    setNotesError('');
    setMakeEditNote(true);
    setNoteIndex(0);
    settingNotes.unshift({ text: '' });
    setTimeout(() => {
      setNotes(settingNotes);
    });
  };

  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
  }, [show]);

  const user = assignees.find(
    (data: any) => data.User?.uuid === LocalStorageService.getUser()?.uuid
  );

  let fullName = user?.User?.firstName + ' ' + user?.User?.lastName;

  const taskDetailsStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks?.status
  );
  useEffect(() => {
    dispatch(
      getTasksDetails({
        uuid,
        successCB: (data: any) => {
          setDueDate({ dueDate: data?.dueDate });
          setDueTimeVal(data?.dueDate);
          setTaskDetails(data);
          setDescription(data?.text);
          setAssigneeUUID(data?.Assignee?.uuid);
          setNotes(
            data?.Notes.map((item: any) => {
              return {
                text: item?.text,
                noteUUID: item?.uuid,
                updatedAt: item?.updatedAt,
              };
            })
          );
          setOldNotes(
            data?.Notes.map((item: any, key: number) => {
              return {
                text: item?.text,
                noteUUID: item?.uuid,
                updatedAt: item?.updatedAt,
              };
            })
          );

          setAssignee(
            data?.Assignee?.firstName + '  ' + data?.Assignee?.lastName
          );
          if (data?.Documents[0]) {
            setFileExist(true);
            setFile(data?.Documents[0]);
            setDocUUID(data?.Documents[0]?.uuid);
            setDocType(data?.Documents[0]?.DocumentType?.name);
            setDocTypeUUID(data?.Documents[0]?.DocumentType?.uuid);
          }
          setTypeUUID(data?.TaskType?.uuid);
          setTastPriority(data?.TaskPriority?.name);

          setPriorityUUID(data?.TaskPriority?.uuid);
          if (data?.OpportunityId) {
            setOpportunityUUID(data?.Opportunity?.uuid);
            setOpportunitySearch(data?.Opportunity?.name);
          } else if (data?.ContactId) {
            setContactSearch(
              data?.Contact?.firstName + ' ' + data?.Contact?.lastName
            );
            setContactUUID(data?.Contact?.uuid);
          } else if (data?.AccountId) {
            setAccountSearch(data?.Account?.name);
            setAccountUUID(data?.Account?.uuid);
          } else if (data?.LeadId) {
            setLeadSearch(data?.Lead?.data?.contact?.name);
            setLeadUUID(data?.Lead?.uuid);
          } else if (data?.PolicyId) {
            setPolicySearch(data?.Policy?.Accounts?.name);
            setPolicyUUID(data?.Policy?.uuid);
          }
          setLinkedTo(
            data?.ContactId
              ? 'Contact'
              : data?.AccountId
              ? 'Account'
              : data?.OpportunityId
              ? 'Opportunity'
              : data?.LeadId
              ? 'Lead'
              : data?.PolicyId
              ? 'Policy'
              : ''
          );
        },
      })
    );
  }, [dispatch, uuid, isdeleted]);
  useEffect(() => {
    if (typeUUID !== taskDetails?.TaskType?.uuid) {
      setDetailsChanged(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeUUID]);

  useEffect(() => {
    fetchAgents(
      (data: IProducer[]) => {
        setAssignees(data);
      },
      'id',
      'asc',
      '',
      'True'
    );
    fetchTaskType((data: any) => {
      setTaskType(data);
    });
    fetchTaskStatus((data: any) => {
      let opendata = data.find((el: any) => el.name === 'OPEN');
      setStatusUUID(opendata?.uuid);
    });
    fetchTaskPriority((data: any) => {
      setTastPriorityList(data);
      // setTastPriority(data.find((el: any) => el.name === 'LOW').name);
      // setPriorityUUID(data.find((el: any) => el.name === 'LOW').uuid);
    });
  }, [typeUUID]);

  const loadOpportunity = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded')
      fetchOpportunities(
        'name',
        'asc',
        value,

        '',
        '',
        (data: any) => {
          setOpportunity(data?.opportunities);
          setLoadingStatus('succeeded');
        },
        '',
        '',
        25,
        0
      );
    // setOpportunity(useSelector(selectAllOpportunities));
  };
  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchPolicies(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        [],
        false,
        '',
        '',
        '',
        '',
        '',
        (data: any) => {
          setPolicies(data?.policies);
          setLoadingStatus('succeeded');
        }
      );
      setLinktoMsg('');
    }
  };

  const loadLead = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchLeads('name', 'asc', value, 25, 0, '', '', '', '', (data: any) => {
        setLeads(data.leads);
        setLoadingStatus('succeeded');
      });
      setLinktoMsg('');
    }
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
        },
        'True'
      );
    }
  };

  const loadContact = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchContacts(
        'firstName',
        'asc',
        value,
        25,
        0,
        'True',
        (data: IContact[]) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        'PERSONAL'
      );
    }
  };

  const onSet = (name: string, value: string) => {
    setDescription(value);
  };

  const onSetDate = (name: string, value: string) => {
    setDueDate({ dueDate: value });
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const dueDateTime = moment(
    moment(dueDate.dueDate).format('YYYY-MM-DD') +
      moment(duetimeVal).format('hh:mm A'),
    'YYYY-MM-DDLT'
  ).toDate();

  const markingAsDone = () => {
    dispatch(getAllActivtiesCount({}));
    dispatch(
      updateTaskDetails({
        uuid,
        data: { action: 'MARK_AS_DONE' },
        successCB: () => {
          setAction(true);
          setMarkAsDone(true);
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 2000);
        },
      })
    );
  };
  useEffect(() => {
    if (taskDetails) {
      if (taskDetails?.OpportunityId || opportunityUUID) {
        if (
          opportunityUUID &&
          taskDetails?.Opportunity?.uuid !== opportunityUUID
        ) {
          setLinkedClicked(false);
        }
      }
      if (taskDetails?.ContactId || contactUUID) {
        if (contactUUID && taskDetails?.Contact?.uuid !== contactUUID) {
          setLinkedClicked(false);
        }
      }
      if (taskDetails?.AccountId || accountUUID) {
        if (accountUUID && taskDetails?.Account?.uuid !== accountUUID) {
          setLinkedClicked(false);
        }
      }
      if (taskDetails?.LeadId || leadUUID) {
        if (leadUUID && taskDetails?.Lead?.uuid !== leadUUID) {
          setLinkedClicked(false);
        }
      }
      if (taskDetails?.PolicyId || policyUUID) {
        if (policyUUID && taskDetails?.Policy?.uuid !== policyUUID) {
          setLinkedClicked(false);
        }
      }
    }
  }, [
    opportunityUUID,
    contactUUID,
    accountUUID,
    taskDetails,
    policyUUID,
    leadUUID,
  ]);
  useEffect(() => {
    if (noteEditDropdown) {
      setLinkedClicked(false);
    }
  }, [noteEditDropdown]);
  const onSubmit = (data: any) => {
    if (file && !DoctypeUUID) {
      setError('Please provide a document type');
      return;
    }
    if (dueDateTime < new Date() || !dueDateTime.getDate()) {
      setPastTimeError('Please Select future time');
      return;
    }
    if (linkedTo === 'Contact' && !contactUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Account' && !accountUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Opportunity' && !opportunityUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Policy' && !policyUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Lead' && !leadUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    }
    if (linkedTo === 'Opportunity') {
      data.accountUUID = null;
      data.contactUUID = null;
      data.opportunityUUID = opportunityUUID;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Account') {
      data.accountUUID = accountUUID;
      data.contactUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Contact') {
      data.contactUUID = contactUUID;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Lead') {
      data.contactUUID = null;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = leadUUID;
      data.policyUUID = null;
    }
    if (linkedTo === 'Policy') {
      data.contactUUID = null;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = policyUUID;
    }
    for (let i = 0; i < notes.length; i++) {
      if (!notes[i].text.trim()) {
        setNotesError(`Notes shouldn't be empty`);
        return notesError;
      }
    }
    let updatedNotes = [...notes];
    for (let i = 0; i <= updatedNotes.length; i++) {
      for (let j = 0; j <= oldNotes.length; j++) {
        if (updatedNotes[i]?.text === oldNotes[j]?.text) {
          updatedNotes.splice(i, 1);
        }
      }
    }

    data.assigneeUUID = assigneeUUID;
    data.taskPriorityUUID = priorityUUID;
    data.dueDate = dueDateTime;
    data.taskStatusUUID = statusUUID;
    data.taskTypeUUID = typeUUID;
    let canSubmit = true;
    if (description) {
      if (!description.trim()) {
        canSubmit = false;
        setdescriptionError('Description cannot be blank space');
      }
    }
    data.description = description;
    data.notes = updatedNotes;
    if (canSubmit) {
      dispatch(
        updateTaskDetails({
          uuid,
          data: data,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            if (
              dueDateTime.getDate() !== new Date(taskDetails.dueDate).getDate()
            ) {
              setAction(true);
              setCloseAllTabs(true);
            }
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 3000);
          },
        })
      );
    }

    if (reupload && uuid && fileExist && file && DoctypeUUID) {
      let formData = new FormData();
      formData.append('docFile', file);
      formData.append('resource', 'task');
      formData.append('resourceUUID', uuid);
      formData.append('documentTypeUUID', DoctypeUUID);
      formData.append('action', 'updateDocument');
      dispatch(
        reaploadDocumentDetails({
          uuid: docUUID,
          data: formData,
          successCB: () => {},
        })
      );
    } else if (uuid && !reupload && fileExist && DoctypeUUID) {
      data.documentTypeUUID = DoctypeUUID;
      data.action = 'documentTypeUpdate';
      dispatch(
        updateDocumentDetails({
          uuid: docUUID,
          data: data,
          successCB: () => {},
        })
      );
    } else if (!fileExist && !reupload && file && DoctypeUUID) {
      let formData = new FormData();
      formData.append('docFile', file);
      formData.append('resource', 'task');
      formData.append('resourceUUID', uuid);
      formData.append('documentTypeUUID', DoctypeUUID);
      dispatch(
        addDocument({
          formData,
          successCB: () => {},
          errorCB: () => {},
        })
      );
    }
  };
  const onSetNotesValue = (value: any, index: number, uuid?: string) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = { text: value, noteUUID: uuid };
    setNotes(updatedNotes);
  };

  let callUUID: any = taskType.find((item: any) => item.name === 'CALL');
  let smsUUID: any = taskType.find((item: any) => item.name === 'SMS');
  let mailUUID: any = taskType.find((item: any) => item.name === 'EMAIL');
  let meetingUUID: any = taskType.find((item: any) => item.name === 'MEETING');
  let othersUUID: any = taskType.find((item: any) => item.name === 'OTHERS');

  const deleteTask = (uuid: string) => {
    dispatch(
      deleteATask({
        successCB: () => {
          setAction(true);
          dispatch(getAllActivtiesCount({}));
          setDelete(true);
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 3000);
        },
        uuid,
      })
    );
  };

  const deleteANote = (uuid: string) => {
    setIsdeleted(!isdeleted);
    dispatch(
      deleteNote({
        successCB: () => {},
        uuid,
      })
    );
  };

  useEffect(() => {
    if (description) {
      setdescriptionError('');
    }
  }, [description]);

  return (
    <>
      {showSuccess && !onDelete && (
        <WarningCard
          message={
            !markAsDone
              ? `<b>${fullName}'s  </b>Task has <b> Updated</b> `
              : `<b>Congratulation, thank you for your effort</b>`
          }
          title={`Success updating Task.`}
        />
      )}
      {showSuccess && onDelete && (
        <ErrorCard
          message={`<b>${fullName}'s </b>Task  has been  <b>Deleted</b>`}
          title={'Success deleting task.'}
        />
      )}
      <StyledAddModal>
        <Modal open={show} align='right'>
          <div className='drawer-content position-relative h-100'>
            <div className='top-div d-flex justify-content-between align-items-center'>
              <h2>Task details</h2>
              <button className='cross-btn'>
                <img
                  className='cross'
                  src={cross}
                  alt=''
                  onClick={() => {
                    onCloseModal();
                  }}
                />
              </button>
            </div>
            <div className='NavButtons'></div>
            <div className='NavButtons d-flex w-100 justify-content-between'>
              <div className='d-flex'>
                <button
                  className='doneButton'
                  type='button'
                  onClick={() => markingAsDone()}
                >
                  Mark as done
                </button>
              </div>
              <img
                className='deleteButton'
                src={bin}
                alt=''
                onClick={() => deleteTask(taskDetails?.uuid)}
              />
            </div>
            <form className='pipeline-form' onSubmit={handleSubmit(onSubmit)}>
              <div className='taskModalContent'>
                <div className='taskContainer '>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Task type</h2>
                    <div className='d-flex align-items-center imagesContainer '>
                      <div className='text-overflow-container'>
                        <img
                          className={`taskImage 
                        
                            ${typeUUID === callUUID?.uuid && 'activeType'}
                          `}
                          onMouseEnter={() => {
                            setHoverUUID(callUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            typeUUID === callUUID?.uuid ||
                            HoverUUID === callUUID?.uuid
                              ? phonevector
                              : phone
                          }
                          alt=''
                          onClick={() => callUUID && setTypeUUID(callUUID.uuid)}
                        />
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>Phone</span>
                        </span>
                      </div>
                      <div className='text-overflow-container'>
                        <img
                          className={`taskImage
                          
                            ${typeUUID === smsUUID?.uuid && 'activeType'}
                          
                          `}
                          onMouseEnter={() => {
                            setHoverUUID(smsUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            typeUUID === smsUUID?.uuid ||
                            HoverUUID === smsUUID?.uuid
                              ? messagevector
                              : message
                          }
                          alt=''
                          onClick={() => smsUUID && setTypeUUID(smsUUID.uuid)}
                        />
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>Message</span>
                        </span>
                      </div>
                      <div className='text-overflow-container'>
                        <img
                          className={`taskImage ${
                            typeUUID === mailUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(mailUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            typeUUID === mailUUID?.uuid ||
                            HoverUUID === mailUUID?.uuid
                              ? emailvector
                              : mail
                          }
                          alt=''
                          onClick={() => mailUUID && setTypeUUID(mailUUID.uuid)}
                        />
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>Email</span>
                        </span>
                      </div>
                      <div className='text-overflow-container'>
                        <img
                          className={`taskImage  ${
                            typeUUID === meetingUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(meetingUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            typeUUID === meetingUUID?.uuid ||
                            HoverUUID === meetingUUID?.uuid
                              ? calendarvector
                              : calender
                          }
                          alt=''
                          onClick={() =>
                            meetingUUID && setTypeUUID(meetingUUID.uuid)
                          }
                        />
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>Meeting</span>
                        </span>
                      </div>
                      <div className='text-overflow-container'>
                        <img
                          className={`taskImage  ${
                            typeUUID === othersUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(othersUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            typeUUID === othersUUID?.uuid ||
                            HoverUUID === othersUUID?.uuid
                              ? othersvector
                              : others
                          }
                          alt=''
                          onClick={() =>
                            othersUUID && setTypeUUID(othersUUID.uuid)
                          }
                        />
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>Others</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Priority</h2>
                    <div className='d-flex align-items-center  col'>
                      <div
                        className={`${
                          tastPriority === 'LOW'
                            ? 'lowPriority'
                            : tastPriority === 'HIGH'
                            ? 'highPriority'
                            : tastPriority === 'MEDIUM'
                            ? 'mediumPriority'
                            : 'lowPriority'
                        }   userpriorityDiv d-flex justify-content-between align-items-center`}
                        onClick={() => setPriorityList(!priorityList)}
                      >
                        <h2 className={`priorityLabel `}>
                          {tastPriority ? tastPriority?.toLowerCase() : '----'}
                        </h2>
                        {priorityList && (
                          <div className='priorityDropdown d-flex flex-column justify-content-center'>
                            {tastPriorityList &&
                              tastPriorityList.map((item: any) => (
                                <ul
                                  className={`priorityList ${
                                    item?.name === 'LOW'
                                      ? 'lowPriority'
                                      : item?.name === 'HIGH'
                                      ? 'highPriority'
                                      : item?.name === 'MEDIUM'
                                      ? 'mediumPriority'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    setTastPriority(item?.name);
                                    setPriorityUUID(item?.uuid);
                                  }}
                                >
                                  {item?.name?.toLowerCase()}
                                </ul>
                              ))}
                          </div>
                        )}
                        <img src={vector} alt='' />
                      </div>
                    </div>
                  </div>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Due</h2>
                    <div>
                      <div className='d-flex align-items-center  col'>
                        <div className='smallDateField'>
                          <CustomDateField
                            name='dueDate'
                            onSetItem={onSetDate}
                            values={dueDate}
                            placeholder='Due'
                            minDate={new Date()}
                          />
                        </div>
                        <div
                          className='smallDateFielddateOnly position-relative'
                          onClick={() => setDueTimeModal(true)}
                          ref={dropTimeRef}
                        >
                          <span>
                            {duetimeVal
                              ? moment(duetimeVal.toString()).format('hh:mm A')
                              : 'Time'}
                          </span>
                          {DueTimeModal && (
                            <TimePicker
                              setTaskDueTime={setDueTimeModal}
                              setDueTime={setDueTimeVal}
                              dueTime={duetimeVal}
                            />
                          )}
                        </div>
                      </div>

                      {(!dueDate.dueDate || dueDateTime < new Date()) &&
                      pastTimeError ? (
                        <span className='error-field'>{pastTimeError} </span>
                      ) : null}
                    </div>
                  </div>

                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Task stage</h2>
                    <div className='d-flex align-items-center  col'>
                      <div className='taskStageDiv align-items-center justify-content-between d-flex'>
                        <h2 className='priorityLabel  '>To-Do</h2>
                        <img src={redVector} alt='' className='redVector' />
                      </div>
                    </div>
                  </div>
                  <div className='d-sm-flex s taskTypeDiv'>
                    <h2 className='titleLabel LinktoDiv col-3'>Link to</h2>
                    <div className='d-flex d-sm-block col'>
                      <button
                        type='button'
                        className='linkDiv d-flex justify-content-between align-items-center'
                        onClick={() => setLinkToDropDown(!linkToDropDown)}
                        onBlur={() => setLinkToDropDown(false)}
                      >
                        <h6 className='bookLabel '>{linkedTo}</h6>
                        {linkToDropDown && (
                          <div className='assigneeDropDown linkToDropDown d-flex flex-column justify-content-center'>
                            {[
                              'Contact',
                              'Account',
                              'Opportunity',
                              'Lead',
                              'Policy',
                            ]?.map((item: any, index: number) => (
                              <div key={index} className='dropList'>
                                <ul
                                  className={`usersList ${
                                    linkedTo === item && 'activeLink'
                                  }`}
                                  onClick={() => {
                                    setLinkedTo(item);
                                  }}
                                >
                                  {item}
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}
                        <img src={vector} alt='' />
                      </button>
                      <div className='Linkedfields'>
                        {linkedTo === 'Contact' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={contactSearch}
                            resourceList={contactList}
                            setResourceSearch={setContactSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadContact}
                            setResourceUUID={setContactUUID}
                          />
                        )}
                        {linkedTo === 'Opportunity' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={opportunitySearch}
                            resourceList={opportunityList}
                            setResourceSearch={setOpportunitySearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadOpportunity}
                            setResourceUUID={setOpportunityUUID}
                          />
                        )}
                        {linkedTo === 'Account' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={accountSearch}
                            resourceList={accountList}
                            setResourceSearch={setAccountSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadAccount}
                            setResourceUUID={setAccountUUID}
                          />
                        )}
                        {linkedTo === 'Lead' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={leadSearch}
                            resourceList={leadList}
                            setResourceSearch={setLeadSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadLead}
                            setResourceUUID={setLeadUUID}
                          />
                        )}
                        {linkedTo === 'Policy' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={policySearch}
                            resourceList={policyList}
                            setResourceSearch={setPolicySearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadPolicy}
                            setResourceUUID={setPolicyUUID}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Assignee</h2>
                    <div className='d-flex align-items-center  col'>
                      <button
                        type='button'
                        className='userAssigneeDiv d-flex justify-content-between align-items-center'
                        onClick={() => setAssigneesList(!assigneesList)}
                        onBlur={() => setAssigneesList(false)}
                      >
                        <h2 className='titleLabel taskAssigneeLabel  d-flex align-items-center  '>
                          <div className='Assigneeprofile-pic d-flex align-items-center justify-content-center'>
                            <div className='profile'>
                              {assignee?.charAt(0)} {assignee?.charAt(6)}
                            </div>
                          </div>
                          <span className='assigneeName'>{assignee}</span>
                        </h2>
                        {assigneesList && (
                          <div className='assigneeDropDown'>
                            {assignees &&
                              assignees?.map((item: any, index: number) => (
                                <div
                                  key={index}
                                  className='AssigneeCard d-flex align-items-center'
                                  onClick={() => {
                                    setAssignee(
                                      item?.User?.firstName +
                                        ' ' +
                                        item.User?.lastName
                                    );
                                    setAssigneeUUID(item?.User?.uuid);
                                  }}
                                >
                                  <div className='profile-pic d-flex align-items-center justify-content-center'>
                                    {item?.User?.firstName.charAt(0)}{' '}
                                    {item?.User?.lastName.charAt(0)}
                                  </div>
                                  <div>
                                    <h6>{`${item?.User?.firstName} ${item?.User?.lastName}`}</h6>
                                    <ul className='user-details'>
                                      <li>
                                        {item?.User?.email
                                          ? item?.User?.email
                                          : '------'}
                                      </li>
                                      <li>
                                        {item?.User?.phoneNumber
                                          ? item?.User?.phoneNumber
                                          : '------'}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        <img src={vector} alt='' />
                      </button>
                    </div>
                  </div>
                  <div className='d-sm-flex taskTypeDiv descripionDiv '>
                    <h2 className='titleLabel col-3'>Description</h2>
                    <div className='d-flex align-items-center descriptionField w-100'>
                      <TextAreaField
                        name='description'
                        placeholder='Add description'
                        values={{ description: description }}
                        onSetItem={onSet}
                      />
                      <img src={edit} alt='' className='editDesc' />
                    </div>
                  </div>
                  {descriptionError && (
                    <div className='errorDIvDesc d-flex align-items-center'>
                      <span className='errorVal'>
                        <img className='erricon' src={warning} alt='' />
                        {descriptionError}
                      </span>
                    </div>
                  )}
                </div>

                <div className=' notesDiv d-flex justify-content-between w-100'>
                  <h2 className='titleLabel'>Note</h2>
                  <button
                    type='button'
                    className='addNotesButton'
                    onClick={() => {
                      if (notes.length > 0) {
                        notes.slice(0, 1).pop()?.text.trim() && addsNotes();
                      } else {
                        addsNotes();
                      }
                    }}
                  >
                    <img src={plus} alt='' className='smallAddbutton' /> Add
                    note
                  </button>
                </div>
                <div>
                  {taskDetailsStatus === 'succeeded' &&
                    notes?.map((item: any, index: number, key: number) =>
                      makeEditNote && index === noteIndex ? (
                        <div
                          key={index}
                          className='d-flex align-items-center descriptionField  col'
                        >
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item.text ? item.text : ''}
                              uuid={item.noteUUID ? item.noteUUID : ''}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                              textCheckFunction={(value: string) => {
                                setTextCheck(value);
                                if (!textCheck) {
                                  setNoteCheck(false);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <NoteCard
                          taskDetails={taskDetails}
                          item={item}
                          index={index}
                          noteIndex={noteIndex}
                          setNoteIndex={setNoteIndex}
                          setMakeEditNote={setMakeEditNote}
                          deleteANote={deleteANote}
                        />
                      )
                    )}

                  {notesError ? (
                    <div className='errorVal'>
                      <img src={warning} alt='warning' className='warning' />
                      {notesError}
                    </div>
                  ) : null}
                </div>
                <div className=' DocumentsDiv'>
                  <h2 className='titleLabel'>Documents (optional)</h2>
                  <DocumentsField
                    file={file}
                    setFile={setFile}
                    colorless={true}
                    setFileError={setFileError}
                    disabled={file?.uuid && DoctypeUUID}
                  />
                  {file && (
                    <div className='documentDetails'>
                      <img src={document} alt='' />
                      <span className='docName'>{file?.name}</span>
                      <div className='itemsDiv d-flex justify-content-between align-items-center'>
                        <button
                          className='documentDropDown d-flex justify-content-between align-items-center'
                          onClick={() => setDropDown(!dropDown)}
                          type='button'
                        >
                          {docType ? docType : 'Document type'}
                          <img src={vector} alt='' />
                          {dropDown && (
                            <div className='documentTypeDrop'>
                              {' '}
                              {DocumentTypes &&
                                DocumentTypes?.map((item: any) => (
                                  <div
                                    key={item}
                                    className='listType d-flex align-items-center '
                                    onClick={() => {
                                      setDropDown(false);
                                      setDocType(item?.name);
                                      setDocTypeUUID(item.uuid);
                                    }}
                                  >
                                    <ul>{item?.name}</ul>
                                  </div>
                                ))}
                            </div>
                          )}
                        </button>
                        <img
                          className='binImage'
                          src={bin}
                          alt=''
                          onClick={() => {
                            setFile('');
                            setDocTypeUUID('');
                            setDocType('');
                            setReupload(true);
                          }}
                        />
                      </div>
                      {error && !DoctypeUUID && file && (
                        <div className='errorDIv d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {error}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {fileError && (
                    <div className='errorDIv d-flex align-items-center'>
                      <span className='errorVal'>
                        <img className='erricon' src={warning} alt='' />
                        {fileError}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {detailsChanged && (
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Discard
                  </button>
                  <button
                    disabled={
                      taskDetails?.Assignee?.uuid === assigneeUUID &&
                      taskDetails?.text === description &&
                      taskDetails?.TaskType?.uuid === typeUUID &&
                      taskDetails?.Documents[0]?.name === file?.name &&
                      taskDetails?.TaskPriority?.uuid === priorityUUID &&
                      dueDateTime.getDate() ===
                        new Date(taskDetails?.dueDate).getDate() &&
                      dueDateTime.getTime() ===
                        new Date(taskDetails?.dueDate).getTime() &&
                      linkedClicked &&
                      taskDetails?.Documents[0]?.DocumentType?.uuid ===
                        DoctypeUUID &&
                      !notesError &&
                      noteCheck
                    }
                    type='submit'
                    className='saveButton'
                  >
                    Save
                    <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              )}
            </form>
          </div>
        </Modal>
      </StyledAddModal>
    </>
  );
};

export default DetailsTaskModal;

const NoteCard = ({
  taskDetails,
  item,
  index,
  noteIndex,
  setNoteIndex,
  setMakeEditNote,
  deleteANote,
}: {
  taskDetails: any;
  item: any;
  index: Number;
  noteIndex: any;
  setNoteIndex: Function;
  setMakeEditNote: Function;
  deleteANote: Function;
}) => {
  const noteRef = useRef(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  useOutsideClick(noteRef, setShowActionsDropdown);

  return (
    <div className='d-flex align-items-center descriptionField col'>
      <div className='notesTextarea'>
        <div className='textContetnt d-flex justify-content-between'>
          <div>
            <span className='namefield'>
              {taskDetails?.CreatedBy?.firstName +
                ' ' +
                taskDetails?.CreatedBy?.lastName}
            </span>{' '}
            <span className='dotarea'>•</span>
            <span>{moment(item?.updatedAt).format('MMM D YYYY | h:mm A')}</span>
          </div>
          <button
            className='vectorContainer'
            onClick={() => {
              setShowActionsDropdown(true);
              setNoteIndex(index);
              setMakeEditNote(false);
            }}
            type='button'
            ref={noteRef}
          >
            <img src={vector} alt='vect' />
          </button>
        </div>
        {item?.text && <NotesContainer text={item.text} />}
      </div>
      {showActionsDropdown && noteIndex === index && (
        <div className='dropdown-div'>
          <div
            className='edit-div d-flex justify-content-between'
            onClick={() => {
              setMakeEditNote(true);
              setNoteIndex(index);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div
            className='delete-div d-flex justify-content-between'
            onClick={() => {
              deleteANote(item?.noteUUID);
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </div>
  );
};

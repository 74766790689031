import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { getAccountType } from '../../../../api/account';
import { fetchAgents } from '../../../../api/agents';
import { IProducer } from '../../../../interfaces/Agent';
import SearchDropdown from '../../../../components/SearchDropdown';
import { fetchJurisdiction } from '../../../../api/jurisdiction';
import { IJurisdiction } from '../../../../interfaces/Jurisdiction';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import { getLeadSourceTypes, getLeadStage } from '../../../../api/lead';
import { ILead, ILeadSourceType } from '../../../../interfaces/Lead';
import Skeleton from 'react-loading-skeleton';

const LeadsFilter = ({
  show,
  closeModal,
  filterFunction,
  sourceType,
  setSourceType,
  sourceStage,
  setSourceStage,
  producer,
  setProducer,
  accountType,
  setAccountType,
  producerSearch,
  setProducerSearch,
}: {
  show: string;
  closeModal: Function;
  filterFunction: Function;
  sourceType: any;
  setSourceType: Function;
  sourceStage: any;
  setSourceStage: Function;
  producer: string;
  setProducer: Function;
  accountType: any;
  setAccountType: Function;
  producerSearch: string;
  setProducerSearch: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [type, setType] = useState([]);
  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const user = LocalStorageService.getUser();

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      // setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            // setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      }
    }
  };

  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);
  const [status, setStatus] = useState<ILead[]>([]);

  useEffect(() => {
    setLoadingStatus('loading');
    getAccountType((data: any) => {
      setType(data);
      setLoadingStatus('succeeded');
    });
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
      setLoadingStatus('succeeded');
    });
    getLeadStage((data: ILead[]) => {
      setStatus(data);
      setLoadingStatus('succeeded');
    });
  }, []);

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  // useEffect(() => {
  //   if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
  //     // setLoadingStatus('loading');
  //     filterFunction(
  //       sourceStage.map((item: any) => item.value),
  //       producer,
  //       sourceType.map((item: any) => item.value),
  //       accountType?.value
  //     );
  //   }
  // }, [accountType, producer, sourceStage, sourceType]);

  const [sourceOptions, setSourceOptions] = useState<any>([]);
  useEffect(() => {
    if (sourceTypes.length > 0) {
      setSourceOptions([]);
      let op: any = [];
      sourceTypes.map((item: any) => {
        op.push({ label: item.name, value: item.uuid, isDisabled: true });
        item.LeadSources?.map((el: any) => {
          op.push({ label: el.name, value: el.uuid });
        });

        return;
      });
      setSourceOptions([...op]);
    }
  }, [sourceTypes]);

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Account Type</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={type.map((item: any) => {
                return {
                  label:
                    item.name?.charAt(0).toUpperCase() +
                    item.name?.toLowerCase().slice(1),
                  value: item.name,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setAccountType(value);
              }}
              value={accountType}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Lead Source</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={sourceOptions}
              placeholder='Select'
              onChange={(value: any) => {
                setSourceType(value);
              }}
              isMulti={true}
              value={sourceType}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Lead status</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={status.map((item: any) => {
                return {
                  label: item.name,
                  value: item.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setSourceStage(value);
              }}
              isMulti={true}
              value={sourceStage}
            />
          </div>
        )}

        {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Producer</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='producer'
                  placeholder='Select'
                  isEdit={true}
                  addName={true}
                  values={producerSearch}
                  searchValue={producers}
                  setSearch={setProducerSearch}
                  status={loadingStatus}
                  className={`${producerSearch && 'filled'}`}
                  loadFunction={loadProducer}
                  setUUID={resetProducer}
                  content={producers?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setProducerSearch(
                          `${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`
                        );
                        setProducer(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.User?.firstName?.charAt(0)}
                        {item?.User?.lastName?.charAt(0)}
                      </div>
                      <div>
                        <h6>
                          {`${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`}
                        </h6>
                        <ul className='user-details'>
                          <li>{item.User?.email}</li>
                          <li>{item?.User?.phoneNumber}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(accountType?.value ? 1 : 0) +
                          sourceStage.length +
                          sourceType.length +
                          (producer ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setProducer('');
                          setAccountType([]);
                          setProducerSearch('');
                          setSourceType([]);
                          setSourceStage([]);
                        }}
                        disabled={
                          (accountType?.value ? 1 : 0) +
                            sourceStage.length +
                            sourceType.length +
                            (producer ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(accountType?.value ? 1 : 0) +
                      sourceStage.length +
                      sourceType.length +
                      (producer ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setProducer('');
                      setAccountType([]);
                      setProducerSearch('');
                      setSourceType([]);
                      setSourceStage([]);
                    }}
                    disabled={
                      (accountType?.value ? 1 : 0) +
                        sourceStage.length +
                        sourceType.length +
                        (producer ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default LeadsFilter;

import styled from '@emotion/styled';
export const UserProfile = styled.div`
  h2 {
    margin-left: 12px;
    font-size: 16px;
  }
  .sideBar {
    width: 331px;
    background: #ffffff;
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);

    .navlink {
      border: none;
      display: block;
      margin: 16px 0;
      background: #fff;
      border-radius: 8px;
      width: 190px;
      color: #262758;
      text-decoration: none;
      padding: 14px 16px;
    }
    .navlink:hover {
      font-weight: 600;
      font-size: 16px;
      background: #ecf4f9;
    }
    .active {
      font-weight: 600;
      background: #c1dbeb;
    }
  }
  .fieldContainer {
    width: 100%;
    background: #fafafa;
    overflow: auto;
    padding: 21px 20px 80px 20px;
  }
  .notificationContainer {
    width: 100vw;
    background: #fafafa;
    padding: 21px 20px 80px 20px;
  }
  .subText {
    font-weight: 600;
    font-size: 24px;
  }
  .cameraDiv {
    background: #ecf4f9;
    border-radius: 40px;
    width: 80px;
    height: 80px;
    padding: 28px 30px;
  }
  .uploadedImg {
    border-radius: 40px;
    width: 80px;
    height: 80px;
  }
  .logoButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e6e9f0;
    border-radius: 8px;
    width: 219px;
    height: 40px;
    border: none;
    margin-left: 24px;
    margin-top: 18px;
    font-weight: 600;
    font-size: 16px;
    color: #262758;
    padding: 8px 20px;
  }
  .instructionText {
    margin-top: 8px;
    color: #5a5f70;
    font-size: 12px;
    margin-left: 24px;
    font-style: italic;
  }
  .scroll-bar {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 120px;
    padding-right: 20px;
    margin-right: 6px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .fields {
    margin-top: 16px;
    width: 100%;
  }
  .secondField {
    margin-left: 0px;
  }
  .personalInfoDiv {
    margin: 37px 0 14px 0;
  }
  .linkField {
    background: #ffffff;
    height: 48px;
    width: 100%;
    padding: 20px;
    border: 1px solid #dfe6f5;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
  }
  .mediaImage {
    margin-right: 16px;
  }
  .copyclipButton {
    background: #fff;
    border: none;
  }

  .mobileNavBar {
    cursor: pointer;
    margin: 16px 20px;
  }
  .mobileNavBar :hover {
    border-bottom: 2px solid #3c498a;
  }
  .password-div {
    position: relative;
    height: 48px;
  }
  .toggleButton {
    cursor: pointer;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 4px;
    right: 3%;
    top: 18%;
  }
  .toggleButton:hover {
    background: #e6e9f0;
  }
  .validationImage {
    margin-top: 10px;
  }
  .Passwordfields {
    margin-top: 16px;
  }
  .submit-button {
    margin-top: 60px;
    width: 100%;
    height: 48px;
  }
  .info {
    position: relative;
    .tooltiptext {
      width: 166px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 15px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      right: 10px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }
  }
  .errorVal {
    margin-top: 10px;
    font-size: 14px;
    color: #cc0000;
    .warning {
      margin-right: 10px;
    }
  }
  .errorValPassword {
    margin-top: 20px;
  }
  .errorValImge {
    margin-left: 20px;
    font-size: 12px;
    .warning {
      margin-right: 5px;
    }
  }
  .logo-spinner {
    width: 80px;
    height: 80px;
    background: #ecf4f9;
    backdrop-filter: contrast(0.5);
    border-radius: 50px;
    .spinner-border {
      top: 38%;
      left: 30px;
      position: absolute;
    }
  }
  .buttonDiv {
    width: 100%;
    // border-top: 1px solid #
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 20px;
    padding-left: 52px;
    margin-top: 70px;
  }
  .cancel-button {
    width: 140px;
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
    }
  }
  .spinnerStyle {
    color: #262758;
    width: 20px;
    height: 20px;
  }
  .subTextNotification {
    font-weight: 400;
    margin-top: 9px;
    color: #5a5f70;
    font-size: 14px;
  }
  .settingsCard {
    width: 100%;
    height: 80.69px;
    background: #ffffff;
    padding: 10px;
    border-radius: 8px;
  }
  .checkboxDiv {
    padding: 20px 16px;
    margin-right: 11px;
    border-right: 1px solid #e6e9f0;
  }
  .switch {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    width: 36px;
    height: 20px;
    background: #dfe6f5;
    transition: 0.4s;

    .inputCheckbox {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    border-radius: 40px;
    cursor: pointer;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    border-radius: 50%;
    background: #ffffff;
    transition: 0.4s;
  }
  .inputCheckbox:checked + .slider:before {
    transform: translateX(14px);
  }
  .inputCheckbox:checked + .slider {
    background: #58c070;
  }
  .settingsContainer {
    .settinghead {
      font-size: 14px;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
      margin: 0;
      line-height: 2.5;
      color: #000;
    }
    .settingsub {
      color: #5a5f70;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
      font-size: 12px;
      display: inline-block;
    }
  }
  .settingsCard {
    margin-top: 12px;
    width: 100%;
    height: 80.69px;
  }
  .password-submit {
    padding: 10px 20px;
    font-size: 16px;
    &:disabled {
      background: #d1d6e5;
    }
  }
  .success-div {
    margin-top: 16px;
  }
  .linkText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  @media screen and (min-width: 576px) {
    h2 {
      margin-left: 12px;
      font-size: 16px;
    }
    .fieldContainer {
      width: 100%;
      padding: 86px 40px 124px 40px;
    }
    .notificationContainer {
      width: 100vw;
      padding: 86px 40px 124px 40px;
    }
    .settingsCard {
      margin-top: 28px;
      width: 100%;
      height: 80.69px;
    }
    .subText {
      font-weight: 600;
      font-size: 24px;
    }
    .logoButton {
      width: 235px;
    }
    .instructionText {
      margin-top: 8px;
      color: #5a5f70;
      font-size: 12px;
      margin-left: 24px;
    }
    .fields {
      margin-top: 16px;
      width: 416px;
    }
    .secondField {
      margin-left: 33px;
    }
    .personalInfoDiv {
      margin: 48px 0 14px 0;
    }
    .linkField {
      background: #ffffff;
      height: 48px;
      width: 100%;
      padding: 20px;
      border: 1px solid #dfe6f5;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
    }
    .mediaImage {
      margin-right: 16px;
    }
    .copyclipButton {
      background: #fff;
      border: none;
    }
    .navigateBtn {
      border: none;
      margin: 16px 0;
      background: #fff;
      border-radius: 8px;
      width: 190px;
      height: 52px;
    }
    .navigateBtn:hover {
      font-weight: 600;
      font-size: 16px;
      background: #ecf4f9;
    }
    .mobileNavBar {
      margin: 0;
    }
    .Passwordfields {
      margin-top: 28px;
      width: 604px;
    }
    .submit-button {
      margin-top: 80px;
      width: 240px;
      height: 64px;
    }
    .arrowl {
      margin-left: 28px;
    }
    .Passwordfields {
      margin-top: 18px;
      width: 455px;
    }
    .submit-button {
      margin-top: 70px;
      width: 240px;
      height: 48px;
    }
    .arrowl {
      margin-left: 28px;
    }
    .toggleButton {
      padding: 3px;
      top: 20%;
    }
    .validationImage {
      width: 455px;
      margin-top: 10px;
    }
    .sub {
      font-size: 14px;
    }
    .resetPassText:hover {
      text-decoration: underline;
      cursor: pointer;
      text-decoration-color: #3c498a;
      text-decoration-thickness: 2px;
      cursor: pointer;
    }
    .buttonDiv {
      width: 880px;
    }
    .settingsContainer {
      .settinghead {
        width: 100%;
        font-size: 16px;
      }
      .settingsub {
        width: 100%;
        font-size: 14px;
      }
    }
    .checkboxDiv {
      padding: 15px 24px;
      margin-right: 24px;
      border-right: 1px solid #e6e9f0;
    }
    .switch {
      position: relative;
      display: inline-block;
      border-radius: 40px;
      width: 54px;
      height: 28px;
      background: #dfe6f5;
      transition: 0.4s;

      .inputCheckbox {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    .slider {
      position: absolute;
      border-radius: 40px;
      cursor: pointer;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    .slider:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background: #ffffff;
      transition: 0.4s;
    }
    .inputCheckbox:checked + .slider:before {
      transform: translateX(26px);
    }
    .inputCheckbox:checked + .slider {
      background: #58c070;
    }
    .success-div {
      margin-top: 12px;
      max-width: 454px;
    }
    .linkText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
  }
  @media screen and (max-width: 940px) {
    .secondField {
      margin-left: 0px;
    }
    .fields {
    }
  }

  @media screen and (min-width: 1200px) {
    h2 {
      margin-left: 12px;
      font-size: 20px;
    }
    .sideBar {
      padding-left: 88px;
      width: 351px;
    }
    .sideBarPadding {
      height: 100vh;
      border-left: 1px solid #e6e9f0;
      padding: 116px 63px 63px 40px;
    }
    .fieldContainer,
    .notificationContainer {
      height: 100vh;
      padding: 124px 40px 124px 40px;
    }

    .subText {
      font-weight: 600;
      font-size: 24px;
    }
    .cameraDiv {
      margin-top: 44px;
    }
    .uploadedImg {
      margin-top: 44px;
    }
    .logoButton {
      border: none;
      margin-left: 24px;
      margin-top: 62px;
    }
    .instructionText {
      margin-top: 8px;
      color: #5a5f70;
      font-size: 12px;
      margin-left: 24px;
    }
    .fields {
      margin-top: 16px;
      width: 448px;
    }
    .secondField {
      margin-left: 44px;
    }
    .personalInfoDiv {
      margin: 48px 0 14px 0;
    }
    .linkField {
      background: #ffffff;
      height: 48px;
      width: 100%;
      padding: 20px;
      border: 1px solid #dfe6f5;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
    }
    .mediaImage {
      margin-right: 16px;
    }
    .copyclipButton {
      background: #fff;
      border: none;
    }
    .navigateBtn {
      border: none;
      margin: 16px 0;
      background: #fff;
      border-radius: 8px;
      width: 190px;
      height: 52px;
    }
    .navigateBtn:hover {
      font-weight: 600;
      font-size: 16px;
      background: #ecf4f9;
    }
    .Passwordfields {
      margin-top: 28px;
    }
    .sub {
      font-size: 16px;
    }
    .buttonDiv {
      width: 955px;
    }
    .notificationContainer {
      width: 75vw;
      padding: 86px 40px 124px 40px;
    }
    .success-div {
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 1900px) {
    .sideBar {
      padding-left: 88px;
      width: 24%;
    }
    .sideBarPadding {
      padding: 116px 63px 63px 120px;
    }
    .fields {
      margin-top: 16px;
      width: 604px;
      .input-field {
        padding: 27px 25px 10px 20px;
      }
      .input-container {
        label {
          left: 20px;
          top: 5px;
        }
      }
    }
    .secondField {
      margin-left: 58px;
    }
    .fieldContainer,
    .notificationContainer {
      padding: 124px 72px;
    }
    .linkField {
      height: 64px;
    }
    .Passwordfields {
      margin-top: 28px;
      width: 604px;
    }
    .submit-button {
      margin-top: 80px;
      height: 64px;
    }
    .arrowl {
      margin-left: 28px;
    }
    .toggleButton {
      padding: 6px;
      top: 32%;
    }
    .validationImage {
      margin-top: 10px;
    }
    .buttonDiv {
      width: 99%;
    }
    .success-div {
      margin-top: 16px;
      max-width: 602px;
    }
  }
`;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../../store';
import SelectField from '../../../../components/SelectField';
import { StyledDeleteModal } from '../../../Admin/elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import {
  onDeleteProductCategory,
  selectAllProductCategories,
} from '../../../../store/productCategories/productCategoriesSlice';

import warning from '../../../../assets/logo/warning.svg';

const ValidationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    label: Yup.string().required('Please select a destination.'),
    value: Yup.string().required('Please select a destination.'),
  }),
});

const DeleteCategory = ({
  showModal,
  setShowModal,
  category,
  setSuccessMessage,
}: {
  showModal: boolean;
  setShowModal: Function;
  category: any;
  setSuccessMessage: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();

  const customProductCategories = useSelector(selectAllProductCategories);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('from', { label: category.name, value: category.uuid });
  }, []);

  const onDelete = (data: any) => {
    data.agencyUUID = uuid;
    data.destinationUUID = data.destination.value;
    data.action = 'deleteCustom';
    dispatch(
      onDeleteProductCategory({
        uuid: category.uuid,
        data,
        successCB: () => {
          setShowModal(false);
          setSuccessMessage(`${category.name} has been deleted.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal(false)}
      />
      <h2>Delete policy Category?</h2>
      <div className='sub-heading'>
        There are some policies associated to this policy type. Please move
        these policy types to a different category.
      </div>
      <form onSubmit={handleSubmit(onDelete)}>
        <div className='mb-3'>
          <SelectField
            name='from'
            placeholder='From Policy Category'
            options={customProductCategories.map((item) => {
              return {
                label: item.name,
                value: item.uuid,
              };
            })}
            control={control}
            isDisabled={true}
          />
        </div>
        <SelectField
          name='destination'
          placeholder='To Policy Category'
          options={customProductCategories
            .filter(
              (item) =>
                item.uuid !== category.uuid &&
                item.type === 'CUSTOM_PRODUCT_CATEGORY'
            )
            .map((item) => {
              return {
                label: item.name,
                value: item.uuid,
              };
            })}
          control={control}
        />
        {errors.destination ? (
          <div className='errorVal'>
            <img src={warning} alt='warning' className='warning' />
            {errors.destination?.value?.message}
          </div>
        ) : null}
        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowModal();
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='WarningButton d-flex align-items-center justify-content-between'>
            Move and Delete
            <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledDeleteModal>
  );
};

export default DeleteCategory;

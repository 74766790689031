import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Moment from 'react-moment';
import { AppDispatch } from '../../../store';
import {
  getPolicies,
  onDeletePolicy,
  PoliciesSliceState,
  selectAllPolicies,
} from '../../../store/policies/policiesSlice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import {
  getCount,
  selectCount,
  updateCount,
} from '../../../store/book/bookSlice';
import MobileTab from '../../../components/MobileTab';
import Link from '../../../components/Link';
import PolicyMobileCard from './components/PolicyMobileCard';
import PolicyModal from './components/AddPolicyModal';
import Table from '../../../components/Table';
import PaginationComponent from '../../../components/PaginationComponent';
import EmptyResults from '../components/Empty';
import { IPolicy } from '../../../interfaces/Policy';
import { StyledTab } from '../../../styles/StyledTab';
import { FilterContainer, MyBookDiv } from '../elements/styles';
import plus from '../../../assets/logo/plus.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import search from '../../../assets/logo/search.svg';
import filter from '../../../assets/logo/filter.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import close from '../../../assets/logo/cross.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import EditPolicyDetails from '../../PolicyDetails/EditPolicyDetails';
import PolicyFilter from './components/FilterDrawer';
import { IOption } from '../../../interfaces/Opportunity';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import blackplus from '../../../assets/logo/blackplus.svg';
import moment from 'moment';

const Policies = () => {
  const tabRef = useRef(null);
  const searchRef = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const policies = useSelector(selectAllPolicies);
  const count = useSelector(selectCount);
  const loadingStatus = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies?.status
  );
  const policyCount = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies?.count
  );
  const [UUID, setUUID] = useState('');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPolicy, setShowPolicy] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [showFilter, setShowFilter] = useState('closed');
  const [producer, setProducer] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [selectedPolicySoldThrough, setSelectedPolicySoldThrough] =
    useState<any>([]);
  const [carrier, setCarrier] = useState('');
  const [account, setAccount] = useState('');
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [selectedProducts, setProducts] = useState<IOption[]>([]);

  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getPolicies({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          producerUUID: producer,
          productCategoryUUID: [],
          hasMGA: selectedPolicySoldThrough?.value
            ? selectedPolicySoldThrough?.value === 'MGA'
              ? true
              : false
            : undefined,
          carrierUUID: carrierType === 'CARRIER' ? carrier : '',
          accountUUID: account,
          customCarrierUUID: carrierType === 'CUSTOM_CARRIER' ? carrier : '',
          productUUID: selectedProducts
            ?.filter((item) => item.type === 'PRODUCT')
            .map((item) => item.value),
          customProductUUID: selectedProducts
            ?.filter((item) => item.type === 'CUSTOM_PRODUCT')
            .map((item) => item.value),
          successCB: (data: any) => {
            dispatch(updateCount({ type: 'policiesCount', count: data.count }));
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    sortBy,
    orderBy,
    debouncedSearchTerm,
    page,
    limit,
    selectedProducts,
    producer,
    carrier,
    selectedPolicySoldThrough,
    account,
  ]);

  useOutsideClick(tabRef, setShowDropdown, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [showFilter, setShowFilter] = useState('closed');
  // const [producer, setProducer] = useState('');
  // const [producerSearch, setProducerSearch] = useState('');
  // const [selectedPolicySoldThrough, setSelectedPolicySoldThrough] =
  //   useState<any>([]);
  // const [carrier, setCarrier] = useState('');
  // const [account, setAccount] = useState('');
  // const [carrierSearchText, setCarrierSearchText] = useState('');
  // const [accountSearch, setAccountSearch] = useState('');
  // const [selectedProducts, setProducts] = useState<IOption[]>([]);
  const [carrierType, setCarrierType] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeletePolicy({
        uuid,
        successCB: () => {
          setPage(0);
          dispatch(getCount());
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <MyBookDiv>
      {showSuccess && (
        <ErrorCard
          message={`<b>${name}</b> has been <b> deleted</b> `}
          title={'Success deleting policy.'}
        />
      )}
      <button
        onClick={() => setShowPolicy('open')}
        className='addButton d-sm-none'
      >
        <img src={plus} alt='add' />
      </button>
      <div className='d-sm-none mb-4'>
        <StyledTab ref={tabRef}>
          <Link
            to='/mybook/policies'
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            Policies<span className='count'>{count.policies}</span>
            <img src={dropdown} alt='' />
          </Link>{' '}
          {showDropdown && <MobileTab />}
        </StyledTab>
      </div>
      <div className='d-flex justify-content-between'>
        <button
          className='addButton d-none d-sm-block'
          onClick={() => setShowPolicy('open')}
          disabled={showPolicy !== 'closed'}
        >
          Add policy
        </button>
        <div className='book-filter d-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setPage(0);
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {(producer ? 1 : 0) +
              (selectedPolicySoldThrough?.value ? 1 : 0) +
              selectedProducts.length +
              (carrier ? 1 : 0) +
              (account ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(producer ? 1 : 0) +
                  (selectedPolicySoldThrough?.value ? 1 : 0) +
                  selectedProducts.length +
                  (carrier ? 1 : 0) +
                  (account ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {(producer ? 1 : 0) +
              (selectedPolicySoldThrough?.value ? 1 : 0) +
              selectedProducts.length +
              (carrier ? 1 : 0) +
              (account ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {(producer ? 1 : 0) +
                  (selectedPolicySoldThrough?.value ? 1 : 0) +
                  selectedProducts.length +
                  (carrier ? 1 : 0) +
                  (account ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && policies.length > 0 ? (
            <>
              {policies?.map((item: IPolicy) => (
                <PolicyMobileCard
                  policy={item}
                  key={item?.uuid}
                  setShowDeleteModal={setShowDeleteModal}
                  setUUID={setUUID}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={policyCount}
              />
            </>
          ) : (
            <EmptyResults name='policy' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            loadingStatus={loadingStatus}
            count={policyCount}
            className='mybook-table'
            name='policy'
            setScroll={() => {
              setScroll(!scroll);
            }}
            columns={[
              {
                accessor: 'name',
                resizable: true,
                sortable: true,
                minWidth: 223,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Account name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center'>
                    <b
                      onClick={() => {
                        navigate(`/policy/${row.original.uuid}`);
                      }}
                    >
                      {row.original.Accounts.name
                        ? row.original.Accounts.name
                        : '--'}
                    </b>
                  </div>
                ),
              },
              {
                accessor: 'type',
                Header: 'Type',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.Product
                      ? row.original.Product.name
                      : row.original.CustomProduct
                      ? row.original.CustomProduct?.name
                      : '--'}
                  </>
                ),
              },
              {
                accessor: 'carrier',
                Header: 'Carrier',
                minWidth: 200,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.Issuer
                      ? row.original.Issuer.Carrier.name
                      : row.original.CustomCarrier
                      ? row.original.CustomCarrier.name
                      : '--'}
                  </>
                ),
              },
              {
                accessor: 'effectiveDate',
                resizable: true,
                sortable: true,
                minWidth: 140,
                Header: () => (
                  <div
                    onClick={() => handleSort('effectiveDate')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Effective date</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'effectiveDate'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <Moment format='MM/DD/YYYY'>
                    {row.original.effectiveDate}
                  </Moment>
                ),
              },
              {
                accessor: 'quoteWonDate',
                resizable: true,
                sortable: true,
                minWidth: 140,
                Header: () => (
                  <div
                    onClick={() => handleSort('quoteWonDate')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Won date</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'quoteWonDate'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <>
                    {row.original.quoteWonDate
                      ? moment(row.original.quoteWonDate).format('MM/DD/YYYY')
                      : '--'}
                  </>
                ),
              },
              {
                accessor: 'producer',
                Header: 'Producer',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div className='toolDiv info d-flex align-items-center h-100'>
                    {row.original.Agents.User.avatar ? (
                      <img
                        src={row.original.Agents.User.avatar}
                        alt=''
                        className='user-pic'
                      />
                    ) : (
                      <div className='user-pic'>
                        {row.original.Agents.User.firstName
                          ?.substring(0, 1)
                          .toUpperCase()}
                        {row.original.Agents.User.lastName
                          ?.substring(0, 1)
                          .toUpperCase()}
                      </div>
                    )}
                    <span className='tooltiptext tooltip-producer'>
                      <span className='tooltipInnertext'>
                        {row.original.Agents?.User?.firstName +
                          ' ' +
                          row.original.Agents?.User?.lastName}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'premium',
                Header: 'Premium',
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <b>
                    $
                    {row.original.premium
                      ? row.original.premium.toLocaleString('en-US')
                      : 0}
                  </b>
                ),
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                width: window.innerWidth > 1900 ? 245 : 206,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <div
                      className='actionButton'
                      onClick={() => {
                        navigate(`/policy/${row.original.uuid}`);
                      }}
                    >
                      View details
                    </div>
                    {/* TODO : Implement dropdwon and edit modal. */}
                    <ActionsDropdown
                      uuid={row.original.uuid}
                      scroll={scroll}
                      setUUID={setUUID}
                      setShowEditModal={setShowEditModal}
                      setShowDeleteModal={setShowDeleteModal}
                      setName={setName}
                      name={
                        row.original.Accounts.name
                          ? row.original.Accounts.name
                          : '--'
                      }
                    />
                  </div>
                ),
              },
            ]}
            data={policies}
          />
        </div>
      </div>
      {showPolicy !== 'closed' && (
        <PolicyModal show={showPolicy} closeModal={setShowPolicy} />
      )}
      {showEditModal !== 'closed' && (
        <EditPolicyDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          uuid={UUID}
          isListing={true}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='policy'
          name={name}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <PolicyFilter
            show={showFilter}
            closeModal={setShowFilter}
            setProducts={setProducts}
            selectedPolicySoldThrough={selectedPolicySoldThrough}
            setSelectedPolicySoldThrough={setSelectedPolicySoldThrough}
            selectedProducts={selectedProducts}
            producer={producer}
            setProducer={setProducer}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            setCarrier={setCarrier}
            carrier={carrier}
            account={account}
            setAccount={setAccount}
            carrierSearchText={carrierSearchText}
            setCarrierSearchText={setCarrierSearchText}
            accountSearch={accountSearch}
            setAccountSearch={setAccountSearch}
            setType={setCarrierType}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Policies;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  setShowDeleteModal,
  setName,
  scroll,
  name,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  setShowDeleteModal: Function;
  setName: Function;
  scroll: boolean;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef<HTMLButtonElement>(null);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='action-dropContainer position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 130,
          }}
        >
          <div className='dropdown-div'>
            <div
              className='edit-div'
              onClick={() => {
                setShowEditModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
              }}
              tabIndex={0}
            >
              Edit <img src={edit} alt='bin' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='edit-div'
              style={{ paddingTop: '10px' }}
              onClick={() => navigate(`/policy/${uuid}/activities`)}
              tabIndex={1}
            >
              Add activity <img src={blackplus} alt='plusIcon' />
            </div>
            <div className='borderDiv'></div>
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setUUID(uuid);
                setShowActionsDropdown(false);
                setName(name);
              }}
              tabIndex={2}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

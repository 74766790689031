import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { fetchStatus, fetchUserRoles } from '../../../../api/agents';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import Skeleton from 'react-loading-skeleton';

const UserFilter = ({
  show,
  closeModal,
  userRole,
  setUserRole,
  setUserStatus,
  userStatus,
}: {
  show: string;
  closeModal: Function;
  userRole: any;
  setUserRole: Function;
  setUserStatus: Function;
  userStatus: any;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [role, setRole] = useState([]);
  const [agentStatus, setStatus] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  useEffect(() => {
    setLoadingStatus('loading');
    fetchStatus((data: any) => {
      setStatus(data);
      setLoadingStatus('succeeded');
    });
    fetchUserRoles((data: any) => {
      setRole(data);
      setLoadingStatus('succeeded');
    });
  }, []);

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>User Role</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={role?.map((item: any) => {
                return {
                  label:
                    item.name?.charAt(0).toUpperCase() +
                    item.name?.split('_').join(' ').toLowerCase().slice(1),
                  value: item?.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setUserRole(value);
              }}
              value={userRole}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>User Status</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={agentStatus.map((item: any) => {
                return {
                  label:
                    item.name?.charAt(0).toUpperCase() +
                    item.name?.toLowerCase().slice(1),
                  value: item?.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setUserStatus(value);
              }}
              value={userStatus}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setUserStatus('');
                          setUserRole('');
                        }}
                        disabled={
                          (userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) === 0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)} filters
                    added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setUserStatus('');
                      setUserRole('');
                    }}
                    disabled={
                      (userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) === 0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default UserFilter;

import styled from '@emotion/styled';

export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 15px;

  .modal-container {
    width: auto;
    margin: auto;
    background: #fff;
    padding: 32px;
    box-shadow: 0px 4px 16px rgba(35, 42, 64, 0.2);
    border-radius: 16px;
    position: relative;
    max-width: 100%;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #262758;
    margin-bottom: 12px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262758;
  }

  button {
    border: none;
  }

  .crossbutton {
    border: none;
    background: none;
    position: absolute;
    top: 5px;
    right: 2px;
    &:hover {
      filter: brightness(0%);
    }
  }

  .button-container {
    margin-top: 40px;
    justify-content: right;
  }

  .qualify-img {
    margin-bottom: 32px;
    width: 100%;
  }

  @media screen and (min-width: 576px) {
    .modal-container {
      width: 320px;
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-container {
      width: 456px;
      padding: 48px;
    }

    .qualify-img {
      margin-bottom: 48px;
    }

    .crossbutton {
      top: 12px;
      right: 12px;
    }
  }
`;

export const ProceedButton = styled.button`
  width: 132px;
  height: 48px;
  background: #262758;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #f5f7fc;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  img {
    margin-left: auto;
  }
`;

export const CancelButton = styled.button`
  width: 108px;
  height: 48px;
  background: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262758;
  border-radius: 8px;
  margin-right: 16px;

  &:hover {
    background: #e6e9f0;
  }
`;

import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import Skeleton from 'react-loading-skeleton';
import { IProductCategory } from '../../../../interfaces/ProductCategory';
import SearchDropdown from '../../../../components/SearchDropdown';
import { fetchJurisdiction } from '../../../../api/jurisdiction';
import { IJurisdiction } from '../../../../interfaces/Jurisdiction';
import { fetchProducts } from '../../../../api/product';
import { useParams } from 'react-router';

const SystemCarrierFilter = ({
  show,
  closeModal,
  setStatus,
  status,
  setSelectedPolicyTypes,
  selectedPolicyTypes,
  batchFilter,
  licenseSearch,
  setLicenseSearch,
  jurisdictionsUUID,
  setJurisdictionsUUID,
}: {
  show: string;
  closeModal: Function;
  setStatus?: Function;
  status?: any;
  setSelectedPolicyTypes: Function;
  selectedPolicyTypes: any;
  batchFilter: boolean;
  licenseSearch: string;
  setLicenseSearch: Function;
  jurisdictionsUUID: string;
  setJurisdictionsUUID: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const statusOption = [
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' },
  ];

  const [productCategory, setProductCategory] = useState<IProductCategory[]>(
    []
  );
  const { uuid } = useParams();

  useEffect(() => {
    setLoadingStatus('loading');
    fetchProducts(
      (data: IProductCategory[]) => {
        setProductCategory(data);
        setLoadingStatus('succeeded');
      },
      'name',
      'asc',
      '',
      uuid
    );
  }, []);

  // useEffect(() => {
  //   filterFunction(); // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedPolicyTypes, status, jurisdictionsUUID]);

  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (licenseSearch) {
        fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
          setLicensedState(data);
        });
      } else {
        setLicensedState([]);
      }
    }
  };
  const resetJurisdiction = (value: string) => {
    setLicensedState([]);
    setJurisdictionsUUID('');
  };
  const filterContent = () => {
    return (
      <>
        {!batchFilter ? (
          <>
            <div>
              <h6 className='head text-uppercase'>Status</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <FilterSelectField
                  options={statusOption}
                  placeholder='Select'
                  onChange={(value: any) => {
                    if (setStatus) setStatus(value);
                  }}
                  value={status}
                />
              </div>
            )}
            <hr className='filter-hr' />
          </>
        ) : null}

        <div>
          <h6 className='head text-uppercase'>Policies Offered</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={productCategory?.map((item: any) => {
                return {
                  label: item.name?.split('_')?.join(' ')?.toLowerCase(),
                  value: item.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setSelectedPolicyTypes(value);
              }}
              isMulti={true}
              value={selectedPolicyTypes}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Licensed state</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <SearchDropdown
              name='driverLicensedState'
              placeholder='Select'
              isEdit={true}
              values={licenseSearch}
              searchValue={licensedState}
              setSearch={setLicenseSearch}
              status={loadingStatus}
              className={`${licenseSearch && 'filled'}`}
              loadFunction={loadLicensedState}
              setUUID={resetJurisdiction}
              content={licensedState?.map((item) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setLicenseSearch(item?.name);
                    setJurisdictionsUUID(item?.uuid);
                  }}
                >
                  <div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
              ))}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(status?.value ? 1 : 0) +
                          selectedPolicyTypes.length +
                          (jurisdictionsUUID ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          if (setStatus) setStatus('');
                          setSelectedPolicyTypes([]);
                          setJurisdictionsUUID('');
                          setLicenseSearch('');
                        }}
                        disabled={
                          (status?.value ? 1 : 0) +
                            selectedPolicyTypes.length +
                            (jurisdictionsUUID ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(status?.value ? 1 : 0) +
                      selectedPolicyTypes.length +
                      (jurisdictionsUUID ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      if (setStatus) setStatus('');
                      setSelectedPolicyTypes([]);
                      setJurisdictionsUUID('');
                      setLicenseSearch('');
                    }}
                    disabled={
                      (status?.value ? 1 : 0) +
                        selectedPolicyTypes.length +
                        (jurisdictionsUUID ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default SystemCarrierFilter;

import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Link from '../../components/Link';
import TopBar from '../../components/TopBar';
import { selectAgency } from '../../store/agents/agentsSlice';
import { StyledTab } from '../../styles/StyledTab';
import LocalStorageService from '../../utils/LocalStorageService';
import { LeadSettingStyles } from './styles';
import { SettingsDiv } from '../AgencySettings/styles';
import LeadSources from './components/LeadSources';
import LeadLostReasons from './components/LeadLostReasons';
import XDateReasons from './components/XDateReasons';
import back from '../../assets/logo/blackRightArrow.svg';
import dropdown from '../../assets/logo/dropdown.svg';

const LeadSettings = () => {
  const navigate = useNavigate();
  const agencyDetails = useSelector(selectAgency);

  const user = LocalStorageService.getUser();

  const { uuid } = useParams();

  const [showDropdown, setShowDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const linkRef = useRef(null);
  useOutsideClick(linkRef, setShowDropdown);

  return (
    <SettingsDiv>
      <div className='content-div inner-content-div'>
        <TopBar backgroundColor={'#fff'}>
          {user?.UserRole.name === 'ADMIN' ? (
            <div className='breadcrumb-div'>
              <span
                onClick={() => navigate('/admin/agency')}
                className='breadcrumb-item'
              >
                Manage Agency /
              </span>
              <span
                onClick={() => navigate(`/admin/agency/${uuid}/`)}
                className='breadcrumb-item'
              >
                {agencyDetails?.name} Team /
              </span>
              Lead Settings
            </div>
          ) : (
            <div
              className='d-flex'
              onClick={() => navigate('/settings')}
              role='button'
            >
              <img src={back} alt='' className='back-button-icon leftarrow' />

              <h2>Lead Settings</h2>
            </div>
          )}
        </TopBar>

        <LeadSettingStyles>
          <StyledTab className='d-sm-none'>
            <div className='position-relative' ref={linkRef}>
              <Link
                to={
                  user.UserRole.name === 'ADMIN'
                    ? pageData[activeTab].adminlocation.replace(
                        '${uuid}',
                        `${uuid}`
                      )
                    : pageData[activeTab].palocation
                }
                onClick={(e) => {
                  setShowDropdown(true);
                  e.preventDefault();
                }}
              >
                {pageData[activeTab].name}
                <img src={dropdown} alt='' />
              </Link>
              {showDropdown && (
                <div className='mobile-tab'>
                  {pageData.map((item, index) => (
                    <Link
                      key={item.name}
                      to={
                        user.UserRole.name === 'ADMIN'
                          ? item.adminlocation.replace('${uuid}', `${uuid}`)
                          : item.palocation
                      }
                      onClick={() => {
                        setActiveTab(index);
                        setShowDropdown(false);
                      }}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </StyledTab>
          <StyledTab className='d-none d-sm-block'>
            <Link
              to={
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}/leads/sources`
                  : '/settings/leads/sources'
              }
            >
              Lead Sources
            </Link>
            <Link
              to={
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}/leads/lost-reasons`
                  : `/settings/leads/lost-reasons`
              }
            >
              Lead Lost Reasons
            </Link>
            <Link
              to={
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}/leads/x-date-reasons`
                  : `/settings/leads/x-date-reasons`
              }
            >
              X-Date Reasons
            </Link>
          </StyledTab>

          <Routes>
            <Route path='/sources' element={<LeadSources />} />
            <Route path='/lost-reasons' element={<LeadLostReasons />} />
            <Route path='/x-date-reasons' element={<XDateReasons />} />
            <Route
              path='*'
              element={
                <Navigate
                  to={
                    user.UserRole.name === 'ADMIN'
                      ? `/admin/agency/${uuid}/leads/sources`
                      : '/settings/leads/sources'
                  }
                  replace
                />
              }
            />
          </Routes>
        </LeadSettingStyles>
      </div>
    </SettingsDiv>
  );
};

export default LeadSettings;

const pageData = [
  {
    name: 'Lead Sources',
    palocation: '/settings/leads/sources',
    adminlocation: '/admin/agency/${uuid}/leads/sources',
  },
  {
    name: 'Lead Lost Reasons',
    palocation: '/settings/leads/lost-reasons',
    adminlocation: '/admin/agency/${uuid}/leads/lost-reasons',
  },
  {
    name: 'X-Date Reasons',
    palocation: '/settings/leads/x-date-reasons',
    adminlocation: '/admin/agency/${uuid}/leads/x-date-reasons',
  },
];

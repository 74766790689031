import React from 'react';
import dropdown from '../../assets/logo/dropdown.svg';

const RotatingDropDownIcon = ({ rotate }: { rotate: boolean }) => {
  return (
    <img
      src={dropdown}
      alt='dropdown'
      style={{ transform: rotate ? 'rotate(180deg)' : '' }}
    />
  );
};

export default RotatingDropDownIcon;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../store';
import { getUser } from '../../store/user/userSlice';
import { login } from '../../api';
import InputField from '../../components/InputField';
import LocalStorageService from '../../utils/LocalStorageService';
import { IUser } from '../../interfaces/User';
import { LoginDiv } from '../../styles/LoginFlow';
import logo from '../../assets/logo/logo.svg';
import arrow from '../../assets/logo/right-arrow.svg';
import eye from '../../assets/logo/eye.svg';
import warning from '../../assets/logo/warning.svg';
import brandImg from '../../assets/illustrations/Welcome.svg';
import closedeye from '../../assets/logo/closedeye.svg';

const SignupSchema = Yup.object().shape({
  password: Yup.string().required('Please enter your password'),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email ')
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Email is not valid'),
});

type FormValues = {
  email: string;
  password: string;
  remember: string;
};

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [Isclicked, setIsclicked] = useState(false);
  const [errorMessage, setError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(SignupSchema),
  });

  const watchAllFields = watch();

  const onSubmit: SubmitHandler<FormValues> = (values: any) => {
    login(
      values.email,
      values.password,
      (res: { access_token: string; refresh_token: string }) => {
        const { access_token, refresh_token } = res;
        LocalStorageService.setToken(access_token, refresh_token);
        if (values.remember) {
          LocalStorageService.rememberUser(values.remember);
        }
        dispatch(
          getUser({
            successCB: (user: IUser) => {
              LocalStorageService.setUser(JSON.stringify(user));
              if (user.UserRole.name === 'ADMIN') {
                navigate('/admin');
              } else {
                navigate('/dashboard');
              }
            },
          })
        );
      },
      (data: any) => {
        setError(
          data?.status === 401 || data?.status === 400
            ? 'Invalid email or password. Please try again'
            : data?.data?.message
        );
      }
    );
  };
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginDiv>
      <img className='logo-svg' src={logo} alt='logo' />

      <div className='content-div'>
        <div className='center-div'>
          <h2 className='welcome-heading'>Welcome to HobNob!</h2>
          <p className='sub'>Login to your account</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className='fields'
              onClick={() => setIsclicked(true)}
              onBlur={() => setIsclicked(false)}
            >
              <InputField
                name='email'
                type='email'
                placeholder='Email'
                register={register}
                className={`${
                  (errors.email || errors.password || errorMessage) &&
                  'error-field'
                } ${watchAllFields.email ? 'filled' : 'input-label'}`}
              />
            </div>

            <div
              className='fields'
              onClick={() => setIsclicked(true)}
              onBlur={() => setIsclicked(false)}
            >
              <div className='password-div'>
                <InputField
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  register={register}
                  className={`${
                    (errors.email || errors.password || errorMessage) &&
                    'error-field'
                  } ${watchAllFields.password ? 'filled' : 'input-label'}`}
                />
                <div className='toggleButton' onClick={toggleVisibility}>
                  {!showPassword ? (
                    <>
                      <img className='eye' alt='eye-icon' src={eye} />
                      Show
                    </>
                  ) : (
                    <>
                      <img
                        className='closedeye'
                        alt='closedeye-icon'
                        src={closedeye}
                      />
                      Hide
                    </>
                  )}
                </div>
              </div>
              {(errors.email || errors.password || errorMessage) && (
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {errors.email || errors.password
                    ? errors.email && errors.password
                      ? 'Please enter your email and password.'
                      : errors.email?.message || errors.password?.message
                    : errorMessage}
                </div>
              )}
            </div>
            <div className='fields'>
              <div className='d-flex align-items-center'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  {...register('remember')}
                />
                <label className='subtext' htmlFor='remember'>
                  Remember Me
                </label>
              </div>
            </div>
            <button type='submit' className='primaryButton submit-button'>
              Log In
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </form>
          <div>
            <button
              className='forgotButton secondaryButton d-none d-sm-block'
              onClick={() => navigate('/reset-password')}
            >
              Reset Password
            </button>
          </div>
        </div>

        <button
          className={`${
            Isclicked
              ? 'd-none'
              : 'forgotButton secondaryButton d-block d-sm-none'
          }`}
          onClick={() => navigate('/reset-password')}
        >
          Reset Password
        </button>
        <div className='d-none d-sm-block'>
          <img className='brand-img' src={brandImg} alt='welcome' />
        </div>
      </div>
    </LoginDiv>
  );
};

export default Login;

import styled from '@emotion/styled';

type DivProps = {
  toolbarDisplay: string;
};

export const EditorContainer = styled.div<DivProps>`
  height: 100%;
  color: #191919;
  .toolbar-button-container {
    margin-bottom: 0;
  }
  .rdw-image-wrapper {
    margin-bottom: 0;
  }
  .toolbar-button-container > div,
  .image-upload {
    padding: 3px 10px 5px;
    border-radius: 8px;
    margin: 0 1px;
    background: transparent;
    height: 32px;
    width: 32px;
    border: none;
    &[aria-selected='true'] {
      background: #3c498a;
      box-shadow: none;
      img {
        filter: invert(1);
      }
    }
  }
  .demo-editor {
    padding: 12px;
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
  }
  .demo-wrapper {
    height: 100%;
    border: 1px solid #cad9f3;
    border-radius: 8px;
  }
  .DraftEditor-editorContainer {
    z-index: 0;
  }
  .toolbar-container {
    background: #ecf4f9;
    border-radius: 6px;
    padding: 6px 12px;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }

  .quill {
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #cad9f3;
    background: #fff;

    .ql-toolbar {
      display: ${(props) => props.toolbarDisplay};
    }

    :focus,
    :focus-within {
      border: 1px solid #3c498a;
      .ql-toolbar {
        display: block;
      }
    }
  }

  .ql-toolbar {
    background: #ecf4f9;
    border-radius: 8px;
    border: 0;
  }

  .ql-container {
    border: 0;
  }

  .ql-editor.ql-blank::before {
    color: #8990a3;
    font-style: normal;
    font-family: 'NoirNo1';
  }

  .ql-editor {
    padding: 14px 12px;
  }

  .ql-snow.ql-toolbar {
    padding: 6px 8px;
    button {
      width: 32px;
      height: 32px;
      padding: 6px;
    }
    .ql-active {
      background: #3c498a;
      color: #fff;
      border-radius: 8px;
      svg {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
          hue-rotate(246deg) brightness(109%) contrast(101%);
      }
    }
  }
`;

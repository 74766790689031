import { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { register as onRegister } from '../../api';
import { getValidateUser } from '../../api/user';
import PasswordValidation from '../../components/PasswordValidation';
import SuccessCard from '../../components/SuccessCard';
import InputField from '../../components/InputField';
import PhoneNumberInput from '../../components/PhoneNumberFormat';
import PasswordField from '../../components/PasswordField';
import { LoginDiv } from '../../styles/LoginFlow';
import { ErrorDiv } from './elements/styles';
import logo from '../../assets/logo/logo.svg';
import arrow from '../../assets/logo/right-arrow.svg';
import eye from '../../assets/logo/eye.svg';
import warning from '../../assets/logo/warning.svg';
import brandImg from '../../assets/illustrations/Welcome.svg';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;

const SignupSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Entered phone number is not valid.')
    .required('Please enter your phone number'),
  email: Yup.string()
    .email('Entered email is not valid.')
    .required('Please enter your email '),
});

type FormValues = {
  password: string;
  phoneNumber: string;
  email: string;
  code: string | null;
};

function Registration() {
  const [password, setPassword] = useState('');
  const [isPasswordClick, setIsPasswordClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState('');
  const [isFilled, setFilled] = useState(false);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      email: '',
      phoneNumber: '',
      code: searchParams.get('code'),
    },
  });

  useEffect(() => {
    let code: any = searchParams.get('code');
    getValidateUser(
      code,
      (userData: any) => {
        setValue('email', userData.email);
        setValue('phoneNumber', userData.phoneNumber);
      },
      (msg: string) => {
        setError(msg);
        navigate('/error/link-expired');
      }
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const toggleVisibility = () => {
    setIsPasswordClick(true);
    setShowPassword(!showPassword);
  };

  const checks = [
    {
      check: (val: string) => val.length >= 8,
      message: 'atleast 8 characters',
    },
    {
      check: (val: string) => /(?=.*[A-Z])/.test(val),
      message: 'atleast one uppercase letter',
    },
    {
      check: (val: string) => /(?=.*[a-z])/.test(val),
      message: 'atleast one lowercase letter',
    },
    {
      check: (val: string) => /(?=.*\d)/.test(val),
      message: 'atleast one number',
    },
    {
      check: (val: string) => /(?=.*[@$!%*?&#])/.test(val),
      message: 'atleast one special character',
    },
  ];

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (
      !checks.every((val: { check: Function; message: string }) =>
        val.check(password)
      )
    ) {
      setIsPasswordClick(true);
    } else {
      values.password = password;
      onRegister(
        values,
        () => navigate('/'),
        (msg: string) => setError(msg)
      );
    }
  };
  return (
    <LoginDiv>
      <img className='logo-svg' src={logo} alt='logo' />
      <div className='content-div'>
        <div className='center-div'>
          <div className={isPasswordClick ? 'd-none d-sm-block' : ''}>
            <h2 className='welcome-heading'>Welcome to HobNob!</h2>
            <p className='sub'>
              After completing the registration, you can log in to our system.
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='fields'>
              <InputField
                name='email'
                type='email'
                placeholder='Email'
                className='prefilled'
                disabled
                register={register}
              />
            </div>
            <div className='fields'>
              <PhoneNumberInput
                name='phoneNumber'
                type='tel'
                placeholder='Phone'
                control={control}
                className={`${errors.phoneNumber && 'error-field'} prefilled`}
              />
              {errors.phoneNumber ? (
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {errors.phoneNumber?.message}
                </div>
              ) : null}
            </div>

            <div className='fields'>
              <div className='password-div'>
                <PasswordField
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  passwordValidationProps={{
                    validationRequired: true,
                    value: password,
                    onPasswordChange: passwordChange,
                    onFocus: () => setIsPasswordClick(true),
                  }}
                  isFilled={isFilled}
                  setFilled={setFilled}
                  placeholder='Create Password'
                />
                {isPasswordClick && (
                  <div
                    className='toggleButton d-flex align-items-center'
                    onClick={toggleVisibility}
                  >
                    <img className='eye' alt='eye-icon' src={eye} />
                    Show
                  </div>
                )}
              </div>
              {!errorMessage &&
              checks.every((val: { check: Function; message: string }) =>
                val.check(password)
              ) ? (
                <div className='success-div'>
                  <SuccessCard
                    title={'Success'}
                    message={'Your password is secure and you are all set!'}
                    hideSuccess={true}
                  />
                </div>
              ) : isPasswordClick ? (
                <PasswordValidation checks={checks} password={password} />
              ) : null}

              {errorMessage && (
                <ErrorDiv>
                  <div className='errorVal fieldError'>
                    <img src={warning} alt='warning' className='warning' />
                    <div>{errorMessage}</div>
                  </div>
                </ErrorDiv>
              )}
            </div>
            <button
              type='submit'
              className='primaryButton submit-button'
              disabled={
                !checks.every((val: { check: Function; message: string }) =>
                  val.check(password)
                )
              }
            >
              Sign Up
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </form>
          <div className='terms'>
            By creating an account you agree to our{' '}
            <span
              className='terms-click'
              onClick={() =>
                window.open(
                  '/global-terms-condition/terms-and-conditions',
                  '_blank'
                )
              }
            >
              Terms
            </span>{' '}
            and have read and acknowledged the &nbsp;
            <span
              className='terms-click'
              onClick={() =>
                window.open(
                  `/global-terms-condition/global-privacy-statement`,
                  '_blank'
                )
              }
            >
              Global privacy statement
            </span>
            .
          </div>
        </div>
        <div className='d-none d-sm-block'>
          <img className='brand-img' src={brandImg} alt='welcome' />
        </div>
      </div>
    </LoginDiv>
  );
}
export default Registration;

import axios from './axios';

const fetchCarriers = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  minimal?: string,
  successCB?: Function,
  lead?: boolean,
  limit?: number,
  offset?: number,
  opportunityUUID?: string,
  agencyUUID?: string,
  type?: string,
  isActive?: boolean,
  customProductUUIDs?: string,
  jurisdictionUUIDs?: string,
  productUUIDs?: string
) => {
  return axios
    .get('/carrier', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        minimal,
        lead,
        limit,
        offset,
        opportunityUUID,
        agencyUUID,
        type,
        isActive,
        customProductUUIDs,
        jurisdictionUUIDs,
        productUUIDs,
      },
    })
    .then((res) => {
      if (successCB) successCB(res.data);
      return res;
    });
};

const fetchCarrier = (uuid: string, successCB: Function, type?: string) => {
  return axios.get(`/carrier/${uuid}`, { params: { type } }).then((res) => {
    successCB(res.data);
    return res;
  });
};

const updateCarrierData = (uuid: string, data: any) => {
  return axios.patch(`/carrier/${uuid}`, data).then((res) => {
    return res;
  });
};

const UpdateCarrier = (
  carrierUUID: string,
  agencyUUID: string,
  status: boolean
) => {
  return axios
    .patch(`/agency/${agencyUUID}`, {
      carrierUUID,
      status: status ? true : undefined,
    })
    .then((res) => {
      return res;
    });
};

const addCarrier = (data: any, successCB: Function) => {
  return axios.post('/carrier', data).then((res) => {
    successCB(res);
    return res;
  });
};

const deleteCarrier = (uuid: String, data: any) => {
  return axios.delete(`/carrier/${uuid}`, { data });
};

export {
  fetchCarrier,
  fetchCarriers,
  UpdateCarrier,
  addCarrier,
  updateCarrierData,
  deleteCarrier,
};

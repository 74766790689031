import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addContact, deleteContact } from '../../api/contact';
import { IContact } from '../../interfaces/Contact';
import { IOpportunity } from '../../interfaces/Opportunity';
import { IAccount } from '../../interfaces/Account';
import {
  fetchContacts,
  updateContactDetails,
  fetchContact,
  fetchContactOpportunity,
  fetchContactAccount,
  ContactDetailsCount,
  fetchContactPolicies,
  addAssosiatedContact,
} from '../../api/contact';
import { IPolicy } from '../../interfaces/Policy';

export type ContactsSliceState = {
  status: string;
  opportunitiesStatus: string;
  quotesBool: boolean;
  policyStatus: string;
  contacts: IContact[];
  contactDetails?: IContact;
  contactOpportunities: IOpportunity[];
  contactAccount: IAccount[];
  accountContactStatus: string;
  count: number;
  opportunitiesCount: number;
  contactPolicies: IPolicy[];
  quotesCount: number;
  activitiesCount: number;
  documentsCount: number;
  policiesCount: number;
  accountsCount: number;
  isLoading: boolean;
  historiesCount: number;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  accountUUID?: string;
  jurisdictionsUUID?: string;
  producerUUID?: string;
  type?: any;
  successCB?: Function;
}

const initialState: ContactsSliceState = {
  status: 'idle',
  opportunitiesStatus: 'idle',
  policyStatus: 'idle',
  quotesBool: false,
  contacts: [],
  contactOpportunities: [],
  contactPolicies: [],
  contactAccount: [],
  count: 0,
  opportunitiesCount: 0,
  accountContactStatus: 'idle',
  quotesCount: 0,
  activitiesCount: 0,
  documentsCount: 0,
  policiesCount: 0,
  accountsCount: 0,
  isLoading: false,
  historiesCount: 0,
};

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async ({
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    type,
    jurisdictionsUUID,
    accountUUID,
    producerUUID,
    successCB,
  }: IProps) => {
    const response = await fetchContacts(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      undefined,
      undefined,
      type,
      accountUUID,
      undefined,
      jurisdictionsUUID,
      producerUUID
    );
    if (successCB) successCB(response.data);
    return response.data;
  }
);

export const createContact = createAsyncThunk(
  'contacts/createContact',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: IContact;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addContact(data, successCB, errorCB);
    return response.data;
  }
);

export const createAssoasiatedContact = createAsyncThunk(
  'contacts/createAssoasiatedContact',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: IContact;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addAssosiatedContact(data, successCB, errorCB);
    return response.data;
  }
);

export const onUpdateContactDetails = createAsyncThunk(
  'contacts/onUpdateContactDetails',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: IContact;
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await updateContactDetails(data, uuid, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const getContact = createAsyncThunk(
  'contacts/getContact',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchContact(uuid, successCB);
    return response.data;
  }
);

export const getContactOpportunities = createAsyncThunk(
  'contacts/getContactOpportunities',
  async ({
    uuid,
    offset,
    getQuote,
    pipelineUUID,
    opportunityStatusUUID,
    productCategoryUUID,
    accountUUID,
    producerUUID,
    productUUID,
    customProductUUID,
    quoteTypeUUID,
    quoteStageUUID,
    quoteStatusUUID,
    coverageTypeUUID,
  }: {
    uuid: string;
    offset: number;
    getQuote?: string;
    pipelineUUID?: any;
    opportunityStatusUUID?: any;
    productCategoryUUID?: any;
    accountUUID?: string;
    producerUUID?: string;
    productUUID?: any;
    customProductUUID?: any;
    quoteTypeUUID?: string;
    quoteStageUUID?: string[];
    quoteStatusUUID?: string;
    coverageTypeUUID?: string[];
  }) => {
    const response = await fetchContactOpportunity(
      uuid,
      offset,
      getQuote,
      pipelineUUID,
      opportunityStatusUUID,
      productCategoryUUID,
      accountUUID,
      producerUUID,
      productUUID,
      customProductUUID,
      quoteTypeUUID,
      quoteStageUUID,
      quoteStatusUUID,
      coverageTypeUUID
    );
    return response.data;
  }
);

export const getContactAccount = createAsyncThunk(
  'contacts/getContactAccount',
  async ({
    uuid,
    accountStatusUUID,
    hasPolicy,
    producerUUID,
    type,
    limit,
    offset,
  }: {
    uuid: string;
    accountStatusUUID?: any;
    hasPolicy?: string;
    producerUUID?: any;
    type?: any;
    limit?: number;
    offset?: number;
  }) => {
    const response = await fetchContactAccount(
      uuid,
      accountStatusUUID,
      hasPolicy,
      producerUUID,
      type,
      limit,
      offset
    );
    return response.data;
  }
);

export const getContactPolicies = createAsyncThunk(
  'contacts/getContactPolicies',
  async ({
    uuid,
    offset,
    producerUUID,
    productCategoryUUID,
    hasMGA,
    carrierUUID,
    customCarrierUUID,
    productUUID,
    customProductUUID,
  }: {
    uuid: string;
    offset: number;
    producerUUID?: string;
    productCategoryUUID?: any;
    hasMGA?: boolean;
    carrierUUID?: any;
    customCarrierUUID?: any;
    productUUID?: any;
    customProductUUID?: any;
  }) => {
    const response = await fetchContactPolicies(
      uuid,
      offset,
      producerUUID,
      productCategoryUUID,
      hasMGA,
      carrierUUID,
      customCarrierUUID,
      productUUID,
      customProductUUID
    );
    return response.data;
  }
);

export const getCount = createAsyncThunk(
  'accounts/getCount',
  async ({ uuid }: { uuid: string }) => {
    const response = await ContactDetailsCount(uuid);
    return response.data;
  }
);

export const onDeleteContact = createAsyncThunk(
  'accounts/onDeleteContact',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteContact(uuid);
    successCB();
    return response.data;
  }
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearOpportunities: (state) => {
      state.contactOpportunities = [];
    },
    clearContactPolicies: (state) => {
      state.contactPolicies = [];
    },
    clearContactAccount: (state) => {
      state.contactAccount = [];
    },
    getContactQuoteCount: (state) => {
      state.quotesCount = state.quotesCount + 1;
    },
    updateContactDetailAccount: (state, action) => {
      if (action.payload.type === 'delete') {
        if (state.contactDetails?.AccountContacts) {
          const index = state.contactDetails.AccountContacts.findIndex(
            (item) => item.Account.uuid === action.payload.uuid
          );
          state.contactDetails.AccountContacts.splice(index, 1);
        }
      } else if (action.payload.type === 'update') {
        const index = state.contactAccount.findIndex(
          (item) => item.uuid === action.payload.account.uuid
        );
        state.contactAccount[index] = action.payload.account;
      } else {
        if (state.contactDetails)
          state.contactDetails.AccountContacts.push({
            Account: action.payload.account,
          });
      }
    },
    updateContactDetailOpportunity: (state, action) => {
      if (action.payload.type === 'add') {
        if (state.contactDetails?.AccountContacts[0]?.Account?.Opportunities)
          state.contactDetails.AccountContacts[0].Account.Opportunities.push(
            action.payload.opportunity
          );
      } else if (action.payload.type === 'update') {
        const index = state.contactOpportunities.findIndex(
          (item) => item.uuid === action.payload.opportunity.uuid
        );
        state.contactOpportunities[index] = action.payload.opportunity;
      } else {
        if (state.contactDetails?.AccountContacts[0]?.Account?.Opportunities) {
          const index =
            state.contactDetails.AccountContacts[0].Account.Opportunities.findIndex(
              (item) => item.uuid === action.payload.uuid
            );
          state.contactDetails.AccountContacts[0].Account.Opportunities.splice(
            index,
            1
          );
        }
      }
    },
    deleteContactPoliciesList: (state, action) => {
      const index = state.contactPolicies.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      state.contactPolicies.splice(index, 1);
    },
    updateContactPoliciesList: (state, action) => {
      const index = state.contactPolicies.findIndex(
        (item) => item.uuid === action.payload.data.uuid
      );
      state.contactPolicies[index] = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getContacts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = action.payload.contacts;
        state.count = action.payload.count;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contacts.unshift(action.payload);
      })
      .addCase(createContact.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createAssoasiatedContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contacts.unshift(action.payload);
      })
      .addCase(createAssoasiatedContact.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createAssoasiatedContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateContactDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.contacts.findIndex(
          (item: any) => item.uuid === action.payload.uuid
        );
        state.contacts[index] = action.payload;
        state.contactDetails = action.payload;
      })
      .addCase(onUpdateContactDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateContactDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contactDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(getContact.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getContactOpportunities.pending, (state, action) => {
        state.opportunitiesStatus = 'loading';
      })
      .addCase(getContactOpportunities.fulfilled, (state, action) => {
        state.opportunitiesStatus = 'succeeded';
        if (action.payload.opportunities < 4) {
          state.quotesBool = true;
        }
        state.contactOpportunities = [
          ...state.contactOpportunities,
          ...action.payload.opportunities,
        ];
      })
      .addCase(getContactOpportunities.rejected, (state, action) => {
        state.opportunitiesStatus = 'failed';
      })
      .addCase(getContactAccount.fulfilled, (state, action) => {
        state.accountContactStatus = 'succeeded';
        state.contactAccount = [
          ...state.contactAccount,
          ...action.payload.accounts,
        ];
      })
      .addCase(getContactAccount.pending, (state, action) => {
        state.accountContactStatus = 'loading';
      })
      .addCase(getCount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activitiesCount = action.payload.activities;
        state.documentsCount = action.payload.documents;
        state.quotesCount = action.payload.quotes;
        state.opportunitiesCount = action.payload.opportunities;
        state.policiesCount = action.payload.policies;
        state.accountsCount = action.payload.accounts;
        state.historiesCount = action.payload.histories;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getContactPolicies.pending, (state, action) => {
        state.policyStatus = 'loading';
      })
      .addCase(getContactPolicies.fulfilled, (state, action) => {
        state.policyStatus = 'succeeded';
        state.contactPolicies = [
          ...state.contactPolicies,
          ...action.payload.policies,
        ];
      })
      .addCase(getContactPolicies.rejected, (state, action) => {
        state.policyStatus = 'failed';
      })
      .addCase(onDeleteContact.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.contacts.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.contacts.splice(index, 1);
      })
      .addCase(onDeleteContact.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const {
  updateStatus,
  clearOpportunities,
  clearContactPolicies,
  clearContactAccount,
  getContactQuoteCount,
  updateContactDetailAccount,
  updateContactDetailOpportunity,
  deleteContactPoliciesList,
  updateContactPoliciesList,
} = contactsSlice.actions;
export default contactsSlice.reducer;

export const selectAllContacts = (state: { contacts: ContactsSliceState }) =>
  state.contacts.contacts;

export const selectContact = (state: { contacts: ContactsSliceState }) =>
  state.contacts.contactDetails;

export const selectContactOpportunities = (state: {
  contacts: ContactsSliceState;
}) => state.contacts.contactOpportunities;

export const selectContactAccount = (state: { contacts: ContactsSliceState }) =>
  state.contacts.contactAccount;

export const selectContactPolicies = (state: {
  contacts: ContactsSliceState;
}) => state.contacts.contactPolicies;

export const selectContactCount = (state: { contacts: ContactsSliceState }) => {
  const objectCount: { [index: string]: any } = {
    documents: state.contacts.documentsCount,
    activities: state.contacts.activitiesCount,
    quotes: state.contacts.quotesCount,
    policies: state.contacts.policiesCount,
    opportunities: state.contacts.opportunitiesCount,
    accounts: state.contacts.accountsCount,
    histories: state.contacts.historiesCount,
  };
  return objectCount;
};

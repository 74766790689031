import { CustomFieldDiv } from '../../../Activities/elements/CustominputStyles';
export interface IProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  onSetItem: Function;
  squareField?: boolean;
  firstField?: boolean;
  lastField?: boolean;
  values: any;
  prefix?: string;
  userUUID: string;
  premiumGoalUUID?: string;
  policyGoalUUID?: string;
}

const CustomeEditGoalField = ({
  name,
  type,
  placeholder,
  className,
  disabled,
  onSetItem,
  values,
  prefix,
  userUUID,
  premiumGoalUUID,
  policyGoalUUID,
}: IProps) => {
  return (
    <CustomFieldDiv>
      <div
        className={`d-flex flex-column w-100 ${className}  ${
          values[name] ? 'filled' : ''
        } input-containerGoalField `}
      >
        <input
          id={name}
          name={name}
          onChange={(e) =>
            onSetItem(
              name,
              e.target.value,
              userUUID,
              policyGoalUUID,
              premiumGoalUUID
            )
          }
          className={`input-field inputGoal-field
           `}
          disabled={disabled}
          value={values[name]}
          placeholder={placeholder}
          type={type}
          prefix={prefix}
          autoComplete='off'
        />
      </div>
    </CustomFieldDiv>
  );
};

export default CustomeEditGoalField;

import { GlobalTCContainer } from '../styles';

const TermsAndCondition = () => {
  const companyName = 'Renegade insurance';
  const contactMail = 'contact@renegade.com';
  const state = 'state';
  return (
    <GlobalTCContainer>
      <div>
        <h2>Terms and Conditions</h2>
        <div className='updated-date'>Updated on: 12 Sept, 2022</div>
      </div>
      <div className='content'>
        <p>
          {companyName} and its related services ("Service") subject to your
          compliance with the terms and conditions ("Terms of Service") set
          forth below. Please read the following carefully.
        </p>
        <p>
          {companyName} reserves the right to update and change the Terms of
          Service at any time without notice. All new features that augment or
          enhance the current service, including the release of new tools and
          resources, shall be subject to the Terms of Service. Continued use of
          the Service after any such changes shall constitute your consent to
          said changes.
        </p>
        <p>
          Violation of any of the terms below will result in the termination of
          your account. While {companyName} prohibits such conduct and content
          on the service, you understand and agree that {companyName} cannot be
          held responsible for the content posted on the Service and you may be
          exposed to such content. You agree to use the Service at your own
          risk.
        </p>
        <h3>Account Terms</h3>
        <ol>
          <li>
            You must be human. Accounts registered by bots and other automated
            methods are not permitted.
          </li>
          <li>
            {companyName} may communicate with you via email regarding your
            account, updates, news, and other issues related to your account.
            You automatically get subscribed to our mailing lists and
            newsletters. You can choose to opt out from receiving emails. You
            are responsible for maintaining the security of your account and
            password. {companyName} cannot and will not be liable for any loss
            or damage from your failure to comply with this security obligation.
          </li>
          <li>
            You are responsible for all content posted and activity that occurs
            under your account (even if the content is user generated).
          </li>
          <li>
            We have a soft limit on all our plans and should we believe that you
            are abusing the service, we may temporarily suspend your account and
            will seek your cooperation in this regard.
          </li>
          <li>
            We reserve the right to accept or refuse any potential client. We
            have the sole discretion on whether to allow you to register, renew,
            change plan, or use our services.{' '}
          </li>
          <li>
            You must provide your full legal name, a valid business email
            address, and any other information requested in order to complete
            the sign-up process.
          </li>
        </ol>
        <h3>Payments, Refunds, Upgrading and Downgrading Terms</h3>
        <ol>
          <li>A valid credit card is required for paying accounts.</li>
          <li>
            Paid Plans
            <ol>
              <li>
                <b>Monthly subscription:</b> {companyName} monthly subscription
                is a month-to-month, pay-as-you-go service. You will be billed
                for every 30 days of service. You may cancel our service any
                time and you won't be charged again, but you are solely
                responsible for the charges already incurred.
              </li>
              <li>
                <b>Yearly and bi-yearly subscription:</b> Since we offer
                generous discounts for our yearly and bi-yearly plans, we offer
                no refunds on these plans. You may cancel the service anytime
                and you will not be charged again. You are, however, solely
                responsible for the charges already incurred.
              </li>
            </ol>
          </li>
          <li>
            No refunds will be made from {companyName} on any of the Monthly,
            Yearly & Bi-Yearly plans.
          </li>
          <li>
            You are responsible for whatever charges have been incurred. Due to
            the equality policy for our customers, no exceptions will be made.
          </li>
          <li>
            For any upgrade or downgrade in plans, your credit card will
            automatically be charged with the new plan rate on and from your
            next billing cycle.
          </li>
          <li>
            Downgrading your Service may cause the loss of content, features, or
            capacity of your account. {companyName} does not accept any
            liability for such losses.
          </li>
        </ol>
        <h3>Cancellation and Termination</h3>
        <ol>
          <li>
            {' '}
            The account owner is the only person who can cancel an account. You
            are solely responsible for properly canceling your account. Any
            email/ phone/ contact request to cancel account will not be allowed.
            Please ensure that the cancelation requests are made only through
            the online control panel.
          </li>
          <li>
            Account cancelations typically take about 2-3 business days to be
            processed. Once your account is canceled, all your account
            information will be permanently deleted. You will not be charged
            again after your official cancelation date.
          </li>
          <li>
            {companyName}, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service, or any other {companyName} service, for any reason,
            at any time. Such termination of the Service will result in the
            deactivation or deletion of your Account or access to your Account,
            and the forfeiture and relinquishment of all content in your
            Account. {companyName} reserves the right to refuse service to
            anyone for any reason at any time.
          </li>
        </ol>
        <h3>Modifications to the Service and Prices</h3>
        <ol>
          <li>
            {companyName} reserves the right to modify and discontinue, at any
            time and from time to time, temporarily or permanently, the Service
            (or any part thereof) with or without notice. Prices of all
            Services, including but not limited to subscription plan fees of the
            Service, are subject to change without notice from us. {companyName}{' '}
            shall not be liable to you or to any third party for any
            modification, price change, suspension, or discontinuance of the
            Service. Copyright and Ownership
            {companyName} or its suppliers own the intellectual property rights
            to any and all protectable components of the Service, including but
            not limited to the name of the Service, artwork and end-user
            interface elements contained within the Service, many of the
            individual features, and the related documentation. You may not
            copy, modify, adapt, reproduce, distribute, reverse engineer,
            decompile, or disassemble any aspect of the Service which{' '}
            {companyName} or its suppliers own.
          </li>
          <li>
            {companyName} claims no intellectual property rights over the
            Content you upload or provide to the Service. However, by using the
            Service to send Content, you agree that others may view and share
            your Content.
          </li>
        </ol>
        <h3>General Conditions</h3>
        <ol>
          <li>
            Your use of the Service, including any content, information, or
            functionality contained within it, is provided "as is" and "as
            available" with no representations or warranties of any kind, either
            expressed or implied, including but not limited to, the implied
            warranties of merchantability, fitness for a particular purpose, and
            non-infringement. You assume total responsibility and risk for your
            use of this Service.
          </li>
          <li>
            You understand that the Service can be used for transmission of your
            Content, and that during processing, your Content may be transferred
            unencrypted over the internet.
          </li>
          <li>
            You understand that {companyName} uses third party vendors and
            hosting partners to provide necessary hardware, software,
            information, networking, storage, and related technology to run the
            service.
          </li>
          <li>
            You agree not to resell, duplicate, reproduce, or exploit any part
            of the Service without the explicit written permission of{' '}
            {companyName}.
          </li>
          <li>
            You may not use the service to store, host, or send unsolicited
            email (spam), chats or SMS messages. {companyName} is Anti-Spam
            compliant and does not authorize or permit spam to be sent out via
            the automation service by you. If there is evidence of spam, your
            services might be suspended without notice. Accidental spam must
            immediately be reported to {companyName} to prevent suspension.
          </li>
          <li>
            You may not use the service to transmit any viruses, worms, or
            malicious content.
          </li>
          <li>
            {companyName} makes no warranties regarding (i) your ability to use
            the Service, (ii) your satisfaction with the Service, (iii) that the
            Service will be available at all times, uninterrupted, and
            error-free (iv), the accuracy of mathematical calculations performed
            by the Service, and (v) that bugs or errors in the Service will be
            corrected.
          </li>
          <li>
            {companyName}, its affiliates and its sponsors are neither
            responsible nor liable for any direct, indirect, incidental,
            consequential, special, exemplary, punitive, or other damages
            arising out of or relating in any way to your use of the Service.
            Your sole remedy for dissatisfaction with the Service is to stop
            using the Service.
          </li>
          <li>
            If any provision of the Terms of Service is held invalid or
            otherwise unenforceable, the enforceability of the remaining
            provisions shall not be impaired thereby.
          </li>
          <li>
            {companyName} may, but have no obligation to, remove Content and
            Accounts containing Content that we determine in our sole discretion
            are unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene, or otherwise objectionable or violates any
            party's intellectual property or these Terms of Service.
          </li>
          <li>
            The Terms of Service sets forth the entire understanding between you
            and {companyName} as to the Service, and supersedes any prior
            agreements between you and {companyName} (including, but not limited
            to, prior versions of the Terms of Service).
          </li>
          <li>
            Any questions regarding the Terms of Service should be addressed to{' '}
            {contactMail}
          </li>
        </ol>

        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed by the laws of the State of {state}{' '}
          without regard to the principles of conflicts of law. You hereby
          expressly agree to submit to the exclusive personal jurisdiction of
          the federal and state courts of the State of {state} for the purpose
          of resolving any dispute relating to your access to or use of the
          Service.
        </p>
      </div>
    </GlobalTCContainer>
  );
};

export default TermsAndCondition;

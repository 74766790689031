import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../../store';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { StyledModal } from '../../../Pipeline/elements/styles';
import close from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import { fetchLineOfBusiness } from '../../../../api/lineOfBusiness';
import { ILineOfBusiness } from '../../../../interfaces/ProductCategory';
import { onUpdateProduct } from '../../../../store/productCategories/productCategoriesSlice';

const PolicyTypeSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter the name.')
    .max(25, 'Name length must be less than 25.'),
});

const EditPolicyType = ({
  showEditPolicyType,
  setShowEditPolicyType,
  setSuccessMessage,
  defaultType,
  policyType,
}: {
  showEditPolicyType: boolean;
  setShowEditPolicyType: Function;
  setSuccessMessage: Function;
  defaultType: any;
  policyType: any;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const types: any[] = [];
  const [lineOfBusiness, setLineOfBusiness] = useState<ILineOfBusiness[]>([]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PolicyTypeSchema),
  });

  const watchName = watch('name');
  const watchLOB = watch('lineOfBusiness');

  useEffect(() => {
    setValue('name', policyType.name);
    setValue('type', { label: defaultType.name, value: defaultType.uuid });
    setValue('lineOfBusiness', {
      label:
        policyType?.LineOfBusiness?.name[0].toUpperCase() +
        policyType?.LineOfBusiness?.name.slice(1).toLowerCase(),
      value: policyType?.LineOfBusiness?.uuid,
    });
  }, [policyType]);

  const onEditPolicyType = (data: any) => {
    data.agencyUUID = uuid;
    data.action = 'editCustom';

    data.lineOfBusinessUUID = data.lineOfBusiness?.value;
    data.productCategoryUUID = data.type.value;

    dispatch(
      onUpdateProduct({
        uuid: policyType.uuid,
        data,
        successCB: () => {
          setShowEditPolicyType(false);
          setSuccessMessage(
            `Policy type <b>${watchName}</b> has been updated.`
          );
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  useEffect(() => {
    fetchLineOfBusiness((data: ILineOfBusiness[]) => setLineOfBusiness(data));
  }, []);

  return (
    <StyledModal
      show={showEditPolicyType}
      centered
      dialogClassName='drawer-dialog'
      contentClassName='drawer-content'
    >
      <img
        alt=''
        src={close}
        className='closeButton'
        onClick={() => setShowEditPolicyType(false)}
      />
      <h2 className='mb-3'>Edit policy type</h2>
      <form onSubmit={handleSubmit(onEditPolicyType)}>
        <div className='mb-3'>
          <SelectField
            options={types.map((type) => {
              return { label: type.name, value: type.uuid };
            })}
            name='type'
            placeholder='Select policy category'
            control={control}
            isDisabled={true}
          />
        </div>

        <div className='mb-3'>
          <SelectField
            options={lineOfBusiness.map((type) => {
              return {
                label:
                  type.name[0].toUpperCase() + type.name.slice(1).toLowerCase(),
                value: type.uuid,
              };
            })}
            name='lineOfBusiness'
            placeholder='Select line of business'
            control={control}
          />
          {!watchLOB ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              Please select a Line of business
            </div>
          ) : null}
        </div>

        <InputField
          name='name'
          type='text'
          placeholder='Name of policy type'
          register={register}
          className={watchName && 'filled'}
        />
        {errors.name ? (
          <div className='errorVal'>
            <img src={warning} alt='warning' className='warning' />
            {errors.name?.message}
          </div>
        ) : null}

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowEditPolicyType(false);
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button className='primaryButton' type='submit'>
            Submit <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledModal>
  );
};

export default EditPolicyType;

import axios from './axios';

const fetchLineOfBusiness = (successCB: Function) => {
  return axios.get('/lineOfBusiness').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

export { fetchLineOfBusiness };

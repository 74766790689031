import styled from '@emotion/styled';
export const StyledOpportunityModal = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  .topdiv {
    width: 90vw;
    border-bottom: 1px solid #e6e9f0;
    z-index: 4;
    height: 71px;
  }
  .entire-div {
    width: 95vw;
  }

  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
    padding: 19px 23px;
  }
  .scroll-bar {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 120px;
    padding-right: 20px;
    margin-right: 6px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 20px;
  }
  .personal {
    width: 56px;
  }
  .commercial {
    width: 80px;
    margin-left: 24px;
  }
  .contact-info {
    left: 49px;
    top: 133.96px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }
  .business-info {
    margin-top: 32px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
  }
  .fields {
    width: 90vw;
    margin-top: 16px;
    color: #262758;
    .search-group {
      .input-placeholder {
        left: 16px;
      }
    }
  }
  .fields::placeholder {
    color: #262758;
  }
  .fields-small {
    width: 90vw;
    margin-top: 16px;
  }
  .fields-medium {
    width: 200px;
    margin-top: 16px;
  }
  .fields-xsmall {
    width: 100px;
    margin-top: 16px;
  }
  .small-div {
    width: 320px;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0;
    background: #fff;
    z-index: 1;
    padding: 10px 20px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 20px;
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .naVigatorbuttons {
    colour: #262758;
    background: none;
    border: none;
    cursor: pointer;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    border-bottom: 3px solid #262758;
    cursor: pointer;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
    font-weight: 600;
  }
  .bigfields {
    width: 90vw;
    height: 134px;
    padding: 16px 16px;
    max-height: 198px;
    margin: 0 auto;
    border: 1px solid #e6e9f0;
    border-radius: 8px;
    margin-top: 18px;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
    &:focus,
    &:active {
      border: 1px solid #3c498a;
      outline-offset: 0px;
      outline: none;
    }
  }
  .errorVal {
    margin-top: 8px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    &.emailError {
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .form-title {
    margin-bottom: 20px;
  }
  .commercial-text {
    margin-left: 20px;
  }
  .textarea {
    width: 90vw;
    margin-top: 16px;
  }
  .addNotesButton {
    margin-top: 40px;
    border: none;
    background: #fff;
  }

  @media screen and (min-width: 576px) {
    width: 100%;
    .fields {
      width: 420px;
    }
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 150px;
      }
    }
    .scroll-bar {
      padding-right: 15px;
    }
    .small-div {
      width: 420px;
    }
    .topdiv {
      height: 76px;
      width: 100%;
    }
    .fields-small {
      width: 200px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .fields-xsmall {
      width: 104px;
    }
    .fields-medium {
      width: 240px;
    }
    .cross-btn {
      padding: 26.68px 34px;
    }
    .entire-div {
      width: 104%;
      margin: 0 auto;
    }
    .scroll-bar {
      height: 68vh;
      padding-bottom: 64px;
    }
    .bigfields {
      margin-top: 25px;
      width: 99.9%;
    }
    .buttonsDiv {
      width: 93%;
      left: 36px;
      padding: 21px 48px;
    }
    .business-info {
      margin-top: 42px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 100%;
    .fields {
      width: 420px;
      margin-top: 16px;
    }
    .topdiv {
      height: 80px;
      border-bottom: 1px solid #e6e9f0;
      width: 100%;
    }
    .entire-div {
      width: 890px;
      margin: 0 auto;
    }
    .small-div {
      width: 420px;
    }
    .fields-small {
      width: 200px;
    }
    .fields-medium {
      width: 298px;
      margin-top: 16px;
    }
    .fields-xsmall {
      width: 101px;
      margin-top: 16px;
    }
    .contact-info {
      margin-top: 24px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      margin-right: 20px;
    }
    .cross {
      width: 30px;
    }
    .scroll-bar {
      height: 75vh;
      padding-right: 20px;
    }
    .saveButton {
      width: 220px;
    }
    .bigfields {
      width: 99.9%;
      margin-top: 35px;
    }
    .form-title {
      margin-bottom: 20px;
    }
    .buttonsDiv {
      width: 92%;
      left: 47px;
      padding: 38px 48px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 100%;
    .cross-btn {
      margin-top: 7px;
      margin-right: 2px;
    }
    .topdiv {
      width: 100%;
      height: 88px;
    }
    .cross {
      width: 32px;
    }
    .personal {
      width: 56px;
    }
    .commercial {
      width: 80px;
      margin-left: 20px;
    }
    .contact-info {
      font-size: 20px;
      line-height: 28px;
      margin-top: 20px;
    }
    .business-info {
      font-size: 20px;
      line-height: 28px;
    }
    .fields {
      width: 576px;
      margin-top: 20px;
    }
    .small-div {
      width: 576px;
    }
    .fields-small {
      width: 278px;
      margin-top: 20px;
    }
    .fields-medium {
      width: 416px;
      margin-top: 20px;
    }
    .fields-xsmall {
      width: 140px;
      margin-top: 20px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .buttonsDiv {
      width: 88%;
      bottom: 0;
      left: 72px;
      z-index: 2;
      padding: 31px 60px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
      right: 20px;
    }
    .entire-div {
      width: 1340px;
      margin: 0 auto;
      padding: 0px 60px;
    }
    .bigfields {
      margin-top: 40px;
      width: 99.8%;
    }
    .scroll-bar {
      height: 75vh;
      padding-right: 20px;
    }
    .form-title {
      margin-bottom: 28px;
    }
  }
`;

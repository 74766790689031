import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { AppDispatch } from '../../store';
import { createLead, LeadsSliceState } from '../../store/leads/leadsSlice';
import { getCount } from '../../store/book/bookSlice';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../store/customFields/customFieldsSlice';
import { fetchContacts } from '../../api/contact';
import { getLeadSourceTypes, getLeadStage } from '../../api/lead';
import { fetchIndustries } from '../../api/industry';
import InputField from '../InputField';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import PhoneNumberInput from '../PhoneNumberFormat';
import SearchDropdown from '../SearchDropdown';
import GoogleSearchInput from '../GoogleSearchInput';
import MarkdownEditor from '../MarkdownEditor';
import CustomFieldInput from '../CustomfieldsInput';
import { ILead, ILeadSourceType } from '../../interfaces/Lead';
import { StyledContactModal } from './style';
import { SuccessAlign } from '../../styles/SuccessAlign';
import { FormModal } from '../../styles/FormModal';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import warning from '../../assets/logo/warning.svg';

const LeadSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  leadStage: Yup.object().shape({
    value: Yup.string().required('Please select lead status'),
  }),
  businessEmail: Yup.string().email('Enter a valid email.'),
  businessPhoneNumber: Yup.string().matches(
    /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
    { message: 'Phone number is not valid', excludeEmptyString: true }
  ),
  leadSource: Yup.object().shape({
    value: Yup.string().required('Please select lead source'),
  }),
});

const AddLeadDrawer = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LeadSchema),
  });

  const [status, setStatus] = useState<ILead[]>([]);
  const [addressObject, setAddressObject] = useState<any>({});
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);
  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  useEffect(() => {
    getLeadStage((data: ILead[]) => {
      setStatus(data);
    });
  }, [show]);

  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'LEAD',
        isAddForm: 'true',
        successCB: () => {},
      })
    );
  }, []);

  const loading = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.isLoading
  );

  const watchAllFields = watch();
  const [commercial, isCommercial] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setError] = useState('');

  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [submit, setSubmit] = useState(false);
  const [businessError, setBusinessError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [textCheck, setTextCheck] = useState('');
  const [noteError, setNoteError] = useState('');

  const [isManual, setIsManual] = useState(false);

  const customFields = useSelector(selectAllCustomFields);
  useEffect(() => {
    customFields.map((item) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setValue('email', '');
    setEmailAccounts([]);
  };

  const loadEmail = (value: string) => {
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const onSubmit = (data: any) => {
    data.customFields = {};

    customFields.map((item: any) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.customFields[item.uuid] = timeItem.value;
        } else {
          data.customFields[item.uuid] = data[item.uuid];
        }
      }
    });
    var canSubmit = true;
    if (industrySearch && !industryUuid) {
      canSubmit = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    } else if (canSubmit && !emailError) {
      let noteSubmit = true;
      if (notes) {
        if (textCheck && !textCheck.trim() && textCheck.length > 1) {
          noteSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      data.notes = notes;
      data.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';
      data.industry = industryUuid;
      data.leadSourceUUID = data.leadSource?.value;
      data.leadStageUUID = data.leadStage?.value;
      data.isManuallyAdded = isManual;
      // To add addressObject with the form
      if (!isManual) {
        data = Object.assign(data, addressObject);
      }
      if (commercial && !data.businessName) {
        return setBusinessError('Please enter  Business Name');
      }
      if (noteSubmit) {
        dispatch(
          createLead({
            data: data,
            successCB: () => {
              setDisabled(true);
              dispatch(getCount());
              closeModal('closing');
              setTimeout(() => {
                setShowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setShowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') setError(error);
            },
          })
        );
      }
    }
  };

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchIndustries(
        (data: any) => {
          setIndustry(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  useEffect(() => {
    setAddressObject({}); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial]);
  const [notes, setNotes] = useState<any>(['']);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  useEffect(() => {
    setError('');
  }, [watchAllFields.phoneNumber]);

  useEffect(() => {
    if (notes) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [notes]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);

  const resetIndustry = (value: string) => {
    setIndustry([]);
    setIndustryUuid('');
  };

  const leadSourceOptions = useMemo(() => {
    return sourceTypes.map((item) => {
      return {
        label: item.name,
        options: item.LeadSources.map((source) => {
          return {
            label: source.name,
            value: source.uuid,
          };
        }),
      };
    });
  }, [sourceTypes]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success adding lead!</b>`}
            message={`<b>${getValues('firstName')}${getValues(
              'lastName'
            )}</b> has been added as a lead`}
          />
          <button
            onClick={() => {
              reset();
              onCloseModal();
              setShowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <FormModal
        open={show}
        align='right'
        containerClassName='fullwidth-container'
      >
        <StyledContactModal>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='addContact'>
                <h2>Add Lead</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex'>
                <p
                  onClick={() => isCommercial(false)}
                  className={
                    !commercial ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                  }
                >
                  Personal
                </p>
                <p
                  onClick={() => isCommercial(true)}
                  className={
                    commercial
                      ? 'naVigatorbuttonsActive commercial'
                      : 'naVigatorbuttons commercial'
                  }
                >
                  Commercial
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar'>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields mt-27'>
                    <SelectField
                      options={leadSourceOptions}
                      name='leadSource'
                      placeholder='Lead Source'
                      required
                      className={`${errors.leadSource && 'error-field'} ${
                        watchAllFields.leadSource && 'filled'
                      }`}
                      control={control}
                      ariaInvalid={!!errors?.leadSource}
                    />
                    {errors.leadSource ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.leadSource?.message
                          ? 'Please select lead source'
                          : errors.leadSource?.value?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <h2 className='contact-info d-xxl-none'>Contact Info</h2>
                </div>
                <div>
                  <h2 className='contact-info d-none d-xxl-block'>
                    Contact Details
                  </h2>
                </div>
                <div className='top-field d-sm-flex justify-content-between'>
                  {/* <Calendar value={dateState} onChange={changeDate} /> */}
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      className={`${errors.firstName && 'error-field'} ${
                        watchAllFields.firstName && 'filled'
                      }`}
                      register={register}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='small-div d-sm-flex justify-content-between'>
                    <div className='fields-small'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                        register={register}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        required
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                        register={register}
                      />
                      {errors.lastName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'} ${
                        (errors.email || errorMessage) && 'error-field'
                      }`}
                      register={register}
                      control={control}
                      type='email'
                      required
                      setUUID={resetEmail}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      errors.email?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message}
                        </div>
                      )
                    )}
                  </div>
                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                      control={control}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SelectField
                      options={status.map((item) => {
                        return {
                          label: ` ${item.name ? item.name : ''}`,
                          value: item.uuid,
                        };
                      })}
                      name='leadStage'
                      placeholder='Lead status'
                      required
                      className={`${errors.leadStage && 'error-field'} ${
                        watchAllFields.leadStage && 'filled'
                      }`}
                      control={control}
                      ariaInvalid={!!errors.leadStage}
                    />
                    {errors.leadStage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.leadStage?.message
                          ? 'Please select lead status'
                          : errors.leadStage?.value?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                {commercial && customFields?.length > 0
                  ? customFields?.map((item: any, index: number) =>
                      index % 2 === 0 ? (
                        <div className='d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <CustomFieldInput
                              item={item}
                              register={register}
                              control={control}
                              value={watchAllFields[item.uuid]}
                              setDueTimeVal={setDueTimeVal}
                              duetimeVal={duetimeVal}
                            />
                          </div>
                          {customFields?.length > index + 1 ? (
                            <div className='fields'>
                              <CustomFieldInput
                                item={customFields[index + 1]}
                                register={register}
                                control={control}
                                value={
                                  watchAllFields[customFields[index + 1].uuid]
                                }
                                setDueTimeVal={setDueTimeVal}
                                duetimeVal={duetimeVal}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    )
                  : null}

                {!commercial ? (
                  <>
                    {!isManual ? (
                      <>
                        <div className='d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <GoogleSearchInput
                              placeholder='Mailing Address'
                              name='mailingAddress'
                              setAddressObject={setAddressObject}
                              addressObject={addressObject}
                              inputType='PERSONAL'
                              className={`${errors.address} ${
                                Object.keys(addressObject).length > 0 &&
                                'filled'
                              }`}
                              setIsManual={setIsManual}
                            ></GoogleSearchInput>
                          </div>
                          <div className='fields'>
                            <InputField
                              name='apartment'
                              type='text'
                              placeholder='Apt'
                              className={`${
                                errors.apartment && 'error-field'
                              } ${watchAllFields.apartment && 'filled'}`}
                              register={register}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 className='business-info'>Mailing address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='apartment'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.apartment && 'error-field'
                              } ${watchAllFields.apartment && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {customFields?.length > 0
                      ? customFields?.map((item: any, index: number) =>
                          index % 2 === 0 ? (
                            <div className='d-sm-flex justify-content-between'>
                              <div className='fields'>
                                <CustomFieldInput
                                  item={item}
                                  register={register}
                                  control={control}
                                  value={watchAllFields[item.uuid]}
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                              {customFields?.length > index + 1 ? (
                                <div className='fields'>
                                  <CustomFieldInput
                                    item={customFields[index + 1]}
                                    register={register}
                                    control={control}
                                    value={
                                      watchAllFields[
                                        customFields[index + 1].uuid
                                      ]
                                    }
                                    setDueTimeVal={setDueTimeVal}
                                    duetimeVal={duetimeVal}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        )
                      : null}
                  </>
                ) : (
                  <div>
                    <div>
                      <h2 className='business-info'>Business Info</h2>
                    </div>
                    <div className='top-field d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='businessName'
                          type='text'
                          placeholder='Business name'
                          required
                          className={`${errors.businessName && 'error-field'} ${
                            watchAllFields.businessName && 'filled'
                          }`}
                          register={register}
                        />
                        {!watchAllFields.businessName && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter Business Name
                          </div>
                        ) : null}
                      </div>

                      <div className='fields'>
                        <SearchDropdown
                          name='industry'
                          placeholder='Industry (Optional)'
                          isEdit={true}
                          values={industrySearch}
                          searchValue={industry}
                          setSearch={setIndustrySearch}
                          status={loadingStatus}
                          className={`${industrySearch && 'filled'}`}
                          register={register}
                          control={control}
                          loadFunction={loadIndustry}
                          setUUID={resetIndustry}
                          content={industry?.map((item: any) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              key={item.uuid}
                              onClick={() => {
                                setIndustrySearch(
                                  `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                );
                                setIndustryUuid(item?.uuid);
                              }}
                            >
                              <div>
                                <h6>
                                  {item.naicsSixDigitCode} -{' '}
                                  {item.naicsSixDigitCodeDescription}
                                </h6>
                                <p className='description'>
                                  {item.naicsFourDigitCodeDescription}
                                </p>
                              </div>
                            </div>
                          ))}
                        />
                        {industrySearch && !industryUuid && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Industry not found
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className='d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <PhoneNumberInput
                          name='businessPhoneNumber'
                          type='tel'
                          placeholder='Business PhoneNumber'
                          required
                          className={`${
                            errors.businessPhoneNumber && 'error-field'
                          } ${watchAllFields.businessPhoneNumber && 'filled'}`}
                          control={control}
                        />
                        {(!watchAllFields.businessPhoneNumber ||
                          !new RegExp(
                            /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/
                          ).test(watchAllFields.businessPhoneNumber)) &&
                        submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter Business Phone Number
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <InputField
                          name='businessEmail'
                          type='email'
                          placeholder='Business email'
                          className={`${
                            errors.businessEmail && 'error-field'
                          } ${watchAllFields.businessEmail && 'filled'}`}
                          register={register}
                        />
                        {errors.businessEmail ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.email?.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!isManual ? (
                      <div className='d-sm-flex justify-content-between'>
                        <div className='fields'>
                          <GoogleSearchInput
                            placeholder='Business address'
                            name='businessAddress'
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            inputType='PERSONAL'
                            className={`${
                              errors.businessAddress && 'error-field'
                            } ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        </div>
                        <div className='fields'>
                          <InputField
                            name='businessApt'
                            type='text'
                            placeholder='Apt'
                            className={`${
                              errors.businessApt && 'error-field'
                            } ${watchAllFields.businessApt && 'filled'}`}
                            register={register}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className='business-info'>Business address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='businessApt'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.businessApt && 'error-field'
                              } ${watchAllFields.businessApt && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div>
                  {notes &&
                    notes?.map((item: any, index: number) => {
                      return (
                        <div
                          className='d-flex align-items-center descriptionField  col'
                          key={index}
                        >
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                              textCheckFunction={setTextCheck}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {noteError ? (
                  <div className='errorVal'>
                    <img src={warning} alt='warning' className='warning' />
                    {noteError}
                  </div>
                ) : null}
              </div>
              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  onClick={() => {
                    setSubmit(true);
                  }}
                  disabled={loading || disabled}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </StyledContactModal>
      </FormModal>
    </>
  );
};

export default AddLeadDrawer;

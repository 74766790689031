import { useEffect, useState } from 'react';
import SelectField from '../../../components/CustomSelectField';
import DateField from '../../../components/CustomDateField';
import { getLostReasons, getXDateReasons } from '../../../api/reason';
import { ILostReason, IXDateReason } from '../../../interfaces/Lead';
import { StyledModal } from '../elements/styles';
import cross from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

interface IValue {
  reason: string;
  Xdate: string;
}

const LostModal = ({
  showModal,
  onCloseModal,
  action,
  setAction,
  onLoseOpp,
}: {
  showModal: boolean;
  onCloseModal: Function;
  action: string;
  setAction: Function;
  onLoseOpp: Function;
}) => {
  const [values, setValues] = useState<IValue>({ reason: '', Xdate: '' });
  const [xDate, setXDate] = useState(false);
  const [xDateReasons, setXDateReasons] = useState<IXDateReason[]>([]);
  const [lostReasons, setLostReasons] = useState<ILostReason[]>([]);
  const [errorMsge, setErrorMsge] = useState(false);
  const [errorReason, setErrorReason] = useState(false);

  const onSetItem = (name: string, value: string) => {
    const newValue = { ...values };
    name === 'Xdate' ? (newValue.Xdate = value) : (newValue.reason = value);
    setValues(newValue);
    if (name === 'Xdate' && newValue?.Xdate) {
      setErrorMsge(false);
    }
    if (name === 'reason' && newValue?.reason) {
      setErrorReason(false);
    }
  };

  useEffect(() => {
    getXDateReasons((data: IXDateReason[]) => {
      setXDateReasons(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLostReasons((data: IXDateReason[]) => {
      setLostReasons(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModal) {
      setValues({ reason: '', Xdate: '' });
      setAction('LOST');
      setXDate(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <StyledModal show={showModal} centered onHide={() => onCloseModal(false)}>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => onCloseModal(false)}
      />
      <h2>Move opportunity to lost?</h2>
      <div className='sub-heading'>Please provide a reason.</div>
      <div className='action-div'>
        What action do you want to take?
        <div
          className={`action ${action === 'LOST' ? 'active' : ''}`}
          onClick={() => {
            setAction('LOST');
            setXDate(false);
            setErrorReason(false);
          }}
        >
          <input
            type='radio'
            className='form-check-input'
            name='action'
            checked={action === 'LOST'}
            onChange={() => setAction('LOST')}
          />
          Move to lost
        </div>
        <div
          className={`action ${action === 'X-Date' ? 'active' : ''}`}
          onClick={() => {
            setAction('X-Date');
            setXDate(true);
            setErrorMsge(false);
            setErrorReason(false);
          }}
        >
          <input
            type='radio'
            className='form-check-input'
            name='action'
            checked={action === 'X-Date'}
            onChange={() => {
              setAction('X-Date');
            }}
          />
          X-Date
        </div>
      </div>
      <SelectField
        options={
          action === 'X-Date'
            ? xDateReasons.map((item) => {
                return { value: item.name, label: item.name };
              })
            : lostReasons.map((item) => {
                return { value: item.name, label: item.name };
              })
        }
        placeholder={
          action === 'X-Date'
            ? 'Reason to move back to pipeline'
            : 'Reason for losing'
        }
        name='reason'
        values={values}
        onSetItem={onSetItem}
      />
      {errorReason ? (
        <div className='errorVal d-flex align-items-center mt-1 '>
          <img src={warning} alt='warning' className='warning me-2' />
          Please select a Reason
        </div>
      ) : null}
      {xDate && (
        <>
          <div className='Xdate-fields'>
            <DateField
              name='Xdate'
              placeholder='Date to move back to pipeline'
              values={values}
              onSetItem={onSetItem}
            />
            {errorMsge ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                Please select a Date
              </div>
            ) : null}
          </div>
          <div>
            <textarea
              className='textarea'
              placeholder='Add notes here'
              autoComplete='off'
            />
          </div>
        </>
      )}
      <div className='buttonsDiv d-flex align-items-center justify-content-end'>
        <button
          onClick={() => {
            onCloseModal();
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='primaryButton'
          onClick={() => {
            if (
              (!values.reason && action === 'LOST') ||
              (!values.Xdate && action === 'X-Date' && !values.reason)
            ) {
              if (!values.reason) setErrorReason(true);
              if (!values.Xdate) setErrorMsge(true);
            } else {
              onLoseOpp(values.reason, values.Xdate);
            }
          }}
        >
          Submit <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </StyledModal>
  );
};

export default LostModal;

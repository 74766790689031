import 'react-datepicker/dist/react-datepicker.css';
import SelectField from '../SelectField';
import InputField from '../InputField';
import DateField from '../DateField';
import { CheckBoxDIv, TimeFieldContainer } from './style';
import SelectFieldEdit from '../SelectFieldEdit';
import DateFieldEdit from '../DateFieldEdit';
import InputFieldEdit from '../DetailsEditFormInput';
import TimePicker from '../TimePicker';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { FieldDiv } from '../InputField/styles';
import { FieldDiv as FieldDivEdit } from '../DetailsEditFormInput/styles';

const CustomFieldInput = ({
  item,
  register,
  className,
  control,
  value,
  isEdit,
  disabled,
  duetimeVal,
  setDueTimeVal,
}: {
  item: any;
  register: Function;
  className?: string;
  control: any;
  value: any;
  isEdit?: boolean;
  disabled?: boolean;
  duetimeVal?: any;
  setDueTimeVal?: Function;
}) => {
  const [DueTimeModal, setDueTimeModal] = useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setDueTimeModal);

  const [dueTime, setDueTime] = useState<any>('');
  useEffect(() => {
    const time = duetimeVal?.find((el: any) => el.uuid === item.uuid);
    if (time) {
      setDueTime(time.value);
    }
  }, [duetimeVal]);

  const setTimeItem = (value: any) => {
    const timeIndex = duetimeVal?.findIndex((el: any) => el.uuid === item.uuid);
    if (timeIndex >= 0) {
      let newTime = { uuid: item.uuid, value };
      let updatedTime = [...duetimeVal];

      updatedTime[timeIndex] = newTime;
      if (setDueTimeVal) setDueTimeVal(updatedTime);
    } else {
      if (setDueTimeVal)
        setDueTimeVal([...duetimeVal, { uuid: item.uuid, value }]);
    }
  };

  return (
    <>
      {isEdit ? (
        <>
          {(item.FieldType?.name === 'TEXT_INPUT' ||
            item.FieldType?.name === 'NUMBER_INPUT') && (
            <InputFieldEdit
              name={item.uuid}
              type={item.FieldType?.name === 'TEXT_INPUT' ? 'text' : 'number'}
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'CURRENCY_INPUT' && (
            <InputFieldEdit
              name={item.uuid}
              type='number'
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              prefix='$'
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'DATEPICKER' && (
            <DateFieldEdit
              name={item.uuid}
              placeholder={item.name}
              control={control}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'TIME' && (
            <TimeFieldContainer
              onClick={() => setDueTimeModal(true)}
              ref={dropRef}
              className='timeFieldContainer'
            >
              <FieldDivEdit>
                <div
                  className={`d-flex flex-column w-100 ${className} input-container ${
                    dueTime && 'filled'
                  }`}
                >
                  <input
                    id={item.uuid}
                    name={item.uuid}
                    className={`input-field`}
                    disabled={disabled}
                    readOnly
                    type='text'
                    autoComplete='off'
                    {...register(item.uuid)}
                    value={
                      dueTime
                        ? moment(dueTime.toString()).format('hh:mm A')
                        : ''
                    }
                  />
                  <label htmlFor={item.uuid} className='input-placeholder'>
                    {item.name}
                  </label>
                </div>
              </FieldDivEdit>
              {DueTimeModal && setDueTimeVal && (
                <TimePicker
                  setTaskDueTime={setDueTimeModal}
                  setDueTime={setTimeItem}
                  dueTime={dueTime || ''}
                />
              )}
            </TimeFieldContainer>
          )}
          {item.FieldType?.name === 'YES/NO_INPUT' && (
            <CheckBoxDIv className='edit-check'>
              <div className=' input-container w-100 d-flex form-appear-check h-100'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  name={item.uuid}
                  {...register(item.uuid)}
                  id={item.uuid}
                  disabled={disabled}
                />
                <label className='form-check-label' htmlFor={item.uuid}>
                  {item.name}
                </label>
              </div>
            </CheckBoxDIv>
          )}
        </>
      ) : (
        <>
          {(item.FieldType?.name === 'TEXT_INPUT' ||
            item.FieldType?.name === 'NUMBER_INPUT') && (
            <InputField
              name={item.uuid}
              type={item.FieldType?.name === 'TEXT_INPUT' ? 'text' : 'number'}
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'CURRENCY_INPUT' && (
            <InputField
              name={item.uuid}
              type='number'
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              prefix='$'
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'DATEPICKER' && (
            <DateField
              name={item.uuid}
              placeholder={item.name}
              control={control}
              value={value}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'TIME' && (
            <TimeFieldContainer
              onClick={() => setDueTimeModal(true)}
              ref={dropRef}
              className='timePicker-addForm'
            >
              <FieldDiv>
                <div
                  className={`d-flex flex-column w-100 ${className} input-container  ${
                    dueTime && 'filled'
                  }`}
                >
                  <input
                    id={item.uuid}
                    name={item.uuid}
                    className={`input-field`}
                    disabled={disabled}
                    type='text'
                    readOnly
                    autoComplete='off'
                    {...register(item.uuid)}
                    defaultValue={
                      dueTime
                        ? moment(dueTime.toString()).format('hh:mm A')
                        : ''
                    }
                  />
                  <label htmlFor={item.uuid} className='input-placeholder'>
                    {item.name}
                  </label>
                </div>
              </FieldDiv>
              {DueTimeModal && setDueTimeVal && (
                <TimePicker
                  setTaskDueTime={setDueTimeModal}
                  setDueTime={setTimeItem}
                  dueTime={dueTime || ''}
                />
              )}
            </TimeFieldContainer>
          )}
          {item.FieldType?.name === 'YES/NO_INPUT' && (
            <CheckBoxDIv className={`${value ? 'filled' : ''}`}>
              <div className=' input-container w-100 d-flex form-appear-check h-100'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  name={item.uuid}
                  {...register(item.uuid)}
                  id={item.uuid}
                  disabled={disabled}
                />
                <label className='form-check-label' htmlFor={item.uuid}>
                  {item.name}
                </label>
              </div>
            </CheckBoxDIv>
          )}
        </>
      )}

      {isEdit ? (
        <>
          {item.FieldType?.name === 'SINGLE_CHOICE' && (
            <SelectFieldEdit
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isEdit={true}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'MULTIPLE_CHOICE' && (
            <SelectFieldEdit
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isMulti={true}
              isEdit={true}
              disabled={disabled}
            />
          )}
        </>
      ) : (
        <>
          {item.FieldType?.name === 'SINGLE_CHOICE' && (
            <SelectField
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'MULTIPLE_CHOICE' && (
            <SelectField
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isMulti={true}
              disabled={disabled}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomFieldInput;

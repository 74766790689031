import styled from '@emotion/styled';

export const LeadSettingStyles = styled.div`
  padding: 23px 0 0 0;

  .settings-div {
    padding-top: 20px;
  }

  .tertiaryButton {
    background: #ecf4f9;
    color: #262758;
    margin-left: 16px;
    img {
      filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(3197%)
        hue-rotate(188deg) brightness(102%) contrast(88%);
    }
  }

  .cards-container {
    margin-top: 20px;
    padding: 2px 4px 15px 2px;
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }

    .type-card {
      background: #ffffff;
      color: #000;
      border-radius: 8px;
      width: 100%;
      margin-bottom: 12px;
      padding: 16px;
      box-shadow: 0px 1px 4px 0px #26275833;

      h6 {
        font-weight: 600;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .reason-card {
      background: #ffffff;
      color: #000;
      border-radius: 8px;
      margin: 0 0 12px 0;
      padding: 16px 24px;
      height: 64px;
      box-shadow: 0px 1px 4px 0px #26275833;

      .tertiaryButton {
        margin-right: 20px;
      }

      button {
        font-size: 14px;
        height: 32px;
        width: 119px;
      }

      h6 {
        font-weight: 600;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
      }
    }

    .title-div {
      padding-bottom: 16px;
      border-bottom: 1px solid #e6e9f0;
      height: 40px;
    }

    .card-icon {
      margin: 0 8px;
      padding: 6px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        background: #e6e9f0;
        cursor: pointer;
      }
    }

    .edit-icon {
      border-right: 1px solid #d1d6e5;
    }
  }

  .source-container {
    padding: 12px 0 20px 0;
  }

  .source-content {
    height: 211px;
    overflow: auto;
    padding-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .source-card {
    margin-bottom: 4px;
    background: #f5f7fc;
    padding: 8px 20px;
    border-radius: 8px;
    height: 48px;

    .source-title {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .addSourceButton {
    font-weight: 600;
    width: 100%;
    background: #e6e9f0;
    color: #262758;
    border-radius: 8px;
    border: 0;
    padding: 8px 20px;
    :hover {
      background: #f5f7fc;
    }
  }

  .xDateButton {
    width: 218px;
  }

  .drop-box {
    background: #f5f7fc;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    position: relative;
  }

  .dropdown-div {
    position: absolute;
    z-index: 1;
    padding: 4px 16px;
    box-shadow: 0px 1px 4px 0px #26275833;
    background: #fff;
    border-radius: 8px;
    top: 30px;
    right: 0;
    font-size: 14px;

    div {
      padding: 0 2px;
    }
  }

  .delete-div {
    color: #9a0a0d;
    border-top: 1px solid #e6e9f0;
  }

  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    border: none;
    width: 48px;
    height: 48px;
    right: 20px;
    background: #262758;
    border-radius: 50%;
    z-index: 3;

    .dropUp {
      position: absolute;
      width: 227px;
      bottom: 50px;
      z-index: 5;
      padding: 10px 12px 5px 5px;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;

      ul {
        padding: 8px 12px;
        border-bottom: 1px solid #e6e9f0;
        border-radius: 8px;
        margin: 0;
        font-weight: 600;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .addButton-background {
    position: fixed;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(35, 42, 64, 0.4);
    top: 0;
    z-index: 3;
  }
  .dropOpen {
    .addButton-img {
      transform: rotate(45deg);
    }
    img {
      filter: invert(1);
    }
    background: #f5f7fc;
  }

  @media screen and (min-width: 576px) {
    padding: 8px 0 0 0;

    .cards-container {
      .type-card {
        width: 32.4%;
        margin: 0 12px 12px 0;
      }

      .type-card:nth-child(3n) {
        margin-right: 0;
      }

      .reason-card {
        box-shadow: none;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 20px 0 0 0;

    .settings-div {
      padding-top: 23px;
    }

    .tertiaryButton {
      margin-left: 20px;
    }

    .cards-container {
      margin-top: 32px;
      padding: 0 5px 0 0;

      .type-card {
        width: 32.15%;
        margin: 0 20px 20px 0;
        padding: 23px 30px;
        box-shadow: none;
      }

      .reason-card {
        padding: 16px 20px 16px 24px;
      }

      .title-div {
        height: 49px;
      }
    }

    .xDateButton {
      width: 232px;
    }
  }

  @media screen and (min-width: 1900px) {
    padding: 8px 0 0 0;

    .settings-div {
      padding-top: 32px;
    }

    .cards-container {
      .type-card {
        width: 24.15%;
        margin-right: 17px;
      }

      .type-card:nth-child(3n) {
        margin-right: 17px;
      }

      .type-card:nth-child(4n) {
        margin-right: 0;
      }

      .reason-card {
        padding: 16px 32px 16px 32px;
      }
    }

    .addButton {
      width: 244px;
    }
  }
`;

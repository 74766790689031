import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideBarDiv } from './styles';
import Link, { LinkImage } from '../Link';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { selectUser, SliceState } from '../../store/user/userSlice';
import QuickAccountDrawer from '../../pages/MyBook/Accounts/components/QuickAddDrawer';
import QuickContactFrom from '../../pages/MyBook/Contacts/components/QuickAddForm';
import QuickAddOpportunityModal from '../QuickAddOpportunity';
import QuickAddAgency from '../../pages/Admin/AgencyList/components/AgencyModal';
import AddLeadDrawer from '../AddLeadDrawer';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import AddQuoteModal from '../AddQuoteModal';
import AddOpportunityDrawer from '../AddOpportunityDrawer';
import logoIIR from '../../assets/logo/logo.svg';
import plus from '../../assets/logo/plus.svg';
import vector from '../../assets/logo/dropdown.svg';
import {
  activities,
  activitiesActive,
  dashboard,
  dashboardActive,
  manage,
  manageActive,
  mybook,
  mybookActive,
  settings,
  settingsActive,
  team,
  teamActive,
} from '../../assets/nav-icons';
import Skeleton from 'react-loading-skeleton';
import {
  MY_BOOK_MENU_LIST,
  QUICK_MENU_LIST_ADMIN,
  QUICK_MENU_LIST_AGENT,
} from '../../utils/SidebarConstants';

function SideBar() {
  const location = useLocation();
  const user = useSelector(selectUser);
  const loadingStatus = useSelector(
    (state: { user: SliceState }) => state.user.status
  );

  const [showQuickAddDropDown, setShowQuickAddDropDown] = useState(false);
  const [addQuickContact, setAddQuickContact] = useState('closed');
  const [longOpportunityForm, setLongOpportunityForm] = useState('closed');
  const [addQuickAccount, setAddQuickAccount] = useState('closed');
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const [addQuickAddAgency, setAddQuickAddAgency] = useState('closed');
  const [addQuickAddLead, setAddQuickAddLead] = useState('closed');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMyBook, setMyBook] = useState(false);
  const [quickAddPolicy, setQuickAddPolicy] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');

  const dropRef = useRef(null);
  const navigate = useNavigate();
  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, setShowDropdown);
  useOutsideClick(dropRef, setShowQuickAddDropDown);

  return (
    <SideBarDiv>
      {loadingStatus === 'idle' || loadingStatus === 'loading' ? (
        <>
          {[...Array(6)].map((value, index) => (
            <Skeleton key={value} className='w-100 my-2' height={50} />
          ))}
        </>
      ) : (
        <>
          <div className='LogoDiv'>
            <img src={logoIIR} className='logo w-100' alt='logo' />
          </div>
          <div className='d-sm-flex justify-content-between'>
            <button
              className='quickAddButton'
              onClick={() => setShowQuickAddDropDown(!showQuickAddDropDown)}
              ref={dropRef}
            >
              <img src={plus} alt='' />

              {showQuickAddDropDown
                ? [
                    user?.UserRole.name === 'ADMIN' ? (
                      <div className='quickFormsDropDown admin-quickAdd'>
                        {QUICK_MENU_LIST_ADMIN.map((quickMenu) => (
                          <div
                            className='quickFormBtn'
                            onClick={() => {
                              setAddQuickAddAgency('open');
                            }}
                          >
                            {quickMenu.name}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='quickFormsDropDown'>
                        {QUICK_MENU_LIST_AGENT.map((quickMenu, index) => (
                          <div
                            key={`${quickMenu.name} ${quickMenu.order}`}
                            className={
                              index === QUICK_MENU_LIST_AGENT.length - 1
                                ? 'quickFormBtn lastQuickFormBtn'
                                : ' quickFormBtn'
                            }
                            onClick={() => {
                              switch (quickMenu.name) {
                                case 'Add Account':
                                  setAddQuickAccount('open');
                                  break;
                                case 'Add Contact':
                                  setAddQuickContact('open');
                                  break;
                                case 'Add Lead':
                                  setAddQuickAddLead('open');
                                  break;
                                case 'Add Opportunity':
                                  setAddQuickOpportunity('open');
                                  break;
                                case 'Add Policy':
                                  setQuickAddPolicy('open');
                                  break;
                                default:
                                  setShowQuoteModal('open');
                              }
                            }}
                          >
                            <img
                              className='imageIcon'
                              src={quickMenu.icon}
                              alt='icon'
                            />{' '}
                            &nbsp; {quickMenu.name}
                          </div>
                        ))}
                      </div>
                    ),
                  ]
                : null}
            </button>
            <div className='quickadd-text'>
              <h5 className='nav-text'>Quick add</h5>
            </div>
          </div>

          {user?.UserRole.name === 'ADMIN' ? (
            <>
              <Link to='/admin'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='admin'
                    activeIcon={manageActive}
                    inactiveIcon={manage}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>Management</h5>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/dashboard'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='dashboard'
                    activeIcon={dashboardActive}
                    inactiveIcon={dashboard}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>Dashboard</h5>
                  </div>
                </div>
              </Link>
              <Link to='/activities'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='activities/'
                    activeIcon={activitiesActive}
                    inactiveIcon={activities}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>Tasks</h5>
                  </div>
                </div>
              </Link>
              <div
                onClick={() => setMyBook(true)}
                className={`mybook-navdiv ${isMyBook ? 'active-div' : ''}`}
              >
                <div
                  onClick={() => {
                    setMyBook(true);
                    setShowDropdown(!showDropdown);
                  }}
                  className=' vector-nav d-sm-flex justify-content-between'
                  ref={dropDownRef}
                >
                  <LinkImage
                    activeIcon={mybookActive}
                    inactiveIcon={mybook}
                    name='mybook'
                    className='nav-logo d-block'
                  />
                  <div className='nav-textdiv justify-content-between'>
                    <h5 className='nav-text'>My Book</h5>
                    <img
                      className={`vector-img ${
                        showDropdown ? 'vector-rotate' : ''
                      }`}
                      src={vector}
                      alt='vector'
                    />
                  </div>
                  {showDropdown && (
                    <div className='drop-Div'>
                      {MY_BOOK_MENU_LIST.map((myBookMenu) => (
                        <div
                          key={`${myBookMenu.name} ${myBookMenu.order}`}
                          onClick={() => {
                            navigate(myBookMenu.path);
                            setShowDropdown(myBookMenu.isShowDropdown);
                          }}
                          className={`mybook-drop ${
                            location?.pathname === myBookMenu.path
                              ? 'active-div'
                              : ''
                          }`}
                        >
                          <LinkImage
                            activeIcon={myBookMenu.activeIcon}
                            inactiveIcon={myBookMenu.inactiveIcon}
                            name={myBookMenu.path}
                            className='image-drop'
                          />
                          {myBookMenu.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <Link to='/pipeline'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='pipeline'
                    activeIcon={manageActive}
                    inactiveIcon={manage}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>Sales pipeline</h5>
                  </div>
                </div>
              </Link>
            </>
          )}
          {user?.UserRole.name === 'PRINCIPAL_AGENT' ? (
            <>
              <Link to='/myteam'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='myteam'
                    activeIcon={teamActive}
                    inactiveIcon={team}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>My Team</h5>
                  </div>
                </div>
              </Link>
              <Link to='/settings'>
                <div className='d-sm-flex justify-content-between'>
                  <LinkImage
                    className='nav-logo d-block'
                    name='settings'
                    activeIcon={settingsActive}
                    inactiveIcon={settings}
                  />
                  <div className='nav-textdiv'>
                    <h5 className='nav-text'>Settings</h5>
                  </div>
                </div>
              </Link>
            </>
          ) : null}
        </>
      )}

      {addQuickAccount !== 'closed' && (
        <QuickAccountDrawer
          show={addQuickAccount}
          closeModal={setAddQuickAccount}
        />
      )}
      {addQuickContact !== 'closed' && (
        <QuickContactFrom
          show={addQuickContact}
          closeModal={setAddQuickContact}
        />
      )}
      {addQuickOpportunity !== 'closed' && (
        <QuickAddOpportunityModal
          show={addQuickOpportunity}
          setLongOpportunityForm={setLongOpportunityForm}
          closeModal={setAddQuickOpportunity}
        />
      )}

      {addQuickAddAgency !== 'closed' && (
        <QuickAddAgency
          show={addQuickAddAgency}
          closeModal={setAddQuickAddAgency}
        />
      )}
      {longOpportunityForm !== 'closed' && (
        <AddOpportunityDrawer
          show={longOpportunityForm}
          closeModal={setLongOpportunityForm}
        />
      )}
      {addQuickAddLead !== 'closed' && (
        <AddLeadDrawer show={addQuickAddLead} closeModal={setAddQuickAddLead} />
      )}
      {quickAddPolicy !== 'closed' && (
        <PolicyModal show={quickAddPolicy} closeModal={setQuickAddPolicy} />
      )}
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Contact'
          page='quickQuote'
        />
      )}
    </SideBarDiv>
  );
}

export default SideBar;

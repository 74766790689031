import { useEffect, useState } from 'react';
import edit from '../../../../assets/logo/greyEdit.svg';
import CustomeEditGoalField from './CustomeEditGoalField';

const GoalDetailsEditCard = ({
  agent,
  setUptedGoals,
  updatedGoals,
  index,
}: {
  agent: any;
  setUptedGoals: Function;
  updatedGoals: any[];
  index: number;
}) => {
  const [premiumGoalAmount, setPremiumGoalAmount] = useState({});
  const [policyGoalCount, setPolicyGoalCount] = useState({});
  useEffect(() => {
    if (agent?.Goals?.length > 0) {
      setPremiumGoalAmount({
        premiumGoalAmount: agent.Goals?.filter(
          (item: any) => item.GoalTypes?.name === 'PREMIUM'
        )[0]?.value,
      });
      setPolicyGoalCount({
        policyGoalCount: agent.Goals?.filter(
          (item: any) => item.GoalTypes?.name === 'POLICY'
        )[0]?.value,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const onSet = (
    name: string,
    value: string,
    uuid: string,
    policyGoalUUID: string,
    premiumGoalUUID: string
  ) => {
    if (name === 'premiumGoalAmount') {
      setPremiumGoalAmount({ premiumGoalAmount: value });
    } else {
      setPolicyGoalCount({ policyGoalCount: value });
    }

    // setPolicyGoalCount;()

    if (
      updatedGoals.findIndex((item: any) => item.agentUUID === agent.uuid) > -1
    ) {
      if (name === 'premiumGoalAmount') {
        let itemIndex = updatedGoals.findIndex(
          (item: any) => item.agentUUID === agent.uuid
        );

        updatedGoals[itemIndex]['premiumGoalAmount'] = value ? value : '';
        updatedGoals[itemIndex]['premiumGoalUUID'] = premiumGoalUUID
          ? premiumGoalUUID
          : '';
      } else {
        let itemIndex = updatedGoals.findIndex(
          (item: any) => item.agentUUID === agent.uuid
        );

        updatedGoals[itemIndex]['policyGoalCount'] = value ? value : '';
        updatedGoals[itemIndex]['policyGoalUUID'] = policyGoalUUID
          ? policyGoalUUID
          : '';
      }
    } else {
      setUptedGoals([
        ...updatedGoals,
        {
          agentUUID: uuid,
          policyGoalUUID: policyGoalUUID ? policyGoalUUID : '',
          premiumGoalUUID: premiumGoalUUID ? premiumGoalUUID : '',
          premiumGoalAmount: name === 'premiumGoalAmount' ? value : '',
          policyGoalCount: name === 'policyGoalCount' ? value : '',
        },
      ]);
    }
  };

  return (
    <div className='ListDetails d-flex  align-items-center justify-content-between'>
      <span className='col-2'>
        {agent.User?.firstName + ' ' + agent.User?.lastName}
      </span>
      <div className='inLineFIeld d-flex align-items-center col-6 '>
        <span className='prefixDollar'>$</span>
        <div className='inputField'>
          <CustomeEditGoalField
            type='number'
            name='premiumGoalAmount'
            values={premiumGoalAmount}
            placeholder='Not set'
            onSetItem={onSet}
            userUUID={agent?.uuid}
            premiumGoalUUID={
              agent.Goals?.filter(
                (item: any) => item.GoalTypes?.name === 'PREMIUM'
              )[0]?.uuid
            }
          />
        </div>
        <img src={edit} alt='' className='editDesc' />
      </div>
      <div className='inLineFIeld d-flex align-items-center col-5'>
        <div className='inputField'>
          <CustomeEditGoalField
            type='number'
            name='policyGoalCount'
            values={policyGoalCount}
            placeholder='Not set'
            onSetItem={onSet}
            userUUID={agent?.uuid}
            policyGoalUUID={
              agent.Goals?.filter(
                (item: any) => item.GoalTypes?.name === 'POLICY'
              )[0]?.uuid
            }
          />
        </div>
        <img src={edit} alt='' className='editDesc smalledit' />
      </div>
    </div>
  );
};

export default GoalDetailsEditCard;

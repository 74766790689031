import { useEffect } from 'react';

export function useOutsideClick(
  ref: any,
  setShowDropdown: Function,
  secondRef?: {
    ref: any;
    setShow: Function;
  }
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (secondRef) {
        if (
          secondRef.ref.current &&
          !secondRef.ref.current.contains(event.target)
        ) {
          secondRef.setShow(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

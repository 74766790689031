import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { getAgents } from '../../store/agents/agentsSlice';
import SideBar from '../../components/SideBar';
import AgencyDetails from './AgencyDetails';
import AgencySettings from '../AgencySettings';
import AgencyList from './AgencyList';
import ManagePage from './Manage';
import PipelineMange from './PipelineMange';
import Carrier from './Carrier';
import LeadSettings from '../LeadSettings';
import MobileTabNav from '../../components/MobileTabNav';
import LocalStorageService from '../../utils/LocalStorageService';
import { AdminDiv } from '../../styles/AdminManage';
import AgencySettingsPage from './AgencySettingsPage';
import LocationSettings from './LocationSettings';
import GoalSettings from './GoalSettings';
import CustomFields from './CustomFieldSettings';
import PolicySettings from './PolicySettings';
function Admin() {
  const user = LocalStorageService.getUser();
  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <Routes>
        <Route path='/agency' element={<AgencyList />} />
        <Route path='/agency/:uuid/*' element={<Settings />} />
        <Route path='/agency/:uuid' element={<AgencySettings />} />
        <Route path='/agency/:uuid/user' element={<AgencyDetails />} />
        <Route path='/agency/:uuid/pipeline' element={<PipelineMange />} />
        <Route path='/agency/:uuid/carrier' element={<Carrier />} />
        <Route path='/agency/:uuid/goal' element={<GoalSettings />} />
        <Route path='/agency/:uuid/customfield/*' element={<CustomFields />} />
        <Route path='/agency/:uuid/policy' element={<PolicySettings />} />
        <Route
          path={'/agency/:uuid/settings/location'}
          element={<LocationSettings />}
        />
        <Route path='/agency/:uuid/settings' element={<AgencySettingsPage />} />

        <Route path='/' element={<ManagePage />} />
        <Route path='*' element={<Navigate to='/admin' replace />} />
      </Routes>
      <div className='d-xl-none'>
        <MobileTabNav />
      </div>
    </AdminDiv>
  );
}

export default Admin;

const Settings = () => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const user = LocalStorageService.getUser();

  useEffect(() => {
    if (user?.UserRole.name === 'ADMIN') {
      dispatch(
        getAgents({
          agencyUUID: uuid,
          orderBy: '',
          sortBy: '',
          searchBy: '',
          limit: 1,
          offset: 0,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uuid]);

  return (
    <Routes>
      <Route path='/user' element={<AgencyDetails />} />
      <Route path='/pipeline' element={<PipelineMange />} />
      <Route path='/carrier/*' element={<Carrier />} />
      <Route path='/leads/*' element={<LeadSettings />} />
      <Route path='/policy/*' element={<PolicySettings />} />
      <Route path='/' element={<AgencySettings />} />
    </Routes>
  );
};

import axios from './axios';

const fetchSearchResuls = (
  types: string[],
  searchBy: string,
  limit: number,
  offset: number
) => {
  return axios
    .get('/search', {
      params: {
        query: searchBy,
        types,
        count: limit,
        offset,
        // sortBy: 'createdByDesc',
      },
    })
    .then((res) => {
      return res;
    });
};

export { fetchSearchResuls };

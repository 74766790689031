import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
const SalesAnalytics = ({
  isMobile,
  data,
}: {
  isMobile: boolean;
  data: any;
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        drawBorder: false,
      },
      y: {
        stacked: false,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any) {
            return '$' + Number((value / 1000).toString()) + 'K';
          },
        },
        grid: {
          borderDash: [5, 5],
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const graphData = {
    labels: data?.map((item: any) => item?.name),
    datasets: [
      {
        maxBarThickness: 112,
        backgroundColor: '#cad9f3',
        data: data?.map((item: any) => item.premium),
      },
    ],
  };

  return (
    <div style={{ overflowX: 'auto', paddingBottom: '5px' }}>
      <div style={{ height: '400px', minWidth: '550px' }}>
        <Bar options={options} data={graphData} />
      </div>
    </div>
  );
};

export default SalesAnalytics;

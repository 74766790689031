import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SuccessAlign } from '../../styles/SuccessAlign';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import warning from '../../assets/logo/warning.svg';
import InputField from '../InputField';
import PhoneNumberInput from '../PhoneNumberFormat';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import Modal from '../Modal';
import {
  getAllLocations,
  selectAllLocations,
  LocationSliceState,
} from '../../store/location/locationSlice';
import { AppDispatch } from '../../store';
import {
  AgentsSliceState,
  updateUserDetail,
} from '../../store/agents/agentsSlice';
import LocalStorageService from '../../utils/LocalStorageService';
import { StyledModal } from '../../pages/Admin/elements/styles';
import { fetchUserRoles } from '../../api/agents';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;

const AgentSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Entered phone number is not valid.')
    .required('Please enter your phone number'),
  userRole: Yup.object().shape({
    value: Yup.string().required('Please select a Role'),
  }),
  locationUUID: Yup.object().shape({
    value: Yup.string().required('Please select a Location'),
  }),
  email: Yup.string()
    .email('Entered email is not valid.')
    .required('Please enter your email '),
  firstName: Yup.string().max(20, 'First name cannot exceed 20 characters'),
  middleName: Yup.string().max(20, 'Middle name cannot exceed 20 characters'),
  lastName: Yup.string()
    .max(20, 'Last name cannot exceed 20 characters')
    .required('Please enter your last name'),
});

const EditUser = ({
  show,
  closeModal,
  data,
}: {
  show: string;
  closeModal: Function;
  data: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgentSchema),
  });

  const watchAllFields = watch();

  const loadingStatus = useSelector(
    (state: { locations: LocationSliceState }) => state.locations?.status
  );

  const locations = useSelector(selectAllLocations);

  const options = [
    // { label: 'Admin', value: 'ADMIN', disabled: false },
    { label: 'Agent', value: 'AGENT', disabled: false },
    { label: 'Principal Agent', value: 'PRINCIPAL_AGENT', disabled: false },
  ];
  const [userRoleOp, setUserRoles] = useState<any>();
  useEffect(() => {
    fetchUserRoles((data: any) => {
      setUserRoles(data);
    });
  }, []);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const loading = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.isLoading
  );

  const user = LocalStorageService.getUser();

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (user?.UserRole.name === 'ADMIN' && uuid) {
        dispatch(
          getAllLocations({
            minimal: 'True',
            agencyUUID: uuid,
            successCB: (data: any) => {},
          })
        );
      } else {
        dispatch(
          getAllLocations({
            minimal: 'True',
            successCB: (data: any) => {},
          })
        );
      }
    }
  }, []);

  const onSubmit = (value: any) => {
    value.userRole = value.userRole.value;
    value.locationUUID = value.locationUUID.value;
    value.agencyUUID = uuid;
    dispatch(
      updateUserDetail({
        uuid: data.User?.uuid,
        data: value,
        successCB: () => {
          closeModal('closing');
          setTimeout(() => {
            setSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 4000);
        },
        errorCB: (error: any) => {
          error.data.message ===
            `Couldn't complete action. Please try again.` &&
            setEmailError('Email already in use');
        },
      })
    );
  };

  useEffect(() => {
    setValue('firstName', data?.User?.firstName || '');
    setValue('middleName', data?.User?.middleName || '');
    setValue('lastName', data?.User?.lastName);
    setValue('email', data?.User?.email);
    setValue('phoneNumber', data?.User?.phoneNumber);
  }, [data]);

  const nameConverted = (name: string) => {
    let convertedName = name?.replace('_', ' ').toLowerCase().split(' ');
    for (let i = 0; i < convertedName?.length; i++) {
      convertedName[i] =
        convertedName[i]?.charAt(0).toUpperCase() +
        convertedName[i]?.substring(1);
    }
    return convertedName?.join(' ');
  };

  useEffect(() => {
    setValue('locationUUID', {
      label: locations.find((el: any) => el.id === data?.LocationId)?.name,
      value: locations.find((el: any) => el.id === data?.LocationId)?.uuid,
    });
  }, [locations, data]);

  useEffect(() => {
    if (data?.User?.UserRole?.id) {
      setValue('userRole', {
        label: nameConverted(data?.User?.UserRole?.name),
        value: data?.User?.UserRole?.uuid,
      });
    } else {
      setValue('userRole', {
        label: nameConverted(
          userRoleOp?.find((el: any) => el.id === data?.User.roleId).name
        ),
        value: userRoleOp?.find((el: any) => el.id === data?.User.roleId).uuid,
      });
    }
  }, [data, userRoleOp]);
  return (
    <>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'User updated'}
            message={`<b>${getValues('firstName')} ${getValues(
              'lastName'
            )}</b> has been successfully updated.`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div>
            <h2>Edit a user</h2>
          </div>
          <div>
            <p className='subhead'>Provide some basic details about the user</p>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='firstName'
                        type='text'
                        placeholder='First Name'
                        className={`${errors.firstName && 'error-field'} ${
                          watchAllFields.firstName && 'filled'
                        }`}
                      />
                      {errors.firstName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.firstName?.message}
                        </div>
                      ) : null}
                    </div>

                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='middleName'
                        type='text'
                        placeholder='Middle Name'
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                      />
                      {errors.middleName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='lastName'
                        type='text'
                        placeholder='Last Name'
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                      />
                      {errors.lastName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='email'
                        type='email'
                        placeholder='Email'
                        disabled
                        className={`${errors.email && 'error-field'} ${
                          watchAllFields.email && 'filled'
                        }`}
                      />
                      {errors.email || emailError ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message || emailError}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <PhoneNumberInput
                        control={control}
                        name='phoneNumber'
                        type='tel'
                        placeholder='Phone Number'
                        className={`${errors.phoneNumber && 'error-field'} ${
                          watchAllFields.phoneNumber && 'filled'
                        }`}
                      />
                      {errors.phoneNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={locations.map((item) => {
                          return {
                            label: item?.name,
                            value: item.uuid,
                          };
                        })}
                        name='locationUUID'
                        placeholder='Location'
                        control={control}
                      />
                      {errors.locationUUID ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.locationUUID?.message
                            ? 'Please select a location'
                            : errors.locationUUID?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={options}
                        name='userRole'
                        placeholder='Select a role'
                        control={control}
                        disabled
                      />
                      {errors.userRole ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.userRole?.message
                            ? 'Please select a role'
                            : errors.userRole?.value?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      disabled={
                        disabled ||
                        (data?.User?.firstName === watchAllFields.firstName &&
                          data?.User?.middleName ===
                            watchAllFields.middleName &&
                          data?.User?.lastName === watchAllFields.lastName &&
                          data?.User?.email === watchAllFields.email &&
                          data?.User?.phoneNumber ===
                            watchAllFields.phoneNumber &&
                          locations.find(
                            (el: any) => el.id === data?.LocationId
                          )?.uuid === getValues('locationUUID').value)
                      }
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default EditUser;

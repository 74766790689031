import { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { selectCount } from '../../store/accounts/accountsSlice';
import { selectOpportunitiesCount } from '../../store/opportunities/opportunitiesSlice';
import { selectContactCount } from '../../store/contacts/contactsSlice';
import { selectPolicyCount } from '../../store/policies/policiesSlice';
import {
  createEvent,
  createNote,
  createTask,
  updateNoteDetails,
} from '../../store/tasks/tasksSlice';
import { getCount as accountCount } from '../../store/accounts/accountsSlice';
import { getCount as contactCount } from '../../store/contacts/contactsSlice';
import { getCount as opportunityCount } from '../../store/opportunities/opportunitiesSlice';
import { getCount as policyCount } from '../../store/policies/policiesSlice';
import Link from '../Link';
import OpportunityInnerTab from '../OpportunityInnerTabs';
import Policies from '../PolicyInnerTab';
import Quotes from '../QuotesInnerTab';
import AccountsInnertab from '../AccountInnerTab';
import Documents from '../DocumentsInnerTab';
import TasksTab from '../ActivitiesInnerTab';
import HistoryTab from '../HistoryInnerTab';

import { StyledTab } from '../../styles/StyledTab';

const DetailsPageTab = ({
  name,
  isMobile,
}: {
  name: string;
  isMobile?: boolean;
}) => {
  const location = useLocation();
  const { uuid } = useParams();
  const accountDetailsCount = useSelector(selectCount);
  const contactDetailsCount = useSelector(selectContactCount);
  const opportunityDetailsCount = useSelector(selectOpportunitiesCount);
  const PolicyCount = useSelector(selectPolicyCount);
  const dispatch = useDispatch<AppDispatch>();
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [added, setAdded] = useState('');
  const [edited, setEdited] = useState('');
  const [ApiSuccess, setApiSuccess] = useState(false);

  const [file, setFile] = useState<any>();
  const [docTypeUUID, setDocTypeUUID] = useState('');
  const handleAddNote = (value: any) => {
    if (name === 'Opportunity') {
      setAddNoteLoading(true);
      value.opportunityUUID = uuid;
      dispatch(
        createNote({
          data: value,
          successCB: () => {
            dispatch(opportunityCount({ uuid: value.opportunityUUID }));
            setAddNoteLoading(false);
            setAdded('Note');
            setTimeout(() => {
              setAdded('');
            }, 2700);
          },
          errorCB: (err: any) => {
            setAddNoteLoading(false);
            setAdded('');
          },
        })
      );
    } else if (name === 'Account') {
      setAddNoteLoading(true);
      value.accountUUID = uuid;
      dispatch(
        createNote({
          data: value,
          successCB: () => {
            dispatch(accountCount({ uuid: value.accountUUID }));
            setAdded('Note');
            setAddNoteLoading(false);
            setTimeout(() => {
              setAdded('');
            }, 2700);
          },
          errorCB: (err: any) => {
            setAddNoteLoading(false);
            setAdded('');
          },
        })
      );
    } else if (name === 'Policy') {
      setAddNoteLoading(true);
      value.policyUUID = uuid;
      dispatch(
        createNote({
          data: value,
          successCB: () => {
            dispatch(policyCount({ uuid: value.policyUUID }));
            setAdded('Note');
            setTimeout(() => {
              setAdded('');
            }, 2700);
          },
          errorCB: (err: any) => {
            setAddNoteLoading(false);
            setAdded('');
          },
        })
      );
    } else if (name === 'Contact') {
      setAddNoteLoading(true);
      value.contactUUID = uuid;
      dispatch(
        createNote({
          data: value,
          successCB: () => {
            dispatch(contactCount({ uuid: value.contactUUID }));
            setAdded('Note');
            setTimeout(() => {
              setAdded('');
            }, 2700);
            setAddNoteLoading(false);
          },
          errorCB: (err: any) => {
            setAddNoteLoading(false);
            setAdded('');
          },
        })
      );
    }
  };
  const handleUpdateNote = (UUID: any, data: any, uuid: string) => {
    if (name === 'Opportunity') {
      data.opportunityUUID = uuid;

      setAddNoteLoading(true);
      dispatch(
        updateNoteDetails({
          uuid: UUID,
          data: data,
          successCB: () => {
            dispatch(opportunityCount({ uuid }));
            setEdited('Note');
            setAddNoteLoading(false);
            setTimeout(() => {
              setEdited('');
            }, 2700);
          },
        })
      );
    }
    if (name === 'Account') {
      data.accountUUID = uuid;

      setAddNoteLoading(true);
      dispatch(
        updateNoteDetails({
          uuid: UUID,
          data: data,
          successCB: () => {
            dispatch(accountCount({ uuid: data.accountUUID }));
            setAddNoteLoading(false);
            setEdited('Note');
            setTimeout(() => {
              setEdited('');
            }, 2700);
          },
        })
      );
    }
    if (name === 'Policy') {
      data.policyUUID = uuid;

      setAddNoteLoading(true);
      dispatch(
        updateNoteDetails({
          uuid: UUID,
          data: data,
          successCB: () => {
            setAddNoteLoading(false);
            dispatch(policyCount({ uuid }));
            setEdited('Note');
            setTimeout(() => {
              setEdited('');
            }, 2700);
          },
        })
      );
    }
    if (name === 'Contact') {
      data.contactUUID = uuid;

      setAddNoteLoading(true);
      dispatch(
        updateNoteDetails({
          uuid: UUID,
          data: data,
          successCB: () => {
            dispatch(contactCount({ uuid }));
            setAddNoteLoading(false);
            setEdited('Note');
            setTimeout(() => {
              setEdited('');
            }, 2700);
          },
        })
      );
    }
  };

  const handleAddTask = (value: any) => {
    if (uuid) {
      setApiSuccess(true);
      let formData = new FormData();
      if (name === 'Opportunity') {
        formData.append('opportunityUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          formData.append(key, value[key]);
        }

        setAddNoteLoading(true);
        dispatch(
          createTask({
            data: formData,
            successCB: () => {
              dispatch(opportunityCount({ uuid }));
              setAdded('Task');
              setApiSuccess(false);
              setAddNoteLoading(false);
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Account') {
        setAddNoteLoading(true);
        formData.append('accountUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          formData.append(key, value[key]);
        }
        dispatch(
          createTask({
            data: formData,
            successCB: () => {
              dispatch(accountCount({ uuid }));
              setApiSuccess(false);
              setAdded('Task');
              setAddNoteLoading(false);
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Policy') {
        setAddNoteLoading(true);
        formData.append('policyUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          formData.append(key, value[key]);
        }
        dispatch(
          createTask({
            data: formData,
            successCB: () => {
              dispatch(policyCount({ uuid }));
              setAdded('Task');
              setApiSuccess(false);
              setAddNoteLoading(false);
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Contact') {
        setAddNoteLoading(true);
        formData.append('contactUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          formData.append(key, value[key]);
        }
        dispatch(
          createTask({
            data: formData,
            successCB: () => {
              dispatch(contactCount({ uuid }));
              setApiSuccess(false);
              setAddNoteLoading(false);
              setAdded('Task');
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      }
    }
  };

  const handleAddEvent = (value: any) => {
    if (uuid) {
      setApiSuccess(true);
      let formData = new FormData();
      if (name === 'Opportunity') {
        formData.append('opportunityUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          if (key === 'guests') {
            formData.append(key, JSON.stringify(value[key]));
          } else {
            formData.append(key, value[key]);
          }
        }

        setAddNoteLoading(true);
        dispatch(
          createEvent({
            data: formData,
            successCB: () => {
              dispatch(opportunityCount({ uuid }));
              setApiSuccess(false);
              setAddNoteLoading(false);
              setAdded('Event');
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Account') {
        setAddNoteLoading(true);
        formData.append('accountUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          if (key === 'guests') {
            formData.append(key, JSON.stringify(value[key]));
          } else {
            formData.append(key, value[key]);
          }
        }
        dispatch(
          createEvent({
            data: formData,
            successCB: () => {
              dispatch(accountCount({ uuid }));
              setApiSuccess(false);
              setAddNoteLoading(false);
              setAdded('Event');
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Policy') {
        setAddNoteLoading(true);
        formData.append('policyUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          if (key === 'guests') {
            formData.append(key, JSON.stringify(value[key]));
          } else {
            formData.append(key, value[key]);
          }
        }
        dispatch(
          createEvent({
            data: formData,
            successCB: () => {
              dispatch(policyCount({ uuid }));
              setApiSuccess(false);
              setAddNoteLoading(false);
              setAdded('Event');
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      } else if (name === 'Contact') {
        setAddNoteLoading(true);
        formData.append('contactUUID', uuid);
        formData.append('docFile', file);
        formData.append('resource', name);
        formData.append('resourceUUID', uuid);
        formData.append('documentTypeUUID', docTypeUUID);
        for (let key in value) {
          if (key === 'guests') {
            formData.append(key, JSON.stringify(value[key]));
          } else {
            formData.append(key, value[key]);
          }
        }
        dispatch(
          createEvent({
            data: formData,
            successCB: () => {
              dispatch(contactCount({ uuid }));
              setApiSuccess(false);
              setAddNoteLoading(false);
              setAdded('Event');
              setTimeout(() => {
                setAdded('');
              }, 2700);
            },
            errorCB: () => {
              setAddNoteLoading(false);
              setAdded('');
            },
          })
        );
      }
    }
  };

  const updatedCount = () => {
    if (uuid) {
      if (name === 'Opportunity') {
        dispatch(opportunityCount({ uuid }));
      }
      if (name === 'Account') {
        dispatch(accountCount({ uuid }));
      }
      if (name === 'Policy') {
        dispatch(policyCount({ uuid }));
      }
      if (name === 'Contact') {
        dispatch(contactCount({ uuid }));
      }
    }
  };
  return (
    <>
      <StyledTab className='d-none d-sm-block'>
        {name === 'Contact' && (
          <>
            <Link to={`/contacts/${uuid}/activities`}>
              Activities
              <span className='count'>{contactDetailsCount.activities}</span>
            </Link>
            <Link to={`/contacts/${uuid}/opportunities`}>
              Opportunities
              <span className='count'>{contactDetailsCount.opportunities}</span>
            </Link>
            <Link to={`/contacts/${uuid}/accounts`}>
              Accounts
              <span className='count'>{contactDetailsCount.accounts}</span>
            </Link>
            <Link to={`/contacts/${uuid}/quotes`}>
              Quotes
              <span className='count'>{contactDetailsCount.quotes}</span>
            </Link>
            <Link to={`/contacts/${uuid}/policies`}>
              Policies
              <span className='count'>{contactDetailsCount.policies}</span>
            </Link>
            <Link to={`/contacts/${uuid}/documents`}>
              Documents
              <span className='count'>{contactDetailsCount?.documents}</span>
            </Link>
            <Link to={`/contacts/${uuid}/histories`}>History</Link>
          </>
        )}
        {name === 'Account' && (
          <>
            <Link to={`/account/${uuid}/activities`}>
              Activities
              <span className='count'>{accountDetailsCount.activities}</span>
            </Link>
            <Link to={`/account/${uuid}/opportunities`}>
              Opportunities
              <span className='count'>{accountDetailsCount.opportunities}</span>
            </Link>
            <Link to={`/account/${uuid}/quotes`}>
              Quotes
              <span className='count'>{accountDetailsCount.quotes}</span>
            </Link>
            <Link to={`/account/${uuid}/policies`}>
              Policies
              <span className='count'>{accountDetailsCount.policies}</span>
            </Link>
            <Link to={`/account/${uuid}/documents`}>
              Documents
              <span className='count'>{accountDetailsCount.documents}</span>
            </Link>
            <Link to={`/account/${uuid}/histories`}>History</Link>
          </>
        )}
        {name === 'Opportunity' && (
          <>
            <Link to={`/opportunities/${uuid}/activities`}>
              Activities
              <span className='count'>
                {opportunityDetailsCount.activities}
              </span>
            </Link>

            <Link to={`/opportunities/${uuid}/quotes`}>
              Quotes
              <span className='count'>{opportunityDetailsCount.quotes}</span>
            </Link>

            <Link to={`/opportunities/${uuid}/documents`}>
              Documents
              <span className='count'>{opportunityDetailsCount.documents}</span>
            </Link>
            <Link to={`/opportunities/${uuid}/histories`}>History</Link>
          </>
        )}
        {name === 'Lead' && (
          <>
            <Link
              to={`/lead/activities`}
              onClick={(e: any) => e.preventDefault()}
            >
              Activities
              <span className='count'>0</span>
            </Link>

            <Link to={`/lead/${uuid}`} onClick={(e: any) => e.preventDefault()}>
              Quotes
              <span className='count'>0</span>
            </Link>

            <Link to={`/lead/${uuid}`} onClick={(e: any) => e.preventDefault()}>
              Documents
              <span className='count'>{0}</span>
            </Link>
            {/* <Link to={`/lead/${uuid}/histories`}>History</Link> */}
          </>
        )}
        {name === 'Policy' && (
          <>
            <Link to={`/policy/${uuid}/activities`}>
              Activities
              <span className='count'>{PolicyCount.activities}</span>
            </Link>
            <Link to={`/policy/${uuid}/documents`}>
              Documents
              <span className='count'>{PolicyCount.documents}</span>
            </Link>
            <Link to={`/policy/${uuid}/histories`}>History</Link>
          </>
        )}
      </StyledTab>

      <Routes>
        <Route
          path='/activities'
          element={
            <TasksTab
              file={file}
              setFile={setFile}
              setDocTypeUUID={setDocTypeUUID}
              docTypeUUID={docTypeUUID}
              addNote={handleAddNote}
              addNoteLoading={addNoteLoading}
              addTask={handleAddTask}
              addEvent={handleAddEvent}
              ApiSuccess={ApiSuccess}
              name={name}
              updateNote={handleUpdateNote}
              added={added}
              edited={edited}
              setEdited={setEdited}
              uuid={uuid}
              isMobile={isMobile}
            />
          }
        />
        <Route
          path='/accounts'
          element={
            <AccountsInnertab getCount={updatedCount} isMobile={isMobile} />
          }
        />
        <Route
          path='/quotes'
          element={
            <Quotes
              pageName={name}
              isMobile={isMobile}
              getCount={updatedCount}
            />
          }
        />
        <Route
          path='/policies'
          element={
            <Policies name={name} isMobile={isMobile} getCount={updatedCount} />
          }
        />
        <Route
          path='/documents'
          element={
            <Documents
              name={name}
              uuid={uuid ? uuid : ''}
              isMobile={isMobile}
            />
          }
        />
        <Route
          path='/histories'
          element={<HistoryTab resourceName={name} isMobile={isMobile} />}
        />

        <Route
          path='/opportunities'
          element={
            <OpportunityInnerTab
              name={name}
              isMobile={isMobile}
              getCount={updatedCount}
            />
          }
        />

        <Route
          path='*'
          element={
            <Navigate
              to={`${location.pathname
                .split('/')
                .slice(0, 3)
                .join('/')}/activities`}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

export default DetailsPageTab;

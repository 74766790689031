import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AppDispatch } from '../../../../store';
import {
  createPolicy,
  PoliciesSliceState,
} from '../../../../store/policies/policiesSlice';
import { getCount } from '../../../../store/book/bookSlice';
import { getCustomFields } from '../../../../store/customFields/customFieldsSlice';
import {
  clearAccountPolicies,
  getAccountPolicies,
  getCount as getCountAccount,
} from '../../../../store/accounts/accountsSlice';
import {
  clearContactPolicies,
  getContactPolicies,
  getCount as getCountContact,
} from '../../../../store/contacts/contactsSlice';
import { fetchContacts, validatePhoneNumber } from '../../../../api/contact';
import { fetchProducts } from '../../../../api/product';
import { fetchIndustries } from '../../../../api/industry';
import { fetchAccounts } from '../../../../api/account';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import SuccessCard from '../../../../components/SuccessCard';
import Modal from '../../../../components/EditDetailsModal';
import SearchDropdown from '../../../../components/SearchDropdown';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import SelectField from '../../../../components/SelectField';
import DateField from '../../../../components/DateField';
import AddPolicyInfo from './AddPolicyInfo';
import MultiSelect from '../../../../components/MultipleSelectField';
import CustomFieldInput from '../../../../components/CustomfieldsInput';
import { IAccount } from '../../../../interfaces/Account';
import { IProduct } from '../../../../interfaces/Product';
import {
  StyledAddModal,
  SelectedContactCard,
  ProgressInfo,
} from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import warning from '../../../../assets/logo/warning.svg';
import rightArrow from '../../../../assets/logo/right-arrow.svg';
import close from '../../../../assets/logo/cross.svg';

const AddPolicyModal = ({
  show,
  closeModal,
  account,
  primaryContact,
  name,
  contactDetails,
  innerTabName,
  accountType,
}: {
  show: string;
  closeModal: Function;
  account?: IAccount;
  primaryContact?: any;
  name?: string;
  contactDetails?: any;
  innerTabName?: string;
  accountType?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [showSuccess, setshowSuccess] = useState(false);
  const watchAllFields = watch();

  const watchPhoneNumber = watch('phoneNumber');
  const watchBusinessPhoneNumber = watch('businessPhoneNumber');

  const [commercial, isCommercial] = useState(false);
  const [addressObject, setAddressObject] = useState<any>({});

  const navigate = useNavigate();

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const [contacts, setContacts] = useState<any[]>([]);
  const [contactSearch, setContactSearch] = useState('');
  const [selectedContact, setSelectedContact] = useState<any>({});

  const [businessContacts, setBusinessContacts] = useState<any[]>([]);
  const [businessContactSearch, setBusinessContactSearch] = useState('');
  const [selectedBusinessContact, setSelectedBusinessContact] = useState<any>(
    {}
  );

  const [addContact, setAddContact] = useState(false);
  const [nameError, setNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [businessPhoneNumberError, setBusinessPhoneNumberError] = useState('');

  const [addBusinessContact, setAddBusinessContact] = useState(false);

  const [showPolicyInfo, setShowPolicyInfo] = useState(false);
  const [DocumentExist, setDocumentExist] = useState(false);
  const [policyIndex, setPolicyIndex] = useState(0);

  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const watchLastName = watch('lastName');
  const [disabled, setDisabled] = useState(false);
  const [accountCustomFields, setAccountCustomFields] = useState<any>();
  const [contactCustomFields, setContactCustomFields] = useState<any>();
  const [policyCustomFields, setPolicyCustomFields] = useState<any>();
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    if (phoneNumberError) {
      setPhoneNumberError('');
    }
  }, [watchPhoneNumber]);

  useEffect(() => {
    setBusinessPhoneNumberError('');
  }, [watchBusinessPhoneNumber]);

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (industrySearch) {
        fetchIndustries(
          (data: any) => {
            setIndustry(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setIndustry([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadEmail = (value: string) => {
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loading = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies.isLoading
  );

  const loadContact = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (contactSearch) {
        fetchContacts(
          'id',
          'asc',
          contactSearch,
          25,
          0,
          'True',
          (data: any) => {
            setContacts(data);
            setLoadingStatus('succeeded');
          },
          commercial ? 'COMMERCIAL' : 'PERSONAL'
        );
      } else {
        setContacts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadBusinessContact = (value: string, contactUUID: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (businessContactSearch) {
        fetchAccounts(
          'name',
          'asc',
          value,
          25,
          0,
          commercial ? 'COMMERCIAL' : 'PERSONAL',
          (data: any) => {
            setBusinessContacts(data);
            setLoadingStatus('succeeded');
          },
          'True',
          contactUUID
        );
      } else {
        setBusinessContacts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [products, setProducts] = useState<IProduct[]>([]);

  const [selectedPolicies, setSelectedPolicies] = useState<any>([]);

  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchProducts(
        (data: IProduct[]) => {
          setProducts(data);
          setLoadingStatus('succeeded');
        },
        '',
        '',
        '',
        '',
        true,
        commercial ? 'COMMERCIAL' : 'PERSONAL'
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial]);

  const onSubmit = async (data: any) => {
    let contactCustomField: any = {};
    let accountCustomField: any = {};
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );

    if (!watchLastName) {
      setNameError('Last name is required');
    }
    if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    }

    if (!showPolicyInfo) {
      if (
        !phoneNumberError &&
        !businessPhoneNumberError &&
        (selectedContact?.uuid || watchAllFields.lastName?.length > 0) &&
        (!commercial ||
          Object.keys(selectedBusinessContact).length > 0 ||
          (watchAllFields.businessName?.length > 0 &&
            watchAllFields.payroll >= 0)) &&
        ((addContact &&
          emailSearch &&
          watchAllFields.lastName.length > 0 &&
          new RegExp(/[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/).test(
            data.phoneNumber
          )) ||
          Object.keys(selectedContact)?.length > 0) &&
        (!commercial ||
          (addBusinessContact &&
            data.businessEmail &&
            new RegExp(/[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/).test(
              data.businessPhoneNumber
            )) ||
          Object.keys(selectedBusinessContact)?.length > 0) &&
        !emailExist
      ) {
        let validPhone = false;
        let validBusinessPhone = false;
        if (addContact) {
          await validatePhoneNumber(
            data.phoneNumber,
            () => {
              validPhone = true;
            },
            (err: any) => {
              setPhoneNumberError(err);
            }
          );
        }
        if (addBusinessContact) {
          await validatePhoneNumber(
            data.businessPhoneNumber,
            () => {
              validBusinessPhone = true;
            },
            (err: any) => {
              setBusinessPhoneNumberError(err);
            }
          );
        }
        if (
          (!addBusinessContact && !addContact) ||
          (addContact &&
            validPhone &&
            addBusinessContact &&
            validBusinessPhone) ||
          (addContact && validPhone && !addBusinessContact) ||
          (addBusinessContact && validBusinessPhone && !addContact)
        ) {
          setShowPolicyInfo(true);
        }
      }
      setSelectedPolicies(data.policies);
    } else {
      setTimeout(() => {
        if (addContact) {
          data.email = emailSearch;
        }
        if (addBusinessContact) {
          data.industry = industryUuid;
        }
        contactCustomFields.map((item: any) => {
          if (item.uuid in data) {
            if (item.FieldType?.name === 'TIME') {
              let timeItem = duetimeVal.find(
                (el: any) => el.uuid === item.uuid
              );
              if (timeItem) contactCustomField[item.uuid] = timeItem.value;
            } else {
              contactCustomField[item.uuid] = data[item.uuid];
            }
          }
        });
        accountCustomFields.map((item: any) => {
          if (item.uuid in data) {
            if (item.FieldType?.name === 'TIME') {
              let timeItem = duetimeVal.find(
                (el: any) => el.uuid === item.uuid
              );
              if (timeItem) accountCustomField[item.uuid] = timeItem.value;
            } else {
              accountCustomField[item.uuid] = data[item.uuid];
            }
          }
        });
        data.contactUUID = selectedContact.uuid;
        data.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';
        if (commercial) {
          data.accountUUID = commercial ? selectedBusinessContact.uuid : null;
        }
        // data.policies = selectedPolicies;
        data.maritalStatus = data.maritalStatus?.value;
        // // To add addressObject with the form
        if (!isManual) {
          data = Object.assign(data, addressObject);
        }
        data.isManuallyAdded = isManual;
        let formData = new FormData();
        for (let [key, value] of Object.entries(data)) {
          let val: any = value;
          if (key === 'policies' || val === undefined || val === null) continue;
          formData.append(key, val);
        }
        formData.append('policies', JSON.stringify(selectedPolicies));
        formData.append(
          'contactCustomFields',
          JSON.stringify(contactCustomField)
        );
        formData.append(
          'accountCustomFields',
          JSON.stringify(accountCustomField)
        );
        selectedPolicies.map((obj: any) => {
          if (DocumentExist) {
            for (let [key, value] of Object.entries(obj.files)) {
              let val: any = value;
              formData.append(key, val);
            }
          }
        });
        setDisabled(true);
        dispatch(
          createPolicy({
            data: formData,
            successCB: () => {
              dispatch(getCount());

              closeModal('closing');
              setTimeout(() => {
                setshowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
              if (innerTabName === 'Account' && account) {
                dispatch(clearAccountPolicies());
                dispatch(getCountAccount({ uuid: account?.uuid }));
                dispatch(
                  getAccountPolicies({
                    uuid: account?.uuid,
                    offset: 0,
                  })
                );
              }
              if (innerTabName === 'Contact' && contactDetails) {
                dispatch(clearContactPolicies());
                dispatch(getCountContact({ uuid: contactDetails?.uuid }));
                dispatch(
                  getContactPolicies({
                    uuid: contactDetails?.uuid,
                    offset: 0,
                  })
                );
              }
            },
            errorCB: (error: string) => {
              setDisabled(false);
            },
          })
        );
      }, 500);
    }
  };
  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setEmailAccounts([]);
  };

  const resetContact = (value?: string) => {
    setSelectedContact({});
    setContacts([]);
  };

  const resetBusinessContact = (value?: string) => {
    setSelectedBusinessContact({});
    setBusinessContacts([]);
  };

  useEffect(() => {
    setAddressObject({});
    setBusinessContacts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial]);

  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];

  useEffect(() => {
    if (selectedContact?.uuid) {
      loadBusinessContact('', selectedContact?.uuid);
      setValue(
        'dateOfBirth',
        selectedContact?.dateOfBirth
          ? moment(selectedContact?.dateOfBirth).toDate()
          : null
      );
      if (selectedContact?.maritalStatus) {
        setValue('maritalStatus', {
          label: selectedContact?.maritalStatus,
          value: selectedContact?.maritalStatus,
        });
      }
      if (selectedContact?.AccountContacts?.length > 0) {
        setValue(
          'address',
          selectedContact?.AccountContacts[0]?.Account?.Address?.city
        );
        setValue(
          'apartment',
          selectedContact?.AccountContacts[0]?.Account?.Address?.street
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  useEffect(() => {
    setValue('employeeCount', selectedBusinessContact?.employeeCount);
    setValue(
      'businessStartedYear',
      selectedBusinessContact.businessStartedYear
    );
    setValue('payroll', selectedBusinessContact.payroll);
    setValue('annualRevenue', selectedBusinessContact.annualRevenue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessContact]);
  useEffect(() => {
    setShowPolicyInfo(false);
    setAddBusinessContact(false);
    setAddContact(false);
    setSelectedContact({});
    setSelectedBusinessContact({});
    setValue('policies', null);
    setSelectedPolicies([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial]);

  useEffect(() => {
    if (
      innerTabName === 'Account' &&
      primaryContact &&
      !commercial &&
      name &&
      accountType
    ) {
      setContactSearch(
        `${primaryContact.Contact.firstName}  ${primaryContact.Contact.lastName}`
      );
      setValue('firstName', primaryContact?.Contact?.firstName);
      setContacts([primaryContact?.Contact]);
      setSelectedContact(primaryContact?.Contact);
      setValue(
        'dateOfBirth',
        primaryContact?.Contact?.dateOfBirth
          ? moment(primaryContact?.Contact?.dateOfBirth).toDate()
          : null
      );
      if (primaryContact?.Contact?.maritalStatus) {
        setValue('maritalStatus', {
          label: primaryContact?.Contact?.maritalStatus,
          value: primaryContact?.Contact?.maritalStatus,
        });
      }
      if (accountType === 'COMMERCIAL') {
        isCommercial(true);
      } else if (accountType === 'PERSONAL') {
        isCommercial(false);
      }
    } else if (innerTabName === 'Account' && account && commercial) {
      setBusinessContactSearch(account?.name);
      setBusinessContacts([account]);
      setSelectedBusinessContact(account);
      setValue('employeeCount', selectedBusinessContact?.employeeCount);
      setValue(
        'businessStartedYear',
        selectedBusinessContact.businessStartedYear
      );
      setValue('payroll', selectedBusinessContact.payroll);
      setValue('annualRevenue', selectedBusinessContact.annualRevenue);
      setContactSearch('');
      setSelectedContact({});
    } else if (
      innerTabName === 'Contact' &&
      contactDetails &&
      !commercial &&
      name
    ) {
      setValue('firstName', contactDetails?.firstName);
      setContactSearch(name);
      setContacts([contactDetails]);
      setSelectedContact(contactDetails);
      setValue(
        'dateOfBirth',
        selectedContact?.dateOfBirth
          ? moment(selectedContact?.dateOfBirth).toDate()
          : null
      );
      if (selectedContact?.maritalStatus) {
        setValue('maritalStatus', {
          label: selectedContact?.maritalStatus,
          value: selectedContact?.maritalStatus,
        });
      }
    } else if (
      innerTabName === 'Contact' &&
      contactDetails &&
      commercial &&
      name
    ) {
      setContactSearch(name);
      setSelectedContact(contactDetails);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial, businessContactSearch]);

  const onClickSubmit = () => {
    setSubmit(true);
  };

  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'POLICY',
        isAddForm: 'true',
        successCB: (data: any) => {
          setPolicyCustomFields(data);
        },
      })
    );
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'CONTACT',
        isAddForm: 'true',
        successCB: (data: any) => {
          setContactCustomFields(data);
        },
      })
    );
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'ACCOUNT',
        isAddForm: 'true',
        successCB: (data: any) => {
          setAccountCustomFields(data);
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    selectedPolicies.map((el: any, index: number) => {
      let newPolicy = Object.assign({}, el);
      let updatedPolicy = [...selectedPolicies];
      policyCustomFields.map((item: any) => {
        if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
          newPolicy[item.uuid] = moment(item.data?.defaultValue).toDate();
        }
        if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
          const filteredData: any = item.data.options.filter(
            (it: any) => it.default === true
          );
          newPolicy[item.uuid] = filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          });
        }
        if (item.FieldType?.name === 'SINGLE_CHOICE') {
          if (item.data?.defaultValue) {
            newPolicy[item.uuid] = {
              label:
                item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
              value:
                item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
            };
          }
        }
        updatedPolicy[index] = newPolicy;
        setSelectedPolicies(updatedPolicy);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyCustomFields]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);

  const resetIndustry = (value: string) => {
    setIndustry([]);
    setIndustryUuid('');
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding Policy'}
            message={`<b class='name-text-overflow'>${
              selectedContact?.firstName
                ? selectedContact?.firstName
                : selectedBusinessContact?.name
                ? selectedBusinessContact?.name
                : 'new Policy '
            }</b> has added a Policy<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show}>
        <StyledAddModal className='policyModal'>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                {showPolicyInfo ? (
                  <h2
                    onClick={() =>
                      policyIndex > 0
                        ? setPolicyIndex(policyIndex - 1)
                        : setShowPolicyInfo(false)
                    }
                  >
                    <img className='backArrow' src={rightArrow} alt='' /> Add
                    Policy
                  </h2>
                ) : (
                  <h2>Add Policy</h2>
                )}
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex navButtons'>
                <button
                  onClick={() => isCommercial(false)}
                  disabled={accountType === 'COMMERCIAL'}
                  className={
                    !commercial
                      ? 'naVigatorbuttonsActive secondaryButton'
                      : 'naVigatorbuttons secondaryButton'
                  }
                >
                  Personal
                </button>
                <div className='commercial-text'>
                  <button
                    onClick={() => isCommercial(true)}
                    disabled={accountType === 'PERSONAL'}
                    className={
                      commercial
                        ? 'naVigatorbuttonsActive secondaryButton'
                        : 'naVigatorbuttons secondaryButton'
                    }
                  >
                    Commercial
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex policy-form-container'>
              <ProgressInfo>
                <div
                  className={showPolicyInfo ? 'completed' : 'progress'}
                ></div>
                <p>BASIC INFO</p>
                {!showPolicyInfo ? (
                  <>
                    <div className='mid-line'></div>
                    <div className='next-level'></div>
                    <p>POLICY INFO</p>
                  </>
                ) : (
                  selectedPolicies.map((item: any, index: number) => (
                    <>
                      <div className='mid-line'></div>
                      <div
                        className={
                          policyIndex === index
                            ? 'progress'
                            : policyIndex < index
                            ? 'next-level'
                            : 'completed'
                        }
                      ></div>
                      <p>{item.label}</p>
                    </>
                  ))
                )}
              </ProgressInfo>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='add-policy-form'
              >
                {!showPolicyInfo ? (
                  <>
                    <div className='scroll-bar  '>
                      {commercial ? (
                        <>
                          <div>
                            <h2 className='contact-info'>
                              Business Information
                            </h2>
                          </div>
                          {!addBusinessContact ? (
                            <>
                              <div className='top-field d-sm-flex justify-content-between'>
                                <div className='fields'>
                                  <SearchDropdown
                                    name='business'
                                    addName={true}
                                    placeholder='Select a business'
                                    isEdit={true}
                                    values={businessContactSearch}
                                    searchValue={
                                      businessContacts?.length > 0
                                        ? businessContacts
                                        : ['']
                                    }
                                    setSearch={setBusinessContactSearch}
                                    status={loadingStatus}
                                    className={`${
                                      businessContactSearch && 'filled'
                                    }`}
                                    register={register}
                                    control={control}
                                    required
                                    disabled={
                                      primaryContact?.primaryContact &&
                                      commercial
                                    }
                                    type='text'
                                    setUUID={resetBusinessContact}
                                    loadFunction={loadBusinessContact}
                                    content={
                                      <>
                                        {businessContacts?.map((item) => (
                                          <div
                                            className='searchValuesCard d-flex align-items-center account-search-card'
                                            onClick={() => {
                                              setBusinessContactSearch('');
                                              setBusinessContacts([]);
                                              setSelectedBusinessContact(item);
                                            }}
                                          >
                                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                                              {item?.name?.charAt(0)}
                                            </div>
                                            <div>
                                              <div className='text-overflow-container'>
                                                <h6 className='name-text-overflow'>
                                                  {item.name}
                                                </h6>
                                                <span className='tooltiptext'>
                                                  <span className='tooltipInnertext'>
                                                    {item.name}
                                                  </span>
                                                </span>
                                              </div>
                                              <ul className='user-details'>
                                                <li>{item?.businessEmail}</li>
                                                <li>
                                                  {item?.businessPhoneNumber}
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ))}
                                        <div
                                          className='searchValuesCard d-flex align-items-center'
                                          onClick={() =>
                                            setAddBusinessContact(true)
                                          }
                                        >
                                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                                            +
                                          </div>
                                          <div>
                                            <h6>Add new business</h6>
                                          </div>
                                        </div>
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                              {Object.keys(selectedBusinessContact)?.length >
                              0 ? (
                                <>
                                  <div className='d-sm-flex justify-content-between'>
                                    <div className='fields '>
                                      <SelectedContactCard className='d-flex align-items-center'>
                                        <div className='profile-pic d-flex align-items-center justify-content-center'>
                                          {selectedBusinessContact?.name?.charAt(
                                            0
                                          )}
                                        </div>
                                        <div className='accountDisplay contact-card-div'>
                                          <h6 className='name-text-overflow'>
                                            {selectedBusinessContact?.name}
                                          </h6>
                                          <ul className='user-details'>
                                            <li>
                                              {selectedBusinessContact?.businessEmail
                                                ? selectedBusinessContact.businessEmail
                                                : '---'}
                                            </li>
                                            <li>
                                              {
                                                selectedBusinessContact?.businessPhoneNumber
                                              }
                                            </li>
                                          </ul>
                                        </div>
                                        <img
                                          src={close}
                                          alt=''
                                          className='close-button'
                                          onClick={() => resetBusinessContact()}
                                        />
                                      </SelectedContactCard>
                                    </div>
                                  </div>
                                  <div className='d-sm-flex justify-content-between'>
                                    <div className='fields dateField'>
                                      <InputField
                                        name='employeeCount'
                                        type='number'
                                        placeholder='Number of employees'
                                        register={register}
                                        className={`${
                                          errors.employeeCount && 'error-field'
                                        } ${
                                          watchAllFields.employeeCount &&
                                          'filled'
                                        }`}
                                      />
                                    </div>
                                    <div className='fields'>
                                      <InputField
                                        name='businessStartedYear'
                                        type='number'
                                        placeholder='Year business started'
                                        register={register}
                                        className={`${
                                          errors.businessStartedYear &&
                                          'error-field'
                                        } ${
                                          watchAllFields.businessStartedYear &&
                                          'filled'
                                        }`}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div className=' d-sm-flex justify-content-between'>
                                      <div className='fields'>
                                        <InputField
                                          name='payroll'
                                          type='number'
                                          min={0}
                                          placeholder='Total payroll'
                                          register={register}
                                          className={`${
                                            errors.payroll && 'error-field'
                                          } ${
                                            watchAllFields.payroll && 'filled'
                                          }`}
                                          prefix='$'
                                        />
                                        {watchAllFields.payroll < 0 ? (
                                          <div className='errorVal'>
                                            <img
                                              src={warning}
                                              alt='warning'
                                              className='warning'
                                            />
                                            Please enter a valid Amount
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className='fields'>
                                        <InputField
                                          name='annualRevenue'
                                          type='number'
                                          placeholder='Annual revenue'
                                          register={register}
                                          className={`${
                                            errors.annualRevenue &&
                                            'error-field'
                                          } ${
                                            watchAllFields.annualRevenue &&
                                            'filled'
                                          }`}
                                          prefix='$'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <div className='top-field d-sm-flex justify-content-between'>
                                <div className='fields'>
                                  <InputField
                                    name='businessName'
                                    type='text'
                                    placeholder='Business name'
                                    register={register}
                                    required
                                    className={`${
                                      errors.businessName && 'error-field'
                                    } ${
                                      watchAllFields.businessName && 'filled'
                                    }`}
                                  />
                                  {watchAllFields.businessName?.length < 1 &&
                                  submit ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      Please enter business name
                                    </div>
                                  ) : null}
                                </div>
                                <div className='fields'>
                                  <SearchDropdown
                                    name='industry'
                                    placeholder='Industry (Optional)'
                                    isEdit={true}
                                    values={industrySearch}
                                    searchValue={industry}
                                    setSearch={setIndustrySearch}
                                    status={loadingStatus}
                                    className={`${
                                      industrySearch &&
                                      !industryUuid &&
                                      submit &&
                                      'error-field'
                                    } ${industrySearch && 'filled'}`}
                                    register={register}
                                    control={control}
                                    loadFunction={loadIndustry}
                                    setUUID={resetIndustry}
                                    content={industry?.map((item: any) => (
                                      <div
                                        className='searchValuesCard d-flex align-items-center'
                                        onClick={() => {
                                          setIndustrySearch(
                                            `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                          );
                                          setIndustryUuid(item?.uuid);
                                        }}
                                      >
                                        <div>
                                          <h6>
                                            {item.naicsSixDigitCode} -{' '}
                                            {item.naicsSixDigitCodeDescription}
                                          </h6>
                                          <p className='description'>
                                            {item.naicsFourDigitCodeDescription}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  />
                                  {industrySearch && !industryUuid && submit ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      Industry not found
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <div className='d-sm-flex justify-content-between'>
                                <div className='fields'>
                                  <PhoneNumberInput
                                    name='businessPhoneNumber'
                                    type='tel'
                                    placeholder='Business PhoneNumber'
                                    className={`${
                                      errors.businessPhoneNumber &&
                                      'error-field'
                                    } ${
                                      watchAllFields.businessPhoneNumber &&
                                      'filled'
                                    }`}
                                    required
                                    control={control}
                                  />
                                  {(!watchAllFields.businessPhoneNumber ||
                                    !new RegExp(
                                      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/
                                    ).test(
                                      watchAllFields.businessPhoneNumber
                                    )) &&
                                  submit ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      Please enter Business Phone Number
                                    </div>
                                  ) : businessPhoneNumberError ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      {businessPhoneNumberError}
                                    </div>
                                  ) : null}
                                </div>

                                <div className='fields'>
                                  <InputField
                                    type='email'
                                    placeholder='Business email'
                                    register={register}
                                    className={`${
                                      errors.businessEmail && 'error-field'
                                    } ${
                                      watchAllFields.businessEmail && 'filled'
                                    }`}
                                    required
                                    name='businessEmail'
                                  />
                                  {!watchAllFields.businessEmail && submit ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      Please enter business email
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div>
                                {!isManual ? (
                                  <div className=' d-sm-flex justify-content-between'>
                                    <div className='fields'>
                                      <GoogleSearchInput
                                        placeholder='Business Address'
                                        name='address'
                                        inputType='PERSONAL'
                                        setAddressObject={setAddressObject}
                                        addressObject={addressObject}
                                        className={`${
                                          errors.address && 'error-field'
                                        } ${
                                          Object.keys(addressObject)?.length >
                                            0 && 'filled'
                                        }`}
                                        setIsManual={setIsManual}
                                      ></GoogleSearchInput>
                                    </div>
                                    <div className='fields'>
                                      <InputField
                                        name='businessApt'
                                        type='text'
                                        placeholder='Apt'
                                        register={register}
                                        className={`${
                                          errors.businessApt && 'error-field'
                                        } ${
                                          watchAllFields.businessApt && 'filled'
                                        }`}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <h2 className='contact-info'>
                                      Business address
                                    </h2>
                                    <div className=' d-sm-flex justify-content-between'>
                                      <div className='fields'>
                                        <InputField
                                          name='address'
                                          type='text'
                                          placeholder='Address'
                                          register={register}
                                          className={`${
                                            errors.address && 'error-field'
                                          } ${
                                            watchAllFields.address && 'filled'
                                          }`}
                                        />
                                      </div>
                                      <div className='fields'>
                                        <InputField
                                          name='zipCode'
                                          type='number'
                                          placeholder='Zipcode'
                                          register={register}
                                          className={`${
                                            errors.zipCode && 'error-field'
                                          } ${
                                            watchAllFields.zipCode && 'filled'
                                          }`}
                                        />
                                      </div>
                                    </div>
                                    <div className=' d-sm-flex justify-content-between'>
                                      <div className='fields'>
                                        <InputField
                                          name='city'
                                          type='text'
                                          placeholder='City'
                                          register={register}
                                          className={`${
                                            errors.city && 'error-field'
                                          } ${watchAllFields.city && 'filled'}`}
                                        />
                                      </div>
                                      <div className='fields'>
                                        <InputField
                                          name='state'
                                          type='text'
                                          placeholder='State'
                                          register={register}
                                          className={`${
                                            errors.state && 'error-field'
                                          } ${
                                            watchAllFields.state && 'filled'
                                          }`}
                                        />
                                      </div>
                                    </div>
                                    <div className=' d-sm-flex justify-content-between'>
                                      <div className='fields'>
                                        <InputField
                                          name='country'
                                          type='text'
                                          placeholder='County'
                                          register={register}
                                          className={`${
                                            errors.country && 'error-field'
                                          } ${
                                            watchAllFields.country && 'filled'
                                          }`}
                                        />
                                      </div>
                                      <div className='fields'>
                                        <InputField
                                          name='businessApt'
                                          type='text'
                                          placeholder='Apt'
                                          register={register}
                                          className={`${
                                            errors.businessApt && 'error-field'
                                          } ${
                                            watchAllFields.businessApt &&
                                            'filled'
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className=' d-sm-flex justify-content-between'>
                                <div className='fields'>
                                  <InputField
                                    name='payroll'
                                    type='text'
                                    placeholder='Total payroll'
                                    register={register}
                                    min={0}
                                    className={`${
                                      errors.payroll && 'error-field'
                                    } ${watchAllFields.payroll && 'filled'}`}
                                    prefix='$'
                                  />
                                  {watchAllFields.payroll < 0 ? (
                                    <div className='errorVal'>
                                      <img
                                        src={warning}
                                        alt='warning'
                                        className='warning'
                                      />
                                      Please enter a valid Amount
                                    </div>
                                  ) : null}
                                </div>
                                <div className='fields'>
                                  <InputField
                                    name='annualRevenue'
                                    type='text'
                                    placeholder='Annual revenue'
                                    register={register}
                                    className={`${
                                      errors.annualRevenue && 'error-field'
                                    } ${
                                      watchAllFields.annualRevenue && 'filled'
                                    }`}
                                    prefix='$'
                                  />
                                </div>
                              </div>
                              {accountCustomFields?.length > 0
                                ? accountCustomFields?.map(
                                    (item: any, index: number) =>
                                      index % 2 === 0 ? (
                                        <div className='d-sm-flex justify-content-between'>
                                          <div className='fields'>
                                            <CustomFieldInput
                                              item={item}
                                              register={register}
                                              control={control}
                                              value={watchAllFields[item.uuid]}
                                              setDueTimeVal={setDueTimeVal}
                                              duetimeVal={duetimeVal}
                                            />
                                          </div>
                                          {accountCustomFields?.length >
                                          index + 1 ? (
                                            <div className='fields'>
                                              <CustomFieldInput
                                                item={
                                                  accountCustomFields[index + 1]
                                                }
                                                register={register}
                                                control={control}
                                                value={
                                                  watchAllFields[
                                                    accountCustomFields[
                                                      index + 1
                                                    ].uuid
                                                  ]
                                                }
                                                setDueTimeVal={setDueTimeVal}
                                                duetimeVal={duetimeVal}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null
                                  )
                                : null}
                            </>
                          )}
                        </>
                      ) : null}
                      <div>
                        <h2 className='contact-info'>Customer Information</h2>
                      </div>
                      {!addContact ? (
                        <>
                          <div className='top-field d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <SearchDropdown
                                name='contact'
                                addName={true}
                                placeholder='Select a contact'
                                isEdit={true}
                                values={contactSearch}
                                searchValue={
                                  contacts?.length > 0 ? contacts : ['']
                                }
                                setSearch={setContactSearch}
                                status={loadingStatus}
                                className={`${contactSearch && 'filled'}`}
                                register={register}
                                control={control}
                                required
                                disabled={
                                  (primaryContact?.primaryContact &&
                                    !commercial) ||
                                  (innerTabName === 'Contact' && contactDetails)
                                }
                                type='text'
                                setUUID={resetContact}
                                loadFunction={loadContact}
                                content={
                                  <>
                                    {contacts?.map((item) => (
                                      <div
                                        className='searchValuesCard d-flex align-items-center account-search-card'
                                        onClick={() => {
                                          setContactSearch('');
                                          setContacts([]);
                                          setSelectedContact(item);
                                        }}
                                      >
                                        <div className='profile-pic d-flex align-items-center justify-content-center'>
                                          {item?.firstName?.charAt(0)}
                                        </div>
                                        <div>
                                          <div className='text-overflow-container'>
                                            <h6 className='name-text-overflow'>
                                              {item?.firstName} {item?.lastName}
                                            </h6>
                                            <span className='tooltiptext'>
                                              <span className='tooltipInnertext'>
                                                {item?.firstName}{' '}
                                                {item?.lastName}
                                              </span>
                                            </span>
                                          </div>

                                          <ul className='user-details'>
                                            <li>
                                              {
                                                item?.ContactDetails?.find(
                                                  (value: any) =>
                                                    value.type === 'EMAIL'
                                                )?.value
                                              }
                                            </li>
                                            <li>
                                              {
                                                item?.ContactDetails?.find(
                                                  (value: any) =>
                                                    value.type === 'PHONENUMBER'
                                                )?.value
                                              }
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                    <div
                                      className='searchValuesCard d-flex align-items-center'
                                      onClick={() => setAddContact(true)}
                                    >
                                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                                        +
                                      </div>
                                      <div>
                                        <h6>Add new contact</h6>
                                      </div>
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </div>
                          {Object.keys(selectedContact)?.length > 0 ? (
                            <>
                              <div className='d-sm-flex justify-content-between'>
                                <div className='fields'>
                                  <SelectedContactCard className='d-flex align-items-center'>
                                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                                      {selectedContact?.firstName?.charAt(0)}
                                    </div>
                                    <div className='contact-card-div'>
                                      <h6 className='name-text-overflow'>
                                        {selectedContact?.firstName}{' '}
                                        {selectedContact?.lastName}
                                      </h6>
                                      <ul className='user-details'>
                                        <li>
                                          {
                                            selectedContact?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'EMAIL'
                                            )?.value
                                          }
                                        </li>
                                        <li>
                                          {
                                            selectedContact?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'PHONENUMBER'
                                            )?.value
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                    {(primaryContact?.primaryContact &&
                                      !commercial) ||
                                      (innerTabName !== 'Contact' &&
                                        !contactDetails && (
                                          <img
                                            src={close}
                                            alt=''
                                            className='close-button'
                                            onClick={() => resetContact()}
                                          />
                                        ))}
                                  </SelectedContactCard>
                                </div>
                              </div>
                              <div className='d-sm-flex justify-content-between'>
                                <div className='fields dateField'>
                                  <DateField
                                    name='dateOfBirth'
                                    placeholder='Date Of Birth'
                                    maxDate={new Date()}
                                    dateOfBirth
                                    control={control}
                                    value={watchAllFields.dateOfBirth}
                                  />
                                </div>
                                <div className='fields'>
                                  <SelectField
                                    options={MaritalStatus}
                                    name='maritalStatus'
                                    placeholder='Marital status'
                                    control={control}
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className='top-field d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <InputField
                                name='firstName'
                                type='text'
                                placeholder='First name'
                                register={register}
                                className={`${
                                  errors.firstName && 'error-field'
                                } ${watchAllFields.firstName && 'filled'}`}
                              />
                            </div>
                            <div className='small-div d-sm-flex justify-content-between'>
                              <div className='fields-small'>
                                <InputField
                                  name='middleName'
                                  type='text'
                                  placeholder='Middle name'
                                  register={register}
                                  className={`${
                                    errors.middleName && 'error-field'
                                  } ${watchAllFields.middleName && 'filled'}`}
                                />
                              </div>
                              <div className='fields-small'>
                                <InputField
                                  name='lastName'
                                  type='text'
                                  placeholder='Last name'
                                  register={register}
                                  required
                                  className={`${
                                    errors.lastName && 'error-field'
                                  } ${watchAllFields.lastName && 'filled'}`}
                                />
                                {!watchLastName && nameError ? (
                                  <div className='errorVal'>
                                    <img
                                      src={warning}
                                      alt='warning'
                                      className='warning'
                                    />
                                    {nameError}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <SearchDropdown
                                name='email'
                                addName={true}
                                placeholder='Email'
                                isEdit={true}
                                values={emailSearch}
                                searchValue={emailAccounts}
                                setSearch={setEmailSearch}
                                status={loadingStatus}
                                className={`${emailSearch && 'filled'}`}
                                control={control}
                                type='email'
                                required
                                setUUID={resetEmail}
                                loadFunction={loadEmail}
                                content={emailAccounts?.map((item) => (
                                  <div
                                    className='searchValuesCard d-flex align-items-center'
                                    onClick={() => {
                                      setEmailSearch(
                                        item?.ContactDetails?.find(
                                          (value: any) => value.type === 'EMAIL'
                                        )?.value
                                      );
                                      setEmailError(true);
                                      setEmailData([
                                        `${item?.firstName} ${item?.lastName}`,
                                        item?.uuid,
                                      ]);
                                    }}
                                  >
                                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                                      {item?.firstName?.charAt(0)}
                                    </div>
                                    <div>
                                      <h6>
                                        {item?.firstName} {item?.lastName}
                                      </h6>
                                      <ul className='user-details'>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'EMAIL'
                                            )?.value
                                          }
                                        </li>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'PHONENUMBER'
                                            )?.value
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              />
                              {emailError ? (
                                <div className='errorVal emailError'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  <div>
                                    This email is already associated with
                                    another contact{' '}
                                    <span
                                      onClick={() =>
                                        navigate(`/contacts/${emailData[1]}`)
                                      }
                                    >
                                      {emailData[0]}
                                    </span>{' '}
                                    Please use a different email.
                                  </div>
                                </div>
                              ) : (
                                !emailSearch &&
                                submit && (
                                  <div className='errorVal'>
                                    <img
                                      src={warning}
                                      alt='warning'
                                      className='warning'
                                    />
                                    Please enter email
                                  </div>
                                )
                              )}
                            </div>

                            <div className='fields'>
                              <PhoneNumberInput
                                name='phoneNumber'
                                type='tel'
                                placeholder='Phone number'
                                control={control}
                                required
                                className={`${
                                  errors.phoneNumber && 'error-field'
                                } ${watchAllFields.phoneNumber && 'filled'}`}
                              />
                              {!watchAllFields.phoneNumber && submit ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  Please enter phone Number
                                </div>
                              ) : submit &&
                                !new RegExp(
                                  /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/
                                ).test(watchAllFields.phoneNumber) ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  Please enter valid phone Number
                                </div>
                              ) : phoneNumberError ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  {phoneNumberError}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields dateField'>
                              <DateField
                                name='dateOfBirth'
                                placeholder='Date Of Birth'
                                maxDate={new Date()}
                                dateOfBirth
                                control={control}
                                value={watchAllFields.dateOfBirth}
                              />
                            </div>
                            <div className='fields'>
                              <SelectField
                                options={MaritalStatus}
                                name='maritalStatus'
                                placeholder='Marital status'
                                control={control}
                              />
                            </div>
                          </div>
                          {commercial && contactCustomFields?.length > 0
                            ? contactCustomFields?.map(
                                (item: any, index: number) =>
                                  index % 2 === 0 ? (
                                    <div className='d-sm-flex justify-content-between'>
                                      <div className='fields'>
                                        <CustomFieldInput
                                          item={item}
                                          register={register}
                                          control={control}
                                          value={watchAllFields[item.uuid]}
                                          setDueTimeVal={setDueTimeVal}
                                          duetimeVal={duetimeVal}
                                        />
                                      </div>
                                      {contactCustomFields?.length >
                                      index + 1 ? (
                                        <div className='fields'>
                                          <CustomFieldInput
                                            item={
                                              contactCustomFields[index + 1]
                                            }
                                            register={register}
                                            control={control}
                                            value={
                                              watchAllFields[
                                                contactCustomFields[index + 1]
                                                  .uuid
                                              ]
                                            }
                                            setDueTimeVal={setDueTimeVal}
                                            duetimeVal={duetimeVal}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null
                              )
                            : null}
                          {!commercial ? (
                            <div>
                              {!isManual ? (
                                <div className=' d-sm-flex justify-content-between'>
                                  <div className='fields'>
                                    <GoogleSearchInput
                                      placeholder='Mailing Address'
                                      name='address'
                                      inputType='PERSONAL'
                                      setAddressObject={setAddressObject}
                                      addressObject={addressObject}
                                      className={`${
                                        errors.address && 'error-field'
                                      } ${
                                        Object.keys(addressObject)?.length >
                                          0 && 'filled'
                                      }`}
                                      setIsManual={setIsManual}
                                    ></GoogleSearchInput>
                                  </div>
                                  <div className='fields'>
                                    <InputField
                                      name='apartment'
                                      type='text'
                                      placeholder='Apt'
                                      register={register}
                                      className={`${
                                        errors.apartment && 'error-field'
                                      } ${
                                        watchAllFields.apartment && 'filled'
                                      }`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <h2 className='contact-info'>
                                    Mailing address
                                  </h2>
                                  <div className=' d-sm-flex justify-content-between'>
                                    <div className='fields'>
                                      <InputField
                                        name='address'
                                        type='text'
                                        placeholder='Address'
                                        register={register}
                                        className={`${
                                          errors.address && 'error-field'
                                        } ${
                                          watchAllFields.address && 'filled'
                                        }`}
                                      />
                                    </div>
                                    <div className='fields'>
                                      <InputField
                                        name='zipCode'
                                        type='number'
                                        placeholder='Zipcode'
                                        register={register}
                                        className={`${
                                          errors.zipCode && 'error-field'
                                        } ${
                                          watchAllFields.zipCode && 'filled'
                                        }`}
                                      />
                                    </div>
                                  </div>
                                  <div className=' d-sm-flex justify-content-between'>
                                    <div className='fields'>
                                      <InputField
                                        name='city'
                                        type='text'
                                        placeholder='City'
                                        register={register}
                                        className={`${
                                          errors.city && 'error-field'
                                        } ${watchAllFields.city && 'filled'}`}
                                      />
                                    </div>
                                    <div className='fields'>
                                      <InputField
                                        name='state'
                                        type='text'
                                        placeholder='State'
                                        register={register}
                                        className={`${
                                          errors.state && 'error-field'
                                        } ${watchAllFields.state && 'filled'}`}
                                      />
                                    </div>
                                  </div>
                                  <div className=' d-sm-flex justify-content-between'>
                                    <div className='fields'>
                                      <InputField
                                        name='country'
                                        type='text'
                                        placeholder='County'
                                        register={register}
                                        className={`${
                                          errors.country && 'error-field'
                                        } ${
                                          watchAllFields.country && 'filled'
                                        }`}
                                      />
                                    </div>
                                    <div className='fields'>
                                      <InputField
                                        name='apartment'
                                        type='text'
                                        placeholder='Apt'
                                        register={register}
                                        className={`${
                                          errors.apartment && 'error-field'
                                        } ${
                                          watchAllFields.apartment && 'filled'
                                        }`}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {contactCustomFields?.length > 0
                                ? contactCustomFields?.map(
                                    (item: any, index: number) =>
                                      index % 2 === 0 ? (
                                        <div className='d-sm-flex justify-content-between'>
                                          <div className='fields'>
                                            <CustomFieldInput
                                              item={item}
                                              register={register}
                                              control={control}
                                              value={watchAllFields[item.uuid]}
                                              setDueTimeVal={setDueTimeVal}
                                              duetimeVal={duetimeVal}
                                            />
                                          </div>
                                          {contactCustomFields?.length >
                                          index + 1 ? (
                                            <div className='fields'>
                                              <CustomFieldInput
                                                item={
                                                  contactCustomFields[index + 1]
                                                }
                                                register={register}
                                                control={control}
                                                value={
                                                  watchAllFields[
                                                    contactCustomFields[
                                                      index + 1
                                                    ].uuid
                                                  ]
                                                }
                                                setDueTimeVal={setDueTimeVal}
                                                duetimeVal={duetimeVal}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null
                                  )
                                : null}
                            </div>
                          ) : null}
                        </>
                      )}
                      {/* select policy */}
                      <div>
                        <h2 className='contact-info'>Select Policies</h2>
                      </div>
                      <div className='d-sm-flex justify-content-between'>
                        <div className='fields text-transform-capitalize'>
                          <MultiSelect
                            options={products.map((item) => {
                              return {
                                label: `${item.name ? item.name : ''}`,
                                value: item.uuid,
                                type: item.type,
                              };
                            })}
                            name='policies'
                            placeholder='Select Policies'
                            required
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                    {commercial &&
                    (Object.keys(selectedBusinessContact)?.length > 0 ||
                      addBusinessContact) &&
                    (Object.keys(selectedContact)?.length > 0 || addContact) ? (
                      <div className='buttonsDiv d-flex align-items-center '>
                        <button
                          type='submit'
                          className='nextButton'
                          disabled={!watchAllFields.policies}
                          onClick={() => {
                            onClickSubmit();
                          }}
                        >
                          Next{' '}
                          <img
                            className='qualifyArrow'
                            src={rightArrow}
                            alt=''
                          />
                        </button>
                      </div>
                    ) : null}
                    {!commercial &&
                    (Object.keys(selectedContact)?.length > 0 || addContact) ? (
                      <div className='buttonsDiv d-flex align-items-center '>
                        <button
                          type='submit'
                          className='nextButton'
                          disabled={!watchAllFields.policies}
                          onClick={() => {
                            onClickSubmit();
                          }}
                        >
                          Next{' '}
                          <img
                            className='qualifyArrow'
                            src={rightArrow}
                            alt=''
                          />
                        </button>
                      </div>
                    ) : null}
                  </>
                ) : (
                  selectedPolicies.map(
                    (item: any, index: number) =>
                      index === policyIndex && (
                        <AddPolicyInfo
                          policy={item}
                          policyIndex={policyIndex}
                          setIndex={setPolicyIndex}
                          itemIndex={index}
                          setDocumentExist={setDocumentExist}
                          selectedPolicies={selectedPolicies}
                          control={control}
                          register={register}
                          setPolicies={setSelectedPolicies}
                          setValue={setValue}
                          isLoading={disabled}
                          customFields={policyCustomFields}
                        />
                      )
                  )
                )}
              </form>
            </div>
          </div>
        </StyledAddModal>
      </Modal>
    </>
  );
};

export default AddPolicyModal;

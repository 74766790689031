import { useRef } from 'react';
import { DocumentsFieldStyle } from '../../../../components/DocumentsField/styles';
import bin from '../../../../assets/logo/bin.svg';
const DocumentField = ({
  setFile,
  file,
  colorless,
  disabled,
  placeholder,
  edit,
  name,
  setFileError,
}: {
  setFile: Function;
  file: any;
  name: string;
  placeholder: string;
  edit?: boolean;
  disabled?: boolean;
  colorless?: boolean;
  setFileError: Function;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (
      fileObj.type.split('/')[0] === 'video' ||
      fileObj.type.split('/')[0] === 'audio'
    ) {
      return setFileError(`File doesn't support`);
    }
    setFileError('');
    event.target.value = null;
    setFile({ ...file, [name]: fileObj });
  };
  const handleClick = () => {
    //     👇️ open file input box on click of other element
    if (inputRef && inputRef.current) inputRef.current.click();
  };
  return (
    <div>
      <DocumentsFieldStyle>
        <div
          className={`PolicyDiv ${disabled && 'disabledDiv'}  ${
            edit && 'editfield'
          }
         `}
        >
          <input
            className=''
            style={{ display: 'none' }}
            ref={inputRef}
            name={name}
            type='file'
            onChange={handleFileChange}
            disabled={disabled}
          />
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='label'>{placeholder}</div>
            <button
              className={`browseButton d-flex justify-content-between align-items-center ${
                colorless && 'colourlessButton'
              } ${disabled && 'disabledButton'} ${
                file && !file[name]?.name && 'normal'
              }`}
              type='button'
              onClick={handleClick}
            >
              <div className={`label textRight `}>
                {' '}
                {file && file[name]?.name ? file[name]?.name : 'Browse files'}
              </div>
              {file && file[name]?.name ? (
                <img
                  className='BinImage'
                  src={bin}
                  alt=''
                  onClick={(e) => {
                    e.stopPropagation();
                    delete file[name];
                    setFile({ ...file });
                  }}
                />
              ) : (
                ''
              )}
            </button>
          </div>
        </div>
      </DocumentsFieldStyle>
    </div>
  );
};

export default DocumentField;

import { FieldDiv } from '../InputField/styles';
import React from 'react';

export interface IProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  onSetItem: Function;
  squareField?: boolean;
  firstField?: boolean;
  lastField?: boolean;
  values: any;
  prefix?: string;
  readonly?: boolean;
  month?: string;
  required?: boolean;
  editTask?: boolean;
}

const InputField = ({
  name,
  type,
  placeholder,
  className,
  disabled,
  onSetItem,
  squareField,
  firstField,
  lastField,
  values,
  prefix,
  readonly,
  month,
  required,
  editTask,
  ...props
}: IProps) => {
  const blockInvalidChar = (e: any, type: string) => {
    if (type === 'number') ['+', '-'].includes(e.key) && e.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      type === 'email' ? e.target?.value?.toLowerCase() : e.target?.value;
    onSetItem(name, value);
  };

  return (
    <FieldDiv isEdit={editTask ? true : false}>
      <div
        className={`d-flex flex-column w-100 ${className}  ${
          values[name] ? 'filled' : ''
        } input-container ${
          squareField
            ? 'squareField'
            : firstField
            ? 'firstField'
            : lastField
            ? 'lastField'
            : ''
        } ${prefix && 'input-style'} `}
      >
        {prefix && <span className='prefix-style'>{prefix}</span>}
        {month && <span className='monthStyle'>{month}</span>}
        <input
          id={name}
          name={name}
          onChange={(e) => handleChange(e)}
          className={`input-field ${squareField && 'squareInput'} ${
            firstField && 'firstInput'
          } ${lastField && 'lastInput'}`}
          disabled={disabled}
          value={values[name]}
          type={type}
          onKeyDown={(e) => blockInvalidChar(e, type)}
          prefix={prefix}
          autoComplete='off'
          readOnly={readonly}
          {...props}
        />
        <label htmlFor={name} className='input-placeholder'>
          {placeholder}
          {required ? <span className='requiredStar'>*</span> : null}
        </label>
      </div>
    </FieldDiv>
  );
};

export default InputField;

import styled from '@emotion/styled';

export const CheckBoxDIv = styled.div`
  width: 100%;
  align-items: center;
  background-color: #fff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  transition: all 100ms;
  box-sizing: border-box;
  color: #262758;
  border: 1px solid #cad9f3;

  padding: 8px;
  height: 48px;
  .input-container {
    align-items: center;
    margin-left: 10px;
  }
  &.filled {
    border: 1px solid #262758;
  }

  .form-check-input {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
  label {
    width: calc(100% - 30px);
  }
  :hover {
    border: 1px solid #cad9f3;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
  }
  &:focus-within {
    border: 1px solid #3c498a;
  }

  &.edit-check {
    border: none;
    height: 60px;
    .form-appear-check {
      height: 100%;
    }
    :hover {
      border: none;
      box-shadow: none;
      border-radius: 8px;
      background: #f5f7fc;
    }
  }
  @media screen and (min-width: 1900px) {
    height: 64px;
  }

  @media screen and (min-width: 2800px) {
    height: 128px;

    .input-field {
      font-size: 32px;
      padding: 40px;
      border-radius: 16px;
    }
  }
`;

export const TimeFieldContainer = styled.div`
  position: relative;
  &.timeFieldContainer .pickerModalContainer,
  .pickerModalContainer {
    right: unset;
    left: 2px;
    top: 50px;
  }

  &.timePicker-addForm .pickerModalContainer {
    width: calc(100% - 2px);
    top: 50px;
  }

  @media screen and (min-width: 576px) {
    &.timeFieldContainer .pickerModalContainer,
    .pickerModalContainer {
      top: 50px;
    }
  }
`;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createLostReason,
  createXDateReason,
  deleteLostReason,
  deleteXDateReason,
  editLostReason,
  editXDateReason,
  getLostReasons,
  getXDateReasons,
} from '../../api/reason';
import { ILostReason, IXDateReason } from '../../interfaces/Lead';

export type reasonsSliceState = {
  status: string;
  xDateReasons: IXDateReason[];
  lostReasons: ILostReason[];
};

const initialState: reasonsSliceState = {
  status: 'idle',
  xDateReasons: [],
  lostReasons: [],
};

export const fetchXDateReasons = createAsyncThunk(
  'reasons/fetchXDateReasons',
  async ({ uuid, searchBy }: { uuid?: string; searchBy: string }) => {
    const response = await getXDateReasons(() => {}, uuid, searchBy);
    return response.data;
  }
);

export const onCreateXDateReason = createAsyncThunk(
  'reasons/onCreateXDateReason',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await createXDateReason(data, successCB, errorCB);
    return response.data;
  }
);

export const onEditXDateReason = createAsyncThunk(
  'reasons/onEditXDateReason',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await editXDateReason(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const onDeleteXDateReason = createAsyncThunk(
  'reasons/onDeleteXDateReason',
  async ({
    agencyUUID,
    uuid,
    successCB,
  }: {
    uuid: string;
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await deleteXDateReason(uuid, { agencyUUID });
    successCB();
    return response.data;
  }
);

export const fetchLostReasons = createAsyncThunk(
  'reasons/fetchLostReasons',
  async ({ uuid, searchBy }: { uuid?: string; searchBy: string }) => {
    const response = await getLostReasons(() => {}, uuid, searchBy);
    return response.data;
  }
);

export const onCreateLostReason = createAsyncThunk(
  'reasons/onCreateLostReason',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await createLostReason(data, successCB, errorCB);
    return response.data;
  }
);

export const onEditLostReason = createAsyncThunk(
  'reasons/onEditLostReason',
  async ({
    uuid,
    data,
    successCB,
    errorCB,
  }: {
    uuid: string;
    data: { agencyUUID?: string; name: string };
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await editLostReason(uuid, data, successCB, errorCB);
    return response.data;
  }
);

export const onDeleteLostReason = createAsyncThunk(
  'reasons/onDeleteLostReason',
  async ({
    agencyUUID,
    uuid,
    successCB,
  }: {
    uuid: string;
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await deleteLostReason(uuid, { agencyUUID });
    successCB();
    return response.data;
  }
);

const reasonsSlice = createSlice({
  name: 'reasons',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLostReasons.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchLostReasons.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(fetchLostReasons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.lostReasons = action.payload;
      })
      .addCase(onCreateLostReason.fulfilled, (state, action) => {
        state.lostReasons.push(action.payload);
      })
      .addCase(onEditLostReason.fulfilled, (state, action) => {
        const index = state.lostReasons.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.lostReasons[index] = action.payload;
      })
      .addCase(onDeleteLostReason.fulfilled, (state, action) => {
        const index = state.lostReasons.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.lostReasons.splice(index, 1);
      })
      .addCase(fetchXDateReasons.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchXDateReasons.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(fetchXDateReasons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.xDateReasons = action.payload;
      })
      .addCase(onCreateXDateReason.fulfilled, (state, action) => {
        state.xDateReasons.push(action.payload);
      })
      .addCase(onEditXDateReason.fulfilled, (state, action) => {
        const index = state.xDateReasons.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.xDateReasons[index] = action.payload;
      })
      .addCase(onDeleteXDateReason.fulfilled, (state, action) => {
        const index = state.xDateReasons.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.xDateReasons.splice(index, 1);
      });
  },
});

export const { updateStatus } = reasonsSlice.actions;

export default reasonsSlice.reducer;

export const selectAllLostReasons = (state: { reasons: reasonsSliceState }) =>
  state.reasons.lostReasons;

export const selectXDateReasons = (state: { reasons: reasonsSliceState }) =>
  state.reasons.xDateReasons;

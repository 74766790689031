import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { StyledCard } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { IContact } from '../../../../interfaces/Contact';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import EditContactDetails from '../../../ContactDetails/EditContactDetails';

const ContactMobileCard = ({
  contact,
  setShowDeleteModal,
  setUUID,
  setName,
}: {
  contact: IContact;
  setShowDeleteModal: Function;
  setUUID: Function;
  setName: Function;
}) => {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDropdown);

  return (
    <StyledCard>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 onClick={() => navigate(`/contacts/${contact.uuid}`)}>
          {contact.firstName
            ? contact.firstName + ' ' + contact.lastName
            : contact.lastName}
        </h6>
      </div>
      <div className='info-div'>
        <div className=' d-flex'>
          <div className='type-div'>
            {contact?.AccountContacts &&
            contact?.AccountContacts[0]?.Account?.AccountType?.name.charAt(0) +
              contact?.AccountContacts[0]?.Account?.AccountType?.name
                .slice(1)
                .toLowerCase()
              ? contact?.AccountContacts[0]?.Account?.AccountType?.name.charAt(
                  0
                ) +
                contact?.AccountContacts[0]?.Account?.AccountType?.name
                  .slice(1)
                  .toLowerCase()
              : 'No account connected'}
          </div>
          Created on <Moment format='MM/DD/YYYY'>{contact.createdAt}</Moment>
        </div>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <div className='action-item'>
            <img src={phone} alt='phone' />
          </div>
          <div className='action-item'>
            <img src={mail} alt='mail' />
          </div>
          <div className='action-item'>
            <img src={message} alt='message' />
          </div>
        </div>
        <div className='vector-div'>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropRef}
          >
            <img src={vector} alt='vector' />
          </div>
          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setUUID(contact?.uuid);
                  setShowDropdown(false);
                  setName(`${contact?.firstName + ' ' + contact?.lastName}`);
                }}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
          {showEditModal !== 'closed' && (
            <EditContactDetails
              show={showEditModal}
              closeModal={setShowEditModal}
              uuid={contact.uuid}
            />
          )}
        </div>
      </div>
    </StyledCard>
  );
};

export default ContactMobileCard;

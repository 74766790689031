import axios from './axios';

const getUserDetails = () => {
  return axios.get('/user/roles');
};

const getValidateUser = (
  code: string,
  successCB: Function,
  errorCB?: Function
) => {
  return axios.get('/auth/validateUser', { params: { code } }).then(
    (res) => {
      if (successCB) {
        successCB(res.data);
      }
      return res;
    },
    (error) => {
      if (errorCB) {
        errorCB('It seems that your link has expired or invalid');
      }
      return error.response;
    }
  );
};

const updateAgentProfile = (
  uuid: string,
  data: any,
  successCB: Function,
  errorCB: Function,
  action: string
) => {
  return axios
    .patch(`/agents/${uuid}/profile`, data, {
      headers:
        action === 'updateLogo'
          ? {
              'Content-Type': 'multipart/form-data',
            }
          : { 'Content-Type': 'application/json' },
    })
    .then(
      (res) => {
        successCB(res.data);
        return res;
      },
      (error) => {
        errorCB(error.response.data);
        throw new Error(error.response?.data?.error);
      }
    );
};

const updateAgentPassword = (
  uuid: string,
  data: { email: string; newPassword: string; confirmPassword: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/agents/${uuid}/changePassword`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB('The password you have entered is incorrect.');
      return error.response;
    }
  );
};

const getAgentDetails = (uuid: string) => {
  return axios.get(`/agents/${uuid}`).then((res) => {
    return res;
  });
};

const updateUser = (
  uuid: string,
  data: any,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/user/${uuid}`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response?.data?.error);
    }
  );
};

const logOut = (successCB: Function, errorCB: Function) => {
  return axios.post(`/user/logout`, {}).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response?.data?.error);
    }
  );
};

export {
  getUserDetails,
  getValidateUser,
  getAgentDetails,
  updateAgentProfile,
  updateAgentPassword,
  updateUser,
  logOut,
};

import { PaginationDiv } from './styles';
import left from '../../assets/logo/leftArrow.svg';

const PaginationComponent = ({
  limit,
  setLimit,
  page,
  setPage,
  count,
}: {
  limit: number;
  setLimit: Function;
  page: number;
  setPage: Function;
  count: number;
}) => {
  return (
    <PaginationDiv>
      <div className='d-flex align-items-center'>
        Show
        <select
          value={limit}
          className='form-select'
          onChange={(e) => {
            setPage(0);
            setLimit(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} rows
            </option>
          ))}
        </select>
      </div>
      <div>
        <button onClick={() => setPage(page - 1)} disabled={page === 0}>
          <img src={left} alt='' />
        </button>{' '}
        {/* {Array.apply(null, Array(Math.ceil(count / limit))).map(
          (item, index) => (
            <button
              className={index === page ? 'active' : ''}
              onClick={() => setPage(index)}
              key={index}
            >
              {index + 1}
            </button>
          )
        )} */}
        <button
          className='active'
          //   className={index === page ? 'active' : ''}
          //   onClick={() => setPage(index)}
          //   key={index}
        >
          {page + 1}
        </button>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page + 1 === Math.ceil(count / limit)}
        >
          <img src={left} alt='' style={{ transform: 'rotate(180deg)' }} />
        </button>{' '}
      </div>
    </PaginationDiv>
  );
};

export default PaginationComponent;

import styled from '@emotion/styled';

export const SelectStyle = styled.div`
  .SlelectField {
    position: relative;
    .input-placeholder {
      position: absolute;
      top: 0;
      left: 10px;
      color: #5a5f70;
      font-size: 12px;
    }
  }
`;

import 'react-datepicker/dist/react-datepicker.css';
import SelectField from './Select';
import InputField from '../CustomInputField';
import DateField from '../CustomDateField';
import { CheckBoxDIv, TimeFieldContainer } from '../CustomfieldsInput/style';
import TimePicker from '../TimePicker';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { FieldDiv } from '../InputField/styles';
import moment from 'moment';

const CustomFieldInputCustom = ({
  item,
  className,
  values,
  onSetItem,
}: {
  item: any;
  className?: string;
  values: any;
  onSetItem: Function;
}) => {
  const [DueTimeModal, setDueTimeModal] = useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setDueTimeModal);

  const [dueTime, setDueTime] = useState<any>('');

  const onSetTime = (value: any) => {
    onSetItem(item.uuid, value);
    setDueTime(value);
  };

  return (
    <>
      {(item.FieldType?.name === 'TEXT_INPUT' ||
        item.FieldType?.name === 'NUMBER_INPUT') && (
        <InputField
          name={item.uuid}
          type={item.FieldType?.name === 'TEXT_INPUT' ? 'text' : 'number'}
          placeholder={item.name}
          onSetItem={onSetItem}
          values={values}
        />
      )}
      {item.FieldType?.name === 'CURRENCY_INPUT' && (
        <InputField
          name={item.uuid}
          type='number'
          placeholder={item.name}
          onSetItem={onSetItem}
          values={values}
          prefix='$'
        />
      )}
      {item.FieldType?.name === 'DATEPICKER' && (
        <DateField
          name={item.uuid}
          placeholder={item.name}
          onSetItem={onSetItem}
          values={values}
        />
      )}
      {item.FieldType?.name === 'TIME' && (
        <TimeFieldContainer
          onClick={() => setDueTimeModal(true)}
          ref={dropRef}
          className='timePicker-addForm'
        >
          <FieldDiv>
            <div
              className={`d-flex flex-column w-100 ${className} input-container ${
                dueTime && 'filled'
              }`}
            >
              <input
                id={item.uuid}
                name={item.uuid}
                onChange={(e) => onSetItem(item.uuid, dueTime)}
                className={`input-field`}
                value={
                  dueTime ? moment(dueTime.toString()).format('hh:mm A') : ''
                }
                type='text'
                autoComplete='off'
                readOnly
              />
              <label htmlFor={item.uuid} className='input-placeholder'>
                {item.name}
              </label>
            </div>
          </FieldDiv>
          {DueTimeModal && (
            <TimePicker
              setTaskDueTime={setDueTimeModal}
              setDueTime={onSetTime}
              dueTime={dueTime || ''}
            />
          )}
        </TimeFieldContainer>
      )}
      {item.FieldType?.name === 'YES/NO_INPUT' && (
        <CheckBoxDIv className={`${values[item.uuid] ? 'filled' : ''}`}>
          <div className=' input-container w-100 d-flex form-appear-check h-100'>
            <input
              type='checkbox'
              className='form-check-input'
              name={item.uuid}
              onChange={(e) => onSetItem(item.uuid, e.target.checked)}
              value={values[item.uuid]}
              id={item.uuid}
            />
            <label className='form-check-label' htmlFor={item.uuid}>
              {item.name}
            </label>
          </div>
        </CheckBoxDIv>
      )}
      {item.FieldType?.name === 'SINGLE_CHOICE' && (
        <SelectField
          options={item.data.options.map((item: any) => {
            return { value: item.order, label: item.value };
          })}
          name={item.uuid}
          placeholder={item.name}
          onSetItem={onSetItem}
          values={values}
          isCustomField={true}
        />
      )}
      {item.FieldType?.name === 'MULTIPLE_CHOICE' && (
        <SelectField
          options={item.data.options.map((item: any) => {
            return { value: item.order, label: item.value };
          })}
          name={item.uuid}
          placeholder={item.name}
          onSetItem={onSetItem}
          values={values}
          isMulti={true}
          isCustomField={true}
        />
      )}
    </>
  );
};

export default CustomFieldInputCustom;

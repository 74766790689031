import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import { IJurisdiction } from '../../../interfaces/Jurisdiction';
import { fetchJurisdiction } from '../../../api/jurisdiction';
import {
  getContact,
  onUpdateContactDetails,
  selectContact,
} from '../../../store/contacts/contactsSlice';
import { fetchContacts } from '../../../api/contact';
import EditInput from '../../../components/DetailsEditFormInput';
import EditSelect from '../../../components/SelectFieldEdit';
import EditPhoneNumber from '../../../components/PhoneNumberFormatInEditForms';
import EditDate from '../../../components/DateFieldEdit';
import Modal from '../../../components/EditDetailsModal';
import SearchDropdown from '../../../components/SearchDropdown';
import SuccessCard from '../../../components/SuccessCard';
import { StyledEditDetailsPage } from '../../AccountDetails/EditAccountDetails/styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import cross from '../../../assets/logo/cross.svg';
import warning from '../../../assets/logo/warning.svg';
import tick from '../../../assets/logo/tick.svg';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../../store/customFields/customFieldsSlice';
import CustomFieldInput from '../../../components/CustomfieldsInput';
import { ICustomField } from '../../../interfaces/CustomField';

const SignupSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email. Please try again'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
});

const EditContactDetails = ({
  show,
  closeModal,
  uuid,
}: {
  show: string;
  closeModal: Function;
  uuid: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];
  const contactDetails = useSelector(selectContact);
  const navigate = useNavigate();

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [submit, setSubmit] = useState(false);
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [jurisdictionSearch, setJurisdictionSearch] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const watchLastName = watch('lastName');
  const [error, setError] = useState('');
  const watchPhone = watch('phoneNumber');

  const [isLoading, setIsLoading] = useState(false);

  const loadEmail = (value: string) => {
    setSubmit(false);
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (licensedState) {
        fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
          setLicensedState(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setLicensedState([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const watchAllFields = watch();

  const customFields = useSelector(selectAllCustomFields);

  const onSubmit = (data: any) => {
    data.customFields = {};
    customFields.map((item: any) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.customFields[item.uuid] = timeItem.value;
        } else {
          data.customFields[item.uuid] = data[item.uuid];
        }
      }
    });
    setSubmit(true);
    let errorLastName = true;
    if (!watchLastName) {
      errorLastName = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (emailExist && emailExist?.uuid !== uuid) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    }
    if (
      !emailError &&
      emailSearch &&
      !(emailExist && emailExist?.uuid !== uuid) &&
      errorLastName === true
    ) {
      data.maritalStatus = data.maritalStatus?.value;
      data.driverlicenseState = data.driverlicenseState?.value;
      data.jurisdictionUUID = jurisdictionsUUID;
      // data.businesses = business;
      data.email = emailSearch;

      dispatch(
        onUpdateContactDetails({
          data,
          uuid,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: (msg: string) => {
            setError(msg);
          },
        })
      );
    }
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  useEffect(() => {
    if (show === 'open') {
      setIsLoading(true);
      dispatch(
        getContact({
          uuid,
          successCB: (data: any) => {
            let contact = data.data;

            setValue('firstName', contact.firstName);

            setValue('middleName', contact.middleName);

            setValue('lastName', contact.lastName);

            setValue(
              'dateOfBirth',
              contact.dateOfBirth ? moment(contact.dateOfBirth)?.toDate() : ''
            );

            setValue('driverlicenseNumber', contact.driverlicenseNumber);
            setJurisdictionsUUID(contact.Jurisdiction?.uuid);
            setJurisdictionSearch(contact.Jurisdiction?.name);
            if (contact.ContactDetails[0]?.type === 'EMAIL') {
              setEmailSearch(contact.ContactDetails[0]?.value);
              setValue('phoneNumber', contact.ContactDetails[1]?.value);
            } else {
              setEmailSearch(contact.ContactDetails[1]?.value);
              setValue('phoneNumber', contact.ContactDetails[0]?.value);
            }
            setValue('maritalStatus', {
              value: contact?.maritalStatus,
              label: contact?.maritalStatus,
            });

            dispatch(
              getCustomFields({
                uuid: '',
                resourceName: 'CONTACT',
                successCB: (data: ICustomField[]) => {
                  data.map((item) => {
                    if (
                      item.FieldType?.name === 'DATEPICKER' &&
                      item.data?.defaultValue
                    ) {
                      setValue(
                        item.uuid,
                        moment(item.data?.defaultValue).toDate()
                      );
                    }
                    if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
                      const filteredData = item.data.options.filter(
                        (it) => it.default === true
                      );
                      setValue(
                        item.uuid,
                        filteredData.map((el) => {
                          return { value: el.order, label: el.value };
                        })
                      );
                    }
                    if (item.FieldType?.name === 'SINGLE_CHOICE') {
                      if (item.data?.defaultValue) {
                        setValue(item.uuid, {
                          label:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.value,
                          value:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.order,
                        });
                      }
                    }
                  });

                  if (contact.ContactCustomField?.length > 0) {
                    contact.ContactCustomField.map((item: any) => {
                      if (item.CustomField.FieldType?.name === 'DATEPICKER') {
                        setValue(
                          item.CustomField.uuid,
                          moment(item.value).toDate()
                        );
                      } else if (item.CustomField.FieldType?.name === 'TIME') {
                        setDueTimeVal([
                          ...duetimeVal,
                          {
                            uuid: item.CustomField.uuid,
                            value: moment(item.value).toDate(),
                          },
                        ]);
                      } else {
                        setValue(item.CustomField.uuid, item.value);
                      }
                    });
                  }

                  setIsLoading(false);
                },
              })
            );
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setEmailAccounts([]);
  };

  useEffect(() => {
    setError('');
  }, [watchPhone]);

  const resetJurisdiction = (value: string) => {
    setLicensedState([]);
    setJurisdictionsUUID('');
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success updating contact!</b>`}
            message={` <b>${getValues('firstName')} ${getValues(
              'lastName'
            )}</b>  has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setShowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledEditDetailsPage>
        <Modal open={show}>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                <h2>Edit contact</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div className='editHeading'>
                  <h2 className='contact-info '>Contact info</h2>
                  <div className='d-flex flex-wrap'>
                    <div
                      className={`fields ${errors.firstName && 'error-name'}`}
                    >
                      <EditInput
                        name='firstName'
                        type='text'
                        placeholder='First name'
                        register={register}
                      />
                      {errors.firstName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.firstName?.message}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className={`fields ${errors.middleName && 'error-name'}`}
                    >
                      <EditInput
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        register={register}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`fields ${errors.lastName && 'error-name'}`}
                    >
                      <EditInput
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        register={register}
                        required
                      />
                      {errors.lastName || (!watchLastName && submit) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      <EditDate
                        name='dateOfBirth'
                        placeholder='Date of birth'
                        dateOfBirth
                        maxDate={new Date()}
                        control={control}
                      />
                    </div>

                    <div className='fields'>
                      <EditPhoneNumber
                        name='phoneNumber'
                        type='tel'
                        placeholder='Phone number'
                        control={control}
                        required
                      />
                      {errors.phoneNumber || error ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message || error}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      <SearchDropdown
                        name='email'
                        addName={true}
                        placeholder='Email'
                        isEdit={true}
                        isEditDetail={true}
                        values={emailSearch}
                        searchValue={emailAccounts}
                        setSearch={setEmailSearch}
                        status={loadingStatus}
                        className={`${emailSearch && 'filled'}`}
                        register={register}
                        control={control}
                        required
                        type='email'
                        setUUID={resetEmail}
                        loadFunction={loadEmail}
                        content={emailAccounts?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setEmailSearch(
                                item?.ContactDetails?.find(
                                  (value: any) => value.type === 'EMAIL'
                                )?.value
                              );
                              setEmailError(true);
                              setEmailData([
                                `${item?.firstName} ${item?.lastName}`,
                                item?.uuid,
                              ]);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.firstName?.charAt(0)}
                            </div>
                            <div>
                              <h6>
                                {item?.firstName} {item?.lastName}
                              </h6>
                              <ul className='user-details'>
                                <li>
                                  {
                                    item?.ContactDetails?.find(
                                      (value: any) => value.type === 'EMAIL'
                                    )?.value
                                  }
                                </li>
                                <li>
                                  {
                                    item?.ContactDetails?.find(
                                      (value: any) =>
                                        value.type === 'PHONENUMBER'
                                    )?.value
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                      {emailError ? (
                        <div className='errorVal emailError'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          <div className='email-exist-error'>
                            This email is already associated with another
                            contact{' '}
                            <span
                              onClick={() =>
                                navigate(`/contacts/${emailData[1]}`)
                              }
                            >
                              {emailData[0]}
                            </span>{' '}
                            Please use a different email.
                          </div>
                        </div>
                      ) : (
                        [
                          !emailSearch && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Please enter your email
                            </div>
                          ) : (
                            errors.email?.message && (
                              <div className='errorVal'>
                                <img
                                  src={warning}
                                  alt='warning'
                                  className='warning'
                                />
                                {errors.email?.message}
                              </div>
                            )
                          ),
                        ]
                      )}
                    </div>
                    <div className='fields'>
                      <EditSelect
                        control={control}
                        name='maritalStatus'
                        placeholder='Marital status'
                        options={MaritalStatus}
                      />
                    </div>
                    <div className='fields'>
                      <SearchDropdown
                        name='jurisdictionUUID'
                        placeholder='Licensed state'
                        isEdit={true}
                        isEditDetail={true}
                        values={jurisdictionSearch}
                        searchValue={licensedState}
                        setSearch={setJurisdictionSearch}
                        status={loadingStatus}
                        className={`${
                          !jurisdictionsUUID && submit && 'error-field'
                        } ${jurisdictionSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadLicensedState}
                        setUUID={resetJurisdiction}
                        content={
                          licensedState.length > 0
                            ? licensedState?.map((item) => (
                                <div
                                  className='searchValuesCard d-flex align-items-center'
                                  onClick={() => {
                                    setJurisdictionSearch(item?.name);
                                    setJurisdictionsUUID(item?.uuid);
                                  }}
                                >
                                  <div>
                                    <h6>{item.name}</h6>
                                  </div>
                                </div>
                              ))
                            : null
                        }
                      />
                    </div>

                    <div className='fields'>
                      <EditInput
                        name='driverlicenseNumber'
                        type='tel'
                        placeholder='License number'
                        register={register}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-wrap'>
                    {customFields?.length > 0
                      ? customFields?.map((item: any, index: number) => (
                          <div className='fields'>
                            <CustomFieldInput
                              item={item}
                              register={register}
                              control={control}
                              value={watchAllFields[item.uuid]}
                              isEdit={true}
                              setDueTimeVal={setDueTimeVal}
                              duetimeVal={duetimeVal}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                {/* {!commercial ? (
                  <div>
                    <h6 className='mailingAdressHead'>MAILING ADDRESS</h6>
                    <div className='fieldRaw d-flex d-sm-flex flex-wrap'>
                      <div className='fields'>
                        <EditInput
                          name='address'
                          type='text'
                          placeholder='Street address'
                          register={register}
                        />
                      </div>
                      <div className='fields'>
                        <EditInput
                          name='zipcode'
                          type='text'
                          placeholder='Zipcode'
                          register={register}
                        />
                      </div>
                      <div className='fields'>
                        <EditInput
                          name='city'
                          type='text'
                          placeholder='City'
                          register={register}
                        />
                      </div>
                      <div className='fields'>
                        <EditInput
                          name='state'
                          type='text'
                          placeholder='State'
                          register={register}
                        />
                      </div>

                      <div className='fields'>
                        <EditInput
                          name='county'
                          type='text'
                          placeholder='County'
                          register={register}
                        />
                      </div>
                      <div className='fields'>
                        <EditInput
                          name='apartment'
                          type='text'
                          placeholder='Apt'
                          register={register}
                        />
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {commercial
                  ? business?.map((item: any, index: any) => (
                      <ComercialEditForm
                        key={index}
                        contact={item}
                        index={index}
                        businesses={business}
                        setBusiness={setBusiness}
                      />
                    ))
                  : null} */}
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    onClick={() => setSubmit(true)}
                    disabled={
                      show === 'closing' ||
                      (moment(watchAllFields?.dateOfBirth).toISOString() ===
                        moment(contactDetails?.dateOfBirth).toISOString() &&
                        watchAllFields?.firstName ===
                          contactDetails?.firstName &&
                        watchAllFields?.middleName ===
                          contactDetails?.middleName &&
                        watchAllFields?.lastName === contactDetails?.lastName &&
                        watchAllFields?.phoneNumber ===
                          contactDetails?.ContactDetails.find(
                            (item: any) => item.type === 'PHONENUMBER'
                          )?.value &&
                        emailSearch ===
                          contactDetails?.ContactDetails.find(
                            (item: any) => item.type === 'EMAIL'
                          )?.value &&
                        watchAllFields?.maritalStatus?.value ===
                          contactDetails?.maritalStatus &&
                        jurisdictionsUUID ===
                          contactDetails?.Jurisdiction?.uuid &&
                        watchAllFields?.driverlicenseNumber ===
                          contactDetails?.driverlicenseNumber &&
                        (customFields?.length > 0
                          ? customFields?.every((item: any) => {
                              const opportunityCustom =
                                contactDetails?.ContactCustomField?.find(
                                  (el: any) => el.CustomField.uuid === item.uuid
                                );
                              if (item.FieldType.name === 'SINGLE_CHOICE') {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid]?.label ===
                                    opportunityCustom.value?.label
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.label !==
                                    undefined &&
                                  watchAllFields[item.uuid]?.value !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (
                                item.FieldType.name === 'MULTIPLE_CHOICE'
                              ) {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid].every(
                                      (watchEl: any) => {
                                        return opportunityCustom.value.find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    ) &&
                                    opportunityCustom.value.every(
                                      (watchEl: any) => {
                                        return watchAllFields[item.uuid].find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    )
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.length > 0
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (item.FieldType.name === 'TIME') {
                                let timeItem = duetimeVal.find(
                                  (el: any) => el.uuid === item.uuid
                                );
                                if (opportunityCustom && timeItem) {
                                  return (
                                    moment(timeItem?.value).toISOString() ===
                                    opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  timeItem !== '' &&
                                  timeItem !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (item.FieldType.name === 'DATEPICKER') {
                                if (opportunityCustom) {
                                  return (
                                    moment(
                                      watchAllFields[item.uuid]
                                    ).toISOString() === opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid] !== '' &&
                                  watchAllFields[item.uuid] !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid] ===
                                    opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid] !== '' &&
                                  watchAllFields[item.uuid] !== undefined &&
                                  watchAllFields[item.uuid] !== false
                                ) {
                                  return false;
                                }
                                return true;
                              }
                            })
                          : true)) ||
                      isLoading
                    }
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </StyledEditDetailsPage>
    </>
  );
};

export default EditContactDetails;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import agenciesReducer from './agencies/agenciesSlice';
import agentsReducer from './agents/agentsSlice';
import leadsReducer from './leads/leadsSlice';
import contactsReducer from './contacts/contactsSlice';
import accountsReducer from './accounts/accountsSlice';
import bookReducer from './book/bookSlice';
import opportuntiesReducer from './opportunities/opportunitiesSlice';
import policiesReducer from './policies/policiesSlice';
import pipelinesReducer from './pipelines/pipelineSlice';
import carriersReducer from './carriers/carriersSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import auditsReducer from './audits/auditsSlice';
import taskReducer from './tasks/tasksSlice';
import eventReducer from './events/eventSlice';
import activitiesReducer from './activity/activitiesSlice';
import documentsReducer from './documents/documentsSlice';
import quotesReducer from './quotes/quoteSlice';
import searchReducer from './search/searchSlice';
import locationsReducer from './location/locationSlice';
import reasonsReducer from './reasons/reasonsSlice';
import goalsReducer from './goals/goalSlice';
import customFieldsReducer from './customFields/customFieldsSlice';
import notificationsReducer from './notifications/notificationsSlice';
import productCategoriesReducer from './productCategories/productCategoriesSlice';

const store = configureStore({
  reducer: {
    agencies: agenciesReducer,
    agents: agentsReducer,
    accounts: accountsReducer,
    user: userReducer,
    tasks: taskReducer,
    activities: activitiesReducer,
    events: eventReducer,
    leads: leadsReducer,
    opportunities: opportuntiesReducer,
    contacts: contactsReducer,
    book: bookReducer,
    policies: policiesReducer,
    pipelines: pipelinesReducer,
    carriers: carriersReducer,
    dashboard: dashboardReducer,
    documents: documentsReducer,
    audits: auditsReducer,
    quotes: quotesReducer,
    search: searchReducer,
    locations: locationsReducer,
    reasons: reasonsReducer,
    goals: goalsReducer,
    customFields: customFieldsReducer,
    notifications: notificationsReducer,
    productCategories: productCategoriesReducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;

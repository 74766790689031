import { useState, useRef, useEffect } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import Moment from 'react-moment';
import { AppDispatch } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import Table from '../../Table';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import ErrorCard from '../../../pages/Pipeline/components/ErrorCard';
import { getCount as accountCount } from '../../../store/accounts/accountsSlice';
import { getCount as contactCount } from '../../../store/contacts/contactsSlice';
import { getCount as opportunityCount } from '../../../store/opportunities/opportunitiesSlice';
import { getCount as policyCount } from '../../../store/policies/policiesSlice';
import { getLeadCount } from '../../../store/leads/leadsSlice';
import {
  DocumentSliceState,
  selectAllDocuments,
  deleteADocument,
} from '../../../store/documents/documentsSlice';
import AddDocumentsModal from './components/AddDocumentsModal';
import { useNavigate } from 'react-router-dom';

const Document = ({
  name,
  uuid,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
}: {
  name: string;
  uuid: string;
  sortBy: string;
  setSortBy: Function;
  orderBy: string;
  setOrderBy: Function;
}) => {
  const documents = useSelector(selectAllDocuments);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const DeleteDoc = (UUID: string) => {
    dispatch(
      deleteADocument({
        uuid: UUID,
        successCB: () => {
          if (name === 'Account' && uuid) {
            dispatch(accountCount({ uuid: uuid }));
          } else if (name === 'Opportunity' && uuid) {
            dispatch(opportunityCount({ uuid: uuid }));
          } else if (name === 'Policy' && uuid) {
            dispatch(policyCount({ uuid: uuid }));
          } else if (name === 'Contact' && uuid) {
            dispatch(contactCount({ uuid: uuid }));
          } else if (name === 'Lead' && uuid) {
            dispatch(getLeadCount({ uuid: uuid }));
          }
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const documentCount = 10;
  const loadingStatus = useSelector(
    (state: { documents: DocumentSliceState }) => state.documents?.status
  );
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <div>
      {showSuccess && (
        <ErrorCard
          title='Success deleting document!'
          message={`<b>${'Document'}</b> has been <b> deleted</b> `}
        />
      )}
      <div className='mybook-content document-content d-none d-sm-block'>
        <div className='d-none d-sm-block'>
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            setScroll={() => {
              setScroll(!scroll);
            }}
            count={documentCount}
            loadingStatus={loadingStatus}
            name='document'
            columns={[
              {
                accessor: 'name',
                resizable: true,
                sortable: true,
                minWidth: 180,
                Header: () => (
                  <div
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Document type</span>
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center'>
                    <a
                      className='s3LocatnText'
                      target='_blank'
                      href={row.original?.s3Location}
                    >
                      <b>
                        {row.original?.DocumentType?.name
                          ? row.original?.DocumentType?.name
                          : '--'}
                      </b>
                    </a>
                  </div>
                ),
              },
              {
                accessor: 'Doc.name',
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Doc. name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                resizable: true,
                minWidth: 115,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center h-100'>
                    <div
                    // className={`status ${
                    //   row.original.Policies?.length > 0
                    //     ? 'statusActive'
                    //     : 'statusInvited'
                    // }`}
                    >
                      {row.original.name}
                    </div>
                  </div>
                ),
              },
              {
                accessor: 'Added by',
                resizable: true,
                sortable: true,
                minWidth: 155,
                Header: () => (
                  <div
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Added by</span>
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div>
                    {row.original.UploadedBy?.firstName +
                      ' ' +
                      row.original.UploadedBy?.lastName}
                  </div>
                ),
              },
              {
                accessor: 'From',
                resizable: true,
                sortable: true,
                hide: name !== 'Opportunity',
                minWidth: 155,
                Header: () => <span>From</span>,
                Cell: ({ row }: { row: any }) => (
                  <div>
                    {row.original.QuoteId ? (
                      <span
                        className='QuoteName'
                        onClick={() => {
                          navigate(`/opportunities/${uuid}/quotes`);
                        }}
                      >
                        {row.original?.Quote?.Carrier?.name}
                      </span>
                    ) : (
                      'Opportunity'
                    )}
                  </div>
                ),
              },
              {
                accessor: 'updatedon',
                Header: () => (
                  <div
                    onClick={() => handleSort('updatedAt')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Updated on</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'updatedAt'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                minWidth: 150,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div className=''>
                    {/* <span className='opp-num'>
                        {row.original.Opportunities.length}
                      </span> */}
                    <Moment format='MM/DD/YYYY'>
                      {row.original?.updatedAt}
                    </Moment>
                  </div>
                ),
              },
              {
                accessor: 'Actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                minWidth: 90,
                maxWidth: 90,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-end h-100'>
                    <div className='actionsDrop d-flex align-items-center  h-100 me-4'>
                      <ActionsDropdown
                        DeleteDoc={DeleteDoc}
                        uuid={row.original.uuid}
                        setShowEditModal={setShowEditModal}
                        scroll={scroll}
                        setUUID={setUUID}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
            data={documents}
            className='mybook-table'
          />
        </div>
      </div>
      {showEditModal !== 'closed' && (
        <AddDocumentsModal
          name={name}
          uuid={UUID}
          resourceUUID={uuid}
          editUUID={UUID}
          isEdit={true}
          show={showEditModal}
          closeModal={setShowEditModal}
        />
      )}
    </div>
  );
};

export default Document;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  scroll,
  DeleteDoc,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  scroll: boolean;
  DeleteDoc: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setShowActionsDropdown(false);
  }, [scroll]);
  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
          setDropDownPosition({
            x: dropRef.current?.getBoundingClientRect().x,
            y: dropRef.current?.getBoundingClientRect().y,
          });
        }
      }}
      ref={dropRef}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShowActionsDropdown(false);
        }
      }}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div
          className='dropdown-div position-fixed '
          style={{
            top: dropDownPosition.y + 33,
            left: dropDownPosition.x - 170,
          }}
        >
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
            }}
            tabIndex={0}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div className='borderDiv'></div>
          <div
            className='delete-div'
            onClick={() => {
              DeleteDoc(uuid);
            }}
            tabIndex={1}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};

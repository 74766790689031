import styled from '@emotion/styled';

export const TimePickerDiv = styled.div`
  .pickerModalContainer {
    position: absolute;
    width: 80vw;
    top: 40px;
    padding: 10px 8px;
    right: -40px;
    height: 78px;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
    border-radius: 7.00366px;
    background: #ffffff;
    z-index: 10;
  }
  .innerTabPicker {
    top: 0px;
    padding: 10px 8px;
    left: 0px;
    font-size: 12px;
    height: 88px;
    border-top: 1px solid #e6e9f0;
    box-shadow: 0px 6.75092px 7.00366px rgba(35, 42, 64, 0.2);
    border-radius: 0 0 8px 8px !important;
    background: #ffffff;
    z-index: 10;
    .timeField {
      width: 75px;
    }
    .fieldDiv {
      width: 100%;
      height: 44px;
      border: 1px solid #cad9f3;
      border-radius: 8px;
      display: flex;
      color: #262758;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px 4px 8px;
      position: relative;
      .dropDownList {
        max-height: 160px;
        width: 100%;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
        border-radius: 8px;
        top: -160px;
        left: 0;
        &::-webkit-scrollbar {
          width: 4px;
          background: #e6e9f0;
        }

        .borderDiv {
          width: 80%;
          margin-left: 8px;
          border: 1px solid #e6e9f0;
          :last-child {
            border: none;
          }
        }
      }
      .dropDownListAmPm {
        top: -100px;
      }
    }
  }
  .timeField {
    width: 80px;
  }
  .fieldDiv {
    width: 100%;
    height: 44px;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px 10px 10px;
    position: relative;
    .dropDownList {
      max-height: 200px;
      width: 100%;
      background: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      top: 60px;
      left: 0;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      ul {
        padding: 10px 0;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 0;
        color: #262758;

        :hover {
          background: #ecf4f9;
          color: #262758;
        }
      }
      .borderDiv {
        width: 80%;
        margin-left: 8px;
        border: 1px solid #e6e9f0;
        :last-child {
          border: none;
        }
      }
    }
  }
  .inverted {
    transform: rotate(180deg);
  }
  @media screen and (min-width: 576px) {
    .pickerModalContainer {
      position: absolute;
      width: 348px;
      top: 40px;
      padding: 40px 22px;
      height: 88px;
      box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
      border-radius: 7.00366px;
      background: #ffffff;
      z-index: 10;
    }
    .timeField {
      width: 90px;
    }
    .fieldDiv {
      height: 50px;
      .dropDownList {
        top: 76px;
      }
    }
    .innerTabPicker {
      width: 288px;
      top: 30px;
      right: 0;
      .timeField {
        width: 75px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .pickerModalContainer {
      padding: 40px 22px;
      right: -30px;
      width: 340px;
      height: 100px;
    }
    .fieldDiv {
      height: 54px;
    }
    .innerTabPicker {
      width: 350px;
      top: 30px;
      font-size: 16px;
      right: 0;
      .timeField {
        width: 95px;
      }
    }
  }
  @media screen and (min-width: 1900px) {
    .pickerModalContainer {
      width: 440px;
      padding: 40px 32px;
      right: -80px;
      .timeField {
        width: 116px;
      }
    }
    .fieldDiv {
      height: 64px;
    }
    .innerTabPicker {
      width: 375px;
      right: 0;
      .timeField {
        width: 105px;
      }
    }
  }
`;

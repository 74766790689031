import { useRef, useState } from 'react';
import { DocumentsFieldStyle } from './styles';
const DocumentsField = ({
  setFile,
  file,
  setFileError,
  colorless,
  disabled,
  customStyle,
}: {
  setFile: Function;
  file: any;
  setFileError: Function;
  disabled?: boolean;
  colorless?: boolean;
  customStyle?: boolean;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    //     👇️ open file input box on click of other element
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (
      fileObj.type.split('/')[0] === 'video' ||
      fileObj.type.split('/')[0] === 'audio'
    ) {
      return setFileError(`Can't support this file`);
    }
    setFileError('');
    event.target.value = null;
    setFile(fileObj);
  };
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileObj = e.dataTransfer.files[0];
      if (!fileObj) {
        return;
      }
      if (
        fileObj.type.split('/')[0] === 'video' ||
        fileObj.type.split('/')[0] === 'audio'
      ) {
        return setFileError(`Can't support this file`);
      }
      setFileError('');
      e.target.value = null;
      setFile(fileObj);
    }
  };

  return (
    <div>
      <DocumentsFieldStyle>
        <div
          className={`DocumentDiv ${dragActive && 'disabledDiv'} ${
            colorless && 'TaskDiv'
          }  ${disabled && 'disabledDiv'} 
          ${customStyle && 'customDocument-style'}`}
          id='drag-file-element'
          onDragEnter={handleDrop}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <h6
            className={`dropText text-center  d-none d-sm-block ${
              disabled && 'disabledDropText'
            }`}
          >
            {colorless ? 'Drag and drop' : 'Drag and drop your file here'}
            <br />
            <h6>or</h6>
          </h6>

          <input
            className=''
            style={{ display: 'none' }}
            ref={inputRef}
            type='file'
            onChange={handleFileChange}
            disabled={disabled}
          />
          <div className='d-flex justify-content-center buttinsDiv'>
            <button
              className={`browseButton ${colorless && 'colourlessButton'} ${
                disabled && 'disabledButton'
              }`}
              type='button'
              onClick={handleClick}
            >
              Browse files
            </button>
          </div>
        </div>
      </DocumentsFieldStyle>
    </div>
  );
};

export default DocumentsField;

import styled from '@emotion/styled';

export const DocumentsFieldStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  .browseButton {
    background: #ecf4f9;
    border: none;
    color: #262758;
    // margin: 0 26px;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    width: 88vw;
    height: 64px;
  }
  .colourlessButton {
    background: none;
    border: none;
    color: #262758;
    border-radius: 32px;
    text-align: center;
    font-weight: 600;
    width: 100%;
    height: 64px;
  }
  .TaskDiv {
    width: 100%;
    border: 1px dashed #8990a3;
    border-radius: 16px;
    padding: 8px 89px;
  }
  .disabledButton {
    background: none;
    color: #8990a3;
  }
  .disabledDiv {
    background: #f5f7fc;
  }
  .customDocument-style {
    padding: 0;
    width: 100%;
  }
  .PolicyDiv {
    width: 100%;
    border: 1px dashed #8990a3;
    border-radius: 8px;
    padding: 12px 6px 12px 16px;
    .browseButton {
      text-align: right;
      width: 80%;
      height: 32px;
    }
    .label {
      color: #000000;
      width: 200px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .textRight {
      text-align: right;
    }
    .BinImage {
      width: 23px;
    }
    .editfield {
      background: #f5f7fc !important;
      box-shadow: none;
      position: relative;
      border: none;
      .label {
        position: absolute;
        font-size: 12px;
        color: #5a5f70;
        top: 4px;
      }
      .textRight {
        position: relative;
        text-align: left;
      }
      .browseButton {
        padding: 0;
      }
    }
  }
  @media screen and (min-width: 570px) {
    .DocumentDiv {
      width: 100%;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    .browseButton {
      background: #ecf4f9;
      border: none;
      color: #262758;
      margin-left: 16px;
      border-radius: 32px;
      text-align: center;
      font-weight: 600;
      width: 168px;
      height: 32px;
    }
    .PolicyDiv {
      .browseButton {
        text-align: right;
        height: 32px;
        width: 180px;
      }
    }
    .colourlessButton {
      margin: 0;
      background: none;
    }
    .disabledButton {
      background: none;
      color: #8990a3;
    }
    .disabledDiv {
      background: #f5f7fc;
    }

    .DocumentDiv {
      border: 1px dashed #8990a3;
      border-radius: 16px;
      padding: 64px 89px;
    }
    .customDocument-style {
      padding: 0;
    }
    .dropText {
      line-height: 42px;
      font-size: 16px;
      color: #191919;
    }
    .disabledDropText {
      line-height: 42px;
      font-size: 16px;
      color: #8990a3;
    }
    .TaskDiv {
      padding: 8px 89px;
    }
    .editfield {
      background: #f5f7fc !important;
      box-shadow: none;
      position: relative;
      border: none;
      .label {
        position: absolute;
        color: #5a5f70;
        top: 4px;
        font-size: 12px;
      }
      .textRight {
        position: relative;
        text-align: left;
      }
      .browseButton {
        padding: 0;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .DocumentDiv {
      width: 100%;
    }
  }
  @media screen and (min-width: 1900px) {
    .DocumentDiv {
      width: 100%;
    }
    .browseButton {
      margin-left: 16px;
      text-align: center;
      font-weight: 600;
      width: 168px;
      height: 48px;
    }
  }
`;

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { StyledEditDetailsPage } from './styles';
import cross from '../../../assets/logo/cross.svg';
import EditInput from '../../../components/DetailsEditFormInput';
import EditSelect from '../../../components/SelectFieldEdit';
import EditPhoneNumber from '../../../components/PhoneNumberFormatInEditForms';
import EditDate from '../../../components/DateFieldEdit';
import Modal from '../../../components/EditDetailsModal';
import tick from '../../../assets/logo/tick.svg';
import SuccessCard from '../../../components/SuccessCard';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import { AppDispatch } from '../../../store';
import { getAccountStatus } from '../../../api/account';
import { fetchContacts } from '../../../api/contact';
import warning from '../../../assets/logo/warning.svg';
import { useNavigate } from 'react-router-dom';
import { IJurisdiction } from '../../../interfaces/Jurisdiction';
import { fetchJurisdiction } from '../../../api/jurisdiction';
import moment from 'moment';
import {
  updateAccountDetails,
  getAccountDetails,
  selectAccount,
} from '../../../store/accounts/accountsSlice';
import SearchDropdown from '../../../components/SearchDropdown';
import { fetchIndustries } from '../../../api/industry';
import GoogleSearchInput from '../../../components/GoogleSearchInput';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../../store/customFields/customFieldsSlice';
import CustomFieldInput from '../../../components/CustomfieldsInput';
import { ICustomField } from '../../../interfaces/CustomField';
import { updateContactDetailAccount } from '../../../store/contacts/contactsSlice';

const SignupSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email. Please try again'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
});

const EditAccountDetails = ({
  show,
  closeModal,
  uuid,
}: {
  show: string;
  closeModal: Function;
  uuid: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const [commercial, isCommercial] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [contactUUID, setContactUUID] = useState('');
  const [accountName, setAccountName] = useState('');
  const [status, setStatus] = useState<any[]>([]);
  const [addressObject, setAddressObject] = useState<any>({});
  const [address, setAddress] = useState('');

  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);

  const [submit, setSubmit] = useState(false);

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [jurisdictionSearch, setJurisdictionSearch] = useState('');
  const [isManual, setIsManual] = useState(false);

  const [errorMessage, setError] = useState('');

  const watchLastName = watch('lastName');

  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (licensedState) {
        fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
          setLicensedState(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setLicensedState([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  const accountDetails = useSelector(selectAccount);

  const watchAllFields = watch();

  const customFields = useSelector(selectAllCustomFields);

  useEffect(() => {
    if (errorMessage) {
      setError('');
    }
  }, [watchAllFields.phoneNumber]);

  const onSubmit = (data: any) => {
    data.customFields = {};
    customFields.map((item: any) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.customFields[item.uuid] = timeItem.value;
        } else {
          data.customFields[item.uuid] = data[item.uuid];
        }
      }
    });
    setSubmit(true);
    var canSubmit = true;
    if (industrySearch && !industryUuid) {
      canSubmit = false;
    }
    let errorLastName = true;
    if (!commercial && !watchLastName) {
      errorLastName = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (emailExist && emailExist?.uuid !== contactUUID) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    }
    if (
      !emailError &&
      emailSearch &&
      canSubmit &&
      !(emailExist && emailExist?.uuid !== contactUUID) &&
      errorLastName === true
    ) {
      data.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';
      data.maritalStatus = data.maritalStatus?.value;
      data.jurisdictionUUID = jurisdictionsUUID;
      data.statusUUID = data.status?.value;
      data.action = 'updateAccount';
      data.email = emailSearch;
      data.contactUUID = contactUUID;
      data.industry = industryUuid;
      data.isManuallyAdded = isManual;
      //ToDo
      // // To add addressObject with the form
      if (!isManual) {
        const addObj = {
          address: addressObject.address,
          city: watchAllFields?.city,
          state: watchAllFields?.state,
          country: watchAllFields.country,
          zipCode: watchAllFields?.zipCode,
        };
        data = Object.assign(data, addObj);
      }
      dispatch(
        updateAccountDetails({
          data,
          uuid,
          successCB: (data: any) => {
            dispatch(
              updateContactDetailAccount({
                account: data,
                type: 'update',
              })
            );
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: (error: string) => {
            if (typeof error === 'string') setError(error);
          },
        })
      );
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show === 'open') {
      setIsLoading(true);
      dispatch(
        getAccountDetails({
          uuid,
          successCB: (account: any) => {
            getAccountStatus((data: any) => {
              setStatus(data);
            });
            if (account.AccountType.name === 'COMMERCIAL') {
              isCommercial(true);
            }
            if (account.AccountContacts[0].Contact?.dateOfBirth) {
              setValue(
                'dateOfBirth',
                moment(account.AccountContacts[0].Contact?.dateOfBirth).toDate()
              );
            } else {
              setValue('dateOfBirth', null);
            }
            setValue(
              'firstName',
              account.AccountContacts[0].Contact?.firstName
            );
            setValue(
              'middleName',
              account.AccountContacts[0]?.Contact?.middleName
            );
            setValue('lastName', account.AccountContacts[0]?.Contact?.lastName);

            setValue(
              'driverlicenseNumber',
              account.AccountContacts[0].Contact?.driverlicenseNumber
            );
            setJurisdictionsUUID(
              account.AccountContacts[0].Contact.Jurisdiction?.uuid
            );
            setJurisdictionSearch(
              account.AccountContacts[0].Contact.Jurisdiction?.name
            );
            if (
              account.AccountContacts[0].Contact.ContactDetails[0]?.type ===
              'EMAIL'
            ) {
              setEmailSearch(
                account.AccountContacts[0].Contact.ContactDetails[0]?.value
              );
              setValue(
                'phoneNumber',
                account.AccountContacts[0].Contact.ContactDetails[1]?.value
              );
            } else {
              setEmailSearch(
                account.AccountContacts[0].Contact.ContactDetails[1]?.value
              );
              setValue(
                'phoneNumber',
                account.AccountContacts[0].Contact.ContactDetails[0]?.value
              );
            }
            setValue('maritalStatus', {
              value: account.AccountContacts[0].Contact?.maritalStatus,
              label: account.AccountContacts[0].Contact?.maritalStatus,
            });
            setValue('accountType', account.AccountType?.name);
            setValue('name', account.name);
            account.name
              ? setAccountName(account.name)
              : setAccountName('Account Info');
            setValue('status', {
              value: account?.AccountStatus.uuid,
              label: account?.AccountStatus.name,
            });
            setContactUUID(account.AccountContacts[0]?.Contact?.uuid);
            if (account.AccountType?.name === 'COMMERCIAL') {
              setValue('businessEmail', account.businessEmail);

              setValue('businessPhoneNumber', account.businessPhoneNumber);
              setValue('payroll', account.payroll);
              setValue('employeeCount', account.employeeCount);
              setValue('annualRevenue', account.annualRevenue);
              setValue('businessStartedYear', account.businessStartedYear);

              // setValue('businessaddress', account.Address?.city);

              setValue('businessapt', account.Address?.street);
              if (account?.Industry !== '' && account?.Industry !== null) {
                setIndustrySearch(
                  `${account?.Industry?.naicsSixDigitCode} - ${account?.Industry?.naicsSixDigitCodeDescription}`
                );
                setIndustryUuid(account?.Industry?.uuid);
              }
            }
            setAddress(account.Address?.name);

            setValue('zipCode', account.Address?.zipCode);
            setValue('city', account.Address?.city);
            setValue('state', account.Address?.state);
            setValue('country', account.Address?.country);
            // setValue('address', account.Address?.city);
            if (account.Address?.isManuallyAdded) {
              setIsManual(true);
              setValue('address', account.Address?.name);
            } else {
              setAddressObject({
                address: account.Address?.name,
                city: account.Address?.city,
                state: account.Address?.state,
                country: account.Address?.country,
                zipCode: account.Address?.zipCode,
              });
            }
            setValue('apartment', account.Address?.street);
            dispatch(
              getCustomFields({
                uuid: '',
                resourceName: 'ACCOUNT',
                successCB: (data: ICustomField[]) => {
                  data.map((item) => {
                    if (
                      item.FieldType?.name === 'DATEPICKER' &&
                      item.data?.defaultValue
                    ) {
                      setValue(
                        item.uuid,
                        moment(item.data?.defaultValue).toDate()
                      );
                    }
                    if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
                      const filteredData = item.data.options.filter(
                        (it) => it.default === true
                      );
                      setValue(
                        item.uuid,
                        filteredData.map((el) => {
                          return { value: el.order, label: el.value };
                        })
                      );
                    }
                    if (item.FieldType?.name === 'SINGLE_CHOICE') {
                      if (item.data?.defaultValue) {
                        setValue(item.uuid, {
                          label:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.value,
                          value:
                            item.data.options[
                              parseInt(item.data?.defaultValue, 10)
                            ]?.order,
                        });
                      }
                    }
                  });

                  if (account.AccountCustomField?.length > 0) {
                    account.AccountCustomField.map((item: any) => {
                      if (item.CustomField.FieldType?.name === 'DATEPICKER') {
                        setValue(
                          item.CustomField.uuid,
                          moment(item.value).toDate()
                        );
                      } else if (item.CustomField.FieldType?.name === 'TIME') {
                        setDueTimeVal([
                          ...duetimeVal,
                          {
                            uuid: item.CustomField.uuid,
                            value: moment(item.value).toDate(),
                          },
                        ]);
                      } else {
                        setValue(item.CustomField.uuid, item.value);
                      }
                    });
                  }
                  setIsLoading(false);
                },
              })
            );
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);

  const navigate = useNavigate();

  const loadEmail = (value: string) => {
    setSubmit(false);
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setEmailAccounts([]);
  };

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (industrySearch) {
        fetchIndustries(
          (data: any) => {
            setIndustry(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setIndustry([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  useEffect(() => {
    setValue('zipCode', addressObject.zipCode);
    setValue('city', addressObject.city);
    setValue('state', addressObject.state);
    setValue('country', addressObject.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressObject]);

  const resetJurisdiction = (value: string) => {
    setLicensedState([]);
    setJurisdictionsUUID('');
  };

  const resetIndustry = (value: string) => {
    setIndustry([]);
    setIndustryUuid('');
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success updating account!</b>`}
            message={` <b class='name-text-overflow'>${getValues(
              'firstName'
            )} ${getValues('lastName')}</b>  has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setShowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledEditDetailsPage>
        <Modal open={show}>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                <h2>Edit account</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div className='d-flex align-items-center'>
                  <h2 className='contact-info'>Account info</h2>
                </div>
                <div className=' d-flex flex-wrap'>
                  {!commercial && (
                    <div className={`fields ${errors.name && 'error-name'}`}>
                      <EditInput
                        name='name'
                        type='text'
                        placeholder='Account name'
                        register={register}
                      />
                      {errors.name ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.name?.message}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className='fields '>
                    <EditInput
                      name='accountType'
                      type='text'
                      placeholder='Insurance type'
                      register={register}
                      disabled={true}
                    />
                  </div>
                  <div className='fields '>
                    <EditSelect
                      options={status.map((item: any) => {
                        return { label: item.name, value: item.uuid };
                      })}
                      name='status'
                      type='text'
                      placeholder='Account status'
                      control={control}
                    />
                  </div>
                  {!commercial && (
                    <div className='d-flex flex-wrap'>
                      {customFields?.length > 0
                        ? customFields.map((item: any, index: number) => (
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                                isEdit={true}
                                setDueTimeVal={setDueTimeVal}
                                duetimeVal={duetimeVal}
                              />
                            </div>
                          ))
                        : null}
                    </div>
                  )}
                </div>

                <hr />
                {commercial && (
                  <>
                    <div className='CommercialDiv'>
                      <div className='d-flex align-items-center'>
                        <h2 className='contact-info'>{accountName}</h2>
                      </div>
                      <div className='d-flex flex-wrap'>
                        <div
                          className={`fields ${errors.name && 'error-name'}`}
                        >
                          <EditInput
                            name='name'
                            type='text'
                            placeholder='Business name'
                            register={register}
                          />
                          {errors.name ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.name?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields '>
                          <SearchDropdown
                            name='industry'
                            placeholder='Industry (Optional)'
                            isEdit={true}
                            isEditDetail={true}
                            values={industrySearch}
                            searchValue={industry}
                            setSearch={setIndustrySearch}
                            status={loadingStatus}
                            className={`${industrySearch && 'filled'}`}
                            register={register}
                            control={control}
                            loadFunction={loadIndustry}
                            setUUID={resetIndustry}
                            content={industry?.map((item: any) => (
                              <div
                                className='searchValuesCard d-flex align-items-center account-details'
                                onClick={() => {
                                  setIndustrySearch(
                                    `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                  );
                                  setIndustryUuid(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>
                                    {item.naicsSixDigitCode} -{' '}
                                    {item.naicsSixDigitCodeDescription}
                                  </h6>
                                  <p className='description'>
                                    {item.naicsFourDigitCodeDescription}
                                  </p>
                                </div>
                              </div>
                            ))}
                          />
                          {industrySearch && !industryUuid && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Industry not found
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className='fields '>
                          <EditInput
                            name='businessPhoneNumber'
                            type='tel'
                            placeholder='Business phone number'
                            register={register}
                          />
                        </div>
                        <div className='fields '>
                          <EditInput
                            name='businessEmail'
                            type='email'
                            placeholder='Business email address'
                            register={register}
                          />
                        </div>

                        <div className='fields '>
                          <EditInput
                            name='employeeCount'
                            type='text'
                            placeholder='Number of employees'
                            register={register}
                          />
                        </div>

                        <div className='fields '>
                          <EditInput
                            name='businessStartedYear'
                            type='text'
                            placeholder='Year business started'
                            register={register}
                          />
                        </div>
                        <div className='fields '>
                          <EditInput
                            name='payroll'
                            type='text'
                            placeholder='Total payroll'
                            register={register}
                          />
                        </div>
                        <div className='fields '>
                          <EditInput
                            name='annualRevenue'
                            type='text'
                            placeholder='Annual revenue'
                            register={register}
                          />
                        </div>
                      </div>
                    </div>
                    <h6 className='mailingAdressHead'>BUSINESS ADDRESS</h6>
                    <div className='  d-sm-flex flex-wrap'>
                      <div className='fields '>
                        {isManual ? (
                          <EditInput
                            name='address'
                            type='text'
                            placeholder='Address'
                            register={register}
                          />
                        ) : (
                          <GoogleSearchInput
                            placeholder='Business address'
                            name='businessaddress'
                            inputType='EDIT'
                            initialValue={address}
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        )}
                      </div>

                      <div className='fields '>
                        <EditInput
                          name='zipCode'
                          type='number'
                          placeholder='Zipcode'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='city'
                          type='text'
                          placeholder='City'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='state'
                          type='text'
                          placeholder='State'
                          register={register}
                        />
                      </div>

                      <div className='fields '>
                        <EditInput
                          name='country'
                          type='text'
                          placeholder='County'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='businessapt'
                          type='text'
                          placeholder='Apt'
                          register={register}
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-wrap'>
                      {customFields?.length > 0
                        ? customFields.map((item: any, index: number) => (
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                                isEdit={true}
                                setDueTimeVal={setDueTimeVal}
                                duetimeVal={duetimeVal}
                              />
                            </div>
                          ))
                        : null}
                    </div>
                    <hr />
                  </>
                )}

                <div className='editHeading'>
                  <h2 className='contact-info '>Contact info</h2>
                </div>
                <div className=' d-flex flex-wrap'>
                  <div className={`fields ${errors.firstName && 'error-name'}`}>
                    <EditInput
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      register={register}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`fields ${errors.middleName && 'error-name'}`}
                  >
                    <EditInput
                      name='middleName'
                      type='text'
                      placeholder='Middle name'
                      register={register}
                    />
                    {errors.middleName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.middleName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className={`fields ${errors.lastName && 'error-name'}`}>
                    <EditInput
                      name='lastName'
                      type='text'
                      placeholder='Last name'
                      register={register}
                      required
                    />
                    {errors.lastName ||
                    (!commercial && !watchLastName && submit) ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.lastName?.message || 'Last name is required'}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields '>
                    <EditDate
                      name='dateOfBirth'
                      placeholder='Date of birth'
                      maxDate={new Date()}
                      dateOfBirth
                      control={control}
                    />
                  </div>

                  <div className='fields '>
                    <EditPhoneNumber
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      control={control}
                      required
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields '>
                    {/* <EditInput
                      name='email'
                      type='email'
                      placeholder='Email'
                      register={register}
                    /> */}
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      isEditDetail={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      control={control}
                      type='email'
                      required
                      setUUID={resetEmail}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      [
                        !emailSearch && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter your email
                          </div>
                        ) : (
                          errors.email?.message && (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.email?.message}
                            </div>
                          )
                        ),
                      ]
                    )}
                  </div>
                  <div className='fields '>
                    <EditSelect
                      control={control}
                      type='text'
                      name='maritalStatus'
                      placeholder='Marital status'
                      options={MaritalStatus}
                    />
                  </div>
                  <div className='fields'>
                    <SearchDropdown
                      name='jurisdictionUUID'
                      placeholder='Licensed state'
                      isEdit={true}
                      isEditDetail={true}
                      values={jurisdictionSearch}
                      searchValue={licensedState}
                      setSearch={setJurisdictionSearch}
                      status={loadingStatus}
                      className={`${
                        jurisdictionSearch &&
                        !jurisdictionsUUID &&
                        submit &&
                        'error-field'
                      } ${jurisdictionSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadLicensedState}
                      setUUID={resetJurisdiction}
                      content={
                        licensedState.length > 0
                          ? licensedState?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setJurisdictionSearch(item?.name);
                                  setJurisdictionsUUID(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>{item.name}</h6>
                                </div>
                              </div>
                            ))
                          : null
                      }
                    />
                  </div>

                  <div className='fields '>
                    <EditInput
                      name='driverlicenseNumber'
                      type='tel'
                      placeholder='License number'
                      register={register}
                    />
                  </div>
                </div>
                {!commercial ? (
                  <div>
                    <h6 className='mailingAdressHead'>MAILING ADDRESS</h6>
                    <div className='d-flex flex-wrap'>
                      <div className='fields '>
                        {isManual ? (
                          <EditInput
                            name='address'
                            type='text'
                            placeholder='Street address'
                            register={register}
                          />
                        ) : (
                          <GoogleSearchInput
                            placeholder='Address'
                            name='address'
                            inputType='EDIT'
                            initialValue={address}
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        )}
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='zipCode'
                          type='number'
                          placeholder='ZipCode'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='city'
                          type='text'
                          placeholder='City'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='state'
                          type='text'
                          placeholder='State'
                          register={register}
                        />
                      </div>

                      <div className='fields '>
                        <EditInput
                          name='country'
                          type='text'
                          placeholder='County'
                          register={register}
                        />
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='apartment'
                          type='text'
                          placeholder='Apt'
                          register={register}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    onClick={() => setSubmit(true)}
                    disabled={
                      show === 'closing' ||
                      (watchAllFields?.name === accountDetails?.name &&
                        watchAllFields?.status?.value ===
                          accountDetails?.AccountStatus?.uuid &&
                        watchAllFields?.firstName ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.firstName &&
                        watchAllFields?.middleName ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.middleName &&
                        watchAllFields?.lastName ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.lastName &&
                        watchAllFields?.phoneNumber ===
                          accountDetails?.AccountContacts[0]?.Contact.ContactDetails.find(
                            (item: any) => item.type === 'PHONENUMBER'
                          )?.value &&
                        emailSearch ===
                          accountDetails?.AccountContacts[0]?.Contact.ContactDetails.find(
                            (item: any) => item.type === 'EMAIL'
                          )?.value &&
                        watchAllFields?.maritalStatus?.value ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.maritalStatus &&
                        jurisdictionsUUID ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.Jurisdiction?.uuid &&
                        watchAllFields?.driverlicenseNumber ===
                          accountDetails?.AccountContacts[0]?.Contact
                            ?.driverlicenseNumber &&
                        moment(watchAllFields?.dateOfBirth).toISOString() ===
                          accountDetails?.AccountContacts[0]?.Contact
                            .dateOfBirth &&
                        ((isManual &&
                          watchAllFields?.address ===
                            accountDetails?.Address?.name) ||
                          (!isManual &&
                            addressObject?.address ===
                              accountDetails?.Address?.name)) &&
                        watchAllFields?.zipCode ===
                          accountDetails?.Address?.zipCode &&
                        watchAllFields?.state ===
                          accountDetails?.Address?.state &&
                        watchAllFields?.city ===
                          accountDetails?.Address?.city &&
                        watchAllFields?.country ===
                          accountDetails?.Address?.country &&
                        ((!commercial &&
                          watchAllFields?.apartment ===
                            accountDetails?.Address?.street) ||
                          (commercial &&
                            watchAllFields?.name === accountDetails?.name &&
                            industryUuid === accountDetails?.Industry?.uuid &&
                            watchAllFields?.businessPhoneNumber ===
                              accountDetails?.businessPhoneNumber &&
                            watchAllFields?.businessEmail ===
                              accountDetails?.businessEmail &&
                            watchAllFields?.businessStartedYear ===
                              accountDetails?.businessStartedYear &&
                            watchAllFields?.annualRevenue ===
                              accountDetails?.annualRevenue &&
                            watchAllFields?.employeeCount ===
                              accountDetails?.employeeCount &&
                            watchAllFields?.payroll ===
                              accountDetails?.payroll &&
                            watchAllFields?.businessapt ===
                              accountDetails?.Address?.street)) &&
                        (customFields?.length > 0
                          ? customFields?.every((item: any) => {
                              const opportunityCustom =
                                accountDetails.AccountCustomField?.find(
                                  (el: any) => el.CustomField.uuid === item.uuid
                                );
                              if (item.FieldType.name === 'SINGLE_CHOICE') {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid]?.label ===
                                    opportunityCustom.value?.label
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.label !==
                                    undefined &&
                                  watchAllFields[item.uuid]?.value !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (
                                item.FieldType.name === 'MULTIPLE_CHOICE'
                              ) {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid].every(
                                      (watchEl: any) => {
                                        return opportunityCustom.value.find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    ) &&
                                    opportunityCustom.value.every(
                                      (watchEl: any) => {
                                        return watchAllFields[item.uuid].find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    )
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.length > 0
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (item.FieldType.name === 'TIME') {
                                let timeItem = duetimeVal.find(
                                  (el: any) => el.uuid === item.uuid
                                );
                                if (opportunityCustom && timeItem) {
                                  return (
                                    moment(timeItem?.value).toISOString() ===
                                    opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  timeItem !== '' &&
                                  timeItem !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (item.FieldType.name === 'DATEPICKER') {
                                if (opportunityCustom) {
                                  return (
                                    moment(
                                      watchAllFields[item.uuid]
                                    ).toISOString() === opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid] !== '' &&
                                  watchAllFields[item.uuid] !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid] ===
                                    opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid] !== '' &&
                                  watchAllFields[item.uuid] !== undefined &&
                                  watchAllFields[item.uuid] !== false
                                ) {
                                  return false;
                                }
                                return true;
                              }
                            })
                          : true)) ||
                      isLoading
                    }
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </StyledEditDetailsPage>
    </>
  );
};

export default EditAccountDetails;

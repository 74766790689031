import styled from '@emotion/styled';

export const GlobalContainer = styled.div`
  .mobile-sidebar {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    .activeCarrierTab {
      display: inline-block;
      border-bottom: 3px solid #000;
      margin-bottom: -2px;
      padding-bottom: 6px;
    }
    .mobileTab-text {
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .container-div {
      padding: 32px;
    }
  }
  @media screen and (max-width: 576px) {
    .container-div {
      padding: 20px;
    }
  }
`;

export const GlobalTC = styled.div`
  padding-top: 30px;
  height: calc(100vh - 60px);

  .sideBar {
    padding-top: 40px;
    border-right: 1px solid #e6e9f0;

    padding-right: 32px;
    margin-right: 32px;
    height: 100%;

    .back-btn-div {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      cursor: pointer;
      img {
        margin-right: 12px;
      }
    }
    .navlink {
      border: none;
      display: block;
      margin: 16px 0px;
      background: #fff;
      border-radius: 8px;
      width: 240px;
      color: #000;
      text-decoration: none;
      padding: 14px 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .active {
      font-weight: 600;
      background: #c1dbeb;
    }
  }
  @media screen and (min-width: 576px) {
    height: calc(100vh - 40px);
  }
  @media screen and (min-width: 1200px) {
    .sideBar {
      padding-right: 48px;
      margin-right: 48px;
    }
  }
  @media screen and (min-width: 1900px) {
    height: calc(100vh - 60px);
    .navlink {
      font-size: 16px;
      line-height: 24px;
    }
    .back-btn-div {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const GlobalTCContainer = styled.div`
  padding-top: 24px;
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
  }
  .updated-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a5f70;
    margin-bottom: 32px;
  }
  .content {
    height: calc(100vh - 240px);
    overflow: auto;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 40px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 43px;
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  ol {
    padding-left: 23px;
    line-height: 22px;
    font-size: 14px;
    ol {
      list-style: lower-alpha;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 40px;
    h2 {
      font-size: 28px;
      line-height: 40px;
    }
    .updated-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-width: 1900px) {
    h2 {
      font-size: 36px;
      line-height: 52px;
    }
    h3 {
      font-size: 24px;
      line-height: 36px;
    }
    .content {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

import React from 'react';
import { ValidationDiv } from './styles';
import tick from '../../assets/logo/success.svg';
import warning from '../../assets/logo/erroMark.svg';

interface ICheck {
  message: string;
  check: Function;
}
interface IProps {
  checks: ICheck[];
  password: string;
}

const PasswordValidation = ({ checks, password }: IProps) => {
  return (
    <ValidationDiv>
      {checks.map((item: ICheck) => (
        <ul key={item.message} className='d-flex align-items-center'>
          <img
            className='statusIcon'
            src={item.check(password) ? tick : warning}
            alt='validation-icon'
          />
          {item.message}
        </ul>
      ))}
    </ValidationDiv>
  );
};

export default PasswordValidation;

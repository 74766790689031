import {
  Link as RouterLink,
  LinkProps,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';

function Link({ children, to, ...props }: LinkProps) {
  const location = useLocation();
  let resolved = useResolvedPath(to);
  // let match = useMatch({ path: resolved.pathname, end: true });
  let match =
    location.pathname.includes(resolved.pathname) ||
    (resolved.pathname === '/mybook' && location.pathname.includes('/account'));

  return (
    <RouterLink
      className={`navlink ${match ? 'active' : ''}`}
      to={to}
      {...props}
    >
      {children}
    </RouterLink>
  );
}

export default Link;

export const LinkImage = ({
  name,
  className,
  activeIcon,
  inactiveIcon,
}: {
  name: string;
  className: string;
  activeIcon: string;
  inactiveIcon: string;
}) => {
  const location = useLocation();
  let resolved = useResolvedPath('/' + name);
  let match = location.pathname.includes(resolved.pathname);

  return (
    <img
      // src={'../../assets/nav-icons/' + name + (match ? '-active' : '') + '.png'}
      src={match ? activeIcon : inactiveIcon}
      alt={name}
      className={className}
    />
  );
};

import { useSelector } from 'react-redux';
import { selectCount } from '../../../store/book/bookSlice';
import Link from '../../../components/Link';
import { StyledTab } from '../../../styles/StyledTab';

const Tab = () => {
  const counts = useSelector(selectCount);

  return (
    <StyledTab>
      <Link to='/mybook/leads'>
        Leads
        <span className='count'>{counts.leads}</span>
      </Link>
      <Link to='/mybook/opportunities'>
        Opportunities
        <span className='count'>{counts.opportunities}</span>
      </Link>
      <Link to='/mybook/accounts'>
        Accounts
        <span className='count'>{counts.accounts}</span>
      </Link>
      <Link to='/mybook/policies'>
        Policies
        <span className='count'>{counts.policies}</span>
      </Link>
      <Link to='/mybook/contacts'>
        Contacts
        <span className='count'>{counts.contacts}</span>
      </Link>
    </StyledTab>
  );
};

export default Tab;

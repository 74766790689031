import React, { useCallback } from 'react';
import { EditorContainer } from './style';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const RichTextEditor = ({
  placeholder,
  note,
  multi,
  index,
  setNote,
  uuid,
  autoFocus,
  onBlur,
  textCheckFunction,
}: {
  placeholder?: string;
  textCheckFunction?: Function;
  note: string;
  uuid?: string;
  index?: number;
  multi?: boolean;
  setNote: Function;
  autoFocus?: boolean;
  onBlur?: Function;
}) => {
  let modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['code-block'],
    ],
  };

  let formats = ['bold', 'italic', 'underline', 'align', 'code-block'];

  const handleChange = (html: any, delta: any, source: any, editor: any) => {
    if (textCheckFunction) {
      textCheckFunction(editor.getText());
    }

    let content = '';
    if (editor.getLength() > 1) {
      content = html;
    }
    if (multi) {
      setNote(content, index, uuid);
    } else {
      setNote(content);
    }
  };

  const useFocusAndSetRef = (ref: any) => {
    ref = useCallback(
      (node: any) => {
        if (node !== null && autoFocus) {
          ref.current = node;
          const len = node.unprivilegedEditor.getLength();
          const selection = { index: len, length: len };
          node.setEditorSelection(node.editor, selection);
        }
      },
      [ref]
    );
    return ref;
  };

  let editorRef;
  editorRef = useFocusAndSetRef(editorRef);

  return (
    <EditorContainer toolbarDisplay={note ? 'block' : 'none'}>
      <ReactQuill
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        onChange={handleChange}
        ref={editorRef}
        defaultValue={note}
      />
    </EditorContainer>
  );
};

export default RichTextEditor;

import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { IAgent } from '../../../interfaces/User';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import vector from '../../../assets/logo/dropdown.svg';
import phone from '../../../assets/logo/phone.svg';
import mail from '../../../assets/logo/mail.svg';
import dollar from '../../../assets/logo/dollar.svg';
import power from '../../../assets/logo/power.svg';
import redpower from '../../../assets/logo/power.png';
import DeactivateUser from '../../Admin/AgencyDetails/components/DeactivateUser';
import { updateAgentDetail } from '../../../store/agents/agentsSlice';
import edit from '../../../assets/logo/edit.svg';
import LocalStorageService from '../../../utils/LocalStorageService';

const ActionsCard = ({
  agents,
  loadingStatus,
  setUUID,
  setShowGoalModal,
  setShowEditModal,
  scroll,
  setData,
}: {
  agents: IAgent;
  loadingStatus: any;
  setUUID: Function;
  setShowGoalModal: Function;
  scroll: boolean;
  setShowEditModal: Function;
  setData: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowDropdown);
  const dispatch = useDispatch<AppDispatch>();
  const user = LocalStorageService.getUser();
  useEffect(() => {
    setShowDropdown(false);
  }, [scroll]);
  const updateAgentStatus = (
    uuid: string,
    data: { action: string; status: string }
  ) => {
    if ((loadingStatus === 'idle' || loadingStatus === 'succeeded') && uuid) {
      dispatch(updateAgentDetail({ uuid, data }));
    }
  };

  return (
    <>
      <div className='d-flex align-items-center justify-content-between h-100'>
        <div className='action-item'>
          <img src={phone} alt='phone' />
        </div>
        <div className='action-item'>
          <img src={mail} alt='mail' />
        </div>
        <button
          className='vector d-flex align-items-center justify-content-center position-relative'
          onClick={() => {
            setShowDropdown(!showDropdown);
            if (dropRef.current) {
              dropRef.current.focus();
              setDropDownPosition({
                x: dropRef.current?.getBoundingClientRect().x,
                y: dropRef.current?.getBoundingClientRect().y,
              });
            }
          }}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setShowDropdown(false);
            }
          }}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showDropdown && (
            <div
              className='action-dropContainer position-fixed '
              style={{
                top: dropDownPosition.y + 33,
                left: dropDownPosition.x - 170,
              }}
            >
              <div className='drop-Div'>
                <div
                  className='agent-drop edit-div'
                  onClick={() => {
                    setShowEditModal('open');
                    setData(agents);
                    setShowDropdown(false);
                  }}
                  tabIndex={0}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div className='borderDiv'></div>
                <div
                  className='agent-drop mt-1'
                  onClick={() => {
                    setShowGoalModal('open');
                    setUUID(agents?.uuid);
                    setShowDropdown(false);
                  }}
                  tabIndex={1}
                >
                  Set Goals <img src={dollar} alt='dollar' />
                </div>
                <div className='borderDiv'></div>
                {agents?.AgentStatus?.name === 'ACTIVE' ? (
                  <div
                    className={` ${
                      agents.User.uuid === user?.uuid
                        ? 'disableStyle'
                        : 'inactive-drop'
                    }`}
                    onClick={() => {
                      if (agents.User.uuid === user?.uuid) {
                        setShowDeactivateModal(false);
                      } else {
                        setShowDeactivateModal(true);
                      }
                    }}
                    tabIndex={2}
                  >
                    Deactivate
                    <img src={redpower} alt='redpower' />
                  </div>
                ) : (
                  <div>
                    <div
                      className='active-drop'
                      onClick={() => {
                        updateAgentStatus(agents?.uuid, {
                          action: 'updateStatus',
                          status: 'ACTIVE',
                        });
                      }}
                      tabIndex={3}
                    >
                      Activate
                      <img src={power} alt='power' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </button>
        {showDeactivateModal && (
          <DeactivateUser
            showModal={showDeactivateModal}
            closeModal={setShowDeactivateModal}
            desablingAgent={agents}
          />
        )}
      </div>
    </>
  );
};

export default ActionsCard;

import { getAllActivties } from '../../api/activites';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
interface IProps {
  sortBy: string;
  searchBy?: string;
  orderBy: string;
  duration?: string;
  showAgency?: string;
}
export type ActivitySliceState = {
  status: string;
  activities: [];
  isLoading: boolean;
};
const initialState: ActivitySliceState = {
  status: 'idle',
  activities: [],
  isLoading: false,
};
export const getActivties = createAsyncThunk(
  'activities/getActivties',
  async ({ sortBy, orderBy, duration, showAgency }: IProps) => {
    const response = await getAllActivties(
      sortBy,
      orderBy,
      duration,
      showAgency
    );
    return response.data;
  }
);
const activitySlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getActivties.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getActivties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activities = action.payload.activities;
      })
      .addCase(getActivties.pending, (state, action) => {
        state.status = 'loading';
      });
  },
});

export const { updateStatus } = activitySlice.actions;

export default activitySlice.reducer;

export const selectAllActivities = (state: {
  activities: ActivitySliceState;
}) => {
  return state.activities?.activities;
};

import React from 'react';
import tick from '../../assets/logo/success.svg';
import { SuccesDiv } from './styles';

interface IProps {
  title: string;
  message: string;
  hideSuccess?: boolean;
}

function SuccessCard({ title, message, hideSuccess }: IProps) {
  return (
    <SuccesDiv>
      <div className='border'></div>
      <div className='message d-flex align-items-start'>
        <img src={tick} alt='Success' className='success-tick' />
        <div>
          {!hideSuccess && (
            <div
              className='success-text'
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          )}
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
    </SuccesDiv>
  );
}

export default SuccessCard;

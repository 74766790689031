import axios from './axios';
const getAllActivties = (
  sortBy: string,
  orderBy: string,
  duration?: string,
  showAgency?: string,
  type?: string,
  limit?: number,
  tasksOffset?: number,
  eventsOffset?: number,
  eventTypeUUID?: string[],
  taskTypeUUID?: string[],
  taskStatusUUID?: string[],
  taskPriorityUUID?: string[],
  assigneeUUID?: string,
  searchBy?: string
) => {
  return axios
    .get('/activities', {
      params: {
        sortBy,
        orderBy,
        duration,
        showAgency,
        type,
        limit,
        tasksOffset,
        eventsOffset,
        eventTypeUUID,
        taskTypeUUID,
        taskStatusUUID,
        taskPriorityUUID,
        assigneeUUID,
        searchBy,
      },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error.res;
      }
    );
};

const getActivtiesCount = (
  eventTypeUUID?: string[],
  taskTypeUUID?: string[],
  taskStatusUUID?: string[],
  taskPriorityUUID?: string[],
  assigneeUUID?: string,
  searchBy?: string
) => {
  return axios
    .get('/activities/count', {
      params: {
        eventTypeUUID,
        taskTypeUUID,
        taskStatusUUID,
        taskPriorityUUID,
        assigneeUUID,
        searchBy,
      },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error.res;
      }
    );
};

export { getAllActivties, getActivtiesCount };

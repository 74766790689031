import React from 'react';
import { WarningCardStyle } from '../elements/styles';
import errorMark from '../../../assets/logo/erroMark.svg';
interface IProps {
  message: string;
  title: string;
}

const WarningCard = ({ message, title }: IProps) => {
  return (
    <WarningCardStyle>
      <div className='border'></div>
      <div className='message d-flex align-items-start'>
        <img src={errorMark} alt='' className='error-icon' />
        <div>
          <div
            className='Heading'
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
    </WarningCardStyle>
  );
};

export default WarningCard;

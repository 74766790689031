import styled from '@emotion/styled';

export const SuccesDiv = styled.div`
  margin-top: 180px;
  padding: 15px 18px;
  width: 455px;
  height: 56px;
  position: relative;
  background: #d4fad4;
  border-radius: 8px;
  .border {
    position: absolute;
    color: #58c070;
    width: 10px;
    height: 58px;
    background: #58c070;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
  }
  .message {
    font-size: 14px;
    color: #262758;
  }
  .succestick {
    margin-right: 16px;
    margin-left: 12px;
  }
`;
export const ErrorDiv = styled.div`
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
    &.fieldError {
      color: #000;
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
`;

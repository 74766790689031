import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IAccount } from '../../../interfaces/Account';
import { IContact } from '../../../interfaces/Contact';
import edit from '../../../assets/logo/edit.svg';
import plus from '../../../assets/logo/blackplus.svg';
import chain from '../../../assets/logo/chain.svg';
import { contacts } from '../../../assets/nav-icons';

const DetailsDrawer = ({
  accountDetails,
  setShowEditModal,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  accountDetails?: IAccount;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
  setShowEditModal: Function;
}) => {
  const navigate = useNavigate();
  return (
    <div className='info-div'>
      <div className='details-div'>
        <div className='d-flex align-items-center'>
          <h5 className='section-title '>Details</h5>
          <img
            className='editIcon'
            src={edit}
            alt='edit'
            onClick={() => setShowEditModal('open')}
          />
        </div>
        <div className='sub-section'>
          <div className='subsection-title'>Created At</div>
          <div>{moment(accountDetails?.createdAt).format('MM/DD/YYYY')}</div>
          {accountDetails?.AccountStatus && (
            <>
              <div className='subsection-title'>Account status</div>
              <div>
                {accountDetails?.AccountStatus?.name
                  .toLowerCase()
                  .split('-')
                  .map(
                    (word: string) => word[0].toUpperCase() + word.substring(1)
                  )
                  .join(' ')}
              </div>
            </>
          )}
          {accountDetails?.AccountType && (
            <>
              <div className='subsection-title'>Account type</div>
              <div>
                {accountDetails?.AccountType?.name
                  .toLowerCase()
                  .split('-')
                  .map(
                    (word: string) => word[0].toUpperCase() + word.substring(1)
                  )
                  .join(' ')}
              </div>
            </>
          )}

          {accountDetails?.Industry && (
            <>
              <div className='subsection-title'>Industry</div>
              <div>
                {accountDetails?.Industry?.naicsSixDigitCode} -{' '}
                {accountDetails?.Industry?.naicsSixDigitCodeDescription}
              </div>
            </>
          )}
          {accountDetails?.employeeCount && (
            <>
              <div className='subsection-title'>Employee Count</div>
              <div>{accountDetails?.employeeCount}</div>
            </>
          )}
          {accountDetails?.annualRevenue && (
            <>
              <div className='subsection-title'>Annual Revenue</div>
              <div>{accountDetails?.annualRevenue}</div>
            </>
          )}
          {accountDetails?.payroll && (
            <>
              <div className='subsection-title'>Payroll</div>
              <div>{accountDetails?.payroll}</div>
            </>
          )}
          <div className='subsection-title'>
            {accountDetails?.AccountType?.name === 'PERSONAL'
              ? 'Home'
              : 'Business'}{' '}
            Address
          </div>
          {accountDetails?.Address?.isManuallyAdded ? (
            <div>
              {accountDetails?.Address?.street
                ? `${accountDetails?.Address?.street}, `
                : ''}{' '}
              {accountDetails?.Address?.name
                ? `${accountDetails?.Address?.name}, `
                : ''}
              {accountDetails?.Address?.city
                ? `${accountDetails?.Address?.city}, `
                : ''}{' '}
              {accountDetails?.Address?.state
                ? `${accountDetails?.Address?.state}, `
                : ''}{' '}
              {accountDetails?.Address?.zipCode}
            </div>
          ) : !accountDetails?.Address?.isManuallyAdded ? (
            <div>
              {accountDetails?.Address?.name
                ? `${accountDetails?.Address?.name}, `
                : '--'}

              {accountDetails?.Address?.zipCode}
            </div>
          ) : (
            <div>No specified address</div>
          )}
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title d-flex align-items-center'>
          Contacts
          {accountDetails?.AccountContacts &&
            accountDetails?.AccountContacts?.length > 0 && (
              <span className='count-div'>
                {accountDetails?.AccountContacts?.length}
              </span>
            )}
          <button
            type='button'
            className='plus-button'
            onClick={() => setShowAddContactModal('open')}
          >
            <img src={plus} alt='plus-icon' />
          </button>
        </h5>
        <div className='sub-section'>
          {accountDetails?.AccountContacts?.map(
            (item: { Contact: IContact }) => (
              <div className='text-overflow-container'>
                <div
                  key={item?.Contact.uuid}
                  className='nav-decor'
                  onClick={() => navigate(`/contacts/${item?.Contact.uuid}`)}
                >
                  <img src={contacts} alt='' className='contact-img' />
                  {item?.Contact.firstName} {item?.Contact.lastName}
                </div>
                <span className='tooltiptext tooltip-producer'>
                  <span className='tooltipInnertext'>
                    {item?.Contact.firstName} {item?.Contact.lastName}
                  </span>
                </span>
              </div>
            )
          )}
          <span
            className='LinkContactDiv'
            onClick={() => setShowLinkContactModal('open')}
          >
            <img className='iconImage' src={chain} alt='' /> Link contact
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailsDrawer;

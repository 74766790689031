import { ChangeEvent } from 'react';
import { FieldDiv } from '../InputField/styles';

interface IProps {
  name: string;
  type: string;
  placeholder: string;
  passwordValidationProps: {
    value: string;
    onPasswordChange: Function;
    onFocus?: Function;
    validationRequired: boolean;
  };
  isFilled: boolean;
  setFilled: Function;
  className?: string;
}

const PasswordField = ({
  name,
  type,
  placeholder,
  passwordValidationProps,
  isFilled,
  setFilled,
  className,
}: IProps) => {
  return (
    <FieldDiv>
      <div
        className={`d-flex flex-column w-100 input-container ${
          isFilled ? 'filled' : ''
        } ${className}`}
      >
        <label htmlFor={name} className='input-placeholder'>
          {placeholder}
        </label>
        <input
          name={name}
          className='input-field'
          type={type}
          autoComplete='off'
          autoFocus={true}
          value={passwordValidationProps.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            passwordValidationProps.onPasswordChange(e);
          }}
          onFocus={(e: ChangeEvent<HTMLInputElement>) => {
            if (passwordValidationProps.onFocus)
              passwordValidationProps.onFocus(e);
          }}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value) {
              setFilled(true);
            } else {
              setFilled(false);
            }
          }}
        />
      </div>
    </FieldDiv>
  );
};

interface IConfirmProps {
  name: string;
  type: string;
  placeholder: string;
  register: Function;
  className: string;
}

export const ConfirmPasswordField = ({
  name,
  type,
  placeholder,
  register,
  className,
}: IConfirmProps) => {
  return (
    <FieldDiv>
      <div className={`d-flex flex-column w-100 ${className} input-container`}>
        <label htmlFor={name} className='input-placeholder'>
          {placeholder}
        </label>

        <input
          name={name}
          className='input-field'
          type={type}
          autoComplete='off'
          {...register(name)}
        />
      </div>
    </FieldDiv>
  );
};

export default PasswordField;

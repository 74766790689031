import { INote } from '../interfaces/Note';
import axios from './axios';

const addNote = (data: INote, successCB: Function, errorCB: Function) => {
  return axios.post('/note', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB('Please try again.');
      return error.res;
    }
  );
};

const addEvent = (data: any, successCB: Function, errorCB: Function) => {
  return axios.post('/event', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB('Please try again.');
      return error.res;
    }
  );
};

const fetchEventType = (successCB: Function) => {
  return axios.get('/event/eventType').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const noteDelete = (uuid: string, successCB: Function) => {
  return axios.delete(`note/${uuid}`).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      return error;
    }
  );
};
const noteUpdate = (uuid: string, data: any) => {
  return axios.patch(`note/${uuid}`, data).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};
export { addNote, addEvent, fetchEventType, noteDelete, noteUpdate };

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { MobileCard } from '../../../Home/style';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
const UserCard = ({ leader, index }: { leader: any; index: number }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const dropRef = useRef(null);
  const now =
    (Math.floor(leader?.policyCount) / Math.floor(leader?.goalPolicy)) * 100;
  const premiumGoal =
    (Math.floor(leader?.policyPremium) / Math.floor(leader?.goalPremium)) * 100;

  useOutsideClick(dropRef, setShowDropdown);
  return (
    <MobileCard>
      <div className='d-flex justify-content-between name-container'>
        <div className='text-overflow-container w-75'>
          {leader.rank}. <b className='name-text'>{leader.name}</b>
          <span className='tooltiptext'>
            <span className='tooltipInnertext'>
              {leader.name ? leader.name : '--'}
            </span>
          </span>
        </div>
        <div
          className='vector mobile-leader-action'
          onClick={() => setShowDropdown(!showDropdown)}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
        </div>

        {showDropdown && (
          <div className='dropdown-div'>
            <div className='edit-div'>
              Edit <img src={edit} alt='bin' />
            </div>
            <div className='delete-div'>
              Delete <img src={bin} alt='bin' />
            </div>
          </div>
        )}
      </div>

      <div className='d-flex'>
        <div
          className='piechart'
          style={{
            backgroundImage: `conic-gradient(#58c070 ${now}deg, #F5F7FC 0)`,
          }}
        ></div>
        <div className='mb-2'>
          <span className='premium-goal-text'>Policy goal</span>
          <label className='mx-2'>
            {leader.policyCount ? leader.policyCount : 0}/{leader.goalPolicy}
          </label>
        </div>
      </div>
      <div className='d-flex'>
        <div
          className='piechart'
          style={{
            backgroundImage: `conic-gradient(#58c070 ${premiumGoal}deg, #F5F7FC 0)`,
          }}
        ></div>
        <div className=''>
          <span className='premium-goal-text'>Premium goal</span>
          <label className='mx-2'>
            ${leader.policyPremium ? leader.policyPremium : 0}
            /${leader.goalPremium}
          </label>
        </div>
      </div>
    </MobileCard>
  );
};

export default UserCard;

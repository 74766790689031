import Skeleton from 'react-loading-skeleton';
import right from '../../../assets/logo/rightvector.svg';
const KanbanSkeletonView = () => {
  return (
    <div className='col'>
      <div className='d-flex stages-div'>
        <div className='w-100 stage-header'>
          <Skeleton height={80} />
        </div>
        <img src={right} alt='' className='stage-item' />
      </div>
      <div className='p-2'>
        <Skeleton height={80} />
      </div>
      <div className='p-2'>
        <Skeleton height={80} />
      </div>
    </div>
  );
};
export default KanbanSkeletonView;

import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IContact } from '../../../../interfaces/Contact';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import bin from '../../../../assets/logo/bin.svg';
import blackplus from '../../../../assets/logo/blackplus.svg';

const ActionsCard = ({
  contact,
  onShowEditModal,
  setShowDeleteModal,
  setUUID,
  scroll,
  setName,
}: {
  contact: IContact;
  onShowEditModal: Function;
  setShowDeleteModal: Function;
  setUUID: Function;
  scroll: boolean;
  setName: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [dropDownPosition, setDropDownPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    setShowDropdown(false);
  }, [scroll]);
  return (
    <>
      <div className='d-flex align-items-center justify-content-between h-100'>
        <div className='action-item'>
          <img src={phone} alt='phone' />
        </div>
        <div className='action-item'>
          <img src={mail} alt='mail' />
        </div>
        <div className='action-item'>
          <img src={message} alt='message' />
        </div>
        <button
          className='vector d-flex align-items-center justify-content-center position-relative'
          onClick={() => {
            setShowDropdown(!showDropdown);
            if (dropRef.current) {
              dropRef.current.focus();
              setDropDownPosition({
                x: dropRef.current?.getBoundingClientRect().x,
                y: dropRef.current?.getBoundingClientRect().y,
              });
            }
          }}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setShowDropdown(false);
            }
          }}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showDropdown && (
            <div
              className='action-dropContainer position-fixed '
              style={{
                top: dropDownPosition.y + 33,
                left: dropDownPosition.x - 130,
              }}
            >
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    onShowEditModal(contact.uuid);
                    setShowDropdown(false);
                  }}
                  tabIndex={0}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div className='borderDiv'></div>
                <div
                  className='edit-div'
                  style={{ paddingTop: '10px' }}
                  onClick={() =>
                    navigate(`/contacts/${contact.uuid}/activities`)
                  }
                  tabIndex={1}
                >
                  Add activity <img src={blackplus} alt='plusIcon' />
                </div>
                <div className='borderDiv'></div>
                <div
                  className='delete-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setUUID(contact.uuid);
                    setShowDropdown(false);
                    setName(`${contact.firstName} ${contact.lastName}`);
                  }}
                  tabIndex={2}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

export default ActionsCard;

import axios from './axios';

const addEvent = (data: any, successCB: Function, errorCB: Function) => {
  return axios
    .post('/event', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB('Please try again.');
        return error.res;
      }
    );
};
const fetchEventDetails = (uuid: string) => {
  return axios.get(`/event/${uuid}`).then(
    (res) => {
      return res;
    },
    (error) => error
  );
};

const updateEvent = (uuid: string, data: any) => {
  return axios.patch(`event/${uuid}`, data).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

const fetchEventType = (successCB: Function) => {
  return axios.get('/event/eventType').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const deleteEvent = (uuid: string, successCB: Function) => {
  return axios.delete(`/event/${uuid}`).then((res) => {
    successCB(res.data);
    return res.data;
  });
};

export {
  addEvent,
  fetchEventDetails,
  fetchEventType,
  deleteEvent,
  updateEvent,
};

import axios from './axios';
import { ILead } from '../interfaces/Lead';

const addLead = (data: ILead, successCB: Function, errorCB: Function) => {
  return axios.post('/lead', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response.data.error);
      return error.res;
    }
  );
};

const updateLeadDetails = (
  data: ILead,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/lead/${uuid}`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response.data.error);
      return error.res;
    }
  );
};

const fetchLeads = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  leadStageUUID?: string,
  producerUUID?: string,
  leadSourceUUID?: any,
  accountType?: string,
  successCB?: Function
) => {
  return axios
    .get('/lead', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        limit,
        offset,
        leadStageUUID,
        producerUUID,
        leadSourceUUID,
        accountType,
      },
    })
    .then((res) => {
      successCB && successCB(res.data);
      return res;
    });
};

const getLeadStage = (successCB: Function) => {
  return axios.get(`/lead/leadStages`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const getLead = (uuid?: string) => {
  return axios.get(`/lead/${uuid}`).then(
    (res) => {
      return res;
    },
    (error) => error
  );
};

const qualifyLead = (uuid: string, successCB: Function, errorCB: Function) => {
  return axios.patch(`/lead/${uuid}/qualify`).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error.response.data.error);
      return error.response;
    }
  );
};

const junkLead = (
  uuid: string,
  data: { junkReason: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/lead/${uuid}/junk`, data).then(
    (res) => {
      successCB();
      return res;
    },
    (error) => {
      errorCB(error.response.data.error);
      return error.response;
    }
  );
};

const LeadDetailsCount = (leadUUID: string) => {
  return axios
    .get(`/user/mybook/count`, { params: { leadUUID } })
    .then((res) => {
      return res;
    });
};

const getLeadSourceTypes = (
  successCB: Function,
  agencyUUID?: string,
  searchBy?: string
) => {
  return axios
    .get('/leadSource', { params: { agencyUUID, searchBy } })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const createSourceType = (
  data: { agencyUUID?: string; name: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .post('/leadSource/type', data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const editSourceType = (
  uuid: string,
  data: { agencyUUID?: string; name: string },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .patch(`/leadSource/type/${uuid}`, data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const createSource = (
  data: {
    agencyUUID?: string;
    name: string;
    typeUUID: string;
  },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .post('/leadSource', data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const editSource = (
  uuid: string,
  data: {
    agencyUUID?: string;
    name: string;
    typeUUID: string;
  },
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .patch(`/leadSource/${uuid}`, data)
    .then((res) => {
      successCB();
      return res;
    })
    .catch((err) => {
      errorCB(err.response?.data?.error);
      throw new Error(err.response?.data?.error);
    });
};

const deleteSource = (
  uuid: string,
  data: {
    agencyUUID?: string;
    destinationUUID: string;
    typeUUID: string;
  }
) => {
  return axios.delete(`/leadSource/${uuid}`, { data });
};

const deleteSourceType = (
  uuid: string,
  data: {
    agencyUUID?: string;
    destinationUUID: string;
  }
) => {
  return axios.delete(`/leadSource/type/${uuid}`, { data });
};

const deleteLead = (uuid: String) => {
  return axios.delete(`/lead/${uuid}`);
};

export {
  updateLeadDetails,
  addLead,
  fetchLeads,
  getLead,
  qualifyLead,
  getLeadStage,
  junkLead,
  LeadDetailsCount,
  getLeadSourceTypes,
  createSourceType,
  editSourceType,
  createSource,
  editSource,
  deleteSource,
  deleteSourceType,
  deleteLead,
};

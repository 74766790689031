import { StyledAddModal } from '../elements/styles';
import redcross from '../../../assets/logo/redcross.svg';
import search from '../../../assets/logo/search.svg';
import { useState } from 'react';
const GuestListDropDown = ({
  contactList,
  guestUUID,
  setGuests,
  guests,
  setGuestUUID,
  Manage,
}: {
  contactList?: any;
  guestUUID: any;
  setGuests: Function;
  setGuestUUID: Function;
  guests: any;
  Manage?: boolean;
}) => {
  const [searchText, setSearchText] = useState('');

  const removeGuest = (guest: any) => {
    let remGuest = [...guests];
    let remUUID = [...guestUUID];
    let index = remGuest.findIndex((item) => item.uuid === guest.uuid);
    remGuest.splice(index, 1);
    setGuests(remGuest);
    let uuidIndex = remGuest.findIndex((item) => item === guest.uuid);
    remUUID.splice(uuidIndex, 1);
    setGuestUUID(remUUID);
  };

  return (
    <StyledAddModal>
      <div className='TopContainer'>
        <div className='GuestDropDown '>
          <div className='searchGuest'>
            <img className='inageSrc' src={search} alt='' />
            <input
              type='text'
              onChange={(e) => setSearchText(e.target.value)}
              className='guestSearchInput'
              placeholder='Search contact...'
            />
          </div>
          {contactList &&
          !Manage &&
          contactList?.filter((item: any) => !guestUUID.includes(item.uuid))
            .length > 0
            ? contactList
                ?.filter((item: any) => !guestUUID.includes(item.uuid))
                .filter((search: any) => {
                  if (searchText === '') {
                    return search;
                  } else if (
                    search.firstName
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    search.lastName
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return search;
                  }
                })
                .map((item: any, index: number) => (
                  <div
                    className='guestlist d-flex align-items-center underLineDiv'
                    onClick={() => {
                      setGuests([...guests, item]);
                      setGuestUUID([...guestUUID, item?.uuid]);
                      contactList.slice(index, 1);
                    }}
                  >
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.firstName?.charAt(0)} {item?.lastName?.charAt(0)}
                    </div>
                    <div>
                      <h6>
                        {`${item?.firstName} ${
                          item.lastName ? item.lastName : ''
                        }`}
                      </h6>
                      <ul className='user-details'>
                        <li>
                          {
                            item.ContactDetails?.find(
                              (item: any) => item.type === 'EMAIL'
                            ).value
                          }
                        </li>
                        <li>
                          {
                            item.ContactDetails?.find(
                              (item: any) => item.type === 'PHONENUMBER'
                            ).value
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                ))
            : !Manage && (
                <span className='d-flex justify-content-center'>
                  Guest List Empty
                </span>
              )}
          {guests &&
            Manage &&
            guests
              .filter((search: any) => {
                if (searchText === '') {
                  return search;
                } else if (
                  search.firstName
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  search.lastName
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                ) {
                  return search;
                }
              })
              .map((item: any, index: number) => (
                <div className='d-flex justify-content-between  align-items-center underLineDiv'>
                  <div className='guestlist d-flex align-items-center '>
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.firstName?.charAt(0)} {item?.lastName?.charAt(0)}
                    </div>
                    <div>
                      <h6>
                        {`${item?.firstName} ${
                          item.lastName ? item.lastName : ''
                        }`}
                      </h6>
                      <ul className='user-details'>
                        <li>
                          {
                            item.ContactDetails?.find(
                              (item: any) => item.type === 'EMAIL'
                            ).value
                          }
                        </li>
                        <li>
                          {
                            item.ContactDetails?.find(
                              (item: any) => item.type === 'PHONENUMBER'
                            ).value
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='ms-sm- 3 p-2'>
                    <img
                      src={redcross}
                      alt='remove'
                      className=''
                      onClick={() => {
                        removeGuest(item);
                      }}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </StyledAddModal>
  );
};

export default GuestListDropDown;

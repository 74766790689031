import { useRef, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { StyledCard } from '../../elements/styles';
import { getCount } from '../../../../store/book/bookSlice';
import EditLeadDrawer from '../../../../components/EditLeadDrawer';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { ILead } from '../../../../interfaces/Lead';
import QualifyLeadsModal from '../../../../components/QualifyLeadsModal';
import { onQualifyLead, onJunkLead } from '../../../../store/leads/leadsSlice';
import { AppDispatch } from '../../../../store';
import LeadJunkModal from '../../../../components/LeadJunkModal';
import junk from '../../../../assets/logo/junk.svg';

const LeadMobileCard = ({
  lead,
  setShowDetailModal,
  setUUID,
  setShowDeleteModal,
  setName,
}: {
  lead: ILead;
  setShowDetailModal: Function;
  setUUID: Function;
  setShowDeleteModal: Function;
  setName: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showQualifyLead, setShowQualifyLead] = useState(false);
  const [qulifyMessage, setQulifyMessage] = useState('');
  const [showJunkLead, setShowJunkLead] = useState(false);

  const dropRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  useOutsideClick(dropRef, setShowDropdown);

  const qualifyFunction = (uuid: string) => {
    dispatch(
      onQualifyLead({
        uuid,
        successCB: (data: any) => {
          dispatch(getCount());
          setShowQualifyLead(false);
        },
        errorCB: (err: any) => {
          setShowEditModal('open');
          setQulifyMessage(err);
          setShowQualifyLead(false);
        },
      })
    );
  };

  const junkFunction = (uuid: string, data: { junkReason: string }) => {
    dispatch(
      onJunkLead({
        uuid,
        data,
        successCB: () => {
          dispatch(getCount());
          setShowJunkLead(false);
        },
        errorCB: (err: any) => {
          setQulifyMessage(err);
          setShowJunkLead(false);
        },
      })
    );
  };

  return (
    <StyledCard>
      <div className='d-flex justify-content-between align-items-center'>
        <h6
          onClick={() => {
            setShowDetailModal('open');
            setUUID(lead?.uuid);
          }}
        >
          {lead.data.contact.firstName
            ? lead.data.contact.firstName + ' ' + lead.data.contact.lastName
            : '--'}
        </h6>
        <div className='status-div'>
          New
          {/* TODO ADD status */}
        </div>
      </div>
      <div className='info-div d-flex'>
        <div className='type-div'>
          {lead.data.accountInfo.accountType?.charAt(0) +
            lead.data.accountInfo.accountType?.slice(1).toLowerCase()}
        </div>
        <div>
          Added on <Moment format='MM/DD/YYYY'>{lead.createdAt}</Moment>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div className='action-item'>
          <img src={phone} alt='phone' />
        </div>
        <div className='action-item'>
          <img src={mail} alt='mail' />
        </div>
        <div className='action-item'>
          <img src={message} alt='message' />
        </div>
        <div className='actionButton' onClick={() => setShowQualifyLead(true)}>
          Qualify
        </div>

        <div className='vector-div' ref={dropRef}>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img src={vector} alt='vector' />
          </div>
          {showDropdown && (
            <div className='drop-div'>
              <div
                className='editdiv'
                onClick={() => {
                  setShowEditModal('open');
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div
                className='junkdiv '
                onClick={() => {
                  setShowJunkLead(true);
                  setShowDropdown(false);
                }}
              >
                Mark as Junked <img src={junk} alt='junk-icon' />
              </div>
              <div
                className='deletediv'
                onClick={() => {
                  setShowDeleteModal('open');
                  setUUID(lead?.uuid);
                  setShowDropdown(false);
                  setName(
                    `${
                      lead?.data?.contact?.firstName +
                      ' ' +
                      lead?.data?.contact?.lastName
                    }`
                  );
                }}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
          {/* TODO : Loading */}
          {showQualifyLead ? (
            <QualifyLeadsModal
              open={showQualifyLead}
              closeModal={setShowQualifyLead}
              qualifyFunction={qualifyFunction}
              uuid={lead.uuid}
              loading={'idle'}
            />
          ) : null}

          {showEditModal !== 'closed' && (
            <EditLeadDrawer
              show={showEditModal}
              closeModal={setShowEditModal}
              UUID={lead.uuid}
              setQulifyMessage={setQulifyMessage}
              qualifyMessage={qulifyMessage}
            />
          )}
          {showJunkLead ? (
            <LeadJunkModal
              open={showJunkLead}
              closeModal={setShowJunkLead}
              junkFunction={junkFunction}
              uuid={lead.uuid}
              loading={'idle'}
            />
          ) : null}
        </div>
      </div>
    </StyledCard>
  );
};

export default LeadMobileCard;

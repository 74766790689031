import { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SelectField from '../SelectField';
import { StyledModal, CancelButton, ProceedButton } from './styles';
import cross from '../../assets/logo/cross.svg';
import rightArrow from '../../assets/logo/right-arrow.svg';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import warning from '../../assets/logo/warning.svg';

const LeadSchema = Yup.object().shape({
  junkReason: Yup.object().shape({
    value: Yup.string().required('Please select a reason'),
  }),
});

const junkReason = [
  { label: 'Out of business', value: 'Out of business' },
  { label: 'No longer own the business', value: 'No longer own the business' },
  { label: 'Incorrect information', value: 'Incorrect information' },
  { label: 'Insurance not needed', value: 'Insurance not needed' },
  { label: 'Too busy', value: 'Too busy' },
  { label: 'Bad information', value: 'Bad information' },
];

const LeadJunkModal = ({
  open,
  closeModal,
  junkFunction,
  uuid,
  loading,
}: {
  open: boolean;
  closeModal: Function;
  junkFunction: Function;
  uuid: string;
  loading: string;
}) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && ref.current?.parentNode === event.target) {
          closeModal(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LeadSchema) });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onJunkLead = (data: any) => {
    data.junkReason = data.junkReason.value;
    junkFunction(uuid, data);
  };

  return (
    <StyledModal>
      <div className='modal-container' ref={wrapperRef}>
        <button
          onClick={() => {
            closeModal(false);
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <h4>Mark lead as junked.</h4>
        <p>Marking lead as junk will remove all information</p>
        <form onSubmit={handleSubmit(onJunkLead)}>
          <div className='field'>
            <SelectField
              options={junkReason}
              name='junkReason'
              placeholder='Reason to junk lead'
              control={control}
              isEdit={true}
            />
            {errors.junkReason ? (
              <div className='errorVal'>
                <img src={warning} alt='warning' className='warning' />
                {errors.junkReason?.value?.message && 'Please select a reason'}
              </div>
            ) : null}
          </div>
          <div className='border d-block d-sm-none'></div>
          <div className='d-flex button-container'>
            <CancelButton
              type='button'
              onClick={() => {
                closeModal(false);
              }}
            >
              Cancel
            </CancelButton>
            <ProceedButton type='submit' disabled={loading === 'loading'}>
              Junk
              <img src={rightArrow} alt='' />
            </ProceedButton>
          </div>
        </form>
      </div>
    </StyledModal>
  );
};

export default LeadJunkModal;

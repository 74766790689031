import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../../store';
import {
  createAccount,
  AccountsSliceState,
} from '../../../../store/accounts/accountsSlice';
import { getCount } from '../../../../store/book/bookSlice';
import { fetchContacts } from '../../../../api/contact';
import { fetchJurisdiction } from '../../../../api/jurisdiction';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import DateField from '../../../../components/DateField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import SuccessCard from '../../../../components/SuccessCard';
import SearchDropdown from '../../../../components/SearchDropdown';
import NewBusiness from './NewBusinessLongForm';
import { IJurisdiction } from '../../../../interfaces/Jurisdiction';
import { StyledAddModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import warning from '../../../../assets/logo/warning.svg';
import tick from '../../../../assets/logo/tick.svg';
import plus from '../../../../assets/logo/blackplus.svg';
import blackplus from '../../../../assets/logo/blackplus.svg';
import MarkdownEditor from '../../../../components/MarkdownEditor';
import { FormModal } from '../../../../styles/FormModal';
import CustomFieldInput from '../../../../components/CustomfieldsInput';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../../../store/customFields/customFieldsSlice';
import moment from 'moment';

const SignupSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
});

const AccountAddDrawer = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const [showSuccess, setshowSuccess] = useState(false);
  const watchAllFields = watch();
  const watchLastName = watch('lastName');
  const navigate = useNavigate();

  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];

  const [commercial, isCommercial] = useState(false);
  const [business, setBusiness] = useState<any>([{ customFields: {} }]);
  const [addressObject, setAddressObject] = useState<any>({});
  const [jurisdictionSearch, setJurisdictionSearch] = useState('');
  const [isErrorOccured, setIsErrorOccured] = useState<any>([]);
  const [submit, setSubmit] = useState(false);
  const addsNewBussiness = () => {
    business
      ? setBusiness([...business, { customFields: {} }])
      : setBusiness([{ customFields: {} }]);
  };
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.isLoading
  );

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [disabled, setDisabled] = useState(false);
  const [accountCustomFields, setAccountCustomFields] = useState<any>();
  const [contactCustomFields, setContactCustomFields] = useState<any>();
  const [errorMessage, setError] = useState('');
  const [textCheck, setTextCheck] = useState('');
  const [noteError, setNoteError] = useState('');

  const loadEmail = (value: string) => {
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (emailSearch) {
        fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
          setEmailAccounts(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setEmailAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'ACCOUNT',
        isAddForm: 'true',
        successCB: (data: any) => {
          setAccountCustomFields(data);
        },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'CONTACT',
        isAddForm: 'true',
        successCB: (data: any) => {
          setContactCustomFields(data);
        },
      })
    );
  }, []);

  useEffect(() => {
    contactCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
    accountCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
  }, [contactCustomFields, accountCustomFields]);

  useEffect(() => {
    if (errorMessage) {
      setError('');
    }
  }, [watchAllFields.phoneNumber]);

  const onSubmit = (data: any) => {
    data.accountCustomFields = {};
    data.contactCustomFields = {};
    let errorexist = false;
    const businessWithErrorValidation = business.map((bus: any) => {
      let errorobj: any = {};

      if (!bus.name) {
        errorobj.businessNameError = 'Please enter Business Name';
        errorexist = true;
      }
      if (!bus.payroll || parseInt(bus.payroll, 10) < 0) {
        errorobj.amountError = 'Please enter Payroll';
        errorexist = true;
      }

      if (!bus.businessPhoneNumber || bus.businessPhoneNumber?.length < 14) {
        errorobj.businessPhoneNumberError =
          'Please enter  Business Phone Number';
        errorexist = true;
      }

      return errorobj;
    });

    setIsErrorOccured(businessWithErrorValidation);

    let errorLastName = true;
    if (!commercial && !watchLastName) {
      errorLastName = false;
    }
    contactCustomFields.map((item: any) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.contactCustomFields[item.uuid] = timeItem.value;
        } else {
          data.contactCustomFields[item.uuid] = data[item.uuid];
        }
      }
    });
    accountCustomFields.map((item: any) => {
      if (item.uuid in data) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) data.accountCustomFields[item.uuid] = timeItem.value;
        } else {
          data.accountCustomFields[item.uuid] = data[item.uuid];
        }
      }
    });
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (errorexist && commercial) {
      return errorexist;
    } else if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    } else {
      let noteSubmit = true;
      if (notes) {
        if (textCheck && !textCheck.trim() && textCheck.length > 1) {
          noteSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      data.notes = notes;
      data.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';
      data.businesses = business;
      if (data.maritalStatus) data.maritalStatus = data.maritalStatus.value;
      if (jurisdictionsUUID) data.jurisdictionUUID = jurisdictionsUUID;
      // To add addressObject with the form
      if (!isManual) {
        data = Object.assign(data, addressObject);
      }
      data.isManuallyAdded = isManual;
      if (errorLastName === true && noteSubmit) {
        dispatch(
          createAccount({
            data,
            successCB: () => {
              dispatch(getCount());
              closeModal('closing');
              setTimeout(() => {
                setDisabled(true);
                setshowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') setError(error);
            },
          })
        );
      }
    }
  };

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
    setEmailAccounts([]);
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (licensedState) {
        fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
          setLicensedState(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setLicensedState([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    setAddressObject({});
  }, [commercial]);

  const [notes, setNotes] = useState<any>(['']);
  const [isManual, setIsManual] = useState(false);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  useEffect(() => {
    if (notes) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [notes]);

  const [duetimeVal, setDueTimeVal] = useState<any>([]);
  const resetJurisdiction = (value: string) => {
    setLicensedState([]);
    setJurisdictionsUUID('');
  };
  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding contact'}
            message={`<b class='name-text-overflow'>${getValues(
              'firstName'
            )} ${getValues('lastName')}</b> has been added as contact<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <FormModal
        open={show}
        align='right'
        containerClassName='fullwidth-container'
      >
        <StyledAddModal>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                <h2>Add account</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex navButtons'>
                <button
                  onClick={() => isCommercial(false)}
                  className={
                    !commercial
                      ? 'naVigatorbuttonsActive secondaryButton'
                      : 'naVigatorbuttons secondaryButton'
                  }
                >
                  Personal
                </button>
                <div className='commercial-text'>
                  <button
                    onClick={() => isCommercial(true)}
                    className={
                      commercial
                        ? 'naVigatorbuttonsActive secondaryButton'
                        : 'naVigatorbuttons secondaryButton'
                    }
                  >
                    Commercial
                  </button>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div>
                  <h2 className='contact-info'>Contact Info</h2>
                </div>
                <div className='top-field d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      register={register}
                      className={`${errors.firstName && 'error-field'} ${
                        watchAllFields.firstName && 'filled'
                      }`}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='small-div d-sm-flex justify-content-between'>
                    <div className='fields-small'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        register={register}
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        register={register}
                        required
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                      />
                      {(errors.lastName && submit) ||
                      (!commercial && !watchLastName && submit) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      control={control}
                      type='email'
                      setUUID={resetEmail}
                      required
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      errors.email?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message}
                        </div>
                      )
                    )}
                  </div>

                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      control={control}
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields dateField'>
                    <DateField
                      name='dateOfBirth'
                      placeholder='Date Of Birth'
                      maxDate={new Date()}
                      control={control}
                      dateOfBirth
                      value={watchAllFields.dateOfBirth}
                    />
                  </div>
                  <div className='fields'>
                    <SelectField
                      options={MaritalStatus}
                      name='maritalStatus'
                      placeholder='Marital status'
                      control={control}
                    />
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='jurisdictionUUID'
                      placeholder='Licensed state'
                      isEdit={true}
                      values={jurisdictionSearch}
                      searchValue={licensedState}
                      setSearch={setJurisdictionSearch}
                      status={loadingStatus}
                      className={`${jurisdictionSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadLicensedState}
                      setUUID={resetJurisdiction}
                      content={
                        licensedState.length > 0
                          ? licensedState?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setJurisdictionSearch(item?.name);
                                  setJurisdictionsUUID(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>{item.name}</h6>
                                </div>
                              </div>
                            ))
                          : null
                      }
                    />
                  </div>
                  <div className='fields'>
                    <InputField
                      name='driverlicenseNumber'
                      type='text'
                      placeholder='License number'
                      register={register}
                      className={`${
                        errors.driverlicenseNumber && 'error-field'
                      } ${watchAllFields.driverlicenseNumber && 'filled'}`}
                    />
                  </div>
                </div>
                {commercial && (
                  <>
                    {contactCustomFields?.length > 0 ? (
                      <>
                        {contactCustomFields?.map((item: any, index: number) =>
                          index % 2 === 0 ? (
                            <div className='d-sm-flex justify-content-between'>
                              <div className='fields'>
                                <CustomFieldInput
                                  item={item}
                                  register={register}
                                  control={control}
                                  value={watchAllFields[item.uuid]}
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                              {contactCustomFields?.length > index + 1 ? (
                                <div className='fields'>
                                  <CustomFieldInput
                                    item={contactCustomFields[index + 1]}
                                    register={register}
                                    control={control}
                                    value={
                                      watchAllFields[
                                        contactCustomFields[index + 1].uuid
                                      ]
                                    }
                                    setDueTimeVal={setDueTimeVal}
                                    duetimeVal={duetimeVal}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        )}
                      </>
                    ) : null}
                  </>
                )}

                {!commercial ? (
                  <div>
                    {!isManual ? (
                      <div className=' d-sm-flex justify-content-between'>
                        <div className='fields'>
                          <GoogleSearchInput
                            placeholder='Mailing Address'
                            name='address'
                            inputType='PERSONAL'
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            className={`${errors.address && 'error-field'} ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        </div>
                        <div className='fields'>
                          <InputField
                            name='apartment'
                            type='text'
                            placeholder='Apt'
                            register={register}
                            className={`${errors.apartment && 'error-field'} ${
                              watchAllFields.apartment && 'filled'
                            }`}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className='contact-info'>Mailing address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='apartment'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.apartment && 'error-field'
                              } ${watchAllFields.apartment && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {contactCustomFields?.length > 0 ? (
                      <>
                        {contactCustomFields?.map((item: any, index: number) =>
                          index % 2 === 0 ? (
                            <div className='d-sm-flex justify-content-between'>
                              <div className='fields'>
                                <CustomFieldInput
                                  item={item}
                                  register={register}
                                  control={control}
                                  value={watchAllFields[item.uuid]}
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                              {contactCustomFields?.length > index + 1 ? (
                                <div className='fields'>
                                  <CustomFieldInput
                                    item={contactCustomFields[index + 1]}
                                    register={register}
                                    control={control}
                                    value={
                                      watchAllFields[
                                        contactCustomFields[index + 1].uuid
                                      ]
                                    }
                                    setDueTimeVal={setDueTimeVal}
                                    duetimeVal={duetimeVal}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        )}
                      </>
                    ) : null}
                    {!commercial && accountCustomFields?.length > 0 ? (
                      <>
                        {!commercial && (
                          <h2 className='contact-info'>Account info</h2>
                        )}
                        {accountCustomFields?.map((item: any, index: number) =>
                          index % 2 === 0 ? (
                            <div className='d-sm-flex justify-content-between'>
                              <div className='fields'>
                                <CustomFieldInput
                                  item={item}
                                  register={register}
                                  control={control}
                                  value={watchAllFields[item.uuid]}
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                              {accountCustomFields?.length > index + 1 ? (
                                <div className='fields'>
                                  <CustomFieldInput
                                    item={accountCustomFields[index + 1]}
                                    register={register}
                                    control={control}
                                    value={
                                      watchAllFields[
                                        accountCustomFields[index + 1].uuid
                                      ]
                                    }
                                    setDueTimeVal={setDueTimeVal}
                                    duetimeVal={duetimeVal}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        )}
                      </>
                    ) : null}
                  </div>
                ) : null}

                {commercial ? (
                  <div className='mt-5'>
                    {business?.map((item: any, index: any) => (
                      <NewBusiness
                        key={index}
                        business={item}
                        index={index}
                        businesses={business}
                        setBusiness={setBusiness}
                        businessPhoneNumberError={
                          isErrorOccured[index]?.businessPhoneNumberError
                        }
                        businessNameError={
                          isErrorOccured[index]?.businessNameError
                        }
                        amountError={isErrorOccured[index]?.amountError}
                        customFields={accountCustomFields}
                      />
                    ))}
                    <button
                      className='mt-4 align-items-center secondaryButton'
                      type='button'
                      onClick={() => addsNewBussiness()}
                    >
                      <img
                        className='addAnotherBusiness'
                        src={blackplus}
                        alt='add'
                      />
                      Add another business
                    </button>
                  </div>
                ) : null}

                <div>
                  {notes &&
                    notes?.map((item: any, index: number) => {
                      return (
                        <div className='d-flex align-items-center descriptionField  col'>
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                              textCheckFunction={setTextCheck}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {noteError ? (
                  <div className='errorVal'>
                    <img src={warning} alt='warning' className='warning' />
                    {noteError}
                  </div>
                ) : null}
              </div>
              <div className='buttonsDiv d-flex align-items-center'>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  disabled={loading || show === 'closing' || disabled}
                  onClick={() => {
                    setSubmit(true);
                  }}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </StyledAddModal>
      </FormModal>
    </>
  );
};

export default AccountAddDrawer;

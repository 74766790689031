import styled from '@emotion/styled';

export const ErrorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
  height: 100vh;
  padding: 68px 20px 32px 20px;

  .illustration {
    background: #f4f5f7;
    border-radius: 50%;
    width: 246px;
    height: 246px;

    img {
      width: 180px;
    }
  }

  .error-content {
    text-align: center;
  }

  .error-code {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #8990a3;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #191919;
    line-height: 36px;
    margin: 4px 0 12px 0;
  }

  .description {
    color: #5a5f70;
    font-size: 14px;
    line-height: 20px;
    max-width: 322px;
  }

  .primaryButton {
    font-size: 16px;
    width: 100%;
    height: 48px;
    margin-top: 64px;
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    padding: 124px 108px 124px 100px;
    justify-content: space-around;

    .illustration {
      width: 292px;
      height: 292px;

      img {
        width: 210px;
      }
    }

    .error-content {
      text-align: left;
    }

    .primaryButton {
      width: 220px;
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 194px;

    .illustration {
      width: 377px;
      height: 376px;

      img {
        width: auto;
      }
    }

    .error-code {
      font-size: 14px;
      line-height: 20px;
    }

    h1 {
      font-size: 36px;
      line-height: 52px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      max-width: 460px;
    }

    .primaryButton {
      margin-top: 52px;
    }
  }

  @media screen and (min-width: 1900px) {
    padding: 299px 355px;

    .illustration {
      width: 482px;
      height: 482px;

      img {
        width: 344px;
      }
    }

    .error-code {
      font-size: 16px;
      line-height: 24px;
    }

    h1 {
      font-size: 44px;
      line-height: 64px;
    }

    .description {
      font-size: 20px;
      line-height: 28px;
      max-width: 540px;
    }

    .primaryButton {
      margin-top: 64px;
      height: 64px;
      width: 258px;
    }
  }
`;

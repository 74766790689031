import { useRef, useEffect } from 'react';
import { StyledModal, CancelButton, ProceedButton } from './style';
import cross from '../../assets/logo/cross.svg';
import rightArrow from '../../assets/logo/right-arrow.svg';
import qualifyImg from '../../assets/illustrations/qualify.svg';

const QualifyLeadsModal = ({
  open,
  closeModal,
  qualifyFunction,
  uuid,
  loading,
}: {
  open: boolean;
  closeModal: Function;
  qualifyFunction: Function;
  uuid: string;
  loading: string;
}) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <StyledModal>
      <div className='modal-container' ref={wrapperRef}>
        <button
          onClick={() => {
            closeModal(false);
          }}
          className='crossbutton'
        >
          <img src={cross} className='cross' alt='cross-icon' />
        </button>
        <img src={qualifyImg} alt='qualify' className='qualify-img' />
        <h4>Qualify lead</h4>
        <p>Are you sure you want to qualify this lead?</p>
        <div className='d-flex button-container'>
          <CancelButton
            onClick={() => {
              closeModal(false);
            }}
          >
            No
          </CancelButton>
          <ProceedButton
            onClick={() => qualifyFunction(uuid)}
            disabled={loading === 'loading'}
          >
            Yes
            <img src={rightArrow} alt='' />
          </ProceedButton>
        </div>
      </div>
    </StyledModal>
  );
};

export default QualifyLeadsModal;

import { useRef, useState } from 'react';
import dropdown from '../../../../assets/logo/dropdown.svg';
import Link from '../../../../components/Link';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { StyledTab } from '../../../../styles/StyledTab';
import LocalStorageService from '../../../../utils/LocalStorageService';

const MobileTab = ({ uuid, dropText }: { uuid: string; dropText: string }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = LocalStorageService.getUser();

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDropdown);
  return (
    <StyledTab className='d-sm-none mobileTab-text'>
      <div className='ActivityName' ref={dropRef}>
        <div
          className='activeCarrierTab'
          onClick={(e) => {
            setShowDropdown(!showDropdown);
          }}
        >
          {dropText.toLowerCase()}
          <img src={dropdown} alt='' />
        </div>
        {showDropdown && (
          <div className='mobile-tab '>
            <Link
              to={
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}/carrier/system-defined-carrier`
                  : `/settings/carrier/system-defined-carrier`
              }
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              System Defined Carriers
            </Link>
            <Link
              to={
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}/carrier/custom-carrier`
                  : `/settings/carrier/custom-carrier`
              }
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Custom Carriers
            </Link>
          </div>
        )}
      </div>
    </StyledTab>
  );
};

export default MobileTab;

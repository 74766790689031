import { StyledDeleteModal } from '../../elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import LongVector from '../../../../assets/logo/longVector.svg';

const InActiveWarningModal = ({
  showModal,
  onCloseModal,
  setShowModal,
  pipelineUUID,
  name,
}: {
  showModal: string;
  onCloseModal: Function;
  setShowModal: Function;
  pipelineUUID: string;
  name: string;
}) => {
  return (
    <StyledDeleteModal show={showModal === 'open' ? true : false} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal('closed')}
      />
      <h2>Inactivate this pipeline?</h2>
      <div className='sub-heading'>
        There are opportunities in this pipeline. If you inactivate this
        pipeline, all the opportunities in this pipeline will be moved to the
        default pipeline.
      </div>
      <div className='PipelineFlow d-flex align-items-center justify-content-between '>
        <div className='nameSpace'>
          <span>
            from <b>{name}</b>
          </span>
        </div>
        <img src={LongVector} alt='' />
        <div className='nameSpace'>
          <span>
            To<b className='nameSpace'> Default Pipeline</b>
          </span>
        </div>
      </div>
      <div className='buttonsDiv d-flex align-items-center justify-content-end'>
        <button
          onClick={() => {
            setShowModal('closed');
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='WarningButton d-flex align-items-center justify-content-between'
          onClick={() => {
            onCloseModal(pipelineUUID);
            setShowModal('closed');
          }}
        >
          Move and Inactivate
          <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </StyledDeleteModal>
  );
};

export default InActiveWarningModal;

import styled from '@emotion/styled';

export const SearchContainer = styled.div`
  @keyframes pageTransit {
    from {
      transform: translate(100%);
    }
    to {
      transform: translate(0);
    }
  }

  .content-div {
    padding: 18px 20px 60px 20px;
    animation-name: pageTransit;
    animation-duration: 0.3s;
  }
  @media (min-width: 576px) {
    .content-div {
      padding: 76px 32px 60px 32px;
    }
  }
  @media screen and (min-width: 1200px) {
    .content-div {
      padding: 80px 48px 0 130px;
    }
  }
  @media screen and (min-width: 1900px) {
    .content-div {
      padding: 116px 120px 0px 208px;
    }
  }
  @media screen and (min-width: 2800px) {
    .content-div {
      padding: 232px 240px 0px 416px;
    }
  }
`;

export const SearchDiv = styled.div`
  padding: 8px;
  .search-content-container {
    max-width: 560px;
    margin: 0 auto;
  }
  .back-button-icon {
    margin-right: 12px;
    width: 20px;
    vertical-align: text-bottom;
  }
  .back-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    background: none;
  }
  .result-count {
    font-size: 14px;
    line-height: 20px;
    color: #8990a3;
    padding: 12px;
    margin-bottom: 20px;
  }
  .show-more-button {
    background: transparent;
    border: none;
    color: #262758;
    opacity: 0.5;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  .search-result-subcard {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 16px;

    .empty-results {
      height: 70vh;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .search-content {
    display: flex;
    align-items: center;
    padding: 12px;
    h6 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #262758;
      text-overflow: ellipsis;
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;

      @media screen and (max-width: 576px) {
        max-width: 60vw;
      }
    }

    :hover {
      background: #fafafa;
      cursor: pointer;
    }
  }
  .nav-tabs {
    border-bottom: 1px solid #e6e9f0;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: none;
    border-bottom: 2px solid #262758;
    background: none;
  }
  .nav-tabs .nav-link {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-bottom: 2px solid transparent;
    padding-left: 1px;
    padding-right: 1px;
    margin: 0 20px;
    :hover {
      color: #000000;
      border: none;
      border-bottom: 2px solid #262758;
    }
  }

  .empty-text {
    color: #262758;
  }

  .empty-img {
    width: 104px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 576px) {
    .empty-text {
      font-weight: 600;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    .search-content-container {
      max-width: 614px;
    }
    .empty-img {
      width: 124px;
    }
    .empty-text {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1900px) {
    .empty-img {
      width: 144px;
    }
    .empty-text {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 1200px) {
    .tab-content {
      overflow-y: auto;
      background: #fff;
      max-height: 60vh;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #e6e9f0;
      }
    }
  }

  @media screen and (max-width: 576px) {
    border-radius: 0;

    .nav-tabs {
      width: 100%;
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const SearchCardDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  h6 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #262758;
  }

  .user-details {
    padding: 0;
    margin: 0;
    li {
      color: #8990a3;
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      border-left: 1px solid #8990a3;
      padding: 0 5px;
      &:first-child {
        border: none;
        padding-left: 0;
      }
      span {
        color: #262758;
        font-weight: 600;
      }
    }
  }

  .result-count {
    font-size: 14px;
    line-height: 20px;
    color: #8990a3;
    padding: 12px;
  }

  .profile-pic {
    background: #262758;
    width: 32px;
    height: 32px;
    border-radius: 46px;
    margin-right: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    flex: 0 0 40px;
    max-width: 32px;
    max-height: 32px;
  }
  .profile-img {
    background: rgba(230, 233, 240, 0.5);
    border-radius: 73px;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    flex: 0 0 40px;
    max-width: 32px;
    max-height: 32px;
    img {
      width: 16px;
    }
  }
  .showAll-button {
    padding: 14px;
    margin: 0 40px;
    border-top: 1px solid #d1d6e5;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    img {
      margin-left: 12px;
    }
  }

  .search-tooltip {
    display: none;
  }

  .link-title:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #3c498a;
    position: relative;

    .search-tooltip {
      display: block;
      position: absolute;
      right: 0;
      top: 20px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 16px 0px #26275833;
      padding: 24px;
      width: max-content;
      z-index: 1;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
      }

      span {
        font-size: 14px;
        font-weight: 400;
      }

      b {
        display: block;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }

  .go-icon {
    margin: 0 16px 0 auto;
  }
`;

import axios from './axios';

const fetchDocuments = (
  contactUUID?: string,
  accountUUID?: string,
  leadUUID?: string,
  opportunityUUID?: string,
  taskUUID?: string,
  eventUUID?: string,
  quoteUUID?: string,
  documentTypeUUID?: string,
  policyUUID?: string,
  sortBy?: string,
  orderBy?: string
) => {
  return axios
    .get(`/document`, {
      params: {
        contactUUID,
        accountUUID,
        leadUUID,
        opportunityUUID,
        taskUUID,
        eventUUID,
        quoteUUID,
        documentTypeUUID,
        policyUUID,
        sortBy,
        orderBy,
      },
    })
    .then((res) => {
      return res;
    });
};
const createDocument = (
  formData: any,
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .post('/document', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB('Please try again.');
        return error.res;
      }
    );
};
const getDocumentType = (successCB: Function) => {
  return axios.get('/document/documentType').then((res) => {
    successCB(res.data);
    return res.data;
  });
};

const updateDocument = (uuid: string, data: any) => {
  return axios.patch(`document/${uuid}`, data).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};
const reaploadDocument = (uuid: string, data: any) => {
  return axios
    .patch(`document/${uuid}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
};

const fetchDoumentDetails = (uuid: string) => {
  return axios.get(`/document/${uuid}`).then(
    (res) => {
      return res;
    },
    (error) => error
  );
};

const deleteDocument = (uuid: string) => {
  return axios.delete(`document/${uuid}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

export {
  fetchDocuments,
  createDocument,
  getDocumentType,
  updateDocument,
  fetchDoumentDetails,
  deleteDocument,
  reaploadDocument,
};

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { getPipelines } from '../../store/pipelines/pipelineSlice';
import { fetchAgents, fetchAgentDetails } from '../../api/agents';
import SelectField from '../CustomSelectField';
import OpportunityModal from './OpportunityModal';
import DateField from '../CustomDateField';
import SearchDropdown from '../SearchDropdown';
import LocalStorageService from '../../utils/LocalStorageService';
import { IProducer } from '../../interfaces/Agent';
import { IPipeline, IPipelineStage } from '../../interfaces/Opportunity';
import arrow from '../../assets/logo/right-arrow.svg';
import { fetchProducts } from '../../api/product';
import { IProduct } from '../../interfaces/Product';
import { fetchSingleProduct } from '../../api/product';

function Opportunity({
  opportunity,
  index,
  setOpportunities,
  opportunities,
  register,
  isCommercial,
  setIsOpportunityEdit,
}: {
  opportunity: any;
  index: number;
  setOpportunities: Function;
  opportunities: any[];
  register: Function;
  isCommercial?: boolean;
  setIsOpportunityEdit: Function;
}) {
  const dispatch = useDispatch<AppDispatch>();

  const [pipelines, setPipelines] = useState<any[]>([]);

  const [addOpportunity, isAddOpportunity] = useState('closed');
  const [searchBy, setSearchBy] = useState('');
  const [producerSearch, setProducerSearch] = useState('');

  let setOpportunityPolicys = opportunities[index].productUUID
    ? opportunities[index].productUUID
    : opportunities[index].customProductUUID;
  let setStage = opportunities[index].stages;
  let producerAgent = opportunities[index].producer;

  const onSetItem = (name: string, value: string, secondary?: string) => {
    let newOpportunity = Object.assign({}, opportunity);
    let updatedOpportunities = [...opportunities];
    newOpportunity[name] = value;
    if (secondary) {
      newOpportunity[secondary] = '';
    }

    updatedOpportunities[index] = newOpportunity;
    setOpportunities(updatedOpportunities);
    setIsOpportunityEdit(true);
  };

  const [product, setProduct] = useState<IProduct[]>([]);
  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (searchBy) {
        fetchProducts(
          (data: IProduct[]) => {
            setProduct(data);
            setLoadingStatus('succeeded');
          },
          'name',
          'asc',
          value,
          '',
          true,
          isCommercial ? 'COMMERCIAL' : 'PERSONAL'
        );
      } else {
        setProduct([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    const user = LocalStorageService.getUser();
    if (user?.UserRole?.name === 'PRINCIPAL_AGENT') {
      if (producerAgent) {
        fetchAgentDetails(producerAgent, (data: any) => {
          if (user.uuid === data?.User.uuid) {
            setProducerSearch('Me');
          } else {
            setProducerSearch(
              `${data?.User?.firstName} ${data?.User?.lastName || ''}`
            );
          }
        });
      }
    } else {
      setProducerSearch('Me');
    }
  }, [producerAgent]);

  const loadProducer = (value: string) => {
    const user = LocalStorageService.getUser();

    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    if (
      opportunities[index].productUUID ||
      opportunities[index].customProductUUID
    ) {
      dispatch(
        getPipelines({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          successCB: (data: any) => {
            setPipelines(data);
          },
          productUUID: opportunities[index].productUUID,
          customProductUUID: opportunities[index].customProductUUID,
        })
      );
    } else {
      setPipelines([]);
    }
  }, [
    opportunities[index].productUUID,
    opportunities[index].customProductUUID,
  ]);

  useEffect(() => {
    if (setOpportunityPolicys) {
      let value = undefined;
      if (product.length > 0) {
        value = product.find((el) => el.uuid === setOpportunityPolicys);
        setSearchBy(value?.name.toLowerCase().split('_').join(' ') || '');
      } else {
        fetchSingleProduct(
          setOpportunityPolicys,
          (data: IProduct) => {
            setSearchBy(data?.name.toLowerCase().split('_').join(' ') || '');
          },
          opportunities[index].productUUID ? 'PRODUCT' : 'CUSTOM_PRODUCT'
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpportunityPolicys, setStage, producerAgent]);

  const resetProduct = (value: string) => {
    let newOpportunity = Object.assign({}, opportunity);
    let updatedOpportunities = [...opportunities];
    newOpportunity.productUUID = value;
    newOpportunity.customProductUUID = value;
    newOpportunity.pipeline = value;
    newOpportunity.pipelineStageUUID = value;

    updatedOpportunities[index] = newOpportunity;
    setOpportunities(updatedOpportunities);
    setIsOpportunityEdit(true);
    setProduct([]);
  };

  const resetProducer = (value: string) => {
    onSetItem('producer', value);
    setProducers([]);
  };

  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item.PipelineStages.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];

      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }

      return result
        .filter(
          (item, index) => item.name !== 'Won' && index < result.length - 2
        )
        .map((el: any) => {
          return { label: el.name, value: el.uuid };
        });
    }
    return [];
  };

  return (
    <div>
      <div className='d-block opertunityDiv  d-sm-flex justify-content-between'>
        <div className='opertunity-field'>
          <SearchDropdown
            name={`policy_${index}`}
            placeholder='Select Policy'
            isEdit={true}
            values={searchBy}
            searchValue={product}
            setSearch={setSearchBy}
            status={loadingStatus}
            className={`${searchBy && 'filled'} text-capitalize`}
            register={register}
            loadFunction={loadPolicy}
            setUUID={resetProduct}
            content={product?.map((item) => (
              <div
                className='searchValuesCard d-flex align-items-center'
                onClick={() => {
                  onSetItem('pipeline', '');
                  onSetItem('pipelineStage', '');
                  setSearchBy(item?.name.toLowerCase().split('_').join(' '));
                  if (item?.type === 'PRODUCT') {
                    onSetItem('productUUID', item.uuid, 'customProductUUID');
                  } else if (item?.type === 'CUSTOM_PRODUCT') {
                    onSetItem('customProductUUID', item.uuid, 'productUUID');
                  }
                }}
              >
                <div>
                  <h6 className='text-capitalize'>
                    {item.name.toLowerCase().split('_').join(' ')}
                  </h6>
                </div>
              </div>
            ))}
          />
        </div>
        <div className='DatefieldsSmall'>
          <DateField
            name='effectiveDate'
            placeholder='Effective Date'
            onSetItem={onSetItem}
            values={opportunity}
          />
        </div>

        <div className='opertunity-field'>
          <SearchDropdown
            name={`producer_${index}`}
            placeholder='Select producer'
            isEdit={true}
            values={producerSearch}
            searchValue={producers}
            setSearch={setProducerSearch}
            status={loadingStatus}
            className={`${producerSearch && 'filled'}`}
            register={register}
            loadFunction={loadProducer}
            setUUID={resetProducer}
            disabled={LocalStorageService.getUser()?.UserRole?.name === 'AGENT'}
            content={producers?.map((item) => (
              <div
                className='searchValuesCard opportunity-producer d-flex align-items-center'
                onClick={() => {
                  setProducerSearch(
                    `${item.User.firstName} ${
                      item.User.lastName ? item.User.lastName : ''
                    }`
                  );
                  onSetItem('producer', item.uuid);
                }}
              >
                <div className='profile-pic d-flex align-items-center justify-content-center'>
                  {item?.User?.firstName?.charAt(0)}
                  {item?.User?.lastName?.charAt(0)}
                </div>
                <div>
                  <h6>
                    {`${item.User.firstName} ${
                      item.User.lastName ? item.User.lastName : ''
                    }`}
                  </h6>
                  <ul className='user-details'>
                    <li>{item.User?.email}</li>
                    <li>{item?.User?.phoneNumber}</li>
                  </ul>
                </div>
              </div>
            ))}
          />
        </div>
        <div className='opertunity-field opertunityTab'>
          <SelectField
            options={pipelines.map((item) => {
              return { label: item.name, value: item.uuid };
            })}
            name='pipeline'
            placeholder='Pipeline'
            onSetItem={(name: string, value: string) => {
              onSetItem(name, value, 'pipelineStage');
            }}
            values={opportunity}
            noOptionsMessage={() => 'Please select a policy first'}
          />
        </div>
        <div className='opertunity-field'>
          <SelectField
            options={onSetPipelineValue(
              pipelines.find((item) => item.uuid === opportunity.pipeline)
            )}
            name='pipelineStage'
            placeholder='Pipeline stage'
            onSetItem={onSetItem}
            values={opportunity}
            noOptionsMessage={() => 'Please select a pipeline first'}
          />
        </div>
      </div>
      <div className='moredetailsOP'>
        <div
          className='d-flex addopportunity  justify-content-between'
          onClick={() => isAddOpportunity('open')}
        >
          <h5 className='addbutton'>Add more details</h5>
          <img src={arrow} className='leftarrow  ' alt='leftarrow' />
        </div>
      </div>
      <hr />
      <div className='OpportunityModal'>
        {addOpportunity !== 'closed' && (
          <OpportunityModal
            show={addOpportunity}
            closeModal={isAddOpportunity}
            onSetItem={onSetItem}
            values={opportunity}
            opportunityPolicy={searchBy}
          />
        )}
      </div>
    </div>
  );
}

export default Opportunity;

import styled from '@emotion/styled';

export const GoalContainer = styled.div`
  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    &.carrier-styledtab {
      padding-top: 20px;
    }
    .leftArrow {
      margin-left: 15px;
    }
  }
`;

export const Goal = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto 15px;

  // custom.css
  .CircularProgressbar-path {
    stroke: #58c070;
  }
  .CircularProgressbar-trail {
    stroke: #f5f7fc;
  }
  .CircularProgressbar-text {
    fill: #262758;
  }
  .CircularProgressbar-background {
    fill: green;
  }

  .goal-percentageText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    strong {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      display: block;
    }
    .achieved {
      color: #5a5f70;
    }
  }

  .goal-progress-bar {
    height: 12px;
    border-radius: 50px;
    margin-bottom: 8px;
  }
`;

export const GoalContent = styled.div`
  padding: 28px 48px 0 20px;
  .editGoalButton {
    background: #262758;
    padding: 12px 20px;
    border: none;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f5f7fc;
    border-radius: 8px;
    width: 227px;
    justify-content: space-between;
    align-items: center;
    img {
      width: 16px;
    }
  }
  .dropdown-div {
    bottom: -65px;
  }
  .goal-content-div {
    margin-top: 28px;
  }
  .user-goal-contain {
    margin-top: 25px;
  }
  .user-goal-contain .table .BorderedFirstColumn {
    box-shadow: -1px -1px 0px 0px #f5f5f5 inset;
    border-bottom: 1px solid #f5f5f5;
  }
  .user-goal-contain .table .thead .BorderedFirstColumnHead {
    box-shadow: none;
  }
  .user-goal-contain .table .thead .th:first-of-type {
    box-shadow: -1px 0px 0px 0px #e6e9f0 inset;
  }
  .goal-div {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 3px;
    }
  }
  .premium-value-container {
    background: #fafafa;
    border-radius: 12px;
    padding: 28px 0;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  .goal-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .goal-stat {
    font-weight: 600;
    font-size: 44px;
    line-height: 64px;
    text-align: center;
    margin-left: auto;
    width: calc(100% - 10px);
    img {
      width: 55px;
      vertical-align: top;
    }
  }
  .goal-values {
    background: #f5f7fc;
    padding: 12px 20px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-box-align: center;
    align-items: center;
    margin: 8px 0px;

    .goal-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      .goal-icon {
        width: 32px;
        height: 32px;
        background: #e6e9f0;
        border-radius: 50px;
        -webkit-box-align: center;
        align-items: center;
        margin-right: 12px;

        img {
          margin: 6px 12px 6px 6px;
          display: block;
        }
      }
    }
  }

  .users-list {
    background: #fff;
    padding: 20px 30px;
    height: 100%;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 3px;
    }
  }
  .piechart {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 3px;
  }

  .leader-progress-bar {
    width: 100%;
    background-color: #e6e9f0;
    height: 4px;
    margin-bottom: 8px;
    .progress-bar {
      background-color: #58c070;
      border-radius: 8px;
      top: 0;
    }
  }
  .leader-scroll .table .td {
    line-height: unset;
    .vector img {
      vertical-align: text-top;
    }
    label {
      font-size: 14px;
    }
  }
  .leader-scroll .table .td.skeletonLoading-td {
    line-height: 45px;
  }
  .progress-bar {
    background-color: #58c070;
    border-radius: 8px;
  }
  @media screen and (max-width: 1200px) {
    .circle-wrap label {
      font-size: 12px;
    }
    .goal-head {
      margin-top: 0;
    }
    .goal-stat {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: unset;
      margin-top: auto;
      img {
        width: 18px;
      }
    }
    .circle-wrap {
      height: auto;
      margin-top: 27px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    padding-right: 20px;
    .circle-wrap {
      margin-top: 28px;
    }
    .goal-div {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      margin-bottom: 12px;
    }
    .list-container.users-list {
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      margin-bottom: 90px;
      height: unset;
    }
  }

  @media screen and (min-width: 576px) {
    padding: 80px 24px 0 24px;
    background: #fafafa;
    .vector {
      &:hover,
      &:focus {
        background: #e6e9f0;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 80px 48px 0 130px;
    .goal-stat {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-width: 1900px) {
    padding: 116px 120px 0 208px;
  }
`;

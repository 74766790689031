import { useState } from 'react';
import { useSelector } from 'react-redux';
import SelectField from '../../../../components/CustomSelectField';
import InputField from '../../../../components/CustomInputField';
import {
  StyledModal,
  StyledModalMobile,
} from '../../../Pipeline/elements/styles';
import { ILocation } from '../../../../interfaces/Location';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import { selectAllLocations } from '../../../../store/location/locationSlice';
const ChangePrimaryLocationModal = ({
  showModal,
  closeTheModal,
  deletingLocation,
  name,
  data,
  onSubmit,
  setPrimaryLocation,
}: {
  showModal: boolean;
  closeTheModal: Function;
  deletingLocation: ILocation;
  name: string;
  data: ILocation;
  setPrimaryLocation: Function;
  onSubmit: Function;
}) => {
  const assignedLocation = {
    assignedLocation: name,
  };

  const locations = useSelector(selectAllLocations);
  const [errorMessege, setErrorMessege] = useState('');
  // const loadingStatus = useSelector(
  //   (state: { locations: LocationSliceState }) => state.locations.status
  // );
  let userName = {
    deleteLocation: deletingLocation?.uuid,
  };
  const onSetItem = (name: string, value: string) => {
    setErrorMessege('');
  };
  return (
    <div>
      {' '}
      <StyledModal
        show={showModal}
        centered
        dialogClassName='DeleteModalContainer'
        className='d-none d-sm-block'
      >
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => {
              closeTheModal(false);
              setPrimaryLocation(true);
            }}
          />
          <h2>Make location primary?</h2>
          <div className='sub-heading'>
            Do you want to make this location as a primary location? By doing
            so, the current primary location will be changed to non-primary
            location.
          </div>
          <div className='fields'>
            <SelectField
              options={locations.map((item) => {
                return {
                  value: item?.uuid,
                  label: item?.name,
                };
              })}
              placeholder='From Location'
              name='deleteLocation'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <InputField
              type='text'
              placeholder='To location'
              name='assignedLocation'
              disabled
              values={assignedLocation}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                setPrimaryLocation(true);
                closeTheModal(false);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='SubmitButton d-flex justify-content-between align-items-center'
              type='submit'
              onClick={() => {
                setPrimaryLocation(false);
                setTimeout(() => {
                  onSubmit(data);
                  closeTheModal(false);
                }, 1000);
              }}
            >
              Submit
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModal>
      <StyledModalMobile show={showModal} className=' d-sm-none'>
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => {
              closeTheModal(false);
              setPrimaryLocation(true);
            }}
          />
          <h2>Make location primary?</h2>
          <div className='sub-heading'>
            Do you want to make this location as a primary location? By doing
            so, the current primary location will be changed to non-primary
            location.
          </div>
          <div className='fields'>
            <SelectField
              options={locations.map((item) => {
                return {
                  value: item?.uuid,
                  label: item?.name,
                };
              })}
              placeholder='From Location'
              name='deleteLocation'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <InputField
              type='text'
              placeholder='To location'
              name='assignedLocation'
              disabled
              values={assignedLocation}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeTheModal(false);
                setPrimaryLocation(true);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='SubmitButton d-flex justify-content-between align-items-center'
              type='submit'
              onClick={() => {
                setPrimaryLocation(false);
                setTimeout(() => {
                  onSubmit(data);
                  closeTheModal(false);
                }, 500);
              }}
              // ): (setPrimaryLocationData &&
              //                 setPrimaryLocationData((deletingLocation.isPrimary = false)))}}
            >
              Submit
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModalMobile>
    </div>
  );
};

export default ChangePrimaryLocationModal;

import styled from '@emotion/styled';
export const StyledActivity = styled.div`
  .book-shrinked {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    background: #e6e9f0;
  }
  .book-filter {
    max-width: 100%;
  }
  .book-search {
    width: 100%;
    padding-left: 0;
    justify-content: end;
    margin-right: 16px;
    input {
      background: #e6e9f0;
      border: 1px solid #262758;
      outline: none;
      border-radius: 8px;
      width: 384px;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;
    }

    .search-button {
      margin-right: -32px;
      position: relative;
      z-index: 1;
      filter: invert(0.3);
    }

    .close-button {
      margin: 0 20px 0 -40px;
      cursor: pointer;
    }
  }
  @media screen and (min-width: 576px) {
    .activity-head {
      font-weight: 600;
      font-size: 20px;
      margin-top: 15px;
    }
    .navbar-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 16px;
      font-weight: 600;
      min-width: 40px;
      height: 32px;
      width: 32px;
    }
    .filter-button {
      background: #e6e9f0;
      border-radius: 8px;
      width: 176px;
      height: 32px;
    }
    .day-head {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 27px;
    }
    .top-section {
      margin-top: 28px;
    }
    .scroll-div {
      overflow-y: scroll;
      max-height: 64vh;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
    .bullet-list {
      margin-top: 24px;
      margin-left: 30px;
      position: relative;
      b {
        color: #5a5f70;
      }
    }
    .date-div {
      color: #8990a3;
    }
    .imageCard {
      color: #cad9f3;
    }
    .imageCard::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: white;
      border-radius: 50%;
      left: -28px;
      background: #cad9f3;
      margin-top: 5px;
    }
    .bullet-div {
      position: absolute;
      height: 114%;
      top: 16px;
      width: 2px;
      left: -23px;
      background: repeating-linear-gradient(
        180deg,
        #d1d6e5,
        #d1d6e5 5px,
        #fff 5px,
        #fff 10px
      );
    }
  }

  @media screen and (min-width: 1200px) {
    .activity-head {
      margin-top: 28px;
    }
    .top-section {
      margin-top: 26px;
    }
    .day-head {
      margin-top: 27px;
    }
    .filter-button {
      width: 212px;
      height: 40px;
    }
    .navbar-button {
      height: 40px;
    }
    .scroll-div {
      max-height: 54vh;
    }
    .bullet-list {
      margin-top: 24px;
    }
    .imageCard::before {
      left: -28px;
    }
    .bullet-div {
      left: -23px;
      top: 20px;
      height: 105%;
    }
  }
  @media screen and (min-width: 1900px) {
    .activity-head {
      margin-top: 38px;
    }
    .top-section {
      margin-top: 31px;
    }
    .day-head {
      margin-top: 36px;
    }
    .filter-button {
      height: 48px;
    }
    .navbar-button {
      height: 48px;
      width: 48px;
    }
    .scroll-div {
      max-height: 40vh;
    }
    .bullet-list {
      margin-top: 28px;
    }
    .imageCard::before {
      left: -28px;
    }
    .bullet-div {
      left: -23px;
      height: 117%;
    }
  }
`;
export const InnerFieldStyle = styled.div`
  .taskHead {
    padding: 40px 20px 20px 0px;
  }
  .tertiaryButton {
    padding: 12px 20px;
    font-size: 16px;
  }
  .filterbutton {
    display: flex;
    align-items: center;
    background: #e6e9f0;
    height: 32px;
  }
  .addIcon {
    margin-left: 20px;
  }
  .opportunityDiv {
    padding: 10px 20px 10px 0px;
  }
  .documentDiv {
    overflow: hidden;
  }
  .activityMobileCard {
    padding: 30px 15px;
    .SearchField {
      border-right: 1px solid #d1d6e5;
      padding-right: 10px;
    }
  }
  .activity-head {
    font-weight: 600;
    font-size: 16px;
    margin-top: 26px;
  }
  .scroll-div {
    overflow-y: scroll;
    max-height: 80vh;
    padding-bottom: 100px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  .day-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 26px;
  }
  .bullet-list {
    margin-top: 16px;
    margin-left: 30px;
    position: relative;

    b {
      color: #5a5f70;
    }
  }
  .date-div {
    color: #8990a3;
  }
  .imageCard {
    color: #cad9f3;
  }
  .imageCard::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    left: -28px;
    background: #cad9f3;
  }
  .bullet-div {
    position: absolute;
    height: 105%;
    top: 8px;
    width: 2px;
    left: -23px;
    bottom: 56%;
    background: repeating-linear-gradient(
      180deg,
      #d1d6e5,
      #d1d6e5 5px,
      #fff 5px,
      #fff 10px
    );
  }
`;

import styled from '@emotion/styled';

export const StyledGoalModal = styled.div`
  position: relative;
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
  }

  .cross {
    width: 20px;
  }

  h2 {
    font-size: 20px;
  }
  .agency-fields {
    width: 99%;
    margin: 16px auto;
    color: #262758;
  }
  .agency-fields::placeholder {
    color: #262758;
  }
  .cancel-button {
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 88%;
    bottom: 0;
    left: 20px;
    background: #fff;
    z-index: 2;
    padding: 20px 0px;
  }
  .field-section {
    padding-bottom: 50px;
    height: 84vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }
  @media screen and (min-width: 576px) {
    width: 360px;
    .buttonsDiv {
      left: 48px;
      width: 81%;
      padding: 20px 0px;
    }
    .field-section {
      padding-bottom: 50px;
      height: 74vh;
      overflow-y: scroll;
      scrollbar-color: transparent transparent;

      ::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    width: 420px;

    .cross {
      width: 32px;
    }

    h2 {
      font-size: 24px;
    }

    .buttonsDiv {
      left: 48px;
      width: 84%;
      padding: 20px 0px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 576px;

    h2 {
      font-size: 28px;
    }
    .agency-fields {
      margin: 20px auto;
    }

    .cancel-button {
      height: 64px;
      margin-right: 36px;
    }

    .agentButton {
      width: 200px;
      height: 64px;
    }

    .buttonsDiv {
      left: 48px;
      width: 85%;
      padding: 20px 0px;
    }
  }
`;

import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getAccountStatus, getAccountType } from '../../../../api/account';
import { fetchAgents } from '../../../../api/agents';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import SearchDropdown from '../../../../components/SearchDropdown';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { IProducer } from '../../../../interfaces/Agent';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import cross from '../../../../assets/logo/cross.svg';

const AccountsFilter = ({
  show,
  closeModal,
  producer,
  setProducer,
  accountType,
  setAccountType,
  producerSearch,
  setProducerSearch,
  accountStatus,
  setAccountStatus,
  hasPolicy,
  setHasPolicy,
}: {
  show: string;
  closeModal: Function;
  producer: string;
  setProducer: Function;
  accountType: any;
  setAccountType: Function;
  producerSearch: string;
  setProducerSearch: Function;
  accountStatus: any;
  setAccountStatus: Function;
  hasPolicy: any;
  setHasPolicy: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const accountTypeOption = [
    { label: 'Prospect', value: 'false' },
    { label: 'Customer', value: 'true' },
  ];

  const user = LocalStorageService.getUser();

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      // setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            // setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
      }
    }
  };

  useEffect(() => {
    setLoadingStatus('loading');
    getAccountStatus((data: any) => {
      setStatus(data);
      setLoadingStatus('succeeded');
    });
    getAccountType((data: any) => {
      setType(data);
      setLoadingStatus('succeeded');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Insurance Type</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={type.map((item: any) => {
                return {
                  label:
                    item.name?.charAt(0).toUpperCase() +
                    item.name?.toLowerCase().slice(1),
                  value: item.name,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setAccountType(value);
              }}
              isMulti={true}
              value={accountType}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Account Status</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={status.map((item: any) => {
                return {
                  label:
                    item.name?.charAt(0).toUpperCase() +
                    item.name?.toLowerCase().slice(1),
                  value: item.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setAccountStatus(value);
              }}
              isMulti={true}
              value={accountStatus}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Account Type</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={accountTypeOption}
              placeholder='Select'
              onChange={(value: any) => {
                setHasPolicy(value);
              }}
              value={hasPolicy}
            />
          </div>
        )}

        {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Producer</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='producer'
                  placeholder='Select'
                  isEdit={true}
                  addName={true}
                  values={producerSearch}
                  searchValue={producers}
                  setSearch={setProducerSearch}
                  status={loadingStatus}
                  className={`${producerSearch && 'filled'}`}
                  loadFunction={loadProducer}
                  setUUID={resetProducer}
                  content={producers?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setProducerSearch(
                          `${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`
                        );
                        setProducer(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.User?.firstName?.charAt(0)}
                        {item?.User?.lastName?.charAt(0)}
                      </div>
                      <div>
                        <h6>
                          {`${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`}
                        </h6>
                        <ul className='user-details'>
                          <li>{item.User?.email}</li>
                          <li>{item?.User?.phoneNumber}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {accountType.length +
                          accountStatus.length +
                          (hasPolicy?.value ? 1 : 0) +
                          (producer ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setProducer('');
                          setHasPolicy([]);
                          setAccountType([]);
                          setAccountStatus([]);
                          setProducerSearch('');
                        }}
                        disabled={
                          accountType.length +
                            accountStatus.length +
                            (hasPolicy?.value ? 1 : 0) +
                            (producer ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {accountType.length +
                      accountStatus.length +
                      (hasPolicy?.value ? 1 : 0) +
                      (producer ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setProducer('');
                      setHasPolicy([]);
                      setAccountType([]);
                      setAccountStatus([]);
                      setProducerSearch('');
                    }}
                    disabled={
                      accountType.length +
                        accountStatus.length +
                        (hasPolicy?.value ? 1 : 0) +
                        (producer ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default AccountsFilter;

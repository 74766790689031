import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DashboardSliceState } from '../../../store/dashboard/dashboardSlice';

const StatusCard = ({
  item,
  options,
  customSelectStyles,
}: {
  item: any;
  options: any;
  customSelectStyles: any;
}) => {
  const loadingStatus = useSelector(
    (state: { dashboard: DashboardSliceState }) => state.dashboard.status
  );
  return (
    <div className='status-hgt'>
      <div className={`${item.className} stat-div`}>
        <div className='position-relative'>
          <div className='stat-head'>
            <h6>{item.name}</h6>
            <Select
              className='dashboard-select'
              defaultValue={options[0]}
              options={options}
              onChange={(e: any) => item.durationFunction(e)}
              styles={customSelectStyles}
              isSearchable={false}
            />
          </div>
          {loadingStatus === 'loading' ? (
            <Skeleton height={150} />
          ) : (
            loadingStatus === 'succeeded' && (
              <>
                <h5>{item.count}</h5>
                <div className='row status-row'>
                  <div className='col-6 col-xl-8 text-center'>
                    <div className='row'>
                      <div className='col-xl-6 first-div-status'>
                        <span className='status-count'>{item.active}</span>
                        <p>Active</p>
                      </div>
                      <div className='col-xl-6 text-center center-div-status'>
                        <span className='status-count'>{item.new}</span>
                        <p>New</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-6 col-xl-4 text-center'>
                    {item.name === 'Policies' ? (
                      <p>
                        Average{' '}
                        <span className='status-count'>{item.average}</span>{' '}
                        days to close a policy
                      </p>
                    ) : (
                      <p>
                        Average{' '}
                        <span className='status-count'>{item.average}</span>{' '}
                        policies per account
                      </p>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusCard;

import styled from '@emotion/styled';

export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;

  .errorVal {
    margin-top: 12px;
    font-size: 12px;
    color: #cc0000;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1200px) {
      font-size: 14px;
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }

  .modal-container {
    width: auto;
    margin: auto;
    background: #fff;
    padding: 32px;
    box-shadow: 0px 4px 16px rgba(35, 42, 64, 0.2);
    border-radius: 16px;
    position: relative;
    max-width: 100%;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #262758;
    margin-bottom: 12px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262758;
  }

  button {
    border: none;
  }

  .crossbutton {
    border: none;
    background: none;
    position: absolute;
    top: 22px;
    right: 22px;
    &:hover {
      filter: brightness(0%);
    }
  }

  .button-container {
    margin-top: 40px;
    justify-content: right;
  }
  @media screen and (min-width: 576px) {
    .modal-container {
      width: 456px;
      padding: 48px;
    }
    .field {
      width: 360px;
    }
    h4 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 1200px) {
    .modal-container {
      width: 456px;
      padding: 48px;
    }
    .field {
      width: 360px;
    }
  }
  @media screen and (min-width: 1900px) {
    .modal-container {
      width: 580px;
      padding: 48px;
    }
    .field {
      width: 484px;
    }
  }
  @media screen and (max-width: 576px) {
    .modal-container {
      height: 100vh;
      border-radius: 0;
    }
    .button-container {
      width: 100%;
      position: absolute;
      bottom: 24px;
      left: 0;
      padding-right: 32px;
    }
    .border {
      border: 1px solid #e6e9f0;
      margin-top: 547px;
    }
    h4 {
      font-size: 20px;
    }
  }
`;

export const ProceedButton = styled.button`
  width: 132px;
  height: 48px;
  background: #cc0000;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #e5e5e5;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  img {
    margin-left: auto;
  }
  @media screen and (min-width: 1200px) {
    width: 168px;
  }
  @media screen and (min-width: 1900px) {
    height: 64px;
  }
`;

export const CancelButton = styled.button`
  width: 108px;
  height: 48px;
  background: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262758;
  border-radius: 8px;
  margin-right: 16px;

  &:hover {
    background: #e6e9f0;
  }
  @media screen and (min-width: 1900px) {
    height: 64px;
  }
`;

import { useParams } from 'react-router-dom';
import Link from '../../../../components/Link';
import { StyledTab } from '../../../../styles/StyledTab';
import LocalStorageService from '../../../../utils/LocalStorageService';

const Tab = () => {
  const { uuid } = useParams();
  const user = LocalStorageService.getUser();
  return (
    <StyledTab>
      <Link
        to={
          user.UserRole.name === 'ADMIN'
            ? `/admin/agency/${uuid}/customfield/leads`
            : `/settings/customfield/leads`
        }
      >
        Leads
      </Link>
      <Link
        to={
          user.UserRole.name === 'ADMIN'
            ? `/admin/agency/${uuid}/customfield/opportunities`
            : `/settings/customfield/opportunities`
        }
      >
        Opportunities
      </Link>
      <Link
        to={
          user.UserRole.name === 'ADMIN'
            ? `/admin/agency/${uuid}/customfield/accounts`
            : `/settings/customfield/accounts`
        }
      >
        Accounts
      </Link>
      <Link
        to={
          user.UserRole.name === 'ADMIN'
            ? `/admin/agency/${uuid}/customfield/policies`
            : `/settings/customfield/policies`
        }
      >
        Policies
      </Link>
      <Link
        to={
          user.UserRole.name === 'ADMIN'
            ? `/admin/agency/${uuid}/customfield/contacts`
            : `/settings/customfield/contacts`
        }
      >
        Contacts
      </Link>
    </StyledTab>
  );
};

export default Tab;

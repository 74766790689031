import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import {
  getOpportunityDetails,
  selectOpportunity,
} from '../../../store/opportunities/opportunitiesSlice';
import { createPolicy } from '../../../store/policies/policiesSlice';
import { getCustomFields } from '../../../store/customFields/customFieldsSlice';
import { fetchContacts } from '../../../api/contact';
import { fetchCarriers } from '../../../api/carriers';
import { updateStage } from '../../../api/opportunities';
import { getIssuer } from '../../../api/policy';
import { getDocumentType } from '../../../api/documents';
import CustomFieldInput from '../../../components/CustomfieldsInput';
import SearchDropdown from '../../../components/SearchDropdown';
import InputField from '../../../components/InputField';
import DateField from '../../../components/DateField';
import SelectField from '../../../components/SelectField';
import Modal from '../../../components/EditDetailsModal';
import SuccessCard from '../../../components/SuccessCard';
import { IContact } from '../../../interfaces/Contact';
import MarkdownEditor from '../../../components/MarkdownEditor';
import DocumentField from '../../MyBook/Policies/components/DocumentField';
import {
  IPipelineStage,
  IStageData,
  IOpportunity,
} from '../../../interfaces/Opportunity';
import { PolicyInfo } from '../elements/styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import { FieldDiv } from '../../../components/InputField/styles';
import defaultCarrier from '../../../assets/logo/defaultCarrier.svg';
import tick from '../../../assets/logo/tick.svg';
import plus from '../../../assets/logo/blackplus.svg';
import cross from '../../../assets/logo/cross.svg';
import warning from '../../../assets/logo/warning.svg';
import home from '../../../assets/logo/home.svg';
import { contacts } from '../../../assets/nav-icons';

interface IProps {
  show: string;
  setWonOpportunity: Function;
  opportunity: IOpportunity;
  setAllStages: Function;
  source: any;
  stages: IPipelineStage[];
  allStages: IStageData[];
}

const digitsOnly = (value: any) => /^\d*[.{1}\d*]\d*$/.test(value);

const PolicySchema = Yup.object().shape(
  {
    premium: Yup.number()
      .typeError('Please enter valid premium')
      .required('Premium is required')
      .min(1, 'Please enter valid premium'),
    mgaFees: Yup.string()
      .nullable()
      .notRequired()
      .when('mgaFees', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.test('mgaFees', 'Only accepts numbers', digitsOnly),
      }),
    premiumFinanceAmount: Yup.string()
      .nullable()
      .notRequired()
      .when('premiumFinanceAmount', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.test('premiumFinanceAmount', 'Only accepts numbers', digitsOnly),
      }),
    esTax: Yup.string()
      .nullable()
      .notRequired()
      .when('esTax', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.test('esTax', 'Only accepts numbers', digitsOnly),
      }),
    agencyFees: Yup.string()
      .nullable()
      .notRequired()
      .when('agencyFees', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.test('agencyFees', 'Only accepts numbers', digitsOnly),
      }),

    billType: Yup.object().shape({
      value: Yup.string().required('Please select a bill type'),
    }),
    policyNumber: Yup.string()
      .required('Please enter policy number')
      .test(
        'policyNumber',
        'please select a valid number',
        (value) => value !== '0'
      ),
    effectiveDate: Yup.string().required('Please effective date'),

    policyTerm: Yup.object().shape({
      value: Yup.string().required('Please select a policyTerm'),
    }),
    quoteWonDate: Yup.string().required('Quote Won Date is required'),
  },
  [
    ['premiumFinanceAmount', 'premiumFinanceAmount'],
    ['mgaFees', 'mgaFees'],
    ['agencyFees', 'agencyFees'],
    ['esTax', 'esTax'],
  ]
);

const OpportinutyWonModal = ({
  show,
  setWonOpportunity,
  opportunity,
  setAllStages,
  allStages,
  source,
  stages,
}: IProps) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PolicySchema),
  });
  const dispatch = useDispatch<AppDispatch>();
  const watchAllFields = watch();
  const opportunityUUID = opportunity.uuid;
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [premiumError, setPremiumError] = useState('');
  const [carrierUUID, setCarriersUUID] = useState('');
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carriers, setCarriers] = useState<any>([]);
  const [showSuccess, setshowSuccess] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [carrierType, setCarrierType] = useState('');
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [files, setFiles] = useState<any>();
  const [policyCustomFields, setPolicyCustomFields] = useState<any>();

  const [showDocument, setShowDocument] = useState(false);

  const policySoldThrough = [
    { label: 'Directly appointed carrier', value: 'direct' },
  ];

  const billType = [
    { label: 'Direct Bill', value: 'direct' },
    { label: 'Agency', value: 'Agency' },
  ];

  const [totalAmount, setTotalAmount] = useState('');
  const [premiumDownPaymentAmount, setPremiumDownPaymentAmount] = useState('');
  const [contactList, setContacts] = useState<any[]>([]);
  const [contactUUID, setContactUUID] = useState('');
  const [premiumFinance, setPremiumFinance] = useState(false);
  const [contactSearch, setContactSearch] = useState('');
  const [submit, setSubmit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [amountCollected, setAmountCollected] = useState('0');
  const [fileError, setFileError] = useState('');
  const [duetimeVal, setDueTimeVal] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (opportunity)
      dispatch(getOpportunityDetails({ uuid: opportunity.uuid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (carrierSearchText) {
        fetchCarriers('id', 'asc', value, 'True', (data: any) => {
          setCarriers(data);
          setLoadingStatus('succeeded');
        });
      } else {
        setCarriers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    setWonOpportunity('closing');
    setTimeout(() => {
      setWonOpportunity('closed');
    }, 300);
  };

  const [issuingCompany, setIssuingCompany] = useState<any[]>([]);
  const loadContact = (value: string) => {
    setLoadingStatus('loading');
    if (contactSearch) {
      fetchContacts(
        'firstName',
        'asc',
        value,
        25,
        0,
        'True',
        (data: IContact[]) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        'PERSONAL'
      );
    } else {
      setContacts([]);
      setLoadingStatus('succeeded');
    }
  };

  useEffect(() => {
    setValue('policySoldThrough', {
      label: 'Directly appointed carrier',
      value: 'other',
    });
    if (
      watchAllFields.effectiveDate &&
      watchAllFields.policyTerm?.value === 'custom' &&
      Number(watchAllFields.policyTermMonth) < 100
    ) {
      let dateEffective = new Date(watchAllFields.effectiveDate);

      setValue(
        'expiryDate',
        moment(
          new Date(
            dateEffective.setDate(
              dateEffective.getDate() +
                Number(watchAllFields.policyTermMonth) * 30
            )
          )
        ).toDate()
      );
    } else if (
      watchAllFields.policyTerm?.value !== 'custom' &&
      watchAllFields.policyTerm?.value &&
      watchAllFields.effectiveDate
    ) {
      const month = watchAllFields.policyTerm?.value.split(' ')[0];
      let dateEffective = new Date(watchAllFields.effectiveDate);
      setValue(
        'expiryDate',
        moment(
          new Date(
            dateEffective.setDate(dateEffective.getDate() + Number(month) * 30)
          )
        ).toDate()
      );
    }
  }, [
    watchAllFields.policyTerm,
    watchAllFields.effectiveDate,
    watchAllFields.policyTermMonth,
  ]);

  useEffect(() => {
    if (!carrierUUID && watchAllFields.issuerUUID?.value) {
      const item: any = issuingCompany.find(
        (el: any) => el.uuid === watchAllFields.issuerUUID?.value
      );
      loadCarrier(item?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields.issuerUUID]);

  useEffect(() => {
    if (
      carrierSearchText &&
      carrierType === 'CARRIER' &&
      !watchAllFields.issuerUUID &&
      carrierUUID
    ) {
      getIssuer(carrierSearchText, carrierUUID, (data: any) => {
        setIssuingCompany(data);
        setLoadingStatus('succeeded');
      });
    } else {
      loadCarrier('');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierUUID]);

  const opportunityDetails = useSelector(selectOpportunity);
  const [notes, setNotes] = useState<any>(['']);
  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };
  const policyTerm =
    opportunityDetails?.ProductCategory?.name === 'Auto'
      ? [{ label: '6 months', value: '6 months' }]
      : [
          { label: '6 months', value: '6 months' },
          { label: '12 months', value: '12 months' },
          { label: 'Custom', value: 'custom' },
        ];
  const onSubmit = (value: any) => {
    value.customFields = {};
    setSubmit(true);
    let submitTrue = true;
    if (carrierType === 'CARRIER' && !watchAllFields.issuerUUID) {
      submitTrue = false;
    }
    if (Number(watchAllFields.premium <= 0)) {
      setPremiumError('Amount should be grater than 0');
      submitTrue = false;
    }
    policyCustomFields.map((item: any) => {
      if (item.uuid in value) {
        if (item.FieldType?.name === 'TIME') {
          let timeItem = duetimeVal.find((el: any) => el.uuid === item.uuid);
          if (timeItem) value.customFields[item.uuid] = timeItem.value;
        } else {
          value.customFields[item.uuid] = value[item.uuid];
        }
      }
    });
    value.transactionType = watchAllFields?.transactionType?.value;
    value.premiumDownPaymentAmount = premiumDownPaymentAmount;
    value.paymentType = paymentType;
    value.amountCollected = amountCollected;
    value.totalAmount = totalAmount;
    if (carrierType === 'CARRIER') {
      value.carrierUUID = carrierUUID;
    } else if (carrierType === 'CUSTOM_CARRIER') {
      value.customCarrierUUID = carrierUUID;
    }
    if (!carrierUUID) {
      submitTrue = false;
    }
    value.issuerUUID = watchAllFields.issuerUUID?.value;
    value.paymentCollectedBy = '';
    if (watchAllFields.policyTerm?.value === 'custom') {
      value.policyTerm = value.policyTermMonth + ' months';
    } else {
      value.policyTerm = value.policyTerm.value;
    }
    if (
      watchAllFields?.policyTerm?.value === 'custom' &&
      (!watchAllFields?.policyTermMonth ||
        Number(watchAllFields?.policyTermMonth) > 99)
    ) {
      return;
    }
    value.notes = notes;
    value.premiumFinance = premiumFinance ? 'true' : 'false';
    value.directBill =
      watchAllFields.billType?.label === 'Direct Bill' ? 'true' : 'false';
    value.agencyBill =
      watchAllFields.billType?.label === 'Agency' ? 'true' : 'false';
    value.policySoldThrough = watchAllFields.policySoldThrough?.value;
    value.files = files;
    var policies = {
      opportunityUUID,
      contactUUID,
      policies: [value],
    };

    let formData = new FormData();
    for (let [key, value] of Object.entries(policies)) {
      let val: any = value;
      if (key === 'policies' || val === undefined || val === null) continue;
      formData.append(key, val);
    }
    formData.append('policies', JSON.stringify(policies.policies));

    if (showDocument && files && Object.keys(files).length > 0) {
      for (let [key, value] of Object.entries(files)) {
        let val: any = value;

        formData.append(key, val);
      }
    }
    if (submitTrue) {
      setDisable(true);
      dispatch(
        createPolicy({
          data: formData,
          successCB: () => {
            updateStage(
              {
                action: 'changePipelineStage',
                pipelineStageUUID: stages[stages.length - 1].uuid,
              },
              opportunityUUID,
              (opp: IOpportunity) => {
                const updatedStages = [...allStages];

                const sourceColumn = updatedStages[Number(source.droppableId)];
                const destColumn = updatedStages[Number(stages.length - 1)];
                const sourceOpps = [...sourceColumn.opportunities];
                const [removed] = sourceOpps.splice(source.index, 1);
                sourceColumn.opportunities = sourceOpps;
                destColumn.opportunities = [
                  ...destColumn.opportunities,
                  removed,
                ];
                sourceColumn.sum -= opp.leastPremium;
                sourceColumn.count -= 1;
                destColumn.sum += opp.leastPremium;
                destColumn.count += 1;

                updatedStages[Number(source.droppableId)] = sourceColumn;
                updatedStages[stages.length - 1] = destColumn;

                setAllStages(updatedStages);
              }
            );
            setWonOpportunity('closing');
            setSubmit(false);
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                setWonOpportunity('closed');
              }, 300);
            }, 2000);
          },
          errorCB: () => {
            setDisable(false);
          },
        })
      );
    }
  };
  useEffect(() => {
    setTotalAmount(
      String(
        parseFloat(watchAllFields.premium || 0) +
          parseFloat(watchAllFields.agencyFees || 0) +
          parseFloat(watchAllFields.mgaFees || 0) +
          parseFloat(watchAllFields.esTax || 0)
      )
    );
  }, [
    watchAllFields.premium,
    watchAllFields.agencyFees,
    watchAllFields.mgaFees,
    watchAllFields.esTax,
  ]);

  useEffect(() => {
    setAmountCollected(
      String(
        parseFloat(watchAllFields.premium || 0) +
          parseFloat(watchAllFields.agencyFees || 0) +
          parseFloat(watchAllFields.mgaFees || 0) +
          parseFloat(watchAllFields.esTax || 0) -
          parseFloat(watchAllFields.premiumFinanceAmount || 0)
      )
    );
    setPremiumDownPaymentAmount(
      String(
        parseFloat(watchAllFields.premium) -
          parseFloat(watchAllFields.premiumFinanceAmount || 0)
      )
    );
    setPremiumError('');
  }, [
    watchAllFields.premium,
    watchAllFields.agencyFees,
    watchAllFields.mgaFees,
    watchAllFields.esTax,
    watchAllFields.premiumFinanceAmount,
  ]);

  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'POLICY',
        isAddForm: 'true',
        successCB: (data: any) => {
          setPolicyCustomFields(data);
        },
      })
    );
  }, []);
  useEffect(() => {
    policyCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
  }, [policyCustomFields]);

  const resetContact = (value: string) => {
    setContacts([]);
    setContactUUID('');
  };

  const resetCarrier = (value: string) => {
    setCarriers([]);
    setCarriersUUID('');
  };
  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding contact'}
            message={`<b>${opportunityDetails?.name} </b> policy is successfully moved to won.<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={show}>
        <div className='entireModal'>
          <PolicyInfo className='scroll-bar  '>
            <div className='d-flex justify-content-between'>
              <div>
                <h2>Move to Won!</h2>
                <span>
                  Congratulations! Please confirm the following details.{' '}
                </span>
              </div>
              <button className='cross-btn'>
                <img
                  className='cross'
                  src={cross}
                  alt='cross-icon'
                  onClick={() => {
                    // reset();
                    setWonOpportunity('closed');
                  }}
                />
              </button>
            </div>
            <div className='content-div'>
              <div className='  OpportunityDetails  d-flex align-items-center'>
                <div className='imageCard '>
                  <img src={home} alt='' />
                </div>
                <div>
                  <div className='d-flex align-items-center'>
                    <h2 className='opportunityName'>
                      {opportunityDetails?.name}
                    </h2>
                    <h6 className='status statusInactive'>
                      {opportunityDetails?.OpportunityStatus?.name}
                    </h6>
                  </div>
                  <div className='d-flex'>
                    {opportunityDetails?.Account?.Address?.zipCode ? (
                      <span className='Oppdetails firstDe'>
                        {opportunityDetails?.Account?.Address?.zipCode}{' '}
                        {opportunityDetails?.Account?.Address?.street}{' '}
                        {opportunityDetails?.Account?.Address?.state}{' '}
                      </span>
                    ) : (
                      <span className='Oppdetails firstDe'>
                        {opportunityDetails?.ProductId
                          ? opportunityDetails?.Product?.name
                          : opportunityDetails?.CustomProductId
                          ? opportunityDetails?.CustomProduct?.name
                          : 'Unavaliable'}
                      </span>
                    )}
                    <span className='Oppdetails premium'>
                      $
                      {opportunityDetails?.leastPremium
                        ? opportunityDetails?.leastPremium.toLocaleString(
                            'en-US'
                          )
                        : 0}
                    </span>
                    <img
                      src={contacts}
                      alt=''
                      className='contact-img d-none d-sm-block'
                    />{' '}
                    <span className='text-overflow-container d-none d-sm-block'>
                      <span
                        className=' nav-decor borderDiv'
                        onClick={() =>
                          navigate(
                            `/account/${opportunityDetails?.Account?.uuid}`
                          )
                        }
                      >
                        Account:
                        {opportunityDetails?.Account?.name}
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>
                            {opportunityDetails?.Account?.name}
                          </span>
                        </span>
                      </span>
                    </span>
                    <img
                      src={contacts}
                      alt=''
                      className='contact-img d-none d-sm-block'
                    />
                    <span className='text-overflow-container d-none d-sm-block'>
                      <span
                        className='  nav-decor'
                        onClick={() =>
                          navigate(
                            `/contacts/${opportunityDetails?.Account.AccountContacts[0].Contact.uuid}`
                          )
                        }
                      >
                        Contact:{' '}
                        {
                          opportunityDetails?.Account?.AccountContacts[0]
                            ?.Contact?.firstName
                        }{' '}
                        {
                          opportunityDetails?.Account?.AccountContacts[0]
                            ?.Contact?.lastName
                        }
                      </span>
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {
                            opportunityDetails?.Account?.AccountContacts[0]
                              ?.Contact?.firstName
                          }{' '}
                          {
                            opportunityDetails?.Account?.AccountContacts[0]
                              ?.Contact?.lastName
                          }
                        </span>
                      </span>
                    </span>
                    {opportunityDetails &&
                      opportunityDetails?.Account?.AccountContacts?.length >
                        1 && (
                        <span className='count-div'>
                          +
                          {opportunityDetails?.Account?.AccountContacts
                            ?.length - 1}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='add-policy-form'
              >
                <div className='d-sm-flex justify-content-between  '>
                  <div className='fields firstField'>
                    <SearchDropdown
                      name='contact'
                      placeholder='Contact associated to'
                      isEdit={true}
                      values={contactSearch}
                      searchValue={contactList}
                      setSearch={setContactSearch}
                      status={loadingStatus}
                      className={`${contactSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadContact}
                      required
                      setUUID={resetContact}
                      content={contactList?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setContactSearch(
                              item?.firstName + ' ' + item?.lastName
                            );
                            setContactUUID(item?.uuid);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}{' '}
                            {item?.lastName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item.firstName} {item.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {item?.ContactDetails.length > 0
                                  ? item?.ContactDetails[0]?.value
                                  : '---'}
                              </li>
                              <li>
                                {item?.ContactDetails.length > 0
                                  ? item?.ContactDetails[1]?.value
                                  : '---'}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />

                    {!contactUUID && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Please select a contact
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <h2 className='carrier-info'>Policy sold through</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <SelectField
                      options={policySoldThrough}
                      name='policySoldThrough'
                      disabled={true}
                      placeholder='Policy sold through'
                      control={control}
                    />

                    {!watchAllFields?.policySoldThrough?.value &&
                    errors.policySoldThrough ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.policySoldThrough?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <h2 className='carrier-info'>Carrier info</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <SearchDropdown
                      name='opportunityCarrierName'
                      placeholder='Carrier name'
                      isEdit={true}
                      values={carrierSearchText}
                      searchValue={carriers}
                      setSearch={setCarrierSearchText}
                      status={loadingStatus}
                      required
                      className={`${carrierSearchText && 'filled'}`}
                      loadFunction={loadCarrier}
                      setUUID={resetCarrier}
                      content={carriers?.map((item: any) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setCarrierSearchText(item?.name);
                            setCarriersUUID(item.uuid);
                            setCarrierType(item.type);
                          }}
                        >
                          {item.logo ? (
                            <img
                              src={item.logo}
                              alt=''
                              className='carrierlogo-icon'
                            />
                          ) : (
                            <img
                              src={defaultCarrier}
                              alt=''
                              className='carrierlogo-icon'
                            />
                          )}
                          <div>
                            <h6>{item.name}</h6>
                            <ul className='user-details'>
                              <li>{item?.groupCode}</li>
                              <li>{item?.groupName}</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {!carrierUUID && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Please select a carrier
                      </div>
                    ) : null}
                  </div>
                  {carrierType === 'CARRIER' ? (
                    <div className='fields'>
                      <SelectField
                        options={issuingCompany?.map((item: any) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='issuerUUID'
                        required
                        placeholder='Issuing company'
                        control={control}
                      />
                      {carrierType === 'CARRIER' &&
                      !watchAllFields.issuerUUID &&
                      submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please select an Issuer
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <div>
                  <h2 className='carrier-info'>Policy specific details</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <InputField
                      name='policyNumber'
                      type='text'
                      placeholder='Policy Number'
                      register={register}
                      required
                      className={`${errors.policyNumber && 'error-field'} ${
                        watchAllFields.policyNumber && 'filled'
                      }`}
                    />
                    {errors.policyNumber ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.policyNumber?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <SelectField
                      options={policyTerm}
                      name='policyTerm'
                      required
                      placeholder='Policy Term'
                      control={control}
                    />
                    {!watchAllFields?.policyTerm?.value && errors.policyTerm ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors?.policyTerm?.message
                          ? 'Please select a policyTerm'
                          : errors.policyTerm?.value?.message}
                      </div>
                    ) : null}
                  </div>
                  {watchAllFields?.policyTerm?.value === 'custom' ? (
                    <div className='fields'>
                      <InputField
                        name='policyTermMonth'
                        type='number'
                        required
                        placeholder='Policy Term in Months'
                        month='months'
                        register={register}
                        className={`${
                          errors.policyTermMonth && 'error-field'
                        } ${watchAllFields.policyTermMonth && 'filled'}`}
                      />
                      {watchAllFields?.policyTerm?.value === 'custom' &&
                      (!watchAllFields?.policyTermMonth ||
                        Number(watchAllFields?.policyTermMonth) > 99) &&
                      submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please enter a valid month
                        </div>
                      ) : null}
                      {errors.policyTermMonth?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.policyTermMonth?.message}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <DateField
                      name='effectiveDate'
                      placeholder='Effective Date'
                      control={control}
                      required
                      value={watchAllFields.effectiveDate}
                    />
                    {errors.effectiveDate ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.effectiveDate?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields'>
                    <DateField
                      name='expiryDate'
                      placeholder='Expiry Date'
                      readOnly={true}
                      required
                      control={control}
                      value={watchAllFields.expiryDate}
                    />
                    {errors.expiryDate ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.expiryDate?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <DateField
                      name='quotePresentedDate'
                      placeholder='Quote presented date'
                      control={control}
                      value={watchAllFields.quotePresentedDate}
                    />
                  </div>
                  <div className='fields'>
                    <DateField
                      name='quoteWonDate'
                      placeholder='Quote won Date'
                      control={control}
                      value={watchAllFields.quoteWonDate}
                      required
                    />
                    {errors.quoteWonDate?.message && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.quoteWonDate?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <h2 className='carrier-info'>Bill Type</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <SelectField
                      options={billType}
                      name='billType'
                      placeholder='Bill Type'
                      required
                      control={control}
                    />
                    {!watchAllFields?.billType?.value && errors.billType ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.billType?.message
                          ? 'Please select a bill type'
                          : errors.billType?.value?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <h2 className='carrier-info'>Payment Type</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <label className='radio-container'>
                      <input
                        type='radio'
                        className='form-check-input'
                        name='paymentType'
                        value='Auto Pay'
                        onChange={() => setPaymentType('Auto Pay')}
                      />
                      Auto Pay
                    </label>
                  </div>
                  <div className='fields'>
                    <label className='radio-container'>
                      <input
                        type='radio'
                        className='form-check-input'
                        name='paymentType'
                        value='Manual Pay'
                        onChange={() => setPaymentType('Manual Pay')}
                      />
                      Manual Pay
                    </label>
                  </div>
                </div>
                {watchAllFields.billType?.label === 'Agency' && (
                  <>
                    <div>
                      <h2 className='carrier-info'>Premium Finance</h2>
                    </div>
                    <div className='d-sm-flex justify-content-between '>
                      <div className='fields'>
                        <label className='radio-container'>
                          <input
                            type='radio'
                            className='form-check-input'
                            name='premiumFinance'
                            value='yes'
                            onChange={() => setPremiumFinance(true)}
                          />
                          Yes
                        </label>
                        {errors.premiumFinance ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.premiumFinance?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <label className='radio-container'>
                          <input
                            type='radio'
                            className='form-check-input'
                            name='premiumFinance'
                            value='no'
                            onChange={() => setPremiumFinance(false)}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {premiumFinance &&
                      watchAllFields.billType?.label === 'Agency' && (
                        <div className='d-sm-flex justify-content-between '>
                          <div className='fields'>
                            <InputField
                              name='premiumFinanceCompanyName'
                              type='text'
                              placeholder='Premium Finance Company'
                              register={register}
                              className={`${
                                errors.premiumFinanceCompanyName &&
                                'error-field'
                              } ${
                                watchAllFields.premiumFinanceCompanyName &&
                                'filled'
                              }`}
                            />
                          </div>
                        </div>
                      )}
                  </>
                )}
                <div>
                  <h2 className='carrier-info'>Amount details</h2>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <InputField
                      name='premium'
                      type='text'
                      required
                      placeholder='Premium amount'
                      register={register}
                      prefix='$'
                      className={`${errors.premium && 'error-field'} ${
                        watchAllFields.premium && 'filled'
                      }`}
                    />
                    {errors.premium || premiumError ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.premium?.message || premiumError}
                      </div>
                    ) : null}
                  </div>
                  {premiumFinance &&
                  watchAllFields.billType?.label === 'Agency' ? (
                    <div className='fields'>
                      <FieldDiv>
                        <div
                          className={`d-flex flex-column w-100 ${
                            premiumDownPaymentAmount ? 'filled' : ''
                          } input-container`}
                        >
                          <input
                            id='premiumDownPaymentAmount'
                            name='premiumDownPaymentAmount'
                            className={`input-field CustomField`}
                            type='number'
                            autoComplete='off'
                            value={premiumDownPaymentAmount}
                            readOnly
                            disabled
                          />
                          <span className='prefix-style disabledStyle'>$</span>
                          <label
                            htmlFor='premiumDownPaymentAmount'
                            className='input-placeholder'
                          >
                            Premium: Down Payment amount
                          </label>
                        </div>
                      </FieldDiv>
                    </div>
                  ) : (
                    <div className='fields'>
                      <InputField
                        name='mgaFees'
                        type='text'
                        placeholder='MGA Fees (Optional)'
                        register={register}
                        prefix='$'
                        className={`${errors.mgaFees && 'error-field'} ${
                          watchAllFields.mgaFees && 'filled'
                        }`}
                      />
                      {errors.mgaFees ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.mgaFees?.message}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>

                <div className='d-sm-flex justify-content-between '>
                  {premiumFinance &&
                  watchAllFields.billType?.label === 'Agency' ? (
                    <>
                      <div className='fields'>
                        <InputField
                          name='premiumFinanceAmount'
                          type='text'
                          prefix='$'
                          placeholder='Premium: Financed amount'
                          register={register}
                          className={`${
                            errors.premiumFinanceAmount && 'error-field'
                          } ${watchAllFields.premiumFinanceAmount && 'filled'}`}
                        />
                        {errors.premiumFinanceAmount ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.premiumFinanceAmount?.message}
                          </div>
                        ) : null}
                      </div>

                      <div className='fields'>
                        <InputField
                          name='mgaFees'
                          type='text'
                          prefix='$'
                          placeholder='MGA Fees (Optional)'
                          register={register}
                          className={`${errors.mgaFees && 'error-field'} ${
                            watchAllFields.mgaFees && 'filled'
                          }`}
                        />
                        {errors.mgaFees ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.mgaFees?.message}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>

                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <InputField
                      name='esTax'
                      type='text'
                      prefix='$'
                      placeholder='E&S Tax (optional)'
                      register={register}
                      className={`${errors.esTax && 'error-field'} ${
                        watchAllFields.esTax && 'filled'
                      }`}
                    />
                    {errors.esTax ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.esTax?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields'>
                    <InputField
                      name='agencyFees'
                      type='text'
                      prefix='$'
                      placeholder='Agency Fees (optional)'
                      register={register}
                      className={`${errors.agencyFees && 'error-field'} ${
                        watchAllFields.agencyFees && 'filled'
                      }`}
                    />
                    {errors.agencyFees ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.agencyFees?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between '>
                  <div className='fields'>
                    <InputField
                      name='otherFees'
                      type='number'
                      prefix='$'
                      placeholder='Other fees / charges (optional)'
                      register={register}
                      className={`${errors.otherFees && 'error-field'} ${
                        watchAllFields.otherFees && 'filled'
                      }`}
                    />
                  </div>
                  <div className='fields'>
                    <FieldDiv>
                      <div
                        className={`d-flex flex-column w-100 ${
                          totalAmount ? 'filled' : ''
                        } input-container`}
                      >
                        <input
                          id='totalAmount'
                          name='totalAmount'
                          className={`input-field CustomField`}
                          type='number'
                          autoComplete='off'
                          value={totalAmount}
                          readOnly
                          disabled
                        />
                        <span className='prefix-style disabledStyle'>$</span>
                        <label
                          htmlFor='totalAmount'
                          className='input-placeholder'
                        >
                          Total amount
                        </label>
                      </div>
                    </FieldDiv>
                    {!totalAmount ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.totalAmount?.message}
                      </div>
                    ) : null}
                  </div>
                </div>
                {watchAllFields.billType?.label === 'Agency' && (
                  <div className='d-sm-flex justify-content-between '>
                    <div className='fields'>
                      <FieldDiv>
                        <div
                          className={`d-flex flex-column w-100 ${
                            amountCollected ? 'filled' : ''
                          } input-container`}
                        >
                          <input
                            id='amountCollected'
                            name='amountCollected'
                            className={`input-field CustomField`}
                            type='number'
                            autoComplete='off'
                            value={amountCollected}
                            readOnly
                            disabled
                          />
                          <span className='prefix-style disabledStyle'>$</span>
                          <label
                            htmlFor='totalAmount'
                            className='input-placeholder'
                          >
                            amountCollected
                            <small> (Excluding Payment Processing Fees)</small>
                          </label>
                        </div>
                      </FieldDiv>
                      {errors.amountCollected ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.amountCollected}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div>
                  {policyCustomFields?.length > 0 ? (
                    <>
                      <div>
                        <h2 className='contact-info'>Additional fields</h2>
                      </div>
                      {policyCustomFields?.map((item: any, index: number) =>
                        index % 2 === 0 ? (
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                                setDueTimeVal={setDueTimeVal}
                                duetimeVal={duetimeVal}
                              />
                            </div>
                            {policyCustomFields?.length > index + 1 ? (
                              <div className='fields'>
                                <CustomFieldInput
                                  item={policyCustomFields[index + 1]}
                                  register={register}
                                  control={control}
                                  value={
                                    watchAllFields[
                                      policyCustomFields[index + 1].uuid
                                    ]
                                  }
                                  setDueTimeVal={setDueTimeVal}
                                  duetimeVal={duetimeVal}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )}
                    </>
                  ) : null}
                </div>
                <div>
                  <div className='d-sm-flex justify-content-end w-100'>
                    <button
                      type='button'
                      className='addNotesButton'
                      onClick={() => {
                        notes.slice(-1).pop() !== '' &&
                          setNotes([...notes, '']);
                      }}
                    >
                      <img src={plus} alt='' className='smallAddbutton' /> Add
                      note
                    </button>
                  </div>

                  {notes &&
                    notes?.map((item: any, index: number) => {
                      return (
                        <div className='d-flex align-items-center descriptionField  col'>
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Write submission comments here'
                              note={item}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                <h2 className='contact-info documnetsAdd'>
                  Add documents<span>(optional)</span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      onClick={() => setShowDocument(!showDocument)}
                    />
                    <span className='slider round'></span>
                  </label>
                </h2>
                {showDocument && (
                  <div>
                    <h2 className='contact-info'>REQUIRED DOCUMENTS</h2>

                    <div className='d-sm-flex flex-wrap justify-content-between'>
                      {DocumentTypes &&
                        DocumentTypes?.map((item: any, index: number) => (
                          <div key={index} className='fields'>
                            <DocumentField
                              setFile={setFiles}
                              name={`0_${item.uuid}`}
                              placeholder={item.name}
                              file={files}
                              colorless={true}
                              setFileError={setFileError}
                            />
                          </div>
                        ))}
                    </div>
                    {fileError ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {fileError}
                      </div>
                    ) : null}
                  </div>
                )}

                <div className='buttonsDiv d-flex align-items-center justify-content-end '>
                  <button
                    className='nextButton cancelButton'
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className={`nextButton `}
                    disabled={disable}
                  >
                    Submit <img className='qualifyArrow' src={tick} alt='' />
                  </button>
                </div>
              </form>
            </div>
          </PolicyInfo>
        </div>
      </Modal>
    </>
  );
};

export default OpportinutyWonModal;

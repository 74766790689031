import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import DeleteWarningModal from './DeleteWarmimgModal';
import DeletePipelineModal from './DeletePipelineModal';
import { IPipeline, IPipelineStage } from '../../../../interfaces/Opportunity';
import EditPipelineDrawer from '../../../../components/EditPipelineDrawer';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import { pipelineStatusUpdate } from '../../../../store/pipelines/pipelineSlice';
import InActiveWarningModal from './InActiveWarningModal';

const ManageCard = ({
  pipeline,
  setShowSuccess,
  setDeletedItem,
  deleteSuccess,
}: {
  pipeline: IPipeline;
  setShowSuccess: Function;
  setDeletedItem: Function;
  deleteSuccess: boolean;
}) => {
  const dropRef = useRef(null);

  const [pipelineStage, setpipelineStage] = useState<IPipelineStage[]>();
  const [dropDown, setDropDown] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [onDelete, setDelete] = useState(false);
  const [showEdit, showEditModal] = useState('closed');
  const [showInActive, showInActiveModal] = useState('closed');

  useEffect(() => {
    onSetPipelineValue(pipeline);
  }, [pipeline]);

  useOutsideClick(dropRef, setDropDown);
  const { uuid } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item?.PipelineStages?.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];
      setpipelineStage(result);
      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }
    }
  };
  const updateStatus = (pipelineUUID: string) => {
    let data = {
      agencyUUID: uuid,
      action: pipeline.active ? 'inactive' : 'active',
    };
    dispatch(
      pipelineStatusUpdate({
        uuid: pipelineUUID,
        data: data,
      })
    );
  };

  const InactiveModal = () => {
    if (pipeline?.active) {
      showInActiveModal('open');
    } else {
      updateStatus(pipeline?.uuid);
    }
  };
  return (
    <div className='PipelineCard d-flex'>
      <div className='checkboxDiv'>
        <label className='switch'>
          <input
            type='checkbox'
            disabled={pipeline?.default ? true : false}
            checked={pipeline?.active}
            className='inputCheckbox'
            onClick={() => {
              InactiveModal();
            }}
          />
          <span className='slider' />
        </label>
      </div>
      <div className='PipelineStagesRow d-flex justify-content-between w-100 align-items-center'>
        <div>
          <h2 className='SubHead'>
            {pipeline.name ? pipeline?.name : 'unknown'}
          </h2>
          {pipelineStage &&
            pipelineStage.map((item: IPipelineStage) => {
              return (
                <span key={item.id}>
                  <span className='PipelineStage'>{item.name}</span>
                  {item.nextStageId ? (
                    <img src={vector} className='NextVector' alt='' />
                  ) : null}
                </span>
              );
            })}
        </div>

        <div className='d-flex'>
          <div
            className='vector d-flex align-items-center justify-content-center position-relative'
            style={{ cursor: pipeline.default ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              pipeline.default ? setDropDown(false) : setDropDown(!dropDown);
            }}
            ref={dropRef}
          >
            <img src={vector} alt='vector' />
            {dropDown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setDropDown(false);
                    showEditModal('open');
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='delete-div'
                  onClick={() => {
                    setDeletedItem(pipeline);
                    setDropDown(false);
                    if (pipeline.opportunityCount === 0) {
                      setDelete(true);
                    } else {
                      setDeleteWarning(true);
                    }
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </div>
          {deleteWarning && (
            <DeleteWarningModal
              showModal={deleteWarning}
              onCloseModal={setDeleteWarning}
              UUID={pipeline?.uuid}
              onDeletePipeline={setDelete}
              name={pipeline?.name}
            />
          )}
          {onDelete && (
            <DeletePipelineModal
              showModal={onDelete}
              onCloseModal={setDelete}
              UUID={pipeline?.uuid}
              onDelete={setDelete}
              setDeleteSuccess={setShowSuccess}
            />
          )}
          {showEdit !== 'closed' && (
            <EditPipelineDrawer
              show={showEdit}
              closeModal={showEditModal}
              pipeline={pipeline}
              setDeleteSuccess={setShowSuccess}
              deleteSuccess={deleteSuccess}
            />
          )}
          {showInActive && (
            <InActiveWarningModal
              showModal={showInActive}
              setShowModal={showInActiveModal}
              onCloseModal={updateStatus}
              pipelineUUID={pipeline?.uuid}
              name={pipeline?.name}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageCard;

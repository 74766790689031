import styled from '@emotion/styled';
import circle from '../../../assets/logo/circle.svg';
export const QuoteCardDiv = styled.div`
  .vector .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    top: 35px;
    font-size: 14px;
    z-index: 4;
    width: 170px;
    cursor: pointer;

    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .quotediv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        filter: invert(1);
        width: 19px;
      }
    }
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  .quote-stage-pill {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #191919;
    background: #e6e9f0;
    border-radius: 50px;
    margin-left: 12px;
    padding: 4px 16px;
    height: 24px;
    text-transform: capitalize;
    margin-top: 8px;
  }
  .green-bg {
    color: #3d8a68;
    background: #d4fad4;
  }
  .danger-bg {
    color: #9a0a0d;
    background: #f9e4d2;
  }
  .blue-bg {
    color: #191919;
    background: #ecf4f9;
  }
  .yellow-bg {
    color: #914123;
    background: #fcf2cf;
  }
  .vector {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    border-radius: 8px;
    background: #f5f7fc;
    cursor: pointer;
    position: relative;
    border: none;

    flex-shrink: 0;
    :hover,
    :focus {
      background: #e6e9f0;
    }
  }
  .opportunity {
    margin-top: 10px;
    width: 100%;
    box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 4px 4px 4px 4px;
    .documentDate {
      max-width: 110px;
    }
    .documentAmmountDetails {
      width: 50%;
    }

    .topContent {
      .topDetails {
        .oppstatus {
          text-transform: capitalize;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 600;
          padding: 0px 4px;
          height: 20px;
        }
        .greenC {
          background: #d4fad4;
          color: #3d8a68;
        }
        .yellowC {
          background: #fcf2cf;
          color: #914123;
        }
        .oppName {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
    .quotesCount {
      width: 80px;
    }

    .borderDiv {
      border-right: 1px solid #d1d6e5;
    }
    .mobileProfile {
      width: 32px;
      height: 32px;
      font-size: 12px;
      padding-left: 6px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .mobileProfileAccount {
      width: 32px;
      height: 32px;
      font-size: 12px;
      margin: 10px 16px 10px 0px;
      border-left: 1px solid #d1d6e5;
    }
    .imageCard {
      width: 40px;
      height: 40px;
      background: #f5f7fc;
      border-radius: 8px;
    }
    .oppDetaislfields {
      width: 100%;
    }
    .oppDetaislfieldsQuotes {
      width: 100%;
      padding: 17px 0px 18px;
    }
    .valueField {
      font-size: 12px;
      padding: 0 10px 0 8px;
      color: #262758;
      max-width: 300px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .carrier-head {
      font-size: 14px;
      font-weight: 600;
      color: #262758;
    }
    .valHead {
      color: #262758;
      font-size: 12px;
      margin-right: 10px;
    }
    .DocumentHead {
      padding: 0 10px 0 0;
      margin: 0;
    }
    .detailsQuotes {
      margin-top: 12px;
    }
    .documentField {
      padding: 4px 1 0px;
    }
    .quotingbtn {
      height: 32px;
      padding: 4px 20px;
      margin-right: 10px;
      width: 100%;
    }
    .editquotingbtn {
      height: 32px;
      padding: 4px 36px;
      margin-right: 10px;
      width: 100%;
    }
    .viewbtn {
      height: 32px;
      padding: 4px 10px;
      margin-right: 10px;
    }
    .actionMenu {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      padding-left: 10px;
      border-radius: 8px;
      background: #f5f7fc;
    }

    .addIcon {
      margin: 0;
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
    .quotesName {
      margin-left: 20px;
    }
    .PinCard {
      padding: 14px 24px 14px 0px;
    }
    .Card {
      padding-left: 0px;
      border-right: 1px solid #d1d6e5;
    }
    .PremiumDiv {
      padding: 14px 35px 14px 0px;
      margin-right: 32px;
    }
    .QuoteHead {
      font-weight: 600;
      font-size: 20px;
    }
    .number {
      background: #e6e9f0;
      font-size: 12px;
      padding: 4px 4px;
      margin-right: 10px;
      margin-left: 4px;
      border-radius: 8px;
    }
    .dateEffective {
      padding: 0;
    }
    .quotesCount {
      padding-right: 20px;
    }
    .horz-row {
      border: 1px solid #e6e9f0;
      margin-top: 16px;
    }
    .quoteCarrierText {
      margin-top: 8px;
    }
  }
  @media screen and (min-width: 576px) {
    .opportunity {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 0px 24px;
      .topContent {
        padding: 24px 0px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 45%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 20px;
            padding: 0px 4px;
            height: 20px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            font-weight: 600;
            font-size: 24px;
            max-width: 50%;
            margin-left: 20px;
          }
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
      }
      .oppDetaislfields {
        width: 60%;
        padding: 17px 24px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 0px 18px;
      }
      .valueField {
        font-weight: 600;
        font-size: 14px;
        padding: 0 10px 0 1px;
        color: #000000;
      }
      .carrier-head {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        height: 32px;
        padding: 4px 20px;
      }
      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }

      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 14px 24px 14px 0px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 14px 0px;
        margin-right: 32px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 20px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        padding: 4px 4px;
        margin-right: 10px;
        margin-left: 4px;
        border-radius: 8px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .opportunity {
      margin-top: 10px;
      width: 100%;
      box-shadow: 0px 1px 4px 0px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 16px 32px 0px 24px;
      .topContent {
        padding: 24px 0px;
        border-bottom: 1px solid #e6e9f0;
        .topDetails {
          width: 35%;
          .oppstatus {
            text-transform: capitalize;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 20px;
            padding: 0px 4px;
            height: 20px;
          }
          .greenC {
            background: #d4fad4;
            color: #3d8a68;
          }
          .yellowC {
            background: #fcf2cf;
            color: #914123;
          }
          .oppName {
            font-weight: 600;
            font-size: 24px;
            max-width: 50%;
            margin-left: 20px;
          }
        }
      }
      .imageCard {
        width: 40px;
        height: 40px;
        background: #f5f7fc;
        border-radius: 8px;
      }
      .oppDetaislfields {
        width: 55%;
        padding: 17px 24px 18px;
      }
      .oppDetaislfieldsQuotes {
        width: 60%;
        padding: 17px 0px 18px;
      }
      .valueField {
        font-weight: 600;
      }
      .valHead {
        color: #262758;
        font-size: 14px;
        display: block;
      }
      .quotingbtn {
        padding: 4px 20px;
        width: 100%;
      }

      .actionMenu {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 8px;
        background: #f5f7fc;
      }
      .addIcon {
        filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
          brightness(0%) contrast(103%);
      }
      .quotesName {
        margin-left: 20px;
      }
      .PinCard {
        padding: 14px 24px 14px 0px;
      }
      .Card {
        padding-left: 0px;
        border-right: 1px solid #d1d6e5;
      }
      .PremiumDiv {
        border-right: 1px solid #d1d6e5;
        padding: 14px 35px 14px 0px;
        margin-right: 32px;
      }
      .QuoteHead {
        font-weight: 600;
        font-size: 20px;
      }
      .number {
        background: #e6e9f0;
        font-size: 12px;
        padding: 4px 4px;
        margin-right: 10px;
        margin-left: 4px;
        border-radius: 8px;
      }
    }
  }
`;

export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  .quote-stage-container {
    height: calc(100vh - 220px);
    overflow-y: scroll;
    padding-right: 4px;
    margin-right: -8px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .radio-container {
    padding: 14px 18px;
    padding-right: 15px;
    background: #ffffff;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
    text-transform: capitalize;
    margin: 8px 0;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }
    &.activestage:hover {
      border: 1px solid #262758;
    }

    .form-check-input {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .form-check-input:checked[type='radio'] {
      background-image: url(${circle});
      background-color: #fff;
      box-shadow: none;
    }
  }

  .modal-container {
    width: 100%;
    margin: auto;
    background: #fff;
    padding: 32px;
    box-shadow: 0px 4px 16px rgba(35, 42, 64, 0.2);
    border-radius: 16px;
    position: relative;
    max-width: 100%;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #262758;
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262758;
    margin-bottom: 16px;
  }

  button {
    border: none;
  }
  .submitButton {
    background: #262758;
    border-radius: 8px;
    width: 168px;
    height: 48px;
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .crossbutton {
    border: none;
    background: none;
    position: absolute;
    top: 22px;
    right: 22px;
    &:hover {
      filter: brightness(0%);
    }
  }

  .button-container {
    margin-top: 40px;
    justify-content: right;
  }
  .activestage {
    border: 1px solid #262758;
  }

  @media screen and (min-width: 576px) {
    .modal-container {
      width: 456px;
      padding: 32px 48px;
    }
    .field {
      width: 360px;
    }
    h4 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
    .quote-stage-container {
      height: 280px;
    }
  }
  @media screen and (min-width: 1200px) {
    .modal-container {
      width: 456px;
      padding: 32px 48px;
    }
    .field {
      width: 360px;
    }
    h4 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media screen and (min-width: 1900px) {
    .modal-container {
      width: 580px;
      padding: 32px 48px;
    }
    .field {
      width: 484px;
    }
  }
  @media screen and (max-width: 576px) {
    .modal-container {
      height: 100vh;
      border-radius: 0;
    }
    .button-container {
      width: 100%;
      position: absolute;
      bottom: 24px;
      left: 0;
      padding-right: 32px;
    }
    .border {
      border: 1px solid #e6e9f0;
      margin-top: 547px;
    }
    h4 {
      font-size: 20px;
    }
  }
`;

export const StyledQuoteModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;

  h4 {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 16px;
    margin-bottom: 28px;
    border-bottom: 1px solid #e6e9f0;
  }

  .crossbutton {
    border: none;
    background: none;
    position: absolute;
    top: 22px;
    right: 22px;
    &:hover {
      filter: brightness(0%);
    }
  }

  .fields {
    font-size: 14px;
    margin-bottom: 28px;
    .label {
      color: #5a5f70;
      font-size: 12px;
      margin-bottom: 4px;
    }

    .value {
      text-transform: capitalize;
    }

    .terminal-value {
      color: #cc0000;
    }
  }

  @media screen and (min-width: 576px) {
    .drawer-container {
      width: 456px;
    }

    h4 {
      padding-bottom: 24px;
      margin-bottom: 32px;
    }

    .crossbutton {
      right: 38px;
    }

    .fields {
      width: 50%;
      margin-bottom: 32px;
    }
  }
  @media screen and (min-width: 1200px) {
    .drawer-container {
      width: 516px;
    }

    h4 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 1900px) {
    .drawer-container {
      width: 696px;
    }

    h4 {
      font-size: 28px;
      padding-bottom: 32px;
      margin-bottom: 40px;
    }

    .fields {
      font-size: 16px;
      margin-bottom: 36px;
    }
  }
`;

import { useState, useEffect } from 'react';
import { fetchCarriers, fetchCarrier } from '../../api/carriers';
import Modal from '../Modal';
import InputField from '../CustomInputField';
import SelectField from '../CustomSelectField';
import DateField from '../CustomDateField';
import SearchDropdown from '../SearchDropdown';
import GoogleSearchInput from '../GoogleSearchInput';
import { LeadStyledModal } from './styles';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import defaultCarrier from '../../assets/logo/defaultCarrier.svg';

function AddOpportunityDrawer({
  show,
  closeModal,
  onSetItem,
  values,
  opportunityPolicy,
}: {
  show: string;
  closeModal: Function;
  onSetItem: Function;
  values: any;
  opportunityPolicy: any;
}) {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [prior, isPrior] = useState(false);
  const policy = [
    { label: 'Auto', value: 'Auto' },
    { label: 'Homeowners(HO3)', value: 'Homeowners(HO3)' },
    { label: 'BOAT/PWC', value: 'BOAT/PWC' },
  ];
  const stages = [
    {
      label: 'Lead',
      value: 'Lead',
    },
    {
      label: 'Qualified',
      value: 'qualified',
    },
    {
      label: 'Quoting',
      value: 'quoting',
    },
    {
      label: 'Presentation',
      value: 'presentation',
    },
    {
      label: 'Binding',
      value: 'binding',
    },
    {
      label: 'Won',
      value: 'won',
    },
  ];

  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carriers, setCarriers] = useState<any>([]);

  const [addressObject, setAddressObject] = useState<any>({});
  const [isManual, setIsManual] = useState(false);

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (carrierSearchText) {
        fetchCarriers(
          'id',
          'asc',
          value,
          'True',
          (data: any) => {
            setCarriers(data);
            setLoadingStatus('succeeded');
          },
          true
        );
      } else {
        setCarriers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const setManual = (value: any) => {
    onSetItem('isManuallyAdded', String(value));
    setIsManual(value);
  };

  useEffect(() => {
    if (values.isManuallyAdded === 'true') {
      setIsManual(true);
      onSetItem('isManuallyAdded', 'true');
    } else {
      setIsManual(false);
      onSetItem('isManuallyAdded', 'false');
    }
    if (
      values.opportunityReasonForLapse ||
      values.effectiveDate ||
      values.expirationDate ||
      values.opportunityPremium ||
      values.opportunityPolicyTerm ||
      values.carrierUUID ||
      values?.customCarrierUUID
    ) {
      isPrior(true);
    }
  }, []);

  useEffect(() => {
    if (
      values.effectiveDate &&
      values.opportunityPolicyTerm === 'custom' &&
      values.policyTermMonth < 100
    ) {
      let dateEffective = new Date(values.effectiveDate);
      onSetItem(
        'expirationDate',
        new Date(
          dateEffective.setDate(
            dateEffective.getDate() + Number(values.policyTermMonth) * 30
          )
        )
      );
    } else if (
      values.opportunityPolicyTerm !== 'custom' &&
      values.opportunityPolicyTerm &&
      values.effectiveDate
    ) {
      const month = values.opportunityPolicyTerm.split(' ')[0];
      let dateEffective = new Date(values.effectiveDate);
      onSetItem(
        'expirationDate',
        new Date(
          dateEffective.setDate(dateEffective.getDate() + Number(month) * 30)
        )
      );
    }
  }, [
    values.opportunityPolicyTerm,
    values.effectiveDate,
    values.policyTermMonth,
  ]);

  const resetCarrier = (value: string) => {
    onSetItem('carrierUUID', value, 'customCarrierUUID');
    setCarriers([]);
  };

  const typeUUID = values?.carrierUUID
    ? values.carrierUUID
    : values?.customCarrierUUID;
  useEffect(() => {
    if (typeUUID) {
      fetchCarrier(
        typeUUID,
        (data: any) => {
          setCarrierSearchText(data?.name);
        },
        values.carrierUUID ? 'CARRIER' : 'CUSTOM_CARRIER'
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeUUID]);

  const policyTerm = [
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Custom', value: 'custom' },
  ];

  return (
    <>
      <Modal open={show} align='right'>
        <div className='opportunity'>
          <LeadStyledModal className='px-0'>
            <div className=' d-flex topHead OpportunityHead justify-content-between'>
              <h2>Add more details</h2>
              <button
                type='button'
                onClick={() => {
                  onCloseModal();
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
            </div>

            <div className='opportunityModal'>
              <div className='modelBody'>
                <h2 className='subText'>
                  {opportunityPolicy
                    ? opportunityPolicy?.toUpperCase()
                    : 'Policy'}
                </h2>
                {isManual && (
                  <>
                    <div className=' d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='address'
                          type='text'
                          placeholder='Address'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                      <div className='fields'>
                        <InputField
                          name='zipCode'
                          type='number'
                          placeholder='Zipcode'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                    </div>
                    <div className=' d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='city'
                          type='text'
                          placeholder='City'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                      <div className='fields'>
                        <InputField
                          name='state'
                          type='text'
                          placeholder='State'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                    </div>
                    <div className=' d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='country'
                          type='text'
                          placeholder='County'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                      <div className='fields'>
                        <InputField
                          name='opportunityBusinessApt'
                          type='text'
                          placeholder='Apt'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className='d-block d-sm-flex topmodOp justify-content-between'>
                  {!isManual && (
                    <div className='d-block d-sm-flex subsmallField  justify-content-between'>
                      <div className='fields-medium'>
                        <GoogleSearchInput
                          placeholder={
                            opportunityPolicy === 'homeOwner(HO3)'
                              ? 'Home address'
                              : opportunityPolicy === 'BOAT/PWC'
                              ? 'Boat'
                              : opportunityPolicy === 'auto'
                              ? 'Auto'
                              : 'Address'
                          }
                          name='opportunityHomeAddress'
                          setAddressObject={setAddressObject}
                          addressObject={addressObject}
                          inputType='COMMERCIAL'
                          initialValue={values.addressComponents?.address}
                          onSetItem={onSetItem}
                          className={`${
                            values?.addressComponents
                              ? Object.keys(values?.addressComponents).length >
                                  0 && 'filled'
                              : ''
                          }`}
                          setIsManual={setManual}
                        ></GoogleSearchInput>
                      </div>
                      <div className='fields-Xsmall'>
                        <InputField
                          name='opportunityBusinessApt'
                          type='text'
                          placeholder='Apt.'
                          onSetItem={onSetItem}
                          values={values}
                        />
                      </div>
                    </div>
                  )}

                  <div className='Datefields firstDateField'>
                    <DateField
                      name='closingDate'
                      placeholder='Expected closing date'
                      values={values}
                      onSetItem={onSetItem}
                    />
                  </div>
                </div>
              </div>

              <div className='prior d-flex'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  name='prier insurence'
                  onClick={() => isPrior(!prior)}
                  defaultChecked={
                    values.opportunityReasonForLapse ||
                    values.effectiveDate ||
                    values.expirationDate ||
                    values.opportunityPremium ||
                    values.opportunityPolicyTerm ||
                    values.carrierUUID ||
                    values?.customCarrierUUID
                  }
                />
                <label className='form-check-label'> Has prior insurance</label>
              </div>
              {prior ? (
                <div>
                  <div className='d-block d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <InputField
                        name='opportunityReasonForLapse'
                        type='text'
                        placeholder='Reason for lapse'
                        onSetItem={onSetItem}
                        values={values}
                      />
                    </div>
                    <div className='fields'>
                      <SearchDropdown
                        name='opportunityCarrierName'
                        placeholder='Carrier name'
                        isEdit={true}
                        values={carrierSearchText}
                        searchValue={carriers}
                        setSearch={setCarrierSearchText}
                        status={loadingStatus}
                        className={`${carrierSearchText && 'filled'}`}
                        loadFunction={loadCarrier}
                        setUUID={resetCarrier}
                        content={carriers?.map((item: any) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setCarrierSearchText(item?.name);
                              if (item.type === 'CARRIER') {
                                onSetItem(
                                  'carrierUUID',
                                  item?.uuid,
                                  'customCarrierUUID'
                                );
                              } else if (item.type === 'CUSTOM_CARRIER') {
                                onSetItem(
                                  'customCarrierUUID',
                                  item?.uuid,
                                  'carrierUUID'
                                );
                              }
                            }}
                          >
                            {item.logo ? (
                              <img
                                src={item.logo}
                                alt=''
                                className='carrierlogo-icon'
                              />
                            ) : (
                              <img
                                src={defaultCarrier}
                                alt=''
                                className='carrierlogo-icon'
                              />
                            )}
                            <div>
                              <h6>{item.name}</h6>
                            </div>
                          </div>
                        ))}
                      />
                    </div>
                  </div>
                  <div className='d-block d-sm-flex justify-content-between'>
                    <div className='Datefields'>
                      <DateField
                        name='effectiveDate'
                        placeholder='Effective date'
                        values={values}
                        onSetItem={onSetItem}
                      />
                    </div>
                    <div className='Datefields'>
                      <DateField
                        name='expirationDate'
                        readOnly
                        placeholder='Expiration date'
                        values={values}
                        onSetItem={onSetItem}
                      />
                    </div>
                  </div>
                  <div className='d-block d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <InputField
                        name='opportunityPremium'
                        type='number'
                        placeholder='Premium'
                        onSetItem={onSetItem}
                        values={values}
                        prefix='$'
                      />
                    </div>
                    <div className='fields'>
                      <SelectField
                        options={policyTerm}
                        name='opportunityPolicyTerm'
                        placeholder='Policy Term'
                        onSetItem={onSetItem}
                        values={values}
                      />
                    </div>
                  </div>
                  {values.opportunityPolicyTerm === 'custom' ? (
                    <div className='d-block d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='policyTermMonth'
                          type='number'
                          placeholder='Policy Term in Months'
                          onSetItem={onSetItem}
                          values={values}
                          month='months'
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className='buttonsDiv  OpButtonsDiv d-flex align-items-center'>
              <button
                onClick={() => onCloseModal()}
                className='cancel-button'
                type='button'
              >
                Cancel
              </button>
              <button
                type='button'
                className='agentButton '
                onClick={() => onCloseModal()}
              >
                Save <img className='tick' src={tick} alt='tick-sign' />
              </button>
            </div>
          </LeadStyledModal>
        </div>
      </Modal>
    </>
  );
}

export default AddOpportunityDrawer;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import InputField from '../InputField';
import { StyledQuoteModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import Modal from '../Modal';
import { AppDispatch } from '../../store';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchDropdown from '../SearchDropdown';
import DateField from '../DateField';
import { fetchAccounts } from '../../api/account';
import { IAccount } from '../../interfaces/Account';
import { fetchCarriers } from '../../api/carriers';
import { fetchOpportunities } from '../../api/opportunities';
import { fetchContacts } from '../../api/contact';
import {
  fetchQuoteStages,
  fetchQuoteTypes,
  fetchCoverageTypes,
} from '../../api/quote';
import warning from '../../assets/logo/warning.svg';
import { createQuote, QuoteSliceState } from '../../store/quotes/quoteSlice';
import {
  selectOpportunity,
  getOpportunityDetails,
  updateOpportunityListPremium,
} from '../../store/opportunities/opportunitiesSlice';
import DocumentsField from '../DocumentsField';
import document from '../../assets/logo/document.svg';
import vector from '../../assets/logo/dropdown.svg';
import bin from '../../assets/logo/bin.svg';
import { getDocumentType } from '../../api/documents';
import { IOpportunity } from '../../interfaces/Opportunity';
import { getCount as opportunityCount } from '../../store/opportunities/opportunitiesSlice';
import {
  clearOpportunities,
  getContactOpportunities,
  selectContact,
} from '../../store/contacts/contactsSlice';
import {
  clearAccountOpportunities,
  getAccountOpportunities,
  getAccountQuoteCount,
  selectAccount,
  updateAccountPremium,
} from '../../store/accounts/accountsSlice';
import { getOpportunityQuoteCount } from '../../store/opportunities/opportunitiesSlice';
import { getContactQuoteCount } from '../../store/contacts/contactsSlice';
import QuickAddOpportunityModal from '../QuickAddOpportunity';
import AddOpportunityDrawer from '../AddOpportunityDrawer';
import defaultCarrier from '../../assets/logo/defaultCarrier.svg';
import { IQuote } from '../../interfaces/Quote';

const QuoteSchema = Yup.object().shape({
  policyTerm: Yup.object().shape({
    value: Yup.string().required('Policy term is required'),
  }),
  quoteStage: Yup.object().shape({
    value: Yup.string().required('Quote stage is required'),
  }),
  quoteType: Yup.object().shape({
    value: Yup.string().required('Quote type is required'),
  }),
  coverageType: Yup.object().shape({
    value: Yup.string().required('Coverage type is required'),
  }),
  effectiveDate: Yup.string().required('Effective date is required'),
  premium: Yup.number()
    .typeError('Premium is required')
    .required('Premium is required')
    .min(1, 'Premium must be greater than 0'),
  policyTermMonth: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .max(2, 'Maximum 2 digit only'),
  contact: Yup.string().required('Associated contact is required'),
});

const AddQuoteModal = ({
  show,
  closeModal,
  type,
  page,
  opportunityUUID,
  opportunityInfo,
}: {
  show: string;
  closeModal: Function;
  type: string;
  page?: string;
  opportunityUUID?: string;
  opportunityInfo?: IOpportunity;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  let { uuid } = useParams();

  const location = useLocation();

  const [showSuccess, setshowSuccess] = useState(false);
  const [opportunitySearch, setOpportunitySearch] = useState('');
  const [opportunity, setOpportunity] = useState('');
  const [opportunities, setOpportunities] = useState<any[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const [accountSearch, setAccountSearch] = useState('');
  const [accounts, setAccounts] = useState<any[]>([]);
  const [account, setAccount] = useState('');

  const [error, setError] = useState('');
  const [isCommercial, setCommercial] = useState(false);

  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carriers, setCarriers] = useState<any>([]);
  const [carrier, setCarrier] = useState('');

  const [contacts, setContacts] = useState<any[]>([]);
  const [contactSearch, setContactSearch] = useState('');
  const [contact, setContact] = useState('');

  const [quoteStage, setQuoteStage] = useState<any[]>([]);
  const [quoteType, setQuoteType] = useState<any[]>([]);
  const [coverageType, setCoverageType] = useState<any[]>([]);
  const [submit, setSubmit] = useState(false);
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [DoctypeUUID, setDocTypeUUID] = useState('');
  const [docType, setDocType] = useState('');
  const [dropDown, setDropDown] = useState(false);
  const [file, setFile] = useState<any>();
  const [carrierType, setCarrierType] = useState('');
  const [carrierUUID, setCarrierUUID] = useState('');
  const [fileError, setFileError] = useState('');

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(QuoteSchema),
  });
  const policyTerm = [
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Custom', value: 'custom' },
  ];
  const watchPolicyTerm = watch('policyTerm');
  const watchAllFields = watch();

  const loadingQuote = useSelector(
    (state: { quotes: QuoteSliceState }) => state.quotes.isLoading
  );

  if (type === 'Opportunity' && !uuid) {
    uuid = opportunityUUID;
  }
  useEffect(() => {
    if (opportunityUUID) {
      dispatch(getOpportunityDetails({ uuid, successCB: (data: any) => {} }));
    }
    if (opportunityInfo) {
      setOpportunitySearch(opportunityInfo?.name);
      setOpportunity(opportunityInfo?.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityUUID, opportunityInfo]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const watchPolicyTermMonth = watch('policyTermMonth');

  const opportunityDetails = useSelector(selectOpportunity);

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (accountSearch) {
        fetchAccounts(
          'name',
          'asc',
          value,
          25,
          0,
          [],
          (data: IAccount[]) => {
            setAccounts(data);
            setLoadingStatus('succeeded');
            if (type === 'Opportunity') {
              setAccountSearch(accounts[0]?.name);
              setAccount(accounts[0]?.uuid);
            }
          },
          'True',
          page === 'quickQuote'
            ? undefined
            : type === 'Contact'
            ? uuid
            : undefined,
          type === 'Opportunity' ? uuid : undefined
        );
      } else {
        setAccounts([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  const onSubmit = (value: any) => {
    let check = true;
    if (watchPolicyTerm?.value === 'custom' && !watchPolicyTermMonth) {
      check = false;
    }
    value.typeUUID = value.quoteType.value;
    value.stageUUID = value.quoteStage.value;
    value.coverageTypeUUID = value.coverageType.value;
    value.accountUUID = type === 'Account' ? uuid : account;
    value.contactUUID =
      page === 'quickQuote' ? contact : type === 'Contact' ? uuid : contact;
    value.opportunityUUID = type === 'Opportunity' ? uuid : opportunity;
    if (carrierType === 'CARRIER') {
      value.carrierUUID = carrierUUID;
    } else if (carrierType === 'CUSTOM_CARRIER') {
      value.customCarrierUUID = carrierUUID;
    }
    if (watchPolicyTerm?.value === 'custom') {
      value.policyTerm = value.policyTermMonth + ' months';
    } else {
      value.policyTerm = value.policyTerm.value;
    }

    if (file && !DoctypeUUID) {
      setError('Please provide a document type');
      return;
    }
    let formData = new FormData();
    for (let [key, data] of Object.entries(value)) {
      if (
        key === 'quoteType' ||
        key === 'quoteStage' ||
        key === 'coverageType'
      ) {
        continue;
      }
      let val: any = data;
      formData.append(key, val);
    }
    formData.append('docFile', file);
    formData.append('documentTypeUUID', DoctypeUUID);
    if (check === true) {
      dispatch(
        createQuote({
          data: formData,
          successCB: (data: IQuote) => {
            if (type === 'Account') {
              dispatch(getAccountQuoteCount());
            } else if (type === 'Contact') {
              dispatch(getContactQuoteCount());
            } else if (type === 'Opportunity') {
              dispatch(getOpportunityQuoteCount());
              uuid && dispatch(opportunityCount({ uuid }));
            }

            if (location.pathname.includes('opportunities')) {
              dispatch(
                updateOpportunityListPremium({
                  premium: data.QuoteRequest.Opportunity.leastPremium,
                  uuid: value.opportunityUUID,
                  type: uuid ? 'detail' : 'list',
                })
              );
            }
            if (location.pathname.includes('account')) {
              dispatch(
                updateAccountPremium({
                  premium: data.accountPremium,
                  uuid: value.accountUUID,
                })
              );
            }
            closeModal('closing');
            if (type === 'Contact' && uuid) {
              dispatch(clearOpportunities());
              dispatch(
                getContactOpportunities({
                  uuid: uuid,
                  offset: 0,
                  getQuote: 'True',
                })
              );
            } else if (type === 'Account' && uuid) {
              dispatch(clearAccountOpportunities());
              dispatch(
                getAccountOpportunities({
                  uuid: uuid,
                  offset: 0,
                  getQuote: 'True',
                })
              );
            }
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 4000);
          },
          errorCB: (error: string) => {
            if (typeof error === 'string') setError(error);
          },
        })
      );
    }
  };

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (carrierSearchText) {
        fetchCarriers(
          'id',
          'asc',
          value,
          'True',
          (data: any) => {
            setCarriers(data);
            setLoadingStatus('succeeded');
          },
          undefined,
          0,
          0,
          opportunity
        );
      } else {
        setCarriers([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadContact = () => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (contactSearch) {
        fetchContacts(
          'id',
          'asc',
          contactSearch,
          25,
          0,
          'True',
          (data: any) => {
            setContacts(data);
            setLoadingStatus('succeeded');
          },
          isCommercial ? 'COMMERCIAL' : 'PERSONAL',
          page === 'quickQuote'
            ? account
            : type === 'Account'
            ? uuid
            : undefined,
          type === 'Opportunity' ? uuid : undefined
        );
      } else {
        setContacts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  const loadOpportunity = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (opportunitySearch) {
        fetchOpportunities(
          'name',
          'asc',
          value,

          '',
          '',
          (data: any) => {
            setOpportunities(data?.opportunities);
            setLoadingStatus('succeeded');
          },
          '',
          '',
          25,
          0,
          page === 'quickQuote' || type === 'Contact'
            ? account
            : type === 'Account'
            ? uuid
            : undefined
        );
      } else {
        setOpportunities([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    fetchQuoteStages((data: any[]) => {
      setQuoteStage(data);
    });
  }, []);

  useEffect(() => {
    fetchQuoteTypes((data: any[]) => {
      setQuoteType(data);
    });
  }, []);

  useEffect(() => {
    fetchCoverageTypes((data: any[]) => {
      setCoverageType(data);
    });
  }, []);

  useEffect(() => {
    if (type === 'Opportunity' && opportunityDetails) {
      setAccountSearch(opportunityDetails?.Account?.name);
      setAccount(opportunityDetails?.Account.uuid);
    }
  }, [opportunityDetails]);

  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
  }, [show]);

  useEffect(() => {
    if (page === 'quickQuote' && account === '') {
      setContact('');
      setOpportunity('');
      setContactSearch('');
      setOpportunitySearch('');
    }
  }, [account]);

  const [opportunityForm, setOpportunityForm] = useState('closed');

  const accountDetails = useSelector(selectAccount);
  const contactDetails = useSelector(selectContact);

  const [longOpportunityForm, setLongOpportunityForm] = useState('closed');

  const resetAccount = (value: string) => {
    setAccounts([]);
    setAccount('');
  };

  const resetContact = (value: string) => {
    setContacts([]);
    setContact('');
  };

  const resetOpportunity = (value: string) => {
    setOpportunities([]);
    setOpportunity('');
  };

  const resetCarrier = (value: string) => {
    setCarriers([]);
    setCarrier('');
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success'}
            message={`<b>New quote has been added </b>.`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledQuoteModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2>Add quote</h2>
          </div>
          <div className='row'></div>
          <div>
            <div>
              {show && !showSuccess && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='scroll-bar'>
                    <div className='field-section'>
                      {type === 'Contact' || type === 'Opportunity' ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='account'
                            placeholder=' Associated account'
                            isEdit={true}
                            values={accountSearch}
                            searchValue={accounts}
                            setSearch={setAccountSearch}
                            status={loadingStatus}
                            disabled={type === 'Opportunity'}
                            className={`${
                              !account && submit && 'error-field'
                            } ${accountSearch && 'filled'}`}
                            register={register}
                            control={control}
                            required
                            setUUID={resetAccount}
                            loadFunction={loadAccount}
                            content={accounts?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setAccountSearch(item?.name);
                                  setAccount(item?.uuid);
                                }}
                              >
                                <div className='profile-pic d-flex align-items-center justify-content-center'>
                                  {item?.name?.charAt(0)}
                                </div>
                                <div>
                                  <h6>{item.name}</h6>
                                  <ul className='user-details'>
                                    <li>
                                      {isCommercial
                                        ? item?.businessEmail
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'EMAIL'
                                          )?.value}
                                    </li>
                                    <li>
                                      {isCommercial
                                        ? item?.businessPhoneNumber
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'PHONENUMBER'
                                          )?.value}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          />

                          {!account && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Account is required
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      {type === 'Account' ||
                      type === 'Opportunity' ||
                      (type === 'Contact' && page === 'quickQuote') ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='contact'
                            placeholder='Associated contact'
                            isEdit={true}
                            values={contactSearch}
                            searchValue={contacts}
                            setSearch={setContactSearch}
                            status={loadingStatus}
                            required
                            className={`${
                              !contact && submit && 'error-field'
                            } ${contactSearch && 'filled'}`}
                            register={register}
                            control={control}
                            type='text'
                            setUUID={resetContact}
                            loadFunction={loadContact}
                            content={
                              <>
                                {contacts?.map((item) => (
                                  <div
                                    className='searchValuesCard d-flex align-items-center'
                                    onClick={() => {
                                      setContactSearch(
                                        item?.firstName + item?.lastName
                                      );
                                      setContact(item?.uuid);
                                    }}
                                  >
                                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                                      {item?.firstName?.charAt(0)}
                                    </div>
                                    <div>
                                      <h6>
                                        {' '}
                                        {item?.firstName} {item?.lastName}
                                      </h6>
                                      <ul className='user-details'>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'EMAIL'
                                            )?.value
                                          }
                                        </li>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'PHONENUMBER'
                                            )?.value
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </>
                            }
                          />

                          {!contact && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Contact is required
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      {type === 'Contact' || type === 'Account' ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='opportunity'
                            placeholder='Associated opportunity'
                            isEdit={true}
                            disabled={
                              opportunityInfo
                                ? true
                                : false || (type === 'Contact' && !account)
                                ? true
                                : false
                            }
                            values={opportunitySearch}
                            required
                            searchValue={
                              opportunities?.length > 0 ? opportunities : ['']
                            }
                            setSearch={setOpportunitySearch}
                            status={loadingStatus}
                            className={`${
                              !opportunity && submit && 'error-field'
                            } ${opportunitySearch && 'filled'}`}
                            register={register}
                            control={control}
                            loadFunction={loadOpportunity}
                            setUUID={resetOpportunity}
                            content={
                              <>
                                {opportunities?.map((item: any) => (
                                  <div
                                    className='searchValuesCard d-flex align-items-center'
                                    onClick={() => {
                                      setOpportunitySearch(item?.name);
                                      setOpportunity(item?.uuid);
                                    }}
                                  >
                                    <div>
                                      <h6>{item?.name}</h6>
                                    </div>
                                  </div>
                                ))}
                                <div
                                  className='searchValuesCard d-flex align-items-center'
                                  onClick={() => {
                                    setOpportunityForm('open');
                                  }}
                                >
                                  <div className='profile-pic d-flex align-items-center justify-content-center'>
                                    +
                                  </div>
                                  <div>
                                    <h6> Add Opportunity</h6>
                                  </div>
                                </div>
                              </>
                            }
                          />

                          {!opportunity && submit && (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Opportunity is required
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className='agency-fields'>
                        <SearchDropdown
                          name='carrierName'
                          placeholder='Carrier'
                          isEdit={true}
                          values={carrierSearchText}
                          searchValue={carriers}
                          setSearch={setCarrierSearchText}
                          required
                          status={loadingStatus}
                          className={`${!carrier && submit && 'error-field'} ${
                            carrierSearchText && 'filled'
                          }`}
                          loadFunction={loadCarrier}
                          setUUID={resetCarrier}
                          setProductUUID={setCarrierUUID}
                          content={carriers?.map((item: any) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setCarrierSearchText(item?.name);
                                setCarrier(item?.uuid);
                                setCarrierType(item.type);
                                setCarrierUUID(item.uuid);
                              }}
                            >
                              {item.logo ? (
                                <img
                                  src={item.logo}
                                  alt=''
                                  className='carrierlogo-icon'
                                />
                              ) : (
                                <img
                                  src={defaultCarrier}
                                  alt=''
                                  className='carrierlogo-icon'
                                />
                              )}
                              <div>
                                <h6>{item.name}</h6>
                              </div>
                            </div>
                          ))}
                        />
                        {!carrier && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Carrier is required
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='policyTerm'
                          options={policyTerm}
                          placeholder='Policy term'
                          control={control}
                          isEdit={true}
                          required
                        />
                        {errors.policyTerm ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.policyTerm?.message
                              ? 'Policy term required'
                              : errors.policyTerm?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      {watchPolicyTerm?.value === 'custom' ? (
                        <div className='agency-fields'>
                          <InputField
                            name='policyTermMonth'
                            type='number'
                            placeholder='Policy Term in Months'
                            month='months'
                            register={register}
                            required
                            className={`${
                              errors.policyTermMonth && 'error-field'
                            } ${watchAllFields.policyTermMonth && 'filled'}`}
                          />
                          {watchPolicyTerm?.value === 'custom' &&
                          !watchPolicyTermMonth &&
                          submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Policy term in months is required
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {errors.policyTermMonth?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.policyTermMonth?.message}
                        </div>
                      )}
                      <div className='agency-fields'>
                        <SelectField
                          name='coverageType'
                          options={coverageType.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Coverage type'
                          control={control}
                          required
                          isEdit={true}
                        />
                        {errors.coverageType ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.coverageType?.message
                              ? 'Quote stage is required'
                              : errors.coverageType?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='quoteStage'
                          options={quoteStage.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Quote stage'
                          required
                          control={control}
                          isEdit={true}
                        />
                        {errors.quoteStage ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.quoteStage?.message
                              ? 'Quote stage is required'
                              : errors.quoteStage?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='quoteType'
                          options={quoteType.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Quote type'
                          control={control}
                          required
                          isEdit={true}
                        />
                        {errors.quoteType ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.quoteType?.message
                              ? 'Quote type is required'
                              : errors.quoteType?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <DateField
                          name='effectiveDate'
                          placeholder='Effective date'
                          control={control}
                          required
                          value={watchAllFields.effectiveDate}
                        />
                        {errors.effectiveDate?.message && (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.effectiveDate?.message}
                          </div>
                        )}
                      </div>
                      <div className='agency-fields'>
                        <DateField
                          name='quotePresentedDate'
                          placeholder='Quote presented date'
                          control={control}
                          value={watchAllFields.quotePresentedDate}
                        />
                      </div>

                      <div className='agency-fields'>
                        <InputField
                          name='premium'
                          type='number'
                          placeholder='Premium'
                          register={register}
                          prefix='$'
                          required
                          className={`${errors.premium && 'error-field'} ${
                            watchAllFields.premium && 'filled'
                          }`}
                        />
                        {errors.premium?.message && (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.premium?.message}
                          </div>
                        )}
                      </div>
                      <div className='bottom-head'>Add document (optional)</div>
                      <div>
                        <DocumentsField
                          file={file}
                          setFileError={setFileError}
                          setFile={setFile}
                          colorless={true}
                          // disabled={file?.uuid && DoctypeUUID}
                          customStyle={true}
                        />
                        {file && (
                          <div className='documentDetails'>
                            <img src={document} alt='' />
                            <span className='docName'>{file?.name}</span>
                            <div className='itemsDiv d-flex justify-content-between align-items-center'>
                              <button
                                className='documentDropDown d-flex justify-content-between align-items-center'
                                onClick={() => setDropDown(!dropDown)}
                                type='button'
                              >
                                {docType ? docType : 'Document type'}
                                <img src={vector} alt='' />
                                {dropDown && (
                                  <div className='documentTypeDrop'>
                                    {' '}
                                    {DocumentTypes &&
                                      DocumentTypes?.map((item: any) => (
                                        <div
                                          className='listType d-flex align-items-center '
                                          onClick={() => {
                                            setDropDown(false);
                                            setDocType(item?.name);
                                            setDocTypeUUID(item.uuid);
                                          }}
                                        >
                                          <ul>{item?.name}</ul>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </button>
                              <img
                                className='binImage'
                                src={bin}
                                alt=''
                                onClick={() => {
                                  setFile('');
                                  setDocTypeUUID('');
                                  setDocType('');
                                  // setReapload(true);
                                }}
                              />
                            </div>
                            {!DoctypeUUID && file && error ? (
                              <div className='errorVal'>
                                <img
                                  src={warning}
                                  alt='warning'
                                  className='warning'
                                />
                                {error}
                              </div>
                            ) : null}
                          </div>
                        )}
                        {fileError ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {fileError}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className='buttonsDiv d-flex align-items-center '>
                    <button
                      onClick={() => {
                        reset();
                        onCloseModal();
                      }}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton'
                      onClick={() => {
                        setSubmit(true);
                      }}
                      disabled={loadingQuote}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledQuoteModal>
      </Modal>
      {opportunityForm !== 'closed' && (
        <QuickAddOpportunityModal
          show={opportunityForm}
          closeModal={setOpportunityForm}
          isInnerTab={true}
          name={type}
          accountType={accountDetails?.AccountType?.name}
          assosiated={accountDetails}
          contactDetails={contactDetails}
          setLongOpportunityForm={setLongOpportunityForm}
        />
      )}
      {longOpportunityForm !== 'closed' && (
        <AddOpportunityDrawer
          show={longOpportunityForm}
          closeModal={setLongOpportunityForm}
        />
      )}
    </>
  );
};

export default AddQuoteModal;

import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { fetchAccounts } from '../../../../api/account';
import { fetchAgents } from '../../../../api/agents';
import { IProducer } from '../../../../interfaces/Agent';
import SearchDropdown from '../../../../components/SearchDropdown';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import { getOpportunityStatus } from '../../../../api/opportunities';
import {
  IOpportunityStatus,
  IPipeline,
} from '../../../../interfaces/Opportunity';
import { fetchProductCategory } from '../../../../api/productCategory';
import { IProductCategory } from '../../../../interfaces/ProductCategory';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import {
  getPipelines,
  selectAllPipelines,
} from '../../../../store/pipelines/pipelineSlice';
import { useSelector } from 'react-redux';
import { IAccount } from '../../../../interfaces/Account';
import Skeleton from 'react-loading-skeleton';
import { fetchProducts } from '../../../../api/product';
import { IProduct } from '../../../../interfaces/Product';

const OpportunityFilter = ({
  show,
  closeModal,
  selectedOppStatus,
  setProducts,
  setOppStatus,
  selectedProducts,
  producer,
  setProducer,
  producerSearch,
  setProducerSearch,
  setPipeline,
  pipeline,
  account,
  setAccount,
  accountSearch,
  setAccountSearch,
  isAccountInnertab,
}: {
  show: string;
  closeModal: Function;
  selectedOppStatus: any;
  selectedProducts: any;
  setProducts: Function;
  setOppStatus: Function;
  producer: string;
  setProducer: Function;
  producerSearch: string;
  setProducerSearch: Function;
  setPipeline: Function;
  pipeline: any;
  account: string;
  setAccount: Function;
  accountSearch: string;
  setAccountSearch: Function;
  isAccountInnertab?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [product, setProduct] = useState<IProduct[]>([]);

  const [status, setStatus] = useState<IOpportunityStatus[]>([]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  useEffect(() => {
    getOpportunityStatus((data: IOpportunityStatus[]) => setStatus(data));
    dispatch(
      getPipelines({
        sortBy: '',
        orderBy: '',
        searchBy: '',
        successCB: (data: IPipeline) => {},
        agencyUUID: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingStatus('loading');
    fetchProducts(
      (data: IProduct[]) => {
        setProduct(data);
        setLoadingStatus('succeeded');
      },
      '',
      '',
      '',
      '',
      true
    );
  }, []);
  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const user = LocalStorageService.getUser();

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      // setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IProducer[]) => {
            setProducers(data);
            // setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True'
        );
      } else {
        setProducers([]);
      }
    }
  };

  const [accounts, setAccounts] = useState<any[]>([]);

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (accountSearch) {
        fetchAccounts(
          'name',
          'asc',
          value,
          25,
          0,
          '',
          (data: IAccount[]) => {
            setAccounts(data);
          },
          'True'
        );
      } else {
        setAccounts([]);
      }
    }
  };

  const pipelines = useSelector(selectAllPipelines);

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };

  const resetAccount = (value: string) => {
    setAccounts([]);
    setAccount('');
  };

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Stages</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={status.map((item) => {
                return { label: item.name, value: item.uuid };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setOppStatus(value);
              }}
              isMulti={true}
              value={selectedOppStatus}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Policy</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={product.map((item) => {
                return { label: item.name, value: item.uuid, type: item.type };
              })}
              name='policy'
              placeholder='Select'
              isMulti={true}
              onChange={(value: any) => {
                setProducts(value);
              }}
              value={selectedProducts}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Pipeline</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={pipelines.map((item: any) => {
                return {
                  label: item.name,
                  value: item.uuid,
                };
              })}
              placeholder='Select'
              onChange={(value: any) => {
                setPipeline(value);
              }}
              isMulti={true}
              value={pipeline}
            />
          </div>
        )}
        {!isAccountInnertab ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Associated account</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='account'
                  placeholder='Select'
                  isEdit={true}
                  values={accountSearch}
                  searchValue={accounts}
                  setSearch={setAccountSearch}
                  status={loadingStatus}
                  className={`${accountSearch && 'filled'}`}
                  loadFunction={loadAccount}
                  setUUID={resetAccount}
                  content={accounts?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setAccountSearch(item?.name);
                        setAccount(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.name?.charAt(0)}
                      </div>
                      <div>
                        <h6>{item.name}</h6>
                        <ul className='user-details'>
                          <li>
                            {item?.businessEmail ||
                              item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value}
                          </li>
                          <li>
                            {item?.businessPhoneNumber ||
                              item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                (value: any) => value.type === 'PHONENUMBER'
                              )?.value}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}

        {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Producer</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='producer'
                  placeholder='Select'
                  isEdit={true}
                  addName={true}
                  values={producerSearch}
                  searchValue={producers}
                  setSearch={setProducerSearch}
                  status={loadingStatus}
                  className={`${producerSearch && 'filled'}`}
                  loadFunction={loadProducer}
                  setUUID={resetProducer}
                  content={producers?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setProducerSearch(
                          `${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`
                        );
                        setProducer(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.User?.firstName?.charAt(0)}
                        {item?.User?.lastName?.charAt(0)}
                      </div>
                      <div>
                        <h6>
                          {`${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`}
                        </h6>
                        <ul className='user-details'>
                          <li>{item.User?.email}</li>
                          <li>{item?.User?.phoneNumber}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(producer ? 1 : 0) +
                          pipeline.length +
                          selectedProducts.length +
                          selectedOppStatus.length +
                          (account ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setProducer('');
                          setProducerSearch('');
                          setPipeline([]);
                          setProducts([]);
                          setOppStatus([]);
                          setAccountSearch('');
                          setAccount('');
                        }}
                        disabled={
                          (producer ? 1 : 0) +
                            pipeline.length +
                            selectedProducts.length +
                            selectedOppStatus.length +
                            (account ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(producer ? 1 : 0) +
                      pipeline.length +
                      selectedProducts.length +
                      selectedOppStatus.length +
                      (account ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setProducer('');
                      setProducerSearch('');
                      setPipeline([]);
                      setProducts([]);
                      setOppStatus([]);
                      setAccountSearch('');
                      setAccount('');
                    }}
                    disabled={
                      (producer ? 1 : 0) +
                        pipeline.length +
                        selectedProducts.length +
                        selectedOppStatus.length +
                        (account ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default OpportunityFilter;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAccountDetails,
  updateAccountDetail,
  fetchAccounts,
  addAccount,
  fetchAccountOpportunity,
  fetchAccountPolicies,
  AccountDetailsCount,
  UpdateAccountContacts,
  deleteAccount,
} from '../../api/account';
import { IAccount } from '../../interfaces/Account';
import { IContact } from '../../interfaces/Contact';
import { IOpportunity } from '../../interfaces/Opportunity';
import { IPolicy } from '../../interfaces/Policy';

export type AccountsSliceState = {
  status: string;
  oppportunityStatus: string;
  policiesStatus: string;
  accounts: IAccount[];
  accountDetails?: IAccount;
  accountOpportunities: IOpportunity[];
  accountPolicies: IPolicy[];
  count: number;
  opportunitiesCount: number;
  quotesCount: number;
  activitiesCount: number;
  documentsCount: number;
  policiesCount: number;
  isLoading: boolean;
  historiesCount: number;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  type?: string[];
  successCB?: Function;
  minimal?: string;
  contactUUID?: string;
  opportunityUUID?: string;
  accountStatusUUID: string[];
  hasPolicy?: string;
  producerUUID?: string;
}

const initialState: AccountsSliceState = {
  status: 'idle',
  oppportunityStatus: 'idle',
  policiesStatus: 'idle',
  accounts: [],
  accountOpportunities: [],
  accountPolicies: [],
  count: 0,
  opportunitiesCount: 0,
  quotesCount: 0,
  activitiesCount: 0,
  documentsCount: 0,
  policiesCount: 0,
  isLoading: false,
  historiesCount: 0,
};

export const getAccounts = createAsyncThunk(
  'accounts/getAccounts',
  async ({
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    type,
    successCB,
    minimal,
    contactUUID,
    opportunityUUID,
    accountStatusUUID,
    hasPolicy,
    producerUUID,
  }: IProps) => {
    const response = await fetchAccounts(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      type,
      successCB,
      minimal,
      contactUUID,
      opportunityUUID,
      accountStatusUUID,
      hasPolicy,
      producerUUID
    );
    if (successCB) successCB(response.data);
    return response.data;
  }
);

export const getAccountDetails = createAsyncThunk(
  'accounts/getAccountDetails',
  async ({ uuid, successCB }: { uuid?: string; successCB: Function }) => {
    const response = await fetchAccountDetails(uuid);
    successCB(response.data);
    return response.data;
  }
);

export const onUpdateAccountContact = createAsyncThunk(
  'accounts/onUpdateAccountContact',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: IContact[];
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await UpdateAccountContacts(
      data,
      uuid,
      successCB,
      errorCB
    );
    return response.data;
  }
);

export const getAccountOpportunities = createAsyncThunk(
  'contacts/getAccountOpportunities',
  async ({
    uuid,
    offset,
    getQuote,
    pipelineUUID,
    opportunityStatusUUID,
    productCategoryUUID,
    producerUUID,
    productUUID,
    customProductUUID,
    quoteTypeUUID,
    quoteStageUUID,
    quoteStatusUUID,
    coverageTypeUUID,
  }: {
    uuid: string;
    offset: number;
    getQuote?: string;
    pipelineUUID?: string[];
    opportunityStatusUUID?: string[];
    productCategoryUUID?: string[];
    producerUUID?: string;
    productUUID?: string[];
    customProductUUID?: string[];
    quoteTypeUUID?: string;
    quoteStageUUID?: string[];
    quoteStatusUUID?: string;
    coverageTypeUUID?: string[];
  }) => {
    const response = await fetchAccountOpportunity(
      uuid,
      offset,
      getQuote,
      pipelineUUID,
      opportunityStatusUUID,
      productCategoryUUID,
      producerUUID,
      productUUID,
      customProductUUID,
      quoteTypeUUID,
      quoteStageUUID,
      quoteStatusUUID,
      coverageTypeUUID
    );
    return response.data;
  }
);
export const getAccountPolicies = createAsyncThunk(
  'contacts/getAccountPolicies',
  async ({
    uuid,
    offset,
    producerUUID,
    productCategoryUUID,
    hasMGA,
    carrierUUID,
    customCarrierUUID,
    productUUID,
    customProductUUID,
  }: {
    uuid: string;
    offset: number;
    producerUUID?: string;
    productCategoryUUID?: string[];
    hasMGA?: boolean;
    carrierUUID?: string;
    customCarrierUUID?: string;
    productUUID?: string[];
    customProductUUID?: string[];
  }) => {
    const response = await fetchAccountPolicies(
      uuid,
      offset,
      producerUUID,
      productCategoryUUID,
      hasMGA,
      carrierUUID,
      customCarrierUUID,
      productUUID,
      customProductUUID
    );
    return response.data;
  }
);

export const createAccount = createAsyncThunk(
  'accounts/createAccount',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: IContact;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addAccount(data, successCB, errorCB);
    return response.data;
  }
);

export const updateAccountDetails = createAsyncThunk(
  'accounts/updateAccountDetails',
  async ({
    data,
    uuid,
    successCB,
    errorCB,
  }: {
    data: IAccount;
    uuid: string;
    successCB: Function;
    errorCB?: Function;
  }) => {
    const response = await updateAccountDetail(data, uuid, errorCB);
    successCB(response.data);
    return response.data;
  }
);
export const getCount = createAsyncThunk(
  'accounts/getCount',
  async ({ uuid }: { uuid: string }) => {
    const response = await AccountDetailsCount(uuid);
    return response.data;
  }
);

export const onDeleteAccount = createAsyncThunk(
  'accounts/onDeleteAccount',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteAccount(uuid);
    successCB();
    return response.data;
  }
);

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearAccountOpportunities: (state) => {
      state.accountOpportunities = [];
    },
    clearAccountPolicies: (state) => {
      state.accountPolicies = [];
    },
    getAccountQuoteCount: (state) => {
      state.quotesCount = state.quotesCount + 1;
    },
    addAccountDetails: (state, action) => {
      if (state.accountDetails) {
        state.accountDetails.AccountContacts = [
          ...state.accountDetails.AccountContacts,
          action.payload.response.accountContact,
        ];
      }
    },
    updateAccountDetailOpportunity: (state, action) => {
      if (action.payload.type === 'add') {
        if (state.accountDetails?.Opportunities)
          state.accountDetails.Opportunities.push(action.payload.opportunity);
      } else if (action.payload.type === 'update') {
        if (state.accountDetails?.Opportunities) {
          const index = state.accountDetails?.Opportunities.findIndex(
            (item) => item.uuid === action.payload.opportunity.uuid
          );
          state.accountOpportunities[index] = action.payload.opportunity;
        }
      } else {
        if (state.accountDetails?.Opportunities) {
          const index = state.accountDetails?.Opportunities.findIndex(
            (item) => item.uuid === action.payload.uuid
          );
          state.accountDetails?.Opportunities.splice(index, 1);
        }
      }
    },
    deleteAccountPoliciesList: (state, action) => {
      const index = state.accountPolicies.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      state.accountPolicies.splice(index, 1);
    },
    updateAccountPoliciesList: (state, action) => {
      const index = state.accountPolicies.findIndex(
        (item) => item.uuid === action.payload.data.uuid
      );
      state.accountPolicies[index] = action.payload.data;
    },
    updateAccountPremium: (state, action) => {
      if (state.accountDetails)
        state.accountDetails.premium = action.payload.premium;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccounts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accounts = action.payload.accounts;
        state.count = action.payload.count;
      })
      .addCase(getAccounts.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getAccountDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAccountDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAccountDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accountDetails = action.payload;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accounts.unshift(action.payload);
      })
      .addCase(createAccount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.accounts.findIndex(
          (item: IAccount) => item.uuid === action.payload.uuid
        );
        state.accounts[index] = action.payload;
        state.accountDetails = action.payload;
      })
      .addCase(updateAccountDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAccountDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAccountOpportunities.pending, (state, action) => {
        state.oppportunityStatus = 'loading';
      })
      .addCase(getAccountOpportunities.fulfilled, (state, action) => {
        state.oppportunityStatus = 'succeeded';
        state.accountOpportunities = [
          ...state.accountOpportunities,
          ...action.payload.opportunities,
        ];
      })
      .addCase(getAccountOpportunities.rejected, (state, action) => {
        state.oppportunityStatus = 'failed';
      })
      .addCase(getAccountPolicies.pending, (state, action) => {
        state.policiesStatus = 'loading';
      })
      .addCase(getAccountPolicies.fulfilled, (state, action) => {
        state.policiesStatus = 'succeeded';
        state.accountPolicies = [
          ...state.accountPolicies,
          ...action.payload.policies,
        ];
      })
      .addCase(getAccountPolicies.rejected, (state, action) => {
        state.policiesStatus = 'failed';
      })
      .addCase(getCount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activitiesCount = action.payload.activities;
        state.documentsCount = action.payload.documents;
        state.quotesCount = action.payload.quotes;
        state.opportunitiesCount = action.payload.opportunities;
        state.policiesCount = action.payload.policies;
        state.historiesCount = action.payload.histories;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onUpdateAccountContact.fulfilled, (state, action) => {
        if (state.accountDetails) {
          state.accountDetails.AccountContacts = [
            ...state.accountDetails.AccountContacts,
            action.payload.accountContact,
          ];
        }
        state.isLoading = false;
      })
      .addCase(onUpdateAccountContact.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateAccountContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onDeleteAccount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.accounts.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.accounts.splice(index, 1);
      })
      .addCase(onDeleteAccount.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const {
  updateStatus,
  clearAccountOpportunities,
  clearAccountPolicies,
  getAccountQuoteCount,
  addAccountDetails,
  deleteAccountPoliciesList,
  updateAccountPoliciesList,
  updateAccountDetailOpportunity,
  updateAccountPremium,
} = accountsSlice.actions;

export default accountsSlice.reducer;

export const selectAllAccounts = (state: { accounts: AccountsSliceState }) =>
  state.accounts.accounts;

export const selectAccount = (state: { accounts: AccountsSliceState }) =>
  state.accounts.accountDetails;
export const selectAccountOpportunities = (state: {
  accounts: AccountsSliceState;
}) => state.accounts.accountOpportunities;

export const selectAccountPolicies = (state: {
  accounts: AccountsSliceState;
}) => state.accounts.accountPolicies;

export const selectCount = (state: { accounts: AccountsSliceState }) => {
  const objectCount: { [index: string]: number } = {
    documents: state.accounts.documentsCount,
    activities: state.accounts.activitiesCount,
    quotes: state.accounts.quotesCount,
    policies: state.accounts.policiesCount,
    opportunities: state.accounts.opportunitiesCount,
    histories: state.accounts.historiesCount,
  };
  return objectCount;
};

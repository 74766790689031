import Modal from '../Modal';
import cross from '../../assets/logo/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { StyledModal } from '../../pages/Admin/elements/styles';
import SelectField from '../SelectField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import tick from '../../assets/logo/tick.svg';
import { AppDispatch } from '../../store';
import { onUpdateOpportunityContact } from '../../store/opportunities/opportunitiesSlice';
import { onUpdatePolicyContact } from '../../store/policies/policiesSlice';
import { useEffect, useState } from 'react';
import warning from '../../assets/logo/warning.svg';
import {
  getContacts,
  selectAllContacts,
} from '../../store/contacts/contactsSlice';
import { onUpdateAccountContact } from '../../store/accounts/accountsSlice';
import { SuccessAlign } from '../../styles/SuccessAlign';
import SuccessCard from '../SuccessCard';
import SearchDropdown from '../SearchDropdown';
import { fetchContacts } from '../../api/contact';

const AgentSchema = Yup.object().shape({});
const OpportunityContactModal = ({
  name,
  uuid,
  accountUUID,
  show,
  closeModal,
  opportunityAccountContact,
}: {
  show: string;
  name: string;
  uuid: string;
  accountUUID: string;
  closeModal: Function;
  opportunityAccountContact?: any;
}) => {
  const contacts = useSelector(selectAllContacts);
  const dispatch = useDispatch<AppDispatch>();
  const [success, setSuccess] = useState(false);
  const [contactSearch, setContactSearch] = useState('');
  const [contact, setContact] = useState('');
  const [isContacts, setContacts] = useState<any[]>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [errorMessage, setErrorMessege] = useState('');
  const [contactsLists, setContactsList] = useState<any>();
  const [isError, setShowError] = useState('');
  const { handleSubmit, control, reset, register } = useForm({
    resolver: yupResolver(AgentSchema),
  });
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loadContact = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (contactSearch) {
        fetchContacts(
          'id',
          'asc',
          contactSearch,
          25,
          0,
          'True',
          (data: any) => {
            setContacts(data);
            setLoadingStatus('succeeded');
          }
        );
      } else {
        setContacts([]);
        setLoadingStatus('succeeded');
      }
    }
  };

  useEffect(() => {
    dispatch(
      getContacts({
        sortBy: '',
        orderBy: '',
        searchBy: '',
        limit: 100,
        offset: 0,
        accountUUID,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (name !== 'account') {
      setContactsList(
        contacts.filter(
          (item) => !opportunityAccountContact.includes(item.uuid)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);
  const onSubmit = (data: any) => {
    if (name === 'opportunity' || name === 'policy') {
      if (!data.contactUUIDs) {
        return setErrorMessege('Please select a contact');
      }
      data.accountUUID = accountUUID;
      data.contactUUIDs = data.contactUUIDs
        ? data.contactUUIDs.map(
            (item: { label: string; value: string }) => item.value
          )
        : [];
      if (name === 'opportunity') {
        setErrorMessege('');
        dispatch(
          onUpdateOpportunityContact({
            uuid,
            data,
            successCB: (data: any) => {
              closeModal('closing');
              setTimeout(() => {
                setSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 4000);
              reset();
            },
            errorCB: () => {},
          })
        );
      } else if (name === 'policy') {
        setErrorMessege('');
        dispatch(
          onUpdatePolicyContact({
            uuid,
            data,
            successCB: (data: any) => {
              closeModal('closing');
              setTimeout(() => {
                setSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 4000);
              reset();
            },
          })
        );
      }
    } else if (name === 'account') {
      if (!contact) {
        return setErrorMessege('Please select a contact');
      }
      data.contactUUID = contact;
      setErrorMessege('');
      dispatch(
        onUpdateAccountContact({
          uuid,
          data,
          successCB: (data: any) => {
            closeModal('closing');
            setTimeout(() => {
              setSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 4000);
            reset();
          },
          errorCB: (data: any) => {
            setShowError(data);
          },
        })
      );
    }
  };
  useEffect(() => {
    if (!contact) {
      setShowError('');
    }
    if (contact) {
      setErrorMessege('');
    }
  }, [contact]);

  const resetContact = (value: string) => {
    setContacts([]);
    setContact('');
  };

  return (
    <div>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'Successfully '}
            message={`<b>Contacts</b> has  Added`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={show} align='right'>
        <StyledModal>
          <button
            type='button'
            className='crossbutton'
            onClick={() => onCloseModal()}
          >
            <img src={cross} className='cross' alt='' />
          </button>

          <div className='HeadContact'>
            <h2>Add Contacts </h2>
          </div>

          <div className='fields-section'>
            <form onSubmit={handleSubmit(onSubmit)}>
              {(name === 'opportunity' || name === 'policy') && (
                <div className='agency-fields'>
                  <SelectField
                    name='contactUUIDs'
                    control={control}
                    placeholder='Select contacts'
                    options={contactsLists?.map((item: any) => {
                      return {
                        value: item.uuid,
                        label: `${item.firstName} ${item.lastName}`,
                      };
                    })}
                    isMulti={true}
                  />
                  {errorMessage ? (
                    <div className='error-div'>
                      <img src={warning} alt='warning' className='warning' />
                      {errorMessage}
                    </div>
                  ) : null}
                </div>
              )}
              {name === 'account' && (
                <div className='agency-fields'>
                  <SearchDropdown
                    name='contact'
                    placeholder='Select Contact'
                    values={contactSearch}
                    searchValue={isContacts}
                    setSearch={setContactSearch}
                    status={loadingStatus}
                    className={`${!contact && errorMessage && 'error-field'} ${
                      contactSearch && 'filled'
                    }`}
                    register={register}
                    setUUID={resetContact}
                    control={control}
                    type='text'
                    loadFunction={loadContact}
                    content={
                      <>
                        {isContacts?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setContactSearch(
                                item?.firstName + ' ' + item?.lastName
                              );
                              setContact(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.firstName?.charAt(0)}
                            </div>
                            <div>
                              <h6>
                                {' '}
                                {item?.firstName} {item?.lastName}
                              </h6>
                              <ul className='user-details'>
                                <li>
                                  {
                                    item?.ContactDetails?.find(
                                      (value: any) => value.type === 'EMAIL'
                                    )?.value
                                  }
                                </li>
                                <li>
                                  {
                                    item?.ContactDetails?.find(
                                      (value: any) =>
                                        value.type === 'PHONENUMBER'
                                    )?.value
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </>
                    }
                  />

                  {errorMessage || isError ? (
                    <div className='errorVal'>
                      <img src={warning} alt='warning' className='warning' />
                      {errorMessage ? errorMessage : isError ? isError : ''}
                    </div>
                  ) : null}
                </div>
              )}
              <div className='buttonsDiv d-flex align-items-center'>
                <button
                  onClick={() => onCloseModal()}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button type='submit' className='agentButton '>
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </StyledModal>
      </Modal>
    </div>
  );
};

export default OpportunityContactModal;

import Moment from 'react-moment';
import { CardMobileDiv } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import call from '../../../../assets/logo/callBlue.svg';
import message from '../../../../assets/logo/msgBlue.svg';
import calldisable from '../../../../assets/logo/callGrey.svg';
import messagedisable from '../../../../assets/logo/msgGrey.svg';
import { IAgent } from '../../../../interfaces/User';
import { useState } from 'react';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';

function AgentCardMobile({
  firstName,
  lastName,
  UserRole,
  createdAt,
  email,
  phoneNumber,
  Agent,
  uuid,
  setDeActivate,
  updateAgentStatus,
  setDeActivatingUser,
  setDeletingUser,
  setShowDeleteModal,
}: {
  firstName: string;
  lastName: string;
  UserRole: {
    name: string;
  };
  uuid: string;
  createdAt: string;
  email: string;
  phoneNumber: string;
  Agent: IAgent;
  setDeActivate: Function;
  setDeActivatingUser: Function;
  updateAgentStatus: Function;
  setDeletingUser: Function;
  setShowDeleteModal: Function;
}) {
  return (
    <CardMobileDiv>
      <div className=' d-flex justify-content-between'>
        <div>
          <h3
            className={`card-title ${
              Agent.AgentStatus?.name === 'INACTIVE' && 'InactiveHead'
            }`}
          >
            {firstName} {lastName}
          </h3>
        </div>
        <div
          className={`role-div ${
            UserRole?.name === 'PRINCIPAL_AGENT'
              ? Agent.AgentStatus?.name === 'ACTIVE'
                ? 'statusActive'
                : ' Inactive'
              : Agent.AgentStatus?.name === 'ACTIVE'
              ? 'statusInvited'
              : ' Inactive'
          } `}
        >
          {UserRole?.name
            .toLowerCase()
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' ')}
        </div>
      </div>
      <div className='d-flex agency-details align-items-center'>
        <div className='subHead'>
          {Agent.AgentStatus?.name === 'ACTIVE' ? (
            <>
              <img src={message} alt={email} className='calls' />
              <img src={call} alt={phoneNumber} className='calls' />
            </>
          ) : (
            <>
              <img src={messagedisable} alt={email} className='calls' />
              <img src={calldisable} alt={phoneNumber} className='calls' />
            </>
          )}
        </div>
        <div className=''>
          Created on
          <br />
          <b>
            <Moment
              className={` ${
                Agent.AgentStatus?.name === 'INACTIVE' && 'InactiveHead'
              }`}
              format='MM/DD/YYYY'
            >
              {createdAt}
            </Moment>
          </b>
        </div>
      </div>
      <div className='actionsMob d-flex justify-content-between'>
        <div
          className={`actionButton ${
            Agent?.AgentStatus?.name === 'ACTIVE'
              ? 'activeActionButton'
              : 'disabledActionButton'
          }`}
          onClick={() => {
            if (Agent?.AgentStatus?.name === 'ACTIVE') {
              setDeActivate(true);
              setDeActivatingUser(Agent);
            } else if (Agent?.AgentStatus?.name === 'INACTIVE') {
              updateAgentStatus(Agent?.uuid, {
                action: 'updateStatus',
                status: 'ACTIVE',
              });
            }
          }}
        >
          {Agent?.AgentStatus?.name === 'ACTIVE' ? 'Deactivate' : '  Activate'}
        </div>
        <ActionsDropdown
          uuid={Agent?.uuid}
          setUUID={() => {}}
          setShowEditModal={() => {}}
          deletingUser={Agent}
          setDeletingUser={setDeletingUser}
          setShowDeleteModal={setShowDeleteModal}
        />
      </div>
    </CardMobileDiv>
  );
}

export default AgentCardMobile;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  deletingUser,
  setDeletingUser,
  setShowDeleteModal,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  deletingUser: any;
  setDeletingUser: Function;
  setShowDeleteModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => setShowActionsDropdown(!showActionsDropdown)}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div
            className='delete-div'
            onClick={() => {
              setShowDeleteModal(true);
              setDeletingUser(deletingUser);
              setShowActionsDropdown(false);
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};

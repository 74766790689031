import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import SelectField from '../../../../components/CustomSelectField';
import {
  StyledModal,
  StyledModalMobile,
} from '../../../Pipeline/elements/styles';
import { AppDispatch } from '../../../../store';
import {
  AgentsSliceState,
  updateAgentDetail,
} from '../../../../store/agents/agentsSlice';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import { IAgent } from '../../../../interfaces/User';
import { selectAllAgents } from '../../../../store/agents/agentsSlice';
import SearchDropdown from '../../../../components/SearchDropdown';
import { fetchAgents } from '../../../../api/agents';
import LocalStorageService from '../../../../utils/LocalStorageService';

const DeactivateUser = ({
  showModal,
  closeModal,
  desablingAgent,
}: {
  showModal: boolean;
  closeModal: Function;
  desablingAgent: IAgent;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const agents = useSelector(selectAllAgents);
  const [errorMessege, setErrorMessege] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const loadingStatus = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.status
  );
  let userName = {
    user: desablingAgent?.User?.uuid,
  };
  const [producers, setProducers] = useState<IAgent[]>([]);
  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [loadStatus, setLoadingStatus] = useState('idle');
  const user = LocalStorageService.getUser();
  const { uuid } = useParams();

  const onSetItem = (name: string, value: string) => {
    setIsValidated(true);
    setErrorMessege('');
  };
  const updateStatement = (
    uuid: string,
    data: { action: string; status: string; assigneeUUID: string }
  ) => {
    if (producer) {
      if ((loadingStatus === 'idle' || loadingStatus === 'succeeded') && uuid) {
        dispatch(updateAgentDetail({ uuid, data }));
        closeModal(false);
      }
    } else {
      setErrorMessege('Please select an Assignee');
    }
  };

  const loadProducer = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      if (producerSearch) {
        fetchAgents(
          (data: IAgent[]) => {
            setProducers(data);
            setLoadingStatus('succeeded');
          },
          'id',
          'asc',
          value,
          'True',
          0,
          0,
          user.UserRole?.name === 'ADMIN' ? uuid : '',
          '',
          ''
        );
      } else {
        setProducers([]);
        setLoadingStatus('succeeded');
      }
    }
  };
  useEffect(() => {
    if (producer) {
      setErrorMessege('');
    }
  }, [producer]);

  const resetProducer = (value: string) => {
    setProducers([]);
    setProducer('');
  };
  return (
    <>
      <StyledModal
        show={showModal}
        centered
        dialogClassName=' modalContainer'
        className='d-none d-sm-block'
      >
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => closeModal(false)}
          />
          <h2>Deactivate this user?</h2>
          <div className='sub-heading'>
            Please select an agent as there might be some resources associated
            with the current agent. Entities associated with the current agent,
            if any will be associated to the selected agent.
          </div>
          <div className='fields'>
            <SelectField
              options={agents.map((item) => {
                return {
                  value: item?.User?.uuid,
                  label: item?.User?.firstName + ' ' + item?.User?.lastName,
                };
              })}
              placeholder='From user'
              name='user'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>
          <div className='fields'>
            <SearchDropdown
              name='assignee'
              placeholder='To user'
              isEdit={true}
              addName={true}
              values={producerSearch}
              searchValue={producers}
              setSearch={setProducerSearch}
              status={loadingStatus}
              className={`${producerSearch && 'filled'}`}
              loadFunction={loadProducer}
              setUUID={resetProducer}
              content={producers
                ?.filter((item: any) => item?.uuid !== desablingAgent?.uuid)
                .map((item) => (
                  <div
                    className='searchValuesCard d-flex align-items-center'
                    onClick={() => {
                      setProducerSearch(
                        `${item?.User.firstName} ${
                          item?.User.lastName ? item?.User.lastName : ''
                        }`
                      );
                      setProducer(item?.uuid);
                    }}
                  >
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.User?.firstName?.charAt(0)}
                      {item?.User?.lastName?.charAt(0)}
                    </div>
                    <div>
                      <h6>
                        {`${item.User.firstName} ${
                          item.User.lastName ? item.User.lastName : ''
                        }`}
                      </h6>
                      <ul className='user-details'>
                        <li>{item.User?.email}</li>
                        <li>{item?.User?.phoneNumber}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center '>
            <button
              onClick={() => {
                closeModal(false);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='removeButton'
              onClick={() => {
                updateStatement(desablingAgent?.uuid, {
                  action: 'updateStatus',
                  status: 'INACTIVE',
                  assigneeUUID: producer,
                });
              }}
            >
              Move and Deactivate
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModal>
      <StyledModalMobile
        show={showModal}
        className=' d-sm-none'
        // animation={false}
      >
        {' '}
        <div>
          <div className='top-notch'></div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => closeModal(false)}
          />
          <h2>Deactivate this user?</h2>
          <div className='sub-heading'>
            There are some opportunities, policies and quotes associated to this
            user. Please move these entities to be associated to a different
            user.
          </div>
          <div className='fields'>
            <SelectField
              options={agents.map((item) => {
                return {
                  value: item?.User?.uuid,
                  label: item?.User?.firstName + ' ' + item?.User?.lastName,
                };
              })}
              placeholder='From user'
              name='user'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <SearchDropdown
              name='assignee'
              placeholder='To user'
              isEdit={true}
              addName={true}
              values={producerSearch}
              searchValue={producers}
              setSearch={setProducerSearch}
              status={loadingStatus}
              className={`${producerSearch && 'filled'}`}
              loadFunction={loadProducer}
              setUUID={resetProducer}
              content={producers
                ?.filter((item: any) => item?.uuid !== desablingAgent?.uuid)
                .map((item) => (
                  <div
                    className='searchValuesCard d-flex align-items-center'
                    onClick={() => {
                      setProducerSearch(
                        `${item?.User.firstName} ${
                          item?.User.lastName ? item?.User.lastName : ''
                        }`
                      );
                      setProducer(item?.uuid);
                    }}
                  >
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.User?.firstName?.charAt(0)}
                      {item?.User?.lastName?.charAt(0)}
                    </div>
                    <div>
                      <h6>
                        {`${item.User.firstName} ${
                          item.User.lastName ? item.User.lastName : ''
                        }`}
                      </h6>
                      <ul className='user-details'>
                        <li>{item.User?.email}</li>
                        <li>{item?.User?.phoneNumber}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeModal(false);
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='removeButton'
              onClick={() => {
                updateStatement(desablingAgent?.uuid, {
                  action: 'updateStatus',
                  status: 'INACTIVE',
                  assigneeUUID: producer,
                });
              }}
            >
              Move and Deactivate
            </button>
          </div>
        </div>
      </StyledModalMobile>
    </>
  );
};

export default DeactivateUser;

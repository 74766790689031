import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { onDeleteXDateReason } from '../../../store/reasons/reasonsSlice';
import { IXDateReason } from '../../../interfaces/Lead';
import { StyledDeleteModal } from '../../Admin/elements/styles';
import cross from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import deleteImg from '../../../assets/illustrations/delete.svg';

const DeleteXDateReasonModal = ({
  showModal,
  setShowModal,
  setSuccessMessage,
  xDateReason,
}: {
  showModal: boolean;
  setShowModal: Function;
  setSuccessMessage: Function;
  xDateReason: IXDateReason;
}) => {
  const { uuid } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const deleteXDateReason = () => {
    dispatch(
      onDeleteXDateReason({
        agencyUUID: uuid,
        uuid: xDateReason.uuid,
        successCB: () => {
          setShowModal(false);
          setSuccessMessage(`X-Date reason has been deleted.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal(false)}
      />
      <img src={deleteImg} alt='delete' className='delete-img' />
      <h2>Delete X-Date reason?</h2>
      <div className='sub-heading'>
        Do you want to delete this X-Date reason?
      </div>

      <div className='buttonsDiv d-flex align-items-center justify-content-end'>
        <button
          onClick={() => {
            setShowModal(false);
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='WarningButton d-flex align-items-center justify-content-between'
          onClick={() => {
            deleteXDateReason();
          }}
        >
          Delete
          <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </StyledDeleteModal>
  );
};

export default DeleteXDateReasonModal;

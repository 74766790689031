import axios from './axios';
import { IAddPipeline } from '../interfaces/Pipeline';

const addPipeline = (data: IAddPipeline) => {
  return axios.post('/pipeline', data);
};

const fetchPipelineTemplate = (successCB: Function) => {
  return axios
    .get('/pipeline/template')
    .then((res) => successCB(res.data.data));
};

const editPipeline = (data: IAddPipeline, uuid: string) => {
  return axios.patch(`/pipeline/${uuid}`, data);
};

const deletePipeline = (uuid: string, agencyUUID?: string) => {
  return axios.delete(`/pipeline/${uuid}`, { data: { agencyUUID } });
};

const updatePipelineStatus = (uuid: string, data: any) => {
  return axios.patch(`/pipeline/${uuid}/updateStatus`, data).then((res) => {
    return res;
  });
};
export {
  deletePipeline,
  addPipeline,
  fetchPipelineTemplate,
  editPipeline,
  updatePipelineStatus,
};

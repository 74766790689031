import axios from './axios';
const fetchProducts = (
  successCB: Function,
  sortBy?: string,
  orderBy?: string,
  searchBy?: string,
  agencyUUID?: string,
  includeCustom?: boolean,
  lineOfBussiness?: string
) => {
  return axios
    .get(`/product`, {
      params: {
        sortBy,
        searchBy,
        orderBy,
        agencyUUID,
        includeCustom,
        lineOfBussiness,
      },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const fetchSingleProduct = (
  uuid: string,
  successCB: Function,
  type: string
) => {
  return axios.get(`/product/${uuid}`, { params: { type } }).then((res) => {
    successCB(res.data);
    return res;
  });
};

const addProduct = (
  data: { agencyUUID?: string; action: string },
  successCB: Function
) => {
  return axios.post('/product', data).then((res) => {
    successCB(res);
    return res;
  });
};

const updateProduct = (
  uuid: string,
  data: { agencyUUID?: string; action: string },
  successCB: Function
) => {
  return axios.patch(`/product/${uuid}`, data).then((res) => {
    successCB(res);
    return res;
  });
};

const deleteProduct = (
  uuid: string,
  data: {
    agencyUUID?: string;
    action: string;
  }
) => {
  return axios.delete(`/product/${uuid}`, { data });
};

export {
  fetchProducts,
  fetchSingleProduct,
  addProduct,
  updateProduct,
  deleteProduct,
};

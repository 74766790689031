import styled from '@emotion/styled';
export const InnerFieldStyle = styled.div`
  .taskDiv {
    padding-top: 0;
  }
  .taskHead {
    padding: 20px 20px 20px 0px;
  }

  .tertiaryButton {
    padding: 12px 20px;
    font-size: 16px;
  }
  .addButton {
    position: fixed;
  }
  .filterbutton {
    display: flex;
    align-items: center;
    background: #e6e9f0;
    height: 32px;
    color: #262758;
  }
  .addIcon {
    margin-left: 20px;
  }
  .opportunityDiv {
    max-height: 60vh;
    padding: 0 10px 20px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .documentDiv {
    overflow: hidden;
  }
  .activityMobileCard {
    padding: 30px 15px;
    .SearchField {
      border-right: 1px solid #d1d6e5;
      padding-right: 10px;
    }
  }
  .s3LocatnText {
    text-decoration: none;
    color: #262758;
  }
  .s3LocatnText:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #3c498a;
    text-decoration-thickness: 2px;
  }
  .scroll-div {
    overflow-y: scroll;
    max-height: 70vh;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    border: none;
    width: 48px;
    height: 48px;
    right: 38px;
    background: #262758;
    border-radius: 50%;
    z-index: 1;
  }

  @media screen and (min-width: 570px) {
    height: 100%;
    .taskHead {
      padding: 22px 0px 20px 0px;
    }
    .tertiaryButton {
      padding: 9px 20px;
      height: 42px;
      font-size: 16px;
    }
    .filterbutton {
      background: #e6e9f0;
      height: 40px;
    }
    .addIcon {
      margin-left: 20px;
    }
    .opportunityDiv {
      max-height: 50vh;
      padding: 2px;
      padding-bottom: 30px;
      overflow-y: auto;
    }
    .scroll-div {
      overflow-y: scroll;
      max-height: 64vh;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
    .actionsDrop {
      margin-right: 40px;
    }
  }
  @media screen and (min-width: 1200px) {
    .taskDiv {
      padding-top: 30px;
    }
    .opportunityDiv {
      max-height: 45vh;
      padding-bottom: 60px;
    }
    .taskHead {
      padding: 0px 0px 20px 0px;
    }
  }
  @media screen and (min-width: 1900px) {
    .opportunityDiv {
      height: 57vh;
    }
    .taskHead {
      padding: 4px 0px 20px 0px;
    }
    .actionsDrop {
      margin-right: 70px;
    }
  }
`;

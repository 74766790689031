import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import {
  onDeleteLeadSource,
  selectLeadSourceTypes,
} from '../../../store/leads/leadsSlice';
import SelectField from '../../../components/SelectField';
import { ILeadSource, ILeadSourceType } from '../../../interfaces/Lead';
import { StyledDeleteModal } from '../../Admin/elements/styles';
import cross from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

const ValidationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    label: Yup.string().required('Please select a destination.'),
    value: Yup.string().required('Please select a destination.'),
  }),
});

const DeleteSourceModal = ({
  showModal,
  setShowModal,
  leadSource,
  type,
  setSuccessMessage,
}: {
  showModal: boolean;
  setShowModal: Function;
  leadSource: ILeadSource;
  type: ILeadSourceType;
  setSuccessMessage: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();

  const sourcetypes = useSelector(selectLeadSourceTypes);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('from', { label: leadSource.name, value: leadSource.uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (data: any) => {
    dispatch(
      onDeleteLeadSource({
        uuid: leadSource.uuid,
        data: {
          agencyUUID: uuid,
          typeUUID: type.uuid,
          destinationUUID: data.destination.value,
        },
        successCB: () => {
          setShowModal(false);
          setSuccessMessage(`${leadSource.name} has been deleted.`);
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        },
      })
    );
  };

  return (
    <StyledDeleteModal show={showModal} centered>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => setShowModal(false)}
      />
      <h2>Delete lead source?</h2>
      <div className='sub-heading'>
        There are some leads associated to this lead source. Please move these
        leads to a different lead source.
      </div>
      <form onSubmit={handleSubmit(onDelete)}>
        <div className='mb-3'>
          <SelectField
            name='from'
            placeholder='From Source'
            options={sourcetypes
              .map((item) => item.LeadSources)
              .flat(1)
              .map((item) => {
                return {
                  label: item.name,
                  value: item.uuid,
                };
              })}
            control={control}
            isDisabled={true}
          />
        </div>
        <div className='mb-3'>
          <SelectField
            name='destination'
            placeholder='To Source'
            options={sourcetypes.map((item) => {
              return {
                label: item.name,
                options: item.LeadSources.filter(
                  (item) => item.uuid !== leadSource.uuid
                ).map((source) => {
                  return {
                    label: source.name,
                    value: source.uuid,
                  };
                }),
              };
            })}
            control={control}
          />
          {errors.destination ? (
            <div className='errorVal'>
              <img src={warning} alt='warning' className='warning' />
              {errors.destination?.value?.message}
            </div>
          ) : null}
        </div>

        <div className='buttonsDiv d-flex align-items-center justify-content-end'>
          <button
            onClick={() => {
              setShowModal();
            }}
            className='secondaryButton'
            type='button'
          >
            Cancel
          </button>
          <button
            className='WarningButton d-flex align-items-center justify-content-between'
            type='submit'
          >
            Move and Delete
            <img className='arrowl' src={arrow} alt='arrow-function' />
          </button>
        </div>
      </form>
    </StyledDeleteModal>
  );
};

export default DeleteSourceModal;

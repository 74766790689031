import { CardMobileDiv } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import primary from '../../../../assets/logo/primary.svg';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import { ILocation } from '../../../../interfaces/Location';
import { useParams } from 'react-router-dom';
import EditLocationModal from './EditLocationModal';
import DeleteLocationModal from './DeleteLocationModal';

import { useState, useRef } from 'react';
function LocationCardMobile({ item }: { item: ILocation }) {
  const { uuid } = useParams();
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const [UUID, setUUID] = useState('');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [location, setLocation] = useState<ILocation>();
  return (
    <CardMobileDiv>
      <div className='d-flex'>
        <div>
          <h3 className={`card-title`}>{item?.name} </h3>
        </div>
        {item?.isPrimary && (
          <img className='primaryName' src={primary} alt='prime' />
        )}
      </div>
      <div className='addressName'>{item?.address}</div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='agency-details align-items-center '>
          <div className='subHeadLocation'>{item?.phoneNumber}</div>
          <div className='subHeadLocation'>Fax: {item?.phoneNumber}</div>
        </div>
        <div className='borderLocation'></div>
        <button
          className='dropdownvector d-flex align-items-center justify-content-center position-relative'
          onClick={() => setShowActionsDropdown(!showActionsDropdown)}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showActionsDropdown && (
            <div
              className={
                item?.isPrimary ? 'onlyEdit-dropdown-div' : 'dropdown-div'
              }
            >
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setUUID(item?.uuid);
                  setShowActionsDropdown(false);
                  setLocation(item);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              {!item?.isPrimary && (
                <div
                  className='delete-div'
                  onClick={() => {
                    setShowDeleteModal(true);
                    setUUID(item?.uuid);
                    setLocation(item);
                    setShowActionsDropdown(false);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              )}
            </div>
          )}
        </button>

        {showDeleteModal && location && (
          <DeleteLocationModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deletingLocation={location}
            agencyUUID={uuid}
          />
        )}
        {showEditModal !== 'closed' && location && (
          <EditLocationModal
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={UUID}
            agencyUUID={uuid}
            isThisprimaryLocation={location?.isPrimary}
          />
        )}
      </div>
    </CardMobileDiv>
  );
}

export default LocationCardMobile;

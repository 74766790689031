import { FieldDiv } from '../DetailsEditFormInput/styles';
import edit from '../../assets/logo/edit.svg';
import { Controller } from 'react-hook-form';

export interface IProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  control?: any;
  required?: boolean;
}

const EditPhoneNumber = ({
  name,
  type,
  placeholder,
  className,
  disabled,
  required,
  control,
}: IProps) => {
  const phoneNumberFormat = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };
  return (
    <FieldDiv>
      <div className={`d-flex flex-column w-100 ${className} input-container`}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <input
                id={name}
                className={`input-field`}
                {...field}
                onChange={(e) =>
                  field.onChange(phoneNumberFormat(e.target.value))
                }
                disabled={disabled}
                type={type}
                autoComplete='off'
              />
            );
          }}
        />

        <label htmlFor={name} className='input-placeholder'>
          {placeholder}
          {required ? <span className='requiredStar'>*</span> : null}
        </label>
        <img className='editIcon' src={edit} alt='edit' />
      </div>
    </FieldDiv>
  );
};
export default EditPhoneNumber;

import { StyledModal } from './style';

function Modal({
  open,
  children,
}: {
  open: string;
  children: React.ReactNode;
}) {
  return (
    <StyledModal>
      <div className={`drawer-container ${open}`}>{children}</div>
    </StyledModal>
  );
}

export default Modal;

import { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import MobileNav from '../../components/MobileTabNav';
import { AdminDiv } from '../../styles/AdminManage';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/user/userSlice';
import { Status, Dashboard } from './style';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import Events from './components/Events';
import LeadFunnel from './components/LeadFunnel';
import SalesAnalytics from './components/SalesAnalytics';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import {
  DashboardSliceState,
  getAccount,
  getFunnelGraph,
  getGoal,
  getLeaders,
  getPolicy,
  getSalesGraph,
  selectAccount,
  selectFunnelGraph,
  selectGoal,
  selectLeaderBoard,
  selectPolicy,
  selectSalesGraph,
} from '../../store/dashboard/dashboardSlice';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LeaderboardTable from './components/Leaderboard';
import {
  getActivties,
  selectAllActivities,
} from '../../store/activity/activitiesSlice';
import 'react-circular-progressbar/dist/styles.css';
import {
  getPipelines,
  selectAllPipelines,
} from '../../store/pipelines/pipelineSlice';
import Goals from './components/Goal';
import StatusCard from './components/StatusCard';
import logo from '../../assets/logo/logo.svg';
import Skeleton from 'react-loading-skeleton';

export const customSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: state.isSelected ? '#ecf4f9' : '#fff',
    fontSize: '14px',
    borderBottom: ' 1px solid #E6E9F0',
    '&:active': {
      background: '#f5f7fc',
    },
    borderRadius: '8px',
    'white-space': 'nowrap',
    '&:hover': {
      background: '#f5f7fc',
    },
    '@media only screen and (max-width: 575px)': {
      width: '130px',
      'white-space': 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    backgroundColor: '#fff',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
    width: 'auto',
    'white-space': 'nowrap',
    marginTop: 0,
    right: 0,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    paddingRight: state.selectProps.menuIsOpen ? '8px' : 0,
    paddingLeft: state.selectProps.menuIsOpen ? 0 : '8px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  }),
  control: (provided: object, state: any) => ({
    ...provided,
    '&:hover': {
      borderColor: 'none',
      backgroundColor: 'transparent',
    },
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: 'none',
  }),
  indicatorSeparator: () => ({ background: 'transparent' }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      color: 'inherit',
      fontWeight: 600,
      fontSize: 'inherit',
      paddingLeft: '2px',
      paddingRight: '2px',
      marginLeft: 0,
      marginRight: 0,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    padding: 0,
    height: '39px',
  }),
};

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const options = [
    { label: 'This month', value: 'month' },
    { label: 'This week', value: 'week' },
  ];

  const salesOption = [{ label: 'Year till date', value: 'Year till date' }];

  const salesFrom = [
    { label: 'Insurance Type', value: 'insurance' },
    { label: 'Carriers', value: 'carrier' },
    { label: 'Policy Type', value: 'product' },
    { label: 'Lead Source', value: 'leadSource' },
  ];

  const activityOption = [
    { label: 'All activities', value: 'activity' },
    { label: 'Tasks', value: 'TASK' },
    { label: 'Events', value: 'EVENT' },
  ];

  const user = useSelector(selectUser);

  const [isAgency, setIsAgency] = useState(false);

  const handleSelectChange = () => {};

  const loadingStatus = useSelector(
    (state: { dashboard: DashboardSliceState }) => state.dashboard.status
  );

  const [goalFilter, setGoalFilter] = useState('month');
  const [goalType, setGoalType] = useState('PREMIUM');
  const [policyFilter, setPolicyFilter] = useState('month');
  const [accountFilter, setAccountFilter] = useState('month');
  const [activityFilter, setActivityFilter] = useState('month');
  const [funnelDuration, setFunnelDuration] = useState('month');
  const [leaderFilter, setLeaderFilter] = useState('month');
  const [greet, setGreet] = useState('');
  const [salesType, setSalesType] = useState('insurance');
  const [funnelPipeline, setFunnelPipeline] = useState('');
  const [activityType, setActivityType] = useState('activity');
  const [sortBy, setSortBy] = useState('rank');
  const [orderBy, setOrderBy] = useState('asc');

  const pipeline = useSelector(selectAllPipelines);
  const leaderboard = useSelector(selectLeaderBoard);

  const handleSelectPolicy = (data: any) => {
    setPolicyFilter(data.value);
  };
  const handleSelectLeader = (data: any) => {
    setLeaderFilter(data.value);
  };
  const handleSelectAccount = (data: any) => {
    setAccountFilter(data.value);
  };
  const handleSelectGoal = (data: any) => {
    setGoalFilter(data.value);
  };
  const handleGoalType = (data: any) => {
    setGoalType(data.value);
  };

  const handleSelectActivity = (data: any) => {
    setActivityFilter(data.value);
  };

  const handleSelectSales = (data: any) => {
    setSalesType(data.value);
  };
  const handleSelectFunnel = (data: any) => {
    setFunnelPipeline(data.value);
  };
  const handleSelectFunnelDuration = (data: any) => {
    setFunnelDuration(data.value);
  };
  const handleSelectActivityType = (data: any) => {
    setActivityType(data.value);
  };

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getPipelines({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          successCB: (data: any) => {
            setFunnelPipeline(data[0].uuid);
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getLeaders({
          duration: leaderFilter,
          showAgency: isAgency.toString(),
          sortBy,
          orderBy,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderFilter, isAgency, sortBy, orderBy]);

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getAccount({
          filter: accountFilter,
          showAgency: isAgency.toString(),
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFilter, isAgency, user]);

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getPolicy({ filter: policyFilter, showAgency: isAgency.toString() })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyFilter, isAgency, user]);

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getGoal({
          filter: goalFilter,
          goalType,
          showAgency: isAgency.toString(),
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalFilter, isAgency, goalType, leaderboard, user]);
  // TODO : Remove leaderboard from dep array after updating goal after setting goal.

  useEffect(() => {
    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getSalesGraph({ type: salesType, showAgency: isAgency.toString() })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesType, isAgency]);

  useEffect(() => {
    if (pipeline.length > 0 && user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getFunnelGraph({
          pipelineUUID: funnelPipeline,
          duration: funnelDuration,
          showAgency: isAgency.toString(),
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelDuration, funnelPipeline, isAgency, pipeline]);

  useEffect(() => {
    var today = new Date().getHours();

    // let currentTime;
    if (today <= 12) {
      setGreet('Good morning');
    } else if (today >= 12 && today <= 17) {
      setGreet('Good afternoon');
    } else {
      setGreet('Good evening');
    }

    if (user && user?.UserRole.name !== 'ADMIN') {
      dispatch(
        getActivties({
          sortBy: 'dueDate',
          orderBy: 'asc',
          duration: activityFilter,
          showAgency: isAgency.toString(),
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityFilter, isAgency]);

  const goal = useSelector(selectGoal);
  const policy = useSelector(selectPolicy);
  const account = useSelector(selectAccount);
  const activities = useSelector(selectAllActivities);
  const salesGraph = useSelector(selectSalesGraph);
  const funnelGraph = useSelector(selectFunnelGraph);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const [isTab, setIsTab] = useState(window.innerWidth <= 1200);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 576);
    setIsTab(window.innerWidth <= 1200);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = [
    {
      name: 'Policies',
      durationFunction: handleSelectPolicy,
      count: policy?.total,
      active: policy?.active,
      className: 'policy',
      average: policy?.average,
      new: policy?.new,
    },
    {
      name: 'Accounts',
      durationFunction: handleSelectAccount,
      count: account?.total,
      active: account?.active,
      className: 'account',
      average: account?.average,
      new: account?.new,
    },
  ];

  const userOption = [
    { label: 'View as My Agency', value: 'agency' },
    { label: 'View as Agent', value: 'agent' },
  ];

  useEffect(() => {
    if (user?.UserRole?.name === 'PRINCIPAL_AGENT') {
      setIsAgency(true);
    } else {
      setIsAgency(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleAgencySelect = (data: any) => {
    if (data.value === 'agency') {
      setIsAgency(true);
    } else {
      setIsAgency(false);
    }
  };

  const percentage = goal?.policyValue
    ? goal.goalValue > goal.policyValue
      ? (goal.policyValue / goal.goalValue) * 100
      : 100
    : 0;

  useEffect(() => {
    if (pipeline.length > 0) {
      setFunnelPipeline(pipeline[0].uuid);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline]);

  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <Dashboard className='content-div'>
        <TopBar backgroundColor={isMobile ? 'unset' : ''}>
          <h2 className='page-title d-none d-md-block'>Dashboard</h2>
          {isMobile && <img src={logo} alt='' className='logo' />}
        </TopBar>
        <header className='d-md-flex justify-content-between dashbord-header-div'>
          <h1>
            {greet + ' '}
            {user?.firstName || user?.lastName}
          </h1>
          {user?.UserRole?.name === 'PRINCIPAL_AGENT' ? (
            <Select
              className='view-as'
              defaultValue={userOption[0]}
              options={userOption}
              onChange={(e) => handleAgencySelect(e)}
              styles={customSelectStyles}
              isSearchable={false}
            />
          ) : null}
        </header>
        {isTab && !isMobile ? (
          <Status>
            <div className='d-flex'>
              <Goals
                options={options}
                handleSelectGoal={handleSelectGoal}
                handleGoalType={handleGoalType}
                selectedGoalType={goalType}
                customSelectStyles={customSelectStyles}
                isTab={isTab}
                percentage={percentage}
                goal={goal}
              />
              <div className='status-container tab-view-contents'>
                <div className='status-section'>
                  {status.map((item) => (
                    <StatusCard
                      item={item}
                      key={item.name}
                      options={options}
                      customSelectStyles={customSelectStyles}
                    />
                  ))}
                </div>
                {isAgency ? (
                  <div className='list-container leaders-list'>
                    <div className='d-flex justify-content-between head-div'>
                      <h4>Leaderboard</h4>
                      <Select
                        className='dashboard-select'
                        defaultValue={options[0]}
                        options={options}
                        onChange={(e) => handleSelectLeader(e)}
                        styles={customSelectStyles}
                        isSearchable={false}
                      />
                    </div>
                    <div>
                      <LeaderboardTable
                        leaders={leaderboard}
                        setSortBy={setSortBy}
                        setOrderBy={setOrderBy}
                        sortBy={sortBy}
                        orderBy={orderBy}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='list-container'>
                    <div className='d-flex justify-content-between head-div'>
                      <h4 className='activity'>
                        <Select
                          className='activity-select'
                          defaultValue={activityOption[0]}
                          options={activityOption}
                          onChange={(e) => handleSelectActivityType(e)}
                          styles={customSelectStyles}
                          isSearchable={false}
                        />
                      </h4>
                      <Select
                        className='dashboard-select'
                        defaultValue={options[0]}
                        options={options}
                        onChange={(e) => handleSelectActivity(e)}
                        styles={customSelectStyles}
                        isSearchable={false}
                      />
                    </div>
                    <Events
                      events={
                        activityType !== 'activity'
                          ? activities.filter(
                              (item: any) => item.type === activityType
                            )
                          : activities
                      }
                    />
                  </div>
                )}
                <Row className='task-event-container'>
                  <Col xl={4}>
                    {isAgency ? (
                      <div className='list-container event-list mt-0'>
                        <div className='d-flex justify-content-between head-div'>
                          <h4 className='activity'>
                            <Select
                              className='activity-select'
                              defaultValue={activityOption[0]}
                              options={activityOption}
                              onChange={(e) => handleSelectActivityType(e)}
                              styles={customSelectStyles}
                              isSearchable={false}
                            />
                          </h4>
                          <Select
                            className='dashboard-select'
                            defaultValue={options[0]}
                            options={options}
                            onChange={(e) => handleSelectActivity(e)}
                            styles={customSelectStyles}
                            isSearchable={false}
                          />
                        </div>
                        <Events
                          events={
                            activityType !== 'activity'
                              ? activities.filter(
                                  (item: any) => item.type === activityType
                                )
                              : activities
                          }
                        />
                      </div>
                    ) : null}
                  </Col>
                  <Col xl={isAgency ? 8 : 12}>
                    <div className='lead-funnel-container mt-0'>
                      <div className='d-md-flex justify-content-between'>
                        <h4
                          className={`leadfunnel-text ${
                            isMobile ? 'd-flex justify-content-between' : ''
                          }`}
                        >
                          Opportunity Funnel
                          {isMobile && (
                            <Select
                              className='duration-select'
                              defaultValue={options[0]}
                              options={options}
                              onChange={(e) => handleSelectFunnelDuration(e)}
                              styles={customSelectStyles}
                              isSearchable={false}
                            />
                          )}
                        </h4>
                        <div className='d-flex'>
                          <Select
                            className='pipeline-select'
                            value={
                              pipeline
                                .map((item) => {
                                  return { label: item.name, value: item.uuid };
                                })
                                .find(
                                  (option: { value: string; label: string }) =>
                                    option.value === funnelPipeline
                                )
                                ? pipeline
                                    .map((item) => {
                                      return {
                                        label: item.name,
                                        value: item.uuid,
                                      };
                                    })
                                    .find(
                                      (option: {
                                        value: string;
                                        label: string;
                                      }) => option.value === funnelPipeline
                                    )
                                : ''
                            }
                            options={pipeline.map((item) => {
                              return { label: item.name, value: item.uuid };
                            })}
                            onChange={(e) => handleSelectFunnel(e)}
                            styles={customSelectStyles}
                            isSearchable={false}
                          />
                          {!isMobile && (
                            <Select
                              className='duration-select'
                              defaultValue={options[0]}
                              options={options}
                              onChange={(e) => handleSelectFunnelDuration(e)}
                              styles={customSelectStyles}
                              isSearchable={false}
                            />
                          )}
                        </div>
                      </div>
                      {loadingStatus === 'loading' ? (
                        <>
                          <Skeleton height={34} width={187} />
                          <Skeleton height={300} />
                        </>
                      ) : (
                        loadingStatus === 'succeeded' &&
                        funnelGraph && (
                          <>
                            <div className='total-count'>
                              Total Opportunities -{' '}
                              {funnelGraph?.totalOpportunities}
                            </div>
                            <LeadFunnel data={funnelGraph} />
                          </>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
                <div className='lead-funnel-container sales-graph-container'>
                  <div className='d-md-flex justify-content-between'>
                    <h4 className='d-flex'>
                      Sales from
                      <Select
                        className='lead-select'
                        defaultValue={salesFrom[0]}
                        options={salesFrom}
                        onChange={(e) => handleSelectSales(e)}
                        styles={customSelectStyles}
                        isSearchable={false}
                      />
                    </h4>
                    <Select
                      className='duration-select'
                      defaultValue={salesOption[0]}
                      options={salesOption}
                      onChange={(e) => handleSelectChange()}
                      styles={customSelectStyles}
                      isSearchable={false}
                    />
                  </div>
                  {loadingStatus === 'loading' ? (
                    <>
                      <Skeleton height={300} />
                    </>
                  ) : (
                    <SalesAnalytics isMobile={isMobile} data={salesGraph} />
                  )}
                </div>
              </div>
            </div>
          </Status>
        ) : (
          <>
            <Status>
              {isMobile ? (
                <>
                  <Goals
                    options={options}
                    handleSelectGoal={handleSelectGoal}
                    handleGoalType={handleGoalType}
                    selectedGoalType={goalType}
                    customSelectStyles={customSelectStyles}
                    isTab={true}
                    percentage={percentage}
                    goal={goal}
                  />
                  <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    selectedItem={0}
                    infiniteLoop={true}
                  >
                    {status.map((item) => (
                      <StatusCard
                        item={item}
                        key={item.name}
                        options={options}
                        customSelectStyles={customSelectStyles}
                      />
                    ))}
                  </Carousel>
                  {isAgency ? (
                    <div className='list-container leaders-list'>
                      <div className='d-flex justify-content-between head-div'>
                        <h4>Leaderboard</h4>
                        <Select
                          className='dashboard-select'
                          defaultValue={options[0]}
                          options={options}
                          onChange={(e) => handleSelectLeader(e)}
                          styles={customSelectStyles}
                          isSearchable={false}
                        />
                      </div>
                      <div>
                        <LeaderboardTable
                          leaders={leaderboard}
                          setSortBy={setSortBy}
                          setOrderBy={setOrderBy}
                          sortBy={sortBy}
                          orderBy={orderBy}
                          isMobile={isMobile}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className='d-flex'>
                  <Goals
                    options={options}
                    handleSelectGoal={handleSelectGoal}
                    handleGoalType={handleGoalType}
                    selectedGoalType={goalType}
                    customSelectStyles={customSelectStyles}
                    isTab={isTab}
                    percentage={percentage}
                    goal={goal}
                  />
                  <div className='status-container'>
                    <div className='status-section'>
                      {status.map((item) => (
                        <StatusCard
                          item={item}
                          key={item.name}
                          options={options}
                          customSelectStyles={customSelectStyles}
                        />
                      ))}
                    </div>
                    {isAgency ? (
                      <div className='list-container leaders-list'>
                        <div className='d-flex justify-content-between head-div'>
                          <h4>Leaderboard</h4>
                          <Select
                            className='dashboard-select'
                            defaultValue={options[0]}
                            options={options}
                            onChange={(e) => handleSelectLeader(e)}
                            styles={customSelectStyles}
                            isSearchable={false}
                          />
                        </div>
                        <div>
                          <LeaderboardTable
                            leaders={leaderboard}
                            setSortBy={setSortBy}
                            setOrderBy={setOrderBy}
                            sortBy={sortBy}
                            orderBy={orderBy}
                            isMobile={isMobile}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='list-container'>
                        <div className='d-flex justify-content-between head-div'>
                          <h4 className='activity'>
                            <Select
                              className='activity-select'
                              defaultValue={activityOption[0]}
                              options={activityOption}
                              onChange={(e) => handleSelectActivityType(e)}
                              styles={customSelectStyles}
                              isSearchable={false}
                            />
                          </h4>
                          <Select
                            className='dashboard-select'
                            defaultValue={options[0]}
                            options={options}
                            onChange={(e) => handleSelectActivity(e)}
                            styles={customSelectStyles}
                            isSearchable={false}
                          />
                        </div>
                        <Events
                          events={
                            activityType !== 'activity'
                              ? activities.filter(
                                  (item: any) => item.type === activityType
                                )
                              : activities
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Status>
            <Row className='task-event-container'>
              <Col xl={4}>
                {isAgency ? (
                  <div className='list-container event-list mt-0'>
                    <div className='d-flex justify-content-between head-div'>
                      <h4 className='activity'>
                        <Select
                          className='activity-select'
                          defaultValue={activityOption[0]}
                          options={activityOption}
                          onChange={(e) => handleSelectActivityType(e)}
                          styles={customSelectStyles}
                          isSearchable={false}
                        />
                      </h4>
                      <Select
                        className='dashboard-select'
                        defaultValue={options[0]}
                        options={options}
                        onChange={(e) => handleSelectActivity(e)}
                        styles={customSelectStyles}
                        isSearchable={false}
                      />
                    </div>
                    <Events
                      events={
                        activityType !== 'activity'
                          ? activities.filter(
                              (item: any) => item.type === activityType
                            )
                          : activities
                      }
                    />
                  </div>
                ) : null}
              </Col>
              <Col xl={isAgency ? 8 : 12}>
                <div className='lead-funnel-container mt-0'>
                  <div className='d-md-flex justify-content-between'>
                    <h4
                      className={
                        isMobile
                          ? 'd-flex justify-content-between'
                          : 'leadfunnel-text'
                      }
                    >
                      Opportunity Funnel
                      {isMobile && (
                        <Select
                          className='duration-select'
                          defaultValue={options[0]}
                          options={options}
                          onChange={(e) => handleSelectFunnelDuration(e)}
                          styles={customSelectStyles}
                          isSearchable={false}
                        />
                      )}
                    </h4>
                    <div className='d-flex'>
                      <Select
                        className='pipeline-select'
                        classNamePrefix='select-pipeline-lead'
                        value={
                          pipeline
                            .map((item) => {
                              return { label: item.name, value: item.uuid };
                            })
                            .find(
                              (option: { value: string; label: string }) =>
                                option.value === funnelPipeline
                            )
                            ? pipeline
                                .map((item) => {
                                  return { label: item.name, value: item.uuid };
                                })
                                .find(
                                  (option: { value: string; label: string }) =>
                                    option.value === funnelPipeline
                                )
                            : ''
                        }
                        options={pipeline.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        onChange={(e) => handleSelectFunnel(e)}
                        styles={customSelectStyles}
                        isSearchable={false}
                      />
                      <span className='d-sm-inline-block d-md-none total-opp'>
                        {funnelGraph?.totalOpportunities}
                      </span>
                      {!isMobile && (
                        <Select
                          className='duration-select'
                          defaultValue={options[0]}
                          options={options}
                          onChange={(e) => handleSelectFunnelDuration(e)}
                          styles={customSelectStyles}
                          isSearchable={false}
                        />
                      )}
                    </div>
                  </div>
                  {loadingStatus === 'loading' ? (
                    <>
                      <Skeleton height={34} width={187} />
                      <Skeleton height={300} />
                    </>
                  ) : (
                    loadingStatus === 'succeeded' &&
                    funnelGraph && (
                      <>
                        <div className='total-count d-none d-sm-inline-block'>
                          Total Opportunities -{' '}
                          {funnelGraph?.totalOpportunities}
                        </div>
                        <LeadFunnel data={funnelGraph} />
                      </>
                    )
                  )}
                </div>
              </Col>
            </Row>
            <div className='lead-funnel-container sales-graph-container'>
              <div className='d-md-flex justify-content-between'>
                <h4 className='d-flex'>
                  Sales from
                  <Select
                    className='lead-select'
                    defaultValue={salesFrom[0]}
                    options={salesFrom}
                    onChange={(e) => handleSelectSales(e)}
                    styles={customSelectStyles}
                    isSearchable={false}
                  />
                </h4>
                <Select
                  className='duration-select'
                  defaultValue={salesOption[0]}
                  options={salesOption}
                  onChange={(e) => handleSelectChange()}
                  styles={customSelectStyles}
                  isSearchable={false}
                />
              </div>
              {loadingStatus === 'loading' ? (
                <>
                  <Skeleton height={300} />
                </>
              ) : (
                loadingStatus === 'succeeded' && (
                  <SalesAnalytics isMobile={isMobile} data={salesGraph} />
                )
              )}
            </div>
          </>
        )}
      </Dashboard>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </AdminDiv>
  );
};

export default Home;

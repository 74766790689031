import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import DOMpurify from 'dompurify';
import { StyledContainer } from './styles';

const NotesContainer = ({ text }: { text: string }) => {
  return (
    <StyledContainer>
      <ReactMarkdown className='reactMarkdownText' rehypePlugins={[rehypeRaw]}>
        {DOMpurify.sanitize(text)}
      </ReactMarkdown>
    </StyledContainer>
  );
};

export default NotesContainer;

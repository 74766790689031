import { PickerDiv } from './styles';
import { Controller } from 'react-hook-form';
import calender from '../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import arrow from '../../assets/logo/leftArrow.svg';

export interface IProps {
  name: string;
  placeholder: string;
  control?: any;
  value: string;
  isTime?: boolean;
  disabled?: boolean;
  maxDate?: any;
  minDate?: any;
  dateOfBirth?: boolean;
  readOnly?: boolean;
  required?: boolean;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DateField = ({
  name,
  placeholder,
  value,
  control,
  isTime,
  maxDate,
  minDate,
  readOnly,
  dateOfBirth,
  required,
  ...props
}: IProps) => {
  return (
    <div>
      <PickerDiv
        className={`${value && 'filled-picker'} ${
          props.disabled && 'disabled-picker'
        }`}
      >
        <div
          className={`d-flex flex-column w-100 input-container ${
            value && 'filled'
          } `}
        >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <>
                {isTime ? (
                  <DatePicker
                    className='date-input'
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    showTimeSelect
                    onChangeRaw={(e) => e.preventDefault()}
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                    {...props}
                  />
                ) : (
                  <DatePicker
                    className='date-input'
                    onChangeRaw={(e) => e.preventDefault()}
                    onChange={(date) => {
                      if (date) {
                        field.onChange(
                          new Date(
                            date?.getTime() - date?.getTimezoneOffset() * 60000
                          )
                        );
                      } else {
                        field.onChange(date);
                      }
                    }}
                    selected={field.value}
                    maxDate={maxDate}
                    peekNextMonth
                    minDate={minDate}
                    readOnly={readOnly}
                    renderCustomHeader={({
                      date,
                      prevYearButtonDisabled,
                      nextYearButtonDisabled,
                      decreaseYear,
                      increaseYear,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <YearMonthPicker
                        date={date}
                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                        prevYearButtonDisabled={prevYearButtonDisabled}
                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                        nextYearButtonDisabled={nextYearButtonDisabled}
                        decreaseMonth={decreaseMonth}
                        decreaseYear={decreaseYear}
                        increaseMonth={increaseMonth}
                        increaseYear={increaseYear}
                      />
                    )}
                    openToDate={
                      value
                        ? new Date(value)
                        : dateOfBirth
                        ? new Date('1990/01/1')
                        : new Date()
                    }
                    dateFormat={'MM/dd/yyyy'}
                    {...props}
                  />
                )}
              </>
            )}
          />
          <label htmlFor={name}>
            {placeholder}{' '}
            {required ? <span className='requiredStar'>*</span> : null}
          </label>

          <div className='CallenderImg'>
            <img src={calender} alt='Calender' />
          </div>
        </div>
      </PickerDiv>
    </div>
  );
};

export default DateField;

export const YearMonthPicker = ({
  date,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: {
  date: Date;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  decreaseYear: () => void;
  increaseYear: () => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}) => {
  return (
    <div
      className='react-datepicker__current-month'
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          type='button'
        >
          <img src={arrow} />
        </button>
        <div className='date-info'>{months[date.getMonth()]}</div>
        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          type='button'
        >
          <img src={arrow} style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <button
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
          type='button'
        >
          <img src={arrow} />
        </button>
        <div className='date-info'>{date.getFullYear()}</div>
        <button
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
          type='button'
        >
          <img src={arrow} style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>
    </div>
  );
};

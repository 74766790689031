import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import {
  getOpportunityDetails,
  selectOpportunity,
  OpportunitiesSliceState,
  getCount,
  onDeleteOpportunity,
} from '../../store/opportunities/opportunitiesSlice';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import EditOpportunityDetails from './EditOpportunityDetails';
import OpportunityDetailsDrawer from './components/OpportunityDetailsDrawer';
import DetailsPageTab from '../../components/DetailsPageTab';
import MobileOpportunityDetails from './components/MobileOpportunityDetails';
import AddQuoteModal from '../../components/AddQuoteModal';
import DeleteModal from '../../components/DeleteModal';
import ErrorCard from '../Pipeline/components/ErrorCard';
import OpportunityContactModal from '../../components/OppoortunityContactModal';
import ContactForm from '../../components/ContactModalDetailsPage/ContactModal';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import LocalStorageService from '../../utils/LocalStorageService';
import { DetailsDiv } from '../../styles/DetailsDiv';
import dropdown from '../../assets/logo/dropdown.svg';
import arrow from '../../assets/logo/right-arrow.svg';
import bin from '../../assets/logo/bin.svg';
import edit from '../../assets/logo/edit.svg';
import defaultPolicy from '../../assets/logo/defaultPolicy.svg';
import { contacts } from '../../assets/nav-icons';

const OpportunityDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const editRef = useRef(null);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showLinkcontactModal, setShowLinkContactModal] = useState('closed');
  const [showAddcontactModal, setShowAddContactModal] = useState('closed');
  const [quickDrop, setQuickDrop] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  useOutsideClick(editRef, setQuickDrop);
  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.status
  );
  const opportunityDetails = useSelector(selectOpportunity);

  const user = LocalStorageService.getUser();

  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(getOpportunityDetails({ uuid }));
    }
    if (uuid) dispatch(getCount({ uuid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteOpportunity({
        uuid,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
            navigate('/mybook/opportunities');
          }, 2000);
        },
      })
    );
  };

  return (
    <DetailsDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <div className='content-section'>
        <div className='content-div d-none d-xl-block'>
          <TopBar>
            <div className='breadcrumb-div'>
              <span
                onClick={() => navigate('/mybook/opportunities')}
                className='breadcrumb-item'
              >
                Opportunities /
              </span>
              {opportunityDetails?.name}
            </div>
          </TopBar>

          <div className='header-div d-flex justify-content-between'>
            {showSuccess && (
              <ErrorCard
                message={`<b>${opportunityDetails?.name}</b> has been <b> deleted</b> `}
                title={'Success deleting opportunity.'}
              />
            )}
            <div className='d-flex align-items-center'>
              <div className='profile-picdiv d-flex align-items-center justify-content-center'>
                <img
                  src={
                    opportunityDetails?.Product?.iconUrl
                      ? opportunityDetails?.Product?.iconUrl
                      : defaultPolicy
                  }
                  alt=''
                />
                {/* { TODO : Show assets based on type} */}
              </div>
              <div>
                <div className=' d-flex align-items-center mb-2 text-overflow-container'>
                  <h2>
                    {opportunityDetails?.name} -{' '}
                    {opportunityDetails?.ProductId
                      ? opportunityDetails?.Product?.name
                      : opportunityDetails?.CustomProduct?.name}
                  </h2>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {opportunityDetails?.name} -{' '}
                      {opportunityDetails?.ProductId
                        ? opportunityDetails?.Product?.name
                        : opportunityDetails?.CustomProduct?.name}
                    </span>
                  </span>
                  <span className='status statusInactive'>
                    {opportunityDetails?.PipelineStage?.name}
                  </span>
                </div>

                <div className='header-info d-flex align-items-center'>
                  <span
                    className='opportunities-div'
                    style={{ textDecoration: 'none' }}
                  >
                    {/* TODO : Change to Address */}
                    {opportunityDetails?.Account?.Address?.city ? (
                      opportunityDetails?.Account?.Address?.city
                    ) : (
                      <span style={{ fontWeight: 400 }}>
                        No specified address
                      </span>
                    )}
                  </span>
                  <span className='amount'>
                    $
                    {opportunityDetails?.leastPremium
                      ? opportunityDetails?.leastPremium.toLocaleString('en-US')
                      : 0}
                  </span>{' '}
                  <span
                    className='opportunities-div d-flex align-items-center'
                    style={{ textDecoration: 'none' }}
                  >
                    <img src={contacts} alt='' className='contact-img' />
                    Account:{' '}
                    <span className='text-overflow-container'>
                      <span
                        className={`nav-decor ${
                          user.Agent.id !==
                            opportunityDetails?.Account.AgentId &&
                          'disabled-decor'
                        }`}
                        onClick={() =>
                          navigate(
                            `/account/${opportunityDetails?.Account?.uuid}`
                          )
                        }
                      >
                        {opportunityDetails?.Account?.name
                          ? opportunityDetails?.Account.name
                          : '--'}{' '}
                      </span>
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {opportunityDetails?.Account?.name
                            ? opportunityDetails?.Account.name
                            : '--'}
                        </span>
                      </span>
                    </span>
                  </span>
                  {opportunityDetails?.Account?.AccountContacts && (
                    <span className='d-flex align-items-center'>
                      <img src={contacts} alt='' className='contact-img' />
                      Contact:{' '}
                      <span className='text-overflow-container'>
                        <span
                          className={`nav-decor ${
                            user.Agent.id !==
                              opportunityDetails?.Account.AgentId &&
                            'disabled-decor'
                          }`}
                          onClick={() =>
                            navigate(
                              `/contacts/${opportunityDetails?.Account.AccountContacts[0].Contact.uuid}`
                            )
                          }
                        >
                          {opportunityDetails?.Account?.AccountContacts[0]
                            ?.Contact.firstName
                            ? opportunityDetails?.Account?.AccountContacts[0]
                                ?.Contact.firstName
                            : opportunityDetails.OpportunityAccountContacts[0]
                                ?.AccountContact.Contact.firstName}{' '}
                          {opportunityDetails?.Account?.AccountContacts[0]
                            ?.Contact.lastName
                            ? opportunityDetails?.Account?.AccountContacts[0]
                                ?.Contact.lastName
                            : opportunityDetails.OpportunityAccountContacts[0]
                                ?.AccountContact.Contact.lastName}{' '}
                        </span>
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>
                            {
                              opportunityDetails?.Account?.AccountContacts[0]
                                ?.Contact.firstName
                            }{' '}
                            {
                              opportunityDetails?.Account?.AccountContacts[0]
                                ?.Contact.lastName
                            }
                          </span>
                        </span>
                      </span>
                      {opportunityDetails.OpportunityAccountContacts?.length >
                        1 && (
                        <span className='count-div'>
                          +
                          {opportunityDetails.OpportunityAccountContacts
                            ?.length - 1}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                className='primaryButton'
                onClick={() => {
                  setShowQuoteModal('open');
                }}
              >
                Add quote
                <img alt='' src={arrow} style={{ marginLeft: 28 }} />
              </button>
              <button
                className='dropDownButton'
                onClick={() => setQuickDrop(!quickDrop)}
                ref={editRef}
              >
                <img src={dropdown} alt='' />
                {quickDrop && (
                  <div className='dropdown-div'>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowEditModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Edit <img src={edit} alt='bin' />
                    </div>
                    <div className='borderDiv'></div>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowDeleteModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Delete <img src={bin} alt='bin' />
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className='d-flex' style={{ borderBottom: '1px solid #E6E9F0' }}>
            {opportunityDetails && (
              <OpportunityDetailsDrawer
                opportunityDetails={opportunityDetails}
                setShowEditModal={setShowEditModal}
                setShowLinkContactModal={setShowLinkContactModal}
                setShowAddContactModal={setShowAddContactModal}
              />
            )}
            <div className='options-div'>
              <DetailsPageTab name={'Opportunity'} />
            </div>
          </div>
        </div>
        <div className='d-xl-none'>
          {opportunityDetails && (
            <MobileOpportunityDetails
              setShowEditModal={setShowEditModal}
              opportunityDetails={opportunityDetails}
              setShowLinkContactModal={setShowLinkContactModal}
              setShowAddContactModal={setShowAddContactModal}
            />
          )}
        </div>
        {opportunityDetails && showEditModal !== 'closed' && (
          <EditOpportunityDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            UUID={opportunityDetails?.uuid}
          />
        )}
        {showQuoteModal !== 'closed' && (
          <AddQuoteModal
            show={showQuoteModal}
            closeModal={setShowQuoteModal}
            type='Opportunity'
          />
        )}

        {showDeleteModal !== 'closed' && opportunityDetails && (
          <DeleteModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deleteFunction={onDelete}
            uuid={opportunityDetails.uuid}
            loading={loadingStatus}
            resource='contact'
            name={
              opportunityDetails?.name ? opportunityDetails?.name : 'unknown'
            }
          />
        )}
        {showLinkcontactModal !== 'closed' && opportunityDetails && (
          <OpportunityContactModal
            name='opportunity'
            uuid={opportunityDetails?.uuid}
            accountUUID={opportunityDetails?.Account?.uuid}
            opportunityAccountContact={opportunityDetails?.OpportunityAccountContacts.map(
              (item) => item?.AccountContact?.Contact?.uuid
            )}
            show={showLinkcontactModal}
            closeModal={setShowLinkContactModal}
          />
        )}
        {showAddcontactModal !== 'closed' && opportunityDetails && (
          <ContactForm
            uuid={opportunityDetails?.uuid}
            resource='Opportunity'
            show={showAddcontactModal}
            closeModal={setShowAddContactModal}
          />
        )}
      </div>
    </DetailsDiv>
  );
};

export default OpportunityDetails;

import { useEffect, useRef } from 'react';
import { LeadsFunnel } from '../style';

const FunnelGraph = require('funnel-graph-js');

export interface IProps {
  data: any;
}

const LeadFunnel = ({ data }: IProps) => {
  const ref = useRef<any>(null);
  const coloursList: any = [];
  const colorCount = 20;

  const handleGraph = () => {
    const x: any = [];
    data?.data.map((element: any, index: number) => {
      x.push([
        (element.count - (element.count * element.stageProbability) / 100) / 2,
        (element.count * element.stageProbability) / 100,
        (element.count - (element.count * element.stageProbability) / 100) / 2,
      ]);
    });
    const datas = {
      labels: data?.data?.map((item: any) => item.stageName),
      subLabels: ['', 'out of', 'policies will most probably move  to Won'],
      colors: [['#c1f6f9'], ['#262758'], ['#c1f6f9']],
      values: x,
    };
    let graph = null;
    const myNode: any = document.getElementById('leads');
    while (myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }
    graph = new FunnelGraph({
      container: '.leads',
      gradientDirection: 'horizontal',
      data: datas,
      displayPercent: true,
      direction: 'Horizontal',
      width: ref?.current?.offsetWidth,
      height: 100,
      subLabelValue: 'raw',
    });
    graph.draw();
    const collection = document.querySelectorAll(
      '.segment-percentage__list > li:nth-child(1) .percentage__list-label'
    );
    const policyCount = document.querySelectorAll(
      '.segment-percentage__list > li:nth-child(2) .percentage__list-label'
    );
    for (let i = 0; i < collection.length; i++) {
      collection[i].innerHTML = (
        parseFloat(collection[i].innerHTML) * 2
      ).toString();
      policyCount[i].innerHTML = (
        parseFloat(collection[i].innerHTML) +
        parseFloat(policyCount[i].innerHTML)
      ).toString();
    }
  };
  useEffect(() => {
    handleGraph(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    handleGraph(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    window.addEventListener('resize', handleGraph);
    return () => {
      window.removeEventListener('resize', handleGraph);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LeadsFunnel>
      <div className='funnel-container' ref={ref}>
        <div className='leads' id='leads'></div>
      </div>
    </LeadsFunnel>
  );
};

export default LeadFunnel;

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { StyledCard } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { IPolicy } from '../../../../interfaces/Policy';
import EditPolicyDetails from '../../../PolicyDetails/EditPolicyDetails';
const PolicyMobileCard = ({
  policy,
  setShowDeleteModal,
  setUUID,
  setName,
}: {
  policy: IPolicy;
  setShowDeleteModal: Function;
  setUUID: Function;
  setName: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const onSetItem = () => {};
  const values = {};

  const dropRef = useRef(null);
  const navigate = useNavigate();

  useOutsideClick(dropRef, setShowDropdown);

  return (
    <StyledCard>
      <div className='d-flex justify-content-between align-items-center'>
        <h6>{policy.Accounts.name}</h6>
        <div className='status-div'>
          {' '}
          {policy.Product
            ? policy.Product.name
            : policy.CustomProduct
            ? policy.CustomProduct?.name
            : '--'}
        </div>
      </div>
      <div className='info-div '>
        <div className='d-flex'>
          <div className='type-div'>
            ${policy.premium ? policy.premium.toLocaleString('en-US') : 0}
          </div>
          <div>
            {policy.Issuer
              ? policy.Issuer.Carrier.name
              : policy.CustomCarrier
              ? policy.CustomCarrier.name
              : '--'}
          </div>
        </div>
        Effective date{' '}
        <Moment format='MM/DD/YYYY'>{policy.effectiveDate}</Moment>
        <div></div>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='toolDiv info d-flex align-items-center h-100'>
          {policy.Agents.User.avatar ? (
            <img src={policy.Agents.User.avatar} alt='' className='user-pic' />
          ) : (
            <div className='user-pic'>
              {policy.Agents.User.firstName?.substring(0, 1).toUpperCase()}
              {policy.Agents.User.lastName?.substring(0, 1).toUpperCase()}
            </div>
          )}
          <span className='tooltiptext tooltip-producer'>
            <span className='tooltipInnertext'>
              {policy.Agents.User.firstName + ' ' + policy.Agents.User.lastName}
            </span>
          </span>
        </div>
        <div className='d-flex'>
          <div
            className='actionButton
          '
            onClick={() => {
              navigate(`/policy/${policy.uuid}`);
            }}
          >
            View details
          </div>

          <div className='vector-div' ref={dropRef}>
            <div
              className='vector'
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <img src={vector} alt='vector' />
            </div>
            {showDropdown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowDropdown(false);
                    setShowEditModal('open');
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='delete-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setUUID(policy?.uuid);
                    setShowDropdown(false);
                    setName(policy?.Accounts?.name);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
            {showEditModal !== 'closed' && policy?.uuid && (
              <EditPolicyDetails
                show={showEditModal}
                closeModal={setShowEditModal}
                uuid={policy.uuid}
                policy={policy}
                isListing={true}
              />
            )}
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default PolicyMobileCard;

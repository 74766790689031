import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import { AppDispatch } from '../../../store';
import {
  clearPipelines,
  getPipelines,
  PipelinesSliceState,
  selectAllPipelines,
} from '../../../store/pipelines/pipelineSlice';
import TopBar from '../../../components/TopBar';
import ManageCard from './components/ManageCard';
import MobileManageCard from './components/MobilePipelineCard';
import FilterModal from '../../../components/FilterDrawer';
import FilterMobileModal from '../../../components/FilterMobileDrawer';
import LocalStorageService from '../../../utils/LocalStorageService';
import { IOption, IPipeline } from '../../../interfaces/Opportunity';
import { PipelineInfoStyles } from '../elements/styles';
import search from '../../../assets/logo/search.svg';
import plus from '../../../assets/logo/plus.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import PipelineModal from '../../../components/AddPipelineModal';
import Filter from '../../../assets/logo/filter.svg';
import close from '../../../assets/logo/cross.svg';
import filter from '../../../assets/logo/filter-mobile.svg';
import ErrorCard from '../../Pipeline/components/ErrorCard';

const PipelineMange = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { opportunities: PipelinesSliceState }) =>
      state.opportunities?.status
  );
  const pipelines = useSelector(selectAllPipelines);
  const user = LocalStorageService.getUser();

  const [showAddModal, setShowAddModal] = useState('closed');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const searchRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [deletedItem, setDeletedItem] = useState<IPipeline>();
  const [showFilter, setShowFilter] = useState('closed');
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [selectedOppStatus, setOppStatus] = useState<IOption[]>([]);
  const [selectedProducts, setProducts] = useState<IOption[]>([]);

  const [selectedActive, setActive] = useState<any>('');

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  const navigate = useNavigate();
  const { uuid } = useParams();

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(clearPipelines());
      dispatch(
        getPipelines({
          sortBy: 'createdAt',
          orderBy: 'desc',
          searchBy: debouncedSearchTerm,
          agencyUUID: uuid,
          showAll: true,
          active: selectedActive?.value,
          productUUID: selectedProducts
            ?.filter((item) => item.type === 'PRODUCT')
            .map((item: any) => item.value),
          customProductUUID: selectedProducts
            ?.filter((item) => item.type === 'CUSTOM_PRODUCT')
            .map((item: any) => item.value),
          successCB: (data: IPipeline) => {},
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedSearchTerm, selectedActive, selectedProducts]);

  return (
    <PipelineInfoStyles>
      <TopBar>
        <div
          className='d-none d-sm-flex align-items-center justify-content-between'
          role='button'
          onClick={() =>
            user?.UserRole?.name === 'ADMIN'
              ? navigate(`/admin/agency/${uuid}`)
              : navigate('/settings')
          }
        >
          <img src={arrow} className='leftArrow' alt='arrow' />
          <h2 className='d-none d-sm-block'>Pipeline Settings</h2>
        </div>
        <div
          className='mobHead d-flex d-sm-none align-items-center justify-content-between'
          onClick={() =>
            user?.UserRole?.name === 'ADMIN'
              ? navigate(`/admin/agency/${uuid}`)
              : navigate('/settings')
          }
          role='button'
        >
          <img src={arrow} className='leftArrow' alt='arrow' />
          <h2 className=' d-block d-sm-none'>Pipeline Settings</h2>
        </div>
      </TopBar>
      <div className='content-div'>
        {showSuccess && (
          <ErrorCard
            message={`<b>${
              deletedItem?.name ? deletedItem?.name : 'PipeLine'
            } </b> has been deleted`}
            title={'Success deleting pipeline.'}
          />
        )}
        <div className='d-none d-sm-flex justify-content-between'>
          <button
            className='createButton d-flex align-items-center d-none d-sm-block'
            onClick={() => setShowAddModal('open')}
          >
            Create Pipeline <img className='buttonAdd' src={plus} alt='plus' />
          </button>
          <div className='book-filter d-flex ms-auto' ref={searchRef}>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}

            <span className='divider d-none d-sm-block' />
            <div
              className='book-shrinked d-none d-sm-flex'
              onClick={() => setShowFilter('open')}
            >
              <img src={Filter} alt='' />
              {selectedOppStatus.length +
                selectedProducts.length +
                (selectedActive ? 1 : 0) >
                0 && (
                <span className='filter-counter'>
                  {selectedOppStatus.length +
                    selectedProducts.length +
                    (selectedActive ? 1 : 0)}
                </span>
              )}
            </div>
            <span
              className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
            />
            <div
              className={`book-shrinked d-sm-none ${
                isSearchClicked && 'd-none'
              }`}
              onClick={() => setShowMobileFilter(true)}
            ></div>
          </div>
        </div>
        <div className=' d-sm-none mobilecardDiv'>
          <h2 className='HeadNavigator'></h2>
          <div className='d-flex'>
            <div className='d-flex search-Field  w-100'>
              <img className='mx-2' src={search} alt='' />
              <input
                className='inputSearchField mx-2'
                type='text'
                placeholder='Search users'
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
            </div>
            <div className='d-flex search-Field filterField position-relative'>
              <img src={filter} alt='' />
              {selectedOppStatus.length +
                selectedProducts.length +
                (selectedActive ? 1 : 0) >
                0 && (
                <span className='filter-counter'>
                  {selectedOppStatus.length +
                    selectedProducts.length +
                    (selectedActive ? 1 : 0)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='d-none d-sm-block'>
          {pipelines.map((item, index) => (
            <ManageCard
              pipeline={item}
              key={index}
              setShowSuccess={setShowSuccess}
              setDeletedItem={setDeletedItem}
              deleteSuccess={showSuccess}
            />
          ))}
        </div>
        <div className='d-block d-sm-none'>
          {pipelines.map((item, index) => (
            <MobileManageCard
              pipeline={item}
              key={index}
              setShowSuccess={setShowSuccess}
              setDeletedItem={setDeletedItem}
              deletingSuccess={showSuccess}
            />
          ))}
        </div>
      </div>
      <button
        className='addButton d-sm-none'
        onClick={() => setShowAddModal('open')}
      >
        <img src={plus} alt='add' />
      </button>
      {showAddModal !== 'closed' && (
        <PipelineModal
          show={showAddModal}
          closeModal={setShowAddModal}
          agencyUUID={uuid}
        />
      )}

      <div className='d-none d-sm-block'>
        {showFilter !== 'closed' && (
          <FilterModal
            show={showFilter}
            closeModal={setShowFilter}
            selectedOppStatus={selectedOppStatus}
            setProducts={setProducts}
            setOppStatus={setOppStatus}
            selectedProducts={selectedProducts}
            selectedActive={selectedActive}
            setActive={setActive}
            pipelineManage={true}
          />
        )}
      </div>
      <div className='d-block d-sm-none'>
        {showMobileFilter ? (
          <FilterMobileModal
            show={showMobileFilter}
            closeModal={setShowMobileFilter}
            selectedOppStatus={selectedOppStatus}
            setProducts={setProducts}
            setOppStatus={setOppStatus}
            selectedProducts={selectedProducts}
            selectedActive={selectedActive}
            setActive={setActive}
            pipelineManage={true}
          />
        ) : null}
      </div>
    </PipelineInfoStyles>
  );
};

export default PipelineMange;

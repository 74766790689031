import Modal from '../../../../components/Modal';
import { StyledModal } from '../../elements/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from '../../../../components/InputField';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import { useEffect, useState } from 'react';
import SelectField from '../../../../components/SelectField';
import { useSelector } from 'react-redux';
import {
  onUpdateCustomFields,
  selectAllCustomFieldsType,
} from '../../../../store/customFields/customFieldsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import blackplus from '../../../../assets/logo/blackplus.svg';
import { FieldDiv } from '../../../../components/InputField/styles';
import Skeleton from 'react-loading-skeleton';
import { PickerDiv } from '../../../../components/DateField/styles';
import calender from '../../../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useParams } from 'react-router';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import { YearMonthPicker } from '../../../../components/DateField';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Field Name is required')
    .max(20, 'The Field name cannot exceed 20 characters'),
});
const EditCustomField = ({
  loadingStatus,
  addCustomField,
  closeModal,
  fields,
}: {
  loadingStatus: string;
  addCustomField: string;
  closeModal: Function;
  fields: any;
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const watchAllFields = watch();
  const dispatch = useDispatch<AppDispatch>();

  const [addToForm, setAddToForm] = useState(fields.addToForm);

  const [options, setOptions] = useState<any>([{}]);

  useEffect(() => {
    setValue('name', fields?.name);
    setValue('fieldType', {
      label: fields?.FieldType?.name?.split('_').join(' ').toLowerCase(),
      value: fields?.FieldType?.uuid,
    });
    setOptions(fields.data?.options);
    setValue('addToForm', fields.addToForm);
    setDefaultOption(fields.data?.defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defaultOption, setDefaultOption] = useState<any>('');
  const { uuid } = useParams();

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const onSubmit = (data: any) => {
    data.typeUUID = data.fieldType.value;
    data.addToForm = addToForm;
    data.options = options
      .filter((value: any) => Object.keys(value).length !== 0)
      .filter((el: any) => Object.keys(el?.value).length !== 0);
    data.defaultValue = defaultOption;
    data.agencyUUID = uuid || '';
    dispatch(
      onUpdateCustomFields({
        uuid: fields?.uuid,
        data,
        successCB: () => {
          closeModal('closing');
          setTimeout(() => {
            setshowSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setshowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 2000);
        },
      })
    );
  };
  const customFieldsType: any = useSelector(selectAllCustomFieldsType);

  const addsNewOption = () => {
    options ? setOptions([...options, {}]) : setOptions([{}]);
  };

  const [showSuccess, setshowSuccess] = useState(false);

  const onItem = (index: number, value: any, isDefault?: boolean) => {
    let updatedOption = [...options];
    let updatedOptionSingle = { ...updatedOption[index] };
    updatedOptionSingle.order = index;
    if (isDefault) {
      if (watchAllFields.fieldType.label.trim() === 'multiple choice') {
        updatedOptionSingle.default = value;
      } else {
        setDefaultOption(value);
      }
    } else if (!isDefault) {
      updatedOptionSingle.value = value;
    }
    if (updatedOptionSingle.hasOwnProperty('value')) {
      updatedOption[index] = updatedOptionSingle;
      setOptions(updatedOption);
    }
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success editing custom field'}
            message={`<b>${getValues('name')}</b> has been edited<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={addCustomField} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2 className='add-customField-heading'>Edit custom field</h2>
          </div>
          <div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='field-section'>
                  <div className='agency-fields'>
                    {loadingStatus === 'loading' ? (
                      <Skeleton height={48} />
                    ) : (
                      <InputField
                        name='name'
                        type='text'
                        placeholder='Field Name'
                        register={register}
                        className={`${errors.name && 'error-field'} ${
                          watchAllFields.name && 'filled'
                        }`}
                      />
                    )}
                    {errors.name ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.name?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    {loadingStatus === 'loading' ? (
                      <Skeleton height={48} />
                    ) : (
                      <SelectField
                        options={customFieldsType?.map((item: any) => {
                          return {
                            label: ` ${
                              item?.name
                                ? item?.name?.split('_').join(' ').toLowerCase()
                                : ''
                            }`,
                            value: item?.uuid,
                          };
                        })}
                        disabled
                        name='fieldType'
                        placeholder='Field Type'
                        className={`fieldType ${
                          errors.fieldType && 'error-field'
                        } ${watchAllFields.fieldType && 'filled'}`}
                        control={control}
                      />
                    )}
                    {errors.fieldType ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.fieldType?.message
                          ? 'Please select a field type'
                          : errors.fieldType?.value?.message}
                      </div>
                    ) : null}
                  </div>
                  {(watchAllFields.fieldType?.label?.trim() ===
                    'single choice' ||
                    watchAllFields.fieldType?.label?.trim() ===
                      'multiple choice' ||
                    fields?.FieldType?.name
                      ?.split('_')
                      .join(' ')
                      .toLowerCase()
                      .trim() === 'single choice' ||
                    fields?.FieldType?.name
                      ?.split('_')
                      .join(' ')
                      .toLowerCase()
                      .trim() === 'multiple choice') && (
                    <>
                      <h5 className='options-subhead'>
                        Provide possible values to this field
                      </h5>
                      <div className='agency-fields'>
                        {options?.map((item: any, index: any) =>
                          loadingStatus === 'loading' ? (
                            <Skeleton height={48} />
                          ) : (
                            <div>
                              <label className='radio-container'>
                                {watchAllFields.fieldType?.label?.trim() ===
                                  'multiple choice' ||
                                fields?.FieldType?.name
                                  ?.split('_')
                                  .join(' ')
                                  .toLowerCase()
                                  .trim() === 'multiple choice' ? (
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    name='options'
                                    value='default'
                                    defaultChecked={item.default}
                                    onChange={(e) =>
                                      onItem(index, e.target.checked, true)
                                    }
                                    disabled={!item.value}
                                  />
                                ) : (
                                  <input
                                    type='radio'
                                    className='form-check-input'
                                    name='options'
                                    value={index}
                                    defaultChecked={
                                      index ===
                                      parseInt(fields.data?.defaultValue, 10)
                                    }
                                    onChange={(e) =>
                                      onItem(index, e.target.value, true)
                                    }
                                    disabled={!item.value}
                                  />
                                )}
                                <FieldDiv className='options-field'>
                                  <div
                                    className={` w-100 ${
                                      item.value ? 'filled' : ''
                                    } input-container`}
                                  >
                                    <input
                                      id={`option_${index}`}
                                      onChange={(e) =>
                                        onItem(index, e.target.value)
                                      }
                                      className='input-field'
                                      value={item.value}
                                      type='text'
                                      autoComplete='off'
                                      placeholder='Option'
                                    />
                                  </div>
                                </FieldDiv>
                              </label>
                            </div>
                          )
                        )}
                      </div>
                      <button
                        className='align-items-center secondaryButton addOptionsButton'
                        type='button'
                        onClick={() => addsNewOption()}
                      >
                        <img
                          className='addAnotherBusiness'
                          src={blackplus}
                          alt='add'
                        />
                        Add another option
                      </button>
                    </>
                  )}
                  {watchAllFields.fieldType?.label?.trim() === 'datepicker' ||
                  fields?.FieldType?.name.toLowerCase()?.trim() ===
                    'datepicker' ? (
                    <div>
                      <PickerDiv className='filled-picker'>
                        <div
                          className={`d-flex flex-column w-100 input-container filled`}
                        >
                          <DatePicker
                            className='date-input'
                            onChangeRaw={(e) => e.preventDefault()}
                            onChange={(date) => setDefaultOption(date)}
                            placeholderText='Provide default if any...'
                            dateFormat={'MM/dd/yyyy'}
                            selected={
                              defaultOption !== ''
                                ? moment(defaultOption).toDate()
                                : null
                            }
                            name='date'
                            renderCustomHeader={({
                              date,
                              prevYearButtonDisabled,
                              nextYearButtonDisabled,
                              decreaseYear,
                              increaseYear,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <YearMonthPicker
                                date={date}
                                prevMonthButtonDisabled={
                                  prevMonthButtonDisabled
                                }
                                prevYearButtonDisabled={prevYearButtonDisabled}
                                nextMonthButtonDisabled={
                                  nextMonthButtonDisabled
                                }
                                nextYearButtonDisabled={nextYearButtonDisabled}
                                decreaseMonth={decreaseMonth}
                                decreaseYear={decreaseYear}
                                increaseMonth={increaseMonth}
                                increaseYear={increaseYear}
                              />
                            )}
                          />
                          <label htmlFor='date'>Estimated date</label>

                          <div className='CallenderImg'>
                            <img src={calender} alt='Calender' />
                          </div>
                        </div>
                      </PickerDiv>
                    </div>
                  ) : null}
                  <hr className='add-customField-hr' />
                  {loadingStatus === 'loading' ? (
                    <Skeleton height={48} />
                  ) : (
                    <div className='prior d-flex form-appear-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name='addToForm'
                        defaultChecked={fields.addToForm}
                        onClick={() => {
                          setAddToForm(!addToForm);
                        }}
                      />
                      <label className='form-check-label'>
                        {' '}
                        Appear in form
                      </label>
                    </div>
                  )}
                </div>
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='agentButton'
                    disabled={
                      loadingStatus === 'loading' ||
                      addCustomField === 'closing'
                    }
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};
export default EditCustomField;
